import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { RadiusGraph, RadiusGraphState } from '../../CoreComponents/GraphComponents';
import { pullManagedJobs } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';

export interface PayAppGraphicReport {
  payAppNumber: number,
  payApplicationName: string,
  payApplicationProgress: number,
  subcontractorCount: number,
  subcontractorConditionalPercentageSent: number,
  subcontractorConditionalPercentageSigned: number,
  subcontractorUnconditionalPercentageSent: number,
  subcontractorUnconditionalPercentageSigned: number
}

interface PayApplicationGraphicsDashboardProps {

}

interface PayApplicationGraphicsDashboardState {
  jobs: Array<SelectOptions>;
  payApps: Array<any>;
  currentTab: number;
}

export default class PayApplicationGraphicsDashboard extends React.Component<PayApplicationGraphicsDashboardProps, PayApplicationGraphicsDashboardState> {

  constructor(props: PayApplicationGraphicsDashboardProps) {
    super(props);
    this.state = {
      jobs: [],
      payApps: [],
      currentTab: 0
    }
    this.changeJob = this.changeJob.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let jobs = await pullManagedJobs();
    this.setState({ jobs: jobs }, () => this.changeJob());
  }

  async changeJob(): Promise<void> {
    const self = this;
    var jobUID = this.state.jobs[this.state.currentTab].value;
    await axios.get("./api/managed-jobs/graphics/" + jobUID).then(function (response) {
      self.setState({
        payApps: response.data
      })
    })
  }

  getPayAppGraphSettings(payApp: PayAppGraphicReport): RadiusGraphState {
    const settings: RadiusGraphState = {
      width: "200px",
      height: "200px",
      baseStroke: "#000",
      colors: [
        "#d7c7a7"
      ],
      values: [
        {
          label: 'Pay App Status',
          value: payApp.payApplicationProgress * 100,
          checked: false,
          key: 0
        }
      ]
    }
    return settings;
  }

  getConditionalGraphSettings(payApp: PayAppGraphicReport): RadiusGraphState {
    const settings: RadiusGraphState = {
      width: "200px",
      height: "200px",
      baseStroke: "#000",
      colors: [
        "#b89961",
        "#d7c7a7"
      ],
      values: [
        {
          label: 'Sent',
          value: payApp.subcontractorConditionalPercentageSent * 100,
          checked: false,
          key: 0
        },
        {
          label: 'Signed',
          value: payApp.subcontractorConditionalPercentageSigned * 100,
          checked: false,
          key: 0
        }
      ]
    }
    return settings;
  }

  getUnconditionalGraphSettings(payApp: PayAppGraphicReport): RadiusGraphState {
    const settings: RadiusGraphState = {
      width: "200px",
      height: "200px",
      baseStroke: "#000",
      colors: [
        "#b89961",
        "#d7c7a7"
      ],
      values: [
        {
          label: 'Sent',
          value: payApp.subcontractorUnconditionalPercentageSent * 100,
          checked: false,
          key: 0
        },
        {
          label: 'Signed',
          value: payApp.subcontractorUnconditionalPercentageSigned * 100,
          checked: false,
          key: 0
        }
      ]
    }
    return settings;
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "80vh", overflow: "auto" }}>
        <Row style={{ width: "98%" }}>
          {
            this.state.jobs.map((item: any, idx: any) => (
              <Col key={item.uid} style={{ margin: "0px", padding: "0px" }}>
                <input type="button" className={this.state.currentTab === idx ? "tab-active" : "tab"}
                  style={{ width: "100%" }} value={item.name} onClick={() => this.setState({ currentTab: idx }, this.changeJob)} />
              </Col>
            ))
          }
        </Row>
        {
          this.state.payApps.map((item: PayAppGraphicReport, idx: any) => (
            <div key={this.state.jobs[this.state.currentTab].label + item.payApplicationName + "Container"}
              style={{ width: "97%", backgroundColor: "#4C4A42", border: "2px solid #d7c7a7", borderTop: idx == 0 ? "1px solid #d7c7a7" : "0px" }}
            >
              <h3 style={{ width: "100%", textAlign: "center" }}>{item.payApplicationName}</h3>
              <Row>
                <Col>
                  <h5 style={{ width: "100%", textAlign: "center" }}>Pay Application Progress</h5>
                </Col>
                <Col>
                  <h5 style={{ width: "100%", textAlign: "center" }}>Conditional Waiver Progress</h5>
                </Col>
                <Col>
                  <h5 style={{ width: "100%", textAlign: "center" }}>Unconditional Waiver Progress</h5>
                </Col>
              </Row>
              <Row>
                <Col style={{ height: "30vh" }}>
                  <RadiusGraph
                    key={this.state.jobs[this.state.currentTab].label + item.payApplicationName + "PayApp"}
                    radiusGraphProps={this.getPayAppGraphSettings(item)}
                  />
                </Col>
                <Col style={{ height: "30vh" }}>
                  <RadiusGraph
                    key={this.state.jobs[this.state.currentTab].label + item.payApplicationName + "Conditional"}
                    radiusGraphProps={this.getConditionalGraphSettings(item)}
                  />
                </Col>
                <Col style={{ height: "30vh" }}>
                  <RadiusGraph
                    key={this.state.jobs[this.state.currentTab].label + item.payApplicationName + "Unconditional"}
                    radiusGraphProps={this.getUnconditionalGraphSettings(item)}
                  />
                </Col>
              </Row>
            </div>
          ))
        }
      </div>
    )
  }
}