import axios from 'axios';
import * as React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip } from 'reactstrap';
import { DatePicker } from '../../CoreComponents/DateComponents';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { DownloadNamedFile } from '../../../functions/documentTools';

interface TransitionPlanWorkOrderData {
  uid: string;
  category: string;
  description: string;
  correctiveAction: string;
  estimated: number;
  due: string;
  alternativeAction: string;
  status: string;
  vendorOrMaintenance: string;
  completed: Date;
  actual: number;
}

interface TransitionPlanViewWorkOrderModalState {
  show: boolean;
  uid: string;
  data: Array<TransitionPlanWorkOrderData>;
  correctiveActionIsOpen: Array<boolean>;
  descriptionIsOpen: Array<boolean>;
}

export default class TransitionPlanViewWorkOrderModal extends React.Component<{}, TransitionPlanViewWorkOrderModalState> {


  constructor(props: {}) {
    super(props);
    this.state = {
      show: false,
      data: [],
      correctiveActionIsOpen: [],
      descriptionIsOpen: [],
      uid: ""
    }
    this.showCorrectiveAction = this.showCorrectiveAction.bind(this);
    this.hideCorrectiveAction = this.hideCorrectiveAction.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.hideDescription = this.hideDescription.bind(this);
    this.exportWorkOrders = this.exportWorkOrders.bind(this);
  }

  async show(uid: string) {
    // need to pull the work orders from the server
    let response = await axios.get('./api/transition-plan-work-orders/transition-plan/' + uid);
    this.setState({
      show: true,
      uid: uid,
      data: response.data,
      correctiveActionIsOpen: new Array(response.data.length).fill(false),
      descriptionIsOpen: new Array(response.data.length).fill(false)
    })
  }

  showCorrectiveAction(idx: number) {
    let isOpen = this.state.correctiveActionIsOpen;
    isOpen[idx] = true;
    this.setState({ correctiveActionIsOpen: isOpen });
  }

  hideCorrectiveAction(idx: number) {
    let isOpen = this.state.correctiveActionIsOpen;
    isOpen[idx] = false;
    this.setState({ correctiveActionIsOpen: isOpen });
  }

  showDescription(idx: number) {
    let isOpen = this.state.descriptionIsOpen;
    isOpen[idx] = true;
    this.setState({ descriptionIsOpen: isOpen });
  }

  hideDescription(idx: number) {
    let isOpen = this.state.descriptionIsOpen;
    isOpen[idx] = false;
    this.setState({ descriptionIsOpen: isOpen });
  }

  async exportWorkOrders(): Promise<void> {
    let response = await axios.get('./api/transition-plan/export/' + this.state.uid);
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} size="xl">
        <div style={{ background: "#4c4a42" }}>
          <ModalHeader style={{ backgroundColor: "#c2a877" }} tag="h3">
            Transition Plan Work Orders
          </ModalHeader>
          <ModalBody>
            <div style={{ maxHeight: "65vh", overflowY: "auto", overflowX: "hidden"}}>
              <table className="fixedTable">
                <thead>
                  <tr>
                    <th className="fixedTableHeaderFirst twenty">Category</th>
                    <th className="fixedTableHeader thirty">Description</th>
                    <th className="fixedTableHeader">Corrective Action</th>
                    <th className="fixedTableHeader fifteen">Est.</th>
                    <th className="fixedTableHeader fifteen">Due</th>
                    <th className="fixedTableHeader twenty">Alternative</th>
                    <th className="fixedTableHeader fifteen">Status</th>
                    <th className="fixedTableHeader twenty">Assignee</th>
                    <th className="fixedTableHeader twenty-five">Completed</th>
                    <th className="fixedTableHeader fifteen">Actual</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.data.map((item, idx: number) => (
                      <tr key={item.uid}>
                        
                        <td className="fixedTableCellFirst">{item.category}</td>
                        <td className="fixedTableCell"
                          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
                          id={"description" + idx}
                          onMouseOver={() => this.showDescription(idx)}
                          onMouseOut={() => this.hideDescription(idx)}
                        >
                          {item.description}
                        </td>
                        <td id={"correctiveAction" + idx} className="fixedTableCell"
                          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
                          onMouseOver={() => this.showCorrectiveAction(idx)}
                          onMouseOut={() => this.hideCorrectiveAction(idx)}
                        >
                          {item.correctiveAction}
                        </td>
                        <td className="fixedTableCell">{"$" + numberWithCommas(item.estimated, 2)}</td>
                        <td className="fixedTableCell">{item.due}</td>
                        <td className="fixedTableCell">{item.alternativeAction}</td>
                        <td className="fixedTableCell">{item.status}</td>
                        <td className="fixedTableCell">{item.vendorOrMaintenance}</td>
                        <td className="fixedTableCell">
                          <DatePicker value={new Date(item.completed)} onChange={null} />
                        </td>
                        <td className="fixedTableCell">{item.actual}</td>
                        <Tooltip
                          title={item.correctiveAction}
                          target={"correctiveAction" + idx}
                          isOpen={this.state.correctiveActionIsOpen[idx]}
                          placement={idx > 10 ? "top" : "bottom"}
                          style={{
                            border: "2px solid #ddd",
                            backgroundColor: "#111",
                            opacity: "2",
                            color: "#fff",
                          }}
                        >
                          {item.correctiveAction}
                        </Tooltip>
                        <Tooltip
                          title={item.correctiveAction}
                          target={"description" + idx}
                          isOpen={this.state.descriptionIsOpen[idx]}
                          placement={idx > 10 ? "top" : "bottom"}
                          style={{
                            border: "2px solid #ddd",
                            backgroundColor: "#111",
                            opacity: "1"
                          }}
                        >
                          {item.description}
                        </Tooltip>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button className="standard-input" onClick={() => this.exportWorkOrders()}>
                  Export
                </Button>
              </Col>
              <Col>
                <Button className="standard-input" onClick={() => this.setState({ show: false})}>
                  Close
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}