import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import axios from 'axios';
import { pullEmployeeAllocationArchiveDates, pullViridianMaintenanceManagers, pullViridianSiteManagers } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { Col, Row } from 'reactstrap';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
interface EmployeeArchiveAllocationWidgetState {
  dates: Array<SelectOptions>;
  date: SelectOptions;
}

export class EmployeePastAllocationReportGenerator extends React.Component<{}, EmployeeArchiveAllocationWidgetState> {

  headers: Array<FilterAndSettingField> = [
    { columnName: "Property", setting: true, filter: {} },
    { columnName: "Role", setting: true, filter: {} },
    { columnName: "%", setting: true, filter: {} }
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      dates: [],
      date: { label: "Select Date", value: "" }
    }
    this.changeDate = this.changeDate.bind(this);
    this.generate = this.generate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var dates = await pullEmployeeAllocationArchiveDates();
    this.setState({ dates: dates })
  }

  changeDate(event: SelectOptions | null) {
    if (event === null) { return; }
    this.setState({ date: event });
  }

  async generate(): Promise<void> {
    let response = await axios.get('./api/property-employee-map-archive/employee-allocations/' + this.state.date.value, { validateStatus: () => true });
    if (response.status === 200) {
      const FileDownload = require('js-file-download');
      const content = response.headers['content-disposition'];
      const startIndex = content.lastIndexOf('\'\'') + 2;
      const name = content.substring(startIndex, content.length);
      FileDownload(response.data, decodeURI(name));
    }
  }

  render(): JSX.Element {
    return (
      <>
        {RenderHeader("Generate Allocation Report")}
        <Row>
          <Col>
            <Select
              options={this.state.dates}
              styles={reactSelectBasicStyle}
              value={this.state.date}
              onChange={this.changeDate}
              menuPlacement="auto"
            />
          </Col>
          <Col>
            <input type="button" onClick={this.generate} value="Generate" className="standard-input"/>
          </Col>
        </Row>

      </>
    )
  }
}