import * as React from 'react';
import { DashboardManagementModal } from '../../CoreComponents/Modals/DashboardManagementModal';
import { GetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DashboardOrganizer, DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { MaintenanceTechSearch } from '../../ContactComponents/Widgets/MaintenanceTechSearch';
import { StudentSearchWidget } from '../../MCIComponents/AdminWidgets/StudentSearchWidget';
import { ActiveCourseWidget } from '../../MCIComponents/AdminWidgets/ActiveCoursesWidget';
import { RegisterStudentWidget } from '../../MCIComponents/AdminWidgets/RegisterStudentWidget';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { Col, Row } from 'reactstrap';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';

interface MCIInstructorDashboardState {
  showModal: boolean;
  renderSettings: RenderSettings;
  widgetList: Array<SelectOptions>;
  tabList: Array<JSX.Element>;
}

export class MCIInstructorDashboard extends React.Component<{}, MCIInstructorDashboardState> {
  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  settingsString: string = "mciInstructorDashboard";

  constructor(props: {}) {
    super(props);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal: boolean = false;
    if (settings == null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      showModal: showModal,
      renderSettings: settings,
      tabList: [],
      widgetList: []
    }
    this.updateUI = this.updateUI.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDashboard();
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI);
    }
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  async prepareDashboard(): Promise<void> {
    let tabList = [
      <MaintenanceTechSearch />,
      <StudentSearchWidget />,
      <ActiveCourseWidget />,
      <RegisterStudentWidget />
    ];
    let widgetList = [
      { label: "Maintenance Tech Search", value: "0" },
      { label: "Student Search", value: "1" },
      { label: "Active Courses", value: "2" },
      { label: "Register Student", value: "3" }
    ];
    this.setState({
      widgetList: widgetList,
      tabList: tabList
    })
  }

  render(): JSX.Element {
    return (
      <>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>MCI Instructor Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        <Row>
          {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
        </Row>
      </>
    )
  }
}