import { SelectCourseOptions, SelectOptions } from "../../interfaces/CoreInterfaces"
import { FilterAndSettingField } from "../CoreComponents/interfaces"

export const Categories: Array<SelectOptions> = [
  { label: "Maintenance Safety", value: "0" },
  { label: "Maintenance Fundementals", value: "1" },
  { label: "Grounds & Landscaping", value: "2" },
  { label: "Advanced Maintenance Skills", value: "3" },
  { label: "Plumbing Skills", value: "4" },
  { label: "Electrical Skills", value: "5" },
  { label: "Appliance Skills", value: "6" },
  { label: "Heating & Cooling", value: "7" },
  { label: "Windows & Doors", value: "8" },
  { label: "Walls & Ceilings", value: "9" },
  { label: "Floors & Stairs", value: "10" },
  { label: "Cabinets & Countertops", value: "11" },
  { label: "Finish Carpentry", value: "12" },
  { label: "Roofing & Gutters", value: "13" },
  { label: "Siding", value: "14" },
  { label: "Masonry", value: "15" },
  { label: "Decks, Patios, & Walks", value: "16" }
]

export const CategoryList = [
  "Maintenance Safety",
  "Maintenance Fundementals",
  "Grounds & Landscaping",
  "Advanced Maintenance Skills",
  "Plumbing Skills",
  "Electrical Skills",
  "Appliance Skills",
  "Heating & Cooling",
  "Windows & Doors",
  "Walls & Ceilings",
  "Floors & Stairs",
  "Cabinets & Countertops",
  "Finish Carpentry",
  "Roofing & Gutters",
  "Siding",
  "Masonry",
  "Decks, Patios, & Walks"
]

export interface CourseData extends SelectOptions {
  label: string;
  value: string;
  order: number;
}

export interface StudentData extends SelectOptions {
  value: string;
  label: string;
  email: string;
  rootUID: string;
  grade: number;
  failed: boolean;
}

//#region ActiveCourseList
export interface ActiveCourseCourseListState {
  uid: string;
  course: SelectCourseOptions;
  activeCourses: Array<SelectCourseOptions>;
  displayCourseList: Array<SelectCourseOptions>;
  courseHeaders: Array<FilterAndSettingField>;
  isAdding: boolean;
  hasStarted: boolean;
  style: MCIActiveCourseListStyleProps;
  courseListCollapsed: boolean;
}

export interface ActiveCourseCourseListProps {
  uid: string;
  hasStarted: boolean;
}

//Styles we are setting as any
export interface MCIActiveCourseListStyleProps {
  courseListDiv: any;
}
//#endregion

//#region MCICourseManagerSettings
export interface MCIActiveCourseManagerSettingsProps {
  uid: string;
  instructor: SelectOptions;
  hasStarted: boolean;
  courseName: string;
  startDate: Date;
  endDate: Date;
}

export interface MCIActiveCourseManagerSettingsState {
  uid: string;
  instructors: Array<any>;
  instructor: SelectOptions;
  settingsHeaders: Array<FilterAndSettingField>;
  hasStarted: boolean;
  courseName: string;
  startDate: Date;
  endDate: Date;
  style: MCIActiveCourseManagerSettingsStyleProps;
}

export interface MCIActiveCourseManagerSettingsStyleProps {
  courseListDiv: any;
}
//#endregion

//#region MCIActiveCourseStudentList

export interface MCIStudent {
  name: string;
  email: string;
  grade: number;
  uid: string;
  failed: boolean;
  // Student Course Map UID -> Rename to studentCourseMapUID
  rootUID: string;
}

//#endregion

//#region MCIActivesubcoursePortal
export interface MCIActiveSubcoursePortalProps {
}
export interface MCIActiveSubcoursePortalState {
  active: string;
  course: string;
  examHeaders: Array<FilterAndSettingField>;
  tableRow: Array<any>;
}
//#endregion

//#region MCICourseCreator
export interface MCICourseCreatorProps {
}
export interface MCICourseCreatorState {
  configHeaders: any;
  examHeaders: any;
  uid: string;
  courseName: string;
  description: string;
  category: SelectOptions;
  exams: Array<any>;
  cost: number;
  instruction: SelectOptions;
  proctoredExamUID: string;
}
//#endregion

//#region MCICourseManager
export interface MCICourseManagerProps {
}
export interface MCICourseManagerState {
  headers: any;
  courses: any;
  filterSettings: any;
}
//#endregion

//#region MCIExamEditor
export interface MCIExamEditorProps {
}
export interface MCIExamEditorState {
  uid: string;
  questions: Array<any>;
  title: string;
  weight: number;
  isFinal: boolean;
  courseUID: string;
  style: MCIExamEditorStyle;
}

export interface MCIExamEditorStyle {
  examEditorTableCol: any;
  examEditorTableRow: any;
  examEditorRightSideBar: any;
  examEditorRightSideBarTitleText: any;
  examEditorRightSideBarLabelText: any;
}
//#endregion

//#region MCIExamQuestions
export interface QuestionProps {
  idx: number;
  questionKey: number;
  question: Question;
  save: (idx: number, question: any) => void;
  move: (prevIdx: number, newIdx: number) => void;
  remove: (index: number) => void;
}

export interface NonMatchingQuestionState {
  index: number;
  question: Question;
  fileInputKey: string;
  key: number;
  save: (idx: number, question: any) => void;
  move: (prevIdx: number, newIdx: number) => void;
  draggable: boolean;
}

export interface Question {
  type: number;
  key: number;
  question: string;
  hintText: string;
  imageUID: string;
  choices: Array<Choices>;
  weight: number;
  image: string;
}

export interface ExamQuestion {
  type: number;
  uid: string;
  question: string;
  hintText: string;
  imageUID: string;
  choices: Array<Choices>;
  weight: number;
  image: string;
  answers: Array<any>;
}

export interface Choices {
  label: string;
  isCorrect: boolean;
  imageUID: string;
  fakeImg: undefined | any;
}

export interface MatchingQuestionState {
  index: number;
  key: number;
  question: Question;
  save: (idx: number, question: any) => void;
  move: (prevIdx: number, newIdx: number) => void;
  firstInputKey: string;
  secondInputKey: string;
  thirdInputKey: string;
  fourthInputKey: string;
  draggable: boolean;
}
//#endregion

//#region MCIStudentManager
export interface MCIStudentManagerProps {
}
export interface MCIStudentManagerState {
  headers: any;
  rows: Array<any>;
}

export interface StudentProfile {
  key: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  companyName: string;
  supervisorName: string;
  supervisorEmail: string;
  propertyName: string;
  propertyEmail: string;
}
//#endregion

//#region MCIStudentProfile

export interface MCIStudentProfileStyleInterface {
  mciStudentImage: any;
  mciWhiteTextTitle: any;
  mciWhiteText: any;
  mciStudentSelectedTab: any;
  mciStudentNotSelectedTab: any;
  mciStudentSelectedIcon: any;
  mciStudentNotSelectedIcon: any;
  mciStudentSelectedButton: any;
  mciStudentNotSelectedButton: any;
  mciStandardLogo: any;
  mciStudentActiveCourseRow: any;
  mciStudentProfileTableHeader: any;
  mciStudentProfileTableFirstCol: any;
  mciStudentProfileTableRegularCol: any;
  mciBlueSmallTitle: any;
  mciStudentSettingsLabelText: any;
}
//#endregion

//#region MCIProfileModules

export interface MCICourseDataInterface {
  uid: string;
  course: string;
  courseName: string;
  instructorName: string;
  grade: number;
  hasStarted: boolean;
}

export interface MCIRecentGradesModuleProps {
}
export interface MCIRecentGradesModuleState {
}

//#endregion

//#region MCIExamForm
export interface MCIExamFormProps {
}
export interface MCIExamFormState {
  preview: boolean,
  examUID: string;
  studentUID: string;
  questions: Array<ExamQuestion>;
  questionsLoaded: boolean;
  submitted: boolean;
  grading: boolean;
  grade: number;
  activeCourseUID: string;
  questionIndex: number;
  style: MCIExamFormStyle;
}
export interface MCIExamFormStyle {
  mciSignUpConfirmBox: any;
  mciSignUpConfirmLogo: any;
  mciSignUpConfirmText: any;
  mciSignUpButton: any;
}

//#region MCIExamQuestions
export interface MCIBooleanQuestionProps {
  question: ExamQuestion;
  questionIndex: number;
  setQuestion: (e: ExamQuestion, i: number) => void;
}
export interface MCIBooleanQuestionState {
  question: ExamQuestion;
  questionIndex: number;
}

export interface MCIChoiceQuestionProps {
  question: ExamQuestion;
  questionIndex: any;
  setQuestion: (e: ExamQuestion, i: number) => void;
}
export interface MCIChoiceQuestionState {
  question: ExamQuestion;
  questionIndex: number;
  answer: number;
  choices: Array<Choices>;
}

export interface MCIStringQuestionProps {
  question: ExamQuestion;
  questionIndex: number;
  setQuestion: (e: ExamQuestion, i: number) => void;
}
export interface MCIStringQuestionState {
  question: ExamQuestion;
  questionIndex: number;
}

export interface MCIMatchingQuestionProps {
  question: ExamQuestion;
  questionIndex: number;
  setQuestion: (e: ExamQuestion, i: number) => void;
}
export interface MCIMatchingQuestionState {
  question: ExamQuestion;
  questionIndex: number;
  options: Array<any>;
}

export interface MCIChecklistQuestionProps {
  question: ExamQuestion;
  questionIndex: number;
  setQuestion: (e: ExamQuestion, i: number) => void;
}
export interface MCIChecklistQuestionState {
  question: ExamQuestion;
  questionIndex: number;
}


//#endregion

//#endregion

//#region MCIProctoredExamAdministration
export interface MCIProctoredExamAdministrationProps {
}
export interface MCIProctoredExamAdministrationState {
  courses: Array<SelectOptions>;
  students: Array<SelectOptions>;
  course: SelectOptions;
  student: SelectOptions;
  activeUID: string;
  currentQuestionIndex: number;
  currentPictureIndex: number;
  questions: Array<any>;
  imageURL: string;
}
//#endregion

//#region MCIStudentCourseHome
export interface MCIStudentcourseHomeProps {
}
export interface MCIStudentcourseHomeState {
  student: string;
  course: string;
  studentName: string;
  courseName: string;
  exams: Array<any>;
  courseGrade: number;
  proctoredExamGrade: number;
}
//#endregion

//#region MCIStudentInformationForm
export interface MCIStudentInformationFormProps {
}
export interface MCIStudentInformationFormState {
  firstName: string;
  firstNameClass: any;
  lastName: string;
  lastNameClass: any;
  email: string;
  emailClass: string;
  companyName: string;
  companyNameClass: any;
  supervisorName: string;
  supervisorEmail: string;
  propertyName: string;
  propertyAddress: string;
  submitted: boolean;
  img: string;
  imgUID: string;
  style: MCIStudentInformationFormStyle;
}
export interface MCIStudentInformationFormStyle {
  mciMainFormat: any;
  mciSignUpBox: any;
  mciSignUpConfirmBox: any;
  mciStandardLogo: any;
  mciSignUpConfirmLogo: any;
  mciBlueTextTitle: any;
  mciSignUpText: any;
  mciSignUpConfirmText: any;
  mciStandardInputRequired: any;
  mciStandardInput: any;
  mciSignUpProfilePic: any;
  mciSignUpLabelText: any;
  mciSignUpButton: any;
}
//#endregion

//#region MCIStudentPortalLogin
export interface MCIStudentPortalLoginProps {
}
export interface MCIStudentPortalLoginState {
  studentName: string;
  _key: string;
  style: MCIStudentPortalLoginStyle
}
export interface MCIStudentPortalLoginStyle {
  mciMainFormat: any;
  mciStudentLoginBox: any;
  mciBlueTextTitle: any;
  mciStandardLogo: any;
  mciStudentLoginInput: any;
  mciStudentLoginLabelText: any;
  mciStudentLoginButton: any;
}
//#endregion