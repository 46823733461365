//#region Imports
import * as React from "react";
//@ts-ignore
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';
//import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { reactSelectBasicStyle } from "../../style/select-constants";
import Select from 'react-select';
import WaterFeaturesTable from "./NofaComponents/WaterFeatureComponents";
import { NumberedMarker } from "./NofaComponents/TableRowConsts";
import { HaversineDistance } from "../../functions/MapFunctions";
import { ServicesTable } from "./NofaComponents/ServicesTableComponent";
import { getBearerToken } from "../../functions/authActions";
import { SelectOptionsNofaTool } from "../../interfaces/CoreInterfaces";
import { ToTitleCase } from "../../functions/stringTools";
import { WarningModal } from "../CoreComponents/Modals";
//#endregion

interface NofaMapperProps {

}

interface PropertyMarkerBorderData {
  lat: number;
  lng: number;
  name: number;
}

interface NofaMapperState {
  center: CenterInterface;
  tmpCenter: CenterInterface;
  markers: Array<any>;
  water: Array<any>;
  forceWater: Array<any>;
  properties: Array<SelectOptionsNofaTool>;
  property: SelectOptionsNofaTool;
  drawing: boolean;
  propertyMarkers: Array<PropertyMarkerBorderData>;
  currentPolygon: any;
  listener: any;
  showPropertyMarker: boolean;
  servicesKey: string;
  waterKey: string;
  borderIndex: number;
  borderCoords: CenterInterface;
  zoom: string;
}

interface CenterInterface {
  lat: number;
  lng: number;
}

export class NofaMapper extends React.Component<NofaMapperProps, NofaMapperState> {

  _domMap = React.createRef<any>();
  map: any;

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: NofaMapperProps) {
    super(props);
    this.state = {
      center: {
        lat: 45.425280,
        lng: -117.275910
      },
      // This property ensures when we move the center that we don't over update it, making sure it can be moved correctly.
      tmpCenter: {
        lat: 45.425280,
        lng: -117.275910
      },
      markers: [],
      water: [],
      forceWater: [],
      properties: [],
      property: {
        label: "Select Property",
        value: "",
        lat: 45.425280,
        lng: -117.275910,
        name: "Select Property",
        type: 0
      },
      drawing: false,
      propertyMarkers: [],
      currentPolygon: null,
      listener: null,
      showPropertyMarker: false,
      servicesKey: Math.random().toFixed(6),
      waterKey: Math.random().toFixed(6),
      borderCoords: { lat: 0, lng: 0 },
      borderIndex: 0,
      zoom: "13"
    }
    this.prepareExport = this.prepareExport.bind(this);
    this.drawBounds = this.drawBounds.bind(this);
    this.stopDrawing = this.stopDrawing.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.updateWater = this.updateWater.bind(this);
    this.updateForcedWater = this.updateForcedWater.bind(this);
    this.FilterResponse = this.FilterResponse.bind(this);
    this.LocateAddress = this.LocateAddress.bind(this);
    this.PushActive = this.PushActive.bind(this);
    this.PushEmpty = this.PushEmpty.bind(this);
    this.Search = this.Search.bind(this);
    this.SearchWater = this.SearchWater.bind(this);
    this.PullData = this.PullData.bind(this);
    this.redrawPolygon = this.redrawPolygon.bind(this);
    this.dropPropertyBorderMarker = this.dropPropertyBorderMarker.bind(this);
    this.setZoom = this.setZoom.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const arr: Array<SelectOptionsNofaTool> = [];
    let response = await axios.get("./api/property/", { validateStatus: () => true });
    if (response.status === 200) {
      response.data.forEach(function (property: any) {
        arr.push({
          lat: parseFloat(property.latitude),
          lng: parseFloat(property.longitude),
          name: property.name,
          type: property.type,
          label: property.name,
          value: property.uid
        })
      });
      this.setState({ properties: arr })
    }
  }

  updateProperty(event: SelectOptionsNofaTool | null): void {
    if (event === null) { return; }
    this.setState({
      property: event,
      markers: [],
      water: []
    }, () => this.LocateAddress())
  }

  setZoom(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ zoom: event.target.value });
  }

  LocateAddress(): void {
    let property = this.state.property;
    let lat: number = property.lat;
    let lng: number = property.lng;
    this.setState({ center: { lat: lat, lng: lng } })
    //@ts-ignore
    let icon = { url: "https://freesvg.org/img/nlyl-blue-circle.png", scaledSize: new window.google.maps.Size(14, 14) }
    //@ts-ignore
    let newMarker = new window.google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: this.map,
      draggable: true,
      icon: icon,
    });
    newMarker.addListener("drag", (event: any) => {
      let _lat = event.latLng.lat();
      let _lng = event.latLng.lng();
      this.setState({
        tmpCenter: {
          lat: _lat,
          lng: _lng
        }
      })
    });
    newMarker.addListener("mouseup", () => {
      let property = this.state.property;
      property.lat = this.state.tmpCenter.lat;
      property.lng = this.state.tmpCenter.lng;
      this.map.panTo(this.state.tmpCenter);
      this.setState({
        center: this.state.tmpCenter,
        property: property
      }, () => this.PullData());
    });
    this.map.panTo({ lat: lat, lng: lng });
    this.PullData();
  }

  FilterResponse(response: Array<any>, filterStrings: Array<string>, filterOutStrings: Array<string>, items: any, location: any): void {
    let first = true;
    let closest = null;
    for (let i = 0; i < response.length; ++i) {
      let current = response[i];
      let filter = current.types.filter((item: string) => filterStrings.length === 0 || filterStrings.includes(item));
      let filterOut = current.types.filter((item: string) => filterOutStrings.includes(item));
      if (filter.length > 0 && filterOut.length === 0) {
        if (first) {
          first = false;
          closest = response[i];
        }
        let lat = current.geometry.location.lat();
        let lng = current.geometry.location.lng();
        let distance = HaversineDistance(location, { lat: lat, lng: lng });
        items.push({
          name: ToTitleCase(current.name),
          lat: lat,
          lng: lng,
          index: items.length,
          distance: distance,
        })
      }
    }
    return closest;
  }

  PushEmpty(markers: any, index: any, comments: any): void {
    markers.push({
      name: "None In Proximity",
      lat: 0,
      lng: 0,
      index: index,
      distance: 0,
      comments: comments,
      currentIndex: 0,
      // create only option
      items: [{
        name: "None In Proximity",
        lat: 0,
        lng: 0,
        index: index,
        distance: 0,
        comments: comments,
        key: Math.random()
      }],
      override: ''
    });
    this.setState({
      markers: markers,
      servicesKey: Math.random().toFixed(8),
      waterKey: Math.random().toFixed(8)
    })
  }

  PushActive(markers: any, index: any, comments: any, closest: any, stores: any): void {
    let lat = closest.geometry.location.lat();
    let lng = closest.geometry.location.lng();
    let distance = HaversineDistance({ lat: this.state.property.lat, lng: this.state.property.lng }, { lat: lat, lng: lng });
    markers.push({
      name: ToTitleCase(closest.name),
      lat: lat,
      lng: lng,
      index: index,
      distance: distance,
      comments: comments,
      items: stores,
      currentIndex: 0,
      override: '',
      key: Math.random()
    });
    this.setState({
      markers: markers,
      servicesKey: Math.random().toFixed(8),
      waterKey: Math.random().toFixed(8)
    })
  }

  Search(keyword: string, index: number, comment: string, filterIn: Array<string>, filterOut: Array<string>): void {
    let item = this.state.center;
    let location = { lat: item.lat, lng: item.lng };
    //@ts-ignore
    let service = new window.google.maps.places.PlacesService(this.map);
    let request: any = {
      keyword: keyword,
      //@ts-ignore
      rankBy: window.google.maps.places.RankBy.DISTANCE,
      location: location
    }
    let markers = this.state.markers;
    service.nearbySearch(request, (response: any) => {
      if (response === null) {
        this.Search(keyword, index, comment, filterIn, filterOut);
        return;
      }
      if (response.length === 0) {
        this.PushEmpty(markers, index, comment);
        return;
      }
      let stores: any[] = [];
      let closest = this.FilterResponse(
        response,
        filterIn,
        filterOut,
        stores,
        location
      );
      this.PushActive(markers, index, comment, closest, stores);
    })
  }

  SearchWater(keyword: string, filterIn: Array<string>, filterOut: Array<string>) {
    let item = this.state.center;
    let location = { lat: item.lat, lng: item.lng };
    //@ts-ignore
    let service = new window.google.maps.places.PlacesService(this.map);
    let request = {
      keyword: keyword,
      //@ts-ignore
      rankBy: window.google.maps.places.RankBy.DISTANCE,
      location: location
    }
    let water = this.state.water;
    service.nearbySearch(request, (response: any) => {
      if (response === null) {
        this.SearchWater(keyword, filterIn, filterOut);
        return;
      }
      let closest = null;
      let count = 1;
      for (let i = 0; i < response.length; ++i) {
        closest = response[i];
        let filter = closest.types.filter((item: string) => filterIn.includes(item));
        let exclude = closest.types.filter((item: string) => filterOut.includes(item));
        let lat = closest.geometry.location.lat();
        let lng = closest.geometry.location.lng();
        let distance = HaversineDistance(location, { lat: lat, lng: lng });
        if (filter.length > 0 && distance < 2 && exclude.length === 0) {
          water.push({
            index: water.length,
            name: closest.name,
            lat: lat,
            lng: lng,
            distance: distance
          });
          count++;
        }
      }
      water = water.sort(function (a, b) { return a.distance - b.distance });
      for (let i = 0; i < water.length; ++i) {
        water[i].index = i + 1;
      }
      this.setState({
        water: water,
        servicesKey: Math.random().toFixed(8),
        waterKey: Math.random().toFixed(8)
      })
    });
  }

  PullData(): void {
    this.setState({ water: [], markers: [] }, () => this.PullSiteList());
  }

  PullSiteList(): void {
    this.Search('airport', 1, "Airport", ['airport'], []);
    this.Search('grocery', 2, 'Full-Service Grocery Store', ['grocery_or_supermarket'], ['gas_station', 'convenience_store']);
    this.Search('bank', 3, "Full-Service Bank", ['bank'], []);
    this.Search('worksource', 4, 'Worksource Center', [], []);
    this.Search('factory', 5, 'Industrial Facilities', [], []);
    this.Search('public transit', 6, 'Train Station', ['transit_station'], []);
    this.Search('bus_station', 7, 'Bus Stop', ['bus_station'], []);
    this.Search('elementry school', 8, 'Elementary School', ['primary_school', 'school'], []);
    this.Search('school', 9, 'Junior High School', ['secondary_school', 'school'], []);
    this.Search('school', 10, 'High School', ['school'], []);
    this.Search('park', 11, 'Public Park', ['park'], ['rv_park']);
    this.Search('libary', 12, 'Public Library', [], []);
    this.Search('museum', 13, 'Museum', [], []);
    this.Search('senior center', 14, 'Senior Center', [], []);

    let keyword = this.state.property.type == 1 ? 'dhs senior and disabled services' : 'dhs self sufficiency office';
    let comments = this.state.property.type == 1 ? "Senior Services" : "DHS Self Sufficiency";

    this.Search(keyword, 15, comments, [], []);
    this.Search('police station', 16, 'Police Department', ['police'], []);
    this.Search('fire department', 17, 'Fire Department', ['fire_station'], []);
    this.Search('hospital', 18, 'Medical Clinic', ['hospital'], []);
    this.Search('hospital', 19, 'Hospital', ['hospital'], []);
    this.PullWaterList();
  }

  PullWaterList(): void {
    this.SearchWater('river', ['natural_feature', 'park'], [])
    this.SearchWater('lake', ['natural_feature', 'park'], ['reservior', 'lagoon']);
    this.SearchWater('wetlands', ['natural_feature', 'park'], []);

  }

  drawBounds(): void {
    this.map.setZoom(17);
    let listener = this.map.addListener("click", (e: any) => this.drawClickListenerCallback(e));
    this.setState({
      drawing: true,
      showPropertyMarker: true,
      listener: listener
    });
  }

  //#region Polygon Drawing Functions
  drawClickListenerCallback(event: any): void {
    let markers = this.state.propertyMarkers;
    let icon = {
      url: "https://upload.wikimedia.org/wikipedia/commons/0/01/SVG_Circle.svg",
      //@ts-ignore
      scaledSize: new window.google.maps.Size(5, 5)
    }
    let name = Math.random();
    //@ts-ignore
    let newMarker = new window.google.maps.Marker({
      position: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      map: this.map,
      draggable: true,
      icon: icon,
    });
    markers.push({ lat: event.latLng.lat(), lng: event.latLng.lng(), name: name });
    newMarker.set('name', name);
    newMarker.addListener("drag", (event: any) => this.dragPropertyBorderMarker(event, name));
    newMarker.addListener("mouseup", () => this.dropPropertyBorderMarker());
    this.setState({
      propertyMarkers: markers
    }, () => this.redrawPolygon());
  }

  dragPropertyBorderMarker(event: any, name: number) {
    let tmp = this.state.propertyMarkers;
    let index = tmp.findIndex(function (item) { return item.name === name });
    let lat = event.latLng.lat();
    let lng = event.latLng.lng();
    this.setState({
      borderCoords: { lat: lat, lng: lng },
      borderIndex: index
    })
  }

  dropPropertyBorderMarker() {
    let tmp = this.state.propertyMarkers;
    tmp[this.state.borderIndex] = {
      lat: this.state.borderCoords.lat,
      lng: this.state.borderCoords.lng,
      name: tmp[this.state.borderIndex].name
    }
    this.setState({
      propertyMarkers: tmp,
      borderCoords: { lat: 0, lng: 0 },
      borderIndex: 0
    }, () => this.redrawPolygon())
  }

  redrawPolygon(): void {
    const bounds = [];
    for (let i = 0; i < this.state.propertyMarkers.length; ++i) {
      let curr = this.state.propertyMarkers[i];
      bounds.push({
        lat: curr.lat,
        lng: curr.lng
      })
    }
    bounds.push({
      lat: this.state.propertyMarkers[0].lat,
      lng: this.state.propertyMarkers[0].lng
    })
    //@ts-ignore
    let outline = new window.google.maps.Polygon({
      paths: bounds,
      strokeColor: "green",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: "green",
      fillOpacity: 0.5
    });
    if (this.state.currentPolygon !== null) {
      this.state.currentPolygon.setMap(null);
    }
    this.setState({
      currentPolygon: outline
    })
    outline.setMap(this.map);
  }

  stopDrawing(): void {
    this.map.setZoom(13);
    //@ts-ignore
    window.google.maps.event.clearListeners(this.map, 'click');
    this.setState({
      drawing: false,
      listener: null,
      showPropertyMarker: false
    })
  }
  //#endregion

  updateWater(water: Array<any>): void {
    this.setState({
      water: water
    })
  }

  updateForcedWater(water: Array<any>): void {
    this.setState({
      //@ts-ignore
      forcedWater: water
    })
  }
  /**
   * This desperately needs to be reduced in size by refactoring. Not for Today (03/29/2021)
   */
  async prepareExport(): Promise<void> {
    if (this.state.propertyMarkers.length === 0) {
      this.warningModal.current.show("Please draw property boundaries before requesting the exported files", "Property Boundary Required");
      return;
    }
    let markers = this.state.markers;
    let water = this.state.water;
    let forceWater = this.state.forceWater;
    let property = this.state.property;

    // This generates the baseURL for The Google Maps Request
    let url = this.generateBaseUrl();
    url = this.generatePropertyBounds(url);
   
    let markerUrl = url;
    let waterUrl = url;
    let topoUrl = url;
    let servicesOut = [];
    for (let i = 0; i < markers.length; i++) {
      let marker = markers[i];
      let index = String.fromCharCode(marker.index + 64);
      markerUrl += "&markers=color:black|size:medium|label:" + index + "|" + markers[i].lat + "," + markers[i].lng;
      servicesOut.push({
        index: marker.index,
        name: marker.override === '' ? marker.name : marker.override,
        distance: marker.distance,
        comments: marker.comments
      })
    }
    let waterOut = [];
    for (let i = 0; i < water.length; i++) {
      let _water = water[i];
      let index = String.fromCharCode(_water.index + 64);
      waterUrl += "&markers=color:blue|size:medium|label:" + index + "|" + _water.lat + "," + _water.lng;
      waterOut.push({
        index: _water.index,
        name: _water.name,
        distance: _water.distance,
        comments: _water.comments
      })
    }
    for (let i = 0; i < forceWater.length; i++) {
      let customWater = forceWater[i];
      let index = String.fromCharCode(customWater.index + 64);
      waterUrl += "&markers=color:blue|size:medium|label:" + index + "|" + customWater.lat + "," + customWater.lng;
      waterOut.push({
        index: customWater.index,
        name: customWater.name,
        distance: customWater.distance,
        comments: customWater.comments
      })
    }
    let nearBy = encodeURI(markerUrl.replace("{ZOOM}", this.state.zoom));
    waterUrl = encodeURI(waterUrl.replace("{ZOOM}", this.state.zoom));
    topoUrl = encodeURI(topoUrl.replace("roadmap", "terrain").replace("{ZOOM}", this.state.zoom));
    let key = this.state.property.value;
    property = this.state.properties.filter(function (item) { return item.value === key })[0]; // get property object
    const data = {
      PropertyId: property.value,
      VicinityUrl: nearBy,
      WaterUrl: waterUrl,
      TopoUrl: topoUrl,
      Services: servicesOut,
      WaterFeatures: waterOut
    }
    let response = await axios.post('./api/manual/nofa-mapping-tool', data, { responseType: 'blob' });
    if (response.status === 200) {
      let FileDownload = require('js-file-download');
      FileDownload(response.data, "NofaMaps.zip");
    }
  }

  generateBaseUrl(): string {
    let url = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDF_xQZ5PZZnYBNn-V4-btiQD3Az9zJ3Qo";
    url += "&center=" + this.state.property.lat + "," + this.state.property.lng;
    url += "&size=640x640";
    url += "&maptype=roadmap";
    url += "&zoom={ZOOM}&scale=2";
    url += "&path=color:purple|weight:3|fillcolor:purple";
    return url;
  }

  generatePropertyBounds(url: string): string {
    let markers = this.state.propertyMarkers;
    for (let i = 0; i < markers.length; ++i) {
      let curr = markers[i];
      url += "|" + curr.lat + "," + curr.lng;
    }
    url += "|" + markers[0].lat + "," + markers[0].lng;
    return url;
  }

  render(): JSX.Element {
    return (
      <div id="nofa-map-tool">
        <WarningModal ref={this.warningModal} />
        <Row style={{ maxHeight: "86vh", minHeight: "86vh" }}>
          <Col style={{ marginLeft: "-20px" }}>
            <Select
              onChange={this.updateProperty}
              options={this.state.properties}
              value={this.state.property}
              styles={reactSelectBasicStyle}
            />
            <hr style={{ height: "5px", padding: "0px", marginTop: "5px", marginBottom: "5px" }} />
            <div id="property-map" style={{ height: '45vh', width: '100%', border: "3px solid #c2a877", margin: "0px", padding: "0px" }} ref={this._domMap}>
              <GoogleMapReact
                key="map"
                bootstrapURLKeys={{
                  key: "AIzaSyDF_xQZ5PZZnYBNn-V4-btiQD3Az9zJ3Qo",
                  libraries: ['places']
                }}
                center={this.state.property}
                defaultZoom={13}
                //@ts-ignore
                onGoogleApiLoaded={({ map, maps }) => {
                  this.map = map;
                }}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  disableDefaultUI: true,
                  mapTypeId: "terrain",
                  mapTypeControl: true,
                  streetViewControl: true,
                  scaleControl: true,
                  fullscreenControl: true,
                  mapTypeControlOptions: {
                    style: 2,
                    mapTypeIds: [
                      "terrain",
                      "satellite",
                      "roadmap"
                    ]
                  }
                }}
              >
                {
                  this.state.markers.map((item) => (
                    <NumberedMarker key={item.index + item.name} lat={item.lat} lng={item.lng} name={item.name} index={item.name !== 'property' ? item.index : null}
                      color={item.color !== undefined ? item.color : "black"} />
                  ))
                }
                {
                  this.state.water.map((item) => (
                    <NumberedMarker key={item.index + item.name} lat={item.lat} lng={item.lng} name={item.name} index={item.name !== 'property' ? item.index : null}
                      color={item.color !== undefined ? item.color : "blue"} />
                  ))
                }
                {
                  this.state.forceWater.filter(function (item) { return item.name !== 'property' }).map((item) => (
                    <NumberedMarker key={item.index + item.name} lat={item.lat} lng={item.lng} name={item.name} index={item.name !== 'property' ? item.index : null}
                      color={item.color !== undefined ? item.color : "purple"} />
                  ))
                }
              </GoogleMapReact>
              <hr style={{ height: "5px", padding: "0px", marginTop: "10px", marginBottom: "7px" }} />
              <Row>
                <Col>
                  <h5 style={{ marginTop: "1vh"}}>Zoom</h5>
                </Col>
                <Col>
                  <input type="number" step="1" value={this.state.zoom} className="standard-input" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setZoom(e)} />
                </Col>
              </Row>
              <hr style={{ height: "5px", padding: "0px", marginTop: "10px", marginBottom: "7px" }} />
              {!this.state.drawing ?
                <input type="button" value="Draw Property Boundary" className="standard-input" style={{ width: "100%" }} onClick={this.drawBounds} />
                : <input type="button" value="Finish Property Boundary" className="standard-input" style={{ width: "100%" }} onClick={this.stopDrawing} />
              }
              <hr style={{ height: "5px", padding: "0px", marginTop: "7px", marginBottom: "7px" }} />
              <input type="button" value="Generate Documents" className="standard-input" onClick={this.prepareExport} />
            </div>
          </Col>
          <Col>
            <Row>
              <Col>
                <ServicesTable key={this.state.servicesKey} markers={this.state.markers} />
              </Col>
            </Row>
            <hr style={{ height: "5px", padding: "0px", marginTop: "15px", marginBottom: "3px" }} />
            <Row>
              <Col>
                <WaterFeaturesTable key={this.state.waterKey} map={this.map} water={this.state.water} forcedWater={this.state.forceWater}
                  property={this.state.property} center={this.state.center} updateWater={this.updateWater} updateForcedWater={this.updateForcedWater}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>

        </Row>
      </div>
    )
  }
}
