import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { EMPTY_GUID } from '../../constants/DefaultConstants';
import { pullEmployeesByCompany, pullEntities, pullProperties } from '../../functions/fetchLinkedObjects';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../style/select-constants';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import DistributionRecordCollector from './DistributionRecordCollector';
import * as Structs from './DistributionRecordCollector.modals.constants';
import { getUserID } from '../../functions/authActions';

export class ManagePropertyEmployeeModal extends React.Component<{}, Structs.ManagePropertyEmployeeModalState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      employee: { label: "", value: "" },
      employees: [],
      properties: [],
      payrollID: 0,
      rows: []
    }
    this.addRow = this.addRow.bind(this);
    this.changePayrollID = this.changePayrollID.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
    this.changeSiteRole = this.changeSiteRole.bind(this);
    this.changeDistribution = this.changeDistribution.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.saveDistributions = this.saveDistributions.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
  }

  async show(employeeName: string, employeeUID: string, payrollID: number, company: number): Promise<void> {
    let properties = await pullProperties();
    let employees = await pullEmployeesByCompany(company);
    let response;
    if (employeeUID !== "") {
      response = (await axios.get('./api/property-employee-map/employee/' + employeeUID, { validateStatus: () => true })).data;
    }
    else {
      response = [];
    }
    this.setState({
      showing: true,
      employee: { label: employeeName, value: employeeUID },
      employees: employees,
      properties: properties,
      payrollID: payrollID,
      rows: response
    });
  }

  getHeaders(): Array<FilterAndSettingField> {
    const headers = [
      { columnName: "Property", setting: true, filter: {} },
      { columnName: "Distribution", setting: true, filter: {} },
      { columnName: "Role", setting: true, filter: {} },
    ]
    return headers;
  }

  changePayrollID(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ payrollID: parseInt(event.target.value) });
  }

  changeProperty(event: SelectOptions, idx: number) {
    let row = this.state.rows[idx];
    row.property = event;
    this.state.rows[idx] = row;
    this.setState({});
  }

  changeEmployee(event: SelectOptions | null) {
    this.setState({
      employee: event
    })
  }

  changeSiteRole(event: SelectOptions, idx: number) {
    let row = this.state.rows[idx];
    row.siteRole = event;
    this.state.rows[idx] = row;
    this.setState({});
  }

  changeDistribution(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.distribution = parseFloat(event.target.value);
    this.state.rows[idx] = row;
    this.setState({});
  }

  addRow(): void {
    let rows: Array<Structs.ManagePropertyEmployeeModalRow> = this.state.rows;
    rows.push({
      uid: "",
      property: { label: "Select Property", value: "" },
      distribution: 0,
      siteRole: { label: "N/A", value: "-1" }
    });
    this.setState({ rows: rows });
  }

  removeRow(idx: number): void {
    let rows: Array<Structs.ManagePropertyEmployeeModalRow> = this.state.rows;
    rows.splice(idx, 1);
    this.setState({ rows: rows });
  }

  async saveDistributions(): Promise<void> {
    const data: any = {
      Employee: this.state.employee.value,
      PayrollID: this.state.payrollID,
      Modifier: getUserID(),
      Changes: []
    }
    this.state.rows.forEach((item) => {
      data.Changes.push({
        uid: item.uid === "" ? EMPTY_GUID : item.uid,
        linkedEntity: item.property.value,
        distribution: item.distribution,
        role: item.siteRole.value
      })
    })
    let response = await axios.put('./api/property-employee-map/employee/', data, { validateStatus: () => true });
    if (response.status === 202) {
      window.location.reload();
    }
    else {
      // TODO: ERROR HANDLING
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Modal isOpen={this.state.showing} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="lg" toggle={() => this.setState({ showing: false })}>
          <ModalHeader tag='h3' style={{ backgroundColor: "#4C4A42" }}>
            <b style={{ color: "#d7c7a7" }}>{this.state.employee.value === "" ? "Add Employee" : "Edit " + this.state.employee.label + "'s Allocations"}</b>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "#4C4A42" }}>
            <Row>
              <Col>
                <label>Employee</label>
                <br />
                {this.state.employee.value === "" ? <Select
                  options={this.state.employees}
                  styles={reactSelectBasicStyle}
                  value={this.state.employee}
                  onChange={this.changeEmployee}
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
                  :
                  <label>{this.state.employee.label}</label>
                }
              </Col>
              <Col>
                <label>Payroll ID</label>
                <br />
                <input type="text" className="standard-input" value={this.state.payrollID} onChange={this.changePayrollID} />
              </Col>
              <Col>
                <label>Company</label>
                <br />
                <label>vmi</label>
              </Col>
            </Row>
            <hr />
            <div style={{ minHeight: "50vh", maxHeight: "50vh", overflowY: "auto", overflowX: "hidden" }}>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={1} />
                </thead>
                <tbody>
                  {
                    this.state.rows.map((item, idx) => (
                      <tr key={item.uid}>
                        <td className="fixedTableCellFirst">
                          <Select
                            options={this.state.properties}
                            styles={reactSelectBasicStyle}
                            value={item.property}
                            onChange={(e: SelectOptions) => this.changeProperty(e, idx)}
                            menuPlacement={idx > 7 ? "top" : "bottom" }
                          />
                        </td>
                        <td className="fixedTableCell">
                          <input type="number" value={item.distribution} className="standard-input" onChange={(e) => this.changeDistribution(e, idx)} />
                        </td>
                        <td className="fixedTableCell">
                          <Select
                            options={DistributionRecordCollector.AllocationRolesSelect}
                            styles={reactSelectBasicStyle}
                            value={item.siteRole}
                            onChange={(e: SelectOptions) => this.changeSiteRole(e, idx)}
                          />
                        </td>
                        <td className="fixedTableCell">
                          <FontAwesomeIcon icon={faTrash} onClick={() => this.removeRow(idx)} style={{ marginLeft: "20%" }} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <hr />
            <Row>
              <Col>
              </Col>
              <Col>
                <input type="button" value="Add Allocation" className="standard-input" onClick={this.addRow} />
              </Col>
            </Row>
            <hr />
            <input type="button" className="standard-input" value="Save Distributions" onClick={this.saveDistributions} />
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export class ManageEntityEmployeeModal extends React.Component<{}, Structs.ManageEntityEmployeeModalState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      employee: { label: "", value: "" },
      employees: [],
      entities: [],
      payrollID: 0,
      rows: []
    }
    this.addRow = this.addRow.bind(this);
    this.changePayrollID = this.changePayrollID.bind(this);
    this.changeEntity = this.changeEntity.bind(this);
    this.changeDistribution = this.changeDistribution.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.saveDistributions = this.saveDistributions.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
  }

  async show(employeeName: string, employeeUID: string, payrollID: number, company: number): Promise<void> {
    let entities = await pullEntities();
    let employees = await pullEmployeesByCompany(company)
    let response;
    if (employeeUID !== "") {
      response = (await axios.get('./api/entity-employee-map/entity/' + employeeUID, { validateStatus: () => true })).data;
    }
    else {
      response = [];
    }
    this.setState({
      showing: true,
      employee: { label: employeeName, value: employeeUID },
      employees: employees,
      entities: entities,
      payrollID: payrollID,
      rows: response
    });
  }

  getHeaders(): Array<FilterAndSettingField> {
    const headers = [
      { columnName: "Entity", setting: true, filter: {} },
      { columnName: "Distribution", setting: true, filter: {} },
    ]
    return headers;
  }

  changePayrollID(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ payrollID: parseInt(event.target.value) });
  }

  changeEntity(event: SelectOptions, idx: number) {
    let row = this.state.rows[idx];
    row.entity = event;
    this.state.rows[idx] = row;
    this.setState({});
  }

  changeEmployee(event: SelectOptions | null) {
    this.setState({
      employee: event
    })
  }

  changeDistribution(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.distribution = parseFloat(event.target.value);
    this.state.rows[idx] = row;
    this.setState({});
  }

  addRow(): void {
    let rows: Array<Structs.ManageEntityEmployeeModalRow> = this.state.rows;
    rows.push({
      uid: "",
      entity: { label: "Select Entity", value: "" },
      distribution: 0,
    });
    this.setState({ rows: rows });
  }

  removeRow(idx: number): void {
    let rows: Array<Structs.ManageEntityEmployeeModalRow> = this.state.rows;
    rows.splice(idx, 1);
    this.setState({ rows: rows });
  }

  async saveDistributions(): Promise<void> {
    const data: any = {
      Employee: this.state.employee.value,
      PayrollID: this.state.payrollID,
      Changes: []
    }
    this.state.rows.forEach((item) => {
      data.Changes.push({
        uid: item.uid === "" ? EMPTY_GUID : item.uid,
        linkedEntity: item.entity.value,
        distribution: item.distribution,
        role: -1
      })
    })
    let response = await axios.put('./api/entity-employee-map/entity/', data, { validateStatus: () => true });
    if (response.status === 202) {
      window.location.reload();
    }
    else {
      // TODO: ERROR HANDLING
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Modal isOpen={this.state.showing} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="lg" toggle={() => this.setState({ showing: false })}>
          <ModalHeader tag='h3' style={{ backgroundColor: "#4C4A42" }}>
            <b style={{ color: "#d7c7a7" }}>{this.state.employee.value === "" ? "Add Employee" : "Edit " + this.state.employee.label + "'s Allocations"}</b>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "#4C4A42" }}>
            <Row>
              <Col>
                <label>Employee</label>
                <br />
                {this.state.employee.value === "" ? <Select
                  options={this.state.employees}
                  styles={reactSelectBasicStyle}
                  value={this.state.employee}
                  onChange={this.changeEmployee}
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
                  :
                  <label>{this.state.employee.label}</label>
                }
              </Col>
              <Col>
                <label>Payroll ID</label>
                <br />
                <input type="text" className="standard-input" value={this.state.payrollID} onChange={this.changePayrollID} />
              </Col>
              <Col>
                <label>Company</label>
                <br />
                <label>cdi</label>
              </Col>
            </Row>
            <hr />
            <div>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={1} />
                </thead>
                <tbody>
                  {
                    this.state.rows.map((item, idx) => (
                      <tr key={item.uid}>
                        <td className="fixedTableCellFirst">
                          <Select
                            options={this.state.entities}
                            styles={reactSelectBasicStyle}
                            value={item.entity}
                            onChange={(e: SelectOptions) => this.changeEntity(e, idx)}
                          />
                        </td>
                        <td className="fixedTableCell">
                          <input type="number" value={item.distribution} className="standard-input" onChange={(e) => this.changeDistribution(e, idx)} />
                        </td>
                        <td className="fixedTableCell">
                          <FontAwesomeIcon icon={faTrash} onClick={() => this.removeRow(idx)} style={{ marginLeft: "20%" }} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <hr />
              <Row>
                <Col>
                </Col>
                <Col>
                  <input type="button" value="Add Allocation" className="standard-input" onClick={this.addRow} />
                </Col>
              </Row>
              <hr />
              <input type="button" className="standard-input" value="Save Distributions" onClick={this.saveDistributions} />
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export class ManageDistributionRecordsModal extends React.Component<{}, Structs.ManageDistRecordsEmployeeModalState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      employee: { label: "", value: "" },
      employees: [],
      entities: [],
      payrollID: 0,
      rows: []
    }
    this.addRow = this.addRow.bind(this);
    this.changePayrollID = this.changePayrollID.bind(this);
    this.changeDistribution = this.changeDistribution.bind(this);
    this.changeJobCode = this.changeJobCode.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.saveDistributions = this.saveDistributions.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
  }

  async show(employeeName: string, employeeUID: string, payrollID: number, company: number): Promise<void> {
    let employees = await pullEmployeesByCompany(company);
    let response;
    if (employeeUID !== "") {
      response = (await axios.get('./api/distribution-records/employee/' + employeeUID, { validateStatus: () => true })).data;
    }
    else {
      response = [];
    }
    this.setState({
      showing: true,
      employee: { label: employeeName, value: employeeUID },
      employees: employees,
      payrollID: payrollID,
      rows: response
    });
  }

  getHeaders(): Array<FilterAndSettingField> {
    const headers = [
      { columnName: "Code", setting: true, filter: {} },
      { columnName: "Distribution", setting: true, filter: {} },
    ]
    return headers;
  }

  changePayrollID(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ payrollID: parseInt(event.target.value) });
  }

  changeJobCode(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.code = event.target.value;
    this.state.rows[idx] = row;
    this.setState({});
  }

  changeEmployee(event: SelectOptions | null) {
    this.setState({
      employee: event
    })
  }

  changeDistribution(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.distribution = parseFloat(event.target.value);
    this.state.rows[idx] = row;
    this.setState({});
  }

  addRow(): void {
    let rows: Array<Structs.ManageDistRecordsEmployeeModalRow> = this.state.rows;
    rows.push({
      uid: "",
      distribution: 0,
      code: ""
    });
    this.setState({ rows: rows });
  }

  removeRow(idx: number): void {
    let rows: Array<Structs.ManageDistRecordsEmployeeModalRow> = this.state.rows;
    rows.splice(idx, 1);
    this.setState({ rows: rows });
  }

  async saveDistributions(): Promise<void> {
    //TODO: Check Distribution Values
    const data: any = {
      Employee: this.state.employee.value,
      PayrollID: this.state.payrollID,
      Changes: []
    }
    this.state.rows.forEach((item) => {
      data.Changes.push({
        uid: item.uid === "" ? EMPTY_GUID : item.uid,
        code: item.code,
        distribution: item.distribution,
      })
    })
    let response = await axios.put('./api/distribution-records/save-distributions/', data, { validateStatus: () => true });
    if (response.status === 202) {
      window.location.reload();
    }
    else {
      // TODO: ERROR HANDLING
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Modal isOpen={this.state.showing} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="lg" toggle={() => this.setState({ showing: false })}>
          <ModalHeader tag='h3' style={{ backgroundColor: "#4C4A42" }}>
            <b style={{ color: "#d7c7a7" }}>{this.state.employee.value === "" ? "Add Employee" : "Edit " + this.state.employee.label + "'s Allocations"}</b>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "#4C4A42" }}>
            <Row>
              <Col>
                <label>Employee</label>
                <br />
                {this.state.employee.value === "" ? <Select
                  options={this.state.employees}
                  styles={reactSelectBasicStyle}
                  value={this.state.employee}
                  onChange={this.changeEmployee}
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
                  :
                  <label>{this.state.employee.label}</label>
                }
              </Col>
              <Col>
                <label>Payroll ID</label>
                <br />
                <input type="text" className="standard-input" value={this.state.payrollID} onChange={this.changePayrollID} />
              </Col>
              <Col>
                <label>Company</label>
                <br />
                <label>abc</label>
              </Col>
            </Row>
            <hr />
            <div>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={1} />
                </thead>
                <tbody>
                  {
                    this.state.rows.map((item, idx) => (
                      <tr key={item.uid + idx}>
                        <td className="fixedTableCellFirst">
                          <input type="text" value={item.code} className="standard-input" onChange={(e) => this.changeJobCode(e, idx)} />
                        </td>
                        <td className="fixedTableCell">
                          <input type="number" value={item.distribution} className="standard-input" onChange={(e) => this.changeDistribution(e, idx)} />
                        </td>
                        <td className="fixedTableCell">
                          <FontAwesomeIcon icon={faTrash} onClick={() => this.removeRow(idx)} style={{ marginLeft: "20%" }} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <hr />
              <Row>
                <Col>
                </Col>
                <Col>
                  <input type="button" value="Add Allocation" className="standard-input" onClick={this.addRow} />
                </Col>
              </Row>
              <hr />
              <input type="button" className="standard-input" value="Save Distributions" onClick={this.saveDistributions} />
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}