function fadeIn(element: HTMLElement) {
  var op = 0.1;
  element.style.display = 'block';
  var timer = setInterval(function () {
    if (op >= 1) {
      clearInterval(timer);
    }
    element.style.opacity = op.toFixed(5);
    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
    op += op * 0.1;
  }, 10);
}

function fadeOut(element: HTMLElement) {
  var op = 1;
  var timer = setInterval(function () {
    if (op <= 0.1) {
      clearInterval(timer);
      element.style.display = 'none';
    }
    element.style.opacity = op.toFixed(5);
    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
    op -= op * 0.1;
  }, 10);
}

/**
 * THIS IS OBSOLETE AND LOOKS LIKE SHIT. WE NEED TO REMOVE IT CAUSE IT IS ABSOLUTE TRASH
 * 
 * This message written by the creator of this function
 */
export function CreateGlobalAlert(message: string, timeout: number = 2000, callback: any = null): void {
  let element = document.getElementById("global-alert");
  fadeIn(element);
  document.getElementById("alert-text").innerHTML = message;
  setTimeout(() => {
    fadeOut(element);
    if (callback) {
      callback();
    }
  }, timeout);
}