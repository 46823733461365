import * as React from 'react';
import { DashboardOrganizer } from '../../../functions/WidgetDashboardOrganizer';
import { Col, Row } from 'reactstrap';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';

export class MaintenanceTechDashboard extends React.Component<{}, {
  tabList: { [key: number]: JSX.Element };
  renderSettings: RenderSettings;
}> {

  constructor(props: {}) {
    super(props);
    this.state = {
      tabList: {
        0: this.getButtons(),
      },
      renderSettings: { rows: [{ col: 1, widgets: [0] }] }
    }
    DashboardOrganizer.bind(this);
  }

  getButtons(): JSX.Element {
    return (
      <div style={{ paddingTop: "3%", overflow: "hidden"}}>
        <Row>
          <Col>
          </Col>
          <Col>
            <input type="button" className="standard-input" value="Create Ticket" onClick={() => window.location.assign("/submit-ticket")} />
          </Col>
          <Col>
            <input type="button" className="standard-input" value="View Knowledge Base" onClick={() => window.location.assign("/knowledge-base-home")} />
          </Col>
          <Col>
          </Col>
        </Row>
      </div>
    )
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "100vh" }}>
        <div style={{ display: "block", height: "6vh", paddingTop: "1vh" }}>
          <h4 style={{ width: "100%", textAlign: "center" }}>Maintenance Tech Dashboard</h4>
        </div>
        {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
      </div>
    )
  }
}

