import * as React from 'react';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { pullSecondaryCategories } from '../../../functions/fetchLinkedObjects';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { getUserID } from '../../../functions/authActions';
import axios from 'axios';
import { pullTicketCategory } from '../../../functions/fetchObjectNameFunctions';

interface TicketEmailSendEmailModalState {
  show: boolean;
  categories: Array<SelectOptions>;
  category: SelectOptions;
  body: string;
  errorMessage: string;
  submitted: boolean;
}

export class TicketEmailSendEmailModal extends React.Component<{ ticket: string }, TicketEmailSendEmailModalState> {

  constructor(props: { ticket: string}) {
    super(props);
    this.state = {
      show: false,
      categories: [],
      category: { label: "", value: ""},
      body: "",
      errorMessage: "",
      submitted: false
    }
    this.changeCategory = this.changeCategory.bind(this);
    this.changeBody = this.changeBody.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let categories = await pullSecondaryCategories();
    this.setState({ categories: categories });
  }

  async show(): Promise<void> {
    let category = await pullTicketCategory(this.props.ticket);
    this.setState({
      show: true,
      body: "",
      errorMessage: "",
      category: category
    })
  }

  changeCategory(event: SelectOptions | null) {
    if (event === null) {
      return;
    }
    this.setState({ category: event, errorMessage: "" });
  }

  changeBody(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ body: event.target.value, errorMessage: "" });
  }

  async submit(): Promise<void> {
    this.setState({ submitted: true });
    if (this.state.category.value === "" || this.state.category.label.toLocaleLowerCase().trim().includes("undefined") || this.state.body === "") {
      if (this.state.body === "") {
        this.setState({ errorMessage: "Please include a body before sending an email", submitted: true });
      }
      else {
        this.setState({ errorMessage: "Please define the category prior to sending the email", submitted: true });
      }
      return;
    }
    let data = {
      ticketUID: this.props.ticket,
      senderUID: getUserID(),
      emailBody: this.state.body,
      categoryUID: this.state.category.value
    };
    let response = await axios.post('./api/tickets/send-ticket-email', data);
    if (response.status === 202) {
      window.location.reload();
    }
    else {
      this.setState({ errorMessage: "There was an issue with submission, please contact Johnny so that he can fix it.", submitted: false });
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="md" toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag='h3' >
            Draft Email
          </ModalHeader>
        </div>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          {this.state.errorMessage }
          <Select
            options={this.state.categories}
            value={this.state.category}
            styles={reactSelectBasicStyle}
            onChange={this.changeCategory}
            required={this.state.category.label.toLocaleLowerCase().includes("undefined")}
          />
          <hr />
          <textarea value={this.state.body} className="standard-input" style={{ minHeight: "10vh" }} onChange={this.changeBody} />
          <hr />
          <Row>
            <Col>
              <input type="button" value="Send Email" className="standard-input" onClick={this.submit} disabled={this.state.submitted} />
            </Col>
            <Col>
              <input type="button" value="Cancel" className="standard-input" onClick={() => this.setState({show: false})}  />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export class TicketEmailViewBodyModal extends React.Component<{}, { show: boolean, message: string }> {

  constructor(props: {}) {
    super(props);
    this.state = {
      show: false,
      message: ""
    }
  }

  show(message: string): void {
    this.setState({
      show: true,
      message: message
    });
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="md" toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag='h3' >
            View Email Body
          </ModalHeader>
        </div>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          <div dangerouslySetInnerHTML={{ __html: this.state.message }}>

          </div>
        </ModalBody>
      </Modal>
    )
  }
}