import axios from 'axios';
import * as React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getBearerToken, getUserID, Jobs, Teams, ValidateAccess } from '../../../../functions/authActions';
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { pullEntitiesWithIndividuals, pullFleetEmployee } from '../../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../../interfaces/CoreInterfaces';
import FilteredWithSettingsTableHeader from '../../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../../CoreComponents/interfaces';
import { EntityTrackingTypes } from '../Creation/EntityCreationTrackingInfo';
import { compareTwoArray } from '../Creation/EntityFormationData';
import { NewEntityWorkFlowSteps } from './NewEntityRequestInProgressTable';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../../functions/selectTools';
import { StatusModal } from '../../../CoreComponents/Modals';
import { DownloadNamedFile } from '../../../../functions/documentTools';

interface NewEntityRequestDraftReviewProps {

}

interface NewEntityRequestDraftReviewState {
  uid: string;
  entityUID: string;
  status: SelectOptions;
  filePath: string;
  entity: any;
  isReviewer: boolean;
  trackingHeaders: Array<FilterAndSettingField>;
  trackingInformation: Array<any>;
  reviewers: Array<SelectOptions>;
  entityList: Array<SelectOptions>;
  signers: Array<SelectOptions>;
}

export default class NewEntityRequestDraftReview extends React.Component<NewEntityRequestDraftReviewProps, NewEntityRequestDraftReviewState> {

  modalRef = React.createRef<EntityRequestRejectDraftModal>();
  loadingRef = React.createRef<StatusModal>();

  constructor(props: NewEntityRequestDraftReviewProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const workflow = params.has("uid") ? params.get("uid") : "";

    this.state = {
      uid: workflow,
      entityUID: "",
      entity: {},
      status: { label: "", value: "" },
      filePath: "",
      isReviewer: false,
      trackingHeaders: [],
      trackingInformation: [],
      reviewers: [],
      entityList: [],
      signers: []
    }
    this.distribute = this.distribute.bind(this);
    this.sendBack = this.sendBack.bind(this);
    this.rejectDraft = this.rejectDraft.bind(this);
    this.approveDraft = this.approveDraft.bind(this);
    this.getDocument = this.getDocument.bind(this);
    this.sendAdobeSign = this.sendAdobeSign.bind(this);
    this.changeSigners = this.changeSigners.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('./api/new-entity-workflow/get-file-path/' + this.state.uid, { responseType: 'blob' }).then((response) => {
      this.setState({
        filePath: URL.createObjectURL(response.data)
      })
    })

    const workflowResponse = await axios.get('./api/new-entity-workflow/' + this.state.uid)
    this.setState({
      entityUID: workflowResponse.data.entityUID,
      entity: workflowResponse.data.entity,
      status: NewEntityWorkFlowSteps.filter(u => u.value === workflowResponse.data.status.toString())[0]
    })

    if (workflowResponse.data.status === 6) {
      await axios.patch('./api/new-entity-workflow/next-node/' + this.state.uid)
    }

    const employees = await pullFleetEmployee();
    const trackingInformation: any = [];

    const entities = await pullEntitiesWithIndividuals();

    const entityResponse = await axios.get("./api/new-entity-request/" + this.state.entityUID)

    entityResponse.data.trackingInformation.forEach((item: any) => {
      item.fleetEmployee = { label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.fleetEmployeeUID }.label;
      trackingInformation.push({
        trackUID: item.uid,
        author: item.fleetEmployee,
        notes: item.notes,
        trackingType: EntityTrackingTypes.filter(u => u.value === item.entityTrackingType.toString())[0]
      })
    })


    const sigernResponse = await axios.get("./api/new-entity-request/signers/" + this.state.entityUID)
    const signers: any = [];
    if (sigernResponse.data.newEntityMembers.length > 0) {
      sigernResponse.data.newEntityMembers.forEach((item: any) => {
        if (item.isSigner) {
          signers.push({ label: entities.filter(u => u.value === item.entityUID)[0].label, value: item.entityUID }
          )
        }
      })
    }

    this.setState({
      trackingInformation: trackingInformation
    })

    const reviewers: Array<SelectOptions> = [];

    this.state.entity.emailList.forEach((item: any) => {
      if (item.emailListType === 0 || item.emailListType === 3) {
        if (getUserID() === item.fleetEmployeeUID) {
          this.setState({ isReviewer: true })
        }
        if (!item.hasApproved) {
          reviewers.push(employees.filter(u => u.value === item.fleetEmployeeUID)[0])
        }
      }
    })

    this.setState({
      reviewers: reviewers,
      entityList: entities,
      signers: signers
    })
  }

  async distribute(): Promise<void> {
    this.loadingRef.current.display("Processing", "Please wait")
    const workflowData = {
      UID: this.state.entityUID,
      RequestorUID: getUserID()
    }

    await axios.patch('./api/new-entity-workflow/next-node-entity', workflowData).then((respsonse) => {
      this.loadingRef.current.hide();
      CreateGlobalAlert("Document Distributed, Going Back to Table", 2500)
      window.location.replace("./entity-request-drafts")
    })
  }

  async sendBack(): Promise<void> {
    await axios.patch('./api/new-entity-workflow/send-back/' + this.state.entityUID).then((respsonse) => {
      CreateGlobalAlert("Document Rejected, Going Back to Table", 2500)
      window.location.replace("./entity-request-drafts")
    })
  }

  rejectDraft(): void {
    this.modalRef.current.display(
      this.state.uid,
      this.state.entityUID
    );
  }

  async getDocument(): Promise<void> {
    const workflowData = {
      UID: this.state.entityUID,
      RequestorUID: getUserID()
    }

    await axios.patch('./api/new-entity-workflow/next-node-entity', workflowData).then((respsonse) => {
      CreateGlobalAlert("Entity Created, Going Back to Table", 2500)
      window.location.replace("./entity-request-drafts")
    })
  }

  async downloadDocument(): Promise<void> {
    let response = await axios.get('./api/new-entity-workflow/get-document/' + this.state.uid, { responseType: 'blob'})
    await DownloadNamedFile(response);
  }

  async sendAdobeSign(): Promise<void> {
    this.loadingRef.current.display("Processing", "Please wait")

    await axios.patch('./api/new-entity-workflow/next-node/' + this.state.uid).then((respsonse) => {
      this.loadingRef.current.hide();
      CreateGlobalAlert("Document Sent To Signers, Going Back to Table", 2500)
      window.location.replace("./entity-request-drafts")
    })
  }

  generateHeaders(): void {
    const trackingHeaders: Array<FilterAndSettingField> = [
      {
        columnName: 'Author',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Tracking Log',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Notes',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      }
    ]
    this.setState({ trackingHeaders: trackingHeaders })
  }

  async approveDraft(): Promise<void> {
    this.loadingRef.current.display("Processing", "Please wait")
    const data = {
      NewEntityUID: this.state.entityUID,
      FleetEmployeeUID: getUserID()
    }

    await axios.patch('./api/new-entity-email-list/approved', data).then((response) => {

    })

    await axios.patch('./api/new-entity-workflow/next-node/' + this.state.uid).then((respsonse) => {
      this.loadingRef.current.hide();
      CreateGlobalAlert("Document Approved, Going Back to Table", 2500)
      window.location.replace("./entity-request-drafts")
    })
  }

  async changeSigners(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return; }
    const compared = compareTwoArray(this.state.signers, event);
    this.setState({ signers: event });

    if (compared.added) {
      const patchResponse = await axios.patch("./api/new-entity-request/make-member-signer/" + compared.item.value + "/" + this.state.entityUID)

    }
    else if (!compared.added) {
      await axios.delete("./api/new-entity-request/remove-signer/" + compared.item.value + "/" + this.state.entityUID).then((response) => {

      })
    }
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "90vh", overflowY: "auto", overflowX: "hidden" }}>
        <EntityRequestRejectDraftModal ref={this.modalRef} />
        <StatusModal ref={this.loadingRef} />
        <Row>
          {this.state.status.value === "2" && <h3>Review Draft Before Sending To Reviewers</h3>}
          {this.state.status.value === "3" && <h3>Review Draft - {this.state.entity.entityName}</h3>}
          {this.state.status.value === "4" && <h3>Create Entity - {this.state.entity.entityName}</h3>}
          {this.state.status.value === "5" && <h3>Send Out To Signers - {this.state.entity.entityName}</h3>}
          {this.state.status.value === "6" && <h3>Awaiting Signatures - {this.state.entity.entityName}</h3>}
          {this.state.status.value === "7" && <h3>Draft Fully Signed - {this.state.entity.entityName}</h3>}
        </Row>
        <Row>
          {this.state.status.value === "2" && <h5>Is the draft ready to be sent to the Required Reviewers?</h5>}
          {this.state.status.value === "4" && <h5>This will create the Entity in the Command Center</h5>}
        </Row>
        {this.state.status.value === "3" && <Row>
          <div hidden={!ValidateAccess([Jobs.Legal_Analyst, Jobs.Technology], [Teams.Asset_Management, Teams.Services])}>
            <h5>Waiting on the following to approve:</h5>
            {this.state.reviewers.map((item) =>
              <h6>{item.label}</h6>
            )}
          </div>
        </Row>}
        {this.state.status.value === "6" && <Row>
          <div hidden={!ValidateAccess([Jobs.Legal_Analyst, Jobs.Technology], [Teams.Asset_Management, Teams.Services])}>
            <h5>Waiting On Signers</h5>
            {this.state.signers.map((item) =>
              <h6>{item.label}</h6>
            )}
          </div>
        </Row>}
        <Row style={{ height: "75vh" }}>
          {this.state.filePath !== "" && <embed src={this.state.filePath} style={{ minHeight: "75vh", minWidth: "100%" }} />}
        </Row>
        {this.state.status.value === "2" && <Row style={{ marginTop: "2vh" }}>
          <Col><input type="button" className="standardInput" value="Yes" style={{ width: "60%", marginLeft: "20%", height: "5vh" }} onClick={() => this.distribute()} /></Col>
          <Col><input type="button" className="standardInput" value="No" style={{ width: "60%", marginLeft: "20%", height: "5vh" }} onClick={() => this.sendBack()} /></Col>
        </Row>}
        {(this.state.status.value === "3" &&
          this.state.isReviewer
        ) && <Row style={{ marginTop: "2vh" }}>
            <Col><input type="button" className="standardInput" value="Approve" style={{ width: "60%", marginLeft: "20%", height: "5vh" }} onClick={() => this.approveDraft()} /></Col>
            <Col><input type="button" className="standardInput" value="Reject" style={{ width: "60%", marginLeft: "20%", height: "5vh" }} onClick={() => this.rejectDraft()} /></Col>
          </Row>}
        {this.state.status.value === "4" && <Row style={{ marginTop: "2vh" }}>
          <Col><input type="button" className="standardInput" value="Create Entity" style={{ width: "60%", marginLeft: "20%", height: "5vh" }} onClick={() => this.getDocument()} /></Col>
        </Row>}
        {this.state.status.value === "5" && <>
          <Row style={{ marginTop: "2vh" }}>
            <Col>
              <label>Signers</label>
              <Select
                value={this.state.signers}
                styles={reactSelectBasicStyle}
                options={this.state.entityList}
                onChange={this.changeSigners}
                menuPlacement="top"
                isMulti
                isClearable={false}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col><input type="button" className="standardInput" value="Send for Adobe Signatures" style={{ width: "60%", marginLeft: "20%", height: "5vh", marginTop: "1vh" }} onClick={() => this.sendAdobeSign()} /></Col>
          </Row>
        </>}
        <Row>
          <input type="button" className="standardInput" value="Download Document" style={{ width: "60%", marginLeft: "20%", height: "5vh", marginTop: "1vh" }} onClick={() => this.downloadDocument()} />
        </Row>
        <Row style={{ marginTop: "2vh" }} hidden={!ValidateAccess([Jobs.Legal_Analyst, Jobs.Technology], [Teams.Asset_Management, Teams.Services])}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.trackingHeaders} icons={0} />
            </thead>
            <tbody>
              {this.state.trackingInformation.map((item: any, idx: number) =>
                <tr style={{ height: "13vh" }}>
                  <td className="fixedTableCellFirst">{item.author}</td>
                  <td className="fixedTableCellFirst">{item.trackingType.label}</td>
                  <td className="fixedTableCell" style={{ whiteSpace: "pre-wrap", maxHeight: "13vh", overflow: "auto" }}>{item.notes}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Row>
      </div>
    )
  }

}

interface EntityRequestRejectDraftModalProps {

}

interface EntityRequestRejectDraftModalState {
  showing: boolean;
  explanation: string;
  workflowUID: string;
  entityUID: string;
}

class EntityRequestRejectDraftModal extends React.Component<EntityRequestRejectDraftModalProps, EntityRequestRejectDraftModalState>{
  constructor(props: EntityRequestRejectDraftModalProps) {
    super(props);
    this.state = {
      showing: false,
      explanation: "",
      workflowUID: "",
      entityUID: ""
    }
    this.changeExplanation = this.changeExplanation.bind(this);
  }

  display(workflow: string, entity: string): void {
    this.setState({
      showing: true,
      workflowUID: workflow,
      entityUID: entity
    })
  }

  changeExplanation(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      explanation: event.target.value
    })
  }

  async submit(): Promise<void> {
    const trackingData = {
      NewEntityUID: this.state.entityUID,
      FleetEmployeeUID: getUserID(),
      Notes: this.state.explanation,
      EntityTrackingType: 20
    }

    await axios.post("./api/new-entity-tracking-information", trackingData).then(function (response) {
    })

    await axios.patch('./api/new-entity-workflow/send-back/' + this.state.entityUID).then((respsonse) => {
      this.setState({ showing: false })
      CreateGlobalAlert("Document Rejected, Going Back to Table", 2500)
      window.location.replace("./entity-request-drafts")
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderRadius: "15%" }}>
        <div style={{ background: "#4c4a42", color: "#c2a877" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ showing: false })}>
            <div style={{ color: "#c2a877" }}>
              Rejection of Draft
            </div>
          </ModalHeader>
          <ModalBody>
            Please state why you are rejecting the draft
            <textarea className="standard-input" value={this.state.explanation} onChange={this.changeExplanation}
              style={{ minHeight: "20vh", marginTop: "2vh" }}
              placeholder="Required" required={this.state.explanation === ''} />
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col><input type="button" className="standard-input" style={{ width: "5vw" }} value="Cancel" onClick={() => this.setState({ showing: false })} /></Col>
              <Col><input type="button" className="standard-input" style={{ width: "5vw" }} value="Submit" onClick={() => this.submit()} /></Col>
            </Row>
          </ModalFooter>
        </div>
      </Modal>
    )
  }

}