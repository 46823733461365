import * as React from 'react'
import { CNADashboardProps, CNADashboardState } from './CNADashboard.constants';
import { DashboardOrganizer, DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';
import { GetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DashboardManagementModal } from '../../CoreComponents/Modals/DashboardManagementModal';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import axios from 'axios';
import { getBearerToken } from '../../../functions/authActions';
import CNAProjectProviderWidget from './CNAWidgets/CNAProjectProviderWidget';
import CNAOwnersNarrativeWidget from './CNAWidgets/CNAOwnersNarrativeWidget';
import CNAInspectedUnitsWidget from './CNAWidgets/CNAInspectedUnitsWidget';
import CNAExecutiveSummaryWidget from './CNAWidgets/CNAExecutiveSummaryWidget';
import CNAPhotosWidget from './CNAWidgets/CNAPhotosWidget';
import CNAChangeLogWidget from './CNAWidgets/CNAChangeLogWidget';
import CNAPropertyInformationWidget from './CNAWidgets/CNAPropertyInformationWidget';
import CNAOwnersInformationWidget from './CNAWidgets/CNAOwnersInformationWidget';
import CNAInspectionReportWidget from './CNAWidgets/CNAInspectionReportWidget';

export default class CNADashboard extends React.Component<CNADashboardProps, CNADashboardState> {

  settingsString: string = "cnaDashboard";
  widgetSettingsModalRef: React.RefObject<DashboardManagementModal> = React.createRef<DashboardManagementModal>();

  constructor(props: CNADashboardProps) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    const cnaUID = params.has("uid") ? params.get("uid") : "";
    if (cnaUID === '') {
      window.location.replace("./property-summary-table");
      return;
    };

    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal = false;

    if (settings == null) {
      showModal = true;
      settings = DefaultPageSettings;
    }

    this.state = {
      cnaUID: cnaUID,
      cnaProjectName: "",
      cnaVersion: "",
      propType: "",
      renderSettings: settings,
      tabList: {},
      widgetList: [],
      showModal: showModal
    }
    DashboardOrganizer.bind(this);
    this.updateUI = this.updateUI.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.getTabList();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const response = await axios.get('./api/capital-needs-assessment/' + this.state.cnaUID)

    if (response.status == 200) {
      this.setState({
        cnaProjectName: response.data.projectName,
        cnaVersion: response.data.version,
        propType: response.data.property.type
      })
    }
    else {
      //TODO ERROR HANDLE
    }
  }

  getTabList(): void {
    let tabList: { [key: number]: JSX.Element } = {
      0: <CNAProjectProviderWidget cnaUID={this.state.cnaUID} />,
      1: <CNAOwnersNarrativeWidget cnaUID={this.state.cnaUID} />,
      2: <CNAInspectedUnitsWidget cnaUID={this.state.cnaUID} />,
      7: <CNAExecutiveSummaryWidget cnaUID={this.state.cnaUID} />,
      8: <CNAPhotosWidget cnaUID={this.state.cnaUID} />,
      9: <CNAChangeLogWidget cnaUID={this.state.cnaUID} />,
      10: <CNAPropertyInformationWidget cnaUID={this.state.cnaUID} />,
      11: <CNAOwnersInformationWidget cnaUID={this.state.cnaUID} />,
      12: <CNAInspectionReportWidget cnaUID={this.state.cnaUID} />
    }

    let widgetList: Array<SelectOptions> = [
      { label: "Provider Information", value: "0" },
      { label: "Owner's Narrative", value: "1" },
      { label: "Inspected Units", value: "2" },
      { label: "Executive Summary", value: "7" },
      { label: "Photos", value: "8" },
      { label: "Change Log", value: "9" },
      { label: "Property Unit Types", value: "10" },
      { label: "Owner Information", value: "11" },
      { label: "Inspection Report", value: "12" }
    ]
    this.setState({
      tabList: tabList,
      widgetList: widgetList
    })
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  openWidgetSettings(): void {
    this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)
  }

  render(): JSX.Element {
    return (
      <div style={{ overflowX: "hidden", overflowY: "auto", height: "80vh" }}>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <div style={{ clear: "both" }}></div>
        <div>
          {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
        </div>
      </div>
    )
  }
}