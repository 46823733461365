import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { getUserID } from '../../../functions/authActions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';

export class EmptyWidget extends React.Component<{}, {id: string}> {

  constructor(props: {}) {
    super(props);
    let key = Math.random();
    this.state = {
      id: "container-" + key
    }
  }

  async componentDidMount(): Promise<void> {
    let parent = document.getElementById(this.state.id).parentElement.style;
    parent.border = "";
    parent.background = "";
  }

  render(): JSX.Element {
    return (<div id={this.state.id}></div>)
  }
}