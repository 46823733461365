import * as React from 'react';
import axios from 'axios';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { Col, Row } from 'reactstrap';
import { EMPTY_GUID } from '../../constants/DefaultConstants';

interface ElectricUtilityFormProps {

}

interface ElectricUtilityFormState {
  name: string;
  netMeterCap: number;
  id: any;
}

export class ElectricUtilityForm extends React.Component<ElectricUtilityFormProps, ElectricUtilityFormState> {

  static displayName = ElectricUtilityForm.name;

  constructor(props: ElectricUtilityFormProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const provider = params.has("provider") ? params.get("provider") : "";
    this.state = {
      name: '',
      netMeterCap: 0,
      id: provider
    }
    this.changeUtilityName = this.changeUtilityName.bind(this);
    this.changeNetMeterCap = this.changeNetMeterCap.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    if (this.state.id !== '') {
      await axios.get('./api/electric-utility-providers/' + this.state.id).then(function (response) {
        self.setState({
          name: response.data.name,
          netMeterCap: response.data.netMeterCap
        })
      })
    }
  }

  changeUtilityName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      name: event.target.value
    })
  }

  changeNetMeterCap(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      netMeterCap: parseInt(event.target.value)
    })
  }

  async submit(): Promise<void> {
    const data = {
      UID: EMPTY_GUID,
      Name: this.state.name,
      NetMeterCap: this.state.netMeterCap
    }
    if (this.state.id === '') {
      await axios.post("./api/electric-utility-providers", data).then(() =>
        CreateGlobalAlert(`${this.state.name} created as an electric provider`, 2000, () => window.location.assign("./electric-utility-manager"))
      )
    }
    else {
      data.UID = this.state.id;
      await axios.put("./api/electric-utility-providers", data).then(() =>
        CreateGlobalAlert(`${this.state.name} updated as an electric provider`, 2000, () => window.location.assign("./electric-utility-manager"))
      );
    }
  }

  render(): JSX.Element {
    return (
      <div id="electric-utility-form">
        <Row>
          <Col>
            <h4>Utility Name</h4>
            <input id="name" className="standard-input" value={this.state.name} type="text" placeholder="Pacific Power" onChange={this.changeUtilityName} />
          </Col>
          <Col>
            <h4>Net Meter Cap</h4>
            <input type="number" id="netMeterCap" value={this.state.netMeterCap} className="standard-input" placeholder="25" onChange={this.changeNetMeterCap} />
          </Col>
        </Row>
        <br />
        <br />
        <input type="button" className="standard-input" value="Save" onClick={this.submit} />
      </div>

    )
  }
}