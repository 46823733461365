import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import axios from 'axios';
import { getBearerToken } from '../../../functions/authActions';
import EntityPropertyOwnershipTableModal from './EntityPropertyOwnershipTableWidget.Modal';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface EntityPropertyOwnershipTableData {
  name: string;
  uid: string;
  managingMember: boolean;
}

interface EntityPropertyOwnershipTableWidgetState {
  headers: Array<FilterAndSettingField>;
  rows: Array<EntityPropertyOwnershipTableData>;
}

export default class EntityPropertyOwnershipTableWidget extends React.Component<{ uid: string }, EntityPropertyOwnershipTableWidgetState> {

  modal: React.RefObject<EntityPropertyOwnershipTableModal> = React.createRef<EntityPropertyOwnershipTableModal>();

  constructor(props: { uid: string }) {
    super(props);
    this.state = {
      headers: [],
      rows: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let response = await axios.get('./api/entity-property-ownership/' + this.props.uid);
    if (response.status === 200) {
      const ownership: Array<EntityPropertyOwnershipTableData> = [];
      response.data.forEach(function (item: any) {
        ownership.push({
          name: item.property.name,
          uid: item.property.uid,
          managingMember: item.isManagingMember
        })
      })
      this.setState({
        rows: ownership
      })
    }
    else {
      //TODO: Error
    }
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Property Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Managing Member",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {},
        additionalClasses: "fifteen"
      }
    ]
    this.setState({ headers: headers });
  }

  save(property: SelectOptions, isManagingMember: boolean): void {
    let properties = this.state.rows;
    properties.push({ name: property.label, uid: property.value, managingMember: isManagingMember });
    this.setState({ rows: properties }, () => window.location.reload());
  }

  async remove(idx: number): Promise<void> {
    let properties = this.state.rows;
    let property = properties[idx];
    properties.splice(idx, 1);
    let response = await axios.delete('./api/entity-property-ownership/' + property.uid);
    if (response.status !== 202) {
      //TODO: Handle Error
    }
    else {
      this.setState({ rows: properties }, () => window.location.reload());
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <EntityPropertyOwnershipTableModal ref={this.modal} save={this.save} />
        <div style={{ minHeight: "30vh" }}>
          {RenderHeader("Property Ownership")}
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: EntityPropertyOwnershipTableData, idx: number) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst" onClick={() => window.open('./property-information-page?uid=' + item.uid)}>{item.name}</td>
                    <td className="fixedTableCell" style={{ textAlign: 'center' }}>{item.managingMember ? "Yes" : "No"}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Delete" onClick={() => this.remove(idx)} className="standard-input" />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Add Property" className="standard-input" onClick={() => this.modal.current.show(this.props.uid)} />
      </div>
    )
  }
}