import * as React from 'react';
import Select, { SelectOptionActionMeta } from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { Col, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';

interface SelectableLegendTableRowProps {
  index: any;
  comments: any;
  distance: any;
  items: any;
  name: any;
  currentIndex: any;
  onChange: any;
  updateComments: any;
  override: any;
}

interface SelectableLegendTableRowState {
  index: any;
  comments: any;
  distance: any;
  items: any;
  name: any;
  currentIndex: any;
  onChange: any;
  updateComments: any;
  override: any;
}

export class SelectableLegendTableRow extends React.Component<SelectableLegendTableRowProps, SelectableLegendTableRowState> {

  constructor(props: SelectableLegendTableRowProps) {
    super(props);
    this.state = {
      index: props.index,
      comments: props.comments,
      distance: props.distance !== undefined ? `${props.distance} Miles` : '',
      items: props.items,
      name: props.name,
      currentIndex: props.currentIndex,
      onChange: props.onChange,
      updateComments: props.updateComments,
      override: props.override
    }
    this.updateComments = this.updateComments.bind(this);
    this.changeOverride = this.changeOverride.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
  }

  changeOverride(event: React.ChangeEvent<HTMLInputElement>): void {
    var value = event.target.value;
    this.setState({
      override: value
    });
    var _event = { value: this.state.currentIndex };
    this.state.onChange(_event, this.state.index, value);
  }

  changeSelection(event: SelectOptions | null): void {
    var item = this.state.items[event.value];
    if (item === undefined) {
      item = {
        distance: 0
      }
    }
    this.setState({
      currentIndex: event.value,
      distance: `${item.distance} Miles`,
    }, this.state.onChange(event, this.state.index, this.state.override));
  }

  updateComments(event: any): void {
    this.setState({
      comments: event.target.value
    }, this.state.updateComments(event.target.value, this.state.index));
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.state.index+1}</td>
        <td className="fixedTableCell">
          <Select
            value={{ label: this.state.items[this.state.currentIndex]?.name ?? "", value: this.state.items[this.state.currentIndex]?.index ?? "-1" }}
            onChange={this.changeSelection}
            styles={reactSelectBasicStyle}
            defaultValue={{ label: this.state.items[0].name, value: this.state.items[0].index }}
            options={this.state.items.map(function (item: any) { return { label: item.name, value: item.index } })}
            menuPlacement={this.state.index > 5 ? "top" : "bottom"}
          />
        </td>
        <td className="fixedTableCell">
          <input
            type="text"
            value={this.state.override}
            placeholder="Override"
            className="standard-input"
            onChange={this.changeOverride}
          />
        </td>
        <td className="fixedTableCell">{this.state.distance}</td>
        <td className="fixedTableCell">
          <input type="text" className="standard-input" value={this.state.comments} onChange={this.updateComments} />
        </td>
      </tr>
    )
  }
}
