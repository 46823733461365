import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import TicketSecondaryCategoriesModal from './TicketSecondaryCategoriesWidget.modals';
import axios from 'axios';
import { WarningModal, YesNoModal } from '../../CoreComponents/Modals';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketSecondaryCategoriesWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<TicketSecondaryCategoryData>;
  name: string;
  primary: string;
}

interface TicketSecondaryCategoryData {
  uid: string;
  name: string;
  primary: string;
  tickets: number;
}

export default class TicketSecondaryCategoriesWidget extends React.Component<{}, TicketSecondaryCategoriesWidgetState>{

  modal: React.RefObject<TicketSecondaryCategoriesModal> = React.createRef<TicketSecondaryCategoriesModal>();
  yesNoModal: React.RefObject<YesNoModal> = React.createRef<YesNoModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);

    this.state = {
      headers: [],
      data: [],
      name: "",
      primary: ""
    }
    this.updateName = this.updateName.bind(this);
    this.updatePrimary = this.updatePrimary.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Category",
        setting: true,
        filter: {
          value: this.state.name,
          type: 'text',
          changeFilter: this.updateName,
          name: "name"
        },
      },
      {
        columnName: "Primary",
        setting: true,
        filter: {
          value: this.state.primary,
          type: 'text',
          changeFilter: this.updatePrimary,
          name: "primary"
        },
      },
      {
        columnName: "Tickets",
        filter: {},
        setting: true
      },
      {
        columnName: "",
        filter: {},
        setting: true
      }
    ]
    this.setState({ headers: headers }, async () => await this.updateData());
  }

  async updateData(): Promise<void> {
    const data = {
      page: 0,
      pageCount: 0,
      name: this.state.name,
      primary: this.state.primary
    }
    let response = await axios.post('./api/secondary-ticket-category/filter', data, { validateStatus: () => true });
    if (response.status === 200) {
      let data: Array<TicketSecondaryCategoryData> = [];
      response.data.forEach((item: any) => {
        data.push({
          uid: item.uid,
          name: item.name,
          primary: item.primaryCategory.name,
          tickets: item.tickets.length
        })
      });
      this.setState({ data: data });
    }
  }

  async updateName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ name: event.target.value }, async () => await this.generateHeaders());
  }

  async updatePrimary(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ primary: event.target.value }, async () => await this.generateHeaders());
  }

  startInactivate(uid: string): void {
    this.yesNoModal.current.show(
      "You are about to inactivate this category. Are you sure you wish to do this, once inactivated you will not be able to use it unless you submit a tech request to have it reactivated",
      "Warning: Inactivating Category",
      () => this.yesNoModal.current.hide(),
      async () => await this.inactivate(uid),
      "Confirm",
      "Cancel",
    );
  }

  async inactivate(uid: string): Promise<void> {
    let response = await axios.patch("./api/secondary-ticket-category/inactivate/" + uid);
    if (response.status !== 202) {
      this.warningModal.current.show("There was an error inactivating the category. Please contact the tech team.", "Error.");
    }
    else {
      this.warningModal.current.show("Successfully inactivated the category.", "Success", () => window.location.reload(), "Reload The Page");
    }
  }
      
  render(): JSX.Element {
    return (
      <div>
        <YesNoModal ref={this.yesNoModal} />
        <WarningModal ref={this.warningModal} />
        <TicketSecondaryCategoriesModal ref={this.modal} />
        {RenderHeader("Secondary Categories")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{item.primary}</td>
                    <td className="fixedTableCell">{item.tickets}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Inactivate" className="standard-input" onClick={() => this.startInactivate(item.uid)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create" className="standard-input" onClick={() => this.modal.current.show()} />
      </div>
    )
  }
}