import * as React from 'react'
import { TechTeamDashboardManagerProps, TechTeamDashboardManagerState } from './TechTeamDashboardManager.constants';
import Select from 'react-select';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { MCIInstructorDashboard } from '../../DashboardComponents/Dashboards/MCIInstructorDashboard';
import { PropertyAccountantDashboard } from '../../DashboardComponents/Dashboards/PropertyAccountingDashboard';
import { RegionalManagerDashboard } from '../../DashboardComponents/Dashboards/RegionalManagerDashboard';
import { VicePresidentDashboard } from '../../DashboardComponents/Dashboards/VicePresidentOperationsDashboard';
import { HumanResourcesDashboard } from '../../DashboardComponents/Dashboards/HumanResourcesDashboard';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import TechnologyTeamLeadDashboard from '../../DashboardComponents/Dashboards/TechnologyTeamLeadDashboard';
import { ChiefOperatingOfficerDashboard } from '../../DashboardComponents/Dashboards/ChiefOperatingOfficerDashboard';
import { SiteManagerDashboard } from '../../DashboardComponents/Dashboards/SiteManagerDashboard';
import { ProjectManagementDashboard } from '../../DashboardComponents/Dashboards/ProjectManagementDashboard';
import { ChiefFinancialOfficerDashboard } from '../../FinanceProformaComponents/FinanceDashboards/ChiefFinancialOfficerDashboard';
import ViridianPresidentDashboard from '../../DashboardComponents/Dashboards/ViridianPresidentDashboard';
import DevelopmentAccountantDashboard from '../../DashboardComponents/Dashboards/DevelopmentAccountantDashboard';


export const TechLeadDashboardOptions: Array<SelectOptions> = [
  { label: "Tech Lead", value: "0" },
  { label: "MCI Instructor", value: "1" },
  { label: "Property Accounting", value: "2" },
  { label: "Regional Manager", value: "3" },
  { label: "Vice President of Operations", value: "4" },
  { label: "Human Resources", value: "5" },
  { label: "COO", value: "6" },
  { label: "Site Manager", value: "7" },
  { label: "Project Management", value: "8" },
  { label: "CFO", value: "9" },
  { label: "VMI President", value: "10" },
  { label: "Development Accounting", value: "11" },
]

export default class TechTeamLeadDashboardManager extends React.Component<TechTeamDashboardManagerProps, TechTeamDashboardManagerState>{

  constructor(props: TechTeamDashboardManagerProps) {
    super(props);
    var params = new URLSearchParams(window.location.search);
    var value = params.has('menu') ? params.get('menu') : '0';
    this.state = {
      dashboard: TechLeadDashboardOptions[parseInt(value)]
    }
    this.dashboardSwitch = this.dashboardSwitch.bind(this);
    this.changeDashboard = this.changeDashboard.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  dashboardSwitch(): JSX.Element {
    switch (this.state.dashboard.value) {
      case "0":
        return <TechnologyTeamLeadDashboard key={this.state.dashboard.value} />
      case "1":
        return <MCIInstructorDashboard key={this.state.dashboard.value} />
      case "2":
        return <PropertyAccountantDashboard key={this.state.dashboard.value} />
      case "3":
        return <RegionalManagerDashboard key={this.state.dashboard.value} />
      case "4":
        return <VicePresidentDashboard key={this.state.dashboard.value} />
      case "5":
        return <HumanResourcesDashboard key={this.state.dashboard.value} />
      case "6": 
        return <ChiefOperatingOfficerDashboard key={this.state.dashboard.value} />
      case "7":
        return <SiteManagerDashboard />
      case "8":
        return <ProjectManagementDashboard />
      case "9":
        return <ChiefFinancialOfficerDashboard />
      case "10":
        return <ViridianPresidentDashboard />
      case "11":
        return <DevelopmentAccountantDashboard />
      default:
        return <h3>ERROR</h3>
    }
  }

  changeDashboard(event: SelectOptions | null): void {
    if (event === null) { return; }
    var params = new URLSearchParams(window.location.search);
    params.set('menu', event.value);
    window.location.search = params.toString();
    //this.setState({ dashboard: event })
  }

  render(): JSX.Element {
    return (
      <>
        {this.dashboardSwitch()}
        <div style={{ width: "25%", marginLeft: "75%" }}>
          <Select
            options={TechLeadDashboardOptions}
            styles={reactSelectBasicStyle}
            value={this.state.dashboard}
            onChange={this.changeDashboard}
            menuPlacement="auto"
            menuPosition="fixed"
            onFocus={RemoveStickyOverlays}
            onBlur={RestoreStickyOverlays}
          />
        </div>
      </>
    )
  }
}