import * as React from 'react'
import { CNAOwnersInformationWidgetProps, CNAOwnersInformationWidgetState } from './CNAOwnersInformationWidget.constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap';

//This needs to render the ownrs informaiton name, address, contact information

export default class CNAOwnersInformationWidget extends React.Component<CNAOwnersInformationWidgetProps, CNAOwnersInformationWidgetState> {

  constructor(props: CNAOwnersInformationWidgetProps) {
    super(props);
    this.state = {
      cnaUID: props.cnaUID,
      ownerName: "",
      addr1: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      email: ""
    }
  }

  async componentDidMount(): Promise<void> {
    const response = await axios.get('./api/capital-needs-assessment/owner/address/' + this.state.cnaUID)

    if (response.status == 200) {
      this.setState({
        ownerName: response.data.owner.name,
        addr1: response.data.owner.streetAddress.streetAddress,
        city: response.data.owner.streetAddress.city,
        state: response.data.owner.streetAddress.stateName,
        zipCode: response.data.owner.streetAddress.zipCode,
        phone: ""
      })
    }
    else {
      //TO DO ERROR HANDLE
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h4>Owner Information</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Name</h5>
          </Col>
          <Col>
            <h6>{this.state.ownerName}</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Address</h5>
          </Col>
          <Col>
            <h6>{this.state.addr1}</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>{this.state.city}</h6>
          </Col>
          <Col>
            <h6>{this.state.state}</h6>
          </Col>
          <Col>
            <h6>{this.state.zipCode}</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Phone</h5>
          </Col>
          <Col>
            <h6>{this.state.phone}</h6>
          </Col>
        </Row>
      </>
    )
  }
}