import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import SignerSettingsManagementModal from './SignerSettingsWidget.modals';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

export interface SignerSettingsWidgetData {
  ownerEmail: string;
  ownerName: string;
  notarySigner: string;
  notarySignerName: string;
  waiverSigner: string;
  secondSigner: string;
  secondSignerName: string;
  firstApprover: SelectOptions;
}

interface SignerSettingsWidgetProps {
  uid: string;
  height?: string;
}

interface SignerSettingsWidgetState {
  headers: Array<FilterAndSettingField>;
  data: SignerSettingsWidgetData;
}

export default class SignerSettingsWidget extends React.Component<SignerSettingsWidgetProps, SignerSettingsWidgetState> {

  signerSettingsModal: React.RefObject<SignerSettingsManagementModal> = React.createRef<SignerSettingsManagementModal>();

  constructor(props: SignerSettingsWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      data: {
        ownerEmail: "",
        ownerName: "",
        notarySigner: "",
        notarySignerName: "",
        waiverSigner: "",
        secondSigner: "",
        secondSignerName: "",
        firstApprover: { label: "First Approver", value: ""}
      }
    }
  }

  async componentDidMount(): Promise<void> {
    let response = await axios.get("./api/managed-jobs/signer-settings/" + this.props.uid);
    if (response.status === 200) {
      let data = response.data;
      data.firstApprover = { label: data.firstApproverName, value: data.firstApprover };
      this.setState({ data: data });
    }
  }

  async showEditModal(): Promise<void> {

  }

  render(): JSX.Element {
    return (
      <>
        <SignerSettingsManagementModal ref={this.signerSettingsModal} uid={this.props.uid} />
        {RenderHeader("Signer Settings")}
        <div style={{ height: this.props.height ? this.props.height : "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <Row>
            <Col>
              <h6>Owner Name</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.ownerName}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Owner Email</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.ownerEmail}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Notary Signer</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.notarySignerName}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Notary Signer Email</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.notarySigner}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Waiver Signer</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.waiverSigner}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Architect Name</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.secondSignerName}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Architect Email</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.secondSigner}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>First Approver</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.firstApprover.label}</h6>
            </Col>
          </Row>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Edit" className="standard-input" onClick={(e) => this.signerSettingsModal.current.show(this.state.data)} />
          </Col>
        </Row>
      </>
    )
  }
}