

import * as React from 'react';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import { Col, Row } from 'reactstrap';
import PropertyOffboardingConfigurationModal from './PropertyOffboardingConfigurationModal';


export default class ConfigurationDashboard extends React.Component<{}, {}> {

  offboardingModal: React.RefObject<PropertyOffboardingConfigurationModal> = React.createRef<PropertyOffboardingConfigurationModal>();

  constructor(props: {}) {
    super(props);
    this.state = {

    }
  }

  async componentDidMount(): Promise<void> {

  }

  render(): JSX.Element {
    return (
      <>
        <PropertyOffboardingConfigurationModal ref={this.offboardingModal} />
        <Row>
          <Col>
            {RenderHeader("Property Offboarding")}
            <input type="button" value="Edit" className="standard-input" onClick={() => this.offboardingModal.current.show()} />
          </Col>
          <Col>
            {RenderHeader("Property Onboarding")}
          </Col>
          <Col>
            
          </Col>
          <Col>

          </Col>
        </Row>
      </>
    )

  }
}
