import * as React from "react";
import { Col, Row } from "reactstrap";
import { numberWithCommas } from "../../../functions/numberFunctions";

export interface PieGraphData {
  label: string;
  value: number;
  color: string;
}

interface PieGraphProps {
  values: Array<PieGraphData>;
  title: string;
}

export interface PieGraphState {
  width: number;
  height: number;
  values: Array<PieGraphData>;
}

export class PieGraphWidget extends React.Component<PieGraphProps, PieGraphState>{

  currentReference: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  constructor(props: PieGraphProps) {
    super(props);
    this.state = {
      width: 30,
      height: 30,
      values: props.values,
    }
  }

  async componentDidMount(): Promise<void> {
    let width = this.currentReference.current.clientWidth;
    this.setState({ width: width * 0.5, height: width * 0.75 });
  }

  componentDidUpdate(): void {
    let width = this.currentReference.current.clientWidth;
    if (width * 0.5 != this.state.width) {
      this.setState({ width: width * 0.5, height: width * 0.5 });
    }
  }

  createLabels(): Array<JSX.Element> {
    var rows = this.state.values.length / 3;
    let ret = []
    for (var i = 0; i < rows; ++i) {
      let cols = [];
      for (var j = 0; j < 3; ++j) {
        if ((i * 3) + j >= this.state.values.length) {
          cols.push(<Col style={{ padding: "0px", margin: "0px", marginBottom: "5px" }}></Col>)
        }
        else {
          let item = this.state.values[(i * 3) + j];
          cols.push(
            <Col style={{ padding: "0px", margin: "0px", marginBottom: "5px" }}>
              <h6 style={{
                color: item.color,
                textAlign: "center",
                width: "100%",
                display: "block",
                padding: "0px",
                margin: "0px",
                font: "bold 12px sans-serif"
              }}>{item.label}</h6>
              <h6 style={{
                color: item.color,
                textAlign: "center",
                width: "100%",
                padding: "0px",
                margin: "0px",
                font: "bold 10px sans-serif"
              }}>{numberWithCommas(item.value, 2)}%</h6>
            </Col>
          )
        }
      }
      ret.push(
        <Row style={{ marginTop: "1vh" }} key={i}>
          {cols}
        </Row>
      )
    }
    return ret;
  }

  createGraph(): JSX.Element {
    let total = 0;
    if (this.state.values.length === 0) {
      const chart = (
        <g transform="rotate(-90, 18, 18)" fill={"#000"} strokeWidth="3">
          <circle r={
            100 / (2 * Math.PI)} cx="50%" cy="50%"
            stroke={"#fff"}
            strokeDasharray="100 100"
          />
        </g>
      )
      return chart;
    }
    else {
      const values = this.state.values;
      values.map((item) => {
        total = parseInt(total.toString()) + parseInt(item.value.toString());
      })
      let runningTotal = 0;
      const chart = (
        <g transform="rotate(-90, 18, 18)" fill="none" strokeWidth="32">
          {
            values.map((item) => <>{
              <circle r={100 / (2 * Math.PI)} cx="50%" cy="50%"
                stroke={item.color}
                strokeDasharray={((item.value / total) * 100) + " 100"}
                transform={"rotate(" + (((runningTotal) / total) * 360) + ", 18, 18)"}
              />
            }{runningTotal = parseInt(runningTotal.toString()) + parseInt(item.value.toString())}</>
            )
          }
        </g>
      )
      return chart;
    }
  }

  render(): JSX.Element {
    return (
      <div ref={this.currentReference} style={{ backgroundColor: "#ccc", border: "2px solid #444", borderRadius: "20px 20px 20px 20px", margin: "0px" }}>
        <h6 style={{ width: "100%", textAlign: "center", color: "#444" }}>{this.props.title}</h6>
        <svg width={this.state.width + "px"} height={this.state.width + "px"} viewBox="0 0 36 36" style={{ display: "block", margin: "auto", borderRadius: "50%" }}>
          {
            this.createGraph()
          }
        </svg>
        {
          this.createLabels()
        }
      </div>
    )
  }
}
