import * as React from 'react'
import { Col, Row } from 'reactstrap';
import { FinancialProformaProjectOverview } from './FinancialProformaProjectOverview';
import GeneralContractorInsuranceAndBond from './GeneralContractorInsuranceAndBond';
import UsesBySite from './UsesBySite';

interface FinancialProformaContainerProps {

}

interface FinancialProformaContainerState {
  currentTab: number;
}

export default class FinancialProformaContainer extends React.Component<FinancialProformaContainerProps, FinancialProformaContainerState> {

  constructor(props: FinancialProformaContainerProps) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  getTab(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <FinancialProformaProjectOverview />
      case 1:
        return <></>
      case 2:
        return <UsesBySite />
      case 3:
        return <GeneralContractorInsuranceAndBond />
    }
  }

  render(): JSX.Element {
    return (
      <>
        <h5>|Project| Financial Proforma</h5>
        <div style={{ height: "80vh" }} >
          {
            this.getTab()
          }
        </div>
        <Row>
          <Col>
            <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "10%" }}
              value="Project Overview" onClick={() => this.setState({ currentTab: 0 })}
            />
            <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "10%" }}
              value="CNA" onClick={() => this.setState({ currentTab: 1 })}
            />
            <input type="button" className={this.state.currentTab === 2 ? "tab-active" : "tab"} style={{ width: "10%" }}
              value="Uses By Site" onClick={() => this.setState({ currentTab: 2 })}
            />
            <input type="button" className={this.state.currentTab === 3 ? "tab-active" : "tab"} style={{ width: "10%" }}
              value="GC Ins & PB" onClick={() => this.setState({ currentTab: 3 })}
            />
          </Col>
        </Row>
      </>
    )
  }
}