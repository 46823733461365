import axios from 'axios';
import * as React from 'react';
import { VendorData, VendorManagementWidgetProps, VendorManagementWidgetState, VendorServerData } from './VendorManagementWidget.constants';
import { ExternalCompanyTypes_List } from '../../constants/EnumConstants';
import { getBearerToken } from '../../functions/authActions';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import {  RenderTitleHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import { Col, Row } from 'reactstrap';

export default class VendorManagementTable extends React.Component<VendorManagementWidgetProps, VendorManagementWidgetState> {

  constructor(props: VendorManagementWidgetProps) {
    super(props);
    this.state = {
      vendors: [],
      companyName: "",
      headers: []
    }
    this.changeName = this.changeName.bind(this);
    this.updateCompanies = this.updateCompanies.bind(this);
    this.addVendor = this.addVendor.bind(this);
    this.generateHeaders = this.generateHeaders.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();

    const response = await axios.get('./api/vendors');

    if (response.status === 200) {
      const vendors: Array<VendorData> = [];
      response.data.forEach((item: VendorServerData) => {
        this.addVendor(vendors, item);
      })

      this.setState({
        vendors: vendors,
        headers: this.generateHeaders()
      })
    }
    else {
      //TODO: ERROR HANDLE
    }
  }

  addVendor(list: Array<VendorData>, item: VendorServerData): void {
    list.push({
      uid: item.uid,
      name: item.name,
      address: item.streetAddress.fullAddress,
      type: ExternalCompanyTypes_List[item.type],
      contacts: item.mainContacts?.map(function (item) { return item.isMainContact ? item.contact?.fullName : null })
        .filter(x => x !== null)
        .join(", "),
      email_list: item.mainContacts?.map(function (item) { return item.isMainContact ? item.contact?.fullName + " <" + item.contact?.emailAddress + ">" : null })
        .filter(x => x !== null)
        .join(", ")
    })
  }

  async changeName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      companyName: event.target.value
    }, await this.updateCompanies)
  }

  async updateCompanies(): Promise<void> {
    const name = this.state.companyName === "" ? "null" : this.state.companyName;
    const response = await axios.get('./api/vendors/filter/' + name)
    const vendors: Array<VendorData> = [];
    response.data.forEach((item: VendorServerData) => {
      this.addVendor(vendors, item);
    });

    this.setState({
      headers: this.generateHeaders(),
      vendors: vendors
    })
  }


  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        setting: true,
        columnName: "Name",
        filter: {
          type: "text",
          name: 'companyName',
          value: this.state.companyName,
          changeFilter: this.changeName,
          placeHolder: "Filter by Company Name"
        }
      },
      {
        setting: true,
        columnName: "Address",
        filter: {}
      },
      {
        setting: true,
        columnName: "Company",
        filter: {}
      },
      {
        setting: true,
        columnName: "Main Contacts",
        filter: {}
      },
      {
        setting: true,
        columnName: "Main Contact Email List",
        filter: {}
      }
    ]
  }


  render(): JSX.Element {
    return (
      <>
        {RenderTitleHeader("Vendors")}
        <div style={{ height: "80vh", overflowY: "auto"}}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              {
                this.state.vendors.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{item.address}</td>
                    <td className="fixedTableCell">{item.type}</td>
                    <td className="fixedTableCell">{item.contacts}</td>
                    <td className="fixedTableCell">{item.email_list}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faCog} style={{ marginLeft: "25%" }} onClick={() => window.location.assign('/vendor-form?uid=' + item.uid)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <Row>
          <Col>
            <input type="button" value="Add New Vendor" className="standard-input" onClick={() => window.location.assign('/vendor-form')} />
          </Col>
          <Col>
          </Col>
          <Col>
          </Col>
        </Row>
      </>
    )
  }
}