import axios from 'axios';
import * as React from 'react';

interface MigrateProps {

}

interface MigrateState {

}

export class Migrate extends React.Component<MigrateProps, MigrateState>{

  constructor(props: MigrateProps) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    await axios.get('./api/migrate').then(function (response) {

    });
  }

  render(): JSX.Element {
    return (
      <></>
    )
  }
}