import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { FileEntryModal } from '../../CoreComponents/Modals';
import { UnitUploadWidgetProps, UnitUploadWidgetState } from './DataUploadWidget.constants';

//[Obsolete("This Upload is Obsolete")]
export default class DataUploadWidget extends React.Component<UnitUploadWidgetProps, UnitUploadWidgetState> {

  fileRef = React.createRef<FileEntryModal>();

  constructor(props: UnitUploadWidgetProps) {
    super(props);
    this.state = {

    }
    this.updateProperty = this.updateProperty.bind(this)
    this.updateBuildings = this.updateBuildings.bind(this)
    this.updateUnits = this.updateUnits.bind(this)
    this.updateTenants = this.updateTenants.bind(this)
    this.updateUnitStatusHistory = this.updateUnitStatusHistory.bind(this)
  }

  async updateProperty(): Promise<void> {
    const data = new FormData();
    data.append("inputFile", this.fileRef.current.state.file)

    const response = await axios.post("./api/property/upload-from-fdb", data)
  }

  async updateBuildings(): Promise<void> {
    const data = new FormData();
    data.append("inputFile", this.fileRef.current.state.file)

    const response = await axios.post("./api/buildings/upload-from-fdb", data)
  }

  async updateUnits(): Promise<void> {
    const data = new FormData();
    data.append("inputFile", this.fileRef.current.state.file)

    const response = await axios.post("./api/units/upload-from-fdb", data)

  }

  async updateTenants(): Promise<void> {
    const data = new FormData();
    data.append("inputFile", this.fileRef.current.state.file)

    const response = await axios.post("./api/tenants/upload-from-fdb", data)

  }

  async updateUnitStatusHistory(): Promise<void> {
    const data = new FormData();
    data.append("inputFile", this.fileRef.current.state.file)

    const response = await axios.post("./api/unit-status-history/upload-from-fdb", data)

  }

  render(): JSX.Element {
    return (
      <div style={{ height: "69vh" }}>
        <FileEntryModal ref={this.fileRef} />
        <Row>
          <Col><h4>Upload Data CSV</h4></Col>
        </Row>
        <hr />
        <Row>
          <Col><input type="button" className="standard-input" value="Upload Property Data" onClick={() => this.fileRef.current.show("Upload Property Data CSV", "csv", this.updateProperty)} /></Col>
          <Col><input type="button" className="standard-input" value="Upload Building Data" onClick={() => this.fileRef.current.show("Upload Building Data CSV", "csv", this.updateBuildings)} /></Col>
          <Col><input type="button" className="standard-input" value="Upload Unit Data" onClick={() => this.fileRef.current.show("Upload Unit Data CSV", "csv", this.updateUnits)} /></Col>
        </Row>
        <br />
        <Row>
          <Col><input type="button" className="standard-input" value="Upload Tenant Data" onClick={() => this.fileRef.current.show("Upload Tenant Data CSV", "csv", this.updateTenants)} /></Col>
          <Col><input type="button" className="standard-input" value="Upload Unit Status History Data" onClick={() => this.fileRef.current.show("Upload Unit Status History Data CSV", "csv", this.updateUnitStatusHistory)} /></Col>
          <Col>{/*<input type="button" className="standard-input" value="Upload Unit Data" onClick={() => this.fileRef.current.show("Upload Unit Data CSV", "csv", this.updateUnits)} />*/}</Col>
        </Row>

      </div>
    )
  }
}