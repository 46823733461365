//#region Imports
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';
import { pullProperties } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { YesNoModal } from '../../CoreComponents/Modals';
import * as Structs from '../Structs';
//#endregion

export class ProjectsTable extends React.Component<Structs.ProjectsTableProps, Structs.ProjectsTableState> {

  modalRef = React.createRef<YesNoModal>();

  constructor(props: Structs.ProjectsTableProps) {
    super(props);
    this.state = {
      uid: props.uid,
      projects: [],
      headers: this.generateHeaders(),
      adding: false,
      properties: [],
      property: { label: "Select Property", value: "" },
      code: ""
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.updateCode = this.updateCode.bind(this);
    this.saveSite = this.saveSite.bind(this);
    this.removeSite = this.removeSite.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    const properties = await pullProperties();

    await axios.get("./api/managed-jobs/projects/" + this.state.uid).then(function (response) {
      const data = response.data;
      let projects: Array<Structs.ProjectData> = [];
      data.forEach(function (item: any) {
        projects.push({
          propertyUID: item.propertyUID,
          projectUID: item.projectUID,
          code: item.code,
          property: item.property
        });
      })
      self.setState({
        projects: projects,
        properties: properties
      })
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Property",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site Code",
        setting: true,
        filter: {}
      }
    ]
  }

  doNothing(): void {

  }

  updateProperty(event: SelectOptions | null): void {
    this.setState({
      property: event
    })
  }

  updateCode(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      code: event.target.value
    })
  }

  confirmRemove(site: string, code: string): void {
    this.modalRef.current.show(
      "Remove " + code + " from project?",
      "Confirm Remove Project",
      this.doNothing,
      () => this.removeSite(site),
      "Remove",
      "Cancel"
    )
  }

  async saveSite(): Promise<void> {
    this.setState({ adding: false })
    await axios.patch('./api/managed-jobs/add-site', { JobUID: this.state.uid, PropertyUID: this.state.property.value, Code: this.state.code }).then(function (response) {
      CreateGlobalAlert("Site has been added to Job", 2500);
      window.location.reload();
    })
  }

  async removeSite(site: string): Promise<void> {
    await axios.patch('./api/managed-jobs/remove-site', { ProjectMapUID: site }).then(function (response) {
      CreateGlobalAlert("Site has been removed from Job", 2500);
    });
    window.location.reload();
  }

  render(): JSX.Element {
    return (
      <div>
        <YesNoModal ref={this.modalRef} />
        <h3>Site Settings Table</h3>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
          </thead>
          <tbody>
            {
              this.state.projects.map((item) => (
                <tr key={item.propertyUID}>
                  <td className="fixedTableCellFirst">{item.property}</td>
                  <td className="fixedTableCell">{item.code}</td>
                  <td className="fixedTableCell">
                    <FontAwesomeIcon icon={faTrash} style={{ marginLeft: "30%" }} onClick={() => this.confirmRemove(item.projectUID, item.code)} />
                  </td>
                </tr>
              ))
            }
            {!this.state.adding
              ? <TableAdditionRow length={3} marginLeft={"25%"} onClick={() => this.setState({ adding: true })} />
              :
              <tr>
                <td className="fixedTableCellFirst">
                  <Select
                    styles={reactSelectBasicStyle}
                    options={this.state.properties}
                    value={this.state.property}
                    onChange={this.updateProperty}
                  />
                </td>
                <td className="fixedTableCell">
                  <input type="text" className="standard-input" value={this.state.code} onChange={this.updateCode} />
                </td>
                <td className="fixedTableCell">
                  <FontAwesomeIcon icon={faSave} style={{ marginLeft: "30%" }} onClick={this.saveSite} />
                </td>
              </tr>

            }
          </tbody>
        </table>
      </div>
    )
  }
}
