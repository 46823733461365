import React from "react";
import { RenderSettings, WidgetSettings } from "../interfaces/WidgetSettingsInterfaces";

export const DefaultPageSettings: RenderSettings = {
  rows: [
    {
      col: 2,
      widgets: [0, 1]
    }
  ]
}

export function DashboardOrganizer(tabList: Array<JSX.Element> | { [key: number]: JSX.Element }, renderSettings: RenderSettings): JSX.Element {
  const widgetsOrganized: Array<WidgetSettings> = [];
  let indexer: number = 0;
  renderSettings.rows.forEach((item) => {
    for (let i = 0; i < item.col; ++i) {
      widgetsOrganized.push({
        widget: tabList[item.widgets[i]],
        key: indexer,
        col: item.col,
      });
      indexer++;
    }
  });
  return (
    <div style={{ overflowY: "scroll", maxHeight: "80vh", borderCollapse: "collapse", scrollSnapType: "y mandatory", scrollSnapStop: "always" }}>
      {
        widgetsOrganized.map((widget: WidgetSettings, idx: number) => {
          let divElement: React.HTMLAttributes<HTMLDivElement> = {
            style: {}
          }
          if (widget.col === 1) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", width: "99%", marginLeft: "1%", float: "left", height: "39vh", marginBottom: "1vh", overflow: "auto", scrollSnapAlign: "start" }
          }
          else if (widget.col === 2 && idx % 2 === 0) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", float: "left", width: "49%", padding: "1%", height: "39vh", marginBottom: "1vh", overflow: "auto", marginLeft: "1%", scrollSnapAlign: "start"}
          }
          else if (widget.col === 2 && idx % 2 > 0) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", float: "left", width: "49%", padding: "1%", height: "39vh", marginBottom: "1vh", overflow: "auto", marginLeft: "1%", scrollSnapAlign: "start" }
          }
          else if (widget.col === 3 && idx % 3 === 0) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", float: "left", width: "32%", padding: "1%", height: "39vh", marginBottom: "1vh", overflow: "auto", marginLeft: "1%", scrollSnapAlign: "start" }
          }
          else if (widget.col === 3 && idx % 3 === 1) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", float: "left", width: "33%", padding: "1%", height: "39vh", marginBottom: "1vh", overflow: "auto", marginLeft: "1%", scrollSnapAlign: "start" }
          }
          else if (widget.col === 3 && idx % 3 === 2) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", float: "left", width: "32%", padding: "1%", height: "39vh", marginBottom: "1vh", overflow: "auto", marginLeft: "1%", scrollSnapAlign: "start" }
          }
          else if (widget.col === 4 && idx % 4 === 0) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", float: "left", width: "24%", padding: "1%", height: "39vh", marginBottom: "1vh", overflow: "auto", marginLeft: "1%", scrollSnapAlign: "start" }
          }
          else if (widget.col === 4 && idx % 4 > 0) {
            divElement.style = { border: "2px solid #c2a877", background: "#4c4a42", display: "block", float: "left", width: "24%", padding: "1%", height: "39vh", marginBottom: "1vh", overflow: "auto", marginLeft: "1%", scrollSnapAlign: "start" }
          }

          return (
            <div key={widget.key} style={divElement.style}>
              {widget.widget}
            </div>
          )
        })
      }
    </div>
  )

}