import { faArrowAltCircleDown, faBinoculars, faRecycle, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { getUserID, getUserJob } from "../../../../functions/authActions";
import { CreateGlobalAlert } from "../../../../functions/CreateGlobalAlerts";
import { pullFleetEmployee, pullStates } from "../../../../functions/fetchLinkedObjects";
import { SelectOptions } from "../../../../interfaces/CoreInterfaces";
import FilteredWithSettingsTableHeader from "../../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../../CoreComponents/interfaces";
import { EntityPurpose_NewRequest } from "../../Structs";
import { DownloadNamedFile } from "../../../../functions/documentTools";

export interface NewEntityInProgressTableProps {

}

export interface NewEntityInProgressTableState {
  headers: any;
  draftsList: Array<any>;
  employees: Array<any>;
  states: Array<any>;
}

export const NewEntityWorkFlowSteps: Array<SelectOptions> = [
  { label: "Ready For Review", value: "1" },
  { label: "Ready To Distribute", value: "2" },
  { label: "Waiting For Draft Approval", value: "3" },
  { label: "Final Draft Review", value: "4" },
  { label: "Ready To Send For Signatures", value: "5" },
  { label: "Out For Signatures", value: "6" },
  { label: "Document Has All Signatures", value: "7" },
  { label: "ENTITY_REGISTERED_WITH_SOS", value: "8" },
  { label: "TAX_ID_REQUESTED", value: "9" },
  { label: "CONTROLLER_AND_ACCOUNTANT_NOTIFIED", value: "10" },
  { label: "BANK_ACCOUNTS_ESTABLISHED", value: "11" },
  { label: "NEW_YARDI_ENTITY_REQUESTED", value: "12" },
]

export default class NewEntityInProgressTable extends React.Component<NewEntityInProgressTableProps, NewEntityInProgressTableState> {

  constructor(props: NewEntityInProgressTableProps) {
    super(props);
    this.state = {
      headers: [],
      draftsList: [],
      employees: [],
      states: []
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.getLines = this.getLines.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
    this.checkSignatures = this.checkSignatures.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders()
    const employees = await pullFleetEmployee();
    const states = await pullStates();

    this.setState({
      employees: employees,
      states: states
    })

    await this.getLines();

  }

  async getLines(): Promise<void> {
    const drafts: any = [];
    const response = await axios.get('./api/new-entity-workflow/in-progress')

    response.data.forEach((item: any) => {
      drafts.push({
        uid: item.uid,
        entityName: item.entity.entityName,
        requestor: { label: this.state.employees.filter(u => u.value === item.entity.requestorUID)[0].label, value: item.entity.requestorUID }.label,
        formationState: { label: this.state.states.filter(u => parseInt(u.value) === item.entity.formationState)[0].label, value: item.entity.formationState.toString() }.label,
        entityPurpose: { label: EntityPurpose_NewRequest.filter(u => parseInt(u.value) === item.entity.entityPurpose)[0].label, value: item.entity.entityPurpose.toString() }.label,
        status: NewEntityWorkFlowSteps.filter(u => u.value === item.status.toString())[0]
      })
    })

    this.setState({ draftsList: drafts })

    CreateGlobalAlert("List Updated",2500)
  }

  componentDidUpdate(): void {

  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Entity Name',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: 'Requestor',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: 'Formation State',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: 'Purpose',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: 'Status',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      }
    ]
    this.setState({ headers: headers })
  }

  async downloadDocument(uid: string, status: string): Promise<void> {

    if (status === "6") {
      await axios.patch('./api/new-entity-workflow/next-node/' + uid)
    }

    let response = await axios.get('./api/new-entity-workflow/get-document/' + uid, { responseType: 'blob' });
    await DownloadNamedFile(response);
    CreateGlobalAlert("Document has been downloaded to your Downloads Folder", 2500);
  }

  async checkSignatures(uid: string): Promise<void> {

    await axios.patch('./api/new-entity-workflow/next-node/' + uid)

    CreateGlobalAlert("Checking for All Signatures", 2500);

    await this.getLines();
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "78vh", overflowY: "auto", overflowX: "hidden", paddingBottom: "5vh" }} >
        <Row>
          <Col>
            <div id="new-entity-request-drafts-table">
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.headers} icons={2} />
                </thead>
                <tbody>
                  <>
                    {this.state.draftsList.map((item, idx) =>
                      <NewEntityRequestDraftRow key={idx} draft={item} refresh={() => this.checkSignatures(item.uid)} download={() => this.downloadDocument(item.uid, item.status.value)} />
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div >
    )
  }
}

interface NewEntityRequestDraftRowProps {
  draft: any;
  refresh: () => void;
  download: (draftUID: string, status: string) => void;
}
interface NewEntityRequestDraftRowState {
  draft: any;
  refresh: () => void;
  download: (draftUID: string, status: string) => void;
}

class NewEntityRequestDraftRow extends React.Component<NewEntityRequestDraftRowProps, NewEntityRequestDraftRowState>{
  constructor(props: NewEntityRequestDraftRowProps) {
    super(props);
    this.state = {
      draft: props.draft,
      refresh: props.refresh,
      download: props.download
    }
    this.viewDraft = this.viewDraft.bind(this);
    this.checkDocument = this.checkDocument.bind(this);
  }

  viewDraft(): void {
    window.location.assign("./entity-request-draft-review?uid=" + this.state.draft.uid);
  }

  async checkDocument(): Promise<void> {
    await axios.patch('./api/new-entity-workflow/next-node/' + this.state.draft.uid)
    CreateGlobalAlert("Checking For Signatures", 2500);
    await this.state.refresh();
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.state.draft.entityName}</td>
        <td className="fixedTableCell">{this.state.draft.requestor}</td>
        <td className="fixedTableCell">{this.state.draft.formationState}</td>
        <td className="fixedTableCell">{this.state.draft.entityPurpose}</td>
        <td className="fixedTableCell">{this.state.draft.status.label}</td>
        {this.state.draft.status.value !== "6" && <td className="fixedTableCell">
          <FontAwesomeIcon icon={faBinoculars} style={{ marginLeft: "30%" }} onClick={this.viewDraft} />
        </td>}
        {this.state.draft.status.value === "6" && <td className="fixedTableCell">
          <FontAwesomeIcon icon={faRedo} style={{ marginLeft: "30%" }} onClick={this.checkDocument} />
        </td>}
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faArrowAltCircleDown} style={{ marginLeft: "30%" }} onClick={() => this.state.download(this.state.draft.uid, this.state.draft.status.value)} />
        </td>
      </tr>
    )
  }
}