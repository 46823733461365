import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldHidden, DataEntryModalFieldSelect, DataEntryModalFieldStatic } from '../../CoreComponents/DataEntryModal';
import { pullPropertyName } from '../../../functions/fetchObjectNameFunctions';
import { pullVirdianCompliance, pullViridianAccountants, pullViridianMaintenanceManagers, pullViridianRegionals, pullViridianSiteManagers } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { PullRegionalManager, PullSiteManagers } from '../../../functions/fetchPropertyContacts';

interface PropertyStaffEditModalData {
  property: string;
  regional: SelectOptions;
  siteManagers: Array<SelectOptions>;
  maintenanceTechs: Array<SelectOptions>;
}

interface PropertyStaffEditModalState {
  propertyUID: string;
  data: PropertyStaffEditModalData;
  regionals: Array<SelectOptions>;
  siteStaff: Array<SelectOptions>;
  accountants: Array<SelectOptions>;
  compliance: Array<SelectOptions>;
}

export default class PropertyStaffEditModal extends React.Component<{}, PropertyStaffEditModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      propertyUID: "",
      regionals: [],
      siteStaff: [],
      accountants: [],
      compliance: [],
      data: {
        property: "",
        regional: { label: "Select Regional", value: "" },
        siteManagers: [],
        maintenanceTechs: []
      }
    }
  }

  async componentDidMount(): Promise<void> {

  }

  async show(uid: string): Promise<void> {
    let propertyName = await pullPropertyName(uid);
    let regionals = await pullViridianRegionals();
    let siteStaff = (await pullViridianSiteManagers()).concat(await pullViridianMaintenanceManagers());
    let accountants = await pullViridianAccountants();
    let compliance = await pullVirdianCompliance();
    let regional = await PullRegionalManager(uid);
    let siteManagers = await PullSiteManagers(uid);
    let data = this.state.data;
    data.property = propertyName;
    data.regional = regional;
    data.siteManagers = siteManagers;
    this.setState({
      propertyUID: uid, 
      data: data, 
      regionals: regionals, 
      siteStaff: siteStaff, 
      accountants: accountants,
      compliance: compliance
    }, async () => this.generateData());
  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "property", name: "Property", patchUrl: undefined, value: this.state.data.property, static: true } as DataEntryModalFieldStatic);
    data.push({ key: "regionalUID", name: "Regional", patchUrl: "./api/property-employee-map/set-regional", value: this.state.data.regional, tracked: true, options: this.state.regionals, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "siteManagers", name: "Site Managers", patchUrl: './api/property-employee-map/patch-site-managers', value: this.state.data.siteManagers, tracked: true, options: this.state.siteStaff, isMulti: true } as DataEntryModalFieldSelect);
    data.push({ key: "maintenanceTechs", name: "Maintenance Techs", patchUrl: './api/property-employee-map/patch-maintenance-managers', value: this.state.data.maintenanceTechs, tracked: true, options: this.state.siteStaff, isMulti: true } as DataEntryModalFieldSelect);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Property Staff", this.state.propertyUID, undefined);
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={null} overrideSave={() => window.location.reload()} />
      </>
    )
  }
}