import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { getUserID } from '../../../functions/authActions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketSummaryData {
  uid: string;
  email: string;
  problem: string;
  work: string;
}

interface AssignedTicketsWidgetState {
  headers: Array<FilterAndSettingField>;
  tickets: Array<TicketSummaryData>;
}

export class AssignedTicketsWidget extends React.Component<{}, AssignedTicketsWidgetState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      headers: [],
      tickets: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    let response = await axios.get('./api/tickets/current/' + getUserID(), { validateStatus: () => true });
    if (response.status === 200) {
      this.setState({
        tickets: response.data
      })
    }
  }

  loadWorkLog(uid: string): void {
    window.open("./ticket-work-manager?uid=" + uid);
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Work Number',
        additionalClasses: "twenty",
        setting: true,
        filter: {}
      },
      {
        columnName: 'Email',
        setting: true,
        filter: {}
      },
      {
        columnName: 'Problem',
        setting: true,
        filter: {}
      },
    ]
    this.setState({
      headers: headers
    })
  }

  render(): JSX.Element {
    return (
      <Row style={{ paddingBottom: "5vh" }}>
        <Col>
          {RenderHeader("Assigned Tickets")}
          <div id="ticket-management-table" style={{ marginLeft: "1%", overflow: "auto", minHeight: "32vh", maxHeight: "32vh" }}>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
              </thead>
              <tbody>
                {
                  this.state.tickets.map((item) => (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst">{item.work}</td>
                      <td className="fixedTableCell">{item.email}</td>
                      <td className="fixedTableCell" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflowX: "hidden" }}>{item.problem}</td>
                      <td className="fixedTableCell">
                        <FontAwesomeIcon icon={faCog} style={{ marginLeft: "15%" }} onClick={() => this.loadWorkLog(item.uid)} />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    )
  }
}