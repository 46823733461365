import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { bindDecimalPlaces } from '../../../functions/numberFunctions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { BarGraph, BarGraphState } from '../../CoreComponents/GraphComponents';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import ReactToPrint from 'react-to-print';
import { Jobs, Teams, ValidateAuth } from '../../../functions/authActions';

//#region Interfaces
interface MCIReportingProps {

}

interface MCIReportingState {
  courses: Array<any>;
  avgStudents: number;
  courseData: Array<any>;
  headers: Array<any>;
  hideClasses: boolean;
  hideMonthlyData: boolean;
  totalIncome: number;
  totalStudents: number;
  totalClasses: number;
  currentTab: number;
  barGraphOverallAllTimeData: BarGraphState;
  barGraphOverallYearlyData: BarGraphState;
  barGraphOverallMonthlyData: BarGraphState;
  barGraphIncomeAllTimeData: BarGraphState;
  barGraphIncomeYearlyData: BarGraphState;
  barGraphIncomeMonthlyData: BarGraphState;
  barGraphStudentsAllTimeData: BarGraphState;
  barGraphStudentsYearlyData: BarGraphState;
  barGraphStudentsMonthlyData: BarGraphState;
  barGraphClassAllTimeData: BarGraphState;
  barGraphClassYearlyData: BarGraphState;
  barGraphClassMonthlyData: BarGraphState;
  barGraphParentTab: number;
  barGraphChildTab: number;
  data: Array<MCIReportingYears>;
  yearOptions: Array<SelectOptions>;
  selectedYear: SelectOptions;
}

interface MCIReportingYears {
  year: number;
  months: Array<MCIReportingMonths>;
  income: number;
  students: number;
  classes: number;
}

interface MCIReportingMonths {
  month: number;
  label: string;
  income: number;
  students: number;
  classes: number
}
//#endregion

function createYearArray(): Array<MCIReportingYears> {
  const years: Array<MCIReportingYears> = [];

  for (let i = 2020; i <= new Date(Date.now()).getFullYear(); i++) {
    years.push({
      year: i,
      months: [
        { month: 0, label: "January", income: 0, students: 0, classes: 0 },
        { month: 1, label: "February", income: 0, students: 0, classes: 0 },
        { month: 2, label: "March", income: 0, students: 0, classes: 0 },
        { month: 3, label: "April", income: 0, students: 0, classes: 0 },
        { month: 4, label: "May", income: 0, students: 0, classes: 0 },
        { month: 5, label: "June", income: 0, students: 0, classes: 0 },
        { month: 6, label: "July", income: 0, students: 0, classes: 0 },
        { month: 7, label: "August", income: 0, students: 0, classes: 0 },
        { month: 8, label: "September", income: 0, students: 0, classes: 0 },
        { month: 9, label: "October", income: 0, students: 0, classes: 0 },
        { month: 10, label: "November", income: 0, students: 0, classes: 0 },
        { month: 11, label: "December", income: 0, students: 0, classes: 0 },
      ],
      income: 0,
      students: 0,
      classes: 0
    })
  }

  return years;
}

function createSelectOptionsYears(years: Array<MCIReportingYears>): Array<SelectOptions> {
  const selectYears: Array<SelectOptions> = []

  years.forEach((year: MCIReportingYears, idx) => {
    selectYears.push(
      { label: year.year.toString(), value: idx.toString() }
    )
  })

  return selectYears
}

export default class MCIReporting extends React.Component<MCIReportingProps, MCIReportingState> {
  printWrapper = React.createRef<any>();

  constructor(props: MCIReportingProps) {
    super(props);
    this.state = {
      courses: [],
      avgStudents: 0,
      courseData: [],
      headers: [],
      hideClasses: false,
      hideMonthlyData: false,
      totalIncome: 0,
      totalStudents: 0,
      totalClasses: 0,
      currentTab: 0,
      barGraphOverallAllTimeData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "# per Category",
        xAxisLabel: "Categories",
        values: []
      },
      barGraphOverallYearlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "# per Year",
        xAxisLabel: "Years",
        values: []
      },
      barGraphOverallMonthlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "# per Month",
        xAxisLabel: "Months",
        values: []
      },
      barGraphIncomeAllTimeData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "USD",
        xAxisLabel: "All Time",
        values: []
      },
      barGraphIncomeYearlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "USD",
        xAxisLabel: "Year",
        values: []
      },
      barGraphIncomeMonthlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "USD",
        xAxisLabel: "Months",
        values: []
      },
      barGraphStudentsAllTimeData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "Students",
        xAxisLabel: "All Time",
        values: []
      },
      barGraphStudentsYearlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "Students",
        xAxisLabel: "Years",
        values: []
      },
      barGraphStudentsMonthlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "Students",
        xAxisLabel: "Months",
        values: []
      },
      barGraphClassAllTimeData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "Classes",
        xAxisLabel: "All Time",
        values: []
      },
      barGraphClassYearlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "Classes",
        xAxisLabel: "Years",
        values: []
      },
      barGraphClassMonthlyData: {
        width: "1000px",
        height: "750px",
        colors: [
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42",
          "#4C4A42"
        ],
        textColor: "#b89961",
        yAxisLabel: "Classes",
        xAxisLabel: "Months",
        values: []
      },
      barGraphParentTab: 0,
      barGraphChildTab: 0,
      data: createYearArray(),
      yearOptions: [],
      selectedYear: { label: "Choose Year", value: "-1" }
    }
    this.averageStudentsPerClass = this.averageStudentsPerClass.bind(this);
    this.getCourseData = this.getCourseData.bind(this);
    this.analyzeCourseData = this.analyzeCourseData.bind(this);
    this.getHeaders = this.getHeaders.bind(this);
    this.setBarGraph = this.setBarGraph.bind(this);
    this.selectYear = this.selectYear.bind(this);
  }

  async componentDidMount(): Promise<void> {

    ValidateAuth([Jobs.President, Jobs.Administrator], [Teams.MCI]);

    await axios.get('./api/active-courses/').then((response) => {
      this.setState({ courses: response.data })
    })
    this.averageStudentsPerClass();
    this.getCourseData();
    this.analyzeCourseData();
    this.getHeaders();

    const selectYears = createSelectOptionsYears(this.state.data)
    this.setState({ yearOptions: selectYears })
  }

  componentDidUpdate(): void {

  }

  averageStudentsPerClass(): void {
    let total = 0;

    this.state.courses.forEach((item) => {
      total += item.students.length;
    })

    const avg = bindDecimalPlaces(total / this.state.courses.length);
    this.setState({ avgStudents: avg });
  }

  getCourseData(): void {
    this.state.courses.forEach((item) => {
      let baseCost = 0;
      if (new Date(item.startDate).getFullYear() >= 2020 &&
        (new Date(item.startDate).getFullYear() <= Date.now() &&
          new Date(item.startDate).getMonth() <= Date.now() &&
          new Date(item.startDate).getDate() <= Date.now()
        )) {
        item.activeCourses.forEach((temp: any) => {
          baseCost += temp.courseConfig.cost
        })
        const totalIncome = baseCost * item.students.length;
        this.state.courseData.push({
          name: item.name,
          year: new Date(item.startDate).getFullYear(),
          month: new Date(item.startDate).getMonth(),
          cost: baseCost,
          income: totalIncome,
          students: parseInt(item.students.length),
          classes: parseInt(item.activeCourses.length)
        });
      }
    })
  }

  analyzeCourseData(): void {
    const data: Array<MCIReportingYears> = this.state.data;
    let totalIncome = 0;
    let totalStudents = 0;
    let totalClasses = 0;

    this.state.courseData.forEach((item) => {
      const yIdx = data.findIndex(u => u.year === item.year);
      const mIdx = data[yIdx].months.findIndex(u => u.month === item.month);

      data[yIdx].months[mIdx].income += item.income;
      data[yIdx].months[mIdx].students += item.students;
      data[yIdx].months[mIdx].classes += item.classes;

      data[yIdx].income += item.income;
      data[yIdx].students += item.students;
      data[yIdx].classes += item.classes;

      this.setState({ data: data });

      totalIncome += item.income;
      totalStudents += item.students;
      totalClasses += item.classes;
    })

    this.state.barGraphOverallAllTimeData.values.push(
      {
        label: "Total Income",
        value: totalIncome,
        checked: false,
        key: 0
      },
      {
        label: "Total Students",
        value: totalStudents,
        checked: false,
        key: 0
      },
      {
        label: "Total Classes",
        value: totalClasses,
        checked: false,
        key: 0
      },
    )
    this.state.barGraphIncomeAllTimeData.values.push(
      {
        label: "Total Income",
        value: totalIncome,
        checked: false,
        key: 0
      }
    )
    this.state.barGraphStudentsAllTimeData.values.push(
      {
        label: "Total Students",
        value: totalStudents,
        checked: false,
        key: 0
      }
    )
    this.state.barGraphClassAllTimeData.values.push(
      {
        label: "Total Classes",
        value: totalClasses,
        checked: false,
        key: 0
      }
    )

    this.state.data.forEach((year, idx) => {
      this.state.barGraphOverallYearlyData.values.push(
        {
          label: year.year.toString() + " Income",
          value: year.income,
          checked: false,
          key: idx
        },
        {
          label: year.year.toString() + " Students",
          value: year.students,
          checked: false,
          key: idx
        },
        {
          label: year.year.toString() + " Classes",
          value: year.classes,
          checked: false,
          key: idx
        }
      )
      this.state.barGraphIncomeYearlyData.values.push(
        {
          label: year.year.toString(),
          value: year.income,
          checked: false,
          key: idx
        }
      )
      this.state.barGraphStudentsYearlyData.values.push(
        {
          label: year.year.toString(),
          value: year.students,
          checked: false,
          key: idx
        }
      )
      this.state.barGraphClassYearlyData.values.push(
        {
          label: year.year.toString(),
          value: year.classes,
          checked: false,
          key: idx
        }
      )
    })

    this.setState({
      totalIncome: totalIncome,
      totalStudents: totalStudents,
      totalClasses: totalClasses
    })
  }

  getHeaders(): void {
    const headers = [
      {
        columnName: 'Reporting Table',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      }
    ]
    this.setState({ headers: headers })
  }

  setBarGraph(): JSX.Element {
    if (this.state.barGraphParentTab === 0) {
      if (this.state.barGraphChildTab === 0) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphOverallAllTimeData} />
      }
      else if (this.state.barGraphChildTab === 1) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphOverallYearlyData} />
      }
      else if (this.state.barGraphChildTab === 2) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphOverallMonthlyData} />
      }
    }
    else if (this.state.barGraphParentTab === 1) {
      if (this.state.barGraphChildTab === 0) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphIncomeAllTimeData} />
      }
      else if (this.state.barGraphChildTab === 1) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphIncomeYearlyData} />
      }
      else if (this.state.barGraphChildTab === 2) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphIncomeMonthlyData} />
      }
    }
    else if (this.state.barGraphParentTab === 2) {
      if (this.state.barGraphChildTab === 0) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphStudentsAllTimeData} />
      }
      else if (this.state.barGraphChildTab === 1) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphStudentsYearlyData} />
      }
      else if (this.state.barGraphChildTab === 2) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphStudentsMonthlyData} />
      }
    }
    else if (this.state.barGraphParentTab === 3) {
      if (this.state.barGraphChildTab === 0) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphClassAllTimeData} />
      }
      else if (this.state.barGraphChildTab === 1) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphClassYearlyData} />
      }
      else if (this.state.barGraphChildTab === 2) {
        return <BarGraph key={Math.random()} barGraphProps={this.state.barGraphClassMonthlyData} />
      }
    }
  }

  selectYear(event: SelectOptions | null): void {
    if (event === null) { return; }

    const overall = this.state.barGraphOverallMonthlyData;
    const income = this.state.barGraphIncomeMonthlyData;
    const students = this.state.barGraphStudentsMonthlyData;
    const classes = this.state.barGraphClassMonthlyData;

    if (overall.values.length > 0) {
      const empty = this.state.barGraphOverallMonthlyData
      empty.values = []
      this.setState({ barGraphOverallMonthlyData: empty })
    }
    if (income.values.length > 0) {
      const empty = this.state.barGraphIncomeMonthlyData
      empty.values = []
      this.setState({ barGraphIncomeMonthlyData: empty })
    }
    if (students.values.length > 0) {
      const empty = this.state.barGraphStudentsMonthlyData
      empty.values = []
      this.setState({ barGraphStudentsMonthlyData: empty })
    }
    if (classes.values.length > 0) {
      const empty = this.state.barGraphClassMonthlyData
      empty.values = []
      this.setState({ barGraphClassMonthlyData: empty })
    }

    this.state.data.forEach((year: MCIReportingYears) => {
      if (event.label === year.year.toString()) {
        year.months.forEach((month: MCIReportingMonths, idx) => {
          overall.values.push(
            {
              label: month.label + " Income",
              value: month.income,
              checked: false,
              key: idx
            },
            {
              label: month.label + " Students",
              value: month.students,
              checked: false,
              key: idx
            },
            {
              label: month.label + " Classes",
              value: month.classes,
              checked: false,
              key: idx
            }
          )
          income.values.push(
            {
              label: month.label,
              value: month.income,
              checked: false,
              key: idx
            }
          )
          students.values.push(
            {
              label: month.label,
              value: month.students,
              checked: false,
              key: idx
            }
          )
          classes.values.push(
            {
              label: month.label,
              value: month.classes,
              checked: false,
              key: idx
            }
          )
        })
      }
    })

    this.setState({
      selectedYear: event,
      barGraphOverallMonthlyData: overall,
      barGraphIncomeMonthlyData: income,
      barGraphStudentsMonthlyData: students,
      barGraphClassMonthlyData: classes
    })
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col style={{ marginBottom: "10px" }}>
            <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "50%" }} value="Table" onClick={() => this.setState({ currentTab: 0 })} />
            <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "50%" }} value="Graphics" onClick={() => this.setState({ currentTab: 1 })} />
          </Col>
        </Row>
        <div style={{ height: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
          {this.state.currentTab === 0 && <table>
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              <tr style={{ height: "3vh" }}>
                <td className="fixedTableCellFirst">
                  {this.state.hideClasses ?
                    <input type="button" className="standard-input" style={{ height: "3vh" }} value="Show Classes" onClick={() => this.setState({ hideClasses: false })} />
                    :
                    <input type="button" className="standard-input" style={{ height: "3vh" }} value="Hide Classes" onClick={() => this.setState({ hideClasses: true })} />
                  }
                </td>
                <td className="fixedTableCell">
                  {this.state.hideMonthlyData ?
                    <input type="button" className="standard-input" style={{ height: "3vh" }} value="Show Monthly Data" onClick={() => this.setState({ hideMonthlyData: false })} />
                    :
                    <input type="button" className="standard-input" style={{ height: "3vh" }} value="Hide Monthly Data" onClick={() => this.setState({ hideMonthlyData: true })} />
                  }
                </td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
              </tr>
              <tr>
                <td className="fixedTableCellFirst">General Data</td>
                <td className="fixedTableCell">Average Students Per Class</td>
                <td className="fixedTableCell">Total Income</td>
                <td className="fixedTableCell">Total Students</td>
                <td className="fixedTableCell">Total Classes</td>
              </tr>
              <tr style={{ height: "3vh" }}>
                <td className="fixedTableCellFirst"></td>
                <td className="fixedTableCell">{this.state.avgStudents}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>${this.state.totalIncome}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>{this.state.totalStudents}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>{this.state.totalClasses}</td>
              </tr>
              <tr style={{ height: "3vh" }}>
                <td className="fixedTableCellFirst"></td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
              </tr>
              {!this.state.hideClasses && <tr>
                <td className="fixedTableCellFirst">Total Income Per Class</td>
                <td className="fixedTableCell">Class Name</td>
                <td className="fixedTableCell">Income</td>
                <td className="fixedTableCell">Students</td>
                <td className="fixedTableCell">Income Per Student</td>
              </tr>}
              {!this.state.hideClasses && <>
                {
                  this.state.courseData.map((item) =>
                    <tr>
                      <td className="fixedTableCellFirst"></td>
                      <td className="fixedTableCell">{item.name}</td>
                      <td className="fixedTableCell" style={{ textAlign: "right" }}>${item.income}</td>
                      <td className="fixedTableCell" style={{ textAlign: "right" }}>{item.students}</td>
                      <td className="fixedTableCell" style={{ textAlign: "right" }}>${item.income / item.students}</td>
                    </tr>
                  )
                }
              </>}
              {!this.state.hideClasses && <tr style={{ height: "3vh" }}>
                <td className="fixedTableCellFirst"></td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
              </tr>}
              {!this.state.hideMonthlyData && <tr style={{ height: "3vh" }}>
                <td className="fixedTableCellFirst">Monthly Data</td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell">Total Income</td>
                <td className="fixedTableCell">Number of Students</td>
                <td className="fixedTableCell">Number of Classes</td>
              </tr>}
              {!this.state.hideMonthlyData && <>
                {
                  this.state.data.map((year) =>
                    <>
                      {
                        year.months.map((month) =>
                          <tr>
                            <td className="fixedTableCellFirst"></td>
                            <td className="fixedTableCell">{month.label + " " + year.year}</td>
                            <td className="fixedTableCell" style={{ textAlign: "right" }}>${month.income}</td>
                            <td className="fixedTableCell" style={{ textAlign: "right" }}>{month.students}</td>
                            <td className="fixedTableCell" style={{ textAlign: "right" }}>{month.classes}</td>
                          </tr>
                        )}
                    </>
                  )}
              </>}
            </tbody>
          </table>}
          {this.state.currentTab === 1 && <div>
            <Row>
              <h4>MCI Graphic Analytics</h4>
            </Row>
            <Row style={{ marginTop: "1vh" }}>
              <Row>
                <div ref={this.printWrapper} className="mciReportingPrint" >
                  {this.setBarGraph()}
                </div>
              </Row>
              <Row style={{ marginTop: "2vh" }}>
                <input type="button" className={this.state.barGraphParentTab === 0 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Overall Data" onClick={() => this.setState({ barGraphParentTab: 0 })} />
                <input type="button" className={this.state.barGraphParentTab === 1 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Income Data" onClick={() => this.setState({ barGraphParentTab: 1 })} />
                <input type="button" className={this.state.barGraphParentTab === 2 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Student Data" onClick={() => this.setState({ barGraphParentTab: 2 })} />
                <input type="button" className={this.state.barGraphParentTab === 3 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Class Data" onClick={() => this.setState({ barGraphParentTab: 3 })} />
              </Row>
              <Row>
                <input type="button" className={this.state.barGraphChildTab === 0 ? "tab-active" : "tab"} style={{ width: "25%" }} value="All Time" onClick={() => this.setState({ barGraphChildTab: 0 })} />
                <input type="button" className={this.state.barGraphChildTab === 1 ? "tab-active" : "tab"} style={{ width: "25%" }} value="By Year" onClick={() => this.setState({ barGraphChildTab: 1 })} />
                <input type="button" className={this.state.barGraphChildTab === 2 ? "tab-active" : "tab"} style={{ width: "25%" }} value="By Month" onClick={() => this.setState({ barGraphChildTab: 2 })} />
                <>                  {this.state.barGraphChildTab === 2 &&
                  <div style={{ width: "25%" }}>
                    <Select
                      value={this.state.selectedYear}
                      styles={reactSelectBasicStyle}
                      options={this.state.yearOptions}
                      onChange={this.selectYear}
                      menuPlacement="top"
                    />
                  </div>}</>
              </Row>
            </Row>
          </div>}
          <Row style={{ marginTop: "2vh" }}>
            {this.state.currentTab === 1 && <ReactToPrint
              trigger={() => <input type="button" className="standard-input no-print" style={{ width: "50%", marginLeft: "25%" }} value="Print" />}
              content={() => this.printWrapper.current}
            />}
          </Row>
        </div>
      </>
    )
  }

}