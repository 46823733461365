import * as React from 'react'
import axios from 'axios';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Select from "react-select";
import { EditYearDataModalProps, EditYearDataModalState } from './CNALineItemDisplay.YearData.EditYearDataModal.constants';
import { CNALineItemData, CNAYearModelInterface } from './CNALineItemDisplay.YearData.constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { getUserID } from '../../../functions/authActions';
import { PatchNumberAuditRequest } from '../../PatchRequests';

export default class EditYearDataModal extends React.Component<EditYearDataModalProps, EditYearDataModalState> {
  constructor(props: EditYearDataModalProps) {
    super(props);
    this.state = {
      show: false,
      title: "",
      lineItem: {
        uid: "",
        lineItem: "",
        isHealthAndSafetyIssue: false,
        yearData: [],
        totalCost: 0,
        duration: 0
      },
      yearOptions: [],
      selectedYear: { label: "Select Year", value: "" },
      totalCost: 0,
      duration: 0,
      dividedTotal: 0,
      callback: undefined
    }
    this.show = this.show.bind(this)
    this.callback = this.callback.bind(this)
    this.changeYear = this.changeYear.bind(this)
    this.updateDuration = this.updateDuration.bind(this)
  }

  show(title: string, inputData: CNALineItemData, callback: () => void): void {
    const yearOptions: Array<SelectOptions> = []

    inputData.yearData.map((yearData: CNAYearModelInterface) => {
      yearOptions.push({ value: yearData.uid, label: yearData.year.toString() })
    })

    this.setState({
      show: true,
      title: title,
      lineItem: inputData,
      yearOptions: yearOptions,
      totalCost: inputData.totalCost,
      duration: inputData.duration,
      dividedTotal: inputData.totalCost / inputData.duration,
      callback: callback
    })
  }

  async callback(): Promise<void> {
    for (let i = 0; i < 21; i++) {
      var yearChangeUID = this.state.lineItem.yearData[i].uid
      const data = {
        UID: yearChangeUID,
        Amount: 0
      }

      await axios.patch('./api/cna-line-item-year-data', data)
    }

    for (let i = 0; i < this.state.duration; i++) {
      var yearChangeUID = this.state.lineItem.yearData.filter(u => u.year === parseInt(this.state.selectedYear.label) + i)[0].uid
      const data = {
        UID: yearChangeUID,
        Amount: Math.ceil(this.state.dividedTotal)
      }

      await axios.patch('./api/cna-line-item-year-data', data)
    }

    this.setState({
      show: false
    });

    if (this.state.callback) {
      await this.state.callback();
    }
  }

  changeYear(event: SelectOptions | null): void {
    if (event === null) { return }
    this.setState({ selectedYear: event })
  }

  async updateDuration(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const data = {
      UID: this.state.lineItem.uid,
      Duration: parseInt(event.target.value)
    }

    this.setState({
      duration: parseInt(event.target.value),
      dividedTotal: this.state.totalCost / parseInt(event.target.value)
    })

    await PatchNumberAuditRequest(this.state.lineItem.uid, getUserID(), parseInt(event.target.value), './api/cna-line-item/update-duration', null)

  }

  render(): JSX.Element {
    return (
      <>
        <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
          <ModalHeader style={{ backgroundColor: "#4C4A42" }}>
            <h3 style={{ color: "#d7c7a7" }}>{this.state.title}</h3>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
            <Row>
              <Col>Year To Begin Funding:</Col>
              <Col>
                <Select
                  value={this.state.selectedYear}
                  styles={reactSelectBasicStyle}
                  options={this.state.yearOptions}
                  onChange={this.changeYear}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
              </Col>
            </Row>
            <br />
            {this.state.selectedYear.value !== "" &&
              <>
                <Row>
                  <Col>Duration:</Col>
                  <Col><input type="text" className="standard-input" value={this.state.duration} onChange={this.updateDuration} /></Col>
                </Row>
                <br />
                <Row>
                  <Col>Total Cost:</Col>
                  <Col>{this.state.totalCost.toFixed(2)}</Col>
                </Row>
                <br />
                <Row>
                  <Col>Divided Cost:</Col>
                  <Col>{this.state.dividedTotal.toFixed(2)}</Col>
                </Row>
                <br />
                <Row>
                  <Col>Dividing the total cost of {this.state.totalCost} starting from {this.state.selectedYear.label} and continuing for the next {this.state.duration - 1} year(s) with the amount of {this.state.dividedTotal.toFixed(2)}:</Col>
                  <Col>
                    <input type="button" value="Update Schedule" className="standard-input" onClick={this.callback} />
                  </Col>
                </Row>
              </>
            }
          </ModalBody>
          <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
            <input type="button" value="Close" className="standard-input" onClick={() => this.setState({ show: false })}
              style={{
                backgroundColor: "#c2a877",
                color: "#15405c"
              }}
            />
          </ModalFooter>
        </Modal>
      </>
    )
  }

}