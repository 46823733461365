import * as React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { GroupOptions, SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays, SortGroupOptionByLabel, SortSelectOptionByLabel } from '../../../functions/selectTools';
import { GetSettings, SetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { RenderSettings, RowRenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';

export interface WidgetSelectOptions extends SelectOptions {
  group: string;
}

interface WidgetDisplaySettingsModalProps {
}

interface WidgetDisplaySettingsModalState {
  show: boolean;
  settingsKey: string;
  renderSettings: RenderSettings;
  widgetList: Array<SelectOptions> | Array<WidgetSelectOptions>;
  optionList: Array<SelectOptions> | Array<WidgetSelectOptions> | Array<GroupOptions>
  updateUI: (settings: RenderSettings) => void;
}

const ColCountOptions: Array<SelectOptions> = [
  { label: "1", value: "1"},
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" }
]

export class DashboardManagementModal extends React.Component<WidgetDisplaySettingsModalProps, WidgetDisplaySettingsModalState> {

  constructor(props: WidgetDisplaySettingsModalProps) {
    super(props);
    this.state = {
      show: false,
      renderSettings: undefined,
      widgetList: undefined,
      updateUI: undefined,
      optionList: [],
      settingsKey: ""
    }
    this.callback = this.callback.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.updateWidget = this.updateWidget.bind(this);
    this.addRow = this.addRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
  }

  show(settingsKey: string, widgetList: Array<SelectOptions> | Array<WidgetSelectOptions>, updateUI: (settings: RenderSettings) => void): void {
    var settings: RenderSettings = GetSettings(settingsKey);
    if (settings === null) {
      settings = DefaultPageSettings;
    }
    widgetList = widgetList.sort(SortSelectOptionByLabel);
    let optionList: Array<GroupOptions> | Array<SelectOptions> | Array<WidgetSelectOptions> = widgetList;
    if ('group' in widgetList['0']) {
      let groupList: { [key: string]: Array<SelectOptions> } = {};
      let _groupList: Array<GroupOptions> = [];
      let _widgetList: Array<WidgetSelectOptions> = widgetList as Array<WidgetSelectOptions>;
      // need to organize widgets
      for (let i = 0; i < _widgetList.length; ++i) {
        let group: string = _widgetList[i].group;
        if (!(group in groupList)) {
          groupList[group] = [];
        }
          groupList[group].push({ label: widgetList[i].label, value: widgetList[i].value });
      }
      for (let key in groupList) {
        _groupList.push({ label: key, options: groupList[key] });
      }
      _groupList = _groupList.sort(SortGroupOptionByLabel);
      optionList = _groupList;
    }
    this.setState({
      show: true,
      settingsKey: settingsKey,
      renderSettings: settings,
      widgetList: widgetList,
      optionList: optionList,
      updateUI: updateUI
    })
  }

  callback(): void {
    this.setState({
      show: false
    })
  }

  updateRow(event: SelectOptions | null, idx: number, colCount: number): void {
    if (event === null) { return }
    const renderSet = this.state.renderSettings;
    if (parseInt(event.label) > colCount) {
      const diff = parseInt(event.label) - colCount
      for (var i = 0; i < diff; ++i) {
        renderSet.rows[idx].widgets.push(0)
      }
    }
    else if (parseInt(event.label) < colCount) {
      const diff = colCount - parseInt(event.label)
      for (var i = 0; i < diff; ++i) {
        renderSet.rows[idx].widgets.splice(0, 1)
      }
    }
    renderSet.rows[idx].col = parseInt(event.label)
    SetSettings(this.state.settingsKey, renderSet);
    this.setState({ renderSettings: renderSet })
    this.state.updateUI(this.state.renderSettings)
  }

  addRow = () => {
    this.state.renderSettings.rows.push({
      col: 2,
      widgets: [0, 0]
    })
    this.setState({});
    SetSettings(this.state.settingsKey, this.state.renderSettings);
    this.state.updateUI(this.state.renderSettings)
  }

  updateWidget(event: SelectOptions | null, idx: number, idx2: number): void {
    if (event === null) { return }

    const renderSet = this.state.renderSettings;
    renderSet.rows[idx].widgets[idx2] = parseInt(event.value)

    SetSettings(this.state.settingsKey, renderSet);
    this.setState({ renderSettings: renderSet })
    this.state.updateUI(this.state.renderSettings)
  }

  deleteRow(idx: number): void {
    this.state.renderSettings.rows.splice(idx, 1)
    this.setState({})
    SetSettings(this.state.settingsKey, this.state.renderSettings);
    this.state.updateUI(this.state.renderSettings)
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="xl" toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag='h3' >
            Edit Displayed Widgets
          </ModalHeader>
        </div>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          {this.state.renderSettings?.rows.map((row: RowRenderSettings, idx: number) => (
            <div key={idx}>
              <Row>
                <Col xs="1">Row {idx + 1}</Col>
                <Col xs="1">
                  <Select
                    value={{ label: row.col.toString(), value: (row.col - 1).toString() }}
                    styles={reactSelectBasicStyle}
                    options={ColCountOptions}
                    onChange={(e: SelectOptions) => this.updateRow(e, idx, row.col)}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    onFocus={RemoveStickyOverlays}
                    onBlur={RestoreStickyOverlays}
                  />
                </Col>
                {
                  row.widgets.map((widget: number, idx2: number) => (
                    <Col xs={row.widgets.length === 2 ? '4' : (row.widgets.length === 1 ? '8' : '2')}>
                      <Select
                        value={this.state.widgetList.filter(u => u.value === widget.toString())[0]}
                        styles={reactSelectBasicStyle}
                        options={this.state.optionList}
                        onChange={(e: SelectOptions) => this.updateWidget(e, idx, idx2)}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        onFocus={RemoveStickyOverlays}
                        onBlur={RestoreStickyOverlays}
                      />
                    </Col>
                  ))
                }
                {row.widgets.length === 3 && <Col xs='2'></Col>}
                <Col xs="2"><input type="button" className="standardInput" value="Remove Row" onClick={() => this.deleteRow(idx)} /></Col>
              </Row>
              <br />
            </div>
          )
          )}
          <hr />
          <Row>
            <Col>
              <input type="button" className="standardInput" value="Add Row" onClick={this.addRow} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
          <input type="button" value="Done" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
          />
        </ModalFooter>
      </Modal>
    )
  }
}
