import * as React from 'react';
import { Col, Row } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';

interface CustomTableCell {
  value: string;
  isBold: boolean;
  textColor?: string;
  backColor?: string;
}

interface UsesBySiteProps {

}

interface UsesBySiteState {
  headers: Array<FilterAndSettingField>;
  rows: Array<Array<any>>;
}

export default class UsesBySite extends React.Component<UsesBySiteProps, UsesBySiteState> {

  static UsesBySiteDescriptions: Array<CustomTableCell> = [
    { value: "Number of Units", isBold: false },
    { value: "Affordable Units", isBold: false },
    { value: "Residential Square Footage", isBold: false },
    { value: "Common Areas", isBold: false },
    { value: "Total Square Footage", isBold: false },
    { value: "Acquisition Costs", isBold: false },
    { value: "Purchase Price - Land", isBold: false },
    { value: "Purchase Price - Improvements", isBold: false },
    { value: "Purchase Price - Reserves", isBold: false },
    { value: "Closing/Recording Costs", isBold: false },
    { value: "Acquisition Subtotal", isBold: false },
    { value: "Construction Costs", isBold: false },
    { value: "Rooftop Solar", isBold: false },
    { value: "On - site Work", isBold: false },
    { value: "Hazardous Materials Abatement", isBold: false },
    { value: "Demolition", isBold: false },
    { value: "Residential Building", isBold: false },
    { value: "Commercial Space / Building", isBold: false },
    { value: "Common Use Facilities", isBold: false },
    { value: "FF & E(Common Area Furnishings)", isBold: false },
    { value: "Internet Wiring & Equipment", isBold: false },
    { value: "Landscaping", isBold: false },
    { value: "Elevator", isBold: false },
    { value: "Laundry Facilities", isBold: false },
    { value: "Storage / Garages", isBold: false },
    { value: "Builder's Risk Insurance", isBold: false },
    { value: "Performance Bond", isBold: false },
    { value: "3rd Party Const.Management", isBold: false },
    { value: "Contingency", isBold: false },
    { value: "General Conditions", isBold: false },
    { value: "Contractor Overhead", isBold: false },
    { value: "Contractor Liability Insurance", isBold: false },
    { value: "Contractor Performance Bond", isBold: false },
    { value: "Contractor Profit", isBold: false },
    { value: "Construction Subtotal", isBold: false },
    { value: "Construction Cost Per Unit", isBold: false },
    { value: "Development Costs", isBold: false },
    { value: "Land Use Approvals", isBold: false },
    { value: "Building Permits / Fees", isBold: false },
    { value: "System Development Charges", isBold: false },
    { value: "Market Study", isBold: false },
    { value: "Environmental Report", isBold: false },
    { value: "Pest / Dryrot Inspection", isBold: false },
    { value: "Soils Report(Geotechnical)", isBold: false },
    { value: "Survey", isBold: false },
    { value: "Capital Needs Assessment", isBold: false },
    { value: "OHCS Construction Inspection", isBold: false },
    { value: "OHCS Construction Analyst", isBold: false },
    { value: "Development Subtotal", isBold: false },
    { value: "General Fees", isBold: false },
    { value: "Architectural", isBold: false },
    { value: "Engineering", isBold: false },
    { value: "Professional Fees:", isBold: false },
    { value: "Legal & Accounting", isBold: false },
    { value: "Legal LIHTC", isBold: false },
    { value: "Appraisals", isBold: false },
    { value: "Special Inspections / Testing", isBold: false },
    { value: "Developer Fee", isBold: false },
    { value: "Consultant Fee", isBold: false },
    { value: "Other General Fees", isBold: false },
    { value: "General Fees Subtotal", isBold: false },
    { value: "Construction Loan Costs / Fees", isBold: false },
    { value: "Lender Inspection Fees", isBold: false },
    { value: "Lender Title / Doc Prep Fees", isBold: false },
    { value: "Lender Legal Fees", isBold: false },
    { value: "Lender Loan Fees", isBold: false },
    { value: "Loan Extension Fees", isBold: false },
    { value: "Property Taxes", isBold: false },
    { value: "Insurance", isBold: false },
    { value: "Construction Loan Fees Subtotal", isBold: false },
    { value: "Soft Loan Fees", isBold: false },
    { value: "Soft Loan Legal", isBold: false },
    { value: "Soft Loan Fee", isBold: false },
    { value: "Bridge Loan Fees Subtotal", isBold: false },
    { value: "Permanent Loan Fees ", isBold: false },
    { value: "Perm Lender Legal", isBold: false },
    { value: "Perm USDA Guarantee Fee - initial", isBold: false },
    { value: "Perm USDA Guarantee Fee - year 1", isBold: false },
    { value: "Perm Lender Loan Fee", isBold: false },
    { value: "Perm Lender Loan Closing Fees", isBold: false },
    { value: "Permanent Loan Fees Subtotal", isBold: false },
    { value: "Tax Credit Fees", isBold: false },
    { value: "OHCS Tax Credit Application Charge", isBold: false },
    { value: "OHCS Tax Credit Reservation Charge", isBold: false },
    { value: "OHCS Recipient Charge", isBold: false },
    { value: "Tax Credit Syndication Costs", isBold: false },
    { value: "Tax Credit Cost Certification", isBold: false },
    { value: "OHCS Doc Prep / DOJ Charges", isBold: false },
    { value: "Tax Credit Fees Subtotal", isBold: false },
    { value: "Bond Issuance Fees", isBold: false },
  ]

  constructor(props: UsesBySiteProps) {
    super(props);
    this.state = {
      headers: this.generateHeaders(),
      rows: this.generateNewUses()
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 1",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 2",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 3",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 4",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        additionalClasses: "five fixedTableHeaderHidden",
        filter: {},
      },
      {
        columnName: "Basis %",
        setting: true,
        filter: {},
      },
      {
        columnName: "Site 1",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 2",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 3",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 4",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total",
        setting: true,
        filter: {}
      },
    ];
    return headers;
  }

  generateNewUses(): Array<Array<any>> {
    let rows: Array<Array<CustomTableCell>> = [];
    let siteCount = 4;
    UsesBySite.UsesBySiteDescriptions.forEach(function (item) {
      var row: Array<CustomTableCell> = [];
      row.push(item);
      for (let i = 0; i < siteCount; ++i) {
        row.push({
          value: (0).toFixed(0),
          isBold: false
        });
      }
      row.push({
        value: (1000).toFixed(0),
        isBold: false
      });
      row.push({
        value: "",
        isBold: false
      })
      row.push({
        value: "",
        isBold: false
      })
      for (let i = 0; i < siteCount; ++i) {
        row.push({
          value: (0).toFixed(0),
          isBold: false
        });
      }
      row.push({
        value: (1000).toFixed(0),
        isBold: false
      });
      rows.push(row);
    });
    return rows;
  }

  render(): JSX.Element {
    return (
      <div style={{ overflow: "auto" }}>
        <Row>
          <Col>
            <div style={{ maxHeight: "78vh" }} >
              <table className="fixedTable" style={{ width: "140%", overflowY: "auto" }}>
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
                </thead>
                <tbody>
                  {
                    this.state.rows.map((row: Array<CustomTableCell>) => (
                      <tr key={row[0]?.value}>
                        {
                          row.map((cell: CustomTableCell, idx) => (
                            <td className={idx === 0
                              ? "fixedTableCellFirst"
                              : (idx === 6 ? "fixedTableCellHidden" :
                                "fixedTableCell"
                              )
                            }
                              style={{
                                color: cell.textColor ? cell.textColor : "#d7c7a7",
                              }}
                            >
                              {cell.value}
                            </td>
                          ))
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <br />
              <br />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}