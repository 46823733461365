//#region Imports
import axios, { AxiosError } from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { CompanyNames, CompanyNames_Select } from '../../constants/EnumConstants';
import { numberWithCommas } from '../../functions/numberFunctions';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../style/select-constants';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../CoreComponents/CoreTableRows';
import { DatePicker } from '../CoreComponents/DateComponents';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { StatusModal, WarningModal } from '../CoreComponents/Modals';
import { getCompany } from './DistributionRecordCollector';
import * as Structs from './Structs';
import { DownloadNamedFile } from '../../functions/documentTools';
//#endregion

export default class CDIPayroll extends React.Component<Structs.CDIPayrollProps, Structs.CDIPayrollState> {

  statusModal = React.createRef<StatusModal>();
  bonusModal = React.createRef<CustomBonusModal>();
  expenseModal = React.createRef<CustomExpensesModal>();
  invoiceModal = React.createRef<CustomInvoiceModal>();
  generateModal = React.createRef<PreviewOrGenerateInvoiceModal>();

  warningModal = React.createRef<WarningModal>();

  constructor(props: Structs.CDIPayrollProps) {
    super(props);
    this.state = {
      laborFile: null,
      payDate: new Date(),
      generateInvoices: false,
      bonuses: [],
      reimbursements: [],
      invoices: {
        'ABC': [],
        'MCI': [],
        'VCL': [],
        'VCON': [],
        'VMI': []
      }
    }

    this.changeFile = this.changeFile.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.runPayrollCheck = this.runPayrollCheck.bind(this);
    this.showBonusModal = this.showBonusModal.bind(this);
    this.showExpenseModal = this.showExpenseModal.bind(this);
    this.showABCInvoices = this.showABCInvoices.bind(this);
    this.showMCIInvoices = this.showMCIInvoices.bind(this);
    this.showVCLInvoices = this.showVCLInvoices.bind(this);
    this.showVCONInvoices = this.showVCONInvoices.bind(this);
    this.showVMIInvoices = this.showVMIInvoices.bind(this);
    this.showInvoiceGenerationModal = this.showInvoiceGenerationModal.bind(this);
    this.processPayroll = this.processPayroll.bind(this);
  }

  changeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      laborFile: event.target.files[0]
    })
  }

  changeDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      payDate: new Date(event.target.valueAsDate) === null ? new Date(1, 0, 1) : new Date(event.target.valueAsDate)
    })
  }

  async runPayrollCheck(): Promise<void> {
    this.statusModal.current.display("CDI Payroll Preprocessing", "Checking for Bonuses & Expense Reimbursements");
    const formData = new FormData();
    formData.append("labor", this.state.laborFile);
    const self = this;
    await axios.post('./api/payroll/cdi/check', formData).then(function (response) {
      console.log(response.data);
      self.setState({
        bonuses: response.data.bonuses,
        reimbursements: response.data.reimbursements
      }, () => self.showBonusModal())
    });
  }

  showBonusModal(): void {
    this.statusModal.current.hide();
    if (this.state.bonuses.length > 0) {
      this.bonusModal.current.display(this.state.bonuses);
    }
    else {
      this.showExpenseModal();
    }
  }

  showExpenseModal(): void {
    this.bonusModal.current.hide();
    if (this.state.reimbursements.length > 0) {
      this.expenseModal.current.display(this.state.reimbursements);
    }
    else {
      this.showABCInvoices();
    }
  }

  showABCInvoices(): void {
    this.expenseModal.current.hide();
    this.invoiceModal.current.display(4, this.state.invoices['ABC'], this.showMCIInvoices);
  }

  showMCIInvoices(abcInvoices: Array<any>): void {
    let invoices = this.state.invoices;
    invoices['ABC'] = abcInvoices;
    this.setState({});
    this.invoiceModal.current.display(2, this.state.invoices['MCI'], this.showVCLInvoices)
  }

  showVCLInvoices(mciInvoices: Array<any>): void {
    let invoices = this.state.invoices;
    invoices['MCI'] = mciInvoices;
    this.setState({});
    this.invoiceModal.current.display(6, this.state.invoices['VCL'], this.showVCONInvoices)
  }

  showVCONInvoices(vclInvoices: Array<any>): void {
    let invoices = this.state.invoices;
    invoices['VCL'] = vclInvoices;
    this.setState({});
    this.invoiceModal.current.display(5, this.state.invoices['VCON'], this.showVMIInvoices)
  }

  showVMIInvoices(vconInvoices: Array<any>): void {
    let invoices = this.state.invoices;
    invoices['VMI'] = vconInvoices;
    this.setState({});
    this.invoiceModal.current.display(1, this.state.invoices['VMI'], this.showInvoiceGenerationModal)
  }

  showInvoiceGenerationModal(_invoices: Array<any>): void {
    this.invoiceModal.current.hide();
    let invoices = this.state.invoices;
    invoices['VCON'] = _invoices;
    this.setState({});
    this.generateModal.current.display();
  }

  async processPayroll(generate: boolean): Promise<void> {
    this.generateModal.current.hide();
    this.setState({ generateInvoices: generate });
    const formData = new FormData();
    const self = this;
    let invoices = []
    for (const key in this.state.invoices) {
      var data: Array<any> = this.state.invoices[key];
      for (let item of data) {
        invoices.push({
          company: getCompany(key),
          description: item.description,
          amount: item.amount
        })
      }
    }
    formData.append("Labor", this.state.laborFile);
    formData.append("Data", JSON.stringify({
      Bonuses: this.state.bonuses,
      Reimbursements: this.state.reimbursements,
      Invoices: invoices
    }))
    formData.append("GenerateInvoices", generate.toString());
    this.statusModal.current.display("Processing CDI Payroll", "Please wait while the payroll processes.");
    let response = await axios.post('./api/payroll/cdi/' + this.state.payDate.toISOString(), formData, { responseType: 'blob', headers: { 'Context-Type': 'multipart/form-data' }, validateStatus: () => true});
    if (response.status === 200) {
      await DownloadNamedFile(response);
      this.statusModal.current.hide();
    }
    else {
      this.statusModal.current.hide();
      let fullStatus: string = await response.data.text();
      let message: string = fullStatus.substring(fullStatus.indexOf(":") + 1, fullStatus.indexOf("at Fleet"));
      this.warningModal.current.show("Payroll Threw the Following Error: " + message);
    }
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        <StatusModal ref={this.statusModal} />
        <CustomBonusModal ref={this.bonusModal} save={this.showExpenseModal} />
        <CustomExpensesModal ref={this.expenseModal} save={this.showABCInvoices} />
        <CustomInvoiceModal ref={this.invoiceModal} />
        <PreviewOrGenerateInvoiceModal ref={this.generateModal} save={this.processPayroll} />
        <Row>
          <Col xs='4'>
            <label>Labor Distribution Report</label>
            <br />
            <input type="file" accept='.xlsx' onChange={this.changeFile} />
          </Col>
        </Row>
        <Row>
          <Col xs='4'>
            <label>Payroll Date</label>
            <br />
            <DatePicker onChange={this.changeDate} value={this.state.payDate} />
          </Col>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col xs='4'>
            <br />
            <br />
            <input type="button" className="standard-input" value="Process" onClick={this.runPayrollCheck} />
          </Col>
        </Row>
        <Row>
          <Col xs='4'>
            <input type="button" className="standard-input" value="View Records" onClick={() => window.location.assign("/payroll-distribution?company=CDI")} />
          </Col>
        </Row>
        <Row>
          <Col xs='4'>
            <br />
            <a href="https://docs.google.com/document/d/1_3ubSuEdZmqFX-M1NPJPV42zzXJ9FkQ0LBV7glsG8Dg/edit?usp=sharing" target="_blank">Documentation</a>
          </Col>
        </Row>
      </>
    )
  }

}

class CustomBonusModal extends React.Component<Structs.CustomBonusModalProps, Structs.CustomBonusModalState> {

  constructor(props: Structs.CustomBonusModalProps) {
    super(props);
    this.state = {
      showing: false,
      message: "",
      bonuses: [],
      headers: this.generateHeaders()
    }
    this.updateBonuses = this.updateBonuses.bind(this);
  }

  display(bonuses: Array<Structs.CDIBonusData>): void {
    this.setState({
      showing: true,
      bonuses: bonuses
    })
  }

  hide(): void {
    this.setState({
      showing: false
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Employee",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "ID",
        setting: true,
        additionalClasses: "icon",
        filter: {}
      },
      {
        columnName: "Amount",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Taxes",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Benefits",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "WC",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      },
      {
        columnName: "Company",
        setting: true,
        filter: {}
      }
    ]
  }

  updateBonuses(event: SelectOptions | null, idx: number): void {
    if (event === null) { return; }
    let bonus = this.state.bonuses[idx];
    bonus.company = event;
    this.setState({})
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="lg">
        <div style={{ background: "#4c4a42" }} >
          <ModalHeader tag="h3" style={{ backgroundColor: "#c2a877" }}>
            Please Review the Bonuses for this current Payroll
          </ModalHeader>
          <ModalBody>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.bonuses.map((bonus: Structs.CDIBonusData, idx: number) => (
                    <tr key={bonus.bonus + idx}>
                      <td className="fixedTableCellFirst">{bonus.name}</td>
                      <td className="fixedTableCell">{bonus.id}</td>
                      <td className="fixedTableCell">{numberWithCommas(bonus.bonus)}</td>
                      <td className="fixedTableCell">{numberWithCommas(bonus.taxes)}</td>
                      <td className="fixedTableCell">{numberWithCommas(bonus.benefits)}</td>
                      <td className="fixedTableCell">{numberWithCommas(bonus.workersComp)}</td>
                      <td className="fixedTableCell">
                        <Select
                          value={bonus.company}
                          options={CompanyNames_Select}
                          onChange={(e: SelectOptions) => this.updateBonuses(e, idx)}
                          styles={reactSelectBasicStyle}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <input type="button" className="standard-input" value="Bonuses Allocated" onClick={this.props.save} />
          </ModalFooter>
        </div>
      </Modal >
    )
  }
}

class CustomExpensesModal extends React.Component<Structs.CustomExpenseModalProps, Structs.CustomExpenseModalState> {

  constructor(props: Structs.CustomExpenseModalProps) {
    super(props);
    this.state = {
      showing: false,
      message: "",
      expenses: [],
      headers: this.generateHeaders()
    }
    this.updateIsTuition = this.updateIsTuition.bind(this);
  }

  display(expenses: Array<any>): void {
    this.setState({
      showing: true,
      expenses: expenses
    })
  }

  hide(): void {
    this.setState({
      showing: false
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Employee",
        setting: true,
        additionalClasses: "twenty-five",
        filter: {}
      },
      {
        columnName: "ID",
        setting: true,
        filter: {}
      },
      {
        columnName: "Amount",
        setting: true,
        additionalClasses: "twenty-five",
        filter: {}
      },
      {
        columnName: "Is Tuition",
        setting: true,
        filter: {}
      }
    ]
  }

  updateIsTuition(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    if (event === null) { return; }
    let expense = this.state.expenses[idx];
    expense.isTuition = event.target.checked;
    this.setState({})
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="lg">
        <div style={{ background: "#4c4a42" }} >
          <ModalHeader tag="h3" style={{ backgroundColor: "#c2a877" }}>
            Are any Expense Reimbursements Tuition Related?
          </ModalHeader>
          <ModalBody>
            <table>
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.expenses.map((bonus, idx) => (
                    <tr key={bonus.value + idx}>
                      <td className="fixedTableCellFirst">{bonus.name}</td>
                      <td className="fixedTableCell">{bonus.id}</td>
                      <td className="fixedTableCell">{numberWithCommas(bonus.value)}</td>
                      <td className="fixedTableCell">
                        <input type="checkbox" checked={bonus.isTuition} onChange={(e) => this.updateIsTuition(e, idx)} style={{ marginLeft: "35%" }} />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <input type="button" className="standard-input" value="Expenses are Correct" onClick={this.props.save} />
          </ModalFooter>
        </div>
      </Modal >
    )
  }
}

interface CustomInvoiceModalProps {

}

interface CustomInvoiceModalState {
  showing: boolean;
  invoices: Array<any>;
  company: number;
  headers: Array<any>;
  save: any;
}

class CustomInvoiceModal extends React.Component<CustomInvoiceModalProps, CustomInvoiceModalState> {

  constructor(props: CustomInvoiceModalProps) {
    super(props);
    this.state = {
      showing: false,
      invoices: [],
      company: 0,
      headers: this.generateHeaders(),
      save: (tmp: any) => console.log("Save not set in modal: ", tmp)
    }
    this.addInvoice = this.addInvoice.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.setAmount = this.setAmount.bind(this);
  }

  display(company: number, invoices: Array<any>, save: (data: Array<any>) => void): void {
    this.setState({
      showing: true,
      invoices: invoices,
      company: company,
      save: save
    })
  }

  hide(): void {
    this.setState({
      showing: false
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Description",
        setting: true,
        additionalClasses: "twenty-five",
        filter: {}
      },
      {
        columnName: "Amount",
        setting: true,
        filter: {}
      },
      {
        columnName: "Company",
        setting: true,
        filter: {}
      },
    ]
  }

  addInvoice(): void {
    let invoices = this.state.invoices;
    invoices.push({
      description: "",
      amount: 0.00
    });
    this.setState({
      invoices: invoices
    })
  }

  setDescription(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let invoice = this.state.invoices[idx];
    invoice.description = event.target.value;
    this.state.invoices[idx] = invoice;
    this.setState({})
  }

  setAmount(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let invoice = this.state.invoices[idx];
    invoice.amount = parseFloat(event.target.value);
    this.state.invoices[idx] = invoice;
    this.setState({})
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="lg">
        <div style={{ background: "#4c4a42" }} >
          <ModalHeader tag="h3" style={{ backgroundColor: "#c2a877" }}>
            Add Invoice Lines to {CompanyNames[this.state.company]} Invoice
          </ModalHeader>
          <ModalBody>
            <table>
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.invoices.map((invoice, idx) => (
                    <tr key={this.state.company + idx}>
                      <td className="fixedTableCellFirst">
                        <input type="text" value={invoice.description} className="standard-input" onChange={(e) => this.setDescription(e, idx)} />
                      </td>
                      <td className="fixedTableCell">
                        <input type="text" value={invoice.amount} className="standard-input" onChange={(e) => this.setAmount(e, idx)} />
                      </td>
                      <td className="fixedTableCell">{CompanyNames[this.state.company]}</td>
                    </tr>
                  ))
                }
                <TableAdditionRow length={3} marginLeft={"45%"} onClick={this.addInvoice} />
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <input type="button" className="standard-input" value="Invoice Lines Added" onClick={() => this.state.save(this.state.invoices)} />
          </ModalFooter>
        </div>
      </Modal >
    )
  }
}

interface PreviewOrGenerateInvoiceModalProps {
  save: any;
}

interface PreviewOrGenerateInvoiceModalState {
  showing: boolean;
  save: any;
}

class PreviewOrGenerateInvoiceModal extends React.Component<PreviewOrGenerateInvoiceModalProps, PreviewOrGenerateInvoiceModalState> {

  constructor(props: PreviewOrGenerateInvoiceModalProps) {
    super(props);
    this.state = {
      showing: false,
      save: props.save
    }
  }

  display(): void {
    this.setState({
      showing: true,
    })
  }

  hide(): void {
    this.setState({
      showing: false
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="lg">
        <div style={{ background: "#4c4a42" }} >
          <ModalHeader tag="h3" style={{ backgroundColor: "#c2a877" }}>
            Preview Or Generate Invoices
          </ModalHeader>
          <ModalBody>
            <h5>
              Do you want to preview or generate the invoices. If you are previewing the invoices, they will generate with the exact same invoice number (as no actual invoices are creating).
              If you are generating the invoices, they will be stored & added to the invoice list.
            </h5>
          </ModalBody>
          <ModalFooter>
            <input type="button" className="standard-input" value="Preview Invoices" onClick={() => this.state.save(false)} />
            <input type="button" className="standard-input" value="Generate Invoices" onClick={() => this.state.save(true)} />
          </ModalFooter>
        </div>
      </Modal >
    )
  }
}