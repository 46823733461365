import axios, { AxiosError } from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { DatePicker } from '../CoreComponents/DateComponents';
import { WarningModal } from '../CoreComponents/Modals';
import { DownloadNamedFile } from '../../functions/documentTools';

interface ABCPayrollProps {

}

interface ABCPayrollState {
  laborFile: string;
  payDate: Date;
  processing: boolean;
}

export default class ABCPayroll extends React.Component<ABCPayrollProps, ABCPayrollState> {

  warningModal = React.createRef<WarningModal>();

  constructor(props: ABCPayrollProps) {
    super(props);
    this.state = {
      laborFile: '',
      payDate: new Date(),
      processing: false
    }

    this.changeFile = this.changeFile.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.processPayroll = this.processPayroll.bind(this);
  }

  changeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      //@ts-ignore
      laborFile: event.target.files[0]
    })
  }

  changeDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      payDate: new Date(event.target.valueAsDate) === null ? new Date(1, 0, 1) : new Date(event.target.valueAsDate)
    })
  }

  async processPayroll(): Promise<void> {
    const formData = new FormData();
    const self = this;
    formData.append("labor", this.state.laborFile);
    this.setState({ processing: true })
    let response = await axios.post('./api/payroll/abc/' + this.state.payDate.toISOString(), formData, { responseType: 'blob', validateStatus: () => true });
    if (response.status === 200) {
      await DownloadNamedFile(response);
      self.setState({ processing: false })
    }
    else {
      this.setState({ processing: false })
      let fullStatus: string = await response.data.text();
      let message: string = fullStatus.substring(fullStatus.indexOf(":") + 1, fullStatus.indexOf("at Fleet"));
      this.warningModal.current.show("Payroll Threw the Following Error: " + message)
    }
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        {!this.state.processing ?
          <>
            <Row>
              <Col xs='4'>
                <label>Labor Distribution Report</label>
                <br />
                <input type="file" accept='.xlsx' onChange={this.changeFile} />
              </Col>
              <Col>
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <label>Payroll Date</label>
                <br />
                <DatePicker onChange={this.changeDate} value={this.state.payDate} />
              </Col>
              <Col>
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <br />
                <br />
                <input type="button" className="standard-input" value="Process" onClick={this.processPayroll} />
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <br />
                <input type="button" className="standard-input" value="View Records" onClick={() => window.location.assign("/payroll-distribution?company=ABC")} />
              </Col>
            </Row>
            <Row>
              <Col xs='4'>
                <br />
                <a href="https://docs.google.com/document/d/1_3ubSuEdZmqFX-M1NPJPV42zzXJ9FkQ0LBV7glsG8Dg/edit?usp=sharing" target="_blank">Documentation</a>
              </Col>
            </Row>
          </>
          :
          <div className="lds-dual-ring" style={{ marginLeft: "40vw", marginTop: "35vh" }}></div>
        }
      </>
    )
  }

}