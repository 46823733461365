import * as React from "react";
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { StaticAssets } from "../StaticAssets"
import { CreateGlobalAlert } from "../../functions/CreateGlobalAlerts";
import Select from "react-select";
import { FullConsumption } from "./ScreenerConsumption";
import { reactSelectBasicStyle } from "../../style/select-constants";
import * as Structs from './Structs';
import { numberWithCommas } from "../../functions/numberFunctions";

const PVWattsRow = (props: any) => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.month}</td>
      <td className="fixedTableCell" style={{ textAlign: "center" }}>{props.rad}</td>
      <td className="fixedTableCell" style={{ textAlign: "center" }}>{props.ac}</td>
      <td className="fixedTableCell" style={{ textAlign: "center" }}>${props.value}</td>
    </tr>
  )
}


export class ScreenerProduction extends React.Component<Structs.ScreenerProductionProps, Structs.ScreenerProductionState> {

  constructor(props: Structs.ScreenerProductionProps) {
    super(props);
    this.state = {
      screener: props.screener,
      property: props.property,
      settings: props.settings,
      systemSize: 0,
      productionCostData: [],
      totalConsumption: 0,
      totalProduction: 0,
      residentialPercentage: 0,
      metering: { label: "Metering Not Loaded", value: "-1" },
      meterData: {},
      pvWattData: [],
      propertyName: '',
      loaded: false
    }
    this.pullPVWatts = this.pullPVWatts.bind(this);
    this.pullPvTableItems = this.pullPvTableItems.bind(this);
    this.calculate = this.calculate.bind(this);
    this.updateValues = this.updateValues.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    await axios.get("./api/meter-settings/" + this.state.settings).then(function (response) {
      self.setState({
        meterData: response.data
      }, self.pullPvTableItems)
    });
    await axios.get('./api/property/' + this.state.property).then(function (response) {
      self.setState({
        propertyName: response.data.name
      });
    })
    await axios.get('./api/solar-consumption-line-item/' + this.state.screener).then(function (response) {
      var out = [];
      var costOut = [];
      var total = 0;
      var residentialPercentage = 0;
      var months = {};
      response.data.forEach(function (item: any, index: number) {
        //@ts-ignore
        var month = StaticAssets.ShortMonths[item.month];
        total += parseFloat(item.consumption);
        if (!item.IsCommon) {
          residentialPercentage += parseFloat(item.consumption);
        }
        //@ts-ignore
        var tmp = months[month];
        if (tmp === undefined) {
          //@ts-ignore
          months[month] = {
            'Consumption': item.consumption,
            'Cost': item.estimatedCost
          }
        }
        else {
          //@ts-ignore
          months[month] = {
            //@ts-ignore
            'Consumption': months[month].consumption + item.consumption,
            //@ts-ignore
            'Cost': months[month].cost + item.estimatedCost
          }
        }
      })
      var idx = 0;
      for (var key in months) {
        //@ts-ignore
        var month = StaticAssets.ShortMonths[idx];
        //@ts-ignore
        var data = months[month];
        out.push({
          x: month,
          y: data.consumption
        })
        costOut.push({
          x: month,
          y: data.Cost
        })
        idx++;
      }
      residentialPercentage = residentialPercentage / total;
      self.setState({
        //consumptionData: out,
        //consumptionCostData: costOut,
        totalConsumption: total,
        residentialPercentage: residentialPercentage
      });
    });
    await axios.get('./api/solar-projects/' + this.state.screener + "/screener")
      .then(function (response) {
        self.setState({ systemSize: response.data.systemWattSize });
      })
  }

  async pullPvTableItems(): Promise<void> {
    var self = this;
    await axios.get("./api/pvwatts-table-item/" + this.state.screener).then(function (response) {
      var prodCost: Array<any> = [];
      var prod: Array<any> = [];
      var tmp: Array<any> = [];
      var tAc: number = 0;
      var total: number = 0;
      var currentRate: number = 0;
      response.data.forEach(function (item: any, index: number) {
        //@ts-ignore
        var month = StaticAssets.ShortMonths[index];
        tAc += parseFloat(item.ac);
        total += parseFloat(item.ac);
        tmp.push({
          month: month,
          solarRad: item.SolarRadiation.toFixed(2),
          ac: numberWithCommas(item.ac),
          value: numberWithCommas(item.ac * currentRate)
        })
        prod.push({
          x: month,
          y: item.ac
        })
        prodCost.push({
          x: month,
          y: item.ac * currentRate
        })
      });
      tmp.push({
        month: "Total",
        solarRad: '-',
        ac: numberWithCommas(tAc),
      })
      self.setState({
        //productionData: prod,
        productionCostData: prodCost,
        pvWattData: tmp,
        totalProduction: total
      });
    })
    await axios.get("./api/solar-screener/" + self.state.property).then(function (response) {
      self.setState({ metering: FullConsumption.MeteringChoices[response.data.meteringType] }, self.calculate);
    })
  }

  async calculate(): Promise<void> {
    var self = this;
    var type = this.state.metering;
    var commId: string = null;
    var resiId: string = null;
    try {
      switch (type.value) {
        case '0':
          commId = this.state.meterData.MasterCommercialRateUID;
          break;
        case '1':
          commId = this.state.meterData.AggregateCommercialRateUID;
          resiId = this.state.meterData.AggregateResidentialRateUID;
          break;
        case '2':
          commId = this.state.meterData.AggregateCommercialOnlyRateUID;
          break;
        default:
          break;
      }
      await axios.get("./api/electric-rate/" + commId).then(function (response) {
        if (resiId !== null) {
          axios.get("./api/electric-rate/" + resiId).then(function (_response) {
            self.updateValues(response.data.weightedConsumptionCharge, _response.data.weightedConsumptionCharge);
          })
        }
        else {
          self.updateValues(response.data.weightedConsumptionCharge, 0);
        }
      });
    }
    catch {
      console.error("Issue with the production tab.. reloading");
      window.location.reload();
    }
  }

  updateValues(commRate: number, resiRate: number): void {
    const self = this;
    try {
      var tmp = self.state.pvWattData;
      var cost = self.state.productionCostData;
      var out: Array<any> = [];
      var costOut: Array<any> = [];
      var totalValue = 0;
      tmp.forEach(function (item, index) {
        if (index > 11) {
          out.push({
            ac: item.ac,
            month: item.month,
            solarRad: item.solarRad,
            value: numberWithCommas(totalValue)
          });
          return;
        }
        var ac = parseFloat(item.ac.replace(",", ""));
        var value = resiRate == 0 ? commRate * ac : ((commRate * ac * (1 - self.state.residentialPercentage)) + (resiRate * ac * self.state.residentialPercentage));
        var _ = cost[index];
        totalValue += value;
        out.push({
          ac: item.ac,
          month: item.month,
          solarRad: item.solarRad,
          value: numberWithCommas(value)
        });
        costOut.push({
          x: _.x,
          y: value
        })
      })
      self.setState({
        pvWattData: out,
        productionCostData: costOut,
        loaded: true
      })
    }
    catch (err) {
      console.error(`Update Values failed with error message ${err}`);
    }
  }

  async pullPVWatts(): Promise<void> {
    await axios.get("/api/manual/" + this.state.property + "/" + this.state.systemSize + "/pull-pv-watts").then(function (response) {
      CreateGlobalAlert(`PV Watts has been pulled successfully, reloading with data`, 1200, () => window.location.reload());
    });
  }

  render(): JSX.Element {
    return (
      <div id="screener-production" style={{ marginLeft: "-15px" }}>
        <h3 id="property-name">{this.state.propertyName} Production Estimates</h3>
        <Row>
          <Col xs='2'>
            <h4 id="project-size">Project Size: {this.state.systemSize}</h4>
          </Col>
          <Col xs='2'>
            <div id="metering-choice" >
              <Select
                options={FullConsumption.MeteringChoices}
                isDisabled
                styles={reactSelectBasicStyle}
                value={this.state.metering}
              />
            </div>
          </Col>
          <Col xs='2'>
            <input type="button" value="Pull PV Watts" className="standard-input" onClick={this.pullPVWatts} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>PV Watts</h4>
            <table className="fixedTable">
              <thead>
                <tr>
                  <th className="small fixedTableHeaderFirst">Month</th>
                  <th className="fixedTableHeader">Solar Radiation</th>
                  <th className="fixedTableHeader">AC Energy</th>
                  <th className="fixedTableHeader">Value</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loaded &&
                  this.state.pvWattData.map((item) => (
                    <PVWattsRow month={item.month} rad={item.solarRad} ac={item.ac} value={item.value} />
                  ))
                }
              </tbody>
            </table>
            <h4 style={{ marginTop: "10px" }}>Energy Burden Info</h4>
            <table className="fixedTable">
              <thead>
                <tr>
                  <th className="fixedTableHeaderFirst">Production</th>
                  <th className="fixedTableHeader">Consumption</th>
                  <th className="fixedTableHeader">% Offset</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="total-production" className="fixedTableCellFirst" style={{ textAlign: "right" }}>{numberWithCommas(this.state.totalProduction)}</td>
                  <td id="total-consumption" className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(this.state.totalConsumption)}</td>
                  <td id="offset" className="fixedTableCell" style={{ textAlign: "right" }}>
                    {((this.state.totalProduction / this.state.totalConsumption) * 100).toFixed(0)}%
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col style={{ marginTop: "-70px" }}>
            <Row>
              <h3>Graphs will be fixed when we have enough time. Sorry for the delay</h3>
              {/*{this.state.consumptionData.length > 0 && this.state.productionData.length > 0 &&*/}
              {/*    <div id="energy-usage" style={{}}>*/}
              {/*        <h4>Energy Usage Offset</h4>*/}
              {/*        <DiscreteColorLegend style={{ margin: '0px', padding: "0px", marginTop: "-5px" }}*/}
              {/*            orientation="horizontal"*/}
              {/*            items={[{*/}
              {/*                title: 'Consumption',*/}
              {/*                color: '#82bce3',*/}
              {/*            },*/}
              {/*            {*/}
              {/*                title: 'Production',*/}
              {/*                color: '#d5e9f6'*/}
              {/*            }*/}
              {/*            ]} />*/}
              {/*        <XYPlot height={300} width={800} margin={{ left: 75 }} xType="ordinal" style={{}}*/}
              {/*            onMouseLeave={this._onMouseLeave}>*/}
              {/*            <XAxis position="middle" style={{*/}
              {/*                text: { fill: "#82bce3" },*/}
              {/*                line: { stroke: "none", fill: "none" }*/}
              {/*            }} />*/}
              {/*            <YAxis title="Consumption" position="middle" tickSize={8} tickFormat={ConsumptionFormatter}*/}
              {/*                style={{*/}
              {/*                    text: { fill: "#82bce3" },*/}
              {/*                    line: { stroke: "#d5e9f6" },*/}
              {/*                    title: { fill: "#82bce3" }*/}
              {/*                }}*/}
              {/*            />*/}
              {/*            <Crosshair values={this.state.crosshairValues}*/}
              {/*                style={{*/}
              {/*                    background: "#4C4A42",*/}
              {/*                    line: { width: "0px" }*/}
              {/*                }}*/}
              {/*                itemsFormat={this.toolTipFormat} />*/}
              {/*            <VerticalBarSeries data={this.state.productionData} color="#d5e9f6" />*/}
              {/*            <LineMarkSeries data={this.state.consumptionData} color="#82bce3" onNearestX={this._onNearestX} />*/}
              {/*        </XYPlot>*/}
              {/*    </div>*/}
              {/*}*/}
            </Row>
            <Row>
              {/*{this.state.consumptionCostData.length > 0 && this.state.productionCostData.length > 0 &&*/}
              {/*    <div id="energy-usage" style={{}}>*/}
              {/*        <h4>Energy Savings Offset</h4>*/}
              {/*        <DiscreteColorLegend style={{ margin: '0px', padding: "0px", marginTop: "-5px" }}*/}
              {/*            orientation="horizontal"*/}
              {/*            items={[{*/}
              {/*                title: 'Consumption Cost',*/}
              {/*                color: '#82bce3',*/}
              {/*            },*/}
              {/*            {*/}
              {/*                title: 'Production Savings',*/}
              {/*                color: '#d5e9f6'*/}
              {/*            }*/}
              {/*            ]} />*/}

              {/*        <XYPlot height={300} width={800} margin={{ left: 65 }} xType="ordinal"*/}
              {/*            onMouseLeave={this._onMouseLeaveCost} >*/}

              {/*            <XAxis position="middle" style={{*/}
              {/*                text: { fill: "#82bce3" },*/}
              {/*                line: { stroke: "#d5e9f6" },*/}
              {/*                title: { fill: "#82bce3" }*/}
              {/*            }} />*/}
              {/*            <YAxis title="Consumption" position="middle" tickFormat={SavingsFormatter}*/}
              {/*                style={{*/}
              {/*                    text: { fill: "#82bce3" },*/}
              {/*                    line: { stroke: "none", fill: "none" }*/}
              {/*                }} />*/}
              {/*            <Crosshair values={this.state.crosshairCostValues}*/}
              {/*                style={{*/}
              {/*                    background: "#4C4A42",*/}
              {/*                    line: { width: "0px" }*/}
              {/*                }}*/}
              {/*                itemsFormat={this.toolTipCostFormat} />*/}
              {/*            <VerticalBarSeries data={this.state.productionCostData} color="#d5e9f6" />*/}
              {/*            <LineMarkSeries data={this.state.consumptionCostData} color="#82bce3" onNearestX={this._onNearestXCost} />*/}

              {/*        </XYPlot>*/}

              {/*    </div>*/}
              {/*}*/}
            </Row>
          </Col>
        </Row>
      </div >
    )
  }
}