import * as React from 'react';
import axios from 'axios';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../functions/numberFunctions';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { getBearerToken } from '../../functions/authActions';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { DownloadNamedFile } from '../../functions/documentTools';
import InvoiceCreationModal from './InvoiceCreationModal';

function pad(num: number, size: number) {
  let _num = num.toString();
  while (_num.length < size) _num = "0" + _num;
  return _num;
}

interface InvoiceRowData {
  uid: string;
  tag: string;
  cost: number;
  vendor: string;
  customer: string;
  linked: string;
  invoiceDate: Date;
  createdBy: string;
  removeRow?: (index: number) => void;
  index?: number;
}

interface InvoiceListDataProps {

}

interface InvoiceListDataState {
  invoices: Array<InvoiceRowData>;
  vendor: string;
  customer: string;
  createdBy: string;
  billed: SelectOptions;
  paid: SelectOptions;
  headers: Array<FilterAndSettingField>;
}

class InvoiceRow extends React.Component<InvoiceRowData, InvoiceRowData> {

  constructor(props: InvoiceRowData) {
    super(props);
    this.state = {
      uid: props.uid,
      tag: props.tag,
      cost: props.cost,
      vendor: props.vendor,
      customer: props.customer,
      index: props.index,
      linked: props.linked,
      invoiceDate: props.invoiceDate,
      removeRow: props.removeRow,
      createdBy: props.createdBy
    }
    this.getInvoice = this.getInvoice.bind(this);
    this.archive = this.archive.bind(this);
  }

  async getInvoice(): Promise<void> {
    let response = await axios.get('./api/get-invoice/' + this.state.uid, { responseType: 'blob' });
    await DownloadNamedFile(response);
  }

  archive(): void {
    const self = this;
    axios.delete('./api/get-invoice/' + this.state.uid).then(function (response) {
      CreateGlobalAlert("Invoice has been Archived. If this is a mistake, let John know.", 2000);
      if (self.state.removeRow !== undefined && self.state.index !== undefined) {
        self.state.removeRow(self.state.index);
      }
    })
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirstLink" onClick={this.getInvoice}>
          {this.state.tag}
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(this.state.cost)}</td>
        <td className="fixedTableCell" style={{ textAlign: "center" }}>{this.state.vendor}</td>
        <td className="fixedTableCell" style={{ textAlign: "center" }}>{this.state.customer}</td>
        <td className="fixedTableCell" style={{ textAlign: "center" }}>{this.state.invoiceDate.toLocaleDateString()}</td>
        <td className="fixedTableCell" style={{ textAlign: "center" }}>{this.state.createdBy}</td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faArchive} onClick={this.archive} style={{ marginLeft: "35%", cursor: "pointer", color: "#b89961" }} />
        </td>
      </tr>
    )
  }
}

export class GenerateInvoiceList extends React.Component<InvoiceListDataProps, InvoiceListDataState> {

  createModal: React.RefObject<InvoiceCreationModal> = React.createRef<InvoiceCreationModal>();

  constructor(props: InvoiceListDataProps) {
    super(props);
    this.state = {
      invoices: [],
      vendor: "",
      customer: "",
      createdBy: "",
      billed: { label: "All", value: "0" },
      paid: { label: "All", value: "0" },
      headers: []
    }
    this.changeString = this.changeString.bind(this);
    this.changeBilled = this.changeBilled.bind(this);
    this.changePaid = this.changePaid.bind(this);
    this.updateList = this.updateList.bind(this);
    this.removeRow = this.removeRow.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    this.generateHeaders();
  }

  //#region Functions

  changeString(event: React.ChangeEvent<HTMLInputElement>): void {
    //@ts-ignore
    this.setState({
      [event.target.name]: event.target.value
    }, () => this.generateHeaders())
  }

  changeBilled(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      billed: event
    }, this.updateList)
  }

  changePaid(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      paid: event
    }, this.updateList)
  }

  async updateList(): Promise<void> {
    const vendor = this.state.vendor === "" ? null : this.state.vendor;
    const customer = this.state.customer === "" ? null : this.state.customer;
    const createdBy = this.state.createdBy === "" ? null : this.state.createdBy;
    const self = this;
    await axios.get('./api/get-invoice/' + vendor + "/" + customer + "/" + createdBy + "/" + this.state.billed.value + "/" + this.state.paid.value).then(function (response) {
      const _: Array<InvoiceRowData> = [];
      response.data.forEach(function (item: any) {
        _.push({
          uid: item.uid,
          tag: item.year + pad(item.number, 5),
          vendor: item.vendorName,
          customer: item.customerName,
          cost: item.totalCost,
          linked: item.linked ? "Yes" : "No",
          invoiceDate: new Date(item.invoiceDate),
          createdBy: item.createdBy
        })
      })
      self.setState({
        invoices: _
      })
    })
  }

  removeRow(idx: number): void {
    const rows = this.state.invoices;
    rows.splice(idx, 1);
    this.setState({
      invoices: rows
    })
  }

  //#endregion

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Invoice Number",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total Cost",
        setting: true,
        filter: {}
      },
      {
        columnName: "Vendor Name",
        setting: true,
        filter: {
          type: "text",
          name: "vendor",
          value: this.state.vendor,
          changeFilter: this.changeString,
          placeHolder: "Filter By Vendor"
        }
      },
      {
        columnName: "Customer Name",
        setting: true,
        filter: {
          type: "text",
          name: "customer",
          value: this.state.customer,
          changeFilter: this.changeString,
          placeHolder: "Filter By Customer"
        }
      },
      {
        columnName: "Invoice Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "Created By",
        setting: true,
        filter: {
          type: "text",
          value: this.state.createdBy,
          onChange: this.changeString,
          placeHolder: "Filter By Created By"
        }
      }
    ]
    this.setState({
      headers: headers
    }, this.updateList)
  }

  render(): JSX.Element {
    return (
      <div>
        <InvoiceCreationModal ref={this.createModal} />
        <h3>Invoice List</h3>
        <div className="scrollable-table-container">
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              {
                this.state.invoices.map((item, idx) => (
                  <InvoiceRow key={item.uid} uid={item.uid} tag={item.tag} cost={item.cost} vendor={item.vendor} customer={item.customer}
                    removeRow={this.removeRow} index={idx} linked={item.linked} invoiceDate={item.invoiceDate} createdBy={item.createdBy}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
        {/*<input*/}
        {/*  type="button"*/}
        {/*  value="Test Creation Modal"*/}
        {/*  onClick={() => this.createModal.current.show()}*/}
        {/*  className="standard-input"*/}
        {/*/>*/}
      </div>
    )
  }
}