//#region Imports
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { getBearerToken, getUserID } from '../../../../functions/authActions';
//@ts-ignore
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { pullFleetEmployee, pullRegisteredAgents, pullStates } from '../../../../functions/fetchLinkedObjects';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../../functions/selectTools';
import { SelectOptions } from '../../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../../style/select-constants';
import { StreetAddress } from '../../../CoreComponents/interfaces';
import { StatusModal } from '../../../CoreComponents/Modals';
import { AddressComponent } from '../../../LocationComponents/AddressComponent';
import { EntityManagementType_NewRequest, EntityAccountingType_NewRequest, EntityPurpose_NewRequest, EntitySCorp_NewRequest, EntityTypes_NewRequest } from '../../Structs';
import * as funcs from '../../../../functions/patchFunctions';
//#endregion

export interface FormationDataTabProps {
  uid: string;
  checkValid: (checkedValue: boolean) => void;
}

export interface FormationDataTabState {
  userName: string;
  uid: string;
  entityName: string;
  entityType: SelectOptions;
  formationState: SelectOptions;
  sosRegistrations: Array<SelectOptions>;
  entityPurpose: SelectOptions;
  initialContribution: number;
  accountingType: SelectOptions;
  employees: Array<SelectOptions>;
  projectLeads: Array<SelectOptions>;
  financeLeads: Array<SelectOptions>;
  CCList: Array<SelectOptions>;
  requiredReviewers: Array<SelectOptions>;
  states: Array<SelectOptions>;
  streetAddress: StreetAddress;
  president: string;
  vicePresident: string;
  secretary: string;
  treasurer: string;
  sCorpElection: SelectOptions;
  management: SelectOptions;
  registeredAgent: SelectOptions;
  registeredAgents: Array<SelectOptions>;
  isComplete: boolean;
  sectionOne: boolean;
  sectionTwo: boolean;
  sectionThree: boolean;
  sectionFour: boolean;
  editingAddress: boolean;
  addCC: boolean;
}

/**
 * OBSOLETE: THIS FUNCTION NEEDS TO BE REMOVED AS IT IS TO BROAD, ANY FILTERS SHOULD BE INHERITED FROM THE SELECT OPTIONS INTERFACE
 * @param oldA
 * @param newA
 * @returns
 */
export function compareTwoArray(oldA: Array<any>, newA: Array<any>): any {
  let returnObj = {
    //@ts-ignore
    item: undefined,
    //@ts-ignore
    added: undefined
  }

  if (newA.length === 0) {
    returnObj.item = oldA[0];
    returnObj.added = false;
  }
  else if (oldA.length < newA.length) {
    newA.forEach((item) => {
      if (!oldA.includes(item)) {
        returnObj.item = item;
        returnObj.added = true;
      }
    })
  }
  else if (oldA.length > newA.length) {
    oldA.forEach((item) => {
      if (!newA.includes(item)) {
        returnObj.item = item;
        returnObj.added = false;
      }
    })
  }

  return returnObj;
}

export class FormationDataTab extends React.PureComponent<FormationDataTabProps, FormationDataTabState> {

  statusModal = React.createRef<StatusModal>();

  constructor(props: FormationDataTabProps) {
    super(props);
    this.state = {
      userName: '',
      uid: props.uid,
      entityName: '',
      entityType: { label: "Limited Liability Corporation", value: "1" },
      formationState: { label: "Oregon", value: "36" },
      sosRegistrations: [],
      entityPurpose: { label: "Membership", value: "0" },
      initialContribution: 2000,
      accountingType: { label: "Select Type", value: "" },
      projectLeads: [],
      financeLeads: [],
      CCList: [],
      requiredReviewers: [],
      streetAddress: {
        address: "200 E Main Street",
        city: "Enterprise",
        state: { label: "Oregon", value: "36" },
        zipcode: "97828"
      },
      president: '',
      vicePresident: '',
      secretary: '',
      treasurer: '',
      sCorpElection: { label: "No", value: "0" },
      management: { label: "Select Management Type", value: "-1" },
      registeredAgent: { label: "Select Registered Agent", value: "" },
      registeredAgents: [],
      employees: [],
      states: [],
      isComplete: false,
      sectionOne: false,
      sectionTwo: false,
      sectionThree: false,
      sectionFour: false,
      editingAddress: false,
      addCC: false
    }
    this.checkForm = this.checkForm.bind(this);
    this.createEntityDraft = this.createEntityDraft.bind(this);

    this.changeName = this.changeName.bind(this);
    this.changeEntityType = this.changeEntityType.bind(this);
    this.changeFormationState = this.changeFormationState.bind(this);
    this.changeSOSRegistration = this.changeSOSRegistration.bind(this);
    this.changeEntityPurpose = this.changeEntityPurpose.bind(this);
    this.changeCapitalContribution = this.changeCapitalContribution.bind(this);
    this.changeEntityAccountType = this.changeEntityAccountType.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.saveAddress = this.saveAddress.bind(this);

    this.changeProjectLeads = this.changeProjectLeads.bind(this);
    this.changeFinanceLeads = this.changeFinanceLeads.bind(this);
    this.changeRequiredReviewers = this.changeRequiredReviewers.bind(this);
    this.changeCCList = this.changeCCList.bind(this);

    this.changePresident = this.changePresident.bind(this);
    this.changeVicePresident = this.changeVicePresident.bind(this);
    this.changeSecretary = this.changeSecretary.bind(this);
    this.changeTreasurer = this.changeTreasurer.bind(this);

    this.changeSCorpElection = this.changeSCorpElection.bind(this);
    this.changeManagement = this.changeManagement.bind(this);
    this.changeRegisteredAgent = this.changeRegisteredAgent.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const states = await pullStates();
    const employees = await pullFleetEmployee();
    const registeredAgents = await pullRegisteredAgents();
    const user = { label: employees.filter(u => u.value === getUserID())[0].label, value: getUserID() }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();

    this.setState({
      userName: user.label,
      states: states,
      employees: employees
    })

    if (this.state.uid !== '') {
      await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
        const sos: SelectOptions[] = [];
        const projectLeads: SelectOptions[] = [];
        const finanaceLeads: SelectOptions[] = [];
        const reviewers: SelectOptions[] = [];
        const ccList: SelectOptions[] = [];

        if (response.data.sosRegistrationStates.length > 0) {
          response.data.sosRegistrationStates.forEach((item: number) => {
            sos.push({ label: states.filter(u => parseInt(u.value) === item)[0].label, value: item.toString() });
          })
        }

        if (response.data.emailList.length > 0) {
          response.data.emailList.forEach((item: any) => {
            if (item.emailListType === 0) { projectLeads.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.toString() }) }
            else if (item.emailListType === 1) { finanaceLeads.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.toString() }) }
            else if (item.emailListType === 3) { reviewers.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.toString() }) }
            else if (item.emailListType === 2) { ccList.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.toString() }) }
          })
        }

        if (ccList.length > 0) { this.setState({ addCC: true }) }

        this.setState({
          entityName: response.data.entityName,
          entityType: { label: EntityTypes_NewRequest.filter(u => parseInt(u.value) === response.data.type)[0].label, value: response.data.type.toString() },
          formationState: { label: states.filter(u => parseInt(u.value) === response.data.formationState)[0].label, value: response.data.formationState.toString() },
          sosRegistrations: sos,
          entityPurpose: { label: EntityPurpose_NewRequest.filter(u => parseInt(u.value) === response.data.entityPurpose)[0].label, value: response.data.entityPurpose.toString() },
          initialContribution: response.data.initialCapital,
          accountingType: { label: EntityAccountingType_NewRequest.filter(u => parseInt(u.value) === response.data.accountingType)[0].label, value: response.data.accountingType.toString() },
          projectLeads: projectLeads,
          financeLeads: finanaceLeads,
          CCList: ccList,
          requiredReviewers: reviewers,
          streetAddress: {
            address: response.data.address.streetAddress,
            city: response.data.address.city,
            state: { label: states.filter(u => parseInt(u.value) === response.data.address.state)[0].label, value: response.data.address.state.toString() },
            zipcode: response.data.address.zipCode
          },
          president: response.data.president,
          vicePresident: response.data.vicePresident,
          secretary: response.data.secretary,
          treasurer: response.data.treasurer,
          sCorpElection: { label: EntitySCorp_NewRequest.filter(u => parseInt(u.value) === response.data.sCorpElection)[0].label, value: response.data.sCorpElection.toString() },
          management: { label: EntityManagementType_NewRequest.filter(u => parseInt(u.value) === response.data.entityManagementType)[0].label, value: response.data.entityManagementType.toString() },
          registeredAgents: registeredAgents
        })

        if (response.data.newRegisteredAgent !== 0) {
          this.setState({
            registeredAgent: {
              label: registeredAgents.filter(u => parseInt(u.value) === response.data.newRegisteredAgent)[0].label,
              value: response.data.newRegisteredAgent.toString()
            },
          })
        }

      })
    }
  }

  async componentDidUpdate(): Promise<void> {
    this.checkForm();
  }

  async checkForm(): Promise<void> {
    //Section One
    if (this.state.entityName !== '' &&
      parseInt(this.state.entityType.value) >= 0 &&
      parseInt(this.state.formationState.value) >= 0 &&
      /*      this.state.sosRegistrations.length > 0 &&*/
      parseInt(this.state.entityPurpose.value) >= 0 &&
      this.state.initialContribution > -1 &&
      this.state.accountingType.value !== ""
    ) {
      this.setState({ sectionOne: true })
    }
    else { this.setState({ sectionOne: false }) }

    if (this.state.sectionOne && this.state.uid === '') {
      await this.createEntityDraft();
    }

    //Section Two
    if (this.state.projectLeads.length > 0 &&
      this.state.financeLeads.length > 0 &&
      this.state.requiredReviewers.length > 0
    ) { this.setState({ sectionTwo: true }) }
    else { this.setState({ sectionTwo: false }) }

    //Section Three
    if (parseInt(this.state.entityType.value) === 0 || parseInt(this.state.entityType.value) === 1) {
      if (this.state.president !== '' &&
        this.state.vicePresident !== '' &&
        this.state.secretary !== '' &&
        this.state.treasurer !== ''
      ) { this.setState({ sectionThree: true }) }
      else { this.setState({ sectionThree: false }) }
    } else { this.setState({ sectionThree: true }) }

    //Section Four
    if (parseInt(this.state.entityType.value) === 2 || parseInt(this.state.entityType.value) === 3) {
      if (parseInt(this.state.sCorpElection.value) >= 0 &&
        parseInt(this.state.management.value) >= 0 &&
        this.state.registeredAgent.value >= ''
      ) { this.setState({ sectionFour: true }) }
      else { this.setState({ sectionFour: false }) }
    } else { this.setState({ sectionFour: true }) }

    if (this.state.sectionOne && this.state.sectionTwo && this.state.sectionThree && this.state.sectionFour) {
      this.setState({ isComplete: true }, () => this.props.checkValid(this.state.isComplete))
    } else { this.setState({ isComplete: false }, () => this.props.checkValid(this.state.isComplete)) }
  }

  async createEntityDraft(): Promise<void> {
    this.statusModal.current.display("Creating Entity Draft", "Please wait while the page refreshes");
    const sos: any = []
    this.state.sosRegistrations.forEach((item) => { sos.push(item.value) })

    const data = {
      RequestorUID: getUserID(),
      EntityName: this.state.entityName,
      Type: this.state.entityType.value,
      FormationState: this.state.formationState.value,
      SosRegistrationStates: sos,
      EntityPurpose: this.state.entityPurpose.value,
      InitialCapital: this.state.initialContribution
    }

    await axios.post("./api/new-entity-request", data).then((response) => {
      this.setState({ uid: response.data })
    });

    const workflowData = {
      UID: this.state.uid,
      RequestorUID: getUserID()
    }

    await axios.post('./api/new-entity-workflow/entry-point', workflowData).then((respsonse) => {
      CreateGlobalAlert("Draft has been made, Reloading Page", 2500);
      window.location.replace("./entity-request-form?draftUID=" + this.state.uid)
    })

  }

  //#region Form

  //#region Section One
  async changeName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ entityName: event.target.value });
    await funcs.patchStringValue(this.state.uid, event.target.value, "./api/new-entity-request/update-name");
  }

  async changeEntityType(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ entityType: event });
    await funcs.patchSelectOptions(this.state.uid, event, "./api/new-entity-request/update-type");
  }

  async changeFormationState(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ formationState: event },
      async () => await funcs.patchSelectOptions(this.state.uid, event, "./api/new-entity-request/update-formation-state")
    );
  }

  async changeSOSRegistration(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ sosRegistrations: event },
      async () => await funcs.patchListSelectOptions(this.state.uid, event, "./api/new-entity-request/update-foreign-sos")
    );
  }

  async changeEntityPurpose(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ entityPurpose: event });
    let initial: number = 2000;
    if (event.value === "1") {
      initial = 0;
    }
    await funcs.patchSelectOptions(this.state.uid, event, "./api/new-entity-request/update-purpose");
    await funcs.patchNumberValue(this.state.uid, initial, "./api/new-entity-request/update-initial-capital");
  }

  async changeCapitalContribution(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ initialContribution: parseFloat(event.target.value) },
      async () => await funcs.patchNumberValue(this.state.uid, parseFloat(event.target.value), "./api/new-entity-request/update-initial-capital")
    );
  }

  async changeEntityAccountType(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ accountingType: event },
      async () => await funcs.patchSelectOptions(this.state.uid, event, "./api/new-entity-request/update-accounting-type")
    );
  }

  updateAddress(streetAddress: StreetAddress): void {
    this.setState({ streetAddress: streetAddress })
  }

  async saveAddress(): Promise<void> {
    const addressData = {
      StreetAddress: this.state.streetAddress.address,
      City: this.state.streetAddress.city,
      State: this.state.streetAddress.state.value,
      ZipCode: this.state.streetAddress.zipcode
    }

    const data = {
      UID: this.state.uid,
      Address: addressData
    }
    const newAddress = {
      address: this.state.streetAddress.address,
      city: this.state.streetAddress.city,
      state: this.state.streetAddress.state,
      zipcode: this.state.streetAddress.zipcode
    }

    this.setState({
      streetAddress: newAddress,
      editingAddress: false
    })

    await axios.patch("./api/new-entity-request/update-address", data).then((response) => {
      CreateGlobalAlert("Address Updated", 2500);
    })
  }
  //#endregion

  //#region Section Two
  async changeProjectLeads(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return; }
    const compared = compareTwoArray(this.state.projectLeads, event);
    this.setState({ projectLeads: event });
    const data = {
      NewEntityUID: this.state.uid,
      EmployeeUID: compared.item.value
    }

    if (compared.added) {
      await axios.patch("./api/new-entity-request/add-project-lead", data).then((response) => {
        CreateGlobalAlert("Project Lead Added", 2500);
      })
    }
    else if (!compared.added) {
      await axios.patch("./api/new-entity-request/remove-project-lead", data).then((response) => {
        CreateGlobalAlert("Project Lead Removed", 2500);
      })
    }
  }

  async changeFinanceLeads(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return; }
    const compared = compareTwoArray(this.state.financeLeads, event);
    this.setState({ financeLeads: event })
    const data = {
      NewEntityUID: this.state.uid,
      EmployeeUID: compared.item.value
    }

    if (compared.added) {
      await axios.patch("./api/new-entity-request/add-finance-lead", data).then((response) => {
        CreateGlobalAlert("Finance Lead Added", 2500);
      })
    }

    if (!compared.added) {
      await axios.patch("./api/new-entity-request/remove-finance-lead", data).then((response) => {
        CreateGlobalAlert("Finance Lead Removed", 2500);
      })
    }
  }

  async changeRequiredReviewers(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return; }
    const compared = compareTwoArray(this.state.requiredReviewers, event);
    this.setState({ requiredReviewers: event })
    const data = {
      NewEntityUID: this.state.uid,
      EmployeeUID: compared.item.value
    }

    if (compared.added) {
      await axios.patch("./api/new-entity-request/add-required-reviewer", data).then((response) => {
        CreateGlobalAlert("Reviewer Added", 2500);
      })
    }

    if (!compared.added) {
      await axios.patch("./api/new-entity-request/remove-required-reviewer", data).then((response) => {
        CreateGlobalAlert("Reviewer Removed", 2500);
      })
    }
  }

  async changeCCList(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return; }
    const compared = compareTwoArray(this.state.CCList, event);
    this.setState({ CCList: event })
    const data = {
      NewEntityUID: this.state.uid,
      EmployeeUID: compared.item.value
    }

    if (compared.added) {
      await axios.patch("./api/new-entity-request/add-cc-list", data).then((response) => {
        CreateGlobalAlert("CC Added", 2500);
      })
    }

    if (!compared.added) {
      await axios.patch("./api/new-entity-request/remove-cc-list", data).then((response) => {
        CreateGlobalAlert("CC Removed", 2500);
      })
    }
  }
  //#endregion

  //#region Section Three
  async changePresident(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ president: event.target.value },
      async () => await funcs.patchStringValue(this.state.uid, this.state.president, "./api/new-entity-request/update-president")
    );
  }

  async changeVicePresident(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ vicePresident: event.target.value },
      async () => await funcs.patchStringValue(this.state.uid, this.state.president, "./api/new-entity-request/update-vice-president")
    );
  }

  async changeSecretary(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ secretary: event.target.value },
      async () => await funcs.patchStringValue(this.state.uid, this.state.secretary, "./api/new-entity-request/update-secretary")
    );
  }

  async changeTreasurer(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ treasurer: event.target.value },
      async () => await funcs.patchStringValue(this.state.uid, event.target.value, "./api/new-entity-request/update-treasurer")
    );
  }
  //#endregion

  //#region Section Four
  async changeSCorpElection(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ sCorpElection: event },
      async () => await funcs.patchSelectOptions(this.state.uid, event, "./api/new-entity-request/update-scorp")
    );
  }

  async changeManagement(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ management: event },
      async () => await funcs.patchSelectOptions(this.state.uid, event, "./api/new-entity-request/update-management-type")
    );
  }

  async changeRegisteredAgent(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ registeredAgent: event },
      async () => await funcs.patchSelectOptions(this.state.uid, event, "./api/new-entity-request/update-registered-agent")
    );
  }
  //#endregion

  //#endregion

  render(): JSX.Element {
    return (
      <div className="entityNewRequestFormContainer" style={{ height: "78vh", overflowY: "auto", overflowX: "hidden", paddingBottom: "5vh" }}>
        <StatusModal ref={this.statusModal} />
        {/*{Heading}*/}
        <Row>
          <Col>
            <h4 style={{ marginTop: "1vh" }}>Requested By: {this.state.userName}</h4>
          </Col>
          <Col>
            <h4 style={{ marginTop: "1vh" }}>Date Requested: {new Date().toLocaleDateString()}</h4>
          </Col>
        </Row>
        {/*{Section One}*/}
        <Row>
          <Col>
            <label>Desired Entity Name</label>
            <input type="text" className="standard-input" style={{ height: "4vh" }} value={this.state.entityName} onChange={this.changeName} />
          </Col>
          <Col>
            <label>Entity Type</label>
            <Select
              options={EntityTypes_NewRequest}
              styles={reactSelectBasicStyle}
              value={this.state.entityType}
              onChange={this.changeEntityType}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
          <Col>
            <label>Formation State</label>
            <Select
              styles={reactSelectBasicStyle}
              options={this.state.states}
              value={this.state.formationState}
              onChange={this.changeFormationState}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Foreign SOS Registration</label>
            <Select
              styles={reactSelectBasicStyle}
              options={this.state.states}
              value={this.state.sosRegistrations}
              onChange={this.changeSOSRegistration}
              isMulti
              isClearable={false}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <label>Entity Purpose</label>
                <Select
                  styles={reactSelectBasicStyle}
                  options={EntityPurpose_NewRequest}
                  menuPlacement="top"
                  value={this.state.entityPurpose}
                  onChange={this.changeEntityPurpose}
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
              </Col>
              <Col>
                <label>Initial Contribution</label>
                <input type="number" className="standard-input" style={{ height: "4vh" }} value={this.state.initialContribution} onChange={this.changeCapitalContribution} />
              </Col>
            </Row>
          </Col>
          <Col>
            <label>Method of Accounting Type</label>
            <Select
              styles={reactSelectBasicStyle}
              options={EntityAccountingType_NewRequest}
              menuPlacement="top"
              value={this.state.accountingType}
              onChange={this.changeEntityAccountType}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
        </Row>
        <hr />
        {/*{Section Two}*/}
        {(this.state.sectionOne && this.state.uid !== '') && <>
          {!this.state.editingAddress ?
            <Row>
              <Col>
                <h3>Address</h3>
                <Row>
                  <Col>
                    <label>Address</label>
                    <br />
                    <label>{this.state.streetAddress.address}</label>
                  </Col>
                  <Col>
                    <label>City</label>
                    <br />
                    <label>{this.state.streetAddress.city}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>State</label>
                    <br />
                    <label>{this.state.streetAddress.state.label}</label>
                  </Col>
                  <Col>
                    <label>ZipCode</label>
                    <br />
                    <label>{this.state.streetAddress.zipcode}</label>
                  </Col>
                </Row>
              </Col>
              <Col xs="3">
                {this.state.sectionOne && <input type="button" className="standard-input" value="Change Address" onClick={() => this.setState({ editingAddress: true })}
                  style={{ height: "4vh", marginBottom: "1vh", marginTop: "1vh" }} />}
              </Col>
            </Row> :
            <Row>
              <Col>
                <AddressComponent states={this.state.states} streetAddress={this.state.streetAddress} updateAddress={this.updateAddress} />
              </Col>
              <Col xs="3">
                {this.state.sectionOne && <input type="button" className="standard-input" value="Save Address" onClick={() => this.saveAddress()}
                  style={{ height: "4vh", marginBottom: "1vh", marginTop: "1vh" }} />}
              </Col>
            </Row>
          }
          <hr />
          <Row>
            <h3>Active Employees & Email Lists</h3>
            <Col>
              <label>Project Leads</label>
              <Select
                value={this.state.projectLeads}
                styles={reactSelectBasicStyle}
                options={this.state.employees}
                onChange={this.changeProjectLeads}
                menuPlacement="top"
                isMulti
                isClearable={false}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col>
              <label>Finance Leads</label>
              <Select
                value={this.state.financeLeads}
                styles={reactSelectBasicStyle}
                options={this.state.employees}
                onChange={this.changeFinanceLeads}
                menuPlacement="top"
                isMulti
                isClearable={false}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col>
              <label>Required Reviewers</label>
              <Select
                value={this.state.requiredReviewers}
                styles={reactSelectBasicStyle}
                options={this.state.employees}
                onChange={this.changeRequiredReviewers}
                menuPlacement="top"
                isMulti
                isClearable={false}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!this.state.addCC ?
                <>
                  <input type="button" className="standardInput" value="Add CC" onClick={() => this.setState({ addCC: true })}
                    style={{ height: "4vh", marginBottom: "2vh", marginTop: "1vh" }} />
                </> :
                <>
                  <label>CC List</label>
                  <Select
                    value={this.state.CCList}
                    styles={reactSelectBasicStyle}
                    options={this.state.employees}
                    onChange={this.changeCCList}
                    menuPlacement="top"
                    isMulti
                    isClearable={false}
                    onFocus={RemoveStickyOverlays}
                    onBlur={RestoreStickyOverlays}
                  />
                </>
              }
            </Col>
            <Col>
            </Col>
            <Col>
            </Col>
          </Row>
        </>}
        {/*{Section Three}*/}
        {(this.state.sectionOne && this.state.sectionTwo && (parseInt(this.state.entityType.value) === 7 || parseInt(this.state.entityType.value) === 2)) && <>
          <hr />
          <h3>Corporation Officers</h3>
          <Row>
            <Col>
              <label>President</label>
              <input type="text" className="standard-input" style={{ height: "4vh" }} value={this.state.president} onChange={this.changePresident} />
            </Col>
            <Col>
              <label>Vice President</label>
              <input type="text" className="standard-input" style={{ height: "4vh" }} value={this.state.vicePresident} onChange={this.changeVicePresident} />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Secretary</label>
              <input type="text" className="standard-input" style={{ height: "4vh" }} value={this.state.secretary} onChange={this.changeSecretary} />
            </Col>
            <Col>
              <label>Treasurer</label>
              <input type="text" className="standard-input" style={{ height: "4vh" }} value={this.state.treasurer} onChange={this.changeTreasurer} />
            </Col>
          </Row>
        </>}
        {/*{Section Four}*/}
        {(this.state.sectionOne && this.state.sectionTwo && (parseInt(this.state.entityType.value) === 1)) && <>
          <hr />
          <h3>Limited Liability Corporation Fields</h3>
          <Row>
            <Col>
              <label>S-Corp Election</label>
              <Select
                options={EntitySCorp_NewRequest}
                styles={reactSelectBasicStyle}
                value={this.state.sCorpElection}
                onChange={this.changeSCorpElection}
                menuPlacement="top"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col>
              <label>Management Type</label>
              <Select
                options={EntityManagementType_NewRequest}
                styles={reactSelectBasicStyle}
                value={this.state.management}
                onChange={this.changeManagement}
                menuPlacement="top"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Registered Agent</label>
              <Select
                options={this.state.registeredAgents}
                styles={reactSelectBasicStyle}
                value={this.state.registeredAgent}
                onChange={this.changeRegisteredAgent}
                menuPlacement="top"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col>
            </Col>
          </Row>
        </>}
      </div>
    )
  }
}