import axios from "axios";
import * as React from "react";
import * as Structs from "../Structs";
import { Col, Row } from "reactstrap";
import { DownloadNamedFile } from "../../../functions/documentTools";
import { StatusModal } from "../../CoreComponents/Modals";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";

export class JobReportingGeneratorWidget extends React.Component<{uid: string}, {}> {

  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();

  constructor(props: Structs.GeneralWaiverTableProps) {
    super(props);
    this.state = {
      uid: props.uid,
      waivers: [],
    }
    this.downloadFinalWaivers = this.downloadFinalWaivers.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  async downloadFinalWaivers(): Promise<void> {
    this.statusModal.current.display("Downloading Final Waivers", "Please wait we pull & zip the files");
    let response = await axios.get('./api/managed-jobs/final-waivers/' + this.props.uid, { responseType: 'blob', validateStatus: () => true });
    this.statusModal.current.hide();
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
  }

  render(): JSX.Element {
    return (
      <>
        <StatusModal ref={this.statusModal} />
        {RenderHeader("Reporting Functions")}
        <Row>
          <Col>
            <input type="button" value="Generate Final Waiver Package" className="standard-input" onClick={this.downloadFinalWaivers} />
          </Col>
        </Row>
      </>
    )
  }
}