import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { pullProperties } from '../../../functions/fetchLinkedObjects';
import { YesNo, YesNoAll } from '../../../constants/EnumConstants';
import axios from 'axios';

interface EntityPropertyOwnershipTableModalProps {
  save: (property: SelectOptions, isManagingMember: boolean) => void;
}

interface EntityPropertyOwnershipTableModalState {
  show: boolean;
  properties: Array<SelectOptions>;
  property: SelectOptions;
  yesNo: SelectOptions;
  uid: string;
}

export default class EntityPropertyOwnershipTableModal extends React.Component<EntityPropertyOwnershipTableModalProps, EntityPropertyOwnershipTableModalState> {

  constructor(props: EntityPropertyOwnershipTableModalProps) {
    super(props);
    this.state = {
      show: false,
      properties: [],
      property: { label: "Select Option", value: "" },
      yesNo: { label: "Select Option", value: "" },
      uid: ""
    }
    this.changeProperty = this.changeProperty.bind(this);
    this.changeYesNo = this.changeYesNo.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();
    this.setState({ properties: properties });
  }

  show(uid: string): void {
    this.setState({
      show: true,
      uid: uid
    })
  }

  forceClose(): void {
    this.setState({
      show: false,
      property: { label: "SelectOptions", value: "" },
      yesNo: { label: "SelectOptions", value: "" },
    })
  }

  changeProperty(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ property: event });
  }

  changeYesNo(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ yesNo: event });
  }

  async save(): Promise<void> {
    const data = {
      PropertyUID: this.state.property.value,
      EntityUID: this.state.uid,
      IsManagingMember: this.state.yesNo.value === "1"
    };
    let response = await axios.post('./api/entity-property-ownership', data);
    if (response.status === 202) {
      this.props.save(this.state.property, this.state.yesNo.value === "1")
    }
    else {
      // TODO: Handle Error 
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Add Owned Property
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Property</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.property}
                  styles={reactSelectBasicStyle}
                  options={this.state.properties}
                  onChange={this.changeProperty}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Managing Member</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.yesNo}
                  styles={reactSelectBasicStyle}
                  options={YesNo}
                  onChange={this.changeYesNo}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Save" className="standard-input" onClick={() => this.save()} />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}