import * as React from 'react';
import { WarningModal } from '../CoreComponents/Modals';
import axios from 'axios';

export async function PullSoftwareBillingRuns(modal: React.RefObject<WarningModal>, software: string): Promise<Array<any>> {
  let response = await axios.get('./api/software-billing-tracking/' + software, { validateStatus: () => true });
  if (response.status !== 200) {
    modal.current.show("Error Pulling " + software + " Billing", "Error.");
  }
  else {
    return response.data;
  }
}