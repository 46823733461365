import * as React from 'react';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { DashboardManagementModal } from '../../CoreComponents/Modals/DashboardManagementModal';
import { GetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DashboardOrganizer, DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { UsefulLinksWidget } from '../../CoreComponents/UsefulLinksWidget';
import { PropertyPayrollSelectionWidget } from '../../PropertyComponents/PropertyWidgets/PropertyPayrollSelectionWidget';
import { PropertyAllocationSelectionWidget } from '../../PropertyComponents/PropertyWidgets/PropertyAllocationSelectionWidget';
import { RegionalManagerApprovalWorkflowWidget } from '../../PayrollComponents/Widgets/RegionalManagerApprovalWorkflowWidget';
import { PayrollBreakdownWidget } from '../Widgets/PayrollBreakdownWidget';
import { EmployeeAllocationsWidget } from '../../PayrollComponents/Widgets/EmployeeAllocationsWidget';
import { Col, Row } from 'reactstrap';
import { EmployeeChangeRequestWidget } from '../../PayrollComponents/Widgets/EmployeeChangeRequestWidget';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';
import { EmployeePastAllocationReportGenerator } from '../../PayrollComponents/Widgets/EmployeePastAllocationReportGenerator';

interface VicePresidentDashboardState {
  showModal: boolean;
  renderSettings: RenderSettings;
  widgetList: Array<SelectOptions>;
  tabList: {[key: number]: JSX.Element};
}

export class VicePresidentDashboard extends React.Component<{}, VicePresidentDashboardState> {
  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();

  constructor(props: {}) {
    super(props);
    let settings: RenderSettings = GetSettings<RenderSettings>("vicePresidentDashboard");
    let showModal: boolean = false;
    if (settings == null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      showModal: showModal,
      renderSettings: settings,
      tabList: [],
      widgetList: []
    }
    this.updateUI = this.updateUI.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDashboard();
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show("vicePresidentDashboard", this.state.widgetList, this.updateUI);
    }
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  async prepareDashboard(): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      0: <UsefulLinksWidget />,
      1: <PropertyPayrollSelectionWidget useRegional={false} />,
      2: <PropertyAllocationSelectionWidget useRegional={false} />,
      3: <RegionalManagerApprovalWorkflowWidget isRegional={false} />,
      4: <PayrollBreakdownWidget />,
      5: <EmployeeAllocationsWidget />,
      6: <EmployeeChangeRequestWidget isRegional={false} />,
      7: <EmployeePastAllocationReportGenerator />
    };
    let widgetList = [
      { label: "Useful Links Widget", value: "0" },
      { label: "Property Payroll", value: "1" },
      { label: "Property Allocations", value: "2" },
      { label: "Payroll Approval Workflows", value: "3" },
      { label: "Payroll Breakdown Widget", value: "4" },
      { label: "Employee Allocation Widget", value: "5" },
      { label: "Employee Change Request", value: "6" },
      { label: "Employee Past Allocation Report", value: "7"}
    ];
    this.setState({
      widgetList: widgetList,
      tabList: tabList
    })
  }

  render(): JSX.Element {
    return (
      <>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>Vice President of Operations Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show("vicePresidentDashboard", this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
      </>
    )
  }
}