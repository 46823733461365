import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import axios from 'axios';

interface PropertyPayrollBudgetModalProps {
  uid: string;
  callback: (success: boolean) => void;
}

interface PropertyPayrollBudgetModalState {
  show: boolean;
  years: Array<SelectOptions>;
  year: SelectOptions;
  site: number;
  maintenance: number;
  phone: number;
  expense: number;
  auto: number;
}

export default class PropertyPayrollBudgetModal extends React.Component<PropertyPayrollBudgetModalProps, PropertyPayrollBudgetModalState> {

  constructor(props: PropertyPayrollBudgetModalProps) {
    super(props);
    this.state = {
      show: false,
      years: [],
      year: { label: new Date().getFullYear().toString(), value: new Date().getFullYear().toString() },
      site: 0,
      maintenance: 0,
      phone: 0,
      expense: 0,
      auto: 0
    }
    this.hide = this.hide.bind(this);
    this.save = this.save.bind(this);
    this.show = this.show.bind(this);
    this.changeYear = this.changeYear.bind(this);
    this.changeSiteManager = this.changeSiteManager.bind(this);
    this.changeMaintenanceTech = this.changeMaintenanceTech.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeExpense = this.changeExpense.bind(this);
    this.changeAuto = this.changeAuto.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let years = [];
    for (let i = 2023; i <= new Date().getFullYear() + 1; ++i) {
      years.push({ label: i.toString(), value: i.toString() });
    }
    this.setState({ years: years });
  }

  changeYear(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ year: event });
  }

  changeSiteManager(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ site: parseFloat(event.target.value) });
  }

  changeMaintenanceTech(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ maintenance: parseFloat(event.target.value) });
  }

  changePhone(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ phone: parseFloat(event.target.value) });
  }

  changeExpense(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ expense: parseFloat(event.target.value) });
  }

  changeAuto(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ auto: parseFloat(event.target.value) });
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  hide(): void {
    this.setState({
      show: false
    })
  }

  async save(): Promise<void> {
    const data = {
      propertyUID: this.props.uid,
      year: this.state.year.value,
      siteManagementBudget: this.state.site,
      maintenanceTechBudget: this.state.maintenance,
      phoneBudget: this.state.phone,
      expenseAllowance: this.state.expense,
      autoAllowance: this.state.auto
    };
    let response = await axios.post('./api/property-payroll-budget/', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.props.callback(true)
    } else {
      this.props.callback(false);
    }
    this.hide();
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Create Property Payroll Budget
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Year</h5>
              </Col>
              <Col>
                <Select
                  options={this.state.years}
                  styles={reactSelectBasicStyle}
                  onChange={this.changeYear}
                  value={this.state.year}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Site Manager</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.site} className="standard-input" onChange={this.changeSiteManager} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Maintenance Tech</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.maintenance} className="standard-input" onChange={this.changeMaintenanceTech} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Phone Budget</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.phone} className="standard-input" onChange={this.changePhone} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Expense Reimbursements</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.expense} className="standard-input" onChange={this.changeExpense} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Auto Allowance</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.auto} className="standard-input" onChange={this.changeAuto} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Save" onClick={this.save} className="standard-input" /> 
          </ModalBody>
        </div>
      </Modal>
    )
  }
}