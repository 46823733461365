import * as React from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { pullStates } from '../../functions/fetchLinkedObjects';
import { StreetAddress } from '../CoreComponents/interfaces';

interface FullAddressProps {
  states?: Array<SelectOptions>;
  streetAddress?: StreetAddress;
  updateAddress: (streetAddress: StreetAddress) => void;
}

interface FullAddressState {
  states: Array<SelectOptions>;
  streetAddress: StreetAddress;
}

export class AddressComponent extends React.Component<FullAddressProps, FullAddressState> {

  constructor(props: FullAddressProps) {
    super(props);
    console.log("Address Props", props.streetAddress);
    this.state = {
      states: props.states === undefined ? [] : props.states,
      streetAddress: {
        address: props.streetAddress?.address === undefined ? "200 E Main Street" : props.streetAddress.address,
        city: props.streetAddress?.city === undefined ? "Enterprise" : props.streetAddress.city,
        state: props.streetAddress?.state === undefined ? { label: "Oregon", value: "36" } : props.streetAddress.state,
        zipcode: props.streetAddress?.zipcode === undefined ? "97828" : props.streetAddress.zipcode
      },
    }
    this.changeAddressString = this.changeAddressString.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  async componentDidMount(): Promise<void> {
    if (this.state.states.length === 0) {
      const states = await pullStates();
      this.setState({
        states: states
      })
    }
  }

  changeAddressString(event: React.ChangeEvent<HTMLInputElement>): void {
    const streetAddress = this.state.streetAddress;
    //@ts-ignore
    streetAddress[event.target.name] = event.target.value;
    //@ts-ignore
    this.setState({
      streetAddress: streetAddress
    }, () => this.props.updateAddress(streetAddress))
  }

  changeState(event: SelectOptions | null): void {
    if (event === null) { return; }
    const streetAddress = this.state.streetAddress;
    streetAddress.state = event;
    this.setState({
      streetAddress: streetAddress
    }, () => this.props.updateAddress(streetAddress))
  }

  render(): JSX.Element {
    return (
      <>
        <h3>Address</h3>
        <Row>
          <Col>
            <label>Address</label>
            <br />
            <input className="standard-input" name="address" type="text" value={this.state.streetAddress.address} onChange={this.changeAddressString} placeholder="200 E Main Street" />
          </Col>
          <Col>
            <label>City</label>
            <br />
            <input className="standard-input" name="city" type="text" value={this.state.streetAddress.city} onChange={this.changeAddressString} placeholder="Enterprise" />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>State</label>
            <br />
            <Select
              value={this.state.streetAddress.state}
              onChange={this.changeState}
              styles={reactSelectBasicStyle}
              options={this.state.states}
              defaultValue={{ label: "Oregon", value: "36" }}
            />
          </Col>
          <Col>
            <label>ZipCode</label>
            <br />
            <input name="zipcode" type="text" className="standard-input" value={this.state.streetAddress.zipcode} onChange={this.changeAddressString} placeholder="97828" />
          </Col>
        </Row>
      </>
    )
  }
}

interface InlineAddressComponentProps {
  address: string;
  city: string;
  state: SelectOptions;
  zipcode: string;
  submit: any;
  saveMargin: any;
  useSave: any;
}

interface InlineAddressComponentState {
  states: Array<any>;
  streetAddress: StreetAddress;
  submit: any;
  saveMargin: any;
  useSave: any;
}

export class InlineAddressComponent extends React.Component<InlineAddressComponentProps, InlineAddressComponentState> {

  constructor(props: InlineAddressComponentProps) {
    super(props);
    this.state = {
      states: [],
      streetAddress: {
        address: props.address === undefined ? "" : props.address,
        city: props.city === undefined ? "" : props.city,
        state: props.state === undefined ? { label: 'State', value: '-1' } : props.state,
        zipcode: props.zipcode === undefined ? "" : props.zipcode
      },
      submit: props.submit,
      saveMargin: props.saveMargin === undefined ? "0px" : props.saveMargin,
      useSave: (props.useSave === undefined ? true : props.useSave)
    }
    this.changeAddressString = this.changeAddressString.bind(this);
    this.changeState = this.changeState.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const states = await pullStates();
    this.setState({
      states: states
    })
  }

  changeAddressString(event: React.ChangeEvent<HTMLInputElement>): void {
    const streetAddress = this.state.streetAddress;
    //@ts-ignore
    streetAddress[event.target.name] = event.target.value;
    //@ts-ignore
    this.setState({
      streetAddress: streetAddress
    })
  }

  changeState(event: SelectOptions | null): void {
    if (event === null) {
      return;
    }
    const streetAddress = this.state.streetAddress;
    streetAddress.state = event;
    this.setState({
      streetAddress: streetAddress
    })
  }

  submit(): void {
    this.state.submit(this.state.streetAddress);
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <label>Address</label>
            <input className="standard-input" name="address" type="text" value={this.state.streetAddress.address} onChange={this.changeAddressString}
              placeholder="Address"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>City</label>
            <input className="standard-input" name="city" type="text" value={this.state.streetAddress.city} onChange={this.changeAddressString}
              placeholder="City"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>State</label>
            <br />
            <Select
              value={this.state.streetAddress.state}
              onChange={this.changeState}
              styles={reactSelectBasicStyle}
              options={this.state.states}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>ZipCode</label>
            <input name="zipcode" type="text" className="standard-input" value={this.state.streetAddress.zipcode} onChange={this.changeAddressString}
              placeholder="ZipCode"
            />
          </Col>
        </Row>
        {this.state.useSave && <Row>
          <Col>
            <input type="button" className="standard-input" onClick={this.submit} value="Save" style={{ width: "100%", marginTop: this.state.saveMargin }} />
          </Col>
        </Row>
        }
      </>
    )
  }
}