import * as React from 'react';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import ARReportModal from './ARReportWidget.modals';


export default class ARReportWidget extends React.Component<{}, {}> {

  reportModal: React.RefObject<ARReportModal> = React.createRef<ARReportModal>();

  constructor(props: {}) {
    super(props);
    this.state = {

    }
  }

  async componentDidMount(): Promise<void> {

  }

  render(): JSX.Element {
    return (
      <div>
        <ARReportModal ref={this.reportModal} />
        {RenderHeader("AR Reports")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh", overflowY: "auto", overflowX: "hidden"}}>
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Property</th>
                <th className="fixedTableHeader">Month Submitted</th>
                <th className="fixedTableHeader">Status</th>
                <th className="fixedTableHeader"></th>
                <th className="fixedTableHeader"></th>
              </tr>
            </thead>
            <tbody>
              
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create AR" onClick={() => this.reportModal.current.show()} className="standard-input" />
      </div>
    )
  }
}