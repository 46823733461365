import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import axios from 'axios';
import { numberWithCommas } from '../../../functions/numberFunctions';

interface ProjectTableManagerProjectData {
  name: string;
  open: number;
  inProgress: number;
  waiting: number;
  closed: number;
  reopened: number;
  percentage: number;
}

export default class ProjectTableManager extends React.Component<{}, { headers: Array<FilterAndSettingField>, data: Array<ProjectTableManagerProjectData> }>{

  constructor(props: {}) {
    super(props);
    this.state = {
      headers: [],
      data: []
    }
    this.generateHeaders = this.generateHeaders.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    let data = await axios.get('./api/primary-ticket-category/projects');
    this.setState({ data: data.data });
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Project',
        setting: true,
        filter: {}
      },
      {
        columnName: 'Open',
        additionalClasses: "fifteen",
        setting: true,
        filter: {}
      },
      {
        columnName: "In-Progress",
        additionalClasses: "fifteen",
        setting: true,
        filter: {}
      },
      {
        columnName: "Waiting",
        additionalClasses: "fifteen",
        setting: true,
        filter: {}
      },
      {
        columnName: 'Reopened',
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: 'Closed',
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: 'Complete',
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      }
    ]
    this.setState({
      headers: headers
    })
  }

  render(): JSX.Element {
    return (
      <div style={{ marginTop: "1vh", paddingBottom: "5vh" }}>
        <div id="ticket-management-table" style={{ overflow: "auto", minHeight: "80vh", maxHeight: "80vh" }}>
          <table key={"projectTable"} className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.name}>
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.open, 0)}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.inProgress, 0)}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.waiting, 0)}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.reopened, 0)}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.closed, 0)}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.percentage * 100, 2)}%</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}