import axios from "axios";
import * as React from "react";
import { getBearerToken, getUserJobFromServer, Jobs, Teams, ValidateAccess } from "../../functions/authActions";
import { PropertyDetailPageProps, PropertyDetailPageState } from "./PropertyDetailPage.constants";
import PropertyInformationWidget from "./PropertyWidgets/PropertyInformationWidget";
import PropertyStaffInformationWidget from "./PropertyWidgets/PropertyStaffInformationWidget";
import SinglePropertyMapWidget from "./PropertyWidgets/SinglePropertyMapWidget";
import PropertyBuildingWidget from "./PropertyWidgets/PropertyBuildingWidget";
import { SelectOptions } from "../../interfaces/CoreInterfaces";
import { DashboardOrganizer, DefaultPageSettings } from "../../functions/WidgetDashboardOrganizer";
import { DashboardManagementModal, WidgetSelectOptions } from "../CoreComponents/Modals/DashboardManagementModal";
import { GetSettings } from "../../functions/StateManagement/StateStorageFunctions";
import { PropertyPayrollWidget } from "./PropertyWidgets/PropertyPayrollWidget";
import Select from "react-select";
import { reactSelectBasicStyle } from "../../style/select-constants";
import { pullProperties, pullPropertiesForRegionals } from "../../functions/fetchLinkedObjects";
import { PropertyAllocationsWidget } from "./PropertyWidgets/PropertyAllocationsWidget";
import SiteVisitTrackingWidget from "./PropertyWidgets/SiteVisitTrackingWidget";
import { RemoveStickyOverlays, RestoreStickyOverlays } from "../../functions/selectTools";
import PropertyVendorContactWidget from "./PropertyWidgets/PropertyVendorContactWidget";
import PropertyUnitWidget from "./PropertyWidgets/PropertyUnitWidget";
import UnitStatusHistory from "./PropertyWidgets/UnitStatusHistory";
import PropertySoftwareLicenseWidget from "./PropertyWidgets/PropertySoftwareLicenseWidget";
import { PieGraphWidget } from "../CoreComponents/Widgets/PieGraphWidget";
import { RenderSettings } from "../../interfaces/WidgetSettingsInterfaces";
import PropertyPayrollBudgetWidget from "./PropertyWidgets/PropertyPayrollBudgetWidget";
import BankManagementWidget from "../DashboardComponents/Widgets/BankManagementWidget";
import DocumentListWidget from "../CoreComponents/Widgets/DocumentListWidget";
import CNAActiveTableWidget from "./PropertyWidgets/CNAActiveTableWidget";
import TransitionPlanManagementWidget from "./PropertyWidgets/TransitionPlanManagementWidget";
import TransitionPlanCategoryWidget from "./PropertyWidgets/TransitionPlanCategoryWidget";

interface DocumentData {
  uid: string;
  name: string;
  size: string;
}

export default class PropertyDetailPage extends React.Component<PropertyDetailPageProps, PropertyDetailPageState> {

  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  documentRef: React.RefObject<DocumentListWidget<DocumentData>> = React.createRef<DocumentListWidget<DocumentData>>();
  settingsKey: string = "propertyDashboard";

  constructor(props: PropertyDetailPageProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsKey);
    let showModal = false;
    if (settings == null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      uid: params.get('uid'),
      property: undefined,
      properties: [],
      tabList: {},
      renderSettings: settings,
      showModal: showModal,
      widgetList: [],
      list: []
    }
    this.updateUI = this.updateUI.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
    this.pullDocumentsForProperty = this.pullDocumentsForProperty.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await this.updateProperty(this.state.uid);
    let properties = ((await getUserJobFromServer()) === Jobs.Regional) ? await pullPropertiesForRegionals() : await pullProperties();
    this.setState({ properties: properties });
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show(this.settingsKey, this.state.widgetList, this.updateUI);
    }
    //this.documentRef.current.build(this.pullDocumentsForProperty);
  }

  componentDidUpdate(): void {
    this.documentRef.current?.build(this.pullDocumentsForProperty);
  }

  async pullDocumentsForProperty(): Promise<Array<any>> {
    let response = await axios.get('./api/property-documents/' + this.state.uid);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw Error("Invalid Request Response")
    }
  }
  //#endregion

  async updateProperty(uid: string): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      0: <PropertyInformationWidget uid={uid} callback={this.updateProperty} />,
      1: <PropertyStaffInformationWidget uid={uid} />,
      2: <SinglePropertyMapWidget uid={uid} />,
      3: <PropertyBuildingWidget uid={uid} />,
      4: <PropertyUnitWidget uid={uid} />,
      8: <UnitStatusHistory propertyUID={uid} />,
      9: <PropertyVendorContactWidget uid={uid} />,
      10: <PropertySoftwareLicenseWidget uid={uid} />,
      11: <PieGraphWidget values={this.state.list} title={"Software Costs - Graph"} />,
      14: <DocumentListWidget ref={this.documentRef} title={"Property Documents"} uploadURL={`api/property-documents/` + uid} />,
      15: <CNAActiveTableWidget propertyUID={uid} />,
      17: <TransitionPlanManagementWidget propertyUID={uid} />,
      18: <TransitionPlanCategoryWidget />
    };
    let widgetList: Array<WidgetSelectOptions> = [
      { label: "Property Information", value: "0", group: "Information" },
      { label: "Staff Information", value: "1", group: "Information" },
      { label: "Property Map", value: "2", group: "Information" },
      { label: "Building Information", value: "3", group: "Units" },
      { label: "Unit Information", value: "4", group: "Units" },
      { label: "Unit Status History", value: "8", group: "Units" },
      { label: "Property Vendor Contact", value: "9", group: "Vendors" },
      { label: "Software Costs - Table", value: "10", group: "Tech" },
      { label: "Software Costs - Graph", value: "11", group: "Tech" },
      { label: "Property Documents", value: "14", group: "Documents" },
      { label: "CNA's", value: "15", group: "Asset Management" },
      { label: "Transition Plans", value: "17", group: "Asset Management" },
      { label: "Transition Categories", value: "18", group: "Asset Management"}
    ]
    if (ValidateAccess([Jobs.Regional, Jobs.CFO, Jobs.HR], [Teams.Property_Management, Teams.Executive, Teams.Human_Resources], true)) {
      tabList[5] = <PropertyPayrollWidget uid={uid} />
      widgetList.push({ label: "Property Payroll", value: "5", group: "Payroll" })

      tabList[6] = <PropertyAllocationsWidget uid={uid} />
      widgetList.push({ label: "Property Allocations", value: "6", group: "Payroll" })

      tabList[12] = <PropertyPayrollBudgetWidget uid={uid} />
      widgetList.push({ label: "Property Payroll Budget", value: "12", group: "Payroll" })
    }
    if (ValidateAccess([Jobs.Technology, Jobs.Technology_Lead], [Teams.Services])) {
      tabList[7] = <SiteVisitTrackingWidget propertyUID={uid} />
      widgetList.push({ label: "Site Visits", value: "7", group: "Tech" })
    }
    if (ValidateAccess([Jobs.Technology, Jobs.Technology_Lead, Jobs.CFO], [Teams.Services, Teams.Executive])) {
      tabList[13] = <BankManagementWidget />
      widgetList.push({ label: "Bank Management", value: "13", group: "Accounting" })
    }
    this.setState({
      property: undefined,
    })
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const getPropertyResponse = (await axios.get('./api/property/' + uid)).data;
    this.setState({
      property: getPropertyResponse,
      tabList: tabList,
      widgetList: widgetList
    })
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings });
  }

  changeProperty(event: SelectOptions | null): void {
    window.location.assign("/property-detail-page?uid=" + event.value);
  }

  render(): JSX.Element {
    return (
      <div style={{ overflowX: "hidden", overflowY: "scroll", height: "90vh" }}>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "10vw", paddingTop: "2vh" }}>
            <Select
              options={this.state.properties}
              styles={reactSelectBasicStyle}
              value={{ label: this.state.property?.name, value: this.state.property?.uid }}
              onChange={this.changeProperty}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "62vw" }}>
            <h5 style={{ textAlign: "center" }}>- {this.state.property?.code.toUpperCase()} -</h5>
            <h3 style={{ textAlign: "center" }}>{this.state.property?.name}</h3>
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", marginLeft: "2%", paddingTop: "2vh" }}>
            <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsKey, this.state.widgetList, this.updateUI)} />
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div>
          {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
        </div>
      </div>
    )
  }
}

