import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldFile, DataEntryModalFieldHidden, DataEntryModalFieldSelect, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { pullProperties } from '../../../functions/fetchLinkedObjects';

interface ReasonableAccomodationCreationState {
  property: SelectOptions;
  properties: Array<SelectOptions>;
  unitNumber: string;
  tenantName: string;
  description: string;
  requestAndVerification: FileList;
  estimatedCost: number;
  documents: FileList;
  additional: FileList;
}

export default class ReasonableAccomodationCreationModal extends React.Component<{}, ReasonableAccomodationCreationState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      property: { label: "Select Property", value: "" },
      properties: [],
      unitNumber: "",
      tenantName: "",
      description: "",
      requestAndVerification: null,
      estimatedCost: 0.0,
      documents: null,
      additional: null
    }
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();

    this.setState({ properties: properties });
  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "property", name: "Property", patchUrl: undefined, value: this.state.property, options: this.state.properties, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "unitNumber", name: "Unit Number", patchUrl: undefined, value: this.state.unitNumber, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "tenantName", name: "Tenant Name", patchUrl: undefined, value: this.state.tenantName, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "description", name: "Description", patchUrl: undefined, value: this.state.description, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "verification", name: "RA Request / Verification", patchUrl: undefined, value: this.state.requestAndVerification, fileKey: Math.random().toString(), single: false, postUrl: './api/reasonable-accomodation-files' } as DataEntryModalFieldFile);
    data.push({ key: "estimatedCost", name: "Est. Cost", patchUrl: undefined, value: this.state.estimatedCost, type: "number" } as DataEntryModalFieldText);
    data.push({ key: "bids", name: "Docs / Bids", patchUrl: undefined, value: this.state.documents, fileKey: Math.random().toString(), single: false, postUrl: './api/reasonable-accomodation-files' } as DataEntryModalFieldFile);
    data.push({ key: "attachments", name: "Attachments", patchUrl: undefined, value: this.state.additional, fileKey: Math.random().toString(), single: false, postUrl: './api/reasonable-accomodation-files' } as DataEntryModalFieldFile);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Start Reasonable Accomodation (RA)", "", "./api/reasonable-accomodations");
  }

  async show(): Promise<void> {
    this.setState({
      property: { label: "Select Property", value: "" },
      unitNumber: "",
      tenantName: "",
      description: "",
      requestAndVerification: null,
      estimatedCost: 0.0,
      documents: null,
      additional: null
    })
    await this.generateData();
  }

  render(): JSX.Element {
    return (
      <DataEntryModal ref={this.entryModal} callback={null} />
    )
  }
}