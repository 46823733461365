import * as React from 'react';
import { PayAppReportingProps } from '../Structs';
import PayApplicationDateDashboard from './PayApplicationDateDashboard';
import PayApplicationGraphicsDashboard from './PayApplicationGraphicsDashboard';
import { Col, Row } from 'reactstrap';

export default class PayApplicationReportingDashboard extends React.Component<PayAppReportingProps, { currentTab: number }> {

  constructor(props: PayAppReportingProps) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  getTab(): Object {
    switch (this.state.currentTab) {
      case 0:
        return <PayApplicationDateDashboard key={Math.random()} />
      case 1:
        return <PayApplicationGraphicsDashboard key={Math.random()} />

    }
  }

  render(): JSX.Element {
    return (
      <>
        <div style={{ height: "85vh", overflow: "scroll" }}>
          <>
            {
              this.getTab()
            }
          </>
        </div>
        <Row>
          <Col>
            <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "50%" }} value="Dates" onClick={() => this.setState({ currentTab: 0 })} />
            <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "50%" }} value="Graphics" onClick={() => this.setState({ currentTab: 1 })} />
          </Col>
        </Row>
      </>
    )
  }
}
