import * as React from 'react'
import { CNAChangeLogWidgetProps, CNAChangeLogWidgetState, CNALoggedChanges } from './CNAChangeLogWidget.constants';
import { StatusModal, WarningModal } from '../../../CoreComponents/Modals';
import axios from 'axios';
import { getBearerToken } from '../../../../functions/authActions';
import { FilterAndSettingField } from '../../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../../CoreComponents/CoreTableHeaders';
import { RenderHeader } from '../../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface CNAChangeLogServerData {
  uid: string;
  employee: string;
  changeTime: Date;
  description: string;
}

export default class CNAChangeLogWidget extends React.Component<CNAChangeLogWidgetProps, CNAChangeLogWidgetState> {

  statusRef: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningRef: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: CNAChangeLogWidgetProps) {
    super(props);
    this.state = {
      cnaUID: props.cnaUID,
      headers: [],
      logs: []
    }

  }

  async componentDidMount(): Promise<void> {
    const logs: Array<CNALoggedChanges> = []
    this.getHeaders()

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const response = await axios.get('./api/cna-change-log/' + this.state.cnaUID)
    if (response.status === 200) {
      response.data.forEach((log: CNAChangeLogServerData) => {
        logs.push({
          uid: log.uid,
          time: log.changeTime,
          employee: log.employee,
          new: log.description
        })
      })
      this.setState({ logs: logs })
    }
    else {
      //TODO ERROR HANDLE
    }
  }

  getHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Time Edit Made',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Editor',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Description',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      }
    ]
    this.setState({ headers: headers })
  }

  render(): JSX.Element {
    return (
      <>
        {RenderHeader("Change Log")}
        <div>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.logs.map((log) => (
                  <tr key={log.uid}>
                    <td className="fixedTableCellFirst">{new Date(log.time).toLocaleTimeString()}</td>
                    <td className="fixedTableCell">{log.employee}</td>
                    <td className="fixedTableCell">{log.new}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div >
      </>
    )
  }

}