import * as React from 'react';
import { TicketTableRowProps, TicketTableRowState } from './TicketTableManager.rows.constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export default class TicketTableRow extends React.Component<TicketTableRowProps, TicketTableRowState>{

  constructor(props: TicketTableRowProps) {
    super(props);
    this.state = {
      ticket: props.ticket,
    }

    this.changeTicketWorkStatus = this.changeTicketWorkStatus.bind(this);
    this.loadWorkLog = this.loadWorkLog.bind(this);
  }

  changeTicketWorkStatus(event: SelectOptions | null): void {
    if (event === null) { return; }

    const ticket = this.state.ticket
    ticket.ticketWorkStatus = event

    this.setState({ ticket: ticket })
  }

  loadWorkLog(): void {
    window.open("./ticket-work-manager?uid=" + this.state.ticket.uid);
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.state.ticket.ticketWorkNumber}</td>
        <td className="fixedTableCell">{this.state.ticket.email}</td>
        <td className="fixedTableCell">{this.state.ticket.department?.label}</td>
        <td className="fixedTableCell">{this.state.ticket.assignee}</td>
        <td className="fixedTableCell">{this.state.ticket.created.toLocaleString('en-US')}</td>
        <td className="fixedTableCell">{this.state.ticket.ticketWorkStatus?.label ?? "Archived Label"}</td>
        <td className="fixedTableCell">{this.state.ticket.category.label}</td>
        <td className="fixedTableCell" style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{this.state.ticket.problemSummary}</td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faCog} style={{ marginLeft: "15%" }} onClick={this.loadWorkLog} />
        </td>
      </tr>
    )
  }
}