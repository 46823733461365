import * as React from "react";
import axios from 'axios';


function dateFromDay(year: number, day: number) {
  var date = new Date(year, 0); // initialize a date in `year-01-01`
  return new Date(date.setDate(day)).toLocaleDateString(); // add the number of days
}

class ProductionGraphViewer extends React.Component<any, any> {
  //constructor(props) {
  //    super(props);
  //    this.state = {
  //        propertyId: props.propertyId,
  //        monthHourly: -1,
  //        hourlyProduction: [],
  //        crosshairHourlyValues: [],
  //        monthlyProduction: [],
  //        forecastedMonthlyProduction: [],
  //        crosshairMonthlyValues: [],
  //        totalProduction: [],
  //        forecastTotalProduction: [],
  //        crosshairTotalValues: [],
  //        ytdProduction: [],
  //        forecastDailySavings: [],
  //        crosshairYtdValues: [],
  //        reset: props.reset
  //    }
  //}

  //_onMouseLeave(name) {
  //    this.setState({ [name]: [] })
  //}

  //_onNearestXMonthly = (value, { index }) => {
  //    try {
  //        var data = this.state.monthlyProduction[index];
  //        var forecast = this.state.forecastedMonthlyProduction[index];
  //        this.setState({ crosshairMonthlyValues: [{ x: data.x, y: data.y }, { x: forecast.x, y: forecast.y }] });
  //    }
  //    catch {
  //        // do nothing
  //    }
  //};

  //_onNearestXHourly = (value, { index }) => {
  //    try {
  //        var data = this.state.hourlyProduction[index];
  //        this.setState({ crosshairHourlyValues: [{ x: data.x, y: data.y }] });
  //    }
  //    catch {
  //        // do nothing
  //    }
  //};

  //_onNearestXTotal = (value, { index }) => {
  //    try {
  //        var data = this.state.totalProduction[index];
  //        var forecast = this.state.forecastTotalProduction[index];
  //        this.setState({ crosshairTotalValues: [{ x: data.x, y: data.y }, { x: forecast.x, y: forecast.y }] });
  //    }
  //    catch {
  //        // do nothing
  //    }
  //};

  //_onNearestXYtd = (value, { index }) => {
  //    try {
  //        var data = this.state.ytdProduction[index];
  //        var forecast = this.state.forecastDailySavings[index];
  //        this.setState({ crosshairYtdValues: [{ x: data.x, y: data.y }, { x: forecast.x, y: forecast.y }] });
  //    }
  //    catch {
  //        // do nothing
  //    }
  //};

  //toolTipMonthlyFormat(arr) {
  //    return [
  //        {
  //            title: "Average Daily Production",
  //            value: FormatNumber(arr[0].y.toFixed(0)) + " kWh"
  //        },
  //        {
  //            title: "Forecasted Daily Production",
  //            value: FormatNumber(arr[1].y.toFixed(0)) + " kWh"
  //        }
  //    ]
  //}

  //toolTitleMonthlyFormat(arr) {
  //    return {
  //        title: "Month",
  //        value: arr[0].x
  //    }
  //}

  //toolTipHourlyFormat(arr) {
  //    return [
  //        {
  //            title: "Average Hourly Production",
  //            value: FormatNumber(arr[0].y.toFixed(2)) + " kWh"
  //        },
  //    ]
  //}

  //toolTitleHourlyFormat(arr) {
  //    var val = arr[0].x;
  //    if (val % 12 == 0) {
  //        val = val == 0 ? "12:00 AM" : "12:00 PM"
  //    }
  //    else {
  //        val = val < 12 ? `${val}:00 AM` : `${val % 12}:00 PM`
  //    }
  //    return { title: "Hour: ", value: val }
  //}

  //toolTipYtdFormat(arr) {
  //    return [{ title: "Actual Daily Production Savings", value: "$" + FormatNumber(arr[0].y.toFixed(2)) }, { title: "Forecasted Daily Production Savings", value: "$" + FormatNumber(arr[1].y.toFixed(2)) }]
  //}

  //toolTitleYtdFormat(arr) {
  //    return {
  //        title: "Month",
  //        value: arr[0].x
  //    }
  //}

  //toolTipTotalFormat(arr) {
  //    return [
  //        { title: "Total Production", value: FormatNumber(arr[0].y.toFixed(0)) + " kWh" },
  //        { title: "Forecasted Production", value: FormatNumber(arr[1].y.toFixed(0)) + " kWh" }
  //    ]
  //}

  //toolTitleTotalFormat(arr) {
  //    return { title: "Days in Service", value: FormatNumber(arr[0].x) + " Days" }
  //}

  //componentDidMount() {
  //    var self = this;
  //    axios.get('/api/solar-production-data/monthly/' + this.state.propertyId)
  //        .then(function (response) {
  //            var items = [];
  //            var forecast = [];
  //            response.data.forEach(function (val) {
  //                var month = StaticAssets.Months[val.Index - 1];
  //                items.push({
  //                    x: month,
  //                    y: val.Average
  //                })
  //                forecast.push({
  //                    x: month,
  //                    y: val.Forecast
  //                })
  //            });
  //            self.setState({
  //                monthlyProduction: items,
  //                forecastedMonthlyProduction: forecast
  //            })
  //        })

  //    axios.get('/api/solar-production-data/hourly/' + this.state.propertyId)
  //        .then(function (response) {
  //            var items = [];
  //            response.data.forEach(function (val) {
  //                var hourString = val.Index;
  //                items.push({
  //                    x: hourString,
  //                    y: val.Average
  //                })
  //            });
  //            self.setState({
  //                hourlyProduction: items,
  //            })
  //        })

  //    axios.get('/api/solar-production-data/total/' + this.state.propertyId)
  //        .then(function (response) {
  //            var items = [];
  //            var forecast = [];
  //            response.data.forEach(function (val) {
  //                items.push({
  //                    x: val.Index,
  //                    y: val.Average
  //                })
  //                forecast.push({
  //                    x: val.Index,
  //                    y: val.Forecast
  //                })
  //            });
  //            self.setState({
  //                totalProduction: items,
  //                forecastTotalProduction: forecast
  //            })
  //        })
  //        .catch(function (response) {
  //            CreateGlobalAlert("No Solar Data is Available, Returning to Table", 1500);
  //            self.state.reset();
  //        });

  //    axios.get('/api/solar-production-data/cost/' + this.state.propertyId)
  //        .then(function (response) {
  //            var items = [];
  //            var forecast = [];
  //            response.data.forEach(function (val) {
  //                var x = StaticAssets.ShortMonths[val.Index- 1];
  //                items.push({
  //                    x: x,
  //                    y: val.Average
  //                });
  //                forecast.push({
  //                    x: x,
  //                    y: val.Forecast
  //                })
  //            });
  //            self.setState({
  //                ytdProduction: items,
  //                forecastDailySavings: forecast
  //            })
  //        });
  //}

  render() {
    return (
      <div id="solar-viewer">
        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        <h4>Hourly Production (Average)</h4>*/}
        {/*        <DiscreteColorLegend style={{*/}
        {/*            margin: '0px', padding: "0px", marginTop: "-5px"*/}
        {/*        }}*/}
        {/*            orientation="horizontal"*/}
        {/*            items={[*/}
        {/*            {*/}
        {/*                title: 'Actual',*/}
        {/*                color: '#d5e9f6',*/}
        {/*                strokeWidth: "14px"*/}
        {/*            }]} />*/}
        {/*        <XYPlot height={300} width={675} margin={{ left: 75 }} xType="ordinal" style={{}} onMouseLeave={() => this._onMouseLeave("crosshairHourlyValues")}>*/}
        {/*            <YAxis title="Production" position="middle" tickSize={8}*/}
        {/*                style={{*/}
        {/*                    text: { fill: "#d5e9f6" },*/}
        {/*                    line: { stroke: "#d5e9f6" },*/}
        {/*                    title: { fill: "#82bce3" }*/}
        {/*                }}*/}
        {/*            />*/}
        {/*            <Crosshair values={this.state.crosshairHourlyValues}*/}
        {/*                style={{*/}
        {/*                    background: "#4C4A42",*/}
        {/*                    line: { width: "0px" }*/}
        {/*                }}*/}
        {/*                itemsFormat={this.toolTipHourlyFormat}*/}
        {/*                titleFormat={this.toolTitleHourlyFormat}*/}
        {/*            />*/}
        {/*            <VerticalBarSeries data={this.state.hourlyProduction} color="#d5e9f6" onNearestX={this._onNearestXHourly} />*/}
        {/*        </XYPlot>*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*        <h4>Daily Production By Month (Average)</h4>*/}
        {/*        <DiscreteColorLegend style={{*/}
        {/*            margin: '0px', padding: "0px", marginTop: "-5px"}}*/}
        {/*            orientation="horizontal"*/}
        {/*            items={[{*/}
        {/*                title: 'Forecasted',*/}
        {/*                color: '#82bce3',*/}
        {/*                strokeWidth: "14px"*/}
        {/*            },*/}
        {/*            {*/}
        {/*                title: 'Actual',*/}
        {/*                color: '#d5e9f6',*/}
        {/*                strokeWidth: "14px"*/}
        {/*            }]} />*/}
        {/*        <XYPlot height={300} width={675} margin={{ left: 75 }} xType="ordinal" style={{}} onMouseLeave={() => this._onMouseLeave("crosshairMonthlyValues")}>*/}
        {/*            <YAxis title="Production" position="middle" tickSize={8}*/}
        {/*                style={{*/}
        {/*                    text: { fill: "#82bce3" },*/}
        {/*                    line: { stroke: "#d5e9f6" },*/}
        {/*                    title: { fill: "#82bce3" }*/}
        {/*                }}*/}
        {/*            />*/}
        {/*            <XAxis position="middle" style={{*/}
        {/*                text: { fill: "#82bce3" },*/}
        {/*                line: { stroke: "none", fill: "none" }*/}
        {/*            }} />*/}
        {/*            <Crosshair values={this.state.crosshairMonthlyValues}*/}
        {/*                style={{*/}
        {/*                    background: "#4C4A42",*/}
        {/*                    line: { width: "0px" }*/}
        {/*                }}*/}
        {/*                itemsFormat={this.toolTipMonthlyFormat}*/}
        {/*                titleFormat={this.toolTitleMonthlyFormat}*/}
        {/*            />*/}
        {/*            <VerticalBarSeries data={this.state.monthlyProduction} color="#d5e9f6" onNearestX={this._onNearestXMonthly} />*/}
        {/*            <VerticalBarSeries data={this.state.forecastedMonthlyProduction} color="#82bce3" onNearestX={this._onNearestXMonthly} />*/}
        {/*        </XYPlot>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        {/*<Row>*/}
        {/*    <Col style={{}}>*/}
        {/*        <h4>Daily Production Savings (Average)</h4>*/}
        {/*        <DiscreteColorLegend style={{*/}
        {/*            margin: '0px', padding: "0px", marginTop: "-5px"*/}
        {/*        }}*/}
        {/*            orientation="horizontal"*/}
        {/*            items={[{*/}
        {/*                title: 'Forecasted',*/}
        {/*                color: '#82bce3',*/}
        {/*                strokeWidth: "14px"*/}
        {/*            },*/}
        {/*            {*/}
        {/*                title: 'Actual',*/}
        {/*                color: '#d5e9f6',*/}
        {/*                strokeWidth: "14px"*/}
        {/*            }]} />*/}
        {/*        <XYPlot height={300} width={675} margin={{ left: 75 }} style={{}} xType="ordinal" onMouseLeave={() => this._onMouseLeave("crosshairYtdValues")}>*/}
        {/*            <YAxis title="Production" position="middle" tickSize={8}*/}
        {/*                style={{*/}
        {/*                    text: { fill: "#82bce3" },*/}
        {/*                    line: { stroke: "#d5e9f6" },*/}
        {/*                    title: { fill: "#82bce3" }*/}
        {/*                }}*/}
        {/*            />*/}
        {/*            <XAxis title={this.state.ytdTitle} position="middle" style={{*/}
        {/*                text: { fill: "#82bce3" },*/}
        {/*                line: { stroke: "none", fill: "none" }*/}
        {/*            }} />*/}
        {/*            <Crosshair values={this.state.crosshairYtdValues}*/}
        {/*                style={{*/}
        {/*                    background: "#4C4A42",*/}
        {/*                    line: { width: "0px" }*/}
        {/*                }}*/}
        {/*                itemsFormat={this.toolTipYtdFormat}*/}
        {/*                titleFormat={this.toolTitleYtdFormat}*/}
        {/*            />*/}
        {/*            <VerticalBarSeries data={this.state.ytdProduction} color="#d5e9f6" onNearestX={this._onNearestXYtd} />*/}
        {/*            <VerticalBarSeries data={this.state.forecastDailySavings} color="#82bce3" onNearestX={this._onNearestXYtd} />*/}
        {/*        </XYPlot>*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*        <h4>Lifetime Production</h4>*/}
        {/*        <DiscreteColorLegend style={{*/}
        {/*            margin: '0px', padding: "0px", marginTop: "-5px"*/}
        {/*        }}*/}
        {/*            orientation="horizontal"*/}
        {/*            items={[{*/}
        {/*                title: 'Forecasted',*/}
        {/*                color: '#82bce3',*/}
        {/*                strokeWidth: "14px"*/}
        {/*            },*/}
        {/*            {*/}
        {/*                title: 'Actual',*/}
        {/*                color: '#d5e9f6',*/}
        {/*                strokeWidth: "14px"*/}
        {/*            }]} />*/}
        {/*        <XYPlot height={300} width={675} margin={{ left: 75 }} xType="ordinal" style={{}} onMouseLeave={() => this._onMouseLeave("crosshairTotalValues")} >*/}
        {/*            <YAxis title="Total Production" position="middle" tickSize={8}*/}
        {/*                style={{*/}
        {/*                    text: { fill: "#82bce3" },*/}
        {/*                    line: { stroke: "#82bce3" },*/}
        {/*                    title: { fill: "#82bce3"}*/}
        {/*                }}*/}
        {/*            />*/}
        {/*            <Crosshair values={this.state.crosshairTotalValues}*/}
        {/*                style={{*/}
        {/*                    background: "#4C4A42",*/}
        {/*                    line: { width: "0px" }*/}
        {/*                }}*/}
        {/*                itemsFormat={this.toolTipTotalFormat}*/}
        {/*                titleFormat={this.toolTitleTotalFormat}*/}
        {/*            />*/}
        {/*            <AreaSeries data={this.state.forecastTotalProduction} color="#82bce3" onNearestX={this._onNearestXTotal} />*/}
        {/*            <AreaSeries data={this.state.totalProduction} color="#d5e9f6" onNearestX={this._onNearestXTotal} />*/}
        {/*        </XYPlot>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        {/*<Row>*/}
        {/*    <input type="button" value="Change Property" onClick={this.state.reset} style={{ width: "95%" }} />*/}
        {/*</Row>*/}
      </div>
    )
  }
}

const TableRow = (props: any) => {
  return (
    <tr>
      <td>{props.name}</td>
      <td>{props.size}</td>
      <td><input type="button" value="Select" style={{ width: "100%" }} onClick={() => props.onClick(props.uid)} /></td>
    </tr>
  )
}

export class SolarProductionViewer extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      propertyId: '00000000-0000-0000-0000-000000000000',
      propertyList: []
    }
    this.OnClick = this.OnClick.bind(this);
    this.Reset = this.Reset.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    await axios.get('./api/solar-projects/active')
      .then(function (response) {
        var output: any = [];
        response.data.forEach(function (item: any) {
          output.push({
            name: item?.projectMapper.property.name,
            size: item.systemWattSize,
            uid: item.projectMapper.propertyUID
          })
        })
        self.setState({
          propertyList: output
        })
      })
  }

  OnClick(propertyId: string): void {
    this.setState({
      propertyId: propertyId
    })
  }

  Reset(): void {
    this.setState({
      propertyId: '00000000-0000-0000-0000-000000000000'
    })
  }

  render(): JSX.Element {
    return (
      <div id="production-viewer">
        {this.state.propertyId === '00000000-0000-0000-0000-000000000000' &&
          <div id="table-data">
            <table>
              <thead>
                <tr>
                  <th className="fixedTableHeaderFirst">Property Name</th>
                  <th className="fixedTableHeader">Project Size</th>
                  <th className="icon fixedTableHeader"></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.propertyList.map((item: any) => (
                    <TableRow key={item.uid} uid={item.uid} name={item.name} size={item.size} onClick={this.OnClick} />
                  ))
                }
              </tbody>
            </table>
          </div>
        }
        {this.state.propertyId !== '00000000-0000-0000-0000-000000000000' &&
          <div>
            <ProductionGraphViewer propertyId={this.state.propertyId} reset={this.Reset} />
          </div>
        }
      </div>
    )
  }
}