import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { getUserID } from '../../../../functions/authActions';
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { pullFleetEmployee } from '../../../../functions/fetchLinkedObjects';
import FilteredWithSettingsTableHeader from '../../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../../CoreComponents/interfaces';
import { StatusModal } from '../../../CoreComponents/Modals';
import { EntityTrackingTypes } from '../Creation/EntityCreationTrackingInfo';
import EntityRequestEditModal from './EntityRequestEditModal';
import EntityRequestReviewFormation from './EntityRequestReviewFormation';
import EntityRequestReviewHeader from './EntityRequestReviewHeader';
import EntityRequestReviewMemberBanking from './EntityRequestReviewMemberBanking';
import EntityRequestSubmitModal from './EntityRequestSubmitModal';

interface NewEntityRequestSubmittedDraftProps {

}

interface NewEntityRequestSubmittedDraftState {
  uid: string;
  trackingHeaders: Array<FilterAndSettingField>;
  trackingInformation: Array<any>;
  stateEntityName: string;
  actName: string;
  interestGroup: string;
  isQualifiedIncome: boolean;
  isRepresented: boolean;
  representor: string;
  isRD: boolean;
}

export interface NewEntityDraftPatchData {
  uid: string;
  stateEntityName: string;
  actName: string;
  interestGroup: string;
  isQualifiedIncome: boolean;
  isRepresented: boolean;
  representor: string;
  isRD: boolean;
}

export default class NewEntityRequestSubmittedDraft extends React.Component<NewEntityRequestSubmittedDraftProps, NewEntityRequestSubmittedDraftState>{

  modalRef = React.createRef<EntityRequestEditModal>();
  submitRef = React.createRef<EntityRequestSubmitModal>();
  loadingRef = React.createRef<StatusModal>();

  constructor(props: NewEntityRequestSubmittedDraftProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const draftUID = params.has("uid") ? params.get("uid") : "";

    this.state = {
      uid: draftUID,
      trackingHeaders: [],
      trackingInformation: [],
      stateEntityName: "",
      actName: "",
      interestGroup: "",
      isQualifiedIncome: false,
      isRepresented: false,
      representor: "",
      isRD: false
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();

    const employees = await pullFleetEmployee();
    const trackingInformation: any = [];

    await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
      response.data.trackingInformation.forEach((item: any) => {
        item.fleetEmployee = { label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.fleetEmployeeUID }.label;
        trackingInformation.push({
          trackUID: item.uid,
          author: item.fleetEmployee,
          notes: item.notes,
          trackingType: { label: EntityTrackingTypes.filter(u => u.value === item.entityTrackingType.toString())[0].label, value: item.entityTrackingType.toString() }
        })
      })

      this.setState({
        trackingInformation: trackingInformation
      })
    })
  }

  componentDidUpdate(): void {

  }

  generateHeaders(): void {
    const trackingHeaders: Array<FilterAndSettingField> = [
      {
        columnName: 'Author',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Tracking Log',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Notes',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      }
    ]
    this.setState({ trackingHeaders: trackingHeaders })
  }

  submitModal(): void {
    this.submitRef.current.display(
      this.state.uid,
      this.state.stateEntityName,
      this.state.actName,
      this.state.interestGroup,
      this.state.isQualifiedIncome,
      this.state.isRepresented,
      this.state.representor,
      this.state.isRD,
      this.submit
    );
  }

  async submit(_data: NewEntityDraftPatchData): Promise<void> {
    this.loadingRef.current.display("Generating Document", "Please wait")

    const data = {
      NewEntityUID: _data.uid,
      StateEntityName: _data.stateEntityName,
      ActName: _data.actName,
      InterestGroupName: _data.interestGroup,
      IsIncomeQualified: _data.isQualifiedIncome,
      IsRepresented: _data.isRepresented,
      RepresentorName: _data.representor,
      IsRuralDevelopment: _data.isRD
    }

    const workflowData = {
      UID: this.state.uid,
      RequestorUID: getUserID()
    }

    await axios.patch('./api/new-entity-request/addtional-info', data).then((response) => {
      
    })

    //Create PDF Document
    await axios.patch('./api/new-entity-workflow/generate-document', workflowData).then((response) => {
      this.loadingRef.current.hide();
      CreateGlobalAlert("Document Generated, Going Back To Table", 2500)
      window.location.assign("./entity-request-drafts")
    })
  }

  render(): JSX.Element {
    return (
      <Row style={{ height: "100vh", overflowY: "auto", overflowX: "hidden", paddingBottom: "15vh" }}>
        <EntityRequestEditModal ref={this.modalRef} />
        <EntityRequestSubmitModal ref={this.submitRef} />
        <EntityRequestReviewHeader uid={this.state.uid} />
        <EntityRequestReviewFormation uid={this.state.uid} />
        <EntityRequestReviewMemberBanking uid={this.state.uid} />
        <StatusModal ref={this.loadingRef} />
        <Row style={{ marginTop: "1vh", marginBottom: "1vh" }}>
          <Col>
            <input
              type="button"
              className="standardInput"
              value="Submit"
              style={{ width: "60%", marginLeft: "20%", height: "5vh", marginBottom: "1vh", background: "#00AA00", color: "#FFFFFF" }}
              onClick={this.submitModal}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.trackingHeaders} icons={0} />
              </thead>
              <tbody>
                {this.state.trackingInformation.map((item: any, idx: number) =>
                  <tr style={{ height: "13vh" }}>
                    <td className="fixedTableCellFirst">{item.author}</td>
                    <td className="fixedTableCellFirst">{item.trackingType.label}</td>
                    <td className="fixedTableCell" style={{ whiteSpace: "pre-wrap", maxHeight: "13vh", overflow: "auto" }}>{item.notes}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Row>
    )
  }
}