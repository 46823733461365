import * as React from 'react'
import { CNAPageManagerProps, CNAPageManagerState } from './CNAPageManager.constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import CNADashboard from './CNADashboard';
import Select from "react-select";
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { Col, Row } from 'reactstrap';
import CNALineItemManagementPage from './CNALineItemManagementPage';
import axios from 'axios';
import { getBearerToken } from '../../../functions/authActions';


export default class CNAPageManager extends React.Component<CNAPageManagerProps, CNAPageManagerState>{

  dashboardPage: React.RefObject<CNADashboard> = React.createRef<CNADashboard>();
  managementPage: React.RefObject<CNALineItemManagementPage> = React.createRef<CNALineItemManagementPage>();

  constructor(props: CNAPageManagerProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const cnaUID = params.has("uid") ? params.get("uid") : "";
    if (cnaUID === '') {
      window.location.replace("./property-summary-table");
      return;
    };
    this.state = {
      dashboardPageOptions: [],
      pageSelected: { label: "Captial Needs Assessment", value: "0" },
      cnaUID: cnaUID,
      name: "",
      version: "",
      lineSelected: { label: "All", value: "4" },
      lineOptions: [
        { label: "All", value: "4" },
        { label: "Sites", value: "0" },
        { label: "Architectual", value: "1" },
        { label: "Mech and Electric", value: "2" },
        { label: "Dwelling Unit", value: "3" }
      ]
    }
    this.changePage = this.changePage.bind(this);
    this.changeLine = this.changeLine.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();

    const response = await axios.get('./api/capital-needs-assessment/' + this.state.cnaUID)

    if (response.status == 200) {
      this.setState({
        name: response.data.projectName,
        version: response.data.version
      })
    }
    else {
      //TODO ERROR HANDLE
    }

    const dashboardPage: Array<SelectOptions> = [
      { label: "Capital Needs Assessment", value: "0" },
      { label: "Line Items", value: "1" },
    ]

    this.setState({
      dashboardPageOptions: dashboardPage
    })

  }

  pageDisplay(): JSX.Element {
    switch (parseInt(this.state.pageSelected.value)) {
      case 0:
        return <CNADashboard ref={this.dashboardPage} />
      case 1:
        return <CNALineItemManagementPage ref={this.managementPage} key="management-page" selectedLine={this.state.lineSelected} />
      default:
        return <h3>OOPS XD Screenshot this to Tom in IT and he'll buy you a candy bar</h3>
    }
  }

  changePage(event: SelectOptions | null) {
    this.setState({ pageSelected: event })
  }

  changeLine(event: SelectOptions | null) {
    this.setState({ lineSelected: event }, () => this.managementPage.current.setSelectedLineViewer(event));
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col xs='3'>
            <Select
              options={this.state.dashboardPageOptions}
              styles={reactSelectBasicStyle}
              value={this.state.pageSelected}
              onChange={this.changePage}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
          <Col>
            <h3 style={{ textAlign: "center" }}>- {this.state.name} Version: {this.state.version} -</h3>
          </Col>
          <Col xs='3'>
            {this.state.pageSelected.value === "0" && <input type="button" className="standard-input" value="Edit Widgets" onClick={() => this.dashboardPage.current.openWidgetSettings()} />}
            {this.state.pageSelected.value === "1" && <Select
              options={this.state.lineOptions}
              styles={reactSelectBasicStyle}
              value={this.state.lineSelected}
              onChange={this.changeLine}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />}
          </Col>
        </Row>
        <hr />
        {this.pageDisplay()}
      </>
    )
  }
}