import * as React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle_95Width } from '../../../style/select-constants';
import Select from 'react-select';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { CalculationMethods, CompoundingTypes, RowProperties } from '../Structs';
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';
import { pullEntitiesWithIndividuals } from '../../../functions/fetchLinkedObjects';

export interface AmortizationPostData {
  LoanTitle: string;
  CompoundingType: string;
  InterestMethod: string;
  StartingInterestRate: number;
  PrincipalLimit: number;
  StartDate: Date;
  Term: number;
  IgnorePrincipal: boolean;
  BallonTerm: number;
  LenderUID: string;
  BorrowerUID: string;
  IsLoan: boolean;
}

interface EntityAmortizationCreationModalProps {

}

interface EntityAmortizationCreationModalState {
  show: boolean;
  modalTitle: string;
  callback: (data: AmortizationPostData) => void;
  title: string;
  entities: Array<SelectOptions>;
  lender: SelectOptions;
  borrower: SelectOptions;
  compoundType: SelectOptions;
  calculationMethod: SelectOptions;
  startingInterestRate: number;
  principalCap: number;
  loanStartDate: Date;
  term: number;
  ignorePrincipal: boolean;
  balloon: number;
  isLoan: SelectOptions;
  check: boolean;
}

const LoanOrCredit: Array<SelectOptions> = [
  { value: "1", label: "Loan" },
  { value: "0", label: "Line Of Credit" }
]

export default class EntityAmortizationCreationModal extends React.Component<EntityAmortizationCreationModalProps, EntityAmortizationCreationModalState>{

  constructor(props: EntityAmortizationCreationModalProps) {
    super(props);
    this.state = {
      show: false,
      modalTitle: "",
      title: "",
      entities: [],
      lender: { label: 'None', value: '' },
      borrower: { label: 'None', value: '' },
      compoundType: { label: 'None', value: '0' },
      calculationMethod: { label: "ACTUAL/360", value: '0' },
      startingInterestRate: 0,
      principalCap: 0.0,
      loanStartDate: new Date(),
      term: -1,
      ignorePrincipal: false,
      balloon: -1,
      callback: undefined,
      isLoan: { value: "", label: "Select Type" },
      check: false
    }
    this.changeTitle = this.changeTitle.bind(this);
    this.changeLender = this.changeLender.bind(this);
    this.changeBorrower = this.changeBorrower.bind(this);
    this.changeCompoundingType = this.changeCompoundingType.bind(this);
    this.changeCalculationMethod = this.changeCalculationMethod.bind(this);
    this.changeRate = this.changeRate.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.changePreventRollout = this.changePreventRollout.bind(this);
    this.changeLoanOrCredit = this.changeLoanOrCredit.bind(this);
    this.validate = this.validate.bind(this);
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const entities = await pullEntitiesWithIndividuals();
    this.setState({ entities: entities });
  }

  changeTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ title: event.target.value })
  }

  changeLender(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ lender: event })
  }

  changeBorrower(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ borrower: event })
  }

  changeCompoundingType(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ compoundType: event })

  }

  changeCalculationMethod(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ calculationMethod: event })
  }

  changeRate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      "startingInterestRate": parseFloat(event.target.value)
    })
  }

  changeInput(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.value === "" && event.target.name === "principalCap") {
      event.target.value = "0";
    }
    // @ts-ignore
    this.setState({
      [event.target.name]: parseFloat(event.target.value)
    })
  }

  changePreventRollout(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ ignorePrincipal: event.target.checked });
  }

  changeLoanOrCredit(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      isLoan: event
    });
  }

  validate(): void {
    if (isNaN(this.state.balloon)) {
      this.setState({
        balloon: -1
      })
    }
    if (isNaN(this.state.term)) {
      this.setState({
        term: -1
      })
    }
    const test1: boolean = this.state.term < 0 || this.state.term > 100;
    const test2: boolean = this.state.balloon > 0 && this.state.balloon < 100;
    const test3: boolean = this.state.balloon > this.state.term;

    if (!test1 && test2 && !test3) {
      // @ts-ignore
      CreateGlobalAlert("The length of the Balloon Term must be greater than the length of the the loan term", 2500);
      this.setState({
        balloon: -1
      })
    }
    if (test1 && test2) {
      // @ts-ignore
      CreateGlobalAlert("Invalid Configuration for Balloon Payment. Please set the Term length before creating Balloon Length", 2500);
      this.setState({
        balloon: -1
      })
    }
    else if (test1) {
      // @ts-ignore
      CreateGlobalAlert("Term is outside of the Range (0,99). Loan will not calculate payback.", 2500);
      this.setState({
        term: -1
      })
    }

  }

  show(modalTitle: string, callback: (data: AmortizationPostData) => void): void {
    this.setState({
      show: true,
      modalTitle: modalTitle,
      callback: callback
    })
  }

  callback(): void {
    if (this.state.lender.value === "") {
      // TODO Add Error
      return;
    }

    if (
      this.state.borrower.value !== "" &&
      this.state.compoundType.value !== "" &&
      this.state.calculationMethod.value !== "" &&
      this.state.startingInterestRate !== 0) {

      let isLoan = this.state.isLoan.value === "1" ? true : false;

      const amortScheduleData: AmortizationPostData = {
        LoanTitle: this.state.title,
        CompoundingType: this.state.compoundType.value,
        InterestMethod: this.state.calculationMethod.value,
        StartingInterestRate: this.state.startingInterestRate,
        PrincipalLimit: this.state.principalCap,
        StartDate: this.state.loanStartDate,
        Term: this.state.term,
        IgnorePrincipal: this.state.ignorePrincipal,
        BallonTerm: this.state.balloon,
        LenderUID: this.state.lender.value,
        BorrowerUID: this.state.borrower.value,
        IsLoan: isLoan
      }

      this.setState({
        show: false,
        check: false
      });
      if (this.state.callback) {
        this.state.callback(amortScheduleData);
      }
    } else {
      this.setState({ check: true })
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <ModalHeader style={{ backgroundColor: "#4C4A42" }}>
          <h3 style={{ color: "#d7c7a7" }}>{this.state.modalTitle}</h3>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          <Row>
            {this.state.check && <h4 style={{ width: "100%", marginTop: "1em", color: "#ff0000" }}>Please double check all information is entered</h4>}
          </Row>
          <Row>
            <Col>
              <h4 style={{ width: "100%", marginTop: "1em" }}>Title</h4>
              <input type="text" name="title" className="standard-input no-print"
                style={{ width: "95%" }}
                value={this.state.title} onChange={this.changeTitle}
                required={this.state.title === ""}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 style={{ width: "100%", marginTop: "1em" }}>Lender</h4>
              <Select
                styles={reactSelectBasicStyle_95Width}
                options={this.state.entities}
                value={this.state.lender}
                onChange={this.changeLender}
                menuPlacement="bottom"
                className="no-print"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 style={{ width: "100%", marginTop: "1em" }}>Borrower</h4>
              <Select
                styles={reactSelectBasicStyle_95Width}
                options={this.state.entities}
                defaultValue={{ label: 'Select Entity', value: '' }}
                value={this.state.borrower}
                onChange={this.changeBorrower}
                className="no-print"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 style={{ width: "100%", marginTop: "1em" }}>Compound Type</h4>
              <Select
                styles={reactSelectBasicStyle_95Width}
                options={CompoundingTypes}
                defaultValue={{ label: 'None', value: '0' }}
                value={this.state.compoundType}
                onChange={this.changeCompoundingType}
                className="no-print"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 style={{ width: "100%", marginTop: "1em" }}>Calculation Method</h4>
              <Select
                styles={reactSelectBasicStyle_95Width}
                options={CalculationMethods}
                defaultValue={{ label: 'ACTUAL/360', value: '0' }}
                value={this.state.calculationMethod}
                onChange={this.changeCalculationMethod}
                className="no-print"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 style={{ width: "75%", float: "left", marginTop: "1em" }}>Starting Interest</h4>
              <input type="number" name="startingInterestRate" value={this.state.startingInterestRate}
                className="standard-input no-print"
                onChange={this.changeRate}
                style={{ width: "95%" }}
                required={this.state.startingInterestRate === 0}
              />
            </Col >
          </Row>
          <Row>
            <Col>
              <h4 style={{ width: "100%", marginTop: "1em" }}>Loan or Credit?</h4>
              <Select
                styles={reactSelectBasicStyle_95Width}
                options={LoanOrCredit}
                value={this.state.isLoan}
                onChange={this.changeLoanOrCredit}
                className="no-print"
              />
            </Col>
          </Row>
          {this.state.isLoan.value === "1" && <>
            <Row>
              <Col>
                <h4 style={{ width: "100%", marginTop: "1em" }}>Principal Amount</h4>
                <input type="number" name="principalCap" className="standard-input no-print"
                  style={{ width: "95%" }}
                  value={this.state.principalCap} onChange={this.changeInput}
                />
                <br className="hidden-print" />
                <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{ }</h4>
              </Col>
              <Col>
                <h4 style={{ width: "100%", marginTop: "1em" }} className={this.state.term === -1 ? "no-print" : ""}>Ignore Principal</h4>
                <input type="checkbox" name="ignorePrincipal" className="no-print" checked={this.state.ignorePrincipal} style={{ width: "95%" }} onChange={this.changePreventRollout} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 style={{ width: "100%", marginTop: "1em" }} className={this.state.term === -1 ? "no-print" : ""}>Term (Yrs) </h4>
                <input
                  type="number"
                  name="term"
                  className="standard-input no-print"
                  value={this.state.term}
                  style={{ width: "95%" }}
                  onBlur={this.validate} onChange={this.changeInput}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 style={{ width: "100%", marginTop: "1em" }} className={this.state.balloon === -1 ? "no-print" : ""}>Balloon (Yrs)</h4>
                <input type="number" name="balloon" className="standard-input no-print"
                  value={this.state.balloon}
                  style={{ width: "95%" }}
                  onBlur={this.validate} onChange={this.changeInput}
                />
              </Col>
            </Row>
          </>}

          {this.state.isLoan.value === "0" && <>
            <Row>
              <Col>
                <h4 style={{ width: "100%", marginTop: "1em" }}>Principal Limit</h4>
                <input type="number" name="principalCap" className="standard-input no-print"
                  style={{ width: "95%" }}
                  value={this.state.principalCap} onChange={this.changeInput}
                />
                <br className="hidden-print" />
                <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{ }</h4>
              </Col>
              <Col>
                <h4 style={{ width: "100%", marginTop: "1em" }} className={this.state.term === -1 ? "no-print" : ""}>Ignore Principal</h4>
                <input type="checkbox" name="ignorePrincipal" className="no-print" checked={this.state.ignorePrincipal} style={{ width: "95%" }} onChange={this.changePreventRollout} />
              </Col>
            </Row>
          </>}
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
          <input type="button" value="Submit" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
          />
        </ModalFooter>
      </Modal>
    )
  }

}