//#region Imports
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../../style/select-constants';
import { SelectOptions } from '../../../../interfaces/CoreInterfaces';
import axios from 'axios';
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { EntityBankAccountType_NewRequest, EntityBankName_NewRequest } from '../../Structs';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../../functions/selectTools';
//#endregion

interface BankingDataTabProps {
  uid: string;
  checkValid: (checkedValue: boolean) => void;
}

interface BankingDataTabState {
  uid: string;
  accounts: Array<BankAccount>;
}

export interface BankAccount {
  type: SelectOptions;
  signers: string;
  bank: SelectOptions;
}

export class BankingInformation extends React.Component<BankingDataTabProps, BankingDataTabState>{

  constructor(props: BankingDataTabProps) {
    super(props);
    this.state = {
      uid: props.uid,
      accounts: []
    }
    this.addAccount = this.addAccount.bind(this);
    this.changeAuthorizedSigners = this.changeAuthorizedSigners.bind(this);
    this.changeBank = this.changeBank.bind(this);
  }

  async componentDidMount(): Promise<void> {
    if (this.state.uid !== '') {
      await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
        const accounts: any = [];
        let check = false;

        response.data.bankingInformation.forEach((item: any) => {
          accounts.push({
            type: { label: EntityBankAccountType_NewRequest.filter(u => parseInt(u.value) === item.bankAccountType)[0].label, value: item.bankAccountType },
            signers: item.authorizedSigners,
            bank: { label: EntityBankName_NewRequest.filter(u => parseInt(u.value) === item.bankName)[0].label, value: item.bankName }
          })

          if (accounts.length > 0) {
            check = true
          }

          this.setState({ accounts: accounts }, () => this.props.checkValid(check))
        })
      })
    }
  }

  async componentDidUpdate(): Promise<void> {

  }

  async addAccount(): Promise<void> {
    const accounts = this.state.accounts
    accounts.push({
      type: { label: "Controlled Account", value: "0" },
      signers: 'Chelsea Dawson,Timothy Cox',
      bank: { label: "Umpqua", value: "0" }
    })

    this.setState({ accounts: accounts }, () => this.props.checkValid(true))

    const data = {
      NewEntityUID: this.state.uid,
      BankAccountType: this.state.accounts[this.state.accounts.length - 1].type.value,
      AuthorizedSigners: this.state.accounts[this.state.accounts.length - 1].signers,
      BankName: this.state.accounts[this.state.accounts.length - 1].bank.value
    }

    await axios.post("./api/new-entity-request/add-new-bank-information", data).then((response) => {
      CreateGlobalAlert("New Bank Added", 2500);
    })
  }

  async changeAccount(event: SelectOptions | null, idx: number): Promise<void> {
    const accounts = this.state.accounts
    accounts[idx].type = event;
    this.setState({ accounts: accounts });

    const data = {
      NewEntityUID: this.state.uid,
      BankAccountType: this.state.accounts[idx].type.value
    }

    await axios.patch("./api/new-entity-request/add-new-bank-information-change-account", data).then((response) => {
      CreateGlobalAlert("Account Type Changed", 2500);
    })
  }

  async changeAuthorizedSigners(event: React.ChangeEvent<HTMLInputElement>, idx: number): Promise<void> {
    const accounts = this.state.accounts
    accounts[idx].signers = event.target.value
    this.setState({ accounts: accounts })

    const data = {
      NewEntityUID: this.state.uid,
      AuthorizedSigners: this.state.accounts[idx].signers
    }

    await axios.patch("./api/new-entity-request/add-new-bank-information-change-signers", data).then((response) => {
      CreateGlobalAlert("Signers Changed", 2500);
    })
  }

  async changeBank(event: SelectOptions | null, idx: number): Promise<void> {
    const accounts = this.state.accounts
    accounts[idx].bank = event;
    this.setState({ accounts: accounts });

    const data = {
      NewEntityUID: this.state.uid,
      BankName: this.state.accounts[idx].bank.value
    }

    await axios.patch("./api/new-entity-request/add-new-bank-information-change-bank", data).then((response) => {
      CreateGlobalAlert("Bank Changed", 2500);
    })
  }

  render(): JSX.Element {
    return (
      <>
        {this.state.accounts?.map((item, idx) =>
          <Row key={item.type.value + idx}>
            <Col>
              <label>Account Type</label>
              <Select
                options={EntityBankAccountType_NewRequest}
                styles={reactSelectBasicStyle}
                value={item.type}
                onChange={(e: SelectOptions) => this.changeAccount(e, idx)}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col>
              <label>Authorized Signers</label>
              <input type="text" className="standard-input" style={{ height: "4vh" }} value={item.signers} onChange={(e) => this.changeAuthorizedSigners(e, idx)} />
            </Col>
            <Col>
              <label>Bank</label>
              <Select
                options={EntityBankName_NewRequest}
                styles={reactSelectBasicStyle}
                value={item.bank}
                onChange={(e: SelectOptions | null) => this.changeBank(e, idx)}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
        )}
        <br />
        <hr />
        <Row>
          <Col></Col>
          <Col>
            <input type="button" value="Add Account" className="standard-input" style={{ height: "4vh" }} onClick={this.addAccount} />
          </Col>
        </Row>
      </>
    )
  }
}