//#region Imports
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { FilterAndSettingField } from '../interfaces';
import { WarningModal } from '../Modals';

//#region Interfaces

export interface CostCategory {
  uid: string;
  description: string;
  categoryCode: string;
  workersCompCode: string;
  workersCompRate: string;
}

export interface ScheduleOfValuesLineItem {
  uid: string;
  costCategory: CostCategory;
  itemNumber: string;
  description: string;
  scheduledValues: number;
  previous: number;
  thisPeriod: number;
  materials: number;
  percentage: number;
  totalCompleted: number;
}

/**
 * Props for the Schedule of Line Items 
 */
export interface ScheduleOfValuesLineItemProps {
  line: ScheduleOfValuesLineItem;
  locked: boolean;
  lockScheduledValues: boolean;
  update: (useCallback: boolean) => void;
  isDescriptionSelectable?: boolean;
  remove?: (uid: string) => Promise<void>;
  api: string;

}

/**
 *  
 */
export interface ScheduleOfValuesLineItemState {
  line: ScheduleOfValuesLineItem;
  locked: boolean;
  isDescriptionSelectable: boolean;
  percentageAbortController: AbortController;
  thisPeriodAbortController: AbortController;
}

//#endregion

export function generateScheduleOfValuesHeaders(remove: boolean = false): Array<FilterAndSettingField> {
  let headers: Array<FilterAndSettingField> = [
    {
      columnName: "No.",
      setting: true,
      filter: {},
      width: 75
    },
    {
      columnName: "Description of Work",
      setting: true,
      filter: {},
    },
    {
      columnName: "Scheduled Values",
      setting: true,
      filter: {},
    },
    {
      columnName: "Previous Billed",
      setting: true,
      filter: {},
    },
    {
      columnName: "This Period",
      setting: true,
      filter: {},
    },
    {
      columnName: "Materials",
      setting: true,
      filter: {},
      additionalClasses: "twenty"
    },
    {
      columnName: "Total Completed",
      setting: true,
      filter: {},
      width: 150
    },
    {
      columnName: "%",
      setting: true,
      filter: {},
      width: 60
    },
    {
      columnName: "Balance To Finish",
      setting: true,
      filter: {},
      width: 150
    }
  ]
  if (remove) {
    headers.push({
      columnName: "",
      setting: true,
      filter: {},
      additionalClasses: "icon"
    })
  }
  return headers;
}

export function addLineItems(x: ScheduleOfValuesLineItem, a: ScheduleOfValuesLineItem): ScheduleOfValuesLineItem {
  return {
    uid: EMPTY_GUID,
    itemNumber: "Irrelevant if Called",
    description: "Irrelevant if Called",
    scheduledValues: x.scheduledValues + a.scheduledValues,
    previous: x.previous + a.previous,
    thisPeriod: x.thisPeriod + a.thisPeriod,
    materials: x.materials + a.materials,
    percentage: (x.thisPeriod + x.previous + x.materials + a.thisPeriod + a.previous + a.materials) * 1.0 / (x.scheduledValues + a.scheduledValues === 0.00 ? 1.00 : x.scheduledValues + a.scheduledValues) * 1.0,
    totalCompleted: x.totalCompleted + a.totalCompleted,
    costCategory: undefined
  }
}

export class ScheduleOfValuesLineItemRow extends React.Component<ScheduleOfValuesLineItemProps, ScheduleOfValuesLineItemState>{

  warningModal = React.createRef<WarningModal>();

  static EmptySOVLineItem: ScheduleOfValuesLineItem = {
    uid: "",
    itemNumber: "",
    description: "",
    scheduledValues: 0,
    previous: 0,
    thisPeriod: 0,
    materials: 0,
    percentage: 0,
    totalCompleted: 0,
    costCategory: undefined,
  };

  static Map = [
    { item: "02-0600", description: "Unusual On-Site Conditions" },
    { item: "03-0500", description: "Concrete" },
    { item: "04-0500", description: "Masonry" },
    { item: "05-0500", description: "Metals" },
    { item: "06-1000", description: "Rough Carpentry" },
    { item: "06-2000", description: "Finish Carpentry" },
    { item: "07-1000", description: "Waterproofing" },
    { item: "07-2100", description: "Insulation" },
    { item: "07-4000", description: "Roofing" },
    { item: "07-6000", description: "Sheet Metal" },
    { item: "08-1000", description: "Doors" },
    { item: "08-5000", description: "Windows" },
    { item: "09-6500", description: "Flooring" },
    { item: "09-7000", description: "Drywall" },
    { item: "09-9000", description: "Painting & Decorating" },
    { item: "10-0500", description: "Specialties" },
    { item: "11-3000", description: "Residential Appliances" },
    { item: "12-1000", description: "Blinds & Shades" },
    { item: "12-3500", description: "Casework" },
    { item: "13-0500", description: "Special Construction" },
    { item: "21-9000", description: "Fire Suppression" },
    { item: "22-4000", description: "Plumbing & Hot Water" },
    { item: "23-7000", description: "Heat & Ventilation" },
    { item: "26-5000", description: "Electrical" },
    { item: "31-0500", description: "Earthwork" },
    { item: "32-0500", description: "Roads & Walks" },
    { item: "32-3000", description: "On-Site Improvements" },
    { item: "32-9000", description: "Landscaping" },
    { item: "33-1000", description: "On-site Utilities" },
    { item: "01-8120", description: "Moving" },
    { item: "50-0300", description: "Other Fees Paid by Contractor" },
    { item: "01-0600", description: "Misc (Labor & Materials)" },
    { item: "01-0500", description: "General Requirements" },
    { item: "50-0500", description: "General Overhead" },
    { item: "", description: "Profit" }
  ]

  constructor(props: ScheduleOfValuesLineItemProps) {
    super(props);
    this.state = {
      line: props.line,
      locked: props.locked,
      isDescriptionSelectable: props.isDescriptionSelectable ? true : false,
      percentageAbortController: null,
      thisPeriodAbortController: null
    };
    this.changeScheduled = this.changeScheduled.bind(this);
    this.changeThisPeriod = this.changeThisPeriod.bind(this);
    this.changeMaterials = this.changeMaterials.bind(this);
    this.changePercentage = this.changePercentage.bind(this);
    this.changeType = this.changeType.bind(this);
    this.calculate = this.calculate.bind(this);
    this.calculatePercentageOnly = this.calculatePercentageOnly.bind(this);
    this.initialize = this.initialize.bind(this);

    this.updateType = this.updateType.bind(this);
    this.updateScheduled = this.updateScheduled.bind(this);
    this.updateThisPeriod = this.updateThisPeriod.bind(this);
    this.updateMaterials = this.updateMaterials.bind(this);
    this.updatePercentage = this.updatePercentage.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.initialize();
  }

  //#region Functions

  changeType(event: SelectOptions | null): void {
    if (event === null) { return; }
    let line = this.state.line;
    line.itemNumber = event.value;
    line.description = event.label;
    this.setState({
      line: line
    }, () => this.updateType())
  }

  async updateType(): Promise<void> {
    const categoryData = {
      UID: this.state.line.uid,
      CategoryID: this.state.line.itemNumber
    }
    const nameData = {
      UID: this.state.line.uid,
      Name: this.state.line.description
    }
    await axios.patch(this.props.api + "/categoryId", categoryData);
    await axios.patch(this.props.api + "/name", nameData);
  }

  changeScheduled(event: React.ChangeEvent<HTMLInputElement>): void {
    const line = this.state.line;
    line.scheduledValues = parseFloat(parseFloat(event.target.value).toFixed(2));
    this.setState({
      line: line,
    })
    this.calculatePercentageOnly();
    this.props.update(true);
  }

  async updateScheduled(): Promise<void> {
    const data = {
      UID: this.state.line.uid,
      scheduledValues: this.state.line.scheduledValues
    }
    await axios.patch(this.props.api + "/scheduleValues", data);
  }

  changeThisPeriod(event: React.ChangeEvent<HTMLInputElement>): void {
    const line = this.state.line;
    const newValue = parseFloat(parseFloat(event.target.value).toFixed(2));
    if ((newValue + line.previous + line.materials) > line.scheduledValues) {
      this.warningModal.current.show("You are attempting to increase the amount of the line to be greater than than the total completed. If you are trying to make the line 100%, I recommend using the percentage column", "Invalid Amount for This Period");
      return;
    }
    line.thisPeriod = newValue;
    this.updateThisPeriod();
    this.setState({
      line: line
    }, this.calculatePercentageOnly)
  }

  async updateThisPeriod(callback: boolean = false): Promise<void> {
    const data = {
      UID: this.state.line.uid,
      CurrentPeriod: this.state.line.thisPeriod
    }
    let controller = new AbortController();
    if (this.state.thisPeriodAbortController) { this.state.thisPeriodAbortController.abort(); }
    this.setState({ thisPeriodAbortController: controller })
    await axios.patch(this.props.api + "/currentPeriod", data, { signal: controller.signal });
    this.setState({ thisPeriodAbortController: null });
    if (callback) {
      this.updatePercentage();
    }
  }

  changeMaterials(event: React.ChangeEvent<HTMLInputElement>): void {
    const line = this.state.line;
    let newValue = parseFloat(parseFloat(event.target.value).toFixed(2));
    if ((newValue + line.previous + line.thisPeriod) > line.scheduledValues) {
      this.warningModal.current.show("You are attempting to increase the value of the line to be greater than than the total completed.", "Invalid Amount for Materials");
      return;
    }
    line.materials = newValue;
    this.setState({
      line: line
    }, this.calculatePercentageOnly)
  }

  async updateMaterials(): Promise<void> {
    const data = {
      UID: this.state.line.uid,
      Materials: this.state.line.materials
    }
    await axios.patch(this.props.api + "/materials", data);
    this.updatePercentage();
    this.updateTotalCompleted();
    this.props.update(true);
  }

  changePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
    const line = this.state.line;
    let value = parseFloat(event.target.value);
    if (value > 100) {
      value = 100;
    }
    else if (value < 0) {
      value = 0;
    }
    line.percentage = parseFloat(value.toFixed(2));
    this.updatePercentage();
    this.setState({
      line: line
    }, this.calculate);
  }

  async updatePercentage(): Promise<void> {
    const data = {
      UID: this.state.line.uid,
      Percentage: this.state.line.percentage
    }
    let controller = new AbortController();
    if (this.state.percentageAbortController) { this.state.percentageAbortController.abort(); }
    this.setState({ percentageAbortController: controller })
    await axios.patch(this.props.api + "/percentage", data, { signal: controller.signal });
    this.setState({ percentageAbortController: null });
    await this.updateThisPeriod(false);
    await this.updateTotalCompleted();
    this.props.update(true);
  }

  async updateTotalCompleted(): Promise<void> {
    const data = {
      UID: this.state.line.uid,
      TotalCompleted: this.state.line.materials + this.state.line.thisPeriod + this.state.line.previous
    }
    await axios.patch(this.props.api + "/totalCompleted", data);
  }

  calculate(): void {
    const line = this.state.line;
    const percentage = line.percentage / 100;
    const materials = line.materials;
    const scheduled = line.scheduledValues;
    const previous = line.previous;
    let currentValue = scheduled * percentage;
    currentValue -= materials;
    currentValue -= previous;
    const retention = parseFloat((currentValue * 0.05).toFixed(2));
    line.thisPeriod = currentValue;
    this.setState({
      line: line
    })
    this.props.update(true);
  }

  async calculatePercentageOnly(): Promise<void> {
    const line = this.state.line;
    const total = line.thisPeriod + line.previous + line.materials;
    const percentage = total / line.scheduledValues;
    line.percentage = parseFloat((percentage * 100).toFixed());
    if (line.percentage === 100) {
      await this.updateMaterials();
      await this.updateThisPeriod();
    }
    this.setState({
      line: line
    })
    this.props.update(true);
  }

  initialize(): void {
    const line = this.state.line;
    const total = line.thisPeriod + line.previous + line.materials;
    let percentage = 0;
    if (line.scheduledValues > 0) {
      percentage = total / line.scheduledValues;
    }
    else {
      percentage = 1;
    }
    line.percentage = parseFloat((percentage * 100).toFixed(2));
    this.setState({
      line: line
    })
    this.props.update(false);
  }

  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <WarningModal ref={this.warningModal} />
        <td className="fixedTableCellFirst">
          {this.state.line.costCategory ? this.state.line.costCategory.categoryCode : this.state.line.itemNumber}
        </td>
        <td className="fixedTableCell">
          {this.state.isDescriptionSelectable ?
            <Select
              styles={reactSelectBasicStyle}
              options={ScheduleOfValuesLineItemRow.Map.map(function (item) { return { label: item.description, value: item.item } })}
              value={{ value: this.state.line.costCategory ? this.state.line.costCategory.categoryCode : this.state.line.itemNumber, label: this.state.line.costCategory ? this.state.line.costCategory.description : this.state.line.description }}
              onChange={this.changeType}
            />
            :
            this.state.line.description
          }
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          { (this.state.locked && this.props.lockScheduledValues)
            ? "$" + numberWithCommas(this.state.line.scheduledValues) :
            <input style={{ textAlign: "right" }} className="standard-input" type="number" value={this.state.line.scheduledValues}
              onChange={this.changeScheduled} onWheel={(e) => e.currentTarget.blur()} onBlur={this.updateScheduled}
            />
          }
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          {"$" + numberWithCommas(this.state.line.previous)}
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          {this.state.locked || this.state.line.percentage === 100
            ? "$" + numberWithCommas(this.state.line.thisPeriod)
            : <input style={{ textAlign: "right" }} className="standard-input" type="number" step="0.001"
              value={this.state.line.thisPeriod} onChange={this.changeThisPeriod}
              onWheel={(e) => e.currentTarget.blur()} onBlur={() => this.updateThisPeriod(true)}
            />
          }
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          {this.state.locked || this.state.line.percentage === 100
            ? "$" + numberWithCommas(this.state.line.materials)
            : <input style={{ textAlign: "right" }} className="standard-input" type="number" step="0.001"
              value={this.state.line.materials} onChange={this.changeMaterials}
              onWheel={(e) => e.currentTarget.blur()} onBlur={this.updateMaterials}
            />
          }
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          ${numberWithCommas(this.state.line.materials + this.state.line.thisPeriod + this.state.line.previous)}
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          {this.state.locked
            ? this.state.line.percentage.toFixed(2) + "%"
            : <input style={{ textAlign: "right" }} className="standard-input" step="0.001" type="number"
              value={this.state.line.percentage} onChange={this.changePercentage}
              onWheel={(e) => e.currentTarget.blur()} onBlur={this.updatePercentage }
            />}
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          ${numberWithCommas(this.state.line.scheduledValues - (this.state.line.materials + this.state.line.thisPeriod + this.state.line.previous))}
        </td>
        {
          this.props.remove && <td className="fixedTableCell">
            <FontAwesomeIcon icon={faTrash} onClick={() => this.props.remove(this.state.line.uid)} style={{ marginLeft: "10%" }} />
          </td>
        }
      </tr>

    )
  }
}
