import * as React from 'react';
import { GetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DashboardOrganizer, DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { DashboardManagementModal } from '../../CoreComponents/Modals/DashboardManagementModal';
import NewTicketWidget from '../../TicketingSystemComponents/TicketWidgets/NewTicketWidget';
import { TechnologyTeamDashboardProps, TechnologyTeamDashboardState } from './TechnologyTeamDashboard.constants';
import SevenDayTicketTrendWidget from '../../TicketingSystemComponents/TicketWidgets/SevenDayTicketTrendWidget';
import { Col, Row } from 'reactstrap';
import KnowledgebaseCreationWidget from '../../TicketingSystemComponents/TicketWidgets/KnowledgebaseCreationWidget';
import UnitUploadWidget from '../../TicketingSystemComponents/TicketWidgets/DataUploadWidget';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';
import TicketSummaryLookupWidget from '../../TicketingSystemComponents/TicketWidgets/TicketSummaryLookupWidget';

export default class TechnologyTeamDashboard extends React.Component<TechnologyTeamDashboardProps, TechnologyTeamDashboardState> {

  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  settingsString: string = "techTicketDashboard";

  constructor(props: TechnologyTeamDashboardProps) {
    super(props);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal: boolean = false;
    if (settings === null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      showModal: showModal,
      renderSettings: settings,
      displayWidgets: [],
      widgetList: []
    }
    this.updateUI = this.updateUI.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDasboard();
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI);
    }
  }

  async prepareDasboard(): Promise<void> {
    let displayWidgets: { [key: number]: JSX.Element } = {
      //0: <NewTicketWidget />,
      //1: <SevenDayTicketTrendWidget />,
      4: <KnowledgebaseCreationWidget />,
      5: <UnitUploadWidget />,
      7: <TicketSummaryLookupWidget />
    };
    let widgetList = [
      { label: "New Tickets", value: "0" },
      //{ label: "7 Day Trend", value: "1" },
      { label: "New Equipment", value: "2" },
      { label: "In Progress Equipment", value: "3" },
      { label: "KB Article Creation", value: "4" },
      { label: "Unit Upload", value: "5" },
      { label: "Add New Equipment", value: "6" },
      { label: "Ticket Summary Lookup", value: "7"}
    ]
    this.setState({
      displayWidgets: displayWidgets,
      widgetList: widgetList
    })
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  render(): JSX.Element {
    return (
      <>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>Tech Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        <Row>
          {DashboardOrganizer(this.state.displayWidgets, this.state.renderSettings)}
        </Row>
      </>
    )
  }
}