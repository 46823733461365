import { FilterAndSettingField } from "../../CoreComponents/interfaces";

export function getEntityName(
  entityWidth: number, 
  updateWidthSettings: (name: string, value: number) => void, 
  value: string, 
  changeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void, 
  blur: () => void
) : FilterAndSettingField {
  return {
    columnName: 'Entity',
    setting: true,
    width: Math.max(entityWidth, 100),
    widthName: "entityWidth",
    widthCallback: updateWidthSettings,
    filter: {
      type: "text",
      name: 'entityName',
      value: value,
      changeFilter: changeFilter,
      placeHolder: "Filter by Entity",
      blur: blur
    }
  }
}

export function getAccountingFirm(
  setting: boolean, 
  width: number, 
  updateWidthSettings: (name: string, value: number) => void,
  value: string,
  changeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void,
  blur: () => void
): FilterAndSettingField {
  return {
    columnName: "Accounting Firm",
    setting: setting,
    width: width,
    widthName: "accountingFirmWidth",
    widthCallback: updateWidthSettings,
    filter: {
      type: 'text',
      name: 'accountFirm',
      value: value,
      changeFilter: changeFilter,
      placeHolder: "Filter by Firm",
      blur: blur
    }
  }
}

export function getUniqueCode(
  width: number,
  updateWidthSettings: (name: string, value: number) => void,
  setting: boolean
): FilterAndSettingField {
  return {
    columnName: 'Unique Code',
    width: Math.max(width, 100),
    widthName: "yardiCodeWidth",
    widthCallback: updateWidthSettings,
    setting: setting,
    filter: {}
  }
}

export function getYardiAccount(
  width: number,
  updateWidthSettings: (name: string, value: number) => void,
  setting: boolean
): FilterAndSettingField {
  return {
    columnName: "Yardi Account",
    width: Math.max(width, 100),
    widthName: "yardiAccountWidth",
    widthCallback: updateWidthSettings,
    setting: setting,
    filter: {}
  }
}

export function getUnfilteredHeader(
  columnName: string,
  width: number,
  widthName: string,
  updateWidthSettings: (name: string, value: number) => void,
  setting: boolean
): FilterAndSettingField {
  return {
    columnName: columnName,
    width: Math.max(width, 100),
    widthName: widthName,
    widthCallback: updateWidthSettings,
    setting: setting,
    filter: {}
  }
}