import * as React from 'react';
import axios from 'axios';
import { getBearerToken } from '../../../functions/authActions';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { pullEmpireEmployees, pullProperties } from '../../../functions/fetchLinkedObjects';
import { TechEquipmentRegionalManagerApprovalProps, TechEquipmentRegionalManagerApprovalState } from './TechEquipmentRegionalApproval.constants';

export default class TechEquipmentRegionalManagerApproval extends React.Component<TechEquipmentRegionalManagerApprovalProps, TechEquipmentRegionalManagerApprovalState> {

  constructor(props: TechEquipmentRegionalManagerApprovalProps) {
    const params = new URLSearchParams(window.location.search);
    const ticketUID = params.has("uid") ? params.get("uid") : "";

    super(props);
    this.state = {
      uid: ticketUID,
      ticket: undefined,
      headers: []
    }
    this.approve = this.approve.bind(this);
    this.deny = this.deny.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.headers();
    await this.pullTicket();
  }

  async pullTicket(): Promise<void> {
    const employee = await pullEmpireEmployees();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const ticketResponse = await axios.get('./api/tech-equipment-ticket/' + this.state.uid)
    ticketResponse.data.employee = employee.filter(u => u.value === ticketResponse.data.employeeUID)[0]
    this.setState({ ticket: ticketResponse.data })
  }

  headers(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Requestor',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Employee',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Description',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Value',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      }
    ]

    this.setState({ headers: headers })
  }

  async approve(): Promise<void> {
    await axios.patch('./api/tech-equipment-ticket/push-status/' + this.state.uid)
    await this.pullTicket();
  }

  async deny(): Promise<void> {
    await axios.patch('./api/tech-equipment-ticket/push-status/deny/' + this.state.uid)
    await this.pullTicket();
  }


  render(): JSX.Element {
    return (
      <div>{this.state.ticket?.status < 3 && <table className="fixedTable">
        <thead>
          <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
        </thead>
        <tbody>
          <tr>
            <td className="fixedTableCellFirst">{this.state.ticket?.fullName}</td>
            <td className="fixedTableCell">{this.state.ticket?.employee.label}</td>
            <td className="fixedTableCell">{this.state.ticket?.description}</td>
            <td className="fixedTableCell">$ {this.state.ticket?.purchaseValue}</td>
            <td className="fixedTableCell">
              <input type="button" className="standard-input" style={{ width: "100%" }} value="Approve" onClick={() => this.approve()} />
            </td>
            <td className="fixedTableCell">
              <input type="button" className="standard-input" style={{ width: "100%" }} value="Deny" onClick={() => this.deny()} />
            </td>
          </tr>
        </tbody>
      </table>}
        {(this.state.ticket?.status >= 3 && this.state.ticket?.status < 9) && <h2>HAS BEEN APPROVED</h2>}
        {this.state.ticket?.status === 9 && <h2>HAS BEEN DENIED</h2>}
        {(this.state.ticket?.status > 9 || this.state.ticket?.status < 0) && <h2>STATUS ERROR</h2>}
      </div>
    )
  }
}