import * as React from 'react';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../style/select-constants';

export interface FixedSelectOptionsTableProps {
  adding: boolean;
  title: string;
  value: SelectOptions | SelectOptions[];
  values: SelectOptions[];
  isMulti: boolean;
  menuPlacement: "top" | "bottom" | "auto";
  update: (event: any) => void;
  save: () => void;
}

export const FixedSelectOptionsTableRow = (props: FixedSelectOptionsTableProps): JSX.Element => {
  let valueString: string;
  if (Array.isArray(props.value)) {
    valueString = props.value.map(u => u.label).join(", ");
  }
  else {
    valueString = props.value.label;
  }
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.title}</td>
      {!props.adding ?
        <>
          <td className="fixedTableCell">{valueString}</td>
          <td className="fixedTableCell"></td>
        </>
        :
        <>
          <td className="fixedTableCell">
            <Select
              styles={reactSelectBasicStyle}
              value={props.value}
              options={props.values}
              onChange={props.update}
              isMulti={props.isMulti}
              menuPlacement={props.menuPlacement}
            />
          </td>
          <td className="fixedTableCell">
            {!props.adding && <FontAwesomeIcon icon={faSave} style={{ marginLeft: "30%" }} onClick={props.save} />}
          </td>
        </>
      }
    </tr>
  )
}

export interface EditSelectOptionsTableProps {
  adding: boolean;
  editing: boolean;
  title: string;
  value: SelectOptions | SelectOptions[];
  values: SelectOptions[];
  isMulti: boolean;
  menuPlacement: "top" | "bottom" | "auto";
  update: (event: any) => void;
  save: () => void;
}

export const EditSelectOptionsTableRow = (props: EditSelectOptionsTableProps): JSX.Element => {
  let valueString: string;
  if (Array.isArray(props.value)) {
    valueString = props.value.map(u => u.label).join(", ");
  }
  else {
    valueString = props.value.label;
  }
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.title}</td>
      {!(props.editing || props.adding) ?
        <>
          <td className="fixedTableCell">{valueString}</td>
          <td className="fixedTableCell"></td>
        </>
        :
        <>
          <td className="fixedTableCell">
            <Select
              styles={reactSelectBasicStyle}
              value={props.value}
              options={props.values}
              onChange={props.update}
              isMulti={props.isMulti}
              menuPlacement={props.menuPlacement}
            />
          </td>
          <td className="fixedTableCell">
            {!props.adding && <FontAwesomeIcon icon={faSave} style={{ marginLeft: "30%" }} onClick={props.save} />}
          </td>
        </>
      }
    </tr>
  )
}

export interface CheckboxTableProps {
  editing: boolean;
  adding: boolean;
  title: string;
  value: boolean;
  update: (event: any) => void;
  save: () => void;
}

export const CheckboxTableRow = (props: CheckboxTableProps): JSX.Element => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.title}</td>
      {!props.editing ?
        <>
          <td className="fixedTableCell">
            {props.value ? "Yes" : "No"}
          </td>
          <td className="fixedTableCell"></td>
        </>
        :
        <>
          <td className="fixedTableCell">
            <input type="checkbox" checked={props.value} onChange={props.update}
              style={{ marginLeft: "50%" }}
            />
          </td>
          <td className="fixedTableCell">
            {!props.adding && <FontAwesomeIcon icon={faSave} style={{ marginLeft: "30%" }} onClick={props.save} />}
          </td>
        </>
      }
    </tr>
  )
}

interface FixedTextboxTableProps {
  adding: boolean;
  value: string;
  update: (event: any) => void;
  title: string;
}

/**
 * This is dumb, archive it
 * 
 * @param props
 * @returns
 */
export const FixedTextboxTableRow = (props: FixedTextboxTableProps): JSX.Element => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.title}</td>
      {!props.adding ?
        <>
          <td className="fixedTableCell">{props.value}</td>
          <td className="fixedTableCell"></td>
        </>
        :
        <>
          <td className="fixedTableCell">
            <input value={props.value} onChange={props.update} className="standard-input" />
          </td>
          <td className="fixedTableCell"></td>
        </>
      }
    </tr>
  )
}