import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { EMPTY_GUID } from '../../constants/DefaultConstants';
import { CompanyNames_Select, YesNoAll } from '../../constants/EnumConstants';
import { pullEmpireEmployees, pullProperties } from '../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../style/select-constants';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../CoreComponents/CoreTableRows';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { StatusModal } from '../CoreComponents/Modals';

interface EmployeeLicenseRow {
  uid: string;
  employee: SelectOptions;
  company: SelectOptions;
  property: string;
  isTerminated: boolean;
}

interface EmployeeManagementTableRowProps {
  row: EmployeeLicenseRow;
  remove: (index: number) => void;
  index: number;
}

interface EmployeeManagementEditableTableRowProps {
  row: EmployeeLicenseRow;
  index: number;
  cancel: (index: number) => void;
  save: (index: number) => void;
}

interface EmployeeManagementEditableTableRowState {
  row: EmployeeLicenseRow;
  employees: Array<SelectOptions>;
}

interface EmployeeLicenseManagementTableProps {
  licenseUID: string;
  update: (uid: string) => void;
}

interface EmployeeLicenseManagementTableState {
  licenseUID: string;
  headers: Array<FilterAndSettingField>;
  rows: Array<EmployeeLicenseRow>;
  name: string;
  currentIndex: number;
  isTerminated: SelectOptions;
  company: SelectOptions;
  filterName: string;
  properties: SelectOptions[];
  propertyList: SelectOptions[];
}

function compare(a: EmployeeLicenseRow, b: EmployeeLicenseRow) {
  if (a.employee.label < b.employee.label) {
    return -1;
  }
  if (a.employee.label > b.employee.label) {
    return 1;
  }
  return 0;
}

class EmployeeManagementTableRow extends React.Component<EmployeeManagementTableRowProps, {}> {

  constructor(props: EmployeeManagementTableRowProps) {
    super(props);
    this.state = {

    }
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.props.row.employee.label}</td>
        <td className="fixedTableCell">{this.props.row.company?.label}</td>
        <td className="fixedTableCell">{this.props.row.isTerminated ? "Yes" : "No"}</td>
        <td className="fixedTableCell">{this.props.row.property}</td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faTrash} onClick={() => this.props.remove(this.props.index)} style={{ marginLeft: "30%" }} />
        </td>
      </tr>
    )
  }
}

class EmployeeManagementEditableTableRow extends React.Component<EmployeeManagementEditableTableRowProps, EmployeeManagementEditableTableRowState> {

  constructor(props: EmployeeManagementEditableTableRowProps) {
    super(props);
    this.state = {
      row: props.row,
      employees: [],
    }
    this.changeEmployee = this.changeEmployee.bind(this);
    this.changeCompany = this.changeCompany.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let employees = await pullEmpireEmployees();
    this.setState({
      employees: employees
    })
  }

  changeEmployee(event: SelectOptions | null) {
    if (event === null) { return; }
    let row = this.state.row;
    row.employee = event;
    this.setState({
      row: row
    }, () => this.getCompany())
  }

  changeCompany(event: SelectOptions | null) {
    if (event === null) { return; }
    let row = this.state.row;
    row.company = event;
    this.setState({
      row: row
    })
  }

  async getCompany(): Promise<void> {
    let employeeUID = this.state.row.employee.value;
    await axios.get('./api/empire/auth/' + employeeUID).then((response) => {
      let row = this.state.row;
      row.company = CompanyNames_Select[response.data.company+1];
      this.setState({
        row: row
      })
    });
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <Select
            options={this.state.employees}
            value={this.state.row.employee}
            styles={reactSelectBasicStyle}
            onChange={this.changeEmployee}
            menuPlacement={this.props.index > 5 ? "top" : "bottom"}
          />
        </td>
        <td className="fixedTableCell">
          <Select
            options={CompanyNames_Select}
            value={this.state.row.company}
            styles={reactSelectBasicStyle}
            onChange={this.changeCompany}
            menuPlacement={this.props.index > 5 ? "top" : "bottom"}
          />
        </td>
        <td className="fixedTableCell">
          {this.state.row.uid === "" && <FontAwesomeIcon icon={faTrash} style={{ marginLeft: "30%" }} onClick={() => this.props.cancel(this.props.index)} />}
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faSave} style={{ marginLeft: "30%" }} onClick={() => this.props.save(this.props.index)} />
        </td>
      </tr>
    )
  }
}

export class EmployeeLicenseManagementTable extends React.Component<EmployeeLicenseManagementTableProps, EmployeeLicenseManagementTableState>{

  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  constructor(props: EmployeeLicenseManagementTableProps) {
    super(props);
    this.state = {
      headers: [],
      rows: [],
      currentIndex: -1,
      name: "",
      licenseUID: props.licenseUID,
      isTerminated: { label: "All", value: "0" },
      company: { label: "All", value: "-1" },
      filterName: "",
      properties: [],
      propertyList: []
    }
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.generateETL = this.generateETL.bind(this);
    this.changeCompany = this.changeCompany.bind(this);
    this.changeIsTerminated = this.changeIsTerminated.bind(this);
    this.changeFilterName = this.changeFilterName.bind(this);
    this.changePropertyList = this.changePropertyList.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();
    this.setState({ properties: properties });
    await this.generateHeaders();
    let response = await axios.get('./api/user-license/' + this.state.licenseUID);
    if (response.status === 200){
      this.setState({
        name: response.data.description,
      })
    }
  }

  async pullEmployeeLicenses(): Promise<void> {
    const data = {
      page: 0,
      pageCount: 0,
      company: this.state.company.value,
      isTerminated: parseInt(this.state.isTerminated.value),
      licenseUID: this.state.licenseUID,
      name: this.state.filterName === "" ? "null" : this.state.filterName,
      properties: this.state.propertyList.map(u => u.value)
    }
    let response = await axios.post('./api/employee-license-map/license/' + this.state.licenseUID, data);
    if (response.status === 200) {
      let rows: Array<EmployeeLicenseRow> = [];
      response.data.forEach(function (item: any) {
        let company = item.companyOverride === -1 ? item.employee?.company : item.companyOverride ?? -1;
        rows.push({
          employee: { label: item.employee.fullName, value: item.employee.uid },
          company: CompanyNames_Select[company + 1],
          isTerminated: item.employee.isTerminated,
          uid: item.uid,
          property: item.employee.employeeMaps.map((item: any) => item.property.name).join(',')
        })
      });
      rows.sort(compare);
      this.setState({
        rows: rows
      })
    }

  }

  async generateHeaders(): Promise<void> {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Employee",
        setting: true,
        filter: {
          name: "filterName",
          value: this.state.filterName,
          changeFilter: this.changeFilterName,
          type: "text",
          placeHolder: "Filter by Employee Name"
        }
      },
      {
        columnName: "Company",
        setting: true,
        filter: {
          name: "company",
          value: this.state.company,
          changeFilter: this.changeCompany,
          options: CompanyNames_Select,
          multi: false,
          blur: () => true
        }
      },
      {
        columnName: "Is Terminated",
        setting: true,
        filter: {
          name: "isTerminated",
          value: this.state.isTerminated,
          changeFilter: this.changeIsTerminated,
          options: YesNoAll,
          multi: false,
          blur: () => true
        },
        additionalClasses: "ten"
      },
      {
        columnName: "Properties",
        setting: true,
        filter: {
          name: "propertyList",
          value: this.state.propertyList,
          changeFilter: this.changePropertyList,
          options: this.state.properties,
          multi: true,
          blur: () => true
        }
      },
      {
        columnName: "",
        additionalClasses: "icon",
        setting: true,
        filter: {}
      }
    ]
    this.setState({
      headers: headers
    })
    await this.pullEmployeeLicenses();
  }

  add(): void {
    let rows = this.state.rows;
    rows.push({
      employee: { label: "Select Employee", value: "" },
      company: { label: "Company Will Change When Employee Is Selected", value: "-1" },
      uid: "",
      property: "Property won't load until refresh",
      isTerminated: false
    })
    this.setState({
      currentIndex: rows.length - 1,
      rows: rows
    })
  }

  async remove(index: number): Promise<void> {
    let rows = this.state.rows;
    let row = rows[index];
    rows.splice(index, 1);
    if (row.uid !== "") {
      //TODO: Update Database
      await axios.delete('./api/employee-license-map/' + row.uid).then(() => {})
    }
    this.setState({
      rows: rows
    })
  }

  cancel(index: number): void {
    this.state.rows.splice(index, 1);
    this.setState({
      currentIndex: -1
    })
  }

  async save(index: number): Promise<void> {
    let row = this.state.rows[index];
    if (row.uid === "") {
      // UPDATE DATABASE
      // CREATE ONLY, PATCH EVERYTHING ELSE
      let data = {
        uid: EMPTY_GUID,
        EmpireEmployeeUID: row.employee.value,
        licenseUID: this.state.licenseUID,
        companyOverride: row.company.value
      };
      await axios.post('./api/employee-license-map', data).then((response) => {
        row.uid = response.data.uid;
        this.state.rows[index] = row;
      })
    }
    this.setState({
      currentIndex: -1
    })
  }

  async generateETL(): Promise<void> {
    let formData = new FormData();
    let key = await axios.get('./api/status-manager');
    this.statusModal.current.display("Generating ETL Export", "Please wait. This can take a minute due to the amount of users we have in the system", key.data)
    let response = await axios.post('./api/user-license/export/' + this.state.licenseUID + "/" + key.data, formData, { validateStatus: () => true, responseType: "blob" });
    this.statusModal.current.hide();
    if (response.status === 200) {
      const FileDownload = require('js-file-download');
      const content = response.headers['content-disposition'];
      const startIndex = content.lastIndexOf('\'\'') + 2;
      const name = content.substring(startIndex, content.length);
      FileDownload(response.data, decodeURI(name));
    }
    else {
      // need to handle error
    }
  }

  async changeIsTerminated(event: SelectOptions | null): Promise<void> {
    this.setState({ isTerminated: event }, this.generateHeaders);
  }

  async changeCompany(event: SelectOptions | null): Promise<void> {
    this.setState({ company: event }, this.generateHeaders);
  }

  async changeFilterName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ filterName: event.target.value }, this.generateHeaders);
  }

  async changePropertyList(event: SelectOptions[] | null): Promise<void> {
    this.setState({ propertyList: event }, this.generateHeaders);
  }

  render(): JSX.Element {
    return (
      <div>
        <StatusModal ref={this.statusModal} />
        <Row style={{ marginTop: "2vh" }}>
          <Col>
            <h4>Employee License Assignments - {this.state.name}</h4>
          </Col>
          <Col xs='1'>
            <input type="button" className="standard-input" value="Close" onClick={() => this.props.update("")} style={{ float: "right" }} />
          </Col>
        </Row>
        <div style={{ height: "69vh",  overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: EmployeeLicenseRow, index: number) => (
                  (this.state.currentIndex === -1 || index < this.state.currentIndex) &&
                  <EmployeeManagementTableRow key={item.uid} row={item} remove={this.remove} index={index} />
                ))
              }
              {this.state.currentIndex !== -1 ?
                <EmployeeManagementEditableTableRow row={this.state.rows[this.state.currentIndex]}
                  cancel={this.cancel} save={this.save} index={this.state.currentIndex}
                /> :
                <TableAdditionRow length={5} marginLeft="30%" onClick={this.add} />
              }
              {
                this.state.rows.map((item: EmployeeLicenseRow, index: number) => (
                  (this.state.currentIndex !== -1 && index > this.state.currentIndex) &&
                  <EmployeeManagementTableRow key={item.uid} row={item} remove={this.remove} index={index} />
                ))
              }
            </tbody>
          </table>
        </div>
        <input type="button" value="Generate ETL" className="standard-input" onClick={this.generateETL} style={{ marginTop: "1vh"} } />
      </div>
    )
  }
}
