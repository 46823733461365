import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SelectOptions } from "../../../../interfaces/CoreInterfaces";
import { StreetAddress } from '../../../CoreComponents/interfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../../functions/selectTools';
import axios from 'axios';
import { getUserID } from '../../../../functions/authActions';

interface EntityRequestEditModalProps {

}

interface EntityRequestEditModalState {
  showing: boolean;
  editingField: string;
  editingStringData: string;
  editingSelectOptions: Array<SelectOptions>;
  editingSelectData: SelectOptions;
  editingMultiSelectData: Array<SelectOptions>;
  editingAddressData: StreetAddress;
  explanation: string;
  saveType: string;
  draftUID: string;
  trackingType: number;
}

function compareTwoArray(oldA: Array<any>, newA: Array<any>) {
  let returnObj = {
    //@ts-ignore
    item: undefined,
    //@ts-ignore
    added: undefined
  }

  if (newA.length === 0) {
    returnObj.item = oldA[0];
    returnObj.added = false;
  }
  else if (oldA.length < newA.length) {
    newA.forEach((item) => {
      if (!oldA.includes(item)) {
        returnObj.item = item;
        returnObj.added = true;
      }
    })
  }
  else if (oldA.length > newA.length) {
    oldA.forEach((item) => {
      if (!newA.includes(item)) {
        returnObj.item = item;
        returnObj.added = false;
      }
    })
  }

  return returnObj;
}

export default class EntityRequestEditModal extends React.Component<EntityRequestEditModalProps, EntityRequestEditModalState>{

  constructor(props: EntityRequestEditModalProps) {
    super(props);
    this.state = {
      showing: false,
      editingField: "",
      editingStringData: "",
      editingSelectOptions: [],
      editingSelectData: { label: "", value: "" },
      editingMultiSelectData: [],
      editingAddressData: {
        address: "",
        city: "",
        state: { label: "", value: "" },
        zipcode: ""
      },
      explanation: "",
      saveType: "",
      draftUID: "",
      trackingType: -1
    }
    this.showStringEdit = this.showStringEdit.bind(this);
    this.changeStringData = this.changeStringData.bind(this);
    this.changeSelectData = this.changeSelectData.bind(this);
    this.changeAddressStringData = this.changeAddressStringData.bind(this);
    this.changeCityStringData = this.changeCityStringData.bind(this);
    this.changeStateSelectData = this.changeStateSelectData.bind(this);
    this.changeZipCodeStringData = this.changeZipCodeStringData.bind(this);
    this.changeMultiSelectData = this.changeMultiSelectData.bind(this);
    this.changeExplanation = this.changeExplanation.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(): void {

  }

  componentDidUpdate(): void {

  }

  showStringEdit(uid: string, field: string, data: string, saveType: string): void {
    this.setState({
      showing: true,
      editingField: field,
      editingStringData: data,
      saveType: saveType,
      draftUID: uid
    })
  }

  showSelectEdit(uid: string, field: string, data: SelectOptions, saveType: string, options: Array<SelectOptions>): void {
    this.setState({
      showing: true,
      draftUID: uid,
      editingField: field,
      editingSelectOptions: options,
      editingSelectData: data,
      saveType: saveType,
    })
  }

  showMultiSelectEdit(uid: string, field: string, data: Array<SelectOptions>, saveType: string, options: Array<SelectOptions>): void {
    this.setState({
      showing: true,
      draftUID: uid,
      editingField: field,
      editingSelectOptions: options,
      editingMultiSelectData: data,
      saveType: saveType,
    })
  }

  showAddressEdit(uid: string, field: string, data: StreetAddress, saveType: string, options: Array<SelectOptions>): void {
    this.setState({
      showing: true,
      draftUID: uid,
      editingField: field,
      editingSelectOptions: options,
      editingAddressData: data,
      saveType: saveType,
    })
  }

  changeStringData(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ editingStringData: event.target.value })
  }

  changeSelectData(event: SelectOptions | null): void {
    this.setState({ editingSelectData: event })
  }

  async changeMultiSelectData(event: Array<SelectOptions> | null): Promise<void> {
    this.setState({ editingMultiSelectData: event })

    if (this.state.saveType === 'ProjectLeads' ||
      this.state.saveType === 'FinanceLeads' ||
      this.state.saveType === 'RequiredReviewers' ||
      this.state.saveType === 'CCList'
    ) {
      const compared = compareTwoArray(this.state.editingMultiSelectData, event);
      const data = {
        NewEntityUID: this.state.draftUID,
        EmployeeUID: compared.item.value
      }

      if (this.state.saveType === 'ProjectLeads') {
        if (compared.added) {
          await axios.patch("./api/new-entity-request/add-project-lead", data).then((response) => {
          })
        }
        else if (!compared.added) {
          await axios.patch("./api/new-entity-request/remove-project-lead", data).then((response) => {
          })
        }
      }
      else if (this.state.saveType === 'FinanceLeads') {
        if (compared.added) {
          await axios.patch("./api/new-entity-request/add-finance-lead", data).then((response) => {
          })
        }
        else if (!compared.added) {
          await axios.patch("./api/new-entity-request/remove-finance-lead", data).then((response) => {
          })
        }
      }
      else if (this.state.saveType === 'RequiredReviewers') {
        if (compared.added) {
          await axios.patch("./api/new-entity-request/add-required-reviewer", data).then((response) => {
          })
        }
        else if (!compared.added) {
          await axios.patch("./api/new-entity-request/remove-required-reviewer", data).then((response) => {
          })
        }
      }
      else if (this.state.saveType === 'CCList') {
        if (compared.added) {
          await axios.patch("./api/new-entity-request/add-cc-list", data).then((response) => {
          })
        }
        else if (!compared.added) {
          await axios.patch("./api/new-entity-request/remove-cc-list", data).then((response) => {
          })
        }
      }
    }
  }

  changeAddressStringData(event: React.ChangeEvent<HTMLInputElement>): void {
    const address = this.state.editingAddressData;
    address.address = event.target.value
    this.setState({ editingAddressData: address })
  }

  changeCityStringData(event: React.ChangeEvent<HTMLInputElement>): void {
    const address = this.state.editingAddressData;
    address.city = event.target.value
    this.setState({ editingAddressData: address })
  }

  changeStateSelectData(event: SelectOptions | null): void {
    const address = this.state.editingAddressData;
    address.state = event
    this.setState({ editingAddressData: address })
  }

  changeZipCodeStringData(event: React.ChangeEvent<HTMLInputElement>): void {
    const address = this.state.editingAddressData;
    address.zipcode = event.target.value
    this.setState({ editingAddressData: address })
  }

  changeExplanation(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ explanation: event.target.value })
  }

  async submit(): Promise<void> {
    let tracking = -1;

    if (this.state.editingStringData !== "") {
      const patchData = {
        UID: this.state.draftUID,
        [this.state.saveType]: this.state.editingStringData
      }

      if (this.state.saveType === 'EntityName') {
        await axios.patch("./api/new-entity-request/update-name", patchData).then(function (response) {
        })
        tracking = 1;
      }
      else if (this.state.saveType === 'InitialCapital') {
        const data = {
          UID: this.state.draftUID,
          InitialCapital: parseFloat(this.state.editingStringData)
        }
        await axios.patch("./api/new-entity-request/update-initial-capital", data).then(function (response) {
        })
        tracking = 6;
      }
      else if (this.state.saveType === 'President') {
        await axios.patch("./api/new-entity-request/update-president", patchData).then(function (response) {
        })
        tracking = 12;
      }
      else if (this.state.saveType === 'VicePresident') {
        await axios.patch("./api/new-entity-request/update-vice-president", patchData).then(function (response) {
        })
        tracking = 13;
      }
      else if (this.state.saveType === 'Secretary') {
        await axios.patch("./api/new-entity-request/update-secretary", patchData).then(function (response) {
        })
        tracking = 14;
      }
      else if (this.state.saveType === 'Treasurer') {
        await axios.patch("./api/new-entity-request/update-treasurer", patchData).then(function (response) {
        })
        this.setState({ trackingType: 15 })
      }
    }
    else if (this.state.editingSelectData.value !== "") {
      const patchData = {
        UID: this.state.draftUID,
        [this.state.saveType]: this.state.editingSelectData.value
      }

      if (this.state.saveType === 'Type') {
        await axios.patch("./api/new-entity-request/update-type", patchData).then(function (response) {
        })
        tracking = 2;
      }
      else if (this.state.saveType === 'EntityPurpose') {
        if (this.state.editingSelectData.value === "0") {
          const data = {
            UID: this.state.draftUID,
            [this.state.saveType]: this.state.editingSelectData.value,
            InitialCapital: 2000
          }
          await axios.patch("./api/new-entity-request/update-purpose", data).then(function (response) {
          })
        }
        else if (this.state.editingSelectData.value === "1") {
          const data = {
            UID: this.state.draftUID,
            [this.state.saveType]: this.state.editingSelectData.value,
            InitialCapital: 0
          }
          await axios.patch("./api/new-entity-request/update-purpose", data).then(function (response) {
          })
        }
        tracking = 3;
      }
      else if (this.state.saveType === 'FormationState') {
        await axios.patch("./api/new-entity-request/update-formation-state", patchData).then(function (response) {
        })
        tracking = 4;
      }
      else if (this.state.saveType === 'SCorpElection') {
        await axios.patch("./api/new-entity-request/update-scorp", patchData).then(function (response) {
        })
        tracking = 16;
      }
      else if (this.state.saveType === 'EntityManagementType') {
        await axios.patch("./api/new-entity-request/update-management-type", patchData).then(function (response) {
        })
        tracking = 17;
      }
      else if (this.state.saveType === 'RegisteredAgentUID') {
        await axios.patch("./api/new-entity-request/add-registered-agent", patchData).then(function (response) {
        })
        tracking = 18;
      }
    }
    else if (this.state.editingMultiSelectData.length > 0) {
      if (this.state.saveType === 'SosRegistrationStates') {
        const values: Array<any> = [];
        this.state.editingMultiSelectData.forEach((item) => {
          if (parseInt(item.value) > 0) {
            values.push(parseInt(item.value))
          }
        })
        const data = {
          UID: this.state.draftUID,
          SosRegistrationStates: values
        }
        await axios.patch("./api/new-entity-request/update-foreign-sos", data).then(function (response) {
        })
        this.setState({ trackingType: 5 })
      }
      else if (this.state.saveType === 'ProjectLeads') {
        tracking = 8;
      }
      else if (this.state.saveType === 'FinanceLeads') {
        tracking = 9;
      }
      else if (this.state.saveType === 'RequiredReviewers') {
        tracking = 10;
      }
      else if (this.state.saveType === 'CCList') {
        tracking = 11;
      }
    }
    else if (this.state.editingAddressData.address !== "") {
      const addressData = {
        StreetAddress: this.state.editingAddressData.address,
        City: this.state.editingAddressData.city,
        State: this.state.editingAddressData.state.value,
        ZipCode: this.state.editingAddressData.zipcode
      }
      const data = {
        UID: this.state.draftUID,
        Address: addressData
      }
      await axios.patch("./api/new-entity-request/update-address", data).then((response) => {
      })
      tracking = 7;
    }

    const trackingData = {
      NewEntityUID: this.state.draftUID,
      FleetEmployeeUID: getUserID(),
      Notes: this.state.explanation,
      EntityTrackingType: tracking
    }

    await axios.post("./api/new-entity-tracking-information", trackingData).then(function (response) {
      window.location.reload()
    })

    this.setState({
      showing: false,
      editingField: "",
      editingStringData: "",
      editingSelectData: { label: "", value: "" },
      editingMultiSelectData: [],
      explanation: "",
      saveType: "",
      draftUID: ""
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderRadius: "15%" }}>
        <div style={{ background: "#4c4a42", color: "#c2a877" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ showing: false })}>
            <div style={{ color: "#c2a877" }}>
              {"Editing " + this.state.editingField}
            </div>
          </ModalHeader>
          <ModalBody>
            Data to Change
            {this.state.editingStringData !== "" && <input type="text" className="standardInput" value={this.state.editingStringData} onChange={this.changeStringData} />}
            {this.state.editingSelectData.value !== "" &&
              <Select
                options={this.state.editingSelectOptions}
                styles={reactSelectBasicStyle}
                value={this.state.editingSelectData}
                onChange={this.changeSelectData}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />}
            {(this.state.editingMultiSelectData.length > 0 ||
              this.state.saveType === 'ProjectLeads' ||
              this.state.saveType === 'FinanceLeads' ||
              this.state.saveType === 'RequiredReviewers' ||
              this.state.saveType === 'CCList'
            ) &&
              <Select
                options={this.state.editingSelectOptions}
                styles={reactSelectBasicStyle}
                value={this.state.editingMultiSelectData}
                onChange={this.changeMultiSelectData}
                isMulti
                isClearable={false}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />}
            {this.state.editingAddressData.address !== '' &&
              <div>
                <input type="text" className="standardInput" value={this.state.editingAddressData.address} onChange={this.changeAddressStringData} />
                <input type="text" className="standardInput" value={this.state.editingAddressData.city} onChange={this.changeCityStringData} />
                <Select
                  options={this.state.editingSelectOptions}
                  styles={reactSelectBasicStyle}
                  value={this.state.editingAddressData.state}
                  onChange={this.changeStateSelectData}
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
                <input type="text" className="standardInput" value={this.state.editingAddressData.zipcode} onChange={this.changeZipCodeStringData} />
              </div>}
            <br />
            Explanation
            <input type="text" className="standardInput" value={this.state.explanation} onChange={this.changeExplanation} />
          </ModalBody>
          <ModalFooter>
            {(this.state.saveType !== 'ProjectLeads' &&
              this.state.saveType !== 'FinanceLeads' &&
              this.state.saveType !== 'RequiredReviewers' &&
              this.state.saveType !== 'CCList'
            ) && <input type="button" className="standard-input" value="Cancel"
              onClick={() => this.setState({
                showing: false,
                editingField: "",
                editingStringData: "",
                editingSelectData: { label: "", value: "" },
                editingMultiSelectData: [],
                editingAddressData: {
                  address: "",
                  city: "",
                  state: { label: "", value: "" },
                  zipcode: ""
                },
                explanation: "",
                saveType: "",
                draftUID: ""
              })} />}
            <input type="button" value="Submit" className="standard-input" onClick={this.submit} />
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}