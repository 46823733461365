import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { PropertyFundingTypes, PropertyTypes, RelatedPortfolios_Select } from '../../../constants/EnumConstants';
import { pullStates } from '../../../functions/fetchLinkedObjects';
import axios from 'axios';

interface PropertyOnboardingModalProps {
  callback: (success: boolean) => void;
}

interface PropertyOnboardingModalState {
  show: boolean;
  propertyName: string;
  propertyCode: string;
  propertyType: SelectOptions;
  relatedPortfolio: SelectOptions;
  units: number;
  address: string;
  city: string;
  state: SelectOptions;
  states: Array<SelectOptions>;
  zipCode: string;
  fundingTypes: Array<SelectOptions>;
}

export default class PropertyOnboardingModal extends React.Component<PropertyOnboardingModalProps, PropertyOnboardingModalState> {

  constructor(props: PropertyOnboardingModalProps) {
    super(props);

    this.state = {
      show: false,
      propertyName: "",
      propertyCode: "",
      propertyType: { label: "Select Type", value: "" },
      relatedPortfolio: { label: "Select Related Portfolio", value: "" },
      units: 0,
      address: "",
      city: "",
      state: { label: "Select State", value: "" },
      states: [],
      zipCode: "",
      fundingTypes: []
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.changePropertyName = this.changePropertyName.bind(this);
    this.changePropertyCode = this.changePropertyCode.bind(this);
    this.changePropertyType = this.changePropertyType.bind(this);
    this.changeRelatedPortfolio = this.changeRelatedPortfolio.bind(this);
    this.changeUnits = this.changeUnits.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changeState = this.changeState.bind(this);
    this.changeZipCode = this.changeZipCode.bind(this);
    this.changeFundingTypes = this.changeFundingTypes.bind(this);
    this.save = this.save.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const states = await pullStates();
    this.setState({ states: states });
  }

  changePropertyName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ propertyName: event.target.value });
  }

  changePropertyCode(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ propertyCode: event.target.value });
  }

  changePropertyType(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ propertyType: event });
  }

  changeRelatedPortfolio(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ relatedPortfolio: event });
  }

  changeUnits(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ units: parseInt(event.target.value) });
  }

  changeAddress(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ address: event.target.value });
  }

  changeCity(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ city: event.target.value });
  }

  changeState(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ state: event });
  }

  changeZipCode(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ zipCode: event.target.value });
  }

  changeFundingTypes(event: SelectOptions[] | null): void {
    if (event === null) { return; }
    this.setState({ fundingTypes: event });
  }

  async save(): Promise<void> {
    const data = {
      propertyName: this.state.propertyName,
      propertyCode: this.state.propertyCode,
      propertyType: this.state.propertyType.value,
      relatedPortfolio: this.state.relatedPortfolio.value,
      unitCount: this.state.units,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state.value,
      zipCode: this.state.zipCode,
      fundingTypes: JSON.stringify(this.state.fundingTypes.map((u => u.value)))
    };
    let response = await axios.post('/api/property/start-onboard', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.props.callback(true)
    }
    else {
      this.props.callback(false)
    }
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  hide(): void {
    this.setState({
      show: false
    }, () => window.location.reload());
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Start Property Onboarding    
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Property Name</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.propertyName} className="standard-input" onChange={this.changePropertyName} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Property Code</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.propertyCode} className="standard-input" onChange={this.changePropertyCode} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Property Type</h5>
              </Col>
              <Col>
                <Select
                  id="property_type"
                  name="property_type"
                  value={this.state.propertyType}
                  styles={reactSelectBasicStyle}
                  options={PropertyTypes}
                  onChange={this.changePropertyType}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Related Portfolio</h5>
              </Col>
              <Col>
                <Select
                  id="portfolio"
                  name="relatedPortfolio"
                  value={this.state.relatedPortfolio}
                  options={RelatedPortfolios_Select}
                  styles={reactSelectBasicStyle}
                  onChange={this.changeRelatedPortfolio}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Units</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.units} className="standard-input" onChange={this.changeUnits} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Address</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.address} className="standard-input" onChange={this.changeAddress}  />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>City</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.city} className="standard-input" onChange={this.changeCity}  />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>State</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.state}
                  styles={reactSelectBasicStyle}
                  options={this.state.states}
                  defaultValue={{ label: "Oregon", value: "36" }}
                  onChange={this.changeState}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>ZipCode</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.zipCode} className="standard-input" onChange={this.changeZipCode} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='5'>
                <h5 style={{ marginTop: "7px" }}>Funding Types</h5>
              </Col>
              <Col>
                <Select
                  options={PropertyFundingTypes}
                  value={this.state.fundingTypes}
                  onChange={this.changeFundingTypes}
                  styles={reactSelectBasicStyle}
                  isMulti
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <input type="button" value="Start Onboarding" className="standard-input" onClick={this.save} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}