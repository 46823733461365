import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { DocumentViewModal } from '../../CoreComponents/Modals/DocumentViewModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SelectOptions, SelectOptionsProperty } from '../../../interfaces/CoreInterfaces';
import { pullProperties } from '../../../functions/fetchLinkedObjects';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { DatePicker } from '../../CoreComponents/DateComponents';
import axios from 'axios';
import { getUserID } from '../../../functions/authActions';
import { FilterWithPropertyCode } from '../../../functions/selectTools';

interface SiteVisitTrackingData {
  propertyName: string;
  visitDate: Date;
  documentUID: string;
  employee: string;
}

interface SiteVisitTrackingWidgetProps {
  propertyUID: string;
}

interface SiteVisitTrackingWidgetState {
  rows: Array<SiteVisitTrackingData>
}

export default class SiteVisitTrackingWidget extends React.Component<SiteVisitTrackingWidgetProps, SiteVisitTrackingWidgetState> {

  documentModal: React.RefObject<DocumentViewModal> = React.createRef<DocumentViewModal>();
  addModal: React.RefObject<AddNewSiteVisitModal> = React.createRef<AddNewSiteVisitModal>();

  headers: Array<FilterAndSettingField> = [
    { columnName: "Property Name", setting: true, filter: {} },
    { columnName: "Date", setting: true, filter: {} },
    { columnName: "Staff", setting: true, filter: {}},
    { columnName: "Document", setting: true, filter: {} }
  ]

  constructor(props: SiteVisitTrackingWidgetProps) {
    super(props);
    this.state = {
      rows: []
    }
    this.addRow = this.addRow.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let response = await axios.get('./api/site-visit-tracking/property/' + this.props.propertyUID, { validateStatus: () => true });
    if (response.status === 200) {
      console.log(response.data);
      this.setState({
        rows: response.data
      });
    }
  }

  addRow(): void {
    this.addModal.current.show();
  }

  render(): JSX.Element {
    return (
      <div>
        <DocumentViewModal ref={this.documentModal} />
        <AddNewSiteVisitModal ref={this.addModal} />
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.rows.map((item) => (
                <tr key={item.documentUID}>
                  <td className="fixedTableCellFirst">{item.propertyName}</td>
                  <td className="fixedTableCell">{new Date(item.visitDate).toLocaleDateString("en-CA")}</td>
                  <td className="fixedTableCell">{item.employee}</td>
                  <td className="fixedTableCell">
                    <FontAwesomeIcon icon={faBinoculars} style={{ marginLeft: "30%" }} onClick={() => this.documentModal.current.show(item.documentUID)} />
                  </td>
                </tr>
              ))
            }
            <TableAdditionRow length={4} marginLeft="30%" onClick={this.addRow} />
          </tbody>
        </table>
      </div>
    )
  }
}

interface AddNewSiteVisitModalState {
  showing: boolean;
  properties: Array<SelectOptionsProperty>;
  property: SelectOptions;
  date: Date;
  file: File;
}

class AddNewSiteVisitModal extends React.Component<{}, AddNewSiteVisitModalState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      properties: [],
      property: { label: "Select Property", value: "" },
      date: new Date(),
      file: null
    }
    this.changeProperty = this.changeProperty.bind(this);
    this.changeVisitDate = this.changeVisitDate.bind(this);
    this.changeSiteVisitDocument = this.changeSiteVisitDocument.bind(this);
    this.save = this.save.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();
    this.setState({
      properties: properties,
    })
  }

  show(): void {
    this.setState({
      showing: true
    })
  }

  changeProperty(event: SelectOptions | null) { 
    if (event === null) { return; }
    this.setState({
      property: event
    })
  }

  changeVisitDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      date: event.target.valueAsDate
    })
  }

  changeSiteVisitDocument(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      file: event.target.files[0]
    })
  }

  async save(): Promise<void> {
    var formInfo = new FormData();
    formInfo.append("PropertyUID", this.state.property.value);
    formInfo.append("Document", this.state.file);
    formInfo.append("EmployeeUID", getUserID());
    formInfo.append("VisitDate", this.state.date.toISOString());
    let response = await axios.post('./api/site-visit-tracking/', formInfo, { validateStatus: () => true });
    if (response.status !== 202) {
      //TODO: Error handling
    }
    else {
      window.location.reload();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderBottom: "0px solid #15405c", borderRadius: "25%" }}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ showing: false})} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Upload Site Visit
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <label>Property</label>
            <Select 
              options={this.state.properties}
              value={this.state.property}
              styles={reactSelectBasicStyle}
              onChange={this.changeProperty}
              filterOption={FilterWithPropertyCode}
            />
            <hr />
            <label>Date</label>
            <DatePicker key={"date"} value={this.state.date} onChange={this.changeVisitDate} />
            <hr />
            <label>Site Visit Checklist</label>
            <br />
            <input type="file" className="standard-input" style={{ border: "solid", height: "20vh", paddingLeft: "18%", paddingTop: "7vh" }} onChange={this.changeSiteVisitDocument} />
            <hr />
          </ModalBody>
          <ModalFooter style={{ border: "0px solid black" }}>
            <input type="button" style={{ height: "5vh", marginTop: "-2vh" }} className="standard-input" value="Save Visit" onClick={this.save} />
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}