import * as React from 'react'
import { CNAInspectedUnitsWidgetProps, CNAInspectedUnitsWidgetState, UnitData } from './CNAInspectedUnitsWidget.constants';
import { StatusModal, WarningModal } from '../../../CoreComponents/Modals';
import { FilterAndSettingField } from '../../../CoreComponents/interfaces';
import axios from 'axios';
import { getUserID } from '../../../../functions/authActions';
import FilteredWithSettingsTableHeader from '../../../CoreComponents/CoreTableHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog } from '@fortawesome/free-solid-svg-icons';

export default class CNAInspectedUnitsWidget extends React.Component<CNAInspectedUnitsWidgetProps, CNAInspectedUnitsWidgetState> {

  statusRef: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningRef: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: CNAInspectedUnitsWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      cnaUID: props.cnaUID,
      units: []
    }
    this.showCNAUnitEditModal = this.showCNAUnitEditModal.bind(this)
    this.updateUnit = this.updateUnit.bind(this)
  }

  async componentDidMount(): Promise<void> {
    this.getHeaders();
    await this.getUnits()
  }

  getHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Unit Number',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Size',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Fully ADA',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Comments',
        additionalClasses: "forty",
        setting: true,
        filter: {

        }
      }
    ]
    this.setState({ headers: headers })
  }

  async getUnits(): Promise<void> {
    const units: Array<UnitData> = []
    const response = await axios.get('./api/capital-needs-assessment/property/units/' + this.state.cnaUID)

    if (response.status === 200) {
      response.data.property.buildings.forEach((building: any) => {
        building.units.forEach((unit: any) => {
          units.push({
            uid: unit.uid,
            name: unit.name,
            size: unit.bedrooms,
            isADA: unit.isADA,
            cnaComments: unit.cnaComments
          })
        })
      })
    }
    else {
      //TODO ERROR HANDLE
    }
    this.setState({ units: units })
  }

  showCNAUnitEditModal(unit: UnitData): void {
    //this.editUnitModal.current.show(
    //  "Editing Unit: " + unit.name,
    //  unit,
    //  this.updateUnit
    //)
  }

  async updateUnit(newUnit: UnitData, oldUnit: UnitData): Promise<void> {

    const data = {
      UID: newUnit.uid,
      IsADA: newUnit.isADA,
      CNAComments: newUnit.cnaComments
    }
    await axios.patch('./api/units/cna-update', data)

    const changeData = {
      CNAUID: this.state.cnaUID,
      FleetEmployeeUID: await getUserID(),
      OldData: oldUnit.name + " - Unit Edited -" + "ADA Status: " + oldUnit.isADA + " | Old Comments: " + oldUnit.cnaComments,
      NewData: newUnit.name + " - Updated -" + "ADA Status: " + newUnit.isADA + " | Old Comments: " + newUnit.cnaComments
    }
    await axios.post('./api/cna-change-log', changeData)
  }

  render(): JSX.Element {
    return (
      <div>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
          </thead>
          <tbody>
            <>
              {
                this.state.units.map((unit, idx) => (
                  <tr key={idx}>
                    <td className="fixedTableCellFirst">{idx + 1}</td>
                    <td className="fixedTableCell">{unit.name}</td>
                    <td className="fixedTableCell">{unit.size}</td>
                    <td className="fixedTableCell">{unit.isADA && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: "30%" }} />}</td>
                    <td className="fixedTableCell">{unit.cnaComments}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faCog} style={{ marginLeft: "30%" }} onClick={() => this.showCNAUnitEditModal(unit)} />
                    </td>
                  </tr>
                ))
              }
            </>
          </tbody>
        </table>
      </div>
    )
  }
}