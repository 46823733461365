import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { pullMCIStudentsMinimal } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import axios from 'axios';

interface StudentSearchWidgetData {
  uid: string;
  grade: number;
  name: string;
}

interface StudentSearchWidgetState {
  students: Array<SelectOptions>;
  tech: SelectOptions;
  grades: Array<StudentSearchWidgetData>;
}

export class StudentSearchWidget extends React.Component<{}, StudentSearchWidgetState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      grades: [],
      students: [],
      tech: { label: "Select Student", value: "" }
    }
    this.changeStudent = this.changeStudent.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var students = await pullMCIStudentsMinimal();
    this.setState({
      students: students
    })
  }

  headers: Array<FilterAndSettingField> = [
    { columnName: "Course", setting: true, filter: {}, additionalClasses: "twenty" },
    { columnName: "Grade", setting: true, filter: {}, additionalClasses: "twenty" },
  ]

  async changeStudent(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ tech: event });
    let response = await axios.get('./api/student-profile/course-grades/' + event.value);
    if (response.status === 200) {
      this.setState({
        grades: response.data
      })
    }
  }

  render(): JSX.Element {
    return (
      <>
        <h4 style={{ width: "100%", textAlign: "center" }}>{this.state.tech.label} Course Grades</h4>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.grades.map((item) => (
                <tr key={item.uid}>
                  <td className="fixedTableCellFirst">{item.name}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right"}}>{item.grade}%</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <hr />
        <Select
          options={this.state.students}
          styles={reactSelectBasicStyle}
          value={this.state.tech}
          onChange={this.changeStudent}
          menuPlacement="auto"
        />
      </>
    )
  }
}