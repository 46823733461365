import * as React from 'react';
import { PieGraphData, PieGraphWidget } from '../../CoreComponents/Widgets/PieGraphWidget';
import Select from 'react-select';
import { pullProperties, pullPropertyPayrollDates } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { Col, Row } from 'reactstrap';
import axios from 'axios';

interface PayrollBreakdownWidgetState {
  siteValues: Array<PieGraphData>;
  siteKey: string;
  maintenanceValues: Array<PieGraphData>;
  maintenanceKey: string;
  properties: Array<SelectOptions>;
  property: SelectOptions;
  dates: Array<SelectOptions>;
  date: SelectOptions;
}

export class PayrollBreakdownWidget extends React.Component<{}, PayrollBreakdownWidgetState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      siteValues: [],
      maintenanceValues: [],
      properties: [],
      property: { label: "Select Property", value: "" },
      siteKey: Math.random().toString(),
      maintenanceKey: Math.random().toString(),
      dates: [],
      date: { label: "Select Option", value: ""}
    }
    this.changeProperty = this.changeProperty.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let response = await pullProperties();
    this.setState({ properties: response });
  }

  async changeProperty(event: SelectOptions | null): Promise<void> {
    let dates = await pullPropertyPayrollDates(event.value);
    this.setState({ property: event, dates: dates });
  }

  async changeDate(event: SelectOptions | null): Promise<void> {
    let response = await axios.get('./api/property-payroll-tracking/' + event.value + "/" + this.state.property.value);
    let item = response.data;
    let siteValues = [
      { label: "Wages", value: item.siteLabor, color: "#176D85" }, 
      { label: "Taxes", value: item.siteTaxes, color: "#5A0E2D" }, 
      { label: "Benefits", value: item.siteBenefits, color: "#038983" }, 
      { label: "WC", value: item.siteWorkersComp, color: "#B2868E" }
    ]
    let maintenanceValues = [
      { label: "Wages", value: item.maintenanceLabor, color: "#176D85" }, 
      { label: "Taxes", value: item.maintenanceTaxes, color: "#5A0E2D" }, 
      { label: "Benefits", value: item.maintenanceBenefits, color: "#038983" },
      { label: "WC", value: item.maintenanceWorkersComp, color: "#B2868E" }
    ]
    this.setState({
      date: event,
      siteValues: siteValues,
      siteKey: Math.random().toString(),
      maintenanceValues: maintenanceValues,
      maintenanceKey: Math.random().toString()
    })
  }

  render(): JSX.Element {
    return (
      <>
        <PieGraphWidget key={this.state.siteKey} values={this.state.siteValues} title="Site Wages" />
        <PieGraphWidget key={this.state.maintenanceKey} values={this.state.maintenanceValues} title="Maintenance" />
        <hr />
        <Row>
          <Col>
            <Select
              value={this.state.property}
              options={this.state.properties}
              styles={reactSelectBasicStyle}
              onChange={this.changeProperty}
              menuPlacement="top"
            />
          </Col>
          <Col>
            <Select
              value={this.state.date}
              options={this.state.dates}
              styles={reactSelectBasicStyle}
              onChange={this.changeDate}
              menuPlacement="top"
            />
          </Col>
        </Row>
        
      </>
    )
  }

}