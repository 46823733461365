import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { ValidateAuth, Jobs } from '../../functions/authActions';
import ABCPayroll from './ABCPayroll';
import CDIPayroll from './CDIPayroll';
import VCONPayroll from './VCONPayroll';
import VMIPayroll from './VMIPayroll';

interface PayrollContainerProps {

}

interface PayrollContainerState {
  currentTab: number
}

export default class PayrollContainer extends React.Component<PayrollContainerProps, PayrollContainerState> {

  constructor(props: PayrollContainerProps) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  async componentDidMount(): Promise<void> {
    ValidateAuth([Jobs.HR, Jobs.Technology_Lead], []);
  }

  getTab(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <CDIPayroll />;
      case 1:
        return <ABCPayroll />;
      case 2:
        return <VCONPayroll />
      case 3:
        return <VMIPayroll />
      default:
        throw Error("Invalid Tab Called during Payroll");
    }
  }

  render(): JSX.Element {
    return (
      <>
        <h4>Payroll Processing</h4>
        <Row>
          <Col>
            <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "25%" }}
              value="CDI" onClick={() => this.setState({ currentTab: 0 })}
            />
            <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "25%" }}
              value="ABChase" onClick={() => this.setState({ currentTab: 1 })}
            />
            <input type="button" className={this.state.currentTab === 2 ? "tab-active" : "tab"} style={{ width: "25%" }}
              value="VCON" onClick={() => this.setState({ currentTab: 2 })}
            />
            <input type="button" className={this.state.currentTab === 3 ? "tab-active" : "tab"} style={{ width: "25%" }}
              value="VMI" onClick={() => this.setState({ currentTab: 3 })}
            />
          </Col>
        </Row>
        {
          this.getTab()
        }
      </>
    )
  }
}