import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { CreateGlobalAlert } from '../functions/CreateGlobalAlerts';
import { reactSelectBasicStyle } from '../style/select-constants';
import Select from 'react-select';
import { SelectOptions } from '../interfaces/CoreInterfaces';
import { getBearerToken } from '../functions/authActions';
import { pullProperties, pullSolarVendors } from '../functions/fetchLinkedObjects';

interface SolarProjectFormProps {

}

interface SolarProjectFormState {
  projectId: string;
  solarProduction: number;
  status: SelectOptions;
  systemSize: number,
  inService: boolean;
  timeSpent: number;
  phase: number;
  dateTime: Date;
  mainNotes: string;
  trackingUrl: string;
  properties: Array<SelectOptions>;
  contractors: Array<SelectOptions>;
  property: SelectOptions;
  contractor: SelectOptions;
}

export class SolarProjectForm extends React.Component<SolarProjectFormProps, SolarProjectFormState> {

  static displayName = SolarProjectForm.name;


  static StatusList = [
    "Screening",
    "Predevelopment",
    "Development",
    "Construction",
    "Financing",
    "Sustainment",
    "Admin Completion",
    "On Hold",
    "Screened Valid",
    "Screened Invalid",
  ];

  static StatusMap = [
    { label: "Screening", value: "0" },
    { label: "Predevelopment", value: "1" },
    { label: "Development", value: "2" },
    { label: "Construction", value: "3" },
    { label: "Financing", value: "4" },
    { label: "Sustainment", value: "5" },
    { label: "Admin Completion", value: "6" },
    { label: "On Hold", value: "7" },
    { label: "Screened Valid", value: "8" },
    { label: "Screened Invalid", value: "9" },
  ]

  constructor(props: SolarProjectFormProps) {
    super(props);
    var params = new URLSearchParams(window.location.search);
    var UID = params.has('uid') ? params.get('uid') : ''
    this.state = {
      solarProduction: 0,
      status: { label: "Select Status", value: "" },
      systemSize: 100000,
      inService: false,
      timeSpent: 0,
      phase: 0,
      projectId: UID,
      dateTime: new Date(),
      mainNotes: '',
      trackingUrl: "./api/solar-time-tracking/",
      properties: [],
      property: { label: "Select Property", value: "" },
      contractors: [],
      contractor: { label: "Select Contractor", value: "" }
    };
    this.changeProperty = this.changeProperty.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeContractor = this.changeContractor.bind(this);
    this.changeNotes = this.changeNotes.bind(this);
    this.setUpdateData = this.setUpdateData.bind(this);
  }


  async componentDidMount(): Promise<void> {
    const self = this;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const properties = await pullProperties();
    const contractors = await pullSolarVendors();
    this.setState({
      properties: properties,
      contractors: contractors
    })
    if (this.state.projectId !== "") {
      await axios.get("./api/solar-projects/" + this.state.projectId).then(function (response) {
        self.setUpdateData(response.data);
      });
    }
    if (this.state.projectId !== "") {
      //this.setSystemType(this.state.projectId);
    }
  }

  changeProperty(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      property: event
    })
  }

  changeStatus(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      status: event
    })
  }

  changeContractor(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      contractor: event
    })
  }

  changeNotes(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ mainNotes: event.target.value });
  }

  setUpdateData(data: any): void {
    this.setState({
      mainNotes: data.notes === null ? '' : data.notes,
      contractor: { label: data.solarContractor?.name, value: data.solarContractorUID },
      solarProduction: data.productionSystem,
      systemSize: data.systemWattSize,
      projectId: data.uid,
      status: { label: SolarProjectForm.StatusList[data.systemStatus], value: data.systemStatus },
      property: { label: data.property.name, value: data.property.uid },
      inService: data.inService
    });
  }

  clearForm(): void {
    window.location.assign("/solar-project-form/");
  }

  async loadScreener(): Promise<void> {
    window.location.assign("./solar-screener-manager/" + this.state.property.value);
  }

  async handleSubmit(): Promise<void> {
    const data = {
      Property: this.state.property.value,
      SolarProduction: this.state.solarProduction,
      SystemStatus: this.state.status.value,
      SystemSize: this.state.systemSize,
      InService: this.state.inService,
      Notes: this.state.mainNotes,
      SolarContractorUID: this.state.contractor.value,
    }
    if (this.state.projectId !== "") {
      axios.put('./api/solar-projects', data).then(function (response) {
        window.location.reload();
      });
    }
    else {
      axios.post('./api/solar-projects', data).then(function (response) {
        window.location.replace("/solar-project-form/" + response.data.UID);
      }).catch(function (response) {
        CreateGlobalAlert("Unable to Create Solar Project.. Please make sure Property & Contractor are filled in. ", 4000);
      });
    }
  }

  render(): JSX.Element {
    return (
      <Container fluid>
        <Row style={{ margin: "0px", marginLeft: "-50px", marginTop: "-15px", padding: "0px", width: "100%", height: "100vh" }}>
          <Col style={{ width: "100%" }}>
            <form id="solar-project-form">
              <Row>
                <Col>
                  <label> Property: </label>
                  <br />
                  <Select
                    value={this.state.property}
                    options={this.state.properties}
                    onChange={this.changeProperty}
                    styles={reactSelectBasicStyle}
                    isDisabled={this.state.projectId !== ""}
                  />
                </Col>
                <Col>
                  <label> System Status </label>
                  <br />
                  <Select
                    value={this.state.status}
                    options={SolarProjectForm.StatusMap}
                    onChange={this.changeStatus}
                    styles={reactSelectBasicStyle}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label> System Size </label>
                  <br />
                  <input type='number' id="system-size" name="systemSize" value={this.state.systemSize}
                    className="standard-input"
                  />
                </Col>
                <Col>
                  <label> Contractor </label>
                  <br />
                  <Select
                    styles={reactSelectBasicStyle}
                    options={this.state.contractors}
                    value={this.state.contractor}
                    onChange={this.changeContractor}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label> Notes </label>
                  <br />
                  <textarea id="main-notes" name="mainNotes" value={this.state.mainNotes} onChange={this.changeNotes} style={{ maxHeight: "65vh" }} />
                </Col>
              </Row>
              <hr />
              <Row id="buttons">
                <Col>
                  <input type="button" value="Submit" onClick={this.handleSubmit} style={{ width: "100%" }} className="standard-input" />
                </Col>
                <Col>
                  <input type="button" value="New Project" onClick={this.clearForm} style={{ width: "100%" }} className="standard-input" />
                </Col>
                {this.state.projectId !== '' &&
                  <Col>
                    <input type="button" value="Screener" onClick={this.loadScreener} style={{ width: "100%" }} className="standard-input" />
                  </Col>
                }
              </Row>
            </form>
          </Col>

        </Row>

      </Container>
    )
  }
}