import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import JobSettingsManagementModal from './JobSettingsWidget.modals';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface JobSettingsWidgetProps {
  uid: string;
  height?: string;
}

interface JobSettingsWidgetState {
  data: JobSettingsWidgetData;
}

export interface JobSettingsWidgetData {
  uid: string;
  name: string;
  entity: SelectOptions;
  contractor: SelectOptions; 
  contractDate: Date;
  createdBy: string;
  createdOn: Date;
  jobLeads: string;
  agencySignature: boolean;
  rdProject: boolean;
  completed: boolean;
  financeLeads: Array<SelectOptions>;
}

export default class JobSettingsWidget extends React.Component<JobSettingsWidgetProps, JobSettingsWidgetState> {

  managementModal: React.RefObject<JobSettingsManagementModal> = React.createRef<JobSettingsManagementModal>();

  constructor(props: JobSettingsWidgetProps) {
    super(props);
    this.state = {
      data: {
        uid: "",
        name: "",
        entity: { label: "Select Entity", value: "" },
        contractor: { label: "Select Contractor", value: "" },
        contractDate: new Date(),
        createdBy: "",
        createdOn: new Date(),
        jobLeads: "",
        agencySignature: false,
        rdProject: false,
        completed: false,
        financeLeads: []
      }
    }
  }

  async componentDidMount(): Promise<void> {
    let response = await axios.get("./api/managed-jobs/job-settings/" + this.props.uid);
    console.log(response.data);
    if (response.status === 200) {
      let data = response.data;
      this.setState({ data: data });
    }
  }

  render(): JSX.Element {
    return (
      <>
        <JobSettingsManagementModal ref={this.managementModal} uid={this.props.uid} />
        { RenderHeader("Job Settings") }
        <div style={{ height: this.props.height ? this.props.height : "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <Row>
            <Col>
              <h6>Job Name</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.name}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Entity Name</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.entity.label}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>General Contractor</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.contractor.label}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Created By</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.createdBy}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Created On</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{new Date(this.state.data.createdOn).toLocaleDateString("en-CA")}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Finance Leads</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.jobLeads}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Agency Signature</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.agencySignature ? "Yes" : "No"}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>RD Project</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.rdProject ? "Yes" : "No"}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Completed</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.data.completed ? "Yes" : "No"}</h6>
            </Col>
          </Row>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Edit" className="standard-input" onClick={(e) => this.managementModal.current.show(this.state.data)} />
          </Col>
        </Row>
      </>
    )
  }
}