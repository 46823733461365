import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { Jobs, Teams, ValidateAccess } from '../../../functions/authActions';
import EntityBankAccountManagementModal from './EntityBankAccountManagementWidget.Modals';
import axios from 'axios';
import { WarningModal } from '../../CoreComponents/Modals';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface EntityBankManagementData {
  uid: string;
  bank: string;
  type: string;
  number: string;
}

interface EntityBankManagementWidgetProps {
  uid: string;
}

interface EntityBankManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  rows: Array<EntityBankManagementData>;
}

export default class EntityBankAccountManagementWidget extends React.Component<EntityBankManagementWidgetProps, EntityBankManagementWidgetState> {

  managementModal: React.RefObject<EntityBankAccountManagementModal> = React.createRef<EntityBankAccountManagementModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: EntityBankManagementWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      rows: []
    }
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    let response = await axios.get('./api/bank-account-entity/widget/' + this.props.uid, { validateStatus: () => true });
    if (response.status === 200) {
      let rows: Array<EntityBankManagementData> = [];
      response.data.forEach((item: any) => (
        rows.push({
          uid: item.uid,
          bank: item.bank,
          type: item.type,
          number: item.accountNumber
        })
      ))
      this.setState({ rows: rows });
    }
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      { columnName: "Bank", setting: true, filter: {} },
      { columnName: "Type", setting: true, filter: {} },
    ]
    if (ValidateAccess([Jobs.Technology_Lead, Jobs.CFO], [Teams.Executive, Teams.Services])) {
      headers.push({ columnName: "Number", setting: true, filter: {}})
    }
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <div>
        <EntityBankAccountManagementModal ref={this.managementModal} uid={this.props.uid} />
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Bank Accounts")}
        <div style={{ height: "25vh" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.bank}</td>
                    <td className="fixedTableCell">{item.type}</td>
                    {ValidateAccess([Jobs.Technology_Lead, Jobs.CFO], [Teams.Executive, Teams.Services]) && <td className="fixedTableCell">{item.number}</td> }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Add Bank Account" className="standard-input" onClick={() => this.managementModal.current.show()} />
      </div>
    )
  }
}