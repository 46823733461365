import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldSelect, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import { SignerSettingsWidgetData } from './SignerSettingsWidget';
import { pullEmpireEmployees } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullGeneralContractingJobName } from '../../../functions/fetchObjectNameFunctions';

interface SignerSettingsManagementModalProps {
  uid: string;
}

interface SignerSettingsManagementModalState {
  data: SignerSettingsWidgetData;
  employees: Array<SelectOptions>;
}

export default class SignerSettingsManagementModal extends React.Component<SignerSettingsManagementModalProps, SignerSettingsManagementModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: SignerSettingsManagementModalProps) {
    super(props);
    this.state = {
      employees: [],
      data: {
        ownerName: "",
        ownerEmail: "",
        notarySigner: "",
        notarySignerName: "",
        waiverSigner: "",
        secondSigner: "",
        secondSignerName: "",
        firstApprover: { label: "First Approver", value: "" }
      }
    }
  }

  async componentDidMount(): Promise<void> {
    let employees = await pullEmpireEmployees();
    this.setState({ employees: employees });
  }

  async show(data: any): Promise<void> {
    this.setState({ data: data }, async () => await this.generateData());
  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    let jobName = await pullGeneralContractingJobName(this.props.uid);
    data.push({ key: "ownerName", name: "Owner Name", patchUrl: './api/managed-jobs/owner-name', type: "text", value: this.state.data.ownerName } as DataEntryModalFieldText);
    data.push({ key: "ownerEmail", name: "Owner Email", patchUrl: './api/managed-jobs/owner-email', type: "text", value: this.state.data.ownerEmail } as DataEntryModalFieldText);
    data.push({ key: "notarySigner", name: "Notary Signer", patchUrl: './api/managed-jobs/notary-signer', type: "text", value: this.state.data.notarySignerName } as DataEntryModalFieldText);
    data.push({ key: "notarySignerEmail", name: "Notary Signer Email", patchUrl: './api/managed-jobs/notary-signer-name', type: "text", value: this.state.data.notarySigner } as DataEntryModalFieldText);
    data.push({ key: "waiverSigner", name: "Waiver Signer", patchUrl: './api/managed-jobs/waiver-signer', type: "text", value: this.state.data.waiverSigner } as DataEntryModalFieldText);
    data.push({ key: "secondSignerName", name: "Architect Name", patchUrl: "./api/managed-jobs/second-signer-name", type: "text", value: this.state.data.secondSignerName } as DataEntryModalFieldText);
    data.push({ key: "secondSigner", name: "Architect Email", patchUrl: './api/managed-jobs/second-signer', type: "text", value: this.state.data.secondSigner } as DataEntryModalFieldText);
    data.push({ key: "firstAppover", name: "First Approver", patchUrl: './api/managed-jobs/first-approver', value: this.state.data.firstApprover, options: this.state.employees, isMulti: false } as DataEntryModalFieldSelect);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Edit " + jobName + " Signers", this.props.uid, undefined);
  }

  overrideSave(data: Array<DataEntryModalField>): void {
    console.log("Closing Modal as Save is only used during Creation, and this Modal doesn't do create");
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} overrideSave={this.overrideSave} overrideSaveText="Save" />
      </>
    )
  }
}