import * as React from "react";
import { SelectOptions } from "../../interfaces/CoreInterfaces";
import { Employee, EmployeeCompany } from "./EmpireEmployeeListAuth";
import axios from "axios";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Select from "react-select";
import { reactSelectBasicStyle } from "../../style/select-constants";
import { RemoveStickyOverlays, RestoreStickyOverlays } from "../../functions/selectTools";
import * as EnumConstants from "../../constants/EnumConstants";
import * as funcs from "../../functions/patchFunctions";

interface EmployeeDataEntryModalProps {

}

interface EmployeeDataEntryModalState {
  showing: boolean;
  title: string;
  roles: Array<SelectOptions>;
  employee: Employee;
  errorMessage: string;
  callback: () => void;
}

export default class EmployeeDataEntryModal extends React.Component<EmployeeDataEntryModalProps, EmployeeDataEntryModalState>{

  static DEFAULT_EMPLOYEE = {
    firstName: "",
    lastName: "",
    email: "",
    alternativeNames: "",
    phoneNumber: "",
    company: { label: "Choose Option", value: "" },
    team: { label: "Choose Team", value: "" },
    role: { label: "Choose Role", value: "" },
    isAdmin: false,
    isKBEditor: false,
    uid: "",
    sendHandbook: false
  };


  constructor(props: EmployeeDataEntryModalProps) {
    super(props);
    this.state = {
      showing: false,
      title: "",
      errorMessage: "",
      roles: [],
      employee: JSON.parse(JSON.stringify(EmployeeDataEntryModal.DEFAULT_EMPLOYEE)),
      callback: undefined
    }
    this.patchCompany = this.patchCompany.bind(this);
    this.patchTeam = this.patchTeam.bind(this);
    this.patchRole = this.patchRole.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeIsAdmin = this.changeIsAdmin.bind(this);
    this.changeIsKBEditor = this.changeIsKBEditor.bind(this);

    this.changeFirstName = this.changeFirstName.bind(this);
    this.patchFirstName = this.patchFirstName.bind(this);

    this.changeAlternativeNames = this.changeAlternativeNames.bind(this);
    this.patchAlternativeNames = this.patchAlternativeNames.bind(this);

    this.changeLastName = this.changeLastName.bind(this);
    this.patchLastName = this.patchLastName.bind(this);

    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    this.changeSendHandbook = this.changeSendHandbook.bind(this);
    
    this.saveNewEmployee = this.saveNewEmployee.bind(this);
    this.callback = this.callback.bind(this)
  }

  display(employee: Employee, callback: () => void): void {
    console.log(employee);
    let title = employee === undefined ? "Create Employee" : "Edit Employee";
    let roles: Array<SelectOptions>  = [];
    if (employee === undefined || employee === null) {
      console.log("Resetting...");
      employee = JSON.parse(JSON.stringify(EmployeeDataEntryModal.DEFAULT_EMPLOYEE));
    }
    else {
      roles = this.GetTeam(employee.team.value);
    }
    this.setState({
      showing: true,
      title: title,
      employee: employee,
      callback: callback,
      roles: roles
    })
  }

  //#region Change Functions
  async changeFirstName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.employee
    item.firstName = event.target.value;
    this.setState({ employee: item })
  }

  async patchFirstName(): Promise<void> {
    if (this.state.employee.uid !== "") {
      await funcs.patchStringValue(this.state.employee.uid, this.state.employee.firstName, './api/empire/auth/first-name');
    }
  }

  async changeAlternativeNames(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.employee
    item.alternativeNames = event.target.value;
    this.setState({ employee: item })
  }

  async patchAlternativeNames(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    if (this.state.employee.uid !== "") {
      await funcs.patchStringValue(this.state.employee.uid, this.state.employee.alternativeNames, './api/empire/auth/alternative-names');
    }
  }

  async changeLastName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.employee
    item.lastName = event.target.value;
    this.setState({ employee: item })
  }

  async patchLastName(): Promise<void> {
    if (this.state.employee.uid !== "") {
      await funcs.patchStringValue(this.state.employee.uid, this.state.employee.lastName, './api/empire/auth/last-name')
    }
  }

  async changeEmail(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.employee
    item.email = event.target.value;
    this.setState({ employee: item });
    if (this.state.employee.uid !== "") {
      const data = {
        UID: this.state.employee.uid,
        Email: event.target.value
      }
      const response = await axios.patch('./api/empire/auth/email', data, { validateStatus: () => true });
      if (response.status !== 202) {
        this.setState({ errorMessage: "Unable to update email. Please submit a tech ticket so we can fix this error." });
      }
    }
  }

  async changeIsAdmin(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const employee = this.state.employee
    employee.isAdmin = event.target.checked
    this.setState({ employee: employee });

    if (this.state.employee.uid) {
      const data = {
        UID: this.state.employee.uid,
       Value: event.target.checked
      }
      const response = await axios.patch('./api/empire/auth/is-admin', data, { validateStatus: () => true });
      if (response.status !== 202) {
        this.setState({ errorMessage: "Unable to Update Is Admin. Please submit a tech ticket so we can fix this error."})
      }
    }
  }

  async changeIsKBEditor(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const employee = this.state.employee
    employee.isKBEditor = event.target.checked
    this.setState({ employee: employee });

    if (this.state.employee.uid) {
      const data = {
        UID: this.state.employee.uid,
        IsKBEditor: event.target.checked
      }
      const response = await axios.patch('./api/empire/auth/is-kb-editor', data, { validateStatus: () => true });
      if (response.status !== 202) {
        this.setState({ errorMessage: "Unable to update Knowledge Base Editor. Please submit a tech ticket so we can fix this error." })
      }
    }
  }

  async changePhoneNumber(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.employee
    item.phoneNumber = event.target.value;
    this.setState({ employee: item })

    if (this.state.employee.uid !== "") {
      const data = {
        UID: this.state.employee.uid,
        Value: event.target.value
      }
      const response = await axios.patch('./api/empire/auth/phone-number', data, { validateStatus: () => true });
      if (response.status !== 202) {
        this.setState({ errorMessage: "Unable to update Phone Number. Please submit a tech ticket so we can fix this error."});
      }
    }
  }

  async changeSendHandbook(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.employee
    item.sendHandbook = event.target.checked;
    this.setState({ employee: item })

    if (this.state.employee.uid !== "") {
      const data = {
        UID: this.state.employee.uid,
        Value: event.target.checked
      }
      const response = await axios.patch('./api/empire/auth/send-handbook', data, { validateStatus: () => true });
      if (response.status !== 202) {
        this.setState({ errorMessage: "Unable to update Phone Number. Please submit a tech ticket so we can fix this error." });
      }
    }
  }
  //#endregion
  
  GetTeam(role: string): Array<SelectOptions> {
    let values: Array<SelectOptions> = []
    switch (role) {
      case "0":
        values = EnumConstants.NewEmployeeRolesExecutive;
        break;
      case "1":
        values = EnumConstants.NewEmployeeRolesHumanResources;
        break;
      case "2":
        values = EnumConstants.NewEmployeeRolesConstruction;
        break;
      case "3":
        values = EnumConstants.NewEmployeeRolesMCI;
        break;
      case "4":
        values = EnumConstants.NewEmployeeRolesFleet;
        break;
      case "5":
        values = EnumConstants.NewEmployeeRolesAssetManagement;
        break;
      case "6":
        values = EnumConstants.NewEmployeeRolesServices;
        break;
      case "7":
        values = EnumConstants.NewEmployeeRolesDevelopment;
        break;
      case "8":
        values = EnumConstants.NewEmployeeRolesPropertyManagement;
        break;
    }
    return values;
  }

  //#region Patch Company, Team, Role
  async patchCompany(event: SelectOptions | null): Promise<void> {
    if (event === null) { return }

    if (this.state.employee.uid !== "") {
      const data = {
        UID: this.state.employee.uid,
        Company: parseInt(event.value)
      }
      const response = await axios.patch('./api/empire/auth/company', data)
    }

    const employee = this.state.employee
    employee.company = event
    this.setState({ employee: employee })
  }

  async patchTeam(event: SelectOptions | null): Promise<void> {
    if (event === null) { return }

    if (this.state.employee.uid !== "") {
      const data = {
        UID: this.state.employee.uid,
        Team: event.value
      }
      const response = await axios.patch('./api/empire/auth/team', data);
      if (response.status !== 202) {
        this.setState({ errorMessage: "Unable to update employee's team. Please reach out to tech so they can investigate it" });
      }
    }
    const employee = this.state.employee
    employee.team = event
    this.setState({ employee: employee, roles: this.GetTeam(employee.team.value) });
  }

  async patchRole(event: SelectOptions | null): Promise<void> {
    if (event === null) { return }
    if (this.state.employee.uid !== "") {
      const data = {
        UID: this.state.employee.uid,
        Job: event.value
      }
      const response = await axios.patch('./api/empire/auth/role', data);
      if (response.status !== 202) {
        this.setState({ errorMessage: "Unable to update employee's team. Please reach out to tech so they can investigate it" });
      }
    }
    const employee = this.state.employee
    employee.role = event
    this.setState({ employee: employee });
  }
  //#endregion

  async saveNewEmployee(): Promise<void> {
    const data = {
      FirstName: this.state.employee.firstName,
      LastName: this.state.employee.lastName,
      Email: this.state.employee.email,
      OfficePhoneNumber: this.state.employee.phoneNumber,
      Company: this.state.employee.company.value,
      Team: this.state.employee.team.value,
      Role: !this.state.employee.isAdmin,
      NewJob: this.state.employee.role.value,
      ExternalBillingRate: 0,
      InternalBillingRate: 0,
      KnowledgeBaseEditor: this.state.employee.isKBEditor
    };

    const response = await axios.post('./api/empire/auth', data, { validateStatus: () => true });

    if (response.status !== 201) {
      this.setState({ errorMessage: "There was an error creating the employee, please contact the tech team so they can identify the issue" });
    }
    else {
      this.setState({ showing: false })
      if (this.state.callback) {
        this.state.callback();
      }
    }
  }

  callback(): void {
    this.setState({
      showing: false
    });
    if (this.state.callback) {
      this.state.callback();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing}>
        <div style={{ background: "#4c4a42" }}>
          <ModalHeader toggle={() => this.setState({ showing: false })} >
            <h3 style={{ color: "#c2a877" }}>{this.state.title}</h3>
          </ModalHeader>
          <ModalBody>
            {
              this.state.errorMessage !== "" &&
              <>
                <label style={{ color: "#ff5555", padding: "0", margin: "0" }}>{this.state.errorMessage}</label>
                <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              </>
            }
            <Row>
              <Col>
                <h5>First Name</h5>
              </Col>
              <Col>
                <input type="text" className="standard-input" value={this.state.employee?.firstName} onChange={this.changeFirstName} onBlur={this.patchFirstName} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Alternative Names</h5>
              </Col>
              <Col>
                <input type="text" className="standard-input" value={this.state.employee?.alternativeNames} onChange={this.changeAlternativeNames}
                  onBlur={this.patchAlternativeNames}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Last Name</h5>
              </Col>
              <Col>
                <input type="text" className="standardInput" value={this.state.employee?.lastName} onChange={this.changeLastName}
                  onBlur={this.patchLastName}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Email</h5>
              </Col>
              <Col>
                <input type="text" className="standardInput" value={this.state.employee?.email} onChange={this.changeEmail} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Phone Number</h5>
              </Col>
              <Col>
                <input type="text" className="standardInput" value={this.state.employee?.phoneNumber} onChange={this.changePhoneNumber} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Company</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.employee?.company}
                  styles={reactSelectBasicStyle}
                  options={EmployeeCompany}
                  onChange={this.patchCompany}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Team</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.employee.team}
                  styles={reactSelectBasicStyle}
                  options={EnumConstants.CompanyTeams}
                  onChange={this.patchTeam}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Role</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.employee.role}
                  styles={reactSelectBasicStyle}
                  options={this.state.roles}
                  onChange={this.patchRole}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
              </Col>
            </Row>
            {
              this.state.employee?.uid !== "" &&
              <>
                <hr />
                <h5 style={{ width: "100%", textAlign: "center" }}>User Security Settings</h5>
                <br />
                <Row>
                  <Col>
                    <h6>Is Admin</h6>
                  </Col>
                  <Col>
                    <input type="checkbox" style={{ height: "3vh", marginLeft: "40%" }} checked={this.state.employee.isAdmin}
                      onChange={this.changeIsAdmin}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6>Knowledge Base Editor</h6>
                  </Col>
                  <Col>
                    <input type="checkbox" style={{ height: "3vh", marginLeft: "40%" }} checked={this.state.employee.isKBEditor}
                      onChange={this.changeIsKBEditor}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6>Send Employee Handbook</h6>
                  </Col>
                  <Col>
                    <input type="checkbox" style={{ height: "3vh", marginLeft: "40%" }} checked={this.state.employee.sendHandbook}
                      onChange={this.changeSendHandbook}
                    />
                  </Col>
                </Row>
              </>
            }
          </ModalBody>
          <ModalFooter>
            {this.state.employee?.uid !== "" && <input type="button" value="Done" className="standard-input" onClick={this.callback}
              style={{
                backgroundColor: "#c2a877",
                color: "#15405c"
              }}
            />}
            {this.state.employee?.uid === "" && <input type="button" value="Create" className="standard-input" onClick={this.saveNewEmployee}
              style={{
                backgroundColor: "#c2a877",
                color: "#15405c"
              }}
            />}
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}
