import * as React from 'react';
import { SelectOptions } from "../../interfaces/CoreInterfaces";
import { ChiefFinancialOfficerDashboard } from '../FinanceProformaComponents/FinanceDashboards/ChiefFinancialOfficerDashboard';
import { HumanResourcesDashboard } from './Dashboards/HumanResourcesDashboard';
import { VicePresidentDashboard } from './Dashboards/VicePresidentOperationsDashboard';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { GetSettings, SetSettings } from '../../functions/StateManagement/StateStorageFunctions';

export interface CFODashboardManagerProps {

}

export interface CFODashboardManagerState {
  dashboard: SelectOptions
}


export const CFODashboardOptions: Array<SelectOptions> = [
  { label: "CFO", value: "0" },
  { label: "Human Resources", value: "1" },
  { label: "Vice President of Operations", value: "2" },
]

export default class CFODashboardManager extends React.Component<CFODashboardManagerProps, CFODashboardManagerState>{

  constructor(props: CFODashboardManagerProps) {
    super(props);
    let settings: CFODashboardManagerState = GetSettings<CFODashboardManagerState>("cfoDashboardManager");
    if (settings === null) {
      settings = {
        dashboard: { value: "1", label: "Human Resources" }
      }
    }
    this.state = settings;
    this.dashboardSwitch = this.dashboardSwitch.bind(this);
    this.changeDashboard = this.changeDashboard.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  dashboardSwitch(): JSX.Element {
    switch (this.state.dashboard.value) {
      case "0":
        return <ChiefFinancialOfficerDashboard key={this.state.dashboard.value} />
      case "1":
        return <HumanResourcesDashboard key={this.state.dashboard.value} />
      case "2":
        return <VicePresidentDashboard key={this.state.dashboard.value} />
      default:
        return <h3>ERROR</h3>
    }
  }

  changeDashboard(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ dashboard: event }, () => {
      SetSettings("cfoDashboardManager", this.state);
    })
  }

  render(): JSX.Element {
    return (
      <>
        {this.dashboardSwitch()}
        <div style={{ width: "25%", marginLeft: "75%" }}>
          <Select
            options={CFODashboardOptions}
            styles={reactSelectBasicStyle}
            value={this.state.dashboard}
            onChange={this.changeDashboard}
            menuPlacement="auto"
            menuPosition="fixed"
            onFocus={RemoveStickyOverlays}
            onBlur={RestoreStickyOverlays}
          />
        </div>
      </>
    )
  }
}