import * as React from 'react'
import { LineDetailsProps, LineDetailsState } from './CNALineItemDisplay.LineDetails.constants';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog } from '@fortawesome/free-solid-svg-icons';
import { getCNALineDetailHeaders, getLineItems, getLineOptions } from './CNALineItemDisplay.functions';
import { CNALineItem } from './CNALineItemDisplay.constants';
import LineItemEditModal from './CNALineItemDisplay.LineItemEditModal';

export default class LineDetails extends React.Component<LineDetailsProps, LineDetailsState>{

  editCNALineItem: React.RefObject<LineItemEditModal> = React.createRef<LineItemEditModal>();

  constructor(props: LineDetailsProps) {
    super(props);
    this.state = {
      headers: getCNALineDetailHeaders(),
      lineOptions: [],
      lineItems: props.lineItems,
      cnaUID: props.cnaUID,
      propType: props.propType,
      categoryType: props.categoryType
    }
    this.refreshItems = this.refreshItems.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.refreshItems();
  }

  async componentDidUpdate(): Promise<void> {
    await this.refreshItems();
  }

  async refreshItems(): Promise<void> {
    const options = await getLineOptions(this.props.categoryType);
    const lineItems = await getLineItems(this.props.cnaUID, this.props.categoryType);

    this.setState({
      lineOptions: options,
      lineItems: lineItems
    })
  }

  showEditModal(cnaLineItem: CNALineItem): void {
    this.editCNALineItem.current.show(
      "Edit Line Item",
      cnaLineItem,
      this.state.lineOptions,
      this.state.propType,
      this.state.cnaUID
    )
  }

  render(): JSX.Element {
    return (
      <>
        <LineItemEditModal ref={this.editCNALineItem} callback={() => this.refreshItems()} />
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
          </thead>
          <tbody>
            <>
              {
                this.state.lineItems.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst" style={!item.isHealthAndSafetyIssue ? {} : { background: "#FF0000" }}>{item.item.label}</td>
                    <td className="fixedTableCell">{item.eul}</td>
                    <td className="fixedTableCell">{item.age}</td>
                    <td className="fixedTableCell">{item.rul}</td>
                    <td className="fixedTableCell">
                      {item.isHealthAndSafetyIssue && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: "40%" }} />}
                    </td>
                    <td className="fixedTableCell">{item.condition.label}</td>
                    <td className="fixedTableCell">{item.action.label}</td>
                    <td className="fixedTableCell">{item.duration}</td>
                    <td className="fixedTableCell">{item.qty}</td>
                    <td className="fixedTableCell">{item.unit.label}</td>
                    <td className="fixedTableCell">{item.unitCost}</td>
                    <td className="fixedTableCell">{item.totalCost}</td>
                    <td className="fixedTableCell">{item.comments}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faCog} style={{ marginLeft: "25%" }} onClick={() => this.showEditModal(item)} />
                    </td>
                  </tr>
                ))
              }
            </>
          </tbody>
        </table>
      </>
    )
  }
}