
import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { pullViridianMaintenanceManagers } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import axios from 'axios';

interface MaintenanceTechSearchData {
  name: string;
  email: string;
  property: string;
}

interface MaintenanceTechSearchState {
  techs: Array<SelectOptions>;
  tech: SelectOptions;
  sites: Array<MaintenanceTechSearchData>;
  regionals: Array<MaintenanceTechSearchData>;
}

export class MaintenanceTechSearch extends React.Component<{}, MaintenanceTechSearchState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      sites: [],
      regionals: [],
      techs: [],
      tech: {label: "Select Tech", value: ""}
    }
    this.changeTech = this.changeTech.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var techs = await pullViridianMaintenanceManagers();
    this.setState({
      techs: techs
    })
  }

  headers: Array<FilterAndSettingField> = [
    { columnName: "Title", setting: true, filter: {}, additionalClasses: "twenty" },
    { columnName: "Name", setting: true, filter: {}, additionalClasses: "twenty" },
    { columnName: "Property", setting: true, filter: {}, additionalClasses: "twenty" },
    { columnName: "Email", setting: true, filter: {} }
  ]

  async changeTech(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ tech: event });
    let response = await axios.get('./api/property-employee-map/maintenance/' + event.value);
    if (response.status === 200) {
      this.setState({
        sites: response.data.sites,
        regionals: response.data.regionals
      })
    }
  }

  render(): JSX.Element {
    return (
      <>
        <h4 style={{ width: "100%", textAlign: "center" }}>{this.state.tech.label} Manager's</h4>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.regionals.map((item) => (
                <tr key={item.email}>
                  <td className="fixedTableCellFirst">Regional</td>
                  <td className="fixedTableCell">{item.name}</td>
                  <td className="fixedTableCell">{item.property}</td>
                  <td className="fixedTableCell">{item.email}</td>
                </tr>
              ))
            }
            {
              this.state.sites.map((item) => (
                <tr key={item.email}>
                  <td className="fixedTableCellFirst">Site Manager</td>
                  <td className="fixedTableCell">{item.name}</td>
                  <td className="fixedTableCell">{item.property}</td>
                  <td className="fixedTableCell">{item.email}</td>
                </tr>
              ))
            }
          </tbody>
        </table >
        <hr />
        <Select
          options={this.state.techs}
          styles={reactSelectBasicStyle}
          value={this.state.tech}
          onChange={this.changeTech}
          menuPlacement="auto"
        />
      </>
    )
  }
}