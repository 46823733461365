import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DatePicker } from '../../CoreComponents/DateComponents';

interface GoogleBillingUploadModalState {
  show: boolean;
  data: GoogleBillingUploadData;
}

export interface GoogleBillingUploadData {
  billingDate: Date;
  csvFile: File;
  googleLicenseCost: number;
}

export default class GoogleBillingUploadModal extends React.Component<{ callback(data: GoogleBillingUploadData): void}, GoogleBillingUploadModalState> {

  constructor(props: { callback(data: GoogleBillingUploadData): void}) {
    super(props);

    this.state = {
      show: false,
      data: {
        billingDate: new Date(),
        csvFile: null,
        googleLicenseCost: 0
      }
    }
    this.setBillingDate = this.setBillingDate.bind(this);
    this.setGoogleLicenseCost = this.setGoogleLicenseCost.bind(this);
    this.changeFile = this.changeFile.bind(this);
  }

  show(): void {
    this.setState({ show: true });
  }

  hide(): void {
    this.setState({ show: false });
  }

  setBillingDate(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.billingDate = event.target.valueAsDate;
    this.setState({ data: data});
  }

  setGoogleLicenseCost(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.googleLicenseCost = parseFloat(event.target.value);
    this.setState({ data: data });
  }

  changeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.csvFile = event.target.files[0];
    this.setState({ data: data });
  }

  submit(): void {
    this.setState({ show: false });
    this.props.callback(this.state.data);
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ show: false })} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Google Billing Processing
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Billing Date</h5>
              </Col>
              <Col>
                <DatePicker value={this.state.data.billingDate} onChange={this.setBillingDate} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Google License Cost</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.data.googleLicenseCost} onChange={this.setGoogleLicenseCost} className="standard-input" />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>CSV Upload</h5>
              </Col>
              <Col>
                <input type="file" className="standard-input" style={{ paddingTop: "0.5vh" }} accept=".csv"
                  onChange={this.changeFile}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Process" className="standard-input" onClick={() => this.submit()} />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}