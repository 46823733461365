import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import ReasonableAccomodationCreationModal from './ReasonableAccomodationWidget.creationmodal';

interface ReasonableAccomodationWidgetState {
  headers: Array<FilterAndSettingField>;
}

export default class ReasonableAccomodationWidget extends React.Component<{}, ReasonableAccomodationWidgetState> {

  entryModal: React.RefObject<ReasonableAccomodationCreationModal> = React.createRef<ReasonableAccomodationCreationModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      headers: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
  }

  async generateHeaders(): Promise<void> {
    let headers: Array<FilterAndSettingField> = [];
    headers.push({ columnName: "Property", setting: true, filter: {} });
    headers.push({ columnName: "Unit", setting: true, filter: {} });
    headers.push({ columnName: "Est. Cost", setting: true, filter: {} });
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <>
        <ReasonableAccomodationCreationModal ref={this.entryModal} />
        {RenderHeader("Reasonable Accomodations")}
        <div style={{ minHeight: "25vh", height: "25vh" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>

            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
        <input type="button" value="Create Reasonable Accomodation" className="standard-input" onClick={() => this.entryModal.current.show()} />
      </>
    )
  }
}