import axios from "axios";
import { CreateGlobalAlert } from "./CreateGlobalAlerts";
import { GetSettings, SetSettings } from "./StateManagement/StateStorageFunctions";

export const AUTHENTICATION_SETTINGS_STRING = "userAuthenticationSettings";

export interface AuthState {
  token: string;
  isAuthenticated: boolean;
  userId: string;
  userRole: number;
  userTeam: number;
  userJob: number;
  lastUpdated: Date;
  lastLogin: Date;
}

export const startState: AuthState = {
  token: '',
  isAuthenticated: false,
  userId: '',
  userRole: -1,
  userTeam: -1,
  userJob: -1,
  lastUpdated: new Date(),
  lastLogin: new Date()
}


export const Jobs = {
  CFO: 0,
  COO: 1,
  President: 2,
  HR: 3,
  Recruiter: 4,
  Accountant: 5,
  Supervisor: 6,
  Instructor: 7,
  Administrator: 8,
  Solar: 9,
  Legal_Analyst: 10,
  Project_Analyst: 11,
  Technology: 12,
  Marketing: 13,
  Finance: 14,
  Project_Manager: 15,
  Compliance: 16,
  Regional: 17,
  Administrative: 18,
  Controller: 19,
  Coordinator: 20,
  Foreman: 21,
  VP_Operations: 22,
  Technology_Lead: 23,
  Site_Manager: 24,
  Maintenance_Tech: 25
}

export const Teams = {
  Executive: 0,
  Human_Resources: 1,
  Construction: 2,
  MCI: 3,
  Fleet: 4,
  Asset_Management: 5,
  Services: 6,
  Development: 7,
  Property_Management: 8
}

export function login(token: any): any {
  //@ts-ignore
  return dispatch => {
    dispatch({
      type: "LOGIN",
      payload: token
    });
  }
}

export function logout(): any {
  //@ts-ignore
  return dispatch => {
    dispatch({
      type: "LOGOUT",
      payload: ""
    });
  };
}

export async function ValidateAuth(acceptedJobs: Array<number>, acceptedTeams: Array<number>): Promise<void> {
  // Job = 3 is Payroll/HR. Admins do not get payroll, just everything else
  // Team = 1 is HR 
  var result = await ValidateAccess(acceptedJobs, acceptedTeams)
  if (!result) {
    CreateGlobalAlert("You are accessing a portion of the site you are not permitted to access. If this happens multiple times HR will be notified.", 2000, () => window.location.replace("./"));
    const data = {
      Employee: getUserID(),
      Path: window.location.pathname
    }
    axios.post("./api/security-violation", data);
  }
}

export async function ValidateAccess(acceptedJobs: Array<number>, acceptedTeams: Array<number>, bypassHR: boolean=false): Promise<boolean> {
  let job = await getUserJobFromServer();
  let team = await getUserTeamFromServer();

  let result = true;
  // Job = 3 is Payroll/HR. Admins do not get payroll, just everything else
  // Team = 1 is HR
  if (!bypassHR && acceptedJobs.includes(Jobs.HR) && job != Jobs.HR && job != Jobs.Technology_Lead) {
    result = false;
  }
  else if (job == Jobs.HR || getUserRole() === 0) {
    // pass as this will bypass the third check
  }
  else if (!acceptedJobs.includes(job) || !acceptedTeams.includes(team)) {
    result = false
  }
  return result;
}

export function LoginUser(auth: AuthState, redirect: string = "") {
  auth.isAuthenticated = true;
  SetSettings<AuthState>("userAuthenticationSettings", auth);
  redirect === "" ? window.location.reload() : window.location.assign(redirect);
}

export function LogoutUser() {
  SetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING, null);
  window.location.assign("/login");
}

export function getBearerToken(): string {
  const state = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING);
  const authToken = state.token;
  return authToken;
}

export function getUserID(): string {
  const state = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING);
  if (state === null) { return undefined; }
  return state.userId;
}

/**
 * OBSOLETE
 * This should be moved to fetchObjectNameFunctions 
 * @returns
 */
export async function getUserFirstName(): Promise<string> {
  let _response = "";
  await axios.get('/api/empire/auth/' + getUserID()).then(function (response) {
    _response = response.data.firstName;
  });
  return _response;
} 

/**
 * OBSOLETE
 * This should be moved to fetchObjectNameFunctions 
 * @returns
 */
export async function getUserLastName(): Promise<string> {
  let _response = "";
  await axios.get('/api/empire/auth/' + getUserID()).then(function (response) {
    _response = response.data.lastName;
  });
  return _response;
}

/**
 * 
 */
export function getUserRole(): number {
  const state = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING)
  return state.userRole;
}

///Obsolete("Delete")
export function getUserJob(): number {
  const state = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING);
  return state.userJob;
}

export function getUserTeam(): number {
  const state = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING);
  return state.userTeam;
}

export function getUserLastUpdated(): Date {
  const state = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING).lastUpdated;
  return new Date(state);
}

export function getUserLastLogin(): Date {
  const state = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING);
  return new Date(state.lastLogin);
}

/**
 * Get the User job. This is something like Tier 3 Support or MCI Admin
 * If you are looking for User / Admin, use get User Role
 */
export async function getUserJobFromServer(): Promise<number> {
  let job = -1;
  let response = await axios.get('./api/empire/auth/get-user-role/' + getUserID(), { validateStatus: () => true });
  job = response.data
  return job;
}

export async function getUserTeamFromServer(): Promise<number> {
  let team = -1;
  await axios.get('./api/empire/auth/get-user-team/' + getUserID()).then((response) => {
    team = response.data
  })
  return team;
}