import * as React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default class TicketSummaryViewModal extends React.Component<{}, { showing: boolean, innerHtml: string}> {

  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      innerHtml: ""
    }
    this.show = this.show.bind(this);
    this.forceClose = this.forceClose.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  show(summary: string): void {
    this.setState({ showing: true, innerHtml: summary });
  }

  forceClose(): void {
    this.setState({ showing: false });
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="lg" style={{ borderBottom: "0px solid #15405c", borderRadius: "25%" }} backdrop="static">
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Ticket Summary Information
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
            <ModalBody style={{ border: "0px solid #15405c" }} dangerouslySetInnerHTML={{ __html: this.state.innerHtml && this.state.innerHtml }}></ModalBody>
        </div>
      </Modal>
    )
  }
}