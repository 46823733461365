import * as React from 'react'
import { StatusModal, WarningModal } from '../CoreComponents/Modals';
import EmpireEmployeeListAuth from './EmpireEmployeeListAuth';

interface EmployeePageManagerProps {

}

interface EmployeePageManagerState {
  pageTab: number;
}

export default class EmployeePageManager extends React.Component<EmployeePageManagerProps, EmployeePageManagerState>{

  statusRef = React.createRef<StatusModal>();
  warningRef = React.createRef<WarningModal>();

  constructor(props: EmployeePageManagerProps) {
    super(props);
    this.state = {
      pageTab: 0
    }
  }

  //getSwitch(): JSX.Element {
  //  switch (this.state.pageTab) {
  //    case 1:
  //      return 
  //    default:
  //      console.error("This isn't supposed to happen");
  //  }
  //}

  render(): JSX.Element {
    return (
      <>
        <EmpireEmployeeListAuth />
      </>
    )
  }
}

//<Row>
//  <Col style={{ marginBottom: "10px" }}>
//    <input type="button" className={this.state.pageTab === 0 ? "tab-active" : "tab"} style={{ width: "50%" }} value="Empire Employees" onClick={() => this.setState({ pageTab: 0 })} />
//    <input type="button" className={this.state.pageTab === 1 ? "tab-active" : "tab"} style={{ width: "50%" }} value="Empire Employees (auth)" onClick={() => this.setState({ pageTab: 1 })} />
//  </Col>
//</Row>
//<Row style={{ height: "90vh", overflowY: "auto", overflowX: "hidden", paddingBottom: "4vh", width: "99%" }}>
//  {
//    this.getSwitch()
//  }
//</Row>