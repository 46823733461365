import * as React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { DatePicker } from '../../CoreComponents/DateComponents';
import { AmortizationLineItem } from '../Structs';
import { calculateMonthlyPayment, calculateWithoutPrevious, calculateWithPrevious } from './CalculateFunctions';

interface NewAmortizationLineItemModalProps {

}

interface NewAmortizationLineItemModalState {
  show: boolean;
  line: AmortizationLineItem;
  modalTitle: string;
  rate: number;
  term: number;
  compoundType: number;
  calculationMethod: number;
  callback: (line: AmortizationLineItem) => void;
}

export default class NewAmortizationLineItemModal extends React.Component<NewAmortizationLineItemModalProps, NewAmortizationLineItemModalState>{

  constructor(props: NewAmortizationLineItemModalProps) {
    super(props);
    this.state = {
      show: false,
      modalTitle: "",
      line: {
        uid: "",
        date: new Date(Date.now()),
        days: 0,
        interestAccumulated: 0,
        advance: 0,
        i_payment: 0,
        i_balance: 0,
        p_payment: 0,
        p_balance: 0,
        monthlyPayment: 0,
        isRateChange: false,
        newInterest: 0,
        previous: undefined,
        index: -1
      },
      rate: 0,
      term: 0,
      compoundType: -1,
      calculationMethod: -1,
      callback: undefined
    }
    this.callback = this.callback.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  show(modalTitle: string, rate: number, term: number, callback: (line: AmortizationLineItem) => void): void {
    this.setState({
      show: true,
      term: term,
      rate: rate,
      modalTitle: modalTitle,
      callback: callback
    })
  }

  callback(): void {
    this.setState({
      show: false
    });
    if (this.state.callback) {
      this.state.callback(this.state.line);
    }
  }

  handleDateChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const line = this.state.line;
    line.date = event.target.valueAsDate === null ? new Date(1, 0, 1) : new Date(event.target.valueAsDate.getFullYear(), event.target.valueAsDate.getUTCMonth(), event.target.valueAsDate.getUTCDate(), event.target.valueAsDate.getUTCHours(), event.target.valueAsDate.getUTCDate(), event.target.valueAsDate.getUTCMinutes() - event.target.valueAsDate.getTimezoneOffset());
    this.setState({
      line: line
    }, this.calculate);
  }

  calculate(): void {
    if (this.state.line.previous !== null && this.state.line.previous !== undefined) {
      const data = calculateWithPrevious(this.state.line.previous, this.state.line.date, this.state.rate,
        this.state.line.advance, this.state.line.p_payment, this.state.line.i_payment, this.state.compoundType, this.state.calculationMethod);
      const line = this.state.line;
      line.p_balance = data.p_balance;
      line.days = data.days;
      line.interestAccumulated = data.interest;
      line.i_balance = data.i_balance;
      this.setState({ line: line }, this.calculateMonthlyPayment);
    }
    else {
      const data = calculateWithoutPrevious(this.state.line.advance, this.state.rate, this.state.line.i_payment, this.state.calculationMethod);
      const line = this.state.line;
      line.p_balance = data.p_balance;
      line.days = data.days;
      line.interestAccumulated = line.i_payment;
      line.i_balance = data.i_balance;
      this.setState({ line: line }, this.calculateMonthlyPayment);
    }
  }

  calculateMonthlyPayment(): void {
    const line = this.state.line;
    line.monthlyPayment = calculateMonthlyPayment(this.state.line.p_balance, this.state.term, this.state.rate);
    this.setState({
      line: line
    });
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const line: any = this.state.line;
    line[event.target.name] = parseFloat(event.target.value);
    this.setState({
      line: line
    }, this.calculate)
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <ModalHeader style={{ backgroundColor: "#4C4A42" }}>
          <h3 style={{ color: "#d7c7a7" }}>{this.state.modalTitle}</h3>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          <Row>
            <Col>Date: </Col>
            <Col><DatePicker className="standard-input" onChange={this.handleDateChange} value={this.state.line.date} /></Col>
          </Row>
          <Row>
            <Col>Advance: </Col>
            <Col><input name="advance" className="standard-input" type="number" value={this.state.line.advance} onChange={(e) => this.handleInputChange(e)} /></Col>
          </Row>
          <Row>
            <Col>Principal Payment: </Col>
            <Col><input name="p_payment" className="standard-input" type="number" value={this.state.line.p_payment} onChange={this.handleInputChange} /></Col>
          </Row>
          <Row>
            <Col>Interest Payment: </Col>
            <Col><input name="i_payment" className="standard-input" type="number" value={this.state.line.i_payment} onChange={this.handleInputChange} /></Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
          <input type="button" value="Submit" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
          />
        </ModalFooter>
      </Modal>
    )
  }

}