import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldCheckbox, DataEntryModalFieldDate, DataEntryModalFieldSelect, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import axios from 'axios';
import { getUserID } from '../../../functions/authActions';
import { StatusModal } from '../../CoreComponents/Modals';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullPaymentApplications } from '../../../functions/fetchLinkedObjects';
import { DownloadNamedFile } from '../../../functions/documentTools';

interface RunPayApplicationProps {
  uid: string;
  callback: (success: boolean) => void;
}

interface RunPayApplicationState {
  show: boolean;
  uid: string;
  payApp: SelectOptions;
  drawNumbers: Array<SelectOptions>;
  isFinal: boolean;
  isTest: boolean;
  stopSave: boolean;
  endDate: Date;
}

export default class RunPayApplication extends React.Component<RunPayApplicationProps, RunPayApplicationState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();
  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();

  constructor(props: RunPayApplicationProps) {
    super(props);
    this.state = {
      show: false,
      uid: props.uid,
      payApp: { label: "Select Pay App", value: "-1" },
      drawNumbers: [],
      isFinal: false,
      isTest: false,
      stopSave: false,
      endDate: new Date()
    }
    this.startRequestForPaymentWorkflow = this.startRequestForPaymentWorkflow.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let numbers = await pullPaymentApplications(this.props.uid);
    this.setState({ drawNumbers: numbers });
  }

  show(): void {
    this.generateData();
  }

  generateData(): void {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "DrawNumber", name: "Pay App Number", patchUrl: undefined, isMulti: false, value: this.state.payApp, options: this.state.drawNumbers } as DataEntryModalFieldSelect);
    data.push({ key: "EndDate", name: "End Date", patchUrl: undefined, value: this.state.endDate } as DataEntryModalFieldDate);
    data.push({ key: "IsFinal", name: "Final Waiver", patchUrl: undefined, checkbox: this.state.isFinal } as DataEntryModalFieldCheckbox);
    data.push({ key: "IsTest", name: "Test Mode", patchUrl: undefined, checkbox: this.state.isTest } as DataEntryModalFieldCheckbox);
    data.push({ key: "StopSave", name: "Prevent Save", patchUrl: undefined, checkbox: this.state.stopSave } as DataEntryModalFieldCheckbox);
    this.entryModal.current.show("Run Pay Application", this.state.uid, "");
    this.entryModal.current.update(data);
  }

  async callback(success: boolean): Promise<void> {
    this.props.callback(success);
  }

  async startRequestForPaymentWorkflow(data: Array<DataEntryModalField>): Promise<void> {
    let key = await axios.get('./api/status-manager');
    const _data: any = {
      JobUID: this.state.uid,
      IsTest: (data.filter(u => u.key === "IsTest")[0] as DataEntryModalFieldCheckbox).checkbox,
      EndDate: (data.filter(u => u.key === "EndDate")[0] as DataEntryModalFieldDate).value,
      StopSave: (data.filter(u => u.key === "StopSave")[0] as DataEntryModalFieldCheckbox).checkbox,
      IsFinal: (data.filter(u => u.key === "IsFinal")[0] as DataEntryModalFieldCheckbox).checkbox,
      CreatedBy: getUserID(),
      DrawNumber: ((data.filter(u => u.key === "DrawNumber")[0] as DataEntryModalFieldSelect).value as SelectOptions).value,
      StatusKey: key.data
    }
    this.statusModal.current.display("Preparing Pay App", "The Pay App is being prepared.", key.data);
    let response = await axios.post('./api/request-for-payment-workflow/generate', _data, { responseType: 'blob' });
    this.statusModal.current.hide();
    await DownloadNamedFile(response);
    window.location.reload();
  }

  render(): JSX.Element {
    return (
      <>
        <StatusModal ref={this.statusModal} />
        <DataEntryModal ref={this.entryModal} callback={this.callback} overrideSave={this.startRequestForPaymentWorkflow} />
      </>
    )
  }
}