//#region Imports
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { getBearerToken } from '../../../functions/authActions';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { StatusModal, WarningModal, YesNoModal } from '../../CoreComponents/Modals';
import { addLineItems, generateScheduleOfValuesHeaders, ScheduleOfValuesLineItem, ScheduleOfValuesLineItemRow } from '../../CoreComponents/ScheduleOfValues/ScheduleOfValuesLineItem';
import * as Structs from '../Structs';
//#endregion

function round(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export class DrawRequestManager extends React.Component<{ uid?: string }, Structs.DrawState> {

  warningModal = React.createRef<WarningModal>();
  yesNoModal = React.createRef<YesNoModal>();
  statusModal = React.createRef<StatusModal>();

  constructor(props: { uid?: string }) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const uid = props.uid ? props.uid : params.get('uid');
    this.state = {
      uid: uid,
      currentDraw: 0,
      loaded: false,
      sites: [],
      site: { label: "Select Site", value: "" },
      draws: [],
      draw: null,
      saving: false,
      subTotalLineItem: ScheduleOfValuesLineItemRow.EmptySOVLineItem,
      totalLineItem: ScheduleOfValuesLineItemRow.EmptySOVLineItem
    }
    this.addDraw = this.addDraw.bind(this);
    this.changeSite = this.changeSite.bind(this);
    this.changeDraw = this.changeDraw.bind(this);
    this.getSubTotalAndTotalValues = this.getSubTotalAndTotalValues.bind(this);
    this.recalculatePrevious = this.recalculatePrevious.bind(this);
    this.updateSoftCostsSOV = this.updateSoftCostsSOV.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get("./api/managed-jobs/projects/" + this.state.uid).then(function (response) {
      const data = response.data;
      const site = data.map(function (item: any) { return { label: item.property, value: item.projectUID } })[0];
      self.setState({
        sites: data.map(function (item: any) { return { label: item.property, value: item.projectUID } }),
        site: site,
      }, () => self.changeSite(site))
    })
  }

  async componentWillUnmount(): Promise<void> {
    this.getSubTotalAndTotalValues(false);
  }

  //#region Update Draw & Site

  async changeSite(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    if (event.value !== undefined) {
      this.statusModal.current.display("Pulling Site Data", "We are pulling the draws for the site");
      await axios.get('./api/draw-requests/' + event.value + "/minimal").then((response) => {
        this.statusModal.current.hide();
        if (response.data.length === 0) {
          this.yesNoModal.current.show(
            "No Payment Application Current Exists, would you like to create one now",
            "No Valid Payment Applications",
            () => window.location.reload(),
            () => this.addDraw(),
            "Create",
            "Cancel"
          );
          return;
        }
        this.setState({
          site: event,
          draws: response.data,
        }, () => this.changeDraw({ label: "Pay App # " + (response.data.at(-1)?.label + 1), value: (response.data.at(-1)?.value) }))
      });
    }
  }

  async changeDraw(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    let uid: string = event.value;
    this.statusModal.current.display("Pulling Draw", "We are pulling the latest version of the draw");
    await axios.get("./api/draw-requests/" + uid).then((response) => {
      this.statusModal.current.hide();
      console.log(response.data);
      let draw: Structs.Draw = {
        uid: response.data.uid,
        number: response.data.number,
        generalRequirements: response.data.generalRequirementsPercentage,
        generalOverhead: response.data.generalOverheadPercentage,
        profit: response.data.profitPercentage,
        lineItems: response.data.lineItems,
        locked: response.data.isLocked
      }
      this.setState({
        draw: draw,
        loaded: true
      })
    })
  }

  //#endregion

  getSubTotalAndTotalValues(updateSoftCosts: boolean = false): void {
    const currentDraw = this.state.draw;
    const lineItems = currentDraw?.lineItems.filter(_item => _item.itemNumber !== "50-0300" && _item.itemNumber !== "50-0500" && _item.itemNumber !== "01-0500" && _item.itemNumber !== "");
    const subTotal = lineItems.reduce(addLineItems, ScheduleOfValuesLineItemRow.EmptySOVLineItem);
    subTotal.description = "TOTAL HARD COSTS";
    const total = currentDraw?.lineItems.reduce(addLineItems, ScheduleOfValuesLineItemRow.EmptySOVLineItem);
    total.description = "TOTAL COSTS";

    if (updateSoftCosts) {
      this.setState({
        subTotalLineItem: subTotal,
        totalLineItem: total
      }, () => this.updateSoftCostsSOV());
    }
    else {
      this.setState({
        subTotalLineItem: subTotal,
        totalLineItem: total
      });
    }
  }

  async addDraw(): Promise<void> {
    await axios.post("./api/draw-requests/" + this.state.uid, {}, {
      validateStatus: (status) => { return status === 202 }
    }).then(() => {
      let callbackUrl = window.location.href.includes("currentTab") ? window.location.href : window.location.href + "&currentTab=1";
      this.warningModal.current.show("The Next Round of Pay Apps has been created", "Payment Application(s) Created", () => window.location.replace(callbackUrl));
    }).catch(() => {
      this.warningModal.current.show("Unable to create the Payment Application. Please contact Johnny Green or Tom Hill for assistance.");
    })
  }

  async recalculatePrevious(): Promise<void> {
    await axios.post("./api/draw-requests/" + this.state.uid + "/recalculate-previous").then((response) => {
      this.warningModal.current.show("The Previous Billed Column has been recalculated. The page will refresh once this modal is closed", "Previous Billed Recalculated", () => window.location.reload());
    })
  }

  calculateUniqueLineItem(lineItem: ScheduleOfValuesLineItem): ScheduleOfValuesLineItem {
    let _lineItem: ScheduleOfValuesLineItem = {
      scheduledValues: lineItem.scheduledValues,
      uid: lineItem.uid,
      itemNumber: lineItem.itemNumber,
      description: lineItem.description,
      previous: lineItem.previous,
      thisPeriod: round((lineItem.scheduledValues * this.state.subTotalLineItem.percentage) - lineItem.previous),
      materials: round(lineItem.materials * this.state.subTotalLineItem.percentage),
      percentage: this.state.subTotalLineItem.percentage,
      totalCompleted: 0,
      costCategory: undefined
    };
    _lineItem.totalCompleted = lineItem.thisPeriod + lineItem.materials + lineItem.previous;
    return _lineItem;
  }

  async updateSoftCostsSOV(): Promise<void> {
    let lineItems = this.state.draw.lineItems;
    let generalReqs = lineItems.filter(item => item.itemNumber === "01-0500")[0];
    let generalReqsIdx = lineItems.indexOf(generalReqs);
    let generalOver = lineItems.filter(item => item.itemNumber === "50-0500")[0];
    let generalOverIdx = lineItems.indexOf(generalOver);
    let profit = lineItems.filter(item => item.itemNumber === "")[0];
    let profitIdx = lineItems.indexOf(profit);

    let _generalReqs = this.calculateUniqueLineItem(generalReqs);
    let _generalOver = this.calculateUniqueLineItem(generalOver);
    let _profit = this.calculateUniqueLineItem(profit);

    if (JSON.stringify(_generalReqs) !== JSON.stringify(generalReqs)) {
      lineItems[generalReqsIdx] = _generalReqs;
      await axios.put('./api/draw-request-line-items', _generalReqs);
    }

    if (JSON.stringify(_generalOver) !== JSON.stringify(generalOver)) {
      lineItems[generalOverIdx] = _generalOver;
      await axios.put('./api/draw-request-line-items', _generalOver);
    }

    if (JSON.stringify(_profit) !== JSON.stringify(profit)) {
      lineItems[profitIdx] = _profit;
      await axios.put('./api/draw-request-line-items', _profit);
    }
    this.state.draw.lineItems = lineItems;
    this.setState({});
  }

  render(): JSX.Element {
    return (
      <div>
        <WarningModal ref={this.warningModal} />
        <YesNoModal ref={this.yesNoModal} />
        <StatusModal ref={this.statusModal} />
        <div>
          <Row>
            <Col>
              <h5 style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>Site</h5>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              <Select
                styles={reactSelectBasicStyle}
                options={this.state.sites.map(function (item) { return { label: item.label, value: item.value } })}
                value={this.state.site}
                onChange={this.changeSite}
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col>
              <h5 style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>Draw</h5>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              <Select
                styles={reactSelectBasicStyle}
                options={this.state.draws}
                value={{ label: "Pay App #" + (this.state.draw?.number + 1), value: (this.state.draw?.uid) }}
                onChange={this.changeDraw}
              />
            </Col>
            <Col>
              <h5 style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>Add Pay Application</h5>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              <input type="button" value="Add" className="standard-input" onClick={this.addDraw} />
            </Col>
            <Col>
              <h5 style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>Recalculate Previous Billed</h5>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              <input type="button" value="Calculate" className="standard-input" onClick={this.recalculatePrevious} />
            </Col>
          </Row>
          <hr />
          <div style={{ height: "71vh", overflowY: "scroll", overflowX: "hidden" }}>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={generateScheduleOfValuesHeaders()} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.loaded && this.state.draw && this.state.draw?.lineItems.map((line) => {
                    if (line.itemNumber !== "01-0500" && line.itemNumber !== "50-0500"
                      && line.itemNumber !== "01-0600" && line.itemNumber !== "" && line.itemNumber !== "50-0300") {
                      return <ScheduleOfValuesLineItemRow key={this.state.site.label + "_" + line.itemNumber + "_" + this.state.draw.uid} line={line}
                        update={this.getSubTotalAndTotalValues} locked={this.state.draw.locked} api="/api/draw-request-line-items" lockScheduledValues={this.state.draw.number !== 0}
                      />
                    }
                  })
                }
                {
                  this.state.loaded && this.state.draw?.lineItems.filter(x => x.itemNumber === "01-0600").map((item) => (
                    <ScheduleOfValuesLineItemRow key={this.state.site.label + "_" + item.itemNumber + "_" + this.state.draw.uid} line={item}
                      update={this.getSubTotalAndTotalValues} locked={this.state.draw.locked} api="/api/draw-request-line-items" lockScheduledValues={this.state.draw.number !== 0}
                    />
                  ))
                }
                {
                  this.state.loaded && <BreakRow data={this.state.subTotalLineItem} />
                }
                {
                  this.state.loaded && this.state.draw?.lineItems.filter(x => x.itemNumber === "01-0500").map((item) => (
                    <ScheduleOfValuesLineItemRow key={this.state.site.label + "_" + item.itemNumber + "_" + this.state.draw.uid + "_" + item.thisPeriod} line={item}
                      update={this.getSubTotalAndTotalValues} locked={true} api="/api/draw-request-line-items" lockScheduledValues={this.state.draw.number !== 0}
                    />
                  ))
                }
                {
                  this.state.loaded && this.state.draw?.lineItems.filter(x => x.itemNumber === "50-0500").map((item) => (
                    <ScheduleOfValuesLineItemRow key={this.state.site.label + "_" + item.itemNumber + "_" + this.state.draw.uid + "_" + item.thisPeriod} line={item}
                      update={this.getSubTotalAndTotalValues} locked={true} api="/api/draw-request-line-items" lockScheduledValues={this.state.draw.number !== 0}
                    />
                  ))
                }
                {
                  this.state.loaded && this.state.draw?.lineItems.filter(x => x.itemNumber === "50-0300").map((item) => (
                    <ScheduleOfValuesLineItemRow key={this.state.site.label + "_" + item.itemNumber + "_" + this.state.draw.uid} line={item}
                      update={this.getSubTotalAndTotalValues} locked={this.state.draw.number !== 0} api="/api/draw-request-line-items" lockScheduledValues={this.state.draw.number !== 0}
                    />
                  ))
                }
                {
                  this.state.loaded && this.state.draw?.lineItems.filter(x => x.itemNumber === "").map((item) => (
                    <ScheduleOfValuesLineItemRow key={this.state.site.label + "_" + item.itemNumber + "_" + this.state.draw.uid + "_" + item.thisPeriod} line={item}
                      update={this.getSubTotalAndTotalValues} locked={true} api="/api/draw-request-line-items" lockScheduledValues={this.state.draw.number !== 0}
                    />
                  ))
                }
                {
                  this.state.loaded && <BreakRow data={this.state.totalLineItem} />
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

//#region Helper Components
const BreakRow = (props: Structs.BreakRowProps) => {
  return (
    <tr style={{ backgroundColor: "#b89961", color: "#15405c" }}>
      <td className="fixedTableCellFirst" style={{ backgroundColor: "#b89961", color: "#15405c" }}></td>
      <td className="fixedTableCell" style={{ backgroundColor: "#b89961", color: "#15405c" }}>{props.data.description}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", backgroundColor: "#b89961", color: "#15405c" }}>{numberWithCommas(props.data.scheduledValues)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", backgroundColor: "#b89961", color: "#15405c" }}>{numberWithCommas(props.data.previous)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", backgroundColor: "#b89961", color: "#15405c" }}>{numberWithCommas(props.data.thisPeriod)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", backgroundColor: "#b89961", color: "#15405c" }}>{numberWithCommas(props.data.materials)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", backgroundColor: "#b89961", color: "#15405c" }}>{numberWithCommas(props.data.thisPeriod + props.data.materials + props.data.previous)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", backgroundColor: "#b89961", color: "#15405c" }}>{props.data.percentage && numberWithCommas(props.data.percentage * 100)}%</td>
      <td className="fixedTableCell" style={{ textAlign: "right", backgroundColor: "#b89961", color: "#15405c" }}>{numberWithCommas(props.data.scheduledValues - props.data.thisPeriod + props.data.materials + props.data.previous)}</td>
    </tr>
  )
}
//#endregion