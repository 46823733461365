import * as React from "react";
import { Container, Row, Col } from 'reactstrap';
//import $ from 'jquery';
import axios from 'axios';
//@ts-ignore
import GoogleMapReact from 'google-map-react';
import { FullConsumption } from './ScreenerConsumption'
import { ScreenerProduction } from './ScreenerProduction'
import { MeteringSettings } from './MeteringSettings'
import { ScreenerLease } from "./ScreenerLease";
import { ScreenerFinancing } from "./ScreenerFinancing";
import { ScreenerLoan } from "./ScreenerLoan";
import { getBearerToken } from "../../functions/authActions";

interface MainSolarScreenerProps {

}

interface MainSolarScreenerState {
  propertyId: string;
  center: MapCenterInterface;
  zoom: number;
  properties: Array<any>;
  currentTab: number;
  screenerId: string;
  settingsId: string;
  financeId: string;
  consumptionCount: number;
  meterType: number;
}

interface MapCenterInterface {
  lat: number;
  lng: number;
}

export class MainSolarScreener extends React.Component<MainSolarScreenerProps, MainSolarScreenerState>  {

  static displayName = MainSolarScreener.name;

  constructor(props: MainSolarScreenerProps) {
    super(props);
    var pathList = window.location.pathname.split("/");
    var UID = pathList[pathList.length - 1];
    this.state = {
      propertyId: UID,
      center: {
        lat: 45.425280,
        lng: -117.275910
      },
      zoom: 14,
      properties: [],
      currentTab: 0,
      screenerId: '',
      settingsId: '',
      financeId: '',
      consumptionCount: 0,
      meterType: 0
    }
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get("./api/property/" + this.state.propertyId)
      .then(function (response) {
        const data = response.data;
        const arr = [];
        arr.push({
          key: data.uid,
          lat: data.latitude,
          lng: data.longitude,
          name: data.name,
          color: data.hexadecimalColor
        })
        self.setState({ 'properties': arr });
      });
    await axios.get('./api/solar-screener/' + this.state.propertyId).then(function (response) {
      var settings = response.data.meteringSettings !== null ? response.data.meteringSettings.UID : '';
      if (response.data.solarProjectFinancing !== null) {
        self.setState({
          financeId: response.data.solarProjectFinancing.uid
        })
      }
      self.setState({
        screenerId: response.data.uid,
        settingsId: settings,
        consumptionCount: response.data.consumptionLines.length,
        meterType: response.data.meteringType
      });
    }).catch(function (response) {
      axios.post("./api/solar-screener/" + self.state.propertyId)
        .then(function (_response) {
          self.setState({
            screenerId: _response.data
          })
        })
    });
  }

  getTab(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <PropertyMap propertyId={this.state.propertyId} />
      case 1:
        return <MeteringSettings key={"meter-settings-" + Math.random()} property={this.state.propertyId} screener={this.state.screenerId} settings={this.state.settingsId} />
      case 2:
        return <FullConsumption property={this.state.propertyId} screener={this.state.screenerId} settings={this.state.settingsId} meterType={this.state.meterType} />
      case 3:
        return <ScreenerProduction property={this.state.propertyId} screener={this.state.screenerId} settings={this.state.settingsId} />
      case 4:
        return <ScreenerFinancing finance={this.state.financeId} screener={this.state.screenerId} />
      default:
        console.log("Error, invalid tab value");
    }
  }

  render(): JSX.Element {
    return (
      <Container fluid>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ marginLeft: "-16px" }}>
            <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "17%" }} value="Property Map" onClick={() => this.setState({ currentTab: 0 })} />
            <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "17%" }} value="Meter Setup" onClick={() => this.setState({ currentTab: 1 })} />
            {this.state.propertyId !== '' && this.state.screenerId !== '' && this.state.settingsId !== '' &&
              <input type="button" className={this.state.currentTab === 2 ? "tab-active" : "tab"} style={{ width: "17%" }} value="Consumption" onClick={() => this.setState({ currentTab: 2 })} />
            }
            {this.state.propertyId !== '' && this.state.screenerId !== '' && this.state.settingsId !== '' && this.state.consumptionCount === 24 &&
              <input type="button" className={this.state.currentTab === 3 ? "tab-active" : "tab"} style={{ width: "17%" }} value="Production" onClick={() => this.setState({ currentTab: 3 })} />
            }
            {this.state.propertyId !== '' && this.state.screenerId !== '' && this.state.settingsId !== '' && this.state.consumptionCount == 24 &&
              <input type="button" className={this.state.currentTab === 4 ? "tab-active" : "tab"} style={{ width: "17%" }} value="Equity & Financing" onClick={() => this.setState({ currentTab: 4 })} />
            }
          </Col>
        </Row>
        {
          this.getTab()
        }

      </Container>
    )
  }
}

interface PropertyMapProps {
  propertyId: string;
}

interface PropertyMapState {
  propertyId: string;
  center: MapCenterInterface;
  zoom: number;
}

class PropertyMap extends React.Component<PropertyMapProps, PropertyMapState>{

  constructor(props: PropertyMapProps) {
    super(props);
    this.state = {
      propertyId: props.propertyId,
      center: {
        lat: 45.425280,
        lng: -117.275910
      },
      zoom: 14
    }
  }

  map: any;

  centerProperty(): void {
    var self = this;
    axios.get("./api/property/" + this.state.propertyId)
      .then(function (response) {
        const data = response.data;
        if (self.map !== null) {
          self.map.panTo({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
          self.map.setZoom(18);
        }
      });
  }

  render(): JSX.Element {
    return (
      <div id="property-tab" style={{ height: "80vh" }}>
        <Row style={{ margin: "0px", padding: "0px", width: "100%", marginLeft: "-15px" }}>
          <Col style={{ margin: "0px", padding: "0px" }}>
            <div id="property-map" style={{ height: '75vh', width: '100%', border: "3px solid #c2a877", margin: "0px", padding: "0px" }} >
              <GoogleMapReact
                key="map"
                bootstrapURLKeys={{ key: "AIzaSyDF_xQZ5PZZnYBNn-V4-btiQD3Az9zJ3Qo" }}
                center={this.state.center}
                defaultZoom={this.state.zoom}
                yesIWantToUseGoogleMapApiInternals
                //@ts-ignore
                onGoogleApiLoaded={({ map, maps }) => {
                  this.map = map;
                  this.centerProperty();
                }}
                options={{
                  disableDefaultUI: true,
                  mapTypeId: "satellite",
                  mapTypeControl: true,
                  streetViewControl: true,
                  zoomControl: true,
                  mapTypeControlOptions: {
                    mapTypeIds: [
                      "roadmap",
                      "satellite"
                    ]
                  }
                }}
              >
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </div>

    )
  }
}