import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <GoogleOAuthProvider clientId="47591139859-d0flrm9a5vnd6hoalj4pto34f0ihkgl8.apps.googleusercontent.com">
        <BrowserRouter basename={baseUrl} >
          <App />
        </BrowserRouter>
    </GoogleOAuthProvider>,
  rootElement);

//registerServiceWorker();
unregister();
