import * as React from "react";
import { Col, Row } from "reactstrap";
import { WarningModal } from "../../CoreComponents/Modals";
import { UsefulLinksWidget } from "../../CoreComponents/UsefulLinksWidget";
import { DrawRequestSummaryTable } from "../../JobManagementComponents/DashboardWidgets/DrawRequestWorkflowSummary";
import { FinanceSnapshotWidget } from "../ConstructionDashboardWidgets/FinanceSnapshotWidget";
import { GeneralContractingProjectSnapshotWidget } from "../ConstructionDashboardWidgets/GeneralContractingProjectSnapshotWidget ";
import { ConstructionFinancialOfficerDasboardProps, ConstructionFinancialOfficerDasboardState } from "./DevelopmentFinanceDashboard.constants";

export default class DevelopmentFinanceDasboard extends React.Component<ConstructionFinancialOfficerDasboardProps, ConstructionFinancialOfficerDasboardState> {

  warningModal = React.createRef<WarningModal>();

  constructor(props: ConstructionFinancialOfficerDasboardProps) {
    super(props);
    this.state = {

    }
    this.triggerWarningModal = this.triggerWarningModal.bind(this);
    
  }

  triggerWarningModal(message: string, title: string): void {
    this.warningModal.current.show(message, title, () => window.location.reload());
  }

  render(): JSX.Element {
    return (
      <div style={{ width: "101%", marginLeft: "-1%", height: "90vh", background: "#4C4A42", border: "2px solid #d7c7a7" }}>
        <h4 style={{ width: "100%", textAlign: "center" }}>Development Finance Dashboard</h4>
        <WarningModal ref={this.warningModal} />
        <>
          <Row>
            <Col>
              <DrawRequestSummaryTable />
            </Col>
          </Row>
          <hr style={{ height: "5px", marginLeft: "1%", marginRight: "1%" }} />
          <Row>
            <Col style={{ marginLeft: "1%" }}>
              <UsefulLinksWidget />
            </Col>
            <Col>
              <GeneralContractingProjectSnapshotWidget isPresident={true} useBoth={true} hideValues={false} />
            </Col>
            <Col>
            </Col>
            <Col style={{ marginRight: "1%" }}>
              <FinanceSnapshotWidget isPresident={true} />
            </Col>
          </Row>
        </>
      </div>
    )
  }
}