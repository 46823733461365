import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { EntityManagementTableSetting } from '../../../reducers/TableSettingStructures';
import { EntityTableSettingsModalData } from '../Structs';

export class EntityTableSettingsModal extends React.Component<EntityTableSettingsModalData, EntityTableSettingsModalData> {

  constructor(props: EntityTableSettingsModalData) {
    super(props);
    this.state = {
      showing: false,
      entityTableSettings: props.entityTableSettings,
      updateSettings: props.updateSettings,
      showAll: props.showAll
    }
    this.show = this.show.bind(this);
    this.setValue = this.setValue.bind(this);
    this.selectAllOrNone = this.selectAllOrNone.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const tableSettings: EntityManagementTableSetting = this.state.entityTableSettings;
    let isAll = true;
    const keys = Object.keys(tableSettings)
    for (let i = 0; i < keys.length; ++i) {
      //@ts-ignore
      if (!tableSettings[keys[i]]) {
        isAll = false
      }
    }
    this.setState({showAll: isAll})
  }

  show(): void {
    this.setState({
      showing: true
    })
  }

  setValue(event: React.ChangeEvent<HTMLInputElement>): void {
    const entityTableSettings: EntityManagementTableSetting = this.state.entityTableSettings;
    //@ts-ignore
    entityTableSettings[event.target.name] = event.target.checked;
    this.setState({
      entityTableSettings: entityTableSettings
    })
  }

  selectAllOrNone(): void {
    const tableSettings: EntityManagementTableSetting = this.state.entityTableSettings;
    let isAll = true;
    const keys = Object.keys(tableSettings)
    for (let i = 0; i < keys.length; ++i) {
      //@ts-ignore
      if (!tableSettings[keys[i]]) {
        isAll = false
      }
    }
    let setValue: boolean = true;
    if (isAll) {
      setValue = false;
    }
    for (let i = 0; i < keys.length; ++i) {
      if (keys[i].toLocaleLowerCase().includes("width")) {
        continue;
      }
      //@ts-ignore
      tableSettings[keys[i]] = setValue;
    }
    this.setState({
      entityTableSettings: tableSettings,
      showAll: setValue
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing}>
        <ModalHeader style={{ color: "#15405c" }} tag="h3" toggle={() => this.setState({ showing: false })}>
          Table Settings
        </ModalHeader>
        <ModalBody>
          <h5 style={{ color: "#15405c", width: "100%", textAlign: "center" }}>
            Please Choose the Current Columns
          </h5>
          <>
            <Row>
              <Col xs='10'></Col>
              <Col>Show</Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>All/None</h5>
              </Col>
              <Col>
                <input type="checkbox" style={{ marginLeft: "15px" }} checked={this.state.showAll} onChange={this.selectAllOrNone} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Name</h5>
              </Col>
              <Col>
                <input type="checkbox" style={{ marginLeft: "15px" }} disabled checked />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Accounting Firm</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showAccountingFirm" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showAccountingFirm} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Unique Code</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showYardiUniqueCode" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showYardiUniqueCode} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Yardi Account</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showYardiAccount" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showYardiAccount} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Tax ID (EIN)</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showTaxID" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showTaxID} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>UEI</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showUei" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showUei} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>CAGE</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showCage" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showCage} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>CAGE Expiration</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showCageExp" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showCageExp} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Managing Members</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showManagingMember" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showManagingMember} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Partners</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showPartners" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showPartners} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Authorized Signers</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showAuthorizedSigners" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showAuthorizedSigners} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Formation State</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showFormationState" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showFormationState} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>SOS Renewal Date</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showSosRenewalDate" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showSosRenewalDate} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Entity Type</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showEntityType" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showEntityType} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>S-Corp Election</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showSCorpElection" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showSCorpElection} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Entity Status</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showEntityStatus" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showEntityStatus} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Formation Date</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showFormationDate" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showFormationDate} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Registered Agent</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showRegisteredAgents" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showRegisteredAgents} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>First Year Tax Return</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showFirstYearTaxReturn" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showFirstYearTaxReturn} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Final Year Tax Return</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showFinalYearTaxReturn" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showFinalYearTaxReturn} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Entity Manager</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showEntityManager" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showEntityManager} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Accountant</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showAccountant" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showAccountant} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Street Address</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showStreetAddress" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showStreetAddress} onChange={this.setValue} />
              </Col>
            </Row>
            <Row>
              <Col xs='10'>
                <h5 style={{ color: "#15405c" }}>Notes</h5>
              </Col>
              <Col>
                <input type="checkbox" name="showNotes" style={{ marginLeft: "15px" }} checked={this.state.entityTableSettings.showNotes} onChange={this.setValue} />
              </Col>
            </Row>
          </>
        </ModalBody>
        <ModalFooter>
          <input type="button" value="Close" style={{
            backgroundColor: "#15405c",
            color: "#c2a877"
          }} onClick={() => this.setState({ showing: false })} />
          <input type="button" value="Save Changes" style={{
            backgroundColor: "#15405c",
            color: "#c2a877"
          }} onClick={() => { this.setState({ showing: false }); this.state.updateSettings(this.state.entityTableSettings) }} />
        </ModalFooter>
      </Modal>
    )
  }
}