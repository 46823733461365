import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';

interface TicketCategoryDocumentationMapProps {
  uid: string;
}


interface TicketCategoryDocumentationMapState {
  headers: Array<FilterAndSettingField>;
  uid: string;
}

export default class TicketCategoryDocumentationMap extends React.Component<TicketCategoryDocumentationMapProps, TicketCategoryDocumentationMapState>{

  constructor(props: TicketCategoryDocumentationMapProps) {
    super(props);
    this.state = {
      uid: props.uid,
      headers: []
    }
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Category",
        setting: true,
        filter: {}
      },
      {
        columnName: "Link",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      }
    ]
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <div>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>

          </tbody>
        </table>
      </div>
    )
  }
}