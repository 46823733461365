import * as React from 'react';

export function RenderHeader(title: string): JSX.Element {
  return (
    <>
      <h5 style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>{title}</h5>
      <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
    </>
  )
}

export function RenderTitleHeader(title: string): JSX.Element {
  return (
    <>
      <h3 style={{ fontSize: "24px", width: "100%", textAlign: "center" }}>{title}</h3>
      <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
    </>
  )
}