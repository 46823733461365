//#region Imports
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { getBearerToken } from '../../../functions/authActions';
import { pullDrawsBySite } from '../../../functions/fetchLinkedObjects';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import { ChangeEvent, ChangeEventLineItem } from '../../CoreComponents/ScheduleOfValues/ChangeOrderLineItem';
import { ScheduleOfValuesLineItemRow } from '../../CoreComponents/ScheduleOfValues/ScheduleOfValuesLineItem';
import * as Structs from '../Structs';
import { CreateChangeEventModal, CreateChangeEventRequest, ChangeOrderActionModal, CreateChangeOrderRequest, EditSoftCostRequest } from './JobManagementModals';
//#endregion

function addChangeEvent(a: ChangeEvent, x: ChangeEvent): ChangeEvent {
  return {
    uid: EMPTY_GUID,
    eventNumber: -1,
    description: "",
    type: { label: "Hold Type", value: "-1" },
    scheduledValues: a.scheduledValues + x.scheduledValues,
    previousBilled: a.previousBilled + x.previousBilled,
    materials: a.materials + x.materials,
    thisPeriod: a.thisPeriod + x.thisPeriod,
    retention: a.retention + x.retention,
    totalCompleted: a.totalCompleted + x.totalCompleted,
    percentage: (a.totalCompleted + x.totalCompleted) / (a.scheduledValues + x.scheduledValues),
    balance: a.balance + x.balance
  }
}

function calculateSoftCost(a: ChangeEvent, x: number): ChangeEvent {
  return {
    uid: EMPTY_GUID,
    eventNumber: -1,
    description: "",
    type: { label: "Hold Type", value: "-1" },
    scheduledValues: a.scheduledValues * x,
    previousBilled: a.previousBilled * x,
    materials: a.materials * x,
    thisPeriod: a.thisPeriod * x,
    retention: ((a.thisPeriod + a.materials) * x) * 0.05,
    totalCompleted: a.totalCompleted * x,
    percentage: (a.totalCompleted * x) / (a.scheduledValues * x),
    balance: a.balance * x
  }
}

export class ChangeOrderManagement extends React.Component<{ uid?: string }, Structs.ChangeOrderState>{

  warningModal = React.createRef<WarningModal>();
  createModal = React.createRef<ChangeOrderActionModal>();
  createEventModal = React.createRef<CreateChangeEventModal>();
  statusModal = React.createRef<StatusModal>();

  constructor(props: { uid?: string }) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const uid = props.uid ? props.uid : params.get('uid');
    this.state = {
      headers: this.generateHeaders(),
      sites: [],
      site: { label: "Select Site", value: "" },
      currentOrder: 0,
      draws: [],
      isUsable: false,
      uid: uid,

      changeOrders: [],
      changeOrder: null,

      subTotal: ChangeEventLineItem.EmptyCE,
      total: ChangeEventLineItem.EmptyCE,
      generalReqs: ChangeEventLineItem.EmptyCE,
      generalOverhead: ChangeEventLineItem.EmptyCE,
      bondSurety: ChangeEventLineItem.EmptyCE,
      profit: ChangeEventLineItem.EmptyCE,
    }
    this.changeSite = this.changeSite.bind(this);
    this.changeChangeOrder = this.changeChangeOrder.bind(this);
    this.addChangeEvent = this.addChangeEvent.bind(this);
    this.calculateTotalForChangeOrder = this.calculateTotalForChangeOrder.bind(this);
    this.changeChangeOrder = this.changeChangeOrder.bind(this);
    this.createNewChangeOrder = this.createNewChangeOrder.bind(this);
    this.pushChangeOrderToServer = this.pushChangeOrderToServer.bind(this);
    this.pushChangeEventToServer = this.pushChangeEventToServer.bind(this);
    this.removeChangeEvent = this.removeChangeEvent.bind(this);
    this.moveCurrentPeriod = this.moveCurrentPeriod.bind(this);
    this.editSoftCosts = this.editSoftCosts.bind(this);
    this.deleteLatestChangeOrder = this.deleteLatestChangeOrder.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get("./api/managed-jobs/projects/" + this.state.uid).then(function (response) {
      const data = response.data;
      const sites = data.map(function (item: any) { return { label: item.property, value: item.projectUID } });
      let params = new URLSearchParams(window.location.search);
      var siteIdx = params.has('siteIdx') ? parseInt(params.get('siteIdx')) : 0;
      self.setState({
        sites: sites,
        site: sites[siteIdx],
        isUsable: true
      }, () => self.changeSite(sites[siteIdx]))
    })
  }

  async changeSite(event: SelectOptions): Promise<void> {
    this.setState({ site: event })
    if (event.value !== undefined) {
      let draws = await pullDrawsBySite(event.value);
      this.setState({ draws: draws });
      await axios.get('./api/change-orders/' + event.value + "/minimal").then((response) => {
        if (response.data.length === 0) {
          this.warningModal.current.show("No Change Orders Exist", "No Change Orders");
          this.setState({
            changeOrders: []
          })
          return;
        }
        else {
          let params = new URLSearchParams(window.location.search);
          var changeOrderIdx = params.has('changeOrder') ? parseInt(params.get('changeOrder')) : response.data.length - 1;
          let changeOrder = changeOrderIdx < response.data.length ? response.data[changeOrderIdx] : response.data[response.data.length - 1];
          this.setState({
            changeOrders: response.data
          }, () => this.changeChangeOrder(changeOrder))
        }
      })
    }
  }

  addChangeEvent(): void {
    if (this.state.changeOrder === null) {
      this.warningModal.current.show("Please create a Change Order before adding events", "Requires Change Order");
      return;
    }
    this.createEventModal.current.show(this.pushChangeEventToServer);
  }

  calculateTotalForChangeOrder(): void {
    if (this.state.changeOrder === undefined || this.state.changeOrder.lineItems === undefined) {
      return;
    }
    const subTotal = this.state.changeOrder.lineItems.reduce(addChangeEvent, ChangeEventLineItem.EmptyCE);
    subTotal.description = "SUBTOTAL";
    const generalRequirements = calculateSoftCost(subTotal, this.state.changeOrder.requirementsPercentage);
    generalRequirements.description = "General Requirements";
    const generalOverhead = calculateSoftCost(subTotal, this.state.changeOrder.overheadPercentage);
    generalOverhead.description = "General Overhead";

    const profit = calculateSoftCost(subTotal, this.state.changeOrder.profitPercentage);
    profit.description = "Profit";
    let tempTotal = addChangeEvent(addChangeEvent(addChangeEvent(subTotal, generalRequirements), generalOverhead), profit);
    const bond = calculateSoftCost(tempTotal, this.state.changeOrder.bondPercentage);
    bond.description = "Bond / Surety";
    let total = addChangeEvent(tempTotal, bond);
    total.description = "Total";
    this.setState({
      subTotal: subTotal,
      generalReqs: generalRequirements,
      generalOverhead: generalOverhead,
      bondSurety: bond,
      profit: profit,
      total: total
    })
  }

  async changeChangeOrder(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    await axios.get("./api/change-orders/" + event.value + "/retrieve").then((response) => {
      console.log(response.data);
      const changeOrder: Structs.ChangeOrderData = {
        uid: response.data.uid,
        lineItems: this.mapLineItems(response.data.lineItems),
        draw: response.data.drawMappings[0].draw.number + 1,
        changeOrderNumber: response.data.changeOrderNumber,
        profitPercentage: response.data.profitPercentage,
        overheadPercentage: response.data.overheadPercentage,
        requirementsPercentage: response.data.generalRequirementsPercentage,
        bondPercentage: response.data.bondPercentage,
      }
      this.setState({
        changeOrder: changeOrder,
      }, () => this.calculateTotalForChangeOrder())
    })
  }

  mapLineItems(data: Array<any>) {
    let output: Array<any> = [];
    data.forEach((item) => {
      let map = ScheduleOfValuesLineItemRow.Map.filter(u => u.item === item.type)[0];
      item.type = { label: map.description, value: map.item };
      output.push(item);
    });
    return output;
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "CE #",
        setting: true,
        filter: {},
        width: 75
      },
      {
        columnName: "Type",
        setting: true,
        filter: {},
      },
      {
        columnName: "Description",
        setting: true,
        filter: {}
      },
      {
        columnName: "Scheduled Values",
        setting: true,
        filter: {}
      },
      {
        columnName: "Previous Billed",
        setting: true,
        filter: {}
      },
      {
        columnName: "This Period",
        setting: true,
        filter: {}
      },
      {
        columnName: "Materials",
        setting: true,
        filter: {},
      },
      {
        columnName: "Retention",
        setting: true,
        filter: {},
        additionalClasses: "ten",
        width: 100
      },
      {
        columnName: "Total Completed",
        setting: true,
        filter: {},
        width: 125
      },
      {
        columnName: "%",
        setting: true,
        filter: {},
        additionalClasses: "center-table-contents",
        width: 75
      },
      {
        columnName: "Balance",
        setting: true,
        filter: {},
        additionalClasses: "ten",
        width: 125
      },
      {
        columnName: "",
        setting: true,
        filter: {},
        width: 50
      },
    ]
  }

  createNewChangeOrder(): void {
    if (this.state.site.value !== "") {
      this.createModal.current.show(this.state.site.value, this.state.changeOrder?.requirementsPercentage ?? 0,
        this.state.changeOrder?.overheadPercentage ?? 0, this.state.changeOrder?.bondPercentage ?? 0,
        this.state.changeOrder?.profitPercentage ?? 0, this.state.changeOrder?.uid ?? ""
      );
    }
    else {
      this.warningModal.current.show("Please select site before creating Change Order");
    }
  }

  async pushChangeOrderToServer(data: CreateChangeOrderRequest): Promise<void> {
    await axios.post('./api/change-orders', data).then((response) => {
      const changeOrder: Structs.ChangeOrderData = {
        uid: response.data.uid,
        lineItems: response.data.lineItems,
        draw: response.data.drawMappings[0].draw.number + 1,
        changeOrderNumber: response.data.changeOrderNumber,
        profitPercentage: response.data.profitPercentage,
        overheadPercentage: response.data.overheadPercentage,
        requirementsPercentage: response.data.requirementsPercentage,
        bondPercentage: response.data.bondPercentage
      }
      this.setState({
        changeOrder: changeOrder,
      })
    }) 
  }

  async pushChangeEventToServer(data: CreateChangeEventRequest): Promise<void> {
    data.changeOrderUID = this.state.changeOrder.uid;
    await axios.post('./api/change-order-line-items', data).then((response) => {
      let callbackUrl = window.location.href.includes("currentTab") ? window.location.href : window.location.href + "&currentTab=2";
      callbackUrl = callbackUrl.includes("changeOrder")
        ? callbackUrl.substring(0, callbackUrl.indexOf('&changeOrder')) + "&changeOrder=" + this.state.changeOrder.changeOrderNumber
        : callbackUrl + "&changeOrder=" + this.state.changeOrder.changeOrderNumber;
      callbackUrl = callbackUrl.includes("siteIdx")
        ? callbackUrl.substring(0, callbackUrl.indexOf('&siteIdx')) + "&siteIdx=" + this.state.sites.indexOf(this.state.site)
        : callbackUrl + "&siteIdx=" + this.state.sites.indexOf(this.state.site)
      window.location.replace(callbackUrl);
    });
  }

  async removeChangeEvent(uid: string): Promise<void> {
    await axios.delete("./api/change-order-line-items/" + uid).then((response) => {
      let callbackUrl = window.location.href.includes("currentTab") ? window.location.href : window.location.href + "&currentTab=2";
      callbackUrl = callbackUrl.includes("changeOrder")
        ? callbackUrl.substring(0, callbackUrl.indexOf('&changeOrder')) + "&changeOrder=" + this.state.changeOrder.changeOrderNumber
        : callbackUrl + "&changeOrder=" + this.state.changeOrder.changeOrderNumber
      callbackUrl = callbackUrl.includes("siteIdx")
        ? callbackUrl.substring(0, callbackUrl.indexOf('&siteIdx')) + "&siteIdx=" + this.state.sites.indexOf(this.state.site)
        : callbackUrl + "&siteIdx=" + this.state.sites.indexOf(this.state.site)
      window.location.replace(callbackUrl);
    })
  }

  async moveCurrentPeriod(): Promise<void> {
    this.statusModal.current.display("Updating All Change Orders", "We are currently updating all change orders moving the Materials & Current Period to previously billed");
    await axios.post('./api/managed-jobs/change-order-update/' + this.state.uid, {}, {
      validateStatus: (status) => { return status === 202 }
    })
      .then(() => {
        this.statusModal.current.hide();
        this.warningModal.current.show("The Change Orders have been updated", "Change Orders Updated");
      })
      .catch(() => {
        this.statusModal.current.hide();
        this.warningModal.current.show("Unknown Error has occured. Please contact Tom or Johnny", "Unknown Error");
      })
  }

  async editSoftCosts(request: EditSoftCostRequest): Promise<void> {
    await axios.patch('./api/change-orders', request, {
      validateStatus: (status) => {return status === 202 }
    }).then((response) => {
      this.warningModal.current.show("Soft Costs have been updated successfully.", "Soft Costs Updated");
      let changeOrder = this.state.changeOrder;
      changeOrder.requirementsPercentage = request.generalRequirements;
      changeOrder.overheadPercentage = request.overhead;
      changeOrder.bondPercentage = request.bond;
      changeOrder.profitPercentage = request.profit;
      this.setState({
        changeOrder: changeOrder
      }, () => this.calculateTotalForChangeOrder())
    }).catch((response) => {
      this.warningModal.current.show("Error Updating Soft Costs, please contact Johnny or Tom", "Error Updating Soft Costs");
    })
  }

  async deleteLatestChangeOrder() {
    let changeOrder = this.state.changeOrders[this.state.changeOrders.length - 1];
    await axios.delete("./api/change-orders/" + changeOrder.value).then((response) => {
      this.warningModal.current.show("The Change Order has been Deleted. This is not reversable & if this was done in error, you will have to recreate the latest change order from scratch.", "Change Order Removed");
    })
  }

  render(): JSX.Element {
    return (
      <div>
        {
          this.state.isUsable ?
            <>
              <ChangeOrderActionModal ref={this.createModal} createCallback={this.pushChangeOrderToServer} editCallback={this.editSoftCosts}
                moveCallback={this.moveCurrentPeriod} deleteCallback={this.deleteLatestChangeOrder}
              />
              <CreateChangeEventModal ref={this.createEventModal} />
              <WarningModal ref={this.warningModal} callback={() => window.location.reload()} />
              <StatusModal ref={this.statusModal} />
              <div style={{ height: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
                <Row>
                  <Col>
                    <label style={{ float: "left", marginRight: "1vw" }}>Site: </label>
                    <Select
                      styles={reactSelectBasicStyle}
                      options={this.state.sites}
                      value={this.state.site}
                      onChange={this.changeSite}
                      onFocus={RemoveStickyOverlays}
                      onBlur={RestoreStickyOverlays}
                    />
                  </Col>
                  <Col>
                    <label style={{ float: "left", marginRight: "1vw" }}>Change Order: </label>
                    <Select
                      styles={reactSelectBasicStyle}
                      options={this.state.changeOrders}
                      value={{ label: (this.state.changeOrder?.changeOrderNumber + 1).toFixed() ?? "You need to create a Change Order", value: this.state.changeOrder?.uid ?? "" }}
                      onChange={this.changeChangeOrder}
                    />
                  </Col>
                  <Col>
                    <input type="button" className="standard-input" value="Actions" onClick={this.createNewChangeOrder} />
                  </Col>
                </Row>
                <table className="fixedTable" style={{ marginTop: "1vh" }}>
                  <thead>
                    <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
                  </thead>
                  <tbody>
                    {
                      this.state.changeOrder?.lineItems.map((item, idx) => (
                        <ChangeEventLineItem key={item.uid} data={item} eventIdx={idx} remove={this.removeChangeEvent} callback={this.calculateTotalForChangeOrder} />
                      ))
                    }
                    <BreakRow data={this.state.subTotal} />
                    <SoftCostRow data={this.state.generalReqs} />
                    <SoftCostRow data={this.state.generalOverhead} />
                    <SoftCostRow data={this.state.bondSurety} />
                    <SoftCostRow data={this.state.profit} />
                    <BreakRow data={this.state.total} />
                    <TableAdditionRow length={12} marginLeft="30%" onClick={this.addChangeEvent} />
                  </tbody>
                </table>
              </div>
              <Row>
                <Col>
                  <label style={{ float: "left", marginRight: "1vw" }}>Associated Draw: </label>
                  <label>{this.state.changeOrder?.draw ?? "Select Change Order"}</label>
                </Col>
                <Col>
                  <label>General Requirements: </label>
                  <label>{(this.state.changeOrder?.requirementsPercentage * 100).toFixed(2) ?? "0"}%</label>
                </Col>
                <Col>
                  <label>General Overhead: </label>
                  <label>{(this.state.changeOrder?.overheadPercentage * 100).toFixed(2) ?? "0"}%</label>
                </Col>
                <Col>
                  <label>Bond / Surety: </label>
                  <label>{(this.state.changeOrder?.bondPercentage * 100).toFixed(2) ?? "0"}%</label>
                </Col>
                <Col>
                  <label>Profit: </label>
                  <label>{(this.state.changeOrder?.profitPercentage * 100).toFixed(2) ?? "0"}%</label>
                </Col>

              </Row>
            </>
            :
            <h3>Please Setup Draws Prior to Starting Change Orders</h3>
        }
      </div >
    )
  }
}


const BreakRow = (props: Structs.COBreakRowProps) => {
  return (
    <tr style={{ backgroundColor: "#b89961", color: "#15405c" }}>
      <td className="fixedTableCellFirst" style={{ backgroundColor: "#b89961", color: "#15405c" }}></td>
      <td className="fixedTableCell" style={{ color: "#15405c" }}>{props.data.description}</td>
      <td className="fixedTableCell" style={{ color: "#15405c" }}></td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{numberWithCommas(props.data.scheduledValues)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{numberWithCommas(props.data.previousBilled)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{numberWithCommas(props.data.thisPeriod)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{numberWithCommas(props.data.materials)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{numberWithCommas(props.data.retention)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{numberWithCommas(props.data.thisPeriod + props.data.materials + props.data.previousBilled)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{props.data.percentage && numberWithCommas(props.data.percentage * 100)}%</td>
      <td className="fixedTableCell" style={{ textAlign: "right", color: "#15405c" }}>{numberWithCommas(props.data.balance)}</td>
      <td className="fixedTableCell"></td>
    </tr>
  )
}

const SoftCostRow = (props: Structs.COBreakRowProps) => {
  return (
    <tr>
      <td className="fixedTableCellFirst"></td>
      <td className="fixedTableCell">{props.data.description}</td>
      <td className="fixedTableCell"></td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(props.data.scheduledValues)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(props.data.previousBilled)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(props.data.thisPeriod)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(props.data.materials)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(props.data.retention)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(props.data.totalCompleted)}</td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{props.data.percentage && numberWithCommas(props.data.percentage * 100)}%</td>
      <td className="fixedTableCell" style={{ textAlign: "right" }}>{numberWithCommas(props.data.balance)}</td>
      <td className="fixedTableCell"></td>
    </tr>
  )
}





