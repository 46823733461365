import { SelectOptions } from "../../interfaces/CoreInterfaces";

export interface ProrateToolProps {

}

export interface ProrateToolState {
  startDate: Date;
  fullAmount: string;
  proratedRate: number;
  decimalPlaces: SelectOptions;
}

export const DecimalOptions: Array<SelectOptions> = [
  { label: "None", value: "0" },
  { label: "Two", value: "2" }
]