import * as React from 'react';
import Select from 'react-select';
import { reactSelectBasicStyle_Filter } from '../../style/select-constants';
import { DatePicker } from './DateComponents';
import { Filter, FilterSelect, FilteredWithSettingsTableHeaderData, HeaderAndFilterSettingsDataProps, FilterDate, HeaderAndFilterSettingsDataState } from './interfaces';


function getIcons(icons: number, className: string): Object {
  const rows = [];
  for (let i = 0; i < icons; ++i) {
    rows.push(<th key={Math.random()} className={"icon " + className + " no-print"}></th>)
  }
  return rows;
}

export default class FilteredWithSettingsTableHeader extends React.Component<FilteredWithSettingsTableHeaderData> {

  render(): JSX.Element {
    return (
      <tr>
        <>
          {
            this.props.columns.map((item, idx) => (
              <HeaderWithFilterAndSettings key={idx} field={item} index={idx} additionalClasses={item.additionalClasses} width={item.width} widthName={item.widthName} widthCallback={item.widthCallback}
              />
            ))
          }
          {
            getIcons(this.props.icons, "fixedTableHeader")
          }
        </>
      </tr>
    )
  }
}

class HeaderWithFilterAndSettings extends React.Component<HeaderAndFilterSettingsDataProps, HeaderAndFilterSettingsDataState> {

  originalOffset: number;
  //cell: HTMLSpanElement;

  spanRef = React.createRef<HTMLSpanElement>();

  constructor(props: HeaderAndFilterSettingsDataProps) {
    super(props);
    let className = props.index === 0 ? 'fixedTableHeaderFirst ' : 'fixedTableHeader ';
    className = props.field.classOverride ? props.field.classOverride : className;
    className += props.additionalClasses ? props.additionalClasses : "";
    this.state = {
      index: props.index ? props.index : 0,
      className: className,
      width: props.width,
    }
    this.adjustWidth = this.adjustWidth.bind(this);
    this.startResize = this.startResize.bind(this);
    this.endResize = this.endResize.bind(this);
  }

  componentDidUpdate(nextProps: HeaderAndFilterSettingsDataProps): void {
    if (this.state.index !== nextProps.index) {
      this.setState({
        index: nextProps.index
      });
    }
  }

  adjustWidth = (event: MouseEvent) => requestAnimationFrame(() => {
    event.stopPropagation();
    event.preventDefault();
    if (!this.originalOffset) { return; }
    let width = event.clientX - this.originalOffset;
    // use 30 as minimum for icons.. This is probably too small normally
    width = Math.max(width, 30);
    this.setState({
      width: width
    });
  });

  startResize(event: React.MouseEvent<HTMLSpanElement>): void {
    event.stopPropagation();
    event.preventDefault();
    this.originalOffset = this.spanRef.current.closest("th").offsetLeft + this.spanRef.current.closest("table").offsetLeft - this.spanRef.current.closest("div").scrollLeft;
    window.addEventListener('mousemove', this.adjustWidth);
    window.addEventListener('mouseup', this.endResize);
  }

  endResize(): void {
    this.props.widthCallback(this.props.widthName, this.state.width);
    window.removeEventListener('mousemove', this.adjustWidth);
    window.removeEventListener('mouseup', this.endResize);
    this.originalOffset = null;
  }

  render(): JSX.Element {
    var width = this.state.width + "px";
    let styles = {};
    if (this.state.width) {
      if (this.state.width === undefined || this.state.width < 50 ) {
        width = "50px";
      }
      //@ts-ignore
      styles['width'] = width;
    }
    //@ts-ignore
    styles['fontSize'] = "16px";
    return (
      <>
        {
          this.props.field.setting && <th id={this.props.field.columnName.toLowerCase() + "-header"} className={this.state.className} style={styles}>
            {this.props.field.columnName} <br />
            {
              this.props.field.filter &&
                ('type' in this.props.field.filter) ? <StandardInput filter={this.props.field.filter} /> :
                ('multi' in this.props.field.filter) ? <SelectInput filter={this.props.field.filter} /> :
                  ('startValue' in this.props.field.filter) ? <DateInput filter={this.props.field.filter} /> :
                    <> </>
            }
            {this.state.width && this.props.widthCallback && this.props.widthName && <span ref={this.spanRef} className="resize-handle"
              onMouseDown={this.startResize}>

            </span>}
          </th>
        }
      </>
    )
  }
}

const StandardInput = (props: { filter: Filter }) => {
  return (
    <input
      id={props.filter.name+"-filter"}
      type={props.filter.type}
      name={props.filter.name}
      value={props.filter.value}
      onChange={(e) => props.filter.changeFilter(e)}
      onBlur={() => props.filter.blur?.()}
      placeholder={props.filter.placeHolder}
      style={{ width: "90%" }}
      className="standard-input"
    />
  )
}

const SelectInput = (props: { filter: FilterSelect }) => {
  return (
    <Select
      id={props.filter.name + "-filter"}
      name={props.filter.name}
      styles={reactSelectBasicStyle_Filter}
      value={props.filter.value}
      options={props.filter.options}
      onChange={props.filter.changeFilter}
      onBlur={() => props.filter.blur?.()}
      components={{ DropdownIndicator: () => null, ClearIndicator: () => null }}
      isMulti={props.filter.multi}
      menuPosition="fixed"
    />
  )
}

const DateInput = (props: { filter: FilterDate }) => {
  return (
    <>
      <DatePicker className="standard-input" onChange={props.filter.changeStartFilter} value={props.filter.startValue} />
      <DatePicker className="standard-input" onChange={props.filter.changeEndFilter} value={props.filter.endValue} />
    </>
  )
}