import axios from "axios";
import * as React from "react";
import { getBearerToken } from "../../../functions/authActions";
import FilteredWithSettingsTableHeader from "../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { PropertyBuilding, PropertyBuildingWidgetProps, PropertyBuildingWidgetState } from "./PropertyBuildingWidget.constants";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";
import PropertyBuildingWidgetModal from "./PropertyBuildingWidget.modals";
import { PropertyBuildingModalData } from "./PropertyBuildingWidget.modals.constants";

export default class PropertyBuildingWidget extends React.Component<PropertyBuildingWidgetProps, PropertyBuildingWidgetState> {

  modal: React.RefObject<PropertyBuildingWidgetModal> = React.createRef<PropertyBuildingWidgetModal>();

  constructor(props: PropertyBuildingWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      buildings: [],
      buildingHeaders: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.setHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const response = (await axios.get('./api/buildings/property/' + this.state.uid));
    if (response.status === 200) {
      this.setState({ buildings: response.data });
    } 
  }

  convertToModalData(item: PropertyBuilding): PropertyBuildingModalData {
    return {
      uid: item.uid,
      propertyUID: this.state.uid,
      propertyName: "",
      name: item.name,
      roof: new Date(item.roofReplacedShortDate),
      lastRehab: new Date(item.lastRehabShortDate),
      unitCount: item.unitCount
    }
  }

  setHeaders(): void {
    const buildingHeaders: Array<FilterAndSettingField> = [
      {
        columnName: "Name",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Unit Count",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Last Rehab",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Roof Replacement",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "",
        additionalClasses: "ten",
        setting: true,
        filter: {}
      }
    ]

    this.setState({
      buildingHeaders: buildingHeaders
    })
  }

  render(): JSX.Element {
    return (
      <>
        <PropertyBuildingWidgetModal ref={this.modal} />
        {RenderHeader("Building Information Table")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.buildingHeaders} icons={0} />
            </thead>
            <tbody>
              {this.state.buildings.map((building: PropertyBuilding) =>
                <tr key={building.uid}>
                  <td className="fixedTableCellFirst">{building?.name}</td>
                  <td className="fixedTableCell">{building.unitCount}</td>
                  <td className="fixedTableCell">{new Date(building.lastRehabShortDate).toLocaleDateString()}</td>
                  <td className="fixedTableCell">{new Date(building.roofReplacedShortDate).toLocaleDateString()}</td>
                  <td className="fixedTableCell">
                    <input type="button" value="Edit" className="standard-input" onClick={() => this.modal.current.show(this.state.uid, this.convertToModalData(building))} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Add Building" className="standard-input" onClick={() => this.modal.current.show(this.state.uid)} />
      </>
    )
  }
}