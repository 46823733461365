import * as React from 'react';
import { Col, Row } from 'reactstrap';
import FinancialsRequestModal from '../Modals/FinancialsRequestModal';
import BankAccountWorkflowEntityRequestModal from './BankAccountWorkflowEntityRequestWidget.Modals';
import { WarningModal } from '../../CoreComponents/Modals';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

export default class ProjectManagementRequestWidget extends React.Component<{}, {}>{

  financialRequest: React.RefObject<FinancialsRequestModal> = React.createRef<FinancialsRequestModal>();
  entityBankAccountRequestModal: React.RefObject<BankAccountWorkflowEntityRequestModal> = React.createRef<BankAccountWorkflowEntityRequestModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = { }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  callback(success: boolean) {
    this.entityBankAccountRequestModal.current.forceClose();
    if (success) {
      this.warningModal.current.show("Bank Account Request Submitted.", "Success.");
    }
    else {
      this.warningModal.current.show("Error submitting request. Please submit a ticket so the tech team can dig into it.", "Error");
    }
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        <FinancialsRequestModal ref={this.financialRequest} />
        <BankAccountWorkflowEntityRequestModal ref={this.entityBankAccountRequestModal} callback={this.callback} />
        {RenderHeader("Workflow Requests - Finance")}
        <Row>
          <Col xs='4'>
            <h5 style={{ marginTop: "5px" }}>Financial Documents</h5>
          </Col>
          <Col style={{ textAlign: "left" }}>
            <input type="button" value="Request Finance Docs" onClick={() => this.financialRequest.current.show()} className="standard-input" />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col xs='4'>
            <h5 style={{ marginTop: "5px" }}>Bank Account - Entity</h5>
          </Col>
          <Col style={{ textAlign: "left" }}>
            <input type="button" value="Request Entity Bank Account" onClick={() => this.entityBankAccountRequestModal.current.show(null)} className="standard-input" />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
      </>
    )
  }

}
//<hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />

        //<hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        //<Row>
        //  <Col xs='4'>
        //    <h5 style={{ marginTop: "5px" }}>Bank Account - Property</h5>
        //  </Col>
        //  <Col style={{ textAlign: "left" }}>
        //    <input type="button" value="Request Property Bank Account" onClick={null} className="standard-input" />
        //  </Col>
        //</Row>