// #region Imports
import * as React from 'react';
import { Route, Switch } from 'react-router';
import axios from 'axios';
import './custom.css';
import './mci-custom.css';

//Main Elements
import Home from './components/Home';
import Layout from './components/Layout';
import Login from './components/GoogleAuthComponents/Login';
import Logout from './components/GoogleAuthComponents/Logout';
import { Migrate } from './components/OtherTools/Migrate';

//Properties
import { PropertySummaryTable } from './components/PropertyComponents/PropertySummaryTable'
import LocationAnalysis from './components/PropertyComponents/LocationAnalysis';
import { BuildingManager } from './components/PropertyComponents/BuildingManager';      //Needs Work
import { UnitManager } from './components/PropertyComponents/UnitManager';              //Needs work

//Utilities
import { ElectricUtilityManager } from './components/UtilityManagerComponents/ElectricUtilityManager';   //Needs work
import { ElectricUtilityRateForm } from './components/UtilityRateComponents/ElectricRateForm'
import { ElectricUtilityForm } from './components/UtilityManagerComponents/ElectricUtilityForm';
import ElectricUtilityTable from './components/UtilityManagerComponents/ElectricUtilityTable';

//Reporting
import { SolarProductionViewer } from './components/ReportingComponents/SolarProductionViewer';
import { SolarProjectList } from './components/ProjectLists';
import { SolarProjectForm } from './components/SolarProjectForm';
import { MainSolarScreener } from './components/SolarScreenerComponents/MainSolarScreener';

//Entities
import EmployeeSettings from './components/ContactComponents/EmployeeSettings';
import { ContactList } from './components/ContactComponents/ContactListAndForm';
import EntityManagementTable from './components/EntityComponents/EntityManagementTable/EntityManagementTable';
import { EntityStructureTable } from './components/EntityComponents/EntityStructure/EntityStructureTable';
import { EntityOwnershipFlowMap } from './components/EntityComponents/EntityStructure/EntityOwnershipFlowMap';
import EntityRequestForm from './components/EntityComponents/EntityRequestComponents/Creation/EntityRequestForm';
import NewEntityRequestDraftManager from './components/EntityComponents/EntityRequestComponents/Drafts/NewEntityRequestDraftManager';
import NewEntityRequestSubmittedDraft from './components/EntityComponents/EntityRequestComponents/Drafts/NewEntityRequestSubmittedDraft';
import NewEntityRequestDraftReview from './components/EntityComponents/EntityRequestComponents/Drafts/NewEntityRequestDraftReview';
import AmortizationComponentManager from './components/EntityComponents/EntityAmortizationSchedule/AmortizationComponentManager';

//Documents
import { SolarDocumentTable } from './components/DocumentComponents/SolarDocumentTable';
import { PropertyDocumentTable } from './components/DocumentComponents/PropertyDocumentTable';

//Job Management
import ActiveJobManagementTable from './components/JobManagementComponents/ActiveJobManagementComponents/ActiveJobManagementTable';
import { ActivateAdobeSign } from './components/AdobeSignComponents/ActivateAdobeSign';
import PayApplicationReportingDashboard from './components/JobManagementComponents/PayApplicationReporting/PayApplicationReportingDashboard';

//Tax Management
import TaxReturnManagementScreen from './components/TaxReturnManagerComponents/TaxReturnManagementScreen';

//Other Tools
import { NofaMapper } from './components/OtherTools/NofaMapperCreator';
import { GenerateInvoiceList } from './components/InvoiceGeneratorComponents/GenerateInvoiceList';
import { DataStorageRequest } from './components/OtherTools/DataStorageRequest';

//UNKNOWN
import FinancialProformaContainer from './components/FinanceProformaComponents/FinancialProformaContainer';

//Outside

//Invoices
import { CustomInvoiceCreator } from './components/InvoiceGeneratorComponents/CustomInvoiceCreator';

//IT
import { TicketPageManager } from './components/TicketingSystemComponents/TicketPageManager';
import KnowledgeBaseArticleEditor from './components/KnowledgeBaseComponents/KnowledgeBaseArticleEditor';

//EasterEggs
import { SVGTest } from './components/TestingComponents/SVGTest'
import PayrollContainer from './components/PayrollComponents/PayrollContainer';
import DistributionRecordCollector from './components/PayrollComponents/DistributionRecordCollector';
import { JobInformationTable } from './components/JobManagementComponents/ActiveJobManagementComponents/JobInformationTable';
import EmployeePageManager from './components/ContactComponents/EmployeePageManager';

import { AnonymouseConfirmationPage } from './components/AnonymouseConfirmationPage';
import PropertyDetailPage from './components/PropertyComponents/PropertyDetailPage';
import TechEquipmentRegionalManagerApproval from './components/TechTeamComponents/TechEquipmentComponents/TechEquipmentRegionalApproval';
import TicketSubmissionPage from './components/TicketingSystemComponents/TicketWork/TicketSubmissionPage';
import TicketWorkDashboard from './components/TicketingSystemComponents/TicketWorkDashboard';
import { SiteManagerDashboard } from './components/DashboardComponents/Dashboards/SiteManagerDashboard';
import { MaintenanceTechDashboard } from './components/DashboardComponents/Dashboards/MaintenanceTechDashboard';
import AuthenticationContainer from './components/AuthenticationContainer';
import TicketSubmissionPageAuth from './components/TicketingSystemComponents/TicketWork/TicketSubmissionPageAuth';
import EquipmentManagementTrackingDashboard from './components/EquipmentTrackingComponents/EquipmentManagementTrackingDashboard';
import KnowledgeBaseSearch from './components/KnowledgeBaseComponents/KnowledgeBaseSearch';
import EntityDetailDashboard from './components/EntityComponents/EntityManagementDashboard';
import CapitalNeedsAssesmentManager from './components/FinanceProformaComponents/CapitalNeedsAssesment/CNAPageManager'
import SoftwareBillingDashboard from './components/SoftwareBillingComponents/SoftwareBillingDashboard';
import { DrawRequestManager } from './components/JobManagementComponents/ActiveJobManagementComponents/DrawRequestManagement';
import { ChangeOrderManagement } from './components/JobManagementComponents/ActiveJobManagementComponents/ChangeOrderManagement';
import JobManagementDashboard from './components/JobManagementComponents/JobManagementDashboard';
import OtherToolsDashboard from './components/OtherTools/OtherToolDashboard';
import EmployeeManagementDashboard from './components/EmployeeManagementComponents/EmployeeManagementDashboard';
import ActiveJobManagementScreen from './components/JobManagementComponents/ActiveJobManagementComponents/ActiveJobManagementScreen';
import { LogoutUser } from './functions/authActions';
import VendorManagementTable from './components/VendorComponents/VendorManagementTable';
import { ExternalCompanyForm } from './components/ContactComponents/VendorListAndForm';
import { SoftwareManagementScreen } from './components/SoftwareBillingComponents/SoftwareManagementScreen';
import ConfigurationDashboard from './components/ConfigurationComponents/ConfigurationDashboard';
import OfficeSuppliesRequestPage from './components/TicketingSystemComponents/TicketWork/OfficeSuppliesRequestPage';

//Clean up

// #endregion

class App extends React.Component<any, any> {
  static displayName = App.name;

  constructor(props: any) {
    super(props);
    document.title = "Chrisman Command Center";

  }

  componentDidMount() {

  }

  render(): JSX.Element {
    axios.interceptors.response.use(undefined, (response) => {
      if (response.response.status === 401) {
        console.log("Unauthorized User. Logging out user");
        LogoutUser();
      }
      else {
        return response;
      }
    })
    return (
      <Switch>
        {/*Outside*/}
        <Route exact path='/login' component={Login} />
        <Route exact path='/logout' component={Logout} />

        {/* Ticket System */}
        <Route path="/ticket-submission" component={TicketSubmissionPage} />

        {/* Confirmation Components */}
        <Route path="/anonymous-approval" component={AnonymouseConfirmationPage} />

        {/*Inside*/}
        <AuthenticationContainer>
          <Switch>
            <Route path="/submit-supply-request" component={OfficeSuppliesRequestPage} />
            <Route path="/submit-ticket" component={TicketSubmissionPageAuth} />
            <Route path="/knowledge-base-home" component={KnowledgeBaseSearch} />
            <Route path='/site-manager-dashboard' component={SiteManagerDashboard} />
            <Route path='/maintenance-tech-dashboard' component={MaintenanceTechDashboard} />
            <Layout>
              {/*Admin*/}
              <Route path="/employees-list" component={EmployeePageManager} />
              <Route path="/employee-management" component={EmployeeManagementDashboard} />

              <Route path="/activateAdobe" component={ActivateAdobeSign} />

              <Route exact path='/' component={Home} />
              <Route path="/migrate" component={Migrate} />

              {/*Properties*/}

              <Route path='/property-summary-table' component={PropertySummaryTable} />
              {/*<Route path='/manage-property' component={PropertyForm} />*/}

              <Route path="/property-detail-page" component={PropertyDetailPage} />
              <Route path="/location-analysis" component={LocationAnalysis} />

              <Route path="/building-manager" component={BuildingManager} />                  {/*Cannot Create*/}
              <Route path="/unit-manager" component={UnitManager} />                          {/*Cannot Create*/}

              {/*Utilities*/}
              <Route path='/electric-utility-manager' component={ElectricUtilityManager} />  {/* Turn into HOC */}
              <Route path='/create-electric-utility' component={ElectricUtilityForm} />       {/*Cannot Create*/}
              <Route path='/manage-electric-rates' component={ElectricUtilityTable} />
              <Route path='/create-electric-rates' component={ElectricUtilityRateForm} />

              {/*Reporting*/}
              <Route path="/solar-production-viewer" component={SolarProductionViewer} />

              {/*Projects*/}
              <Route path='/solar-project-manager' component={SolarProjectList} />
              <Route path='/solar-project-form' component={SolarProjectForm} />
              <Route path="/solar-screener-manager" component={MainSolarScreener} />

              {/*Entites*/}
              <Route path="/employee-account-settings" component={EmployeeSettings} />

              <Route path="/contact-list" component={ContactList} />
             
              <Route path="/vendor-dashboard" component={VendorManagementTable} />
              <Route path="/vendor-form" component={ExternalCompanyForm} />

              <Route path="/entity-manager" component={EntityManagementTable} />
              <Route path="/entity-structure-manager" component={EntityStructureTable} />
              <Route path="/entity-structure-management-map" component={EntityOwnershipFlowMap} />
              <Route path="/entity-request-form" component={EntityRequestForm} />
              <Route path="/entity-request-drafts" component={NewEntityRequestDraftManager} />
              <Route path="/entity-request-submitted-draft" component={NewEntityRequestSubmittedDraft} />
              <Route path="/entity-request-draft-review" component={NewEntityRequestDraftReview} />
              <Route path="/entity-information-page" component={EntityDetailDashboard} />
              <Route path="/amortization-schedules" component={AmortizationComponentManager} />

              {/*Documents*/}
              <Route path="/solar-document-table" component={SolarDocumentTable} />
              <Route path="/property-document-table" component={PropertyDocumentTable} />

              {/*Job Management*/}
              <Route exact path="/active-job-management" component={ActiveJobManagementTable} />
              <Route path="/active-job-management/old-system" component={ActiveJobManagementScreen} />
              <Route path="/active-job-management/dashboard" component={JobManagementDashboard} />
              <Route path="/active-job-management/draws" component={DrawRequestManager} />
              <Route path="/active-job-management/change-orders" component={ChangeOrderManagement} />
              <Route path="/active-job-reporting" component={PayApplicationReportingDashboard} />
              <Route path="/create-new-job" component={JobInformationTable} />

              {/*Tax Management*/}
              <Route path="/tax-management" component={TaxReturnManagementScreen} />

              {/*Other Tools*/}
              <Route path="/nofa-mapping-tool" component={NofaMapper} />
              <Route path="/custom-invoice-generator" component={CustomInvoiceCreator} />
              <Route path="/invoice-list" component={GenerateInvoiceList} />
              <Route path="/database-code-generator" component={DataStorageRequest} />
              <Route path="/the-laboratory" component={OtherToolsDashboard} />
              <Route path="/configurations" component={ConfigurationDashboard} />
              <Route path="/old-software-billing" component={SoftwareManagementScreen} />

              {/*Tech*/}
              <Route path="/task-management" component={TicketPageManager} />
              <Route path="/ticket-work-manager" component={TicketWorkDashboard} />
              <Route path="/equipment-manager" component={EquipmentManagementTrackingDashboard} />
              <Route path="/tech-equipment-regional" component={TechEquipmentRegionalManagerApproval} />

              <Route path="/knowledge-base-article-editor" component={KnowledgeBaseArticleEditor} />

              <Route path="/SVG-Test" component={SVGTest} />

              {/*Finance Proforma*/}
              <Route path="/finance-proforma" component={FinancialProformaContainer} />

              {/*Payroll*/}
              <Route path="/payroll-processing" component={PayrollContainer} />
              <Route path="/payroll-distribution" component={DistributionRecordCollector} />

              {/*Software*/}
              <Route path="/software-billing-management" component={SoftwareBillingDashboard} />
              <Route path="/old-software-billing" component={SoftwareManagementScreen} />
              <Route path="/capital-needs-assessment-viewer" component={CapitalNeedsAssesmentManager} />

            </Layout>
          </Switch>
        </AuthenticationContainer>
      </Switch>
    );
  }
}

export default App;