import * as React from 'react';
import { PropertySoftwareLicenseWidgetProps, PropertySoftwareLicenseWidgetState, SoftwareCostsPerUnit } from './PropertySoftwareLicenseWidget.constants';
import axios from 'axios';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { numberWithCommas } from '../../../functions/numberFunctions';

export default class PropertySoftwareLicenseWidget extends React.Component<PropertySoftwareLicenseWidgetProps, PropertySoftwareLicenseWidgetState> {
  constructor(props: PropertySoftwareLicenseWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      list: [],
      headers: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.setHeaders();

    const response = (await axios.get('./api/property-license-map/get-by-property/' + this.state.uid)).data
    response.forEach((_item: any) => {
      const item = {
        name: _item.name,
        costPerUnit: _item.costPerUnit,
        unitCount: _item.unitCount,
        totalCost: _item.unitCount * _item.costPerUnit
      }
      this.state.list.push(item)
    })

    this.setState({});
  }

  setHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Name",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Cost",
        additionalClasses: "fifteen",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Count",
        additionalClasses: "fifteen",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Total",
        additionalClasses: "fifteen",
        setting: true,
        filter: {
        }
      }
    ]

    this.setState({
      headers: headers
    })
  }

  render(): JSX.Element {
    return (
      <>
        <h4>Software Costs - Table</h4>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            {this.state.list.map((item: SoftwareCostsPerUnit) =>
              <tr key={Math.random()}>
                <td className="fixedTableCellFirst">{item?.name}</td>
                <td className="fixedTableCell" style={{ background: "#15405c", textAlign: "right" }}>$ {numberWithCommas(item?.costPerUnit ?? 0)}</td>
                <td className="fixedTableCell" style={{ background: "#15405c", textAlign: "right" }}>{numberWithCommas(item?.unitCount ?? 0, 0)}</td>
                <td className="fixedTableCell" style={{ background: "#15405c", textAlign: "right" }}>$ {numberWithCommas(item?.totalCost ?? 0)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    )
  }
}