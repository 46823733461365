import { SelectOptions } from "../../../interfaces/CoreInterfaces";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";


export interface TicketTableState {
  headers: Array<FilterAndSettingField>;
  tickets: Array<Ticket>;
  loaded: true;
  categories: Array<SelectOptions>;
  filterSettings: TicketFilterSettings;
  refreshed: boolean;
  previousController: AbortController;
}

export interface Ticket {
  fullName: string;
  email: string;
  problemSummary: string;
  phoneNumber: string;
  companyName: SelectOptions;
  property: string;
  category: SelectOptions;
  title: string;
  created: Date;
  uid: string;
  ticketWorkStatus: SelectOptions;
  ticketWorkNumber: string;
  assignee: string;
  ticketComments: Array<any>;
  department: SelectOptions;
}

export interface TicketFilterSettings {
  email: string;
  assignee: string;
  status: SelectOptions;
  category: SelectOptions;
  department: SelectOptions;
  workNumber: string;
  problem: string;
}

export const CompanyNames: Array<SelectOptions> = [
  { label: "None Selected", value: "0" },
  { label: "Viridian", value: "1" },
  { label: "Chrisman Development", value: "2" },
  { label: "Fleet Development", value: "3" },
  { label: "AB Chase", value: "4" },
  { label: "Viridian Consult", value: "5" },
  { label: "Viridian Construction", value: "6" },
  { label: "Maintainence & Construction Institute", value: "7" }
]

export const TicketCategories: Array<SelectOptions> = [
  { label: "All", value: "-1" },
  { label: "Undefined", value: "0" },
  { label: "Access/Permissions", value: "1" },
  { label: "Accounting: CDI", value: "35" },
  { label: "Accounting: Viridian", value: "36" },
  { label: "Adobe", value: "40"},
  { label: "Asset Management", value: "38" },
  { label: "Cell Phones", value: "18" },
  { label: "CC - Requests", value: "27" },
  { label: "CC - Bugs", value: "33" },
  { label: "CC - Data Change", value: "34" },
  { label: "Comments/Suggestions", value: "21" },
  { label: "Compliance", value: "39" },
  { label: "Computer OS/Hardware", value: "2" },
  { label: "Concur", value: "25" },
  { label: "Data Update", value: "3" },
  { label: "Duplicates", value: "28" },
  { label: "Email", value: "4" },
  { label: "Employee Onboard/Change", value: "5" },
  { label: "Fax", value: "41"},
  { label: "Google", value: "24" },
  { label: "Human Resources", value: "37" },
  { label: "Inspections", value: "6" },
  { label: "Kissflow", value: "7" },
  { label: "Marketplace", value: "29" },
  { label: "Mobile App", value: "8" },
  { label: "Networking", value: "17" },
  { label: "Order New Equipment", value: "9" },
  { label: "Other Department", value: "10" },
  { label: "Paychex", value: "30" },
  { label: "Password Trouble", value: "11" },
  { label: "Peripheral Equipment", value: "12" },
  { label: "Printer", value: "13" },
  { label: "Property Access", value: "14" },
  { label: "Rent Cafe", value: "19" },
  { label: "Reports", value: "15" },
  { label: "Software", value: "20" },
  { label: "Tenant Tech", value: "22" },
  { label: "Training", value: "26" },
  { label: "Unknown", value: "32" },
  { label: "Viridian Academy", value: "31" },
  { label: "Viridian Website", value: "23" },
  { label: "Yardi", value: "16" },

  { label: "Domain Transition", value: "1000" },
  { label: "Cloud Transition", value: "1001"}
]

export const TicketWorkStatuses: Array<SelectOptions> = [
  { label: "Open", value: "0" },
  { label: "In-Progress", value: "1" },
  { label: "Closed", value: "2" },
  { label: "Re-Opened", value: "3" },
  { label: "Waiting", value: "4" },
  { label: "Required Action", value: "7" },
  { label: "Waiting - Yardi Support", value: "8"}
]

export const SupportDepartment: Array<SelectOptions> = [
  { label: "All", value: "-1" },
  { label: "Accounting", value: "1" },
  { label: "Asset Management", value: "6" },
  { label: "Compliance", value: "3" },
  { label: "HR", value: "2" },
  { label: "Regional", value: "4" },
  { label: "Tech", value: "0" },
  { label: "Admin", value: "7"}
]


export const ActiveWorkTicketWorkStatuses: Array<SelectOptions> = [
  { label: "Waiting", value: "4" },
  { label: "Project", value: "5" },
  { label: "Closed", value: "2" }
]

export const TicketFilterStatuses: Array<SelectOptions> = [
  { label: "Not Closed", value: "0" },
  { label: "Required Action", value: "8"},
  { label: "Waiting", value: "5" },
  { label: "Project", value: "6" },
  { label: "In-Progress", value: "1" },
  { label: "Re-Opened", value: "7" },
  { label: "Open Only", value: "2" },
  { label: "All", value: "3" },
  { label: "Closed", value: "4" },
]

export interface TicketComments {
  uid: string;
  ticketUID: string;
  assignee: string;
  comments: string;
  startTime: Date;
  endTime: Date;
}