import axios from "axios";
import * as React from "react"
import FilteredWithSettingsTableHeader from "../../CoreComponents/CoreTableHeaders"
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { WarningModal } from "../../CoreComponents/Modals";
import { StatusMap } from "../constants";
import { Col, Row } from "reactstrap";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";

interface DrawAppStatus {
  uid: string;
  job: string;
  status: string;
  number: number;
  endDate: Date;
}

interface DrawAppSummaryState {
  headers: Array<FilterAndSettingField>;
  workflows: Array<DrawAppStatus>;
}

interface DrawAppSummaryProps {
}

export class DrawRequestSummaryTable extends React.Component<DrawAppSummaryProps, DrawAppSummaryState> {

  warningModal = React.createRef<WarningModal>();

  constructor(props: DrawAppSummaryProps) {
    super(props);
    this.state = {
      headers: [],
      workflows: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    let response = await axios.get('./api/request-for-payment-workflow/get-active-payapps/', { validateStatus: () => true })
    if (response.status === 200) {
      this.setState({
        workflows: response.data
      })
    }
    else {
      this.warningModal.current.show("Error While Loading Workflows for Review. Please submit a tech ticket so that Johnny or Tom can review it", "Error.")
    }
  }

  generateHeaders(): void {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Job",
        setting: true,
        filter: {}
      },
      {
        columnName: "Draw Number",
        setting: true,
        filter: {}
      },
      {
        columnName: "Status",
        setting: true,
        filter: {}
      },
      {
        columnName: "EndDate",
        setting: true, 
        additionalClasses: "fifteen",
        filter: {}
      },
    ];
    this.setState({
      headers: headers
    })
  }

  render(): JSX.Element {
    return (
      <div style={{ maxHeight: "38vh", minHeight: "38vh", overflowY: "auto" }}>
        {RenderHeader("Draw Request Tracking")}
        <div style={{ marginLeft: "1%", marginRight: "1%", height: "27vh" }}>
          <WarningModal ref={this.warningModal} />
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.workflows.map((item: DrawAppStatus) => {
                  return (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst" style={{ fontSize: "14px" }}>
                        <a href={"./active-job-management-screen?uid=" + item.uid} style={{ backgroundColor: "#4C4A42"}}>
                          {item.job}
                        </a>
                      </td>
                      <td className="fixedTableCell" style={{ fontSize: "14px" }} >{item.number + 1}</td>
                      <td className="fixedTableCell" style={{ fontSize: "14px" }} >{StatusMap[parseInt(item.status)]}</td>
                      <td className="fixedTableCell" style={{ fontSize: "14px" }} >{new Date(item.endDate).toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' })}</td>
                    </tr>)
                })
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Start Pay Application" onClick={null} className="standard-input" />
          </Col>
        </Row>
      </div>
    )
  }
}