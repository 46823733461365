import * as React from 'react'
import { PropertyVendorContactWidgetProps, PropertyVendorContactWidgetState, VendorContactInformation } from './PropertyVendorContactWidget.constants';
import { Col, Row } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import axios from 'axios';
import { pullExternalCompaniesVendorType } from '../../../functions/fetchLinkedObjects';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { ExternalCompanyTypes } from '../../../constants/EnumConstants';

export default class PropertyVendorContactWidget extends React.Component<PropertyVendorContactWidgetProps, PropertyVendorContactWidgetState> {

  constructor(props: PropertyVendorContactWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      contacts: [],
      uid: props.uid,
      vendors: [],
      vendor: { label: "Select Vendor", value: "" }
    }

    this.changeVendor = this.changeVendor.bind(this);
    this.save = this.save.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.headers();
    const vendors = await pullExternalCompaniesVendorType();

    this.getVendors();

    this.setState({
      vendors: vendors
    })
  }

  async getVendors(): Promise<void> {
    const response = (await axios.get('./api/property-vendor-map/get-by-property/' + this.state.uid)).data;

    const vendorList: Array<VendorContactInformation> = []

    response.forEach((_item: any) => {
      const item: VendorContactInformation = {
        uid: _item.uid,
        name: _item.externalCompany.name,
        type: ExternalCompanyTypes.filter(u => u.value === _item.externalCompany.type?.toString())[0],
        number: _item.externalCompany.mainContacts[0].contact.phoneNumber
      }
      vendorList.push(item);
    })

    this.setState(({ contacts: vendorList }))
  }

  headers(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Name',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Type',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Number',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      }
    ]

    this.setState({ headers: headers })
  }

  changeVendor(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ vendor: event })
  }

  async save(): Promise<void> {
    const data = {
      PropertyUID: this.state.uid,
      VendorUID: this.state.vendor.value
    }

    await axios.post('./api/property-vendor-map', data)

    this.getVendors();
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "69vh" }}>
        <Row>
          <Col>
            <h4>Vendor Contact</h4>
          </Col>
        </Row>
        <br />
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.contacts.map((item) =>
                <tr key={item.uid}>
                  <td className="fixedTableCellFirst">{item.name}</td>
                  <td className="fixedTableCell">{item.type?.label}</td>
                  <td className="fixedTableCell">{item.number}</td>
                </tr>
              )
            }
          </tbody>
        </table>
        <br />
        <Row>
          <Col><h5>Add</h5></Col>
          <Col>
            <Select
              options={this.state.vendors}
              styles={reactSelectBasicStyle}
              value={this.state.vendor}
              onChange={this.changeVendor}
              menuPlacement="auto"
              menuPosition="fixed"
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
          <Col>
            <input type="button" className="standardInput" value="submit" onClick={this.save} />
          </Col>
        </Row>
      </div>
    )
  }
}