import axios from 'axios';
import * as React from 'react';
import { getUserID } from '../../functions/authActions';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';

interface ActivateAdobeSignState {
  code: string;
}
interface ActivateAdobeSignProps {
  auth: any;
}

export class ActivateAdobeSign extends React.Component<ActivateAdobeSignProps, ActivateAdobeSignState>{

  constructor(props: ActivateAdobeSignProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const code: string = params.get('code');
    this.state = {
      code: code
    }
  }

  async componentDidMount(): Promise<void> {
    await axios.get('./api/adobe-sign-controller/' + this.state.code + "/" + getUserID()).then(function (response) {
      CreateGlobalAlert("Adobe Sign has correctly configured OAuth", 2000, () => window.location.replace("/"));
    });
  }

  render(): JSX.Element {
    return (
      <div>
        <h4>Setting up AdobeSign. Please wait for redirect</h4>
      </div>
    )
  }
}