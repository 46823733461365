import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import * as React from 'react'
import ReactToPrint from 'react-to-print';
import { Col, Row } from 'reactstrap';
import { getBearerToken, Jobs, Teams, ValidateAccess } from '../../functions/authActions';
import { HaversineDistance, MapCoord } from '../../functions/MapFunctions';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { FileEntryModal, StatusModal, WarningModal } from '../CoreComponents/Modals';
import { LocationMarkersMultiInfoHidden, LocationMarkersMultiInfoShow } from '../OtherTools/NofaComponents/TableRowConsts';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';

interface LocationAnalysisProps {

}

interface LocationAnalysisState {
  center: CenterInterface;
  zoom: number;
  apiLoaded: boolean;
  displayList: Array<PropertyMapData>;
  propertyListData: Array<PropertyMapData>;
  propertiesName: Array<SelectOptions>;
  propertiesCode: Array<SelectOptions>;
  regionals: Array<SelectOptions>;
  sites: Array<SelectOptions>;
  maintenenances: Array<SelectOptions>;
  searchChildOptions: Array<SelectOptionsWithColor>;
  searchChildDisplay: Array<SelectOptionsWithColor>;
  searchParent: SelectOptions;
  searchChild: SelectOptions;
  searchTableName: string;
  isTech: boolean;
  searchString: string;
}

interface CenterInterface {
  lat: number;
  lng: number;
}

export interface SelectOptionsWithColor extends SelectOptions {
  color: string;
}

export interface Property extends SelectOptionsWithColor {
  uid: string;
  name: string;
  code: string;
  unitCount: number;
  lng: number;
  lat: number;
  regional: Array<SelectOptions>;
  site: Array<SelectOptions>;
  maintenance: Array<SelectOptions>;
  isFocused: boolean;
}

export interface PropertyMapData extends SelectOptionsWithColor {
  name: string;
  code: string;
  unitCount: number;
  lng: number;
  lat: number;
  regional: Array<SelectOptions>;
  site: Array<SelectOptions>;
  maintenance: Array<SelectOptions>;
  isFocused: boolean;
}

const MapDisplayOptions: Array<SelectOptions> = [
  { label: "Regional Managers", value: "0" },
  { label: "Site Managers", value: "1" },
  { label: "Maintenance Managers", value: "2" },
  { label: "Properties - Name", value: "3" },
  { label: "Properties - Code", value: "4" }
]

const CONST_REGIONAL_MAP_COLORS: Array<string> = [
  "#CA4428",
  "#4B5C38",
  "#176D85",
  "#433B7F",
  "#A99A18",
  "#5A0E2D",
  "#038983",
  "#B2868E"
]

export default class LocationAnalysis extends React.Component<LocationAnalysisProps, LocationAnalysisState>{

  statusRef = React.createRef<StatusModal>();
  warningRef = React.createRef<WarningModal>();
  fileRef = React.createRef<FileEntryModal>();
  printWrapper = React.createRef<any>();
  map: any;

  constructor(props: LocationAnalysisProps) {
    super(props);
    this.state = {
      center: {
        lat: 45.425280,
        lng: -117.275910
      },
      zoom: 6,
      apiLoaded: false,
      displayList: [],
      propertyListData: [],
      propertiesName: [],
      propertiesCode: [],
      regionals: [],
      sites: [],
      maintenenances: [],
      searchChildOptions: [],
      searchChildDisplay: [],
      isTech: false,
      searchParent: { label: "LOADING", value: "" },
      searchChild: { label: "LOADING", value: "" },
      searchTableName: "Loading Data",
      searchString: ""
    }
    this.parentSearchChange = this.parentSearchChange.bind(this);
    this.childSearchChange = this.childSearchChange.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.showToolTip = this.showToolTip.bind(this);
    this.loadPropertiesByRegional = this.loadPropertiesByRegional.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.statusRef.current.display("Loading", "Please Wait")
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const tech = await ValidateAccess([Jobs.Technology], [Teams.Services]);
    this.setState({
      isTech: tech,
    })
  }

  async loadPropertiesByCode(event: SelectOptions): Promise<void> {
    this.statusRef.current.display("Loading Property Data", "Loading");
    let responsePropertyCodeSorted = await axios.get('./api/property/property-code-sorted');
    const propertiesCode: Array<PropertyMapData> = [];
    responsePropertyCodeSorted.data.forEach((map: any) => {
      propertiesCode.push({
        value: map.uid,
        label: map.code,
        code: map.code,
        name: map.name,
        unitCount: map.unitCount,
        lat: map.latitude,
        lng: map.longitude,
        color: this.generateColor(),
        regional: map.regionals,
        site: map.siteManagers,
        maintenance: map.maintenanceTechs,
        isFocused: false
      })
    })
    let searchTableName = "Properties - Code";
    let searchOption = { label: "All Property", value: "-1" }
    this.setPropertySearchData(event, propertiesCode, searchTableName, searchOption);
  }

  async loadPropertiesByName(event: SelectOptions): Promise<void> {
    this.statusRef.current.display("Loading Property Data", "Loading");
    let responsePropertyNameSorted = await axios.get('./api/property/property-name-sorted');
    const propertiesName: Array<PropertyMapData> = [];
    responsePropertyNameSorted.data.forEach((map: any) => {
      propertiesName.push({
        value: map.uid,
        label: map.name,
        code: map.code,
        name: map.name,
        unitCount: map.unitCount,
        lat: map.latitude,
        lng: map.longitude,
        color: this.generateColor(),
        regional: map.regionals,
        site: map.siteManagers,
        maintenance: map.maintenanceTechs,
        isFocused: false
      })
    })
    let searchTableName = "Properties - Name";
    let searchOption = { label: "All Property", value: "-1" }
    this.setPropertySearchData(event, propertiesName, searchTableName, searchOption);
  }

  async loadPropertiesByRegional(event: SelectOptions): Promise<void> {
    this.statusRef.current.display("Loading Property Data", "Loading");
    let responsePropertyNameSorted = await axios.get('./api/property/property-name-sorted');
    let regionals: Array<SelectOptionsWithColor> = []
    const propertiesName: Array<PropertyMapData> = [];
    responsePropertyNameSorted.data.forEach((map: any) => {
      regionals = regionals.concat(map.regionals);
      propertiesName.push({
        value: map.uid,
        label: map.name,
        code: map.code,
        name: map.name,
        unitCount: map.unitCount,
        lat: map.latitude,
        lng: map.longitude,
        color: this.generateColor(),
        regional: map.regionals,
        site: map.siteManagers,
        maintenance: map.maintenanceTechs,
        isFocused: false
      })
    })
    regionals.sort();
    let outputRegionals: Array<SelectOptionsWithColor> = [];
    let index = 0;
    regionals.forEach((item) => {
      if (outputRegionals.filter(u => u.value == item.value).length === 0) {
        item.color = CONST_REGIONAL_MAP_COLORS[index];
        index++;
        outputRegionals.push(item);
        propertiesName.forEach((prop) => {
          let isRegional: boolean = prop.regional.filter(u => u.value == item.value).length > 0;
          if (isRegional) {
            prop.color = item.color;
          }
        })
      }
    })
    regionals = JSON.parse(JSON.stringify(outputRegionals));
    let searchTableName = "Regional Managers";
    let searchOption = { label: "All Property", value: "-1" }
    this.setPropertySearchData(event === null ? { label: "Regional Managers", value: "0" } : event, propertiesName, searchTableName, searchOption, regionals);
  }

  async loadPropertiesBySiteManager(event: SelectOptions): Promise<void> {
    this.statusRef.current.display("Loading Property Data", "Loading");
    let responsePropertyNameSorted = await axios.get('./api/property/property-name-sorted');
    let siteManagers: Array<SelectOptionsWithColor> = []
    const propertiesName: Array<PropertyMapData> = [];
    responsePropertyNameSorted.data.forEach((map: any) => {
      siteManagers = siteManagers.concat(map.siteManagers);
      propertiesName.push({
        value: map.uid,
        label: map.name,
        code: map.code,
        name: map.name,
        unitCount: map.unitCount,
        lat: map.latitude,
        lng: map.longitude,
        color: this.generateColor(),
        regional: map.regionals,
        site: map.siteManagers,
        maintenance: map.maintenanceTechs,
        isFocused: false
      })
    })
    siteManagers.sort();
    let outputSiteManagers: Array<SelectOptionsWithColor> = [];
    let index = 0;
    siteManagers.forEach((item) => {
      if (outputSiteManagers.filter(u => u.value == item.value).length === 0) {
        item.color = this.generateColor();
        index++;
        outputSiteManagers.push(item);
      }
    })
    siteManagers = JSON.parse(JSON.stringify(outputSiteManagers));
    let searchTableName = "Site Managers";
    let searchOption = { label: "All Property", value: "-1" }
    this.setPropertySearchData(event, propertiesName, searchTableName, searchOption, siteManagers);
  }

  async loadPropertiesByMaintenanceTech(event: SelectOptions): Promise<void> {
    this.statusRef.current.display("Loading Property Data", "Loading");
    let responsePropertyNameSorted = await axios.get('./api/property/property-name-sorted');
    let maintenanceTechs: Array<SelectOptionsWithColor> = []
    const propertiesName: Array<PropertyMapData> = [];
    responsePropertyNameSorted.data.forEach((map: any) => {
      maintenanceTechs = maintenanceTechs.concat(map.maintenanceTechs);
      propertiesName.push({
        value: map.uid,
        label: map.name,
        code: map.code,
        name: map.name,
        unitCount: map.unitCount,
        lat: map.latitude,
        lng: map.longitude,
        color: this.generateColor(),
        regional: map.regionals,
        site: map.siteManagers,
        maintenance: map.maintenanceTechs,
        isFocused: false
      })
    })
    maintenanceTechs.sort();
    let outputMaintenanceTechs: Array<SelectOptionsWithColor> = [];
    let index = 0;
    maintenanceTechs.forEach((item) => {
      if (outputMaintenanceTechs.filter(u => u.value == item.value).length === 0) {
        item.color = this.generateColor();
        index++;
        outputMaintenanceTechs.push(item);
      }
    })
    maintenanceTechs = JSON.parse(JSON.stringify(outputMaintenanceTechs));
    let searchTableName = "Maintenance Techs";
    let searchOption = { label: "All Property", value: "-1" }
    this.setPropertySearchData(event, propertiesName, searchTableName, searchOption, maintenanceTechs);
  }

  setPropertySearchData(event: SelectOptions, arr: Array<PropertyMapData>, tableName: string, option: SelectOptions, childOptions: Array<SelectOptionsWithColor> = null): void {
    this.setState({
      searchParent: event,
      searchChild: option,
      searchChildOptions: childOptions === null ? arr : childOptions,
      searchChildDisplay: childOptions === null ? arr : childOptions,
      searchTableName: tableName,
      displayList: arr,
      propertyListData: arr,
      center: {
        lat: 45.425280,
        lng: -117.275910
      }
    })
    this.updateMap
    this.map.panTo(this.state.center);
    this.statusRef.current.hide();
  }

  generateColor(): string {
    const genRanHex1 = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 4).toString(16)).join('');
    const genRanHex2 = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    const color = genRanHex1(1) + genRanHex2(1) + genRanHex1(1) + genRanHex2(1) + genRanHex1(1) + genRanHex2(1)
    return "#" + color;
  }

  async setApiLoaded(): Promise<void> {
    this.setState({
      apiLoaded: true
    }, async () => await this.loadPropertiesByRegional(null));
  }

  async parentSearchChange(event: SelectOptions | null): Promise<void> {
    if (event === null) { return }
    if (event.value === "0") {
      await this.loadPropertiesByRegional(event);
    }
    else if (event.value === "1") {
      await this.loadPropertiesBySiteManager(event);
    }
    else if (event.value === "2") {
      await this.loadPropertiesByMaintenanceTech(event);
    }
    else if (event.value === "3") {
      await this.loadPropertiesByName(event);
    }
    else if (event.value === "4") {
      await this.loadPropertiesByCode(event);
    }
  }

  childSearchChange(event: SelectOptionsWithColor | null): void {
    if (event === null) { return }
    this.setState({ searchChild: event }, () => this.updateMap(event))
  }

  updateMap(event: SelectOptionsWithColor): void {
    this.statusRef.current.display("Loading", "Loading");
    const searchResults: Array<PropertyMapData> = [];
    const searchLabels: Array<SelectOptionsWithColor> = [];
    let tableName: string = "";
    if (this.state.searchParent.value === "0") {
      this.state.propertyListData.forEach((property) => {
        let isRegional: boolean = property.regional.filter(u => u.value == event.value).length > 0;
        if (isRegional) {
          property.color = this.generateColor();
          searchResults.push(property);
          searchLabels.push(property);
        }
      })
      tableName = "Regional Managers";
      this.setZoomAndCenter(searchResults);
    }
    else if (this.state.searchParent.value === "1") {
      this.state.propertyListData.forEach((property) => {
        let isSiteManager: boolean = property.site.filter(u => u.value == event.value).length > 0;
        if (isSiteManager) {
          searchResults.push(property);
          searchLabels.push(property);
        }
      })
      tableName = "Site Managers";
      this.setZoomAndCenter(searchResults);
    }
    else if (this.state.searchParent.value === "2") {
      this.state.propertyListData.forEach((property) => {
        let isMaintenance: boolean = property.maintenance.filter(u => u.value == event.value).length > 0;
        if (isMaintenance) {
          searchResults.push(property);
          searchLabels.push(property);
        }
      })
      tableName = "Maintenance Techs";
      this.setZoomAndCenter(searchResults);
    }
    else if (this.state.searchParent.value === "3" || this.state.searchParent.value === "4") {
      let property = this.state.propertyListData.filter(u => u.value === event.value)[0];
      searchResults.push(property);
      searchLabels.push(property);
      this.map.setZoom(13);
      this.map.setCenter({
        lat: parseFloat(property.lat.toString()),
        lng: parseFloat(property.lng.toString())
      });
      tableName = "Properties List";
    }
    this.setState({
      displayList: searchResults,
      searchChildDisplay: searchLabels,
      searchTableName: tableName
    })
    this.statusRef.current.hide();
  }

  setZoomAndCenter(searchResults: Array<PropertyMapData>): void {
    const distances = [];
    let zoom = 13;
    let center =
    {
      lat: 45.425280,
      lng: -117.275910
    }
    if (searchResults.length > 2) {
      let maxdistanceProps = {
        property1: searchResults[0],
        property2: searchResults[0],
        distance: 0
      }
      for (var idx0 = 0; idx0 < searchResults.length; idx0++) {
        if (searchResults[idx0].code.trim() == ".vir") {
          continue;
        }
        const point1 = {
          lng: searchResults[idx0].lng,
          lat: searchResults[idx0].lat
        }
        for (var idx1 = idx0+1; idx1 < searchResults.length; ++idx1) {
          const point2 = {
            lng: searchResults[idx1].lng,
            lat: searchResults[idx1].lat
          }
          const distance = HaversineDistance(point1, point2)

          if (distance > maxdistanceProps.distance) {
            maxdistanceProps = {
              property1: searchResults[idx0],
              property2: searchResults[idx1],
              distance: distance
            }
          }
          distances.push({
            property1: searchResults[idx0],
            property2: searchResults[idx1],
            distance: distance
          })
        }
      }
      zoom = 13 - Math.ceil(Math.min(maxdistanceProps.distance, 700) / 100.0);
      center = {
        lng: (parseFloat(maxdistanceProps.property1?.lng.toString()) + parseFloat(maxdistanceProps.property2?.lng.toString())) / 2,
        lat: (parseFloat(maxdistanceProps.property1?.lat.toString()) + parseFloat(maxdistanceProps.property2?.lat.toString())) / 2
      }
    }
    else if (searchResults.length === 1) {
      zoom = 13;
      center = { lng: parseFloat(searchResults[0].lng.toString()), lat: parseFloat(searchResults[0].lat.toString())}
    }

    this.map.setZoom(zoom);
    this.map.panTo(center);
  }

  showToolTip(property: PropertyMapData): void {
    let propertyData = this.state.displayList.filter(u => u.value == property.value)[0];
    let index = this.state.displayList.indexOf(propertyData);
    propertyData.isFocused = !propertyData.isFocused;
    this.state.displayList[index] = propertyData;
    this.setState({})
  }

  render(): JSX.Element {
    return (
      <div style={{ overflowX: "hidden", overflowY: "auto", height: "90vh" }}>
        <FileEntryModal ref={this.fileRef} />
        <StatusModal ref={this.statusRef} />
        <div ref={this.printWrapper} className="force-width force-background-height force-background border-print print-overflow body">
          <Row>
            <Col>
              <h3>Location Analysis</h3>
            </Col>
          </Row>
          <Row style={{ marginBottom: "2vh" }}>
            <Col>
              <Select
                value={this.state.searchParent}
                styles={reactSelectBasicStyle}
                options={MapDisplayOptions}
                onChange={this.parentSearchChange}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
            <Col>
              <Select
                value={this.state.searchChild}
                styles={reactSelectBasicStyle}
                options={this.state.searchChildOptions}
                onChange={this.childSearchChange}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <Row className="force-map-height" style={{ minHeight: "70vh", maxHeight: "70vh", width: "99%", marginLeft: "1vw" }}>
            <Col>
              <GoogleMapReact
                key="map"
                bootstrapURLKeys={{
                  key: "AIzaSyDF_xQZ5PZZnYBNn-V4-btiQD3Az9zJ3Qo",
                  libraries: ['places']
                }}
                center={this.state.center}
                defaultZoom={this.state.zoom}
                //@ts-ignore
                onGoogleApiLoaded={({ map, maps }) => {
                  this.map = map;
                  this.setApiLoaded()
                }}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  disableDefaultUI: true,
                  mapTypeId: "terrain",
                  mapTypeControl: true,
                  streetViewControl: true,
                  scaleControl: true,
                  fullscreenControl: true,
                  mapTypeControlOptions: {
                    style: 2,
                    mapTypeIds: [
                      "terrain",
                      "satellite",
                      "roadmap"
                    ]
                  }
                }}
              >
                {
                  this.state.displayList.map((property: PropertyMapData) => {
                    if (property.isFocused) {
                      return <LocationMarkersMultiInfoShow
                        key={property.value}
                        name={property.label}
                        lng={property.lng}
                        lat={property.lat}
                        color={property.color}
                        unitCount={property.unitCount}
                        code={property.label}
                        regional={property.regional}
                        site={property.site}
                        maintenance={property.maintenance}
                        onFocus={() => this.showToolTip(property)}
                      />
                    } else {
                      return <LocationMarkersMultiInfoHidden
                        key={property.label}
                        name={property.name}
                        lng={property.lng}
                        lat={property.lat}
                        color={property.color}
                        unitCount={property.unitCount}
                        code={property.code}
                        regional={property.regional}
                        site={property.site}
                        maintenance={property.maintenance}
                        onFocus={() => this.showToolTip(property)}
                      />
                    }
                  })
                }
              </GoogleMapReact>
            </Col>
            <Col xs="3" style={{ border: "3px solid #d7c7a7", backgroundColor: "#4C4A42", marginRight: "1vw", maxHeight: "70vh", overflowX: "hidden", overflowY: "auto" }}>
              <Row>
                <h4>{this.state.searchTableName}</h4>
              </Row>
              {
                this.state.searchChildDisplay.map((item) => (
                  <Row key={item.value}>
                    <Col> <h6>{item.label}</h6> </Col>
                    <Col xs="2" style={{ backgroundColor: item.color }}>
                      <div style={{ color: item.color, width: "2vh" }}>|||||||</div>
                    </Col>
                  </Row>
                ))
              }
            </Col>
          </Row>
          <Row style={{ marginTop: "2vh" }}>
            <Col>
              <ReactToPrint
                trigger={() => <input type="button" className="standard-input no-print" style={{ width: "10vw" }} value="Print" />}
                content={() => this.printWrapper.current}
              />
            </Col>
          </Row>
        </div>
      </div>
    )
  }

}