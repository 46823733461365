import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { EMPTY_GUID } from '../../../../constants/DefaultConstants';
import { pullMCIInstructors } from '../../../../functions/fetchLinkedObjects';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../../functions/selectTools';
import { SelectOptions } from '../../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../../style/select-constants';
import FilteredWithSettingsTableHeader from '../../../CoreComponents/CoreTableHeaders';
import { DatePicker } from '../../../CoreComponents/DateComponents';
import { MCIActiveCourseCreationModalProps, MCIActiveCourseCreationModalState } from './ActiveCourseCreationModal.constants';

export class ActiveCourseCreationModal extends React.Component<MCIActiveCourseCreationModalProps, MCIActiveCourseCreationModalState> {

  constructor(props: MCIActiveCourseCreationModalProps) {
    super(props);
    this.state = {
      showing: false,
      settingsHeaders: [],
      startDate: new Date(),
      uid: "",
      instructor: { label: "Select Options", value: "" },
      instructors: [],
      courseName: ""
    }
    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
    this.checkDate = this.checkDate.bind(this);
    this.changeInstructor = this.changeInstructor.bind(this);
    this.changeCourseName = this.changeCourseName.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.save = this.save.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const instructors = await pullMCIInstructors();
    this.setState({
      instructors: instructors
    })
  }

  show(): void {
    this.setState({
      showing: true
    })
  }

  close(): void {
    this.setState({
      showing: false
    })
  }

  async checkDate(): Promise<void> {
    //Set Var
    const newDate = new Date(this.state.startDate)

    //check date
    if (newDate.getFullYear() < 1977) {
      newDate.setFullYear(1977);
      newDate.setMonth(4);
      newDate.setDate(25);
    } else if (newDate.getFullYear() === 1977 && newDate.getMonth() < 4) {
      newDate.setMonth(4);
      newDate.setDate(25);
    } else if (newDate.getFullYear() === 1977 && newDate.getMonth() === 4 && newDate.getDate() < 25) {
      newDate.setDate(25);
    }
    this.setState({ startDate: new Date(newDate) });
  }

  async changeInstructor(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({
      instructor: event
    })
  }

  async changeCourseName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ courseName: event.target.value })
  }

  changeStartDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ startDate: new Date(event.target.valueAsDate) })
  }

  async save(): Promise<void> {
    if (this.state.startDate < new Date(Date.now())) {
      this.setState({ startDate: new Date(Date.now()) })
    }
    const data = {
      Name: this.state.courseName,
      InstructorUID: this.state.instructor.value,
      //@ts-ignore
      Students: [],
      UID: EMPTY_GUID,
      StartDate: this.state.startDate
    }
    let response = await axios.post('./api/active-courses', data);
    this.props.creationCallback(response.data, response.status === 202);
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="lg">
        <div style={{ background: "#4c4a42" }}>
          <ModalHeader toggle={this.close}>
            <h3 style={{ color: "#d7c7a7" }}>Edit Course Settings</h3>
          </ModalHeader>
          <ModalBody>
            <>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.settingsHeaders} icons={0} />
                </thead>
                <tbody>
                  <tr>
                    <td className="fixedTableCellFirst">Course Name</td>
                    <td className="fixedTableCell">
                      <input type="text" placeholder="Course Name" value={this.state.courseName} onChange={this.changeCourseName} className="standard-input" />
                    </td>
                  </tr>
                  <tr>
                    <td className="fixedTableCellFirst">Instructor</td>
                    <td className="fixedTableCell">
                      <Select
                        styles={reactSelectBasicStyle}
                        options={this.state.instructors}
                        value={this.state.instructor}
                        onChange={this.changeInstructor}
                        onFocus={RemoveStickyOverlays}
                        onBlur={RestoreStickyOverlays}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="fixedTableCellFirst">Start Date</td>
                    <td className="fixedTableCell">
                      <DatePicker className="standard-input" onChange={this.changeStartDate} value={this.state.startDate} onBlur={this.checkDate} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
            <hr />
            <Row>
              <Col>
                <input type="button" className="standard-input" value="Save" onClick={this.save} />
              </Col>
              <Col>
                <input type="button" className="standard-input" value="Cancel" onClick={this.close} />
              </Col>
            </Row>
          </ModalBody>

        </div>
      </Modal>
    )
  }
}