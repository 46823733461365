import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldCheckbox, DataEntryModalFieldDate, DataEntryModalFieldSelect, DataEntryModalFieldStatic, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import { pullEntityContractors, pullFinanceLeads } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullGeneralContractingJobName } from '../../../functions/fetchObjectNameFunctions';
import { JobSettingsWidgetData } from './JobSettingsWidget';

interface JobSettingsManagementModalState {
  data: JobSettingsWidgetData;
  entities: Array<SelectOptions>;
  financeLeads: Array<SelectOptions>;
}

export default class JobSettingsManagementModal extends React.Component<{ uid: string }, JobSettingsManagementModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: { uid: string }) {
    super(props);
    this.state = {
      entities: [],
      financeLeads: [],
      data: {
        uid: "",
        name: "",
        entity: { label: "Select Entity", value: "" },
        contractor: { label: "Select Contractor", value: "" },
        contractDate: new Date(),
        createdBy: "",
        createdOn: new Date(),
        jobLeads: "",
        agencySignature: false,
        rdProject: false,
        completed: false,
        financeLeads: []
      }
    }
  }

  async componentDidMount(): Promise<void> {
    let entities: Array<SelectOptions> = await pullEntityContractors();
    let finance: Array<SelectOptions> = await pullFinanceLeads();
    this.setState({ entities: entities, financeLeads: finance });
  }

  async show(data: any): Promise<void> {
    this.setState({ data: data }, async () => await this.generateData());
  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    let jobName = await pullGeneralContractingJobName(this.props.uid);
    data.push({ key: "jobName", name: "Job Name", patchUrl: undefined, static: true, value: jobName } as DataEntryModalFieldStatic);
    data.push({ key: "entity", name: "Entity", patchUrl: undefined, static: true, value: this.state.data.entity.label} as DataEntryModalFieldStatic);
    data.push({ key: "contractor", name: "Contractor", patchUrl: undefined, static: true, value: this.state.data.contractor.label } as DataEntryModalFieldStatic);
    data.push({ key: "financeLeads", name: "Finance Leads", patchUrl: './api/managed-jobs/finance-leads', value: this.state.data.financeLeads, isMulti: true, options: this.state.financeLeads} as DataEntryModalFieldSelect)
    data.push({ key: "contractDate", name: "Contract Date", patchUrl: './api/managed-jobs/contract-date', value: new Date(this.state.data.contractDate)} as DataEntryModalFieldDate);
    data.push({ key: "createdBy", name: "Created By", patchUrl: undefined, static: true, value: this.state.data.createdBy } as DataEntryModalFieldStatic);
    data.push({ key: "createdOn", name: "Created On", patchUrl: undefined, static: true, value: new Date(this.state.data.createdOn).toLocaleDateString('en-CA')} as DataEntryModalFieldStatic);
    data.push({ key: "agencySignature", name: "Agency Signature", patchUrl: "./api/managed-jobs/agency-signature", checkbox: this.state.data.agencySignature } as DataEntryModalFieldCheckbox);
    data.push({ key: "rdProject", name: "RD Project", patchUrl: "./api/managed-jobs/rd-project", checkbox: this.state.data.rdProject } as DataEntryModalFieldCheckbox);
    data.push({ key: "completed", name: "Completed", patchUrl: './api/managed-jobs/completed', checkbox: this.state.data.completed } as DataEntryModalFieldCheckbox);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Edit " + jobName + " Settings", this.props.uid, undefined);
  }

  overrideSave(data: Array<DataEntryModalField>): void {
    console.log("Closing Modal as Save is only used during Creation, and this Modal doesn't do create");
    window.location.reload();
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} overrideSave={this.overrideSave} overrideSaveText="Save" />
      </>
    )
  }
}