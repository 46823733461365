import * as React from 'react';
import { PieGraphState, PieGraphWidget } from '../../CoreComponents/Widgets/PieGraphWidget';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { TicketWorkStatuses } from '../TicketWork/TicketTableManager.constants';


interface ProjectTaskAnalysisWidgetState {
  statusData: PieGraphState;
  statusKey: string;
  departmentData: PieGraphState;
  departmentKey: string;
  stack: boolean;
}
 
interface ProjectTaskAnalysisWidgetProps {
  uid: string;
}

export default class ProjectTaskAnalysisWidget extends React.Component<ProjectTaskAnalysisWidgetProps, ProjectTaskAnalysisWidgetState> {
  colors: Array<string> = ['#316650', '#641C34', '#1D334A', '#6D6552', '#1D1E33']
  container: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  constructor(props: ProjectTaskAnalysisWidgetProps) {
    super(props);

    this.state = {
      statusKey: Math.random().toString(),
      statusData: {
        width: 50,
        height: 50,
        values: [
          { label: "Expenses", value: 25, color: "#04ea40" },
          { label: "Expenses", value: 50, color: "#a3aea3" },
          { label: "Expenses", value: 25, color: "#333" },
        ]
      },
      departmentKey: Math.random().toString(),
      departmentData: {
        width: 50,
        height: 50,
        values: [
          { label: "Expenses", value: 25, color: "#04ea40" },
          { label: "Expenses", value: 50, color: "#a3aea3" },
          { label: "Expenses", value: 25, color: "#333" },
        ]
      },
      stack: false
    }
  }

  async componentDidMount(): Promise<void> {
    let width = this.container.current.parentElement.style.width;
    if (width === "24%") {
      this.setState({ stack: true });
    }
    let response = await axios.get('./api/tickets/project-status-data/' + this.props.uid);
    if (response.status === 200) {
      let categories: Array<any> = response.data;
      let count = categories.reduce((a, { count }) => a + count, 0);
      let topThree = categories.slice(0, 5);
      let rest = categories.slice(5);
      let data: any = { width: 50, height: 50, values: [] };
      topThree.forEach((item, idx) => (
        data.values.push({
          label: TicketWorkStatuses[item.status].label,
          value: (item.count / count) * 100,
          color: this.colors[idx]
        })
      ))
      if (categories.length > 3) {
        let restCount = rest.reduce((a, { count }) => a + count, 0);
        data.values.push({ label: "Other", value: (restCount / count) * 100, color: '#3E3B32' })
      }
      this.setState({
        statusData: data,
        statusKey: Math.random().toString()
      })
    }

    let _response = await axios.get('./api/tickets/project-assignee-data/' + this.props.uid);
    if (_response.status === 200) {
      let departments: Array<any> = _response.data;
      let count = departments.reduce((a, { count }) => a + count, 0);
      let topThree = departments.slice(0, 5);
      let rest = departments.slice(5);
      let data: any = { width: 50, height: 50, values: [] };
      topThree.forEach((item, idx) => (
        data.values.push({
          label: item.assignee,
          value: (item.count / count) * 100,
          color: this.colors[idx]
        })
      ))
      if (departments.length > 3) {
        let restCount = rest.reduce((a, { count }) => a + count, 0);
        data.values.push({ label: "Other", value: (restCount / count) * 100, color: '#3E3B32' })
      }
      this.setState({
        departmentData: data,
        departmentKey: Math.random().toString()
      })
    }
  }

  render(): JSX.Element {
    return (
      <div ref={this.container}>
        <h5 style={{ width: "100%", textAlign: "center" }}>Project Task Analysis</h5>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        {this.state.stack ?
          <>
            <Row>
              <Col>
                <PieGraphWidget key={this.state.statusKey} values={this.state.statusData.values} title="Status" />
              </Col>
            </Row>
            <Row>
              <Col>
                <PieGraphWidget key={this.state.departmentKey} values={this.state.departmentData.values} title="Assignees" />
              </Col>
            </Row>
          </>
          : <Row>
            <Col>
              <PieGraphWidget key={this.state.statusKey} values={this.state.statusData.values} title="Status" />
            </Col>
            <Col>
              <PieGraphWidget key={this.state.departmentKey} values={this.state.departmentData.values} title="Assignees" />
            </Col>
          </Row>
        }
      </div>
    )
  }
}