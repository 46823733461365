import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullProperties } from '../../../functions/fetchLinkedObjects';
import { getUserID } from '../../../functions/authActions';
import axios from 'axios';

interface ARReportModalState {
  showing: boolean;
  property: SelectOptions;
  properties: Array<SelectOptions>;
  attachment: File;
}

export default class ARReportModal extends React.Component<{}, ARReportModalState>{

  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      property: { label: "Select Property", value: "" },
      properties: [],
      attachment: null
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.submit = this.submit.bind(this);
    this.changeProperties = this.changeProperties.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();
    this.setState({ properties: properties });
  }

  show(): void {
    this.setState({ showing: true });
  }

  hide(): void {
    this.setState({ showing: false });
  }

  changeProperties(event: SelectOptions | null) {
    if (event === null) { return; }
    this.setState({ property: event });
  }

  async submit(): Promise<void> {
    let data = new FormData();
    data.append("propertyUID", this.state.property.value);
    data.append("attachment", this.state.attachment);
    data.append("currentAssigneeUID", getUserID());
    let response = await axios.post('./api/ar-report-workflow', data);
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={this.hide} size="lg">
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h4" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c", textAlign: "center" }}>
            AR Report
          </ModalHeader>
          <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
            <ModalBody style={{ border: "0px solid #15405c" }}>
              <Row>
                <Col>
                  <label>Property</label>
                  <Select
                    styles={reactSelectBasicStyle}
                    value={this.state.property}
                    options={this.state.properties}
                    onChange={this.changeProperties}
                    required={this.state.property.value === ""}
                  />
                </Col>
                <Col>
                  <label>Attachments</label>
                  <input type="file" className="standard-input" style={{ paddingTop: "0.25vh" }}
                    required={this.state.attachment === null}
                  />
                </Col>
              </Row>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              <input type="button" value="Submit AR" onClick={this.submit} className="standard-input" />
            </ModalBody>
          </div>
        </div>
      </Modal>
    )
  }
}