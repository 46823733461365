//#region Imports
import * as React from 'react';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import { EntityTableSettingsModal } from './EntityTableSettingsModal';
import { pullStates } from '../../../functions/fetchLinkedObjects';
import { EntityData, EntityEmptyRow, EntityTypes, EntityTypes_Select } from '../Structs';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import { FilterAndSettingField, StreetAddress } from '../../CoreComponents/interfaces';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { EntityManagementTableSetting } from '../../../reducers/TableSettingStructures';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import Select from 'react-select';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { EntityTableRow, FinalEmptyRow } from './EntityAdditionalTableRows';
import { GetSettings, SetSettingKey, SetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { EntityFilterSettings, EntityTableData } from './EntityManagementTable.constants';
import { getAccountingFirm, getEntityName, getUnfilteredHeader, getUniqueCode, getYardiAccount } from './EntityManagementTable.generators';
import { EntityManagementModal } from './EntityManagementModal';
//#endregion

const defaultFilter = {
  entityName: "", 
  accountFirm: "", 
  formationState: { label: "Select State", value: "-1" }, 
  entityType: { label: "All", value: "0" }, 
  accountant: '', 
  entityManager: '',
  entityStatus: { label: "Active", value: "1" }
}

export function getEntityTableSettings(): EntityManagementTableSetting {
  return {
    entityWidth: 100,
    showAccountingFirm: true,
    accountingFirmWidth: 100,
    showYardiUniqueCode: true,
    yardiCodeWidth: 100,
    showYardiAccount: true,
    yardiAccountWidth: 100,
    showTaxID: true,
    taxWidth: 100,
    showUei: true,
    ueiWidth: 100,
    showCage: true,
    cageWidth: 100,
    showCageExp: true,
    cageExpWidth: 100,
    showManagingMember: true,
    managingMemberWidth: 100,
    showPartners: true,
    partnersWidth: 100,
    showAuthorizedSigners: true,
    authorizedSignersWidth: 100,
    showFormationState: true,
    formationStateWidth: 100,
    showSosRenewalDate: true,
    sosRenewalDateWidth: 100,
    showEntityType: true,
    entityTypeWidth: 100,
    showSCorpElection: true,
    sCorpWidth: 100,
    showEntityStatus: true,
    entityStatusWidth: 100,
    showFormationDate: true,
    formationDateWidth: 100,
    showRegisteredAgents: true,
    registeredAgentsWidth: 100,
    showFirstYearTaxReturn: true,
    firstYearWidth: 100,
    showFinalYearTaxReturn: true,
    finalYearWidth: 100,
    showEntityManager: true,
    entityManagerWidth: 100,
    showAccountant: true,
    accountantWidth: 100,
    showStreetAddress: true,
    streetAddressWidth: 100,
    showNotes: true,
    notesWidth: 100,
    iconWidth: 100
  }
}

export default class EntityManagementTable extends React.Component<{}, EntityTableData> {

  settingsKey: string = "entityTableSettings";
  filterKey: string = "entityFilterSettings";

  modalRef = React.createRef<EntityTableSettingsModal>();
  statusModal = React.createRef<StatusModal>();
  managementModal = React.createRef<EntityManagementModal>();
  warningModal = React.createRef<WarningModal>();

  static EntityStatusFilters: Array<SelectOptions> = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
    { label: "All", value: "-1" }
  ]

  constructor(props: EntityEmptyRow) {
    super(props);
    let settings: EntityManagementTableSetting = GetSettings<EntityManagementTableSetting>(this.settingsKey);
    let filter: EntityFilterSettings = GetSettings<EntityFilterSettings>(this.filterKey);
    if (settings === null) {
      settings = getEntityTableSettings();
      SetSettings(this.settingsKey, settings);
    }
    if (filter === null) {
      filter = defaultFilter;
      SetSettings(this.filterKey, filter);
    }
    this.state = {
      entities: [],
      relatedPortfolio: -1,
      entityTableSettings: settings,
      filter: filter,
      width: "99%",
      divWidth: "99%",
      states: [],
      editableIndex: -1,
      headers: [],
      pages: [],
      page: { label: "1", value: "0" },
      pageCount: 25,
      totalCount: 0,
    };
    this.generateHeaders = this.generateHeaders.bind(this);
    this.processEntity = this.processEntity.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.changeEntityType = this.changeEntityType.bind(this);
    this.changeFormationState = this.changeFormationState.bind(this);
    this.updateEntities = this.updateEntities.bind(this);
    this.updatePageCount = this.updatePageCount.bind(this);
    this.editRow = this.editRow.bind(this);
    this.saveNewEntity = this.saveNewEntity.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.showModal = this.showModal.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.create = this.create.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.exportAll = this.exportAll.bind(this);
    this.exportFilter = this.exportFilter.bind(this);
    this.changeEntityStatus = this.changeEntityStatus.bind(this);
    this.getTableStyles = this.getTableStyles.bind(this);
    this.updateWidthSettings = this.updateWidthSettings.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const states = await pullStates();
    states.unshift({
      label: "All",
      value: "-1"
    })
    this.setState({
      states: states
    })
    this.updateEntities();
  }

  async loadStructure(): Promise<void> {
    const self = this;
    this.updatePageCount();
    if (self.state.entityTableSettings.showManagingMember || self.state.entityTableSettings.showPartners) {
      this.state.entities.forEach(async function (item) {
        await axios.get("./api/entity-structure/" + item.uid).then(function (response) {
          const entities = self.state.entities;
          const entity = entities.filter(_ => _.uid === item.uid)[0];
          const idx = entities.findIndex(_ => _.uid === entity?.uid);
          let ownership = "";
          let managingMember = "";
          if (response.data.length > 0) {
            response.data.forEach(function (item: any) {
              if (item.isManagingMember) {
                managingMember = item.ownerEntity.name;
              }
              ownership += `${item.ownerEntity.name} (${item.percentageOwned}%), `;
            })
            ownership = ownership.slice(0, ownership.length - 2);
          }
          entity.ownership = ownership;
          entity.managingMember = managingMember;
          entities[idx] = entity;
          self.setState({
            entities: entities
          })
        })
      })
    }
  }

  //#region Functions

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      getEntityName(this.state.entityTableSettings.entityWidth, this.updateWidthSettings, this.state.filter.entityName, this.changeFilter, this.updateEntities),
      getAccountingFirm(this.state.entityTableSettings.showAccountingFirm, this.state.entityTableSettings.accountingFirmWidth, this.updateWidthSettings, this.state.filter.accountFirm, this.changeFilter, this.updateEntities),
      getUniqueCode(this.state.entityTableSettings.yardiCodeWidth, this.updateWidthSettings, this.state.entityTableSettings.showYardiUniqueCode),
      getYardiAccount(this.state.entityTableSettings.yardiAccountWidth, this.updateWidthSettings, this.state.entityTableSettings.showYardiAccount),
      getUnfilteredHeader("Tax ID (EIN)", this.state.entityTableSettings.taxWidth, "taxWidth", this.updateWidthSettings, this.state.entityTableSettings.showTaxID),
      getUnfilteredHeader("UEI", this.state.entityTableSettings.ueiWidth, "ueiWidth", this.updateWidthSettings, this.state.entityTableSettings.showUei),
      getUnfilteredHeader("CAGE", this.state.entityTableSettings.cageWidth, "cageWidth", this.updateWidthSettings, this.state.entityTableSettings.showCage),
      getUnfilteredHeader("CAGE Expiration", this.state.entityTableSettings.cageExpWidth, "cageExpWidth", this.updateWidthSettings, this.state.entityTableSettings.showCageExp),
      getUnfilteredHeader("Managing Member", this.state.entityTableSettings.managingMemberWidth, "managingMemberWidth", this.updateWidthSettings, this.state.entityTableSettings.showManagingMember),
      getUnfilteredHeader("Partners", this.state.entityTableSettings.partnersWidth, "partnersWidth", this.updateWidthSettings, this.state.entityTableSettings.showPartners),
      getUnfilteredHeader("Authorized Signers", this.state.entityTableSettings.authorizedSignersWidth, "authorizedSignersWidth", this.updateWidthSettings, this.state.entityTableSettings.showAuthorizedSigners),
      {
        columnName: "Formation State",
        width: Math.max(this.state.entityTableSettings.formationStateWidth, 100),
        widthName: "formationStateWidth",
        widthCallback: this.updateWidthSettings,
        setting: this.state.entityTableSettings.showFormationState,
        filter: {
          name: "formationState",
          multi: false,
          changeFilter: this.changeFormationState,
          options: this.state.states,
          value: this.state.filter.formationState,
          blur: this.updateEntities
        }
      },
      getUnfilteredHeader("SOS Renewal Date", this.state.entityTableSettings.sosRenewalDateWidth, "sosRenewalDateWidth", this.updateWidthSettings, this.state.entityTableSettings.showSosRenewalDate),
      {
        columnName: "Entity Type",
        width: Math.max(this.state.entityTableSettings.entityTypeWidth, 100),
        widthName: "entityTypeWidth",
        widthCallback: this.updateWidthSettings,
        setting: this.state.entityTableSettings.showEntityType,
        filter: {
          name: "entityType",
          value: this.state.filter.entityType,
          multi: false,
          options: EntityTypes_Select,
          changeFilter: this.changeEntityType,
          blur: this.updateEntities
        }
      },
      getUnfilteredHeader("S-Corp Election", this.state.entityTableSettings.sCorpWidth, "sCorpWidth", this.updateWidthSettings, this.state.entityTableSettings.showSCorpElection),
      {
        columnName: "Entity Status",
        width: Math.max(this.state.entityTableSettings.entityStatusWidth, 100),
        widthName: "entityStatusWidth",
        widthCallback: this.updateWidthSettings,
        setting: this.state.entityTableSettings.showEntityStatus,
        filter: {
          name: "entityStatus",
          value: this.state.filter.entityStatus,
          multi: false,
          options: EntityManagementTable.EntityStatusFilters,
          changeFilter: this.changeEntityStatus,
          blur: this.updateEntities
        }
      },
      getUnfilteredHeader("Formation Date", this.state.entityTableSettings.formationDateWidth, "formationDateWidth", this.updateWidthSettings, this.state.entityTableSettings.showFormationDate),
      getUnfilteredHeader("Registered Agent", this.state.entityTableSettings.registeredAgentsWidth, "registeredAgentsWidth", this.updateWidthSettings, this.state.entityTableSettings.showRegisteredAgents),
      getUnfilteredHeader("First Year Tax Return", this.state.entityTableSettings.firstYearWidth, "firstYearWidth", this.updateWidthSettings, this.state.entityTableSettings.showFirstYearTaxReturn),
      getUnfilteredHeader("Final Year Tax Return", this.state.entityTableSettings.finalYearWidth, "finalYearWidth", this.updateWidthSettings, this.state.entityTableSettings.showFinalYearTaxReturn),
      {
        columnName: "Entity Manager",
        width: Math.max(this.state.entityTableSettings.entityManagerWidth, 100),
        widthName: "entityManagerWidth",
        widthCallback: this.updateWidthSettings,
        setting: this.state.entityTableSettings.showEntityManager,
        filter: {
          type: "text",
          name: "entityManager",
          value: this.state.filter.entityManager,
          changeFilter: this.changeFilter,
          placeHolder: "Filter By Manager",
          blur: this.updateEntities
        }
      },
      {
        columnName: "Accountant",
        width: Math.max(this.state.entityTableSettings.accountantWidth, 100),
        widthName: "accountantWidth",
        widthCallback: this.updateWidthSettings,
        setting: this.state.entityTableSettings.showAccountant,
        filter: {
          type: "text",
          name: "accountant",
          value: this.state.filter.accountant,
          changeFilter: this.changeFilter,
          placeHolder: "Filter by Accountant",
          blur: this.updateEntities
        }
      },
      getUnfilteredHeader("Street Address", this.state.entityTableSettings.streetAddressWidth, "streetAddressWidth", this.updateWidthSettings, this.state.entityTableSettings.showStreetAddress),
      getUnfilteredHeader("Notes", this.state.entityTableSettings.notesWidth, "notesWidth", this.updateWidthSettings, this.state.entityTableSettings.showNotes),
      getUnfilteredHeader("", this.state.entityTableSettings.iconWidth, "iconWidth", this.updateWidthSettings, true)
    ]
    function sum(total: number, obj: FilterAndSettingField) {
      if (!obj.setting) {
        return total;
      }
      return total + (obj.width ? obj.width : 0)
    }
    let width = headers.reduce(sum, 0);
    this.setState({
      width: width + "px",
      headers: headers
    })
  }

  processEntity(item: any, entities: Array<EntityData>): void {
    const type = EntityTypes[item.type];
    const ownership = "";
    const managingMember = "";
    entities.push({
      uid: item.uid,
      name: item.name,
      uniqueCode: item.uniqueCode,
      isIndividual: item.isIndividual,
      jurisdiction: { label: item.formationStateString, value: item.formationState },
      firm: { label: item.firm?.name, value: item.firm?.uid },
      type: { label: type, value: item.type },
      fein: item.fein,
      duns: item.duns,
      uei: item.uei,
      cage: item.saM_CAGE_CODE,
      cageExpiration: new Date(item.saM_CAGE_EXPIRATION),
      sosRenew: new Date(item.sosRenewalDate),
      sCorpElection: EntityManagementModal.SCorpElections[item.sCorpElection],
      status: { label: item.isActive ? "Active" : "Inactive", value: item.isActive ? "1" : "0" },
      formationDate: new Date(item.formationDate),
      accountant: { label: item.accountant?.fullName ?? "Select Accountant", value: item.accountant?.uid ?? "" },
      portfolio: { label: item.managementCompany?.name, value: item.managementCompany?.uid },
      yardiAccount: item.isInCDIYardi ? { label: "CDI", value: "1" } : { label: "VMI", value: "0" },
      isFirstYearReturn: { label: item.isFirstYearReturn ? "Yes" : "No", value: item.isFirstYearReturn ? "1" : "0" },
      isFinalYearReturn: { label: item.isFinalYearReturn ? "Yes" : "No", value: item.isFinalYearReturn ? "1" : "0" },
      notes: item.notes,
      agent: { label: item.registeredAgent?.name ?? "", value: item.registeredAgentUID ?? "" },
      authorizedSigners: item.authorizedSigners === null ? [] : item.authorizedSigners.map((item: any) => { return { value: item.contact.uid, label: item.contact.fullName } }),
      ownership: ownership,
      managingMember: managingMember,
      streetAddress: item.streetAddress?.fullAddress ?? "",
      address: {
        address: item.streetAddress?.streetAddress,
        city: item.streetAddress?.city,
        state: { label: item.streetAddress?.stateName, value: item.streetAddress?.state },
        zipcode: item.streetAddress?.zipCode
      },
    });
  }

  changeFilter(event: React.ChangeEvent<HTMLInputElement>): void {
    let filter = this.state.filter;
    //@ts-ignore
    filter[event.target.name] = event.target.value;
    SetSettings(this.filterKey, filter);
    this.setState({ filter: filter }, this.generateHeaders);
  }

  changeEntityType(event: SelectOptions | null): void {
    if (event === null) { return; }
    let filter = this.state.filter;
    filter.entityType = event;
    SetSettings(this.filterKey, filter);
    this.setState({ filter: filter }, this.generateHeaders)
  }

  changeFormationState(event: SelectOptions | null): void {
    if (event === null) { return; }
    let filter = this.state.filter;
    filter.formationState = event;
    SetSettings(this.filterKey, filter);
    this.setState({ filter: filter }, this.generateHeaders)
  }

  changeEntityStatus(event: SelectOptions | null): void {
    if (event === null) { return; }
    let filter = this.state.filter;
    filter.entityStatus = event;
    SetSettings(this.filterKey, filter);
    this.setState({ filter: filter }, this.generateHeaders)
  }

  async updateEntities(): Promise<void> {
    this.generateHeaders();
    const manager = this.state.filter.entityManager === '' ? 'null' : this.state.filter.entityManager;
    const name = this.state.filter.entityName === '' ? "null" : this.state.filter.entityName;
    const data = {
      Page: this.state.page.value,
      PageCount: this.state.pageCount,
      EntityName: name,
      Firm: this.state.filter.accountFirm === '' ? "null" : this.state.filter.accountFirm,
      ShowFirm: this.state.entityTableSettings.showAccountingFirm,
      FormationState: this.state.filter.formationState.value,
      EntityType: this.state.filter.entityType.value,
      EntityStatus: this.state.filter.entityStatus.value,
      Accountant: this.state.filter.accountant === '' ? "null" : this.state.filter.accountant,
      ShowAccountant: this.state.entityTableSettings.showAccountant,
      Manager: manager,
      ShowManager: this.state.entityTableSettings.showEntityManager,
      ShowStreetAddress: this.state.entityTableSettings.showStreetAddress,
      ShowEntityStatus: this.state.entityTableSettings.showEntityStatus,
      ShowEntityType: this.state.entityTableSettings.showEntityType,
      ShowFormationState: this.state.entityTableSettings.showFormationState,
      ShowSosRenewalDate: this.state.entityTableSettings.showSosRenewalDate,
      ShowRegisteredAgent: this.state.entityTableSettings.showRegisteredAgents,
      ShowAuthorizedSigners: this.state.entityTableSettings.showAuthorizedSigners
    }
    this.statusModal.current.display("Loading Entities", "Please be patient as we load the entities");
    let response = await axios.post(`./api/entities/filtered/`, data, { validateStatus: () => true });
    if (response.status === 200) {
      const entities: Array<EntityData> = [];
      response.data.list.forEach((item: any) => (
        this.processEntity(item, entities)
      ));
      this.statusModal.current.hide();
      this.setState({
        entities: entities,
        totalCount: response.data.count,
      }, this.loadStructure);
    }
  }

  async exportFilter(): Promise<void> {
    const manager = this.state.filter.entityManager === '' ? 'null' : this.state.filter.entityManager;
    const name = this.state.filter.entityName === '' ? "null" : this.state.filter.entityName;
    const data = {
      Filter: {
        Page: this.state.page.value,
        PageCount: this.state.pageCount,
        EntityName: name,
        Firm: this.state.filter.accountFirm === '' ? "null" : this.state.filter.accountFirm,
        ShowFirm: this.state.entityTableSettings.showAccountingFirm,
        FormationState: this.state.filter.formationState.value,
        EntityType: this.state.filter.entityType.value,
        Accountant: this.state.filter.accountant === '' ? "null" : this.state.filter.accountant,
        ShowAccountant: this.state.entityTableSettings.showAccountant,
        Manager: manager,
        ShowManager: this.state.entityTableSettings.showEntityManager,
        ShowStreetAddress: this.state.entityTableSettings.showStreetAddress,
        ShowEntityType: this.state.entityTableSettings.showEntityType,
        ShowFormationState: this.state.entityTableSettings.showFormationState,
        ShowSosRenewalDate: this.state.entityTableSettings.showSosRenewalDate,
        ShowRegisteredAgent: this.state.entityTableSettings.showRegisteredAgents,
        ShowAuthorizedSigners: this.state.entityTableSettings.showAuthorizedSigners
      },
      ShowUniqueCode: this.state.entityTableSettings.showYardiUniqueCode,
      ShowAccount: this.state.entityTableSettings.showYardiAccount,
      ShowTaxID: this.state.entityTableSettings.showTaxID,
      //ShowDuns: this.state.entityTableSettings.showDuns,
      ShowUEI: this.state.entityTableSettings.showUei,
      ShowCage: this.state.entityTableSettings.showCage,
      ShowCageExpiration: this.state.entityTableSettings.showCageExp,
      ShowManagingMembers: this.state.entityTableSettings.showManagingMember,
      ShowPartners: this.state.entityTableSettings.showPartners,
      ShowSCorp: this.state.entityTableSettings.showSCorpElection,
      ShowFormationDate: this.state.entityTableSettings.showFormationDate,
      ShowFirstYear: this.state.entityTableSettings.showFirstYearTaxReturn,
      ShowFinalYear: this.state.entityTableSettings.showFinalYearTaxReturn,
      ShowNotes: this.state.entityTableSettings.showNotes
    };
    this.statusModal.current.display("Exporting Entity List.", "The Entity List is Exporting. Please hold for a minute");
    await axios.post("./api/entities/export-filter", data).then((response) => {
      this.statusModal.current.hide();
      const fileDownload = require('js-file-download');
      fileDownload(response.data, `Master Entity List ${new Date().toLocaleDateString()}.csv`);
    })
  }

  async exportAll(): Promise<void> {
    this.statusModal.current.display("Exporting Entity List.", "The Entity List is Exporting. Please hold for a minute");
    await axios.post("./api/entities/export-all").then((response) => {
      this.statusModal.current.hide();
      const fileDownload = require('js-file-download');
      fileDownload(response.data, `Master Entity List ${new Date().toLocaleDateString()}.csv`);
    })
  }

  updatePageCount(): void {
    let length = Math.ceil(this.state.totalCount / this.state.pageCount);
    let pages: any = [];
    for (let i = 0; i < length; ++i) {
      pages.push({
        label: i + 1,
        value: i
      })
    }
    this.setState({
      pages: pages
    });
  }

  editRow(uid: string, entity: EntityData): void {
    this.managementModal.current.show(uid);
  }

  async saveNewEntity(entity: EntityData): Promise<void> {
    const data = {
      uid: "00000000-0000-0000-0000-000000000000",
      name: entity.name,
      accountUID: entity.accountant.value === "" ? EMPTY_GUID : entity.accountant.value,
      accountFirmUID: entity.firm.value === "" ? EMPTY_GUID : entity.firm.value,
      uniqueCode: entity.uniqueCode,
      type: entity.type.value,
      duns: entity.duns,
      uei: entity.uei,
      cage: entity.cage,
      cageExpiration: entity.cageExpiration.toISOString(),
      sCorpElection: entity.sCorpElection.value,
      entityManagerUID: entity.portfolio.value,
      formationState: entity.jurisdiction.value,
      formationDate: entity.formationDate.toISOString(),
      sosRenewalDate: entity.sosRenew.toISOString(),
      fein: entity.fein,
      isInCDIYardi: entity.yardiAccount.value === "1" ? true : false,
      isFirstYearReturn: entity.isFirstYearReturn.value === "1" ? true : false,
      isFinalYearReturn: entity.isFinalYearReturn.value === "1" ? true : false,
      isActive: entity.status.value === "1",
      registeredAgent: entity.agent.value === "" ? EMPTY_GUID : entity.agent.value,
      notes: entity.notes,
      authorizedSigners: entity.authorizedSigners === undefined ? [] : entity.authorizedSigners.map(u => u.value)
    };
    let response = await axios.post('./api/entities', data, {validateStatus: () => true});
    if (response.status === 200) {
      this.warningModal.current.show("Entity has been created. The page will reload once this modal is closed.", "Entity Created", () => window.location.reload());
    }
    else {
      this.warningModal.current.show("Error creating the entity. Please contact Johnny or Tom to help get this resolved.", "Error.", () => window.location.reload());
    }
  }

  editAddress(index: number, updateItem?: StreetAddress): void {
    const items = this.state.entities;
    const item = items[index];
    if (updateItem !== undefined) {
      item.streetAddress = `${updateItem.address} ${updateItem.city},${updateItem.state.label} ${updateItem.zipcode}`;
      item.address = updateItem;
    }
    items[index] = item;
    this.setState({
      entities: items
    })
  }

  showModal(): void {
    if (this.modalRef.current === null) {
      console.error("Modal Reference is incorrect");
      return;
    }
    this.modalRef.current.show();
  }

  updateSettings(entityTableSettings: EntityManagementTableSetting): void {
    this.setState({ entityTableSettings: entityTableSettings });
    SetSettings(this.settingsKey, entityTableSettings);
    this.updateEntities();
  }

  updateWidthSettings(name: string, value: number): void {
    //@ts-ignore
    this.state.entityTableSettings[name] = value;
    SetSettingKey(this.settingsKey, name, value);
  }

  create(): void {
    this.managementModal.current.show();
  }

  selectPage(event: SelectOptions): void {
    this.setState({
      page: event
    }, this.updateEntities)
  }

  getTableStyles(): Object {
    return { width: this.state.width }
  }
  //#endregion

  render(): JSX.Element {
    return (
      <div>
        <StatusModal ref={this.statusModal} />
        <WarningModal ref={this.warningModal} />
        <EntityTableSettingsModal ref={this.modalRef} entityTableSettings={this.state.entityTableSettings} updateSettings={this.updateSettings} showAll />
        <EntityManagementModal ref={this.managementModal} saveRow={this.saveNewEntity} />
        <Row>
          <Col xs='2'>
            <h3 style={{ marginLeft: "-15px" }}>Entity Table</h3>
          </Col>
          <Col>
            <input type="button" className="standard-input" style={{ float: "right", width: "15%", marginRight: "1.2%" }} value="Show/Hide Columns" onClick={this.showModal} />
          </Col>
        </Row>
        <div id="entity-management-table" style={{ overflow: "scroll", minHeight: "77vh", maxHeight: "77vh", marginLeft: "-15px", marginTop: "1%", width: "100%", color: "#fff" }}>
            <table id="tableTest" className="fixedTable" style={this.getTableStyles()}>
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.entities.map((item, idx) => (
                    <EntityTableRow key={Math.random()} entity={item} index={idx}
                      entityTableSettings={this.state.entityTableSettings} editAddress={this.editAddress}
                      editRow={this.editRow} />
                  ))
                }
              </tbody>
            </table>
        </div>
        <div id="footer-bar" style={{ marginLeft: "-25px" }}>
          <hr />
          <input type="button" value="Add New Entity" className="standard-input" style={{ width: "15vw" }}
            onClick={() => this.create()}
          />
          <input type="button" value="Export Filter" className="standard-input" style={{ marginLeft: "1vw", width: "15vw" }}
            onClick={this.exportFilter}
          />
          <input type="button" value="Export All" className="standard-input" style={{ marginLeft: "1vw", width: "15vw" }}
            onClick={this.exportAll}
          />
          <div style={{ width: "15vw", float: "right" }}>
            <Select
              id="page-number"
              menuPlacement="top"
              value={this.state.page}
              options={this.state.pages}
              onChange={this.selectPage}
              styles={reactSelectBasicStyle}
            />
          </div>
          <label style={{ marginRight: "1vw", float: "right" }}> Select Page: </label>
        </div>
      </div>
    )
  }
}
