import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import axios from 'axios';
import { pullProperties } from '../../../functions/fetchLinkedObjects';

interface PropertyOffboardingModalProps {
  callback: (success: boolean) => void;
}
interface PropertyOffboardingModalState {
  show: boolean;
  properties: Array<SelectOptions>;
  property: SelectOptions;
}

export default class PropertyOffboardingModal extends React.Component<PropertyOffboardingModalProps, PropertyOffboardingModalState> {

  constructor(props: PropertyOffboardingModalProps) {
    super(props);

    this.state = {
      show: false,
      properties: [],
      property: { label: "Select Property", value: ""}
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.start = this.start.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();
    this.setState({ properties: properties });
  }
  
  async save(): Promise<void> {
    const data = {
      
    };
    let response = await axios.post('/api/property/start-offboard', data, { validateStatus: () => true });
    if (response.status !== 202) {

    }
  }

  changeProperty(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ property: event });
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  hide(): void {
    this.setState({
      show: false
    }, () => window.location.reload());
  }

  async start(): Promise<void> {
    const data = {
      propertyUID: this.state.property.value
    }
    let response = await axios.post('./api/property/start-offboard', data, { validateStatus: () => true });
    if (response.status !== 202) {
      this.props.callback(false);
    }
    else {
      this.props.callback(true);
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Start Property Offboarding
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5>Property</h5>
              </Col>
              <Col>
                <Select
                  options={this.state.properties}
                  styles={reactSelectBasicStyle}
                  onChange={this.changeProperty}
                  value={this.state.property}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <input type="button" value="Start Offboarding" className="standard-input" onClick={this.start} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}