import * as React from 'react'
import { CNALineItemManagementPageProps, CNALineItemManagementPageState } from './CNALineItemManagementPage.constants';
import { getBearerToken } from '../../../functions/authActions';
import axios from 'axios';
import CNALineItemDisplay from './CNALineItemDisplay';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import LineDetails from './CNALineItemDisplay.linedetails';

export default class CNALineItemManagementPage extends React.Component<CNALineItemManagementPageProps, CNALineItemManagementPageState> {

  lineItemRef: React.RefObject<LineDetails> = React.createRef<LineDetails>();

  constructor(props: CNALineItemManagementPageProps) {
    super(props);
    this.state = {
      cnaUID: "",
      propType: 0,
      selectedLineViewer: props.selectedLine
    }
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();

    const response = await axios.get('./api/capital-needs-assessment/' + this.state.cnaUID)

    if (response.status == 200) {
      this.setState({
        propType: response.data[0].property.type
      })
    }
    else {
      //TODO ERROR HANDLE
    }
  }

  setSelectedLineViewer(lineItemViewerSetting: SelectOptions) {
    this.setState({ selectedLineViewer: lineItemViewerSetting });
  }

  selectedLines(): JSX.Element {
    switch (parseInt(this.state.selectedLineViewer.value)) {
      case 0:
        return <CNALineItemDisplay key="sites" categoryType={"sites"} propType={this.state.propType} />
      case 1:
        return <CNALineItemDisplay categoryType={"architectual"} propType={this.state.propType} />
      case 2:
        return <CNALineItemDisplay categoryType={"mech-and-electric"} propType={this.state.propType} />
      case 3:
        return <CNALineItemDisplay categoryType={"dwelling-unit"} propType={this.state.propType} />
      case 4:
        return (
          <div key="all-displayed" style={{ overflowY: "auto", overflowX: "hidden", height: "75vh" }}>
            <CNALineItemDisplay categoryType={"sites"} propType={this.state.propType} />
            <hr />
            <CNALineItemDisplay categoryType={"architectual"} propType={this.state.propType} />
            <hr />
            <CNALineItemDisplay categoryType={"mech-and-electric"} propType={this.state.propType} />
            <hr />
            <CNALineItemDisplay categoryType={"dwelling-unit"} propType={this.state.propType} />
          </div>
        )
      default:
        return
    }
  }

  render(): JSX.Element {
    return (
      <>
        {
          this.selectedLines()
        }
      </>
    )
  }

}