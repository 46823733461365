export interface MapCoord {
  lng: number;
  lat: number;
}


export function HaversineDistance(loc1: MapCoord, loc2: MapCoord): number {
  var R = 3958.8;
  var rlat1 = loc1.lat * (Math.PI / 180);
  var rlat2 = loc2.lat * (Math.PI / 180);
  var diffLat = rlat2 - rlat1;
  var diffLng = (loc2.lng - loc1.lng) * (Math.PI / 180);
  var d = 2 * R * Math.asin(Math.sqrt(Math.sin(diffLat / 2) * Math.sin(diffLat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(diffLng / 2) * Math.sin(diffLng / 2)));
  return parseFloat(d.toFixed(2));
}