import axios from "axios";
import * as React from "react"
import FilteredWithSettingsTableHeader from "../../CoreComponents/CoreTableHeaders"
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { StatusModal, WarningModal } from "../../CoreComponents/Modals";
import { StatusMap } from "../constants";
import { Col, Row } from "reactstrap";
import RunPayApplication from "../Modals/RunPayApplication";
import { getBearerToken, getUserID } from "../../../functions/authActions";
import { DownloadNamedFile } from "../../../functions/documentTools";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";

interface DrawAppStatus {
  uid: string;
  job: string;
  status: string;
  number: number;
  endDate: Date;
  isFinal: boolean;
  isTest: boolean;
  creator: string;
}

interface DrawAppSummaryState {
  headers: Array<FilterAndSettingField>;
  workflows: Array<DrawAppStatus>;
}

interface DrawAppSummaryProps {
  uid: string;
}

/**
 * Internal is used for within the Construction Management System
 * The Non-Internal Table of the Same Name is used for the home dashboards
 */
export class DrawRequestSummaryTableInternal extends React.Component<DrawAppSummaryProps, DrawAppSummaryState> {

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  runPaymentApplication: React.RefObject<RunPayApplication> = React.createRef<RunPayApplication>();

  constructor(props: DrawAppSummaryProps) {
    super(props);
    this.state = {
      headers: [],
      workflows: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let response = await axios.get('./api/request-for-payment-workflow/job/' + this.props.uid, { validateStatus: () => true })
    if (response.status === 200) {
      this.setState({
        workflows: response.data
      })
    }
    else {
      this.warningModal.current.show("Error While Loading Workflows for Review. Please submit a tech ticket so that Johnny or Tom can review it", "Error.")
    }
  }

  generateHeaders(): void {
    let headers: Array<FilterAndSettingField> = []
    headers.push({ columnName: "Draw", setting: true, filter: {} });
    headers.push({ columnName: "Status", setting: true, filter: {} });
    headers.push({ columnName: "Creator", setting: true, filter: {}, additionalClasses: "twenty" });
    headers.push({ columnName: "End Date", setting: true, filter: {}, additionalClasses: "twenty" });
    headers.push({ columnName: "Final", setting: true, filter: {}, additionalClasses: "fifteen" });
    headers.push({ columnName: "", setting: true, filter: {}, additionalClasses: "twenty" });
    headers.push({ columnName: "", setting: true, filter: {}, additionalClasses: "twenty" });
    this.setState({ headers: headers });
  }

  async approveWorkflow(id: string, status: number): Promise<void> {
    let response = await axios.get('./api/request-for-payment-workflow/approve/' + id + "/" + getUserID() + "/" + status, { validateStatus: () => true });
    if (response.status === 304) {
      window.location.reload();
    }
    else if (response.status === 412) {
      this.warningModal.current.show(await response.data, "Error while processing");
    }
    else {
      this.warningModal.current.show(await response.data, "Error while Processing");
    }
  }

  async downloadFile(uid: any): Promise<void> {
    this.statusModal.current.display("Preparing ZipFile", "Please wait while we prepare the zip file. It will take around a minute");
    let response = await axios.get('./api/request-for-payment-workflow/all-documents/' + uid, { responseType: 'blob', validateStatus: () => true });
    this.statusModal.current.hide();
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
    else {
      this.warningModal.current.show("Error Downloading Files. Please contact the tech team so they can investigate the concern.", "Error");
    }
  }

  render(): JSX.Element {
    return (
      <>
        <RunPayApplication key="run-payment-modal" ref={this.runPaymentApplication} uid={this.props.uid} callback={null} />
        <WarningModal ref={this.warningModal} />
        <StatusModal ref={this.statusModal} />
        {RenderHeader("Draw Application in Progress")}
        <div style={{ height: "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.workflows.map((item: DrawAppStatus) => {
                  return (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst">{item.number + 1 + (item.isTest ? " (Test)" : "")} </td>
                      <td className="fixedTableCell">{StatusMap[parseInt(item.status)]}</td>
                      <td className="fixedTableCell">{item.creator}</td>
                      <td className="fixedTableCell">{new Date(item.endDate).toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' })}</td>
                      <td className="fixedTableCell">{item.isFinal ? "Yes" : "No"}</td>
                      <td className="fixedTableCell">
                        <input type="button" value="Advance" className="standard-input"
                          onClick={(e) => this.approveWorkflow(item.uid, parseInt(item.status))}
                        />
                      </td>
                      <td className="fixedTableCell">
                        <input type="button" value="Download" className="standard-input" onClick={() => this.downloadFile(item.uid)} />
                      </td>
                    </tr>)
                })
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Start Pay Application" onClick={() => this.runPaymentApplication.current.show()} className="standard-input" />
          </Col>
        </Row>
      </>
    )
  }
}

