import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { StreetAddress } from '../../CoreComponents/interfaces';
import { YesNoModal } from '../../CoreComponents/Modals';
import { EntityEmptyRow, EntityTableRowData } from '../Structs';
import { getDayTag } from '../../../functions/dateFunctions';


export class FinalEmptyRow extends React.Component<EntityEmptyRow, EntityEmptyRow> {

  constructor(props: EntityEmptyRow) {
    super(props);
    this.state = {
      entityTableSettings: props.entityTableSettings,
      create: props.create
    }
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst" ></td>
        {this.state.entityTableSettings.showAccountingFirm && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showYardiUniqueCode && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showYardiAccount && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showTaxID && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showUei && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showCage && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showCageExp && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showManagingMember && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showPartners && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showAuthorizedSigners && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showFormationState && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showSosRenewalDate && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showEntityType && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showSCorpElection && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showEntityStatus && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showFormationDate && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showRegisteredAgents && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showFirstYearTaxReturn && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showFinalYearTaxReturn && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showEntityManager && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showAccountant && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showStreetAddress && <td className="fixedTableCell"></td>}
        {this.state.entityTableSettings.showNotes && <td className="fixedTableCell"></td>}
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faPlus} style={{ marginLeft: "15%", cursor: "pointer", color: "#b89961" }} onClick={this.state.create} />
        </td>
      </tr>
    )
  }
}

export class EntityTableRow extends React.Component<EntityTableRowData, EntityTableRowData> {

  modalRef = React.createRef<YesNoModal>();
  static Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(props: EntityTableRowData) {
    super(props);
    this.state = {
      index: props.index,
      entity: props.entity,
      entityTableSettings: props.entityTableSettings,
      backgroundColor: "#4C4A42",
      color: '#d7c7a7',
      creating: false,
      editRow: props.editRow,
      editAddress: props.editAddress
    };
    this.checkRedirect = this.checkRedirect.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
  }

  checkRedirect(): void {
    this.modalRef.current?.show(
      "Open in New Tab or Current Tab",
      "Open New Tab?",
      () => window.location.assign('./entity-information-page?uid=' + this.state.entity.uid),
      () => window.open('./entity-information-page?uid=' + this.state.entity.uid),
      "New Tab",
      "Current Tab"
    )
  }

  async saveAddress(address: StreetAddress): Promise<void> {
    const data = {
      StreetAddress: address.address,
      City: address.city,
      State: address.state.value,
      ZipCode: address.zipcode
    }
    let response = await axios.put('./api/entities/' + this.state.entity.uid + '/address', data);
    if (response.status === 200) {
      this.state.editAddress(this.state.index, address);
    }
  }


  render(): JSX.Element {
    let sosTag = getDayTag(this.state.entity.sosRenew.getUTCDate());
    let cageTag = getDayTag(this.state.entity.cageExpiration.getUTCDate());
    let sosDate = EntityTableRow.Months[this.state.entity.sosRenew.getUTCMonth()] + " " + this.state.entity.sosRenew.getUTCDate() + sosTag;
    let cageDate = EntityTableRow.Months[this.state.entity.cageExpiration.getUTCMonth()] + " " + this.state.entity.cageExpiration.getUTCDate() + cageTag + " " + this.state.entity.cageExpiration.getUTCFullYear();
    return (
      <tr>
        <YesNoModal ref={this.modalRef} />
        <td className="fixedTableCellFirst" style={{ backgroundColor: this.state.backgroundColor, color: this.state.color }}
          onMouseEnter={() => this.setState({ color: '#b89961' })} onMouseLeave={() => this.setState({ color: '#d7c7a7' })} >
          <em onClick={this.checkRedirect} style={{ cursor: "pointer" }}>
            {this.state.entity.name}
          </em>
        </td>
        {this.state.entityTableSettings.showAccountingFirm && <td className="fixedTableCell">{this.state.entity.firm.label}</td>}
        {this.state.entityTableSettings.showYardiUniqueCode && <td className="fixedTableCell">{this.state.entity.uniqueCode}</td>}
        {this.state.entityTableSettings.showYardiAccount && <td className="fixedTableCell">{this.state.entity.yardiAccount.value === "1" ? "CDI" : "VMI"}</td>}
        {this.state.entityTableSettings.showTaxID && <td className="fixedTableCell">{this.state.entity.fein}</td>}
        {this.state.entityTableSettings.showUei && <td className="fixedTableCell">{this.state.entity.uei}</td>}
        {this.state.entityTableSettings.showCage && <td className="fixedTableCell">{this.state.entity.cage}</td>}
        {this.state.entityTableSettings.showCageExp &&
          <td className="fixedTableCell">{this.state.entity.cageExpiration.toLocaleDateString() === '1/1/1'
            || this.state.entity.cageExpiration.toLocaleDateString() === '1/1/0001'
            || this.state.entity.cageExpiration.toLocaleDateString() === "1/1/1901" ?
            "" : cageDate}
          </td>
        }
        {this.state.entityTableSettings.showManagingMember && <td className="fixedTableCell">{this.state.entity.managingMember}</td>}
        {this.state.entityTableSettings.showPartners && <td className="fixedTableCell">{this.state.entity.ownership}</td>}
        {this.state.entityTableSettings.showAuthorizedSigners && <td className="fixedTableCell">
          {this.state.entity.authorizedSigners.map(u => u.label).join(",")}
        </td>}
        {this.state.entityTableSettings.showFormationState && <td className="fixedTableCell">{this.state.entity.jurisdiction.label}</td>}
        {this.state.entityTableSettings.showSosRenewalDate && <td className="fixedTableCell">{this.state.entity.sosRenew.toLocaleDateString() === '1/1/1'
          || this.state.entity.sosRenew.toLocaleDateString() === '1/1/0001'
          || this.state.entity.sosRenew.toLocaleDateString() === "1/1/1901" ? "" : sosDate}</td>}
        {this.state.entityTableSettings.showEntityType && <td className="fixedTableCell">{this.state.entity.type.label}</td>}
        {this.state.entityTableSettings.showSCorpElection && <td className="fixedTableCell">
          {this.state.entity.sCorpElection.value === "2" ? "No" : this.state.entity.sCorpElection.value === "1" ? "Yes" : ""}
        </td>}
        {this.state.entityTableSettings.showEntityStatus && <td className="fixedTableCell">{this.state.entity.status.value === "1" ? "Active" : "Inactive"}</td>}
        {this.state.entityTableSettings.showFormationDate && <td className="fixedTableCell">{this.state.entity.formationDate.toLocaleDateString() === '1/1/1'
          || this.state.entity.formationDate.toLocaleDateString() === '1/1/0001'
          || this.state.entity.formationDate.toLocaleDateString() === "1/1/1901" ? "" : this.state.entity.formationDate.toLocaleDateString()}
        </td>}
        {this.state.entityTableSettings.showRegisteredAgents && <td className="fixedTableCell">{this.state.entity.agent.label}</td>}
        {this.state.entityTableSettings.showFirstYearTaxReturn && <td className="fixedTableCell">{this.state.entity.isFirstYearReturn.value === "1" ? "Yes" : ""}</td>}
        {this.state.entityTableSettings.showFinalYearTaxReturn && <td className="fixedTableCell">{this.state.entity.isFinalYearReturn.value === "1" ? "Yes" : ""}</td>}
        {this.state.entityTableSettings.showEntityManager && <td className="fixedTableCell">{this.state.entity.portfolio.label}</td>}
        {this.state.entityTableSettings.showAccountant && <td className="fixedTableCell">
          <a href={"/employee-account-info?uid=" + this.state.entity.accountant.value}>
            {this.state.entity.accountant.label}
          </a>
        </td>}
        {this.state.entityTableSettings.showStreetAddress
          &&
          <td className="fixedTableCell">
            {this.state.entity.streetAddress}
          </td>
        }
        {this.state.entityTableSettings.showNotes && <td className="fixedTableCell">{this.state.entity.notes}</td>}
        <td className="fixedTableCell">
          {!this.state.creating &&
            <FontAwesomeIcon icon={faCog} style={{ marginLeft: "15%", cursor: "pointer", color: "#b89961" }} onClick={
              () => this.state.editRow(this.state.entity.uid, this.state.entity)}
            />
          }
        </td>
      </tr>
    )
  }
}
