import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { YesNoModal } from '../Modals';
import { ScheduleOfValuesLineItemRow } from './ScheduleOfValuesLineItem';

export interface ChangeEventRowProps {
  data: ChangeEvent;
  callback: () => void;
  remove: (uid: string) => void;
  eventIdx: number;
}

export interface ChangeEventRowState {
  data: ChangeEvent;
}

export interface ChangeEvent {
  uid: string;
  eventNumber: number;
  type: SelectOptions;
  description: string;
  scheduledValues: number;
  previousBilled: number;
  materials: number;
  thisPeriod: number;
  retention: number;
  totalCompleted: number;
  percentage: number;
  balance: number;
}

export class ChangeEventLineItem extends React.Component<ChangeEventRowProps, ChangeEventRowState> {

  yesNoModal = React.createRef<YesNoModal>();

  static EmptyCE: ChangeEvent = {
    uid: "",
    eventNumber: -1,
    type: { label: "Empty", value: ""},
    description: "",
    scheduledValues: 0,
    previousBilled: 0,
    thisPeriod: 0,
    materials: 0,
    retention: 0,
    percentage: 0,
    totalCompleted: 0,
    balance: 0
  };

  constructor(props: ChangeEventRowProps) {
    super(props);
    this.state = {
      data: props.data
    }
    //this.changeType = this.changeType.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeScheduledValues = this.changeScheduledValues.bind(this);
    this.changePercentage = this.changePercentage.bind(this);
    this.changeThisPeriod = this.changeThisPeriod.bind(this);
    this.changeMaterials = this.changeMaterials.bind(this);
    this.changeRetention = this.changeRetention.bind(this);
    this.changeEventNumber = this.changeEventNumber.bind(this);
    this.calculate = this.calculate.bind(this);
    this.calculatePercentageOnly = this.calculatePercentageOnly.bind(this);
    this.remove = this.remove.bind(this);

    this.updateDescription = this.updateDescription.bind(this);
    this.updateScheduledValues = this.updateScheduledValues.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    //this.updateType = this.updateType.bind(this);
    this.updateThisPeriod = this.updateThisPeriod.bind(this);
    this.updateRetention = this.updateRetention.bind(this);
    this.updateMaterials = this.updateMaterials.bind(this);

    this.showConfirmationModal = this.showConfirmationModal.bind(this);
  }

  //#region Functions

  //changeType(event: SelectOptions | null): void {
  //  if (event === null) { return; }
  //  this.state.data.type = event;
  //  this.setState({});
  //}

  //async updateType(): Promise<void> {
  //  const data = {
  //    uid: this.state.data.uid,
  //    type: this.state.data.type.value
  //  }
  //  await axios.patch('./api/change-order-line-items/type', data);
  //}

  changeDescription(event: React.ChangeEvent<HTMLInputElement>): void {
    this.state.data.description = event.target.value;
    this.setState({});
  }

  async updateDescription(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      description: this.state.data.description
    }
    await axios.patch('./api/change-order-line-items/description', data);
  }

  changeScheduledValues(event: React.ChangeEvent<HTMLInputElement>): void {
    this.state.data.scheduledValues = parseFloat(parseFloat(event.target.value).toFixed(2))
    this.setState({});
  }

  async updateScheduledValues(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      scheduledValues: this.state.data.scheduledValues
    }
    await axios.patch('./api/change-order-line-items/scheduled-values', data);
  }

  /**
   * This function should call the update methods as a callback. Please
   * do not create a patch for percentage
   */
  changePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
    let value = parseFloat(event.target.value);
    if (value > 100) {
      value = 100;
    }
    else if (value < 0) {
      value = 0;
    }
    this.state.data.percentage = (value / 100);
    this.setState({}, () => this.calculate());
  }

  changeThisPeriod(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.thisPeriod = parseFloat(parseFloat(event.target.value).toFixed(2))
    this.setState({
      data: data
    }, this.calculatePercentageOnly)
  }

  async updateThisPeriod(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      thisPeriod: this.state.data.thisPeriod
    }
    await axios.patch('./api/change-order-line-items/this-period', data);
  }

  changeMaterials(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.materials = parseFloat(parseFloat(event.target.value).toFixed(2))
    this.setState({
      data: data
    }, this.calculatePercentageOnly);
  }

  async updateMaterials(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      materials: this.state.data.materials
    }
    await axios.patch('./api/change-order-line-items/materials', data);
  }

  changeRetention(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.retention = parseFloat(parseFloat(event.target.value).toFixed(2))
    this.setState({
      data: data
    }, this.calculatePercentageOnly);
  }

  async updateRetention(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      retention: this.state.data.retention
    }
    await axios.patch('./api/change-order-line-items/retention', data);
  }

  changeEventNumber(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.eventNumber = parseInt(event.target.value)
    this.setState({
      data: data
    }, this.calculatePercentageOnly);
  }

  async updateEventNumber(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      eventNumber: this.state.data.eventNumber
    }
    await axios.patch('./api/change-order-line-items/retention', data);
  }

  calculate(): void {
    const current = (this.state.data.scheduledValues * this.state.data.percentage) - this.state.data.previousBilled - this.state.data.materials;
    const retention = (current + this.state.data.materials + this.state.data.previousBilled) * 0.05;
    const total = current + this.state.data.materials + this.state.data.previousBilled;
    let data = this.state.data;
    data.thisPeriod = current;
    data.retention = retention;
    data.totalCompleted = total;
    data.balance = this.state.data.scheduledValues - total;
    this.setState({ data: data }, () => this.props.callback());
    this.updateThisPeriod();
  }

  calculatePercentageOnly(): void {
    const scheduled = this.state.data.scheduledValues;
    const percentage = (this.state.data.thisPeriod + this.state.data.previousBilled + this.state.data.materials) / scheduled;
    const retention = parseFloat(((this.state.data.thisPeriod + this.state.data.materials) * .05).toFixed(2));
    let data = this.state.data;
    data.totalCompleted = percentage * scheduled;
    data.balance = scheduled - (percentage * scheduled);
    data.percentage = percentage;
    data.retention = retention;
    this.setState({
      data: data
    }, () => this.props.callback())
  }

  showConfirmationModal(): void {
    this.yesNoModal.current.show(
      "Are you sure you wish to delete the change order?",
      "Delete Change Order",
      () => this.remove(),
      () => console.log("cancel"),
      "Cancel",
      "Delete"
    );
  }

  async remove(): Promise<void> {
    await this.props.remove(this.state.data.uid);
  }

  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <YesNoModal ref={this.yesNoModal} />
        <td className="fixedTableCellFirstNoStick">
          <input type="number" className="standard-input"
            value={this.state.data.eventNumber} onChange={this.changeEventNumber}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </td>
        <td className="fixedTableCell">
          {/*<Select*/}
          {/*  styles={reactSelectBasicStyle}*/}
          {/*  options={ScheduleOfValuesLineItemRow.Map.map(function (item) { return { label: item.description, value: item.item } })}*/}
          {/*  value={this.state.data.type}*/}
          {/*  onChange={this.changeType}*/}
          {/*  onBlur={this.updateType}*/}
          {/*/>*/}
        </td>
        <td className="fixedTableCell">
          <input className="standard-input" type="text" value={this.state.data.description}
            onChange={this.changeDescription}
            onWheel={(e) => e.currentTarget.blur()}
            onBlur={this.updateDescription}
          />
        </td>
        <td className="fixedTableCell">
          <input className="standard-input" type="number" value={this.state.data.scheduledValues}
            onChange={this.changeScheduledValues}
            onWheel={(e) => e.currentTarget.blur()}
            onBlur={this.updateScheduledValues}
          />
        </td>
        <td className="fixedTableCell">
          {this.state.data.previousBilled}
        </td>
        <td className="fixedTableCell">
          <input className="standard-input" type="number" value={this.state.data.thisPeriod}
            onChange={this.changeThisPeriod} onBlur={this.updateThisPeriod}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </td>
        <td className="fixedTableCell">
          <input className="standard-input" type="number" value={this.state.data.materials}
            onChange={this.changeMaterials} onBlur={this.updateMaterials}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </td>
        <td className="fixedTableCell">
          <input className="standard-input" type="number" value={this.state.data.retention}
            onChange={this.changeRetention} onBlur={this.updateRetention}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </td>
        <td className="fixedTableCell">
          ${numberWithCommas(this.state.data.totalCompleted)}
        </td>
        <td className="fixedTableCell">
          <input className="standard-input" type="number" value={this.state.data.percentage * 100}
            onChange={this.changePercentage}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </td>
        <td className="fixedTableCell">
          ${numberWithCommas(this.state.data.balance)}
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faTrash} onClick={this.showConfirmationModal} style={{ marginLeft: "30%" }} />
        </td>
      </tr>
    )
  }

}