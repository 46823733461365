import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { LineItemEditModalProps, LineItemEditModalState } from './CNALineItemDisplay.LineItemEditModal.constants';
import { CNALineActions, CNALineCondtions, CNALineItem, CNALineItemSelectOptions, CNALineQuantityTypes } from './CNALineItemDisplay.constants';
import { getUserID } from '../../../functions/authActions';
import { PatchBooleanAuditRequest, PatchNumberAuditRequest, PatchStringAuditRequest } from '../../PatchRequests';
import { WarningModal } from '../../CoreComponents/Modals';
import { YesNo } from '../../../constants/EnumConstants';

export default class LineItemEditModal extends React.Component<LineItemEditModalProps, LineItemEditModalState> {

  warningRef: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: LineItemEditModalProps) {
    super(props);
    this.state = {
      show: false,
      title: "",
      lineData: {
        uid: "",
        item: { label: "Please Select Option", value: "", family: 0, elderly: 0 },
        eul: 0,
        age: "",
        rul: "",
        isHealthAndSafetyIssue: { label: "No", value: "0" },
        condition: { label: "Please Select Option", value: "" },
        action: { label: "Please Select Option", value: "" },
        duration: "",
        qty: "",
        unit: { label: "Please Select Option", value: "" },
        unitCost: "",
        totalCost: "",
        comments: ""
      },
      lineOptions: [],
      propType: -1,
      cnaUID: "",
      callback: undefined
    }
    this.show = this.show.bind(this)
    this.callback = this.callback.bind(this)
    this.changeMinorCategory = this.changeMinorCategory.bind(this)
    this.changeAge = this.changeAge.bind(this)
    this.changeEUL = this.changeEUL.bind(this)
    this.changeRUL = this.changeRUL.bind(this)
    this.changeCondition = this.changeCondition.bind(this)
    this.changeAction = this.changeAction.bind(this)
    this.changeDuration = this.changeDuration.bind(this)
    this.changeQty = this.changeQty.bind(this)
    this.changeUnit = this.changeUnit.bind(this)
    this.changeUnitCost = this.changeUnitCost.bind(this)
    this.changeComments = this.changeComments.bind(this)
    this.changeIsHealthAndSafetyIssue = this.changeIsHealthAndSafetyIssue.bind(this)
  }

  show(title: string, linedata: CNALineItem, lineOptions: Array<CNALineItemSelectOptions>, propType: number, cnaUID: string) {
    if (linedata.isHealthAndSafetyIssue) {
      linedata.isHealthAndSafetyIssue = { label: "Yes", value: "1" }
    }
    else {
      linedata.isHealthAndSafetyIssue = { label: "No", value: "0" }
    }
    this.setState({
      show: true,
      title: title,
      lineData: linedata,
      lineOptions: lineOptions,
      propType: propType,
      cnaUID: cnaUID
    })
  }

  async changeMinorCategory(event: CNALineItemSelectOptions | null): Promise<void> {
    if (event === null) { return }
    const item = this.state.lineData
    item.item = event
    await PatchStringAuditRequest(this.state.lineData.uid, getUserID(), this.state.lineData.item.label, './api/cna-line-item/update-minor-category', null);
    this.setState({ lineData: item })
  }

  async changeEUL(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.lineData
    item.eul = parseInt(event.target.value);
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), item.eul, './api/cna-line-item/update-eul', null)
    this.setState({ lineData: item })
  }

  async changeAge(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.lineData
    item.age = event.target.value;
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.target.value), './api/cna-line-item/update-age', null)
    this.setState({ lineData: item })
  }

  async changeRUL(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.lineData
    item.rul = event.target.value;
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.target.value), './api/cna-line-item/update-rul', null)
    this.setState({ lineData: item })
  }

  async changeIsHealthAndSafetyIssue(event: SelectOptions | null): Promise<void> {
    const item = this.state.lineData
    item.isHealthAndSafetyIssue = event
    let value = false;
    if (event.value === '1') {
      value = true
    }
    await PatchBooleanAuditRequest(this.state.lineData.uid, getUserID(), value, './api/cna-line-item/update-is-health-and-safety', null)
    this.setState({ lineData: item })
  }

  async changeCondition(event: SelectOptions | null): Promise<void> {
    if (event === null) { return }
    const item = this.state.lineData
    item.condition = event
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.value), './api/cna-line-item/update-condition', null)
    this.setState({ lineData: item })
  }

  async changeAction(event: SelectOptions | null): Promise<void> {
    if (event === null) { return }
    const item = this.state.lineData
    item.action = event
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.value), './api/cna-line-item/update-action', null)
    this.setState({ lineData: item })
  }

  async changeDuration(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.lineData
    item.duration = event.target.value;
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.target.value), './api/cna-line-item/update-duration', null)
    this.setState({ lineData: item })
  }

  async changeQty(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.lineData
    item.qty = event.target.value;
    item.totalCost = (parseInt(item.qty) * parseInt(item.unitCost)).toString()
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.target.value), './api/cna-line-item/update-qty', null)
    this.setState({ lineData: item })
  }

  async changeUnit(event: SelectOptions | null): Promise<void> {
    if (event === null) { return }
    const item = this.state.lineData
    item.unit = event
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.value), './api/cna-line-item/update-qty-type', null)
    this.setState({ lineData: item })
  }

  async changeUnitCost(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.lineData
    item.unitCost = event.target.value;
    item.totalCost = (parseInt(item.qty) * parseInt(item.unitCost)).toString()
    await PatchNumberAuditRequest(this.state.lineData.uid, getUserID(), parseInt(event.target.value), './api/cna-line-item/update-unit-cost', null)
    this.setState({ lineData: item })
  }

  async changeComments(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const item = this.state.lineData
    item.comments = event.target.value;
    await PatchStringAuditRequest(this.state.lineData.uid, getUserID(), event.target.value, './api/cna-line-item/update-comments', null)
    this.setState({ lineData: item })
  }

  callback(): void {
    this.setState({
      show: false
    });
    if (this.props.callback) {
      this.props.callback();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <ModalHeader style={{ backgroundColor: "#4C4A42" }}>
          <h3 style={{ color: "#d7c7a7" }}>{this.state.title}</h3>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          <Row>
            <Col>Line Category:</Col>
            <Col>
              <Select
                value={this.state.lineData.item}
                styles={reactSelectBasicStyle}
                options={this.state.lineOptions}
                onChange={this.changeMinorCategory}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>EUL:</Col>
            <Col>
              <input type="number" className="standard-input" value={this.state.lineData.eul} onChange={this.changeEUL} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Age:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.age} onChange={this.changeAge} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>RUL:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.rul} onChange={this.changeRUL} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Is Health And Safety Issue:</Col>
            <Col>
              <Select
                value={this.state.lineData.isHealthAndSafetyIssue}
                styles={reactSelectBasicStyle}
                options={YesNo}
                onChange={this.changeIsHealthAndSafetyIssue}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Condition:</Col>
            <Col>
              <Select
                value={this.state.lineData.condition}
                styles={reactSelectBasicStyle}
                options={CNALineCondtions}
                onChange={this.changeCondition}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Action:</Col>
            <Col>
              <Select
                value={this.state.lineData.action}
                styles={reactSelectBasicStyle}
                options={CNALineActions}
                onChange={this.changeAction}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Duration:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.duration} onChange={this.changeDuration} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>QTY:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.qty} onChange={this.changeQty} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Unit:</Col>
            <Col>
              <Select
                value={this.state.lineData.unit}
                styles={reactSelectBasicStyle}
                options={CNALineQuantityTypes}
                onChange={this.changeUnit}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Unit Cost:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.unitCost} onChange={this.changeUnitCost} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Comments:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.comments} onChange={this.changeComments} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
          <input type="button" value="Submit" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
          />
        </ModalFooter>
      </Modal>
    )
  }

}