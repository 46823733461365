import axios from 'axios';
import * as React from 'react';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';

const TaxReturnStatusMap = [
  "Waiting To Start",
  "Preparing Documents",
  "Financial Review",
  "Waiting On Firm",
  "Draft Return Received",
  "Internal Review",
  "Shareholder Review",
  "Review Complete",
  "Tax Return Submitted",
  "Tax Return Complete",
]

interface TaxReturnRowProps {
  row: any;
}

interface TaxReturnRowState {

}

class TaxReturnRow extends React.Component<TaxReturnRowProps, TaxReturnRowState>{

  constructor(props: TaxReturnRowProps) {
    super(props);
    this.state = {

    }
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.props.row.entity}</td>
        <td className="fixedTableCell">{this.props.row.canStart ? "Yes" : "No"}</td>
        <td className="fixedTableCell">{this.props.row.taxReturnStatus}</td>
        <td className="fixedTableCell">{this.props.row.currentAssignee}</td>
        <td className="fixedTableCell">{this.props.row.accountant}</td>
        <td className="fixedTableCell">{this.props.row.notes}</td>
        {
          this.props.row.TaxReturnStatus === "Waiting To Start" &&
          <td className="fixedTableCell"><input className="standard-input" type="button" value="Take" /></td>
        }
      </tr>
    )
  }
}

interface TaxReturnManagementScreenProps {

}

interface TaxReturnManagementScreenState {
  headers: Array<any>;
  returns: Array<any>;
}

export default class TaxReturnManagementScreen extends React.Component<TaxReturnManagementScreenProps, TaxReturnManagementScreenState>{

  constructor(props: TaxReturnManagementScreenProps) {
    super(props);
    this.state = {
      headers: this.generateHeaders(),
      returns: []
    }
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    await axios.get('./api/entity-tax-returns').then(function (response) {
      const rets: any = [];
      response.data.forEach(function (item: any) {
        rets.push({
          entity: item.entity.name,
          taxReturnStatus: TaxReturnStatusMap[item.status],
          accountant: item.accountant.fullName,
          year: item.year,
          currentAssignee: item.currentAssignee,
          notes: item.notes,
          canStart: item.canStart
        })
      });
      self.setState({
        returns: rets
      })
    });
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        setting: true,
        columnName: "Entity",
        filter: {}
      },
      {
        setting: true,
        columnName: "Can Start",
        filter: {}
      },
      {
        setting: true,
        columnName: "Tax Return Status",
        filter: {}
      },
      {
        setting: true,
        columnName: "Current Assignee",
        filter: {}
      },
      {
        setting: true,
        columnName: "Accountant",
        filter: {}
      }
      ,
      {
        setting: true,
        columnName: "Notes",
        filter: {}
      },
      {
        setting: true,
        columnName: "Action",
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div style={{ minHeight: "84vh", maxHeight: "84vh", overflow: "scroll" }}>
        <h3> Tax Management List {new Date().getFullYear() - 1}</h3>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.returns.map((item: any, idx: any) => (
                <TaxReturnRow key={idx} row={item} />
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}