import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import axios from 'axios';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { pullEmpireEmployees } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface SupervisorEmployeeManagementRowData {
  uid: string;
  employee: SelectOptions;
  position: string;
}

interface SupervisorEmployeeManagementWidgetProps {
  supervisorUID: string;
}

interface SupervisorEmployeeManagementWidgetState {
  rows: Array<SupervisorEmployeeManagementRowData>;
}

export class SupervisorEmployeeManagementWidget extends React.Component<SupervisorEmployeeManagementWidgetProps, SupervisorEmployeeManagementWidgetState> {

  employeeManagementModal: React.RefObject<EmployeeSubordinateModal> = React.createRef<EmployeeSubordinateModal>();

  headers: Array<FilterAndSettingField> = [
    { columnName: "Employee", setting: true, filter: {} },
    { columnName: "Position", setting: true, filter: {} },
  ]

  constructor(props: SupervisorEmployeeManagementWidgetProps) {
    super(props);
    this.state = {
      rows: []
    }
  }


  async componentDidMount(): Promise<void> {
    let response = await axios.get('./api/employee-supervisor-map/employees/' + this.props.supervisorUID, { validateStatus: () => true });

    this.setState({
      rows: response.data
    })
  }

  render(): JSX.Element {
    return (
      <div>
        <EmployeeSubordinateModal ref={this.employeeManagementModal} />
        {RenderHeader("Manage Employees")}
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.rows.map((item: SupervisorEmployeeManagementRowData) => (
                <tr key={item.uid}>
                  <td className="fixedTableCellFirst">{item.employee.label}</td>
                  <td className="fixedTableCell">{item.position}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Manage" className="standard-input" onClick={() => this.employeeManagementModal.current.show(this.props.supervisorUID)} />
      </div>
    )
  }
}

interface EmployeeSubordinateModalState {
  showing: boolean;
  supervisorUID: string;
  employees: Array<SelectOptions>;
  rows: Array<SupervisorEmployeeManagementRowData>;
}

class EmployeeSubordinateModal extends React.Component<{}, EmployeeSubordinateModalState> {

  headers: Array<FilterAndSettingField> = [
    { columnName: "Employee", setting: true, filter: {} },
    { columnName: "Position", setting: true, filter: {} },
    { columnName: "", setting: true, filter: {}, additionalClasses: "icon" }
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      employees: [],
      rows: [],
      supervisorUID: ""
    }
    this.forceClose = this.forceClose.bind(this);
    this.addRow = this.addRow.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
    this.changePosition = this.changePosition.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let employees = await pullEmpireEmployees();
    this.setState({
      employees: employees
    })
  }

  forceClose(): void {
    this.setState({
      showing: false
    }, () => window.location.reload());
  }

  async show(supervisorUID: string): Promise<void> {
    let response = await axios.get('./api/employee-supervisor-map/employees/' + supervisorUID, { validateStatus: () => true });
    this.setState({
      showing: true,
      supervisorUID: supervisorUID,
      rows: response.data
    })
  }

  addRow(): void {
    let rows = this.state.rows;
    rows.push({
      employee: { label: "Select Employee", value: "" },
      position: "",
      uid: EMPTY_GUID
    });
    this.setState({ rows: rows })
  }

  async changeEmployee(event: SelectOptions | null, idx: number): Promise<void> {
    let rows = this.state.rows;
    let row = rows[idx];
    if (row.employee.value === "") {
      const data = {
        SupervisorUID: this.state.supervisorUID,
        EmployeeUID: event.value
      }
      let response = await axios.post('./api/employee-supervisor-map', data, { validateStatus: () => true });
      if (response.status !== 200) {

      }
    }
    let response = await axios.get('./api/employee/pull-position/' + event.value, { validateStatus: () => true });
    if (response.status === 200) {
      row.employee = event;
      row.position = response.data === null ? "" : response.data;
      rows[idx] = row;
      this.setState({ rows: rows});
    }
  }

  changePosition(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    let rows = this.state.rows;
    row.position = event.target.value;
    rows[idx] = row;
    this.setState({ rows: rows});
  }

  async updatePosition(idx: number): Promise<void> {
    let row = this.state.rows[idx];
    const data = {
      uid: row.employee.value,
      position: row.position
    }
    let response = await axios.patch('./api/empire/auth/position', data, { validateStatus: () => true });
    if (response.status !== 202) {

    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="md" style={{ borderBottom: "0px solid #15405c" }}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Manage Employee's
          </ModalHeader>
          <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
            <ModalBody style={{ border: "0px solid #15405c" }}>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
                </thead>
                <tbody>
                  {
                    this.state.rows.map((item: SupervisorEmployeeManagementRowData, idx: number) => (
                      <tr key={item.uid}>
                        <td className="fixedTableCellFirst">
                          {item.employee.value === "" ? <Select
                            styles={reactSelectBasicStyle}
                            options={this.state.employees}
                            onChange={(e: SelectOptions) => this.changeEmployee(e, idx)}
                            value={item.employee}
                            onFocus={RemoveStickyOverlays}
                            onBlur={RestoreStickyOverlays}
                          />
                            :
                            <label>{item.employee.label}</label>
                          }
                        </td>
                        <td className="fixedTableCell">
                          <input type="text" value={item.position} className="standard-input" onChange={(e) => this.changePosition(e, idx)} onBlur={(e) => this.updatePosition(idx)} />
                        </td>
                        <td className="fixedTableCell">
                          <FontAwesomeIcon icon={faTrash} />
                        </td>
                      </tr>
                    ))
                  }
                  <TableAdditionRow length={3} onClick={this.addRow} marginLeft="25%" />
                </tbody>
              </table>
            </ModalBody>
          </div>
        </div>
      </Modal>
    )
  }
}