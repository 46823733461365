import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldHidden, DataEntryModalFieldStatic, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import axios from 'axios';
import { pullEmpireEmployees } from '../../../functions/fetchLinkedObjects';

export interface AdobeSignManagementModalData {
  uid: string;
  employee: SelectOptions;
  clientID: string;
  clientSecret: string;
  adobeServer: string;
}

interface AdobeSignManagementWidgetProps {
  callback: () => void;
}

interface AdobeSignManagementWidgetState {
  data: AdobeSignManagementModalData;
}

export default class AdobeSignManagementModal extends React.Component<AdobeSignManagementWidgetProps, AdobeSignManagementWidgetState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: AdobeSignManagementWidgetProps) {
    super(props);
    this.state = {
      data: {
        uid: "",
        employee: { label: "Select Employee", value: "" },
        clientID: "",
        clientSecret: "",
        adobeServer: ""
      }
    }
    this.generateData = this.generateData.bind(this);
    this.setClientID = this.setClientID.bind(this);
    this.setClientSecret = this.setClientSecret.bind(this);
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  async show(uid: string): Promise<void> {
    let employees = await pullEmpireEmployees();
    let data = this.state.data;
    data.employee = employees.filter(u => u.value === uid)[0];
    let response = await axios.get('./api/adobe-sign-credentials/' + uid);
    if (response.status === 200) {
      data.adobeServer = response.data.adobeServer;
      data.clientID = response.data.clientID;
      data.clientSecret = response.data.clientSecret;
      data.uid = response.data.uid;
    }
    else {
      data.uid = "";
    }
    this.setState({ data: data}, async () => await this.generateData());
  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "employeeName", name: "Employee", patchUrl: undefined, value: this.state.data.employee.label, static: true } as DataEntryModalFieldStatic);
    data.push({ key: "employeeUID", name: "Employee UID", patchUrl: undefined, value: this.state.data.employee.value, hidden: true } as DataEntryModalFieldHidden)
    data.push({ key: "clientID", name: "Client ID", patchUrl: undefined, value: this.state.data.clientID, type: "text", placeholder: "ABCDEFG", callback: this.setClientID} as DataEntryModalFieldText);
    data.push({ key: "clientSecret", name: "Client Secret", patchUrl: undefined, value: this.state.data.clientSecret, type: "text", placeholder: "HIJKLMNO", callback: this.setClientSecret } as DataEntryModalFieldText);
    data.push({ key: "adobeServer", name: "Adobe Server", patchUrl: undefined, value: this.state.data.adobeServer, type: "text", placeholder: "na4", callback: this.setAdobeServer } as DataEntryModalFieldText);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Adobe Sign API Setup", this.state.data.uid, 'api/adobe-sign-credentials');
  }

  setClientID(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.clientID = event.target.value;
    this.setState({ data: data }, () => this.generateData());
  } 

  setClientSecret(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.clientSecret = event.target.value;
    this.setState({ data: data }, () => this.generateData());
  }

  setAdobeServer(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.adobeServer = event.target.value;
    this.setState({ data: data }, () => this.generateData());
  }

  async callback(success: boolean): Promise<void> {
    this.entryModal.current.hide();
    this.props.callback();
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={this.callback} />
      </>
    )
  }
}