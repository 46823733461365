import * as React from 'react'
import { CNAContact, CNAProjectProviderWidgetProps, CNAProjectProviderWidgetState } from './CNAProjectProviderWidget.constants';
import { StatusModal, WarningModal } from '../../../CoreComponents/Modals';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { RenderHeader } from '../../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

export default class CNAProjectProviderWidget extends React.Component<CNAProjectProviderWidgetProps, CNAProjectProviderWidgetState> {

  statusRef: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningRef: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: CNAProjectProviderWidgetProps) {
    super(props);
    this.state = {
      cnaUID: props.cnaUID,
      provider: {
        name: "",
        addr1: "",
        addr2: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        email: ""
      }
    }
  }

  async componentDidMount(): Promise<void> {
    let provider: CNAContact = undefined;
    const providerAddressResponse = await axios.get('./api/capital-needs-assessment/provider/address/' + this.state.cnaUID)
    const providerContactResponse = await axios.get('./api/capital-needs-assessment/provider/contact/' + this.state.cnaUID)

    provider = {
      name: providerAddressResponse.data.provider.name,
      addr1: providerAddressResponse.data.provider.streetAddress.streetAddress,
      addr2: "",
      city: providerAddressResponse.data.provider.streetAddress.city,
      state: providerAddressResponse.data.provider.streetAddress.stateName,
      zipCode: providerAddressResponse.data.provider.streetAddress.zipCode,
      phoneNumber: providerContactResponse.data.provider.mainContacts[0].contact.phoneNumber ? providerContactResponse.data.provider.mainContacts[0].contact.phoneNumber : "Contact is not set",
      email: providerContactResponse.data.provider.mainContacts[0].contact.emailAddress ? providerContactResponse.data.provider.mainContacts[0].contact.emailAddress : "Contact is not set"
    }

    this.setState({
      provider: provider
    })

  }

  render(): JSX.Element {
    return (
      <div>
        {RenderHeader("Provider Contact")}
        <Row>
          <Col>
            <h5>Name</h5>
          </Col>
          <Col>
            <h6>{this.state.provider.name}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5>Address</h5>
          </Col>
          <Col>
            <h6>{this.state.provider.addr1}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5>Phone</h5>
          </Col>
          <Col>
            <h6>{this.state.provider.phoneNumber}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5>Email</h5>
          </Col>
          <Col>
            <h6>{this.state.provider.email}</h6>
          </Col>
        </Row>
      </div>
    )
  }
}