import * as React from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { numberWithCommas } from '../../functions/numberFunctions';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { DatePicker } from '../CoreComponents/DateComponents';
import { DecimalOptions, ProrateToolProps, ProrateToolState } from './ProrateTool.constants';

export default class ProrateTool extends React.Component<ProrateToolProps, ProrateToolState>{

  constructor(props: ProrateToolProps) {
    super(props);
    this.state = {
      startDate: new Date(),
      fullAmount: "",
      proratedRate: 0,
      decimalPlaces: { label: "None", value: "0" }
    }
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeFullAmount = this.changeFullAmount.bind(this);
    this.changeDecimalPlaces = this.changeDecimalPlaces.bind(this);
    this.prorateRent = this.prorateRent.bind(this);
  }

  //Get Start Date
  changeStartDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ startDate: event.target.valueAsDate })
  }

  //Get Full Monthly Amount
  changeFullAmount(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ fullAmount: event.target.value })

  }

  //Change Decimal Options
  changeDecimalPlaces(event: SelectOptions | null): void {
    this.setState({ decimalPlaces: event })
  }

  //Calculate
  prorateRent(): void {
    this.setState({ proratedRate: 0 })

    const startDate = new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth(), this.state.startDate.getDate(), 16, 0, 0)
    const amount: number = parseFloat(this.state.fullAmount)
    let leap: boolean = false
    let days: number = 30

    const month: number = startDate.getMonth()

    if (startDate.getFullYear() % 4 === 0) {
      leap = true
    }

    if (leap && month === 1) {
      days = 29
    }
    else if (month === 1 && !leap) {
      days = 28
    }
    else if (((month & 1) == 0 && month <= 7) || ((month & 1) == 1 && month >= 8)
    ) {
      days = 31
    }
    else {
      days = 30
    }

    const dividedAmount = amount / days

    const beginDay = startDate.getDate()

    let daysLeft = days - beginDay + 1

    const prorateAmount = daysLeft * dividedAmount;

    this.setState({ proratedRate: prorateAmount })

  }

  render(): JSX.Element {
    return (
      <div style={{ padding: "5%" }}>
        <h3>Proration Tool</h3>
        <Row>
          <Col>
            <h6>Enter Start Date</h6>
            <DatePicker className="standard-input" onChange={this.changeStartDate} value={this.state.startDate} />
          </Col>
          <Col>
            <h6>Enter Amount Full Amount</h6>
            <input type="number" className="standard-input" onChange={this.changeFullAmount} value={this.state.fullAmount} />
          </Col>
          <Col>
            <h6>Decimal Places</h6>
            <Select
              value={this.state.decimalPlaces}
              styles={reactSelectBasicStyle}
              options={DecimalOptions}
              onChange={this.changeDecimalPlaces}
              menuPlacement="auto"
              menuPosition="fixed"
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
        </Row>
        <hr />
        <input type="button" className="standard-input" style={{ marginBottom: "2%" }} onClick={() => this.prorateRent()} value="Prorate" />
        <h4>$ {numberWithCommas(this.state.proratedRate, parseInt(this.state.decimalPlaces.value))}</h4>
      </div>
    )
  }
}