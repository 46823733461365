import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { getBearerToken } from '../../../../functions/authActions';
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { pullFleetEmployee } from '../../../../functions/fetchLinkedObjects';
import EntityRequestEditModal from './EntityRequestEditModal';

interface EntityRequestReviewHeaderProps {
  uid: string;
}

interface EntityRequestReviewHeaderState {
  uid: string;
  userName: string;
  entityName: string;
  creationDate: Date;
}

export default class EntityRequestReviewHeader extends React.Component<EntityRequestReviewHeaderProps, EntityRequestReviewHeaderState> {

  modalRef = React.createRef<EntityRequestEditModal>();

  constructor(props: EntityRequestReviewHeaderProps) {
    super(props);
    this.state = {
      uid: props.uid,
      userName: "",
      entityName: "",
      creationDate: new Date("1977-5-26")
    }
    this.returnRequest = this.returnRequest.bind(this);
    this.editStringEntry = this.editStringEntry.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const employees = await pullFleetEmployee();
    await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
      this.setState({
        entityName: response.data.entityName,
        userName: { label: employees.filter(u => u.value === response.data.requestorUID)[0].label, value: response.data.requestorUID }.label,
        creationDate: new Date(response.data.creationDate)
      })
    })
  }

  componentDidUpdate(): void {

  }

  async returnRequest(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();

    await axios.patch('./api/new-entity-workflow/send-back/' + this.state.uid).then((response) => {
      CreateGlobalAlert("Workflow Updated", 2500);
    })

    await axios.patch("./api/new-entity-request/return-request/" + this.state.uid).then((response) => {
      CreateGlobalAlert("Draft has been returned, redirecting to Drafts Table", 2500);
      window.location.replace("./entity-request-drafts")
    });
  }

  editStringEntry(field: string, data: string, saveType: string): void {
    this.modalRef.current?.showStringEdit(
      this.state.uid,
      field,
      data,
      saveType,
    )
  }

  render(): JSX.Element {
    return (
      <Row style={{ width: "98%", marginLeft: "1%", border: "3px solid #d7c7a7", backgroundColor: "#4C4A42", marginBottom: "2vh", paddingTop: "1vh" }}>
        <EntityRequestEditModal ref={this.modalRef} />
        <Col>
          <h3>Entity Draft Review</h3>
        </Col>
        <Col>
          <div>
            <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Entity Name</h5>
            <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }} onClick={() => this.editStringEntry("Entity Name", this.state.entityName, 'EntityName')} />
          </div>
          <div>
            <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Requestor Name</h5>
          </div>
          <div>
            <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Requested Date</h5>
          </div>
        </Col>
        <Col>
          <h5>{this.state.entityName}</h5>
          <h5>{this.state.userName}</h5>
          <h5>{this.state.creationDate.toDateString()}</h5>
        </Col>
        <Col>
          <input type="button" className="standardInput" value="Reject"
            style={{ width: "60%", marginLeft: "20%", height: "5vh", marginBottom: "1vh", background: "#AA0000", color: "#FFFFFF" }}
            onClick={this.returnRequest} />
        </Col>
        <hr />
      </Row>
    )
  }
}