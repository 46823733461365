import * as React from 'react'
import axios from 'axios';
import { Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { CNALineItemData, CNAYearData, CNAYearModelInterface, YearDataProps, YearDataState } from './CNALineItemDisplay.YearData.constants';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import EditYearDataModal from './CNALineItemDisplay.YearData.EditYearDataModal';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';

export default class YearData extends React.Component<YearDataProps, YearDataState> {

  statusRef: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningRef: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  yearRef: React.RefObject<EditYearDataModal> = React.createRef<EditYearDataModal>();

  constructor(props: YearDataProps) {
    super(props);
    this.state = {
      headers: [],
      category: props.cnaLineItemCategory,
      cnaUID: props.cnaUID,
      inflatePercent: 7,
      totalData: [],
      inflationData: [],
      inflatedTotal: [],
      lineItemList: []
    }
    this.updateInflationPercent = this.updateInflationPercent.bind(this)
    this.getLineItems = this.getLineItems.bind(this)
  }

  async componentDidMount(): Promise<void> {
    const inflatedTotal: Array<CNAYearData> = []
    const inflationData: Array<number> = []
    const totalList: Array<CNAYearData> = []

    inflationData.push(0)
    inflatedTotal.push({ year: i, amount: 0 })
    totalList.push({ year: i, amount: 0 })

    for (var i = 0; i < 21; i++) {
      let data = Math.pow((1 + (this.state.inflatePercent / 100.0)), i)
      data = (data - 1) * 100
      inflationData.push(data)
      inflatedTotal.push({ year: i, amount: 0 })
      totalList.push({ year: i, amount: 0 })
    }

    await this.getLineItems();

    this.state.lineItemList.forEach((item) => {
      const _year: Array<CNAYearModelInterface> = []
      _year.push({
        amount: 0,
        year: 0,
        uid: ""
      })


      item.yearData.forEach((year, idx) => {
        _year.push(year)
        if (item.isHealthAndSafetyIssue) {
          _year[0].amount += year.amount
          totalList[0].amount += year.amount
          inflatedTotal[0].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
        }
        _year[idx + 1].amount = year.amount
        totalList[idx + 1].amount += year.amount
        if (idx < 1) {
          inflatedTotal[idx + 1].amount += year.amount
        }
        else {
          inflatedTotal[idx + 1].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
        }
      })

      item.yearData = []
      item.yearData = _year
    })


    this.setState({
      totalData: totalList,
      inflationData: inflationData,
      inflatedTotal: inflatedTotal
    })

    this.getHeaders();

  }

  getHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Item',
        additionalClasses: "thirty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'H & S',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      }
    ]

    for (let count = 0; count < 21; count++) {
      headers.push(
        {
          columnName: (new Date(Date.now()).getFullYear() + count).toString(),
          additionalClasses: "ten",
          setting: true,
          filter: {

          }
        }
      )
    }

    this.setState({ headers: headers })
  }

  async getLineItems(): Promise<void> {
    const lineItemList: Array<CNALineItemData> = []

    const response = await axios.get('./api/cna-line-item/' + this.state.category + '/' + this.state.cnaUID)

    if (response.status === 200) {
      response.data.forEach((item: any) => {
        lineItemList.push({
          uid: item.uid,
          lineItem: item.minorCategory,
          yearData: item.cnaLineItemYearData,
          isHealthAndSafetyIssue: item.isHealthAndSafetyIssue,
          totalCost: item.unitCost * item.quantity,
          duration: item.duration
        })
      })
    }
    else {
      //TODO ERROR HANDLE
    }

    this.setState({ lineItemList: lineItemList })
  }

  updateInflationPercent(event: React.ChangeEvent<HTMLInputElement>): void {
    if (Number.isNaN(parseInt(event.target.value))) {
      event.target.value = "0"
    }

    if (parseInt(event.target.value) > 0) {
      this.state.totalData.forEach((item, idx) => {
        this.state.inflatedTotal[idx].amount = item.amount * Math.pow((1 + (parseInt(event.target.value) / 100.0)), idx)
        this.state.inflationData[idx] = Math.pow((1 + (parseInt(event.target.value) / 100.0)), idx)
      })
    }
    else if (parseInt(event.target.value) <= 0) {
      this.state.totalData.forEach((item, idx) => {
        this.state.inflatedTotal[idx].amount = item.amount
        this.state.inflationData[idx] = 0
      })
    }

    this.setState({ inflatePercent: parseInt(event.target.value) })
  }

  render(): JSX.Element {
    return (
      <div>
        <EditYearDataModal ref={this.yearRef} />
        <Row>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              <>
                {
                  this.state.lineItemList.map((item) => (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst" style={!item.isHealthAndSafetyIssue ? {} : { background: "#FF0000" }}>{item.lineItem}</td>
                      {
                        this.getTableRow(item)
                      }
                      <td className="fixedTableCell">
                        <FontAwesomeIcon icon={faCog} style={{ marginLeft: "25%" }} onClick={() => this.editYear(item)} />
                      </td>
                    </tr>
                  ))
                }
                <tr key={"STATIC0"}>
                  <td className="fixedTableCellFirst">Uninflated Totals</td>
                  {
                    this.state.totalData.map((item, idx) => (
                      <td key={"STATIC1" + idx} className="fixedTableCell">$ {item.amount}</td>
                    ))
                  }
                </tr>
                <tr key={"STATIC3"}>
                  <td className="fixedTableCellFirst">Inflation Factor:  <input type="text" className="standard-input" style={{ width: "2vw", height: "3vh", float: "right", textAlign: "right" }} value={this.state.inflatePercent} onChange={this.updateInflationPercent} /></td>
                  {
                    this.state.inflationData.map((item, idx) => (
                      <td key={"STATIC4" + idx} className="fixedTableCell">{item.toFixed(2)} %</td>
                    ))
                  }
                </tr>
                <tr key={"STATIC5"}>
                  <td className="fixedTableCellFirst">Inflated Totals</td>
                  {
                    this.state.inflatedTotal.map((item, idx) => (
                      <td key={"STATIC6" + idx} className="fixedTableCell">$ {item.amount.toFixed(2)}</td>
                    ))
                  }
                </tr>
              </>
            </tbody>
          </table>
        </Row>
      </div>
    )
  }

  //Render Functions

  getTableRow(item: CNALineItemData): JSX.Element {
    let ret: JSX.Element = <>
      {
        item.yearData.map((data) => {
          return <td key={data.uid} className="fixedTableCell">$ {data.amount}</td>
        })
      }
    </>;
    return ret;
  }

  async editYear(lineItem: CNALineItemData): Promise<void> {
    this.yearRef.current.show("Set Payment Schedule", lineItem, this.getLineItems)
  }

}