import * as React from 'react';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { WarningModal } from '../CoreComponents/Modals';
import axios from 'axios';
import ComputerAccessoryTrackingModal, { AccessoryTypeSelect } from './ComputerAccessoryTrackingManagementWidget.modals';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { numberWithCommas } from '../../functions/numberFunctions';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

export interface ComputerAccessoryTrackingSummary {
  uid: string;
  computer: SelectOptions;
  description: string;
  brand: string;
  type: SelectOptions;
  installDate: Date;
  cost: number;
}

interface ComputerAccessoryTrackingManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<ComputerAccessoryTrackingSummary>;
  computers: Array<ComputerAccessoryTrackingSummary>;
  description: string;
  brand: string;
  computerName: string;
  accessoryType: SelectOptions;
}

export default class ComputerAccessoryTrackingManagementWidget extends React.Component<{}, ComputerAccessoryTrackingManagementWidgetState> {

  trackingModal: React.RefObject<ComputerAccessoryTrackingModal> = React.createRef<ComputerAccessoryTrackingModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      headers: [],
      data: [],
      computers: [],
      description: "",
      brand: "",
      accessoryType: { label: "All", value: "-1" },
      computerName: ""
    }
    this.callback = this.callback.bind(this);
    this.changeComputerName = this.changeComputerName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeBrand = this.changeBrand.bind(this);
    this.changeType = this.changeType.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async updateData(): Promise<void> {
    const data = {
      page: 0,
      pageCount: 0,
      description: this.state.description,
      brand: this.state.brand,
      accessoryType: this.state.accessoryType.value,
      computerName: this.state.computerName
    }
    let response = await axios.post('./api/computer-accessory-tracking/filter', data, { validateStatus: () => true });
    if (response.status === 200) {
      let data: Array<ComputerAccessoryTrackingSummary> = [];
      response.data.forEach((item: any) => {
        data.push({
          uid: item.uid,
          computer: { label: item.computer.computerName, value: item.computer.uid },
          description: item.description,
          brand: item.brand,
          type: AccessoryTypeSelect[item.accessoryType + 1],
          installDate: new Date(item.installationDate),
          cost: item.accessoryCost
        })
      })
      this.setState({ data: data });
    }
    else {
      //this.warningModal.current.show("There was an error loading data. Please reach out to the tech team to let them know.", "Error");
    }
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Computer",
        setting: true,
        additionalClasses: "twenty",
        filter: {
          type: "text",
          name: "computerName",
          value: this.state.computerName,
          changeFilter: this.changeComputerName,
          placeHolder: "Filter Computers"
        }
      },
      {
        columnName: "Description",
        setting: true,
        filter: {
          type: "text",
          name: "description",
          value: this.state.description,
          changeFilter: this.changeDescription,
          placeHolder: "Filter Description"
        }
      },
      {
        columnName: "Brand",
        setting: true,
        additionalClasses: "fifteen",
        filter: {
          type: "text",
          name: "brand",
          value: this.state.brand,
          changeFilter: this.changeBrand,
          placeHolder: "Filter Brand"
        }
      },
      {
        columnName: "Type",
        setting: true,
        additionalClasses: "twenty",
        filter: {
          name: "accessoryType",
          value: this.state.accessoryType,
          changeFilter: this.changeType,
          options: AccessoryTypeSelect,
          multi: false
        }
      },
      {
        columnName: "Install",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Cost",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      }
    ]
    this.setState({ headers: headers }, async () => await this.updateData());
  }

  async changeComputerName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ computerName: event.target.value }, async () => await this.generateHeaders());
  }

  async changeDescription(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ description: event.target.value }, async () => await this.generateHeaders());
  }

  async changeBrand(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ brand: event.target.value }, async () => await this.generateHeaders());
  }

  async changeType(event: SelectOptions): Promise<void> {
    this.setState({ accessoryType: event }, async () => await this.generateHeaders());
  }

  callback(edit: boolean): void {
    this.trackingModal.current.hide();
    if (!edit) {
      this.warningModal.current.show("Computer Accessory has been created.", "Computer Accesory Tracking Created", () => window.location.reload());
    }
    else {
      window.location.reload();
    }
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        <ComputerAccessoryTrackingModal ref={this.trackingModal} callback={this.callback} />
        {RenderHeader("Computer Accessory Tracking")}
        <div style={{ height: "25vh", overflowY: "scroll", paddingBottom: "5px" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.computer.label}</td>
                    <td className="fixedTableCell">{item.description}</td>
                    <td className="fixedTableCell">{item.brand}</td>
                    <td className="fixedTableCell">{item.type.label}</td>
                    <td className="fixedTableCell">{item.installDate.toLocaleDateString('en-CA')}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.cost, 2)}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="View" className="standard-input" onClick={() => this.trackingModal.current.show(item)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create" className="standard-input" onClick={() => this.trackingModal.current.show()} />
      </>
    )
  }
}
