import * as React from "react";
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { StaticAssets } from "../StaticAssets"
import { CreateGlobalAlert } from "../../functions/CreateGlobalAlerts";
import { numberWithCommas } from "../../functions/numberFunctions";
import { SelectOptions } from "../../interfaces/CoreInterfaces";
import { reactSelectBasicStyle } from "../../style/select-constants";
import Select from "react-select";
import * as Structs from './Structs';

export class FullConsumption extends React.Component<Structs.FullConsumptionProps, Structs.FullConsumptionState> {

  commonRef = React.createRef<CommonConsumption>();
  unitRef = React.createRef<UnitConsumption>();

  static MeteringChoices: Array<SelectOptions> = [
    { label: "Master", value: "0" },
    { label: "Aggregate", value: "1" },
    { label: "Aggregate - Commercial Only", value: "2" },
  ]

  constructor(props: Structs.FullConsumptionProps) {
    super(props);
    this.state = {
      property: props.property,
      screener: props.screener,
      settings: props.settings,
      meterType: props.meterType,
      meterData: null,
      commonRows: [],
      residentialRows: [],
      combinedRows: [],
      showResidential: true,
      provider: '',
      meter: { label: "Select Metering", value: "-1" },

      commonConsumptionRate: 0,
      commonDemandRate: 0,
      commonFlatFees: 0,
      commonTax: 0,
      commonMeters: 0,

      residentialRate: 0,
      residentialDemand: 0,
      residentialFlat: 0,
      residentialTax: 0,
      residentialMeters: 0,
      loaded: false
    }
    this.submit = this.submit.bind(this);
    this.calculate = this.calculate.bind(this);
    this.changeMetering = this.changeMetering.bind(this);

    this.calculateMaster = this.calculateMaster.bind(this);
    this.calculateAggregate = this.calculateAggregate.bind(this);
    this.calculateAggregateCommOnly = this.calculateAggregateCommOnly.bind(this);
  }

  async submit(): Promise<void> {
    var rates = [];
    this.commonRef.current.state.rows.forEach(function (item, idx) {
      const data = {
        Month: idx,
        IsCommon: true,
        Consumption: item.consumption,
        Demand: item.demand,
        EstimatedCost: item.estimatedCost
      }
      rates.push(data);
    });
    if (this.state.showResidential) {
      this.unitRef.current.state.rows.forEach(function (item, idx) {
        const data = {
          Month: idx,
          IsCommon: false,
          Consumption: item.consumption,
          Demand: item.demand,
          EstimatedCost: item.estimatedCost
        }
        rates.push(data);
      });
    }
    else {
      for (let i = 0; i < 12; ++i) {
        const data = {
          Month: i,
          IsCommon: false,
          Consumption: 0,
          Demand: 0,
          EstimatedCost: 0
        }
        rates.push(data);
      }
    }

    var type = this.state.meter.value;
    await axios.post('./api/solar-consumption-line-item/' + this.state.screener + "/" + type + "/clear-and-update", rates).then(function (response) {
      CreateGlobalAlert("Solar Consumption has been updated, reloading screener", 1200, () => window.location.reload());
    })
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    await axios.get("./api/meter-settings/" + this.state.settings).then(function (response) {
      self.setState({
        meterData: response.data,
      })
    });
    await axios.get("./api/electric-utility-providers/property/" + this.state.property + "/active-only").then(function (response) {
      self.setState({
        provider: response.data.name
      })
    });
    await axios.get("./api/solar-screener/" + self.state.property).then(function (response) {
      var common: Array<Structs.ConsumptionRow> = new Array(12);
      var resi: Array<Structs.ConsumptionRow> = new Array(12);
      var combined: Array<Structs.ConsumptionRow> = new Array(12);
      response.data.consumptionLines.forEach(function (item: any) {
        if (item.isCommon) {
          common[item.month] = {
            //@ts-ignore
            month: StaticAssets.Months[item.month],
            consumption: item.consumption,
            flat: 0,
            demand: item.demand,
            estimatedCost: 0
          };
        }
        else {
          resi[item.month] = {
            //@ts-ignore
            month: StaticAssets.Months[item.month],
            consumption: item.consumption,
            flat: 0,
            demand: item.demand,
            estimatedCost: 0
          };
        }
      });
      //JSON
      combined = JSON.parse(JSON.stringify(common));

      resi.forEach(function (item, idx) {
        combined[idx].consumption += item.consumption;
        combined[idx].demand += item.demand
      })
      self.setState({
        commonRows: common,
        residentialRows: resi,
        combinedRows: combined,
        meter: FullConsumption.MeteringChoices[response.data.meteringType]
      }, () => self.calculate());
    });
  }

  calculate(): void {
    var type = this.state.meter.value;
    this.setState({ loaded: false })
    switch (type) {
      case '0':
        this.calculateMaster();
        break;
      case '1':
        this.calculateAggregate();
        break;
      case '2':
        this.calculateAggregateCommOnly();
        break;
      default:
        break;
    }
  }

  changeMetering(event: SelectOptions | null): void {
    this.setState({
      meter: event,
    }, () => this.calculate())
  }

  async calculateMaster(): Promise<void> {
    const self = this;
    await axios.get("./api/electric-rate/" + self.state.meterData.masterCommercialRateUID).then(function (response) {
      self.setState({
        commonConsumptionRate: response.data.weightedConsumptionCharge,
        commonDemandRate: response.data.weightedDemandCharge,
        commonTax: response.data.totalTaxPercentage,
        commonFlatFees: response.data.totalFlatCharges,
        commonMeters: self.state.meterData.masterCommercial,
        loaded: true,
        showResidential: false
      })
    });
  }

  async calculateAggregateCommOnly(): Promise<void> {
    const self = this;
    await axios.get("./api/electric-rate/" + self.state.meterData.aggregateCommercialOnlyRateUID).then(function (response) {
      self.setState({
        commonConsumptionRate: response.data.weightedConsumptionCharge,
        commonDemandRate: response.data.weightedDemandCharge,
        commonTax: response.data.totalTaxPercentage,
        commonFlatFees: response.data.totalFlatCharges,
        commonMeters: self.state.meterData.aggregateCommercialOnly,
        loaded: true,
        showResidential: false
      })
    });
  }

  async calculateAggregate(): Promise<void> {
    const self = this;
    await axios.get("./api/electric-rate/" + self.state.meterData.aggregateCommercialRateUID).then(function (response) {
      self.setState({
        commonConsumptionRate: response.data.weightedConsumptionCharge,
        commonDemandRate: response.data.weightedDemandCharge,
        commonTax: response.data.totalTaxPercentage,
        commonFlatFees: response.data.totalFlatCharges,
        commonMeters: self.state.meterData.aggregateCommercial
      })
    });
    await axios.get("./api/electric-rate/" + self.state.meterData.aggregateResidentialRateUID)
      .then(function (_response) {
        self.setState({
          residentialRate: _response.data.weightedConsumptionCharge,
          residentialDemand: _response.data.weightedDemandCharge,
          residentialTax: _response.data.totalTaxPercentage,
          residentialFlat: _response.data.totalFlatCharges,
          residentialMeters: self.state.meterData.aggregateResidential,
          loaded: true,
          showResidential: true
        })
      })
  }

  render(): JSX.Element {
    return (
      <div id="full-consumption-page">
        <div id="table-container" style={{ minHeight: "80vh", maxHeight: "80vh", overflowY: "auto", marginLeft: "-15px" }}>
          <h3 id="utility-name">Electric Provider: {this.state.provider}</h3>
          <div id="metering-choice">
            <Select
              options={FullConsumption.MeteringChoices}
              styles={reactSelectBasicStyle}
              value={this.state.meter}
              onChange={this.changeMetering}
            />
          </div>
          <div>
            {this.state.loaded && <Row style={{ margin: "0px", padding: "0px", width: "100%", marginTop: "10px" }}>
              <Col style={{ marginLeft: "-5px" }}>
                <CommonConsumption ref={this.commonRef} consumption={this.state.commonConsumptionRate}
                  demand={this.state.commonDemandRate} flat={this.state.commonFlatFees} taxPercentage={this.state.commonTax}
                  meterCount={this.state.commonMeters} rows={!this.state.showResidential ? this.state.combinedRows : this.state.commonRows}
                />
              </Col>
              {this.state.showResidential &&
                <Col id="unit-table-container" style={{ margin: "0px", marginLeft: "12px", padding: "0px" }}>
                  <UnitConsumption ref={this.unitRef} consumption={this.state.residentialRate} demand={this.state.residentialDemand}
                    flat={this.state.residentialFlat} taxPercentage={this.state.residentialTax} meterCount={this.state.residentialMeters}
                    rows={this.state.residentialRows}
                  />
                </Col>
              }
            </Row>
            }
          </div>
        </div>
        <input type="button" value="Save Consumption Data" style={{ width: "100%", marginLeft: "-15px", marginTop: "15px" }} onClick={this.submit} />
      </div>
    )
  }
}


class CommonConsumption extends React.Component<Structs.ConsumptionProps, Structs.ConsumptionState> {

  constructor(props: Structs.ConsumptionProps) {
    super(props);
    this.state = {
      rows: props.rows,
      rates: [],
      consumption: props.consumption,
      demand: props.demand,
      flat: props.flat,
      taxPercentage: props.taxPercentage,
      meterCount: props.meterCount,
      totalConsumption: 0,
      totalDemand: 0,
      totalFlat: 0,
      totalCost: 0
    }
    this.calculate = this.calculate.bind(this);
    this.changeConsumption = this.changeConsumption.bind(this);
    this.changeDemand = this.changeDemand.bind(this);
  }

  componentDidMount(): void {
    this.setCommonConsumptionData();
  }

  changeConsumption(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.consumption = parseFloat(event.target.value)
    this.state.rows[idx] = row;
    this.setState({}, () => this.calculate())
  }

  changeDemand(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.demand = parseFloat(event.target.value)
    this.state.rows[idx] = row;
    this.setState({}, () => this.calculate())
  }

  calculate(): void {
    if (!Number.isInteger(this.state.meterCount)) {
      CreateGlobalAlert("Meter Settings are not configured correctly...", 2500);
    }
    let totalConsumption = 0;
    let totalDemand = 0;
    let totalFees = 0;
    let totalCost = 0;
    let rows = this.state.rows;
    const self = this;
    this.state.rows.forEach(function (item, idx) {
      let meterFees = self.state.flat * self.state.meterCount;
      let cost = meterFees + (item.consumption * self.state.consumption) + (item.demand * self.state.demand);
      cost += (cost * self.state.taxPercentage);
      totalConsumption += item.consumption;
      totalDemand += item.demand;
      totalFees += meterFees;
      totalCost += cost;
      item.estimatedCost = cost;
      item.flat = meterFees;
      rows[idx] = item;
    })
    this.setState({
      rows: rows,
      totalConsumption: totalConsumption,
      totalDemand: totalDemand,
      totalFlat: totalFees,
      totalCost: totalCost
    })
  }

  setCommonConsumptionData(): void {
    if (this.state.rows.length !== 0) {
      this.calculate();
      return;
    }
    var tmp: Array<Structs.ConsumptionRow> = [];
    for (var i = 0; i < 12; ++i) {
      //@ts-ignore
      var month = StaticAssets.Months[i];
      tmp.push({
        month: month,
        consumption: 0,
        demand: 0,
        flat: 0,
        estimatedCost: 0
      })
    }
    this.setState({ rows: tmp }, () => this.calculate());
  }

  render(): JSX.Element {
    return (
      <div id="common-consumption-container" key="common-consumption-container">
        <h4 id="common-utility-rate">Commercial Schedule</h4>
        <table style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th className="fixedTableHeaderFirst">Consumption Rate</th>
              <th className="fixedTableHeader">Demand Rate</th>
              <th className="fixedTableHeader">Flat Charges</th>
              <th className="fixedTableHeader">Tax Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="common-consumption" style={{ textAlign: "right" }}>${numberWithCommas(this.state.consumption, 3)} per kWh</td>
              <td id="common-demand" style={{ textAlign: "right" }}>${numberWithCommas(this.state.demand, 3)} per kW</td>
              <td id="common-flat" style={{ textAlign: "right" }}>${numberWithCommas(this.state.flat)}</td>
              <td id="common-tax" style={{ textAlign: "right" }}>{numberWithCommas(this.state.taxPercentage * 100, 3)}%</td>
            </tr>
          </tbody>
        </table>
        <h5 style={{ marginTop: "10px" }}>Common Area Consumption</h5>
        <table style={{ tableLayout: "fixed", width: "100%" }}>
          <thead>
            <tr>
              <th className="fixedTableHeaderFirst">Month</th>
              <th className="fixedTableHeader">Consumption (kWh)</th>
              <th className="fixedTableHeader">Demand (kW)</th>
              <th className="fixedTableHeader">Meter Fees</th>
              <th className="fixedTableHeader">Estimated Cost</th>
            </tr>
          </thead>
          <tbody id="common-consumption-list">
            {
              this.state.rows.map((item, idx) => (
                <tr className="consumption-row" key={idx}>
                  <td className="fixedTableCellFirst">{item.month}</td>
                  <td className="fixedTableCell">
                    <input type='number' className="standard-input" value={item.consumption} onChange={(e) => this.changeConsumption(e, idx)} placeholder="0" />
                  </td>
                  <td className="fixedTableCell">
                    <input type='number' className="standard-input" onChange={(e) => this.changeDemand(e, idx)} placeholder="0" value={item.demand} />
                  </td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.flat)}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.estimatedCost)}</td>
                </tr>
              ))
            }
            <tr>
              <td className="fixedTableCellFirst">Total</td>
              <td className="fixedTableCell" id="total-unit-consumption">{numberWithCommas(this.state.totalConsumption)}</td>
              <td className="fixedTableCell" id="total-unit-demand">{numberWithCommas(this.state.totalDemand)}</td>
              <td className="fixedTableCell" id="total-unit-meter-fees" style={{ textAlign: "right" }}>${numberWithCommas(this.state.totalFlat)}</td>
              <td className="fixedTableCell" id="total-unit-cost" style={{ textAlign: "right" }}>${numberWithCommas(this.state.totalCost)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

class UnitConsumption extends React.Component<Structs.ConsumptionProps, Structs.ConsumptionState> {

  constructor(props: Structs.ConsumptionProps) {
    super(props);
    this.state = {
      rows: props.rows,
      rates: [],
      consumption: props.consumption,
      demand: props.demand,
      flat: props.flat,
      taxPercentage: props.taxPercentage,
      meterCount: props.meterCount,
      totalConsumption: 0,
      totalDemand: 0,
      totalFlat: 0,
      totalCost: 0
    }
    this.calculate = this.calculate.bind(this);
    this.changeConsumption = this.changeConsumption.bind(this);
    this.changeDemand = this.changeDemand.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.setUnitConsumptionData();
  }

  calculate(): void {
    if (!Number.isInteger(this.state.meterCount)) {
      CreateGlobalAlert("Meter Settings are not configured correctly...", 2500);
    }
    let totalConsumption = 0;
    let totalDemand = 0;
    let totalFees = 0;
    let totalCost = 0;
    let rows = this.state.rows;
    const self = this;
    this.state.rows.forEach(function (item, idx) {
      let meterFees = self.state.flat * self.state.meterCount;
      let cost = meterFees + (item.consumption * self.state.consumption) + (item.demand * self.state.demand);
      cost += (cost * self.state.taxPercentage);
      totalConsumption += item.consumption;
      totalDemand += item.demand;
      totalFees += meterFees;
      totalCost += cost;
      item.estimatedCost = cost;
      item.flat = meterFees;
      rows[idx] = item;
    })
    this.setState({
      rows: rows,
      totalConsumption: totalConsumption,
      totalDemand: totalDemand,
      totalFlat: totalFees,
      totalCost: totalCost
    })
  }

  setUnitConsumptionData(): void {
    if (this.state.rows.length !== 0) {
      this.calculate();
      return;
    }
    var tmp: Array<Structs.ConsumptionRow> = [];
    for (var i = 0; i < 12; ++i) {
      //@ts-ignore
      var month = StaticAssets.Months[i];
      tmp.push({
        month: month,
        consumption: 0,
        demand: 0,
        flat: 0,
        estimatedCost: 0
      })
    }
    this.setState({ rows: tmp }, () => this.calculate());
  }

  changeConsumption(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.consumption = parseFloat(event.target.value)
    this.state.rows[idx] = row;
    this.setState({}, () => this.calculate())
  }

  changeDemand(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.demand = parseFloat(event.target.value)
    this.state.rows[idx] = row;
    this.setState({}, () => this.calculate())
  }

  render(): JSX.Element {
    return (
      <div id="unit-consumption-container">
        <h4 id="common-utility-rate">Residential Schedule</h4>
        <table style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th className="fixedTableHeaderFirst">Consumption Rate</th>
              <th className="fixedTableHeader">Demand Rate</th>
              <th className="fixedTableHeader">Flat Charges</th>
              <th className="fixedTableHeader">Tax Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="residential-consumption" style={{ textAlign: "right" }}>${numberWithCommas(this.state.consumption, 3)} per kWh</td>
              <td id="residential-demand" style={{ textAlign: "right" }}>${numberWithCommas(this.state.demand, 3)} per kW</td>
              <td id="residential-flat" style={{ textAlign: "right" }}>${numberWithCommas(this.state.flat)}</td>
              <td id="residential-tax" style={{ textAlign: "right" }}>{numberWithCommas(this.state.taxPercentage * 100, 3)}%</td>
            </tr>
          </tbody>
        </table>
        <h5 style={{ marginTop: "10px" }}>Unit Consumption</h5>
        <table style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th className="fixedTableHeaderFirst">Month</th>
              <th className="fixedTableHeader">Consumption (kWh)</th>
              <th className="fixedTableHeader">Demand (kW)</th>
              <th className="fixedTableHeader">Meter Fees</th>
              <th className="fixedTableHeader">Estimated Cost</th>
            </tr>
          </thead>
          <tbody id="unit-consumption-list">
            {
              this.state.rows.map((item, idx) => (
                <tr className="unit-consumption-row">
                  <td className="fixedTableCellFirst">{item.month}</td>
                  <td className="fixedTableCell">
                    <input type='number' className="standard-input" value={item.consumption} onChange={(e) => this.changeConsumption(e, idx)} placeholder="0" />
                  </td>
                  <td className="fixedTableCell">
                    <input type='number' className="standard-input" onChange={(e) => this.changeDemand(e, idx)} placeholder="0" value={item.demand} />
                  </td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.flat)}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.estimatedCost)}</td>
                </tr>
              ))
            }
            <tr>
              <td className="fixedTableCellFirst">Total</td>
              <td className="fixedTableCell" id="total-unit-consumption">{numberWithCommas(this.state.totalConsumption)}</td>
              <td className="fixedTableCell" id="total-unit-demand">{numberWithCommas(this.state.totalDemand)}</td>
              <td className="fixedTableCell" id="total-unit-meter-fees" style={{ textAlign: "right" }}>${numberWithCommas(this.state.totalFlat)}</td>
              <td className="fixedTableCell" id="total-unit-cost" style={{ textAlign: "right" }}>${numberWithCommas(this.state.totalCost)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}