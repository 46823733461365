import * as React from 'react';
import axios from "axios";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { numberWithCommas } from '../../functions/numberFunctions';
import { FileEntryModal, StatusModal } from '../CoreComponents/Modals';

interface PropertyLicenseRow {
  uid: number;
  property: SelectOptions;
  unitCount: number;
  unitCost: number;
  estimatedCost: number;
}

interface PropertyLicenseRowProps {
  row: PropertyLicenseRow;
}

interface PropertyLicenseManagementProps {
  licenseUID: string;
  update: (uid: string) => void;
}

interface PropertyLicenseManagementState {
  licenseUID: string;
  name: string;
  headers: Array<FilterAndSettingField>;
  rows: Array<PropertyLicenseRow>;
}


class PropertyLicenseManagementTableRow extends React.Component<PropertyLicenseRowProps, {}> {

  constructor(props: PropertyLicenseRowProps) {
    super(props);
    this.state = {

    }
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.props.row.property.label}</td>
        <td className="fixedTableCell">{numberWithCommas(this.props.row.unitCount, 0)}</td>
        <td className="fixedTableCell">{numberWithCommas(this.props.row.unitCost, 2)}</td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(this.props.row.estimatedCost, 2)}</td>
      </tr>
    )
  }
}


export class PropertyLicenseManagementTable extends React.Component<PropertyLicenseManagementProps, PropertyLicenseManagementState>{

  statusModal = React.createRef<StatusModal>();

  constructor(props: PropertyLicenseManagementProps) {
    super(props);
    this.state = {
      headers: [],
      rows: [],
      licenseUID: props.licenseUID,
      name: ""
    }
    this.generateETL = this.generateETL.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    this.statusModal.current.display("Loading Property Licenses", "Please wait. This can take a minute due to the amount of properties we manage.")
    await axios.get('./api/property-license-map/license/' + this.state.licenseUID).then((response) => {
      let rows: Array<PropertyLicenseRow> = [];
      response.data.forEach(function (item: any) {
        rows.push({
          uid: item.uid,
          property: { label: item.property.name, value: item.property.uid },
          unitCount: item.property.unitCount,
          unitCost: item.license.costPerUnit,
          estimatedCost: item.property.unitCount * item.license.costPerUnit
        })
      });
      this.statusModal.current.hide();
      this.setState({
        rows: rows
      })
    });
    await axios.get('./api/unit-license/' + this.state.licenseUID).then((response) => {
      this.setState({
        name: response.data.name
      })
    })

  }

  generateHeaders(): void {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Property",
        setting: true,
        filter: {}
      },
      {
        columnName: "Unit Count",
        setting: true,
        filter: {}
      },
      {
        columnName: "Unit Cost",
        setting: true,
        filter: {}
      },
      {
        columnName: "Estimated Cost",
        additionalClasses: "",
        setting: true,
        filter: {}
      },
    ]
    this.setState({
      headers: headers
    })
  }


  async generateETL(): Promise<void> {

    const formData = new FormData();
    let key = await axios.get('./api/status-manager');
    this.statusModal.current.display("Generating ETL Export", "Please wait. This can take a minute due to the amount of properties we manage.", key.data)
    let response = await axios.post('./api/unit-license/generate-upload/' + this.state.licenseUID + "/" + key.data, formData, { validateStatus: () => true, responseType: "blob" });
    this.statusModal.current.hide();
    if (response.status === 200) {
      const FileDownload = require('js-file-download');
      const content = response.headers['content-disposition'];
      const startIndex = content.lastIndexOf('\'\'') + 2;
      const name = content.substring(startIndex, content.length);
      FileDownload(response.data, decodeURI(name));
    }
    else {
      // need to handle error
    }
  }

  render(): JSX.Element {
    return (
      <>
        <StatusModal ref={this.statusModal} />
        <Row style={{ marginTop: "2vh" }}>
          <Col>
            <h4>Property License Assignments - {this.state.name}</h4>
          </Col>
          <Col xs='1'>
            <input type="button" className="standard-input" value="Close" onClick={() => this.props.update("")} style={{ float: "right" }} />
          </Col>
        </Row>
        <div style={{ height: "67vh", overflowX: "hidden", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: PropertyLicenseRow, index: number) => (
                  <PropertyLicenseManagementTableRow key={item.uid} row={item} />
                ))
              }
            </tbody>
          </table>
        </div>
        <Row>
          <Col>
            <input type="button" value="Generate ETL For Billing Properties" className="standard-input" onClick={this.generateETL} />
          </Col>
          <Col>

          </Col>
        </Row>
      </>
    )
  }
}
