import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullBanks, pullEntities } from '../../../functions/fetchLinkedObjects';
import { Bank_Account_Types } from '../../CoreComponents/CoreStructs/BankConstants';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { getUserID } from '../../../functions/authActions';
import axios from 'axios';

interface BankAccountWorkflowEntityRequestModalData {
  key: number;
  bank: SelectOptions;
  type: string;
}

interface BankAccountWorkflowEntityRequestModalProps {
  callback: (success: boolean) => void;
}

interface BankAccountWorkflowEntityRequestModalState {
  show: boolean;
  banks: Array<SelectOptions>;
  bank: SelectOptions;
  type: SelectOptions;
  otherType: string;
  headers: Array<FilterAndSettingField>;
  entityName: string;
  entityUID: string;
  entities: Array<SelectOptions>;
  showAdd: boolean;
  rows: Array<BankAccountWorkflowEntityRequestModalData>;
}

export default class BankAccountWorkflowEntityRequestModal extends React.Component<BankAccountWorkflowEntityRequestModalProps, BankAccountWorkflowEntityRequestModalState> {

  constructor(props: BankAccountWorkflowEntityRequestModalProps) {
    super(props);
    this.state = {
      show: false,
      banks: [],
      bank: { label: "Select Option", value: "" },
      type: { label: "Select Type", value: "" },
      otherType: "",
      headers: [],
      entityName: "",
      entityUID: "",
      showAdd: false,
      rows: [],
      entities: []
    }

    this.changeBank = this.changeBank.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeOtherType = this.changeOtherType.bind(this);
    this.changeEntity = this.changeEntity.bind(this);
    this.saveBank = this.saveBank.bind(this);
    this.forceClose = this.forceClose.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let banks = await pullBanks();
    let entities = await pullEntities();
    this.setState({ banks: banks, entities: entities });
    this.generateHeaders();
  }

  forceClose(): void {
    this.setState({
      show: false
    })
  }

  show(entity: SelectOptions): void {
    if (entity === null) {
      this.setState({ show: true, entityName: "Please Select Entity", entityUID: "" })
    }
    else {
      this.setState({
        show: true,
        entityName: entity.label,
        entityUID: entity.value
      })
    }
  }

  generateHeaders(): void {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Bank", setting: true, filter: {}
      },
      {
        columnName: "Type", setting: true, filter: {}
      }
    ];
    this.setState({ headers: headers });
  }

  changeBank(event: SelectOptions | null): void {
    this.setState({ bank: event });
  }

  changeType(event: SelectOptions | null): void {
    this.setState({ type: event });
  }

  changeEntity(event: SelectOptions | null): void {
    this.setState({ entityName: event.label, entityUID: event.value });
  }

  changeOtherType(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ otherType: event.target.value });
  }

  async saveBank(): Promise<void> {
    let rows: Array<BankAccountWorkflowEntityRequestModalData> = this.state.rows;
    rows.push({
      key: Math.random(),
      bank: this.state.bank,
      type: this.state.type.value === '2' ? this.state.otherType : this.state.type.label
    })
    this.setState({
      rows: rows,
      bank: { label: "Select Option", value: "" },
      type: { label: "Select Type", value: "" },
      showAdd: false
    })
  }

  async submitRequest(): Promise<void> {
    const data: any = {
      CreatedBy: getUserID(),
      RequestData: [],
      EntityUID: this.state.entityUID,
    }
    this.state.rows.map((item) => {
      data.RequestData.push({
        Bank: item.bank.value,
        AccountType: item.type
      });
    })
    let response = await axios.post('./api/bank-account-entity-request', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.props.callback(true);
    }
    else {
      this.props.callback(false);
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })} size="md">
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Request Bank Account
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            {
              this.state.entityUID !== "" ?
                <>
                  <Row>
                    <Col xs='4'>
                      <h5 style={{ marginTop: "7px" }}>Entity</h5>
                    </Col>
                    <Col>
                      <h5 style={{ marginTop: "7px", textAlign: "right", width: "100%" }}>{this.state.entityName}</h5>
                    </Col>
                  </Row>
                  <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                  <div>
                    <table className="fixedTable">
                      <thead>
                        <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
                      </thead>
                      <tbody>
                        {
                          this.state.rows.map((item: BankAccountWorkflowEntityRequestModalData) => (
                            <tr key={item.key}>
                              <td className="fixedTableCellFirst">{item.bank.label}</td>
                              <td className="fixedTableCell">{item.type}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                  <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                  {this.state.showAdd ?
                    <>
                      <Row>
                        <Col xs='4'>
                          <h5 style={{ marginTop: "7px" }}>Bank</h5>
                        </Col>
                        <Col>
                          <Select
                            options={this.state.banks}
                            value={this.state.bank}
                            onChange={this.changeBank}
                            styles={reactSelectBasicStyle}
                          />
                        </Col>
                      </Row>
                      <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                      <Row>
                        <Col xs='4'>
                          <h5 style={{ marginTop: "7px" }}>Type</h5>
                        </Col>
                        <Col>
                          <Select
                            options={Bank_Account_Types}
                            value={this.state.type}
                            onChange={this.changeType}
                            styles={reactSelectBasicStyle}
                          />
                        </Col>
                      </Row>
                      <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                      {
                        this.state.type.value === '2' &&
                        <>
                          <Row>
                            <Col xs='4'>
                              <h5 style={{ marginTop: "7px" }}>Account Type</h5>
                            </Col>
                            <Col>
                              <input type="text" value={this.state.otherType} className="standard-input" onChange={this.changeOtherType} />
                            </Col>
                          </Row>
                          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                        </>
                      }
                      <Row>
                        <Col xs='4'>

                        </Col>
                        <Col>
                          <input type="button" value="Save Account" className="standard-input" onClick={this.saveBank} />
                        </Col>
                        <Col xs='4'>

                        </Col>
                      </Row>
                    </>
                    :
                    <Row>
                      <Col xs='4'>

                      </Col>
                      <Col>
                        <input type="button" value="Add Another Account" className="standard-input" onClick={() => this.setState({ showAdd: true })} />
                      </Col>
                      <Col xs='4'>

                      </Col>
                    </Row>
                  }

                  {
                    !this.state.showAdd &&
                    <>
                      <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                      <input type="button" value="Submit Request" className="standard-input" onClick={this.submitRequest} />
                    </>
                  }
                </>
                :
                <>
                  <Select
                    options={this.state.entities}
                    value={{ label: this.state.entityName, value: this.state.entityUID }}
                    styles={reactSelectBasicStyle}
                    onChange={this.changeEntity}
                  />
                </>
            }

          </ModalBody>
        </div>
      </Modal >
    )
  }
}