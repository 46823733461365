import * as React from 'react';
import axios from 'axios';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { Col, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';

export interface NewTicketWidgetProps {

}

export interface NewTicketWidgetState {
  tickets: Array<TicketServerClient>;
  headers: Array<FilterAndSettingField>;
  days: number;
}

interface TicketServerResponse {
  uid: string;
  fullName: string;
  problemSummary: string;
  created: Date;
  category: SelectOptions;
}

interface TicketServerClient {
  uid: string;
  fullName: string;
  problemSummary: string;
  created: Date;
  category: SelectOptions;
}

export default class NewTicketWidget extends React.Component<NewTicketWidgetProps, NewTicketWidgetState> {

  constructor(props: NewTicketWidgetProps) {
    super(props);
    this.state = {
      tickets: [],
      headers: [],
      days: 30
    }
    this.loadWorkLog = this.loadWorkLog.bind(this);
    this.viewAllTickets = this.viewAllTickets.bind(this);
    this.setDays = this.setDays.bind(this);
    this.pullData = this.pullData.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let headers = this.headers();
    this.setState({ headers: headers }, async () => await this.pullData());
  }

  headers(): Array<FilterAndSettingField> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Requestor',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Problem',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Category',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Created',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: "",
        additionalClasses: "ten",
        setting: true,
        filter: {}
      }
    ]
    return headers;
  }

  loadWorkLog(ticketUID: string): void {
    window.open("./ticket-work-manager?uid=" + ticketUID);
  }

  viewAllTickets(): void {
    window.open("./task-management")
  }

  async setDays(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ days: parseInt(event.target.value) }, async () => this.pullData());
  } 

  async pullData(): Promise<void> {
    const response = (await axios.get('./api/tickets/new-tickets/' + this.state.days));
    const tickets: Array<TicketServerClient> = [];

    response.data.forEach((ticket: TicketServerResponse) => {
      tickets.push({ uid: ticket.uid, fullName: ticket.fullName, problemSummary: ticket.problemSummary, created: new Date(ticket.created), category: ticket.category });
    })

    this.setState({ tickets: tickets })
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h5 style={{ fontSize: "18px", width: "100%", textAlign: "left", marginTop: ".2vh" }}>
              {`Tickets From Last ${this.state.days} days`}

            </h5>
          </Col>
          <Col xs='4'>
            <input type="number" value={this.state.days} className="standard-input" style={{ textAlign: "right", height: "3vh" }}
              onChange={this.setDays}
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <div style={{ maxHeight: "24.1vh", minHeight: "24.1vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.tickets.map((ticket) =>
                  <tr key={ticket.uid}>
                    <td className="fixedTableCellFirst">{ticket.fullName}</td>
                    <td className="fixedTableCell">{ticket.problemSummary}</td>
                    <td className="fixedTableCell">{ticket.category.label}</td>
                    <td className="fixedTableCell">{new Date(ticket.created).toLocaleDateString()}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="View" className="standard-input" onClick={() => this.loadWorkLog(ticket.uid)} />
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" className="standard-input" value="View Full List" onClick={this.viewAllTickets} />
      </>
    )
  }
}