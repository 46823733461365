import { SelectOptions } from "../../../interfaces/CoreInterfaces";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";

export interface CNALineItemDisplayProps {
  categoryType: string;
  propType: number;
}

export interface CNALineItemDisplayState {
  categoryType: string;
  cnaUID: string;
  propType: number;
  tab: number;
  lineItems: Array<CNALineItem>
}

export interface CNALineItemSelectOptions extends SelectOptions {
  family: number;
  elderly: number;
}

export interface CNALineItem {
  uid: string;
  item: CNALineItemSelectOptions;
  eul: number;
  age: string;
  rul: string;
  isHealthAndSafetyIssue: SelectOptions;
  condition: SelectOptions;
  action: SelectOptions;
  duration: string;
  qty: string;
  unit: SelectOptions;
  unitCost: string;
  totalCost: string;
  comments: string;
}

export const CNALineCondtions: Array<SelectOptions> = [
  { label: "Excellent", value: "0" },
  { label: "Good", value: "1" },
  { label: "Fair", value: "2" },
  { label: "Poor", value: "3" }
]

export const CNALineActions: Array<SelectOptions> = [
  { label: "Maintain", value: "0" },
  { label: "Repair", value: "1" },
  { label: "Replace", value: "2" },
  { label: "Construct", value: "3" },
  { label: "No Action", value: "4" }
]

export const CNALineQuantityTypes: Array<SelectOptions> = [
  { label: "EA", value: "0" },
  { label: "ONE", value: "1" },
  { label: "UNIT", value: "2" },
  { label: "LF", value: "3" },
  { label: "LS", value: "4" },
  { label: "SF", value: "5" },
  { label: "SQ", value: "6" }
]