import * as React from 'react';
import { DisplayImageModal, SavedFileImageData, generateImageGrid } from '../../../functions/imageTools';
import axios from 'axios';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketPhotoGalleryState {
  uid: string;
  photos: Array<SavedFileImageData>;
}

export default class TicketPhotoGallery extends React.Component<{uid: string}, TicketPhotoGalleryState> {

  imageModal: React.RefObject<DisplayImageModal> = React.createRef<DisplayImageModal>();
  constructor(props: { uid: string }) {
    super(props);
    this.state = {
      uid: props.uid,
      photos: []
    }
    this.showImage = this.showImage.bind(this);
  }

  async componentDidMount(): Promise<void> {

    const photoResponse = await axios.get('./api/tech-ticket-photo-map/' + this.state.uid)
    let photos: Array<SavedFileImageData> = [];
    photoResponse.data.forEach(async (photo: any) => {
      await axios.get('./api/saved-files/image/' + photo.savedFileUID, { responseType: "blob" }).then((data) => {
        const img = URL.createObjectURL(data.data);
        this.state.photos.push({
          img: img,
          imgUID: photo.uid
        })
        this.setState({});
      });
    })
  }

  showImage(src: string): void {
    this.imageModal.current.show(src);
  }

  render(): JSX.Element {
    
    return (
      <div id="photo-container">
        <DisplayImageModal ref={this.imageModal} />
        {RenderHeader("Photo Gallery")}
        {
          generateImageGrid(this.state.photos, 2, this.showImage)
        }
      </div>
    )
  }
}