import axios from "axios"

export async function PatchStringRequest(key: string, value: string, route: string, callback: (success: boolean) => void): Promise<void> {
  let response = await axios.patch(route, { UID: key, Value: value }, { validateStatus: () => true });

  if (callback) {
    callback(response.status === 202);
  }
  
}

export async function PatchNumberRequest(key: string, value: number, route: string, callback: (success: boolean) => void): Promise<void> {
  let response = await axios.patch(route, { UID: key, Value: value }, { validateStatus: () => true });

  if (callback) {
    callback(response.status === 202);
  }
}

export async function PatchBooleanRequest(key: string, value: boolean, route: string, callback: (success: boolean) => void): Promise<void> {
  let response = await axios.patch(route, { UID: key, Value: value }, { validateStatus: () => true });

  if (callback) {
    callback(response.status === 202);
  }
}

export async function PatchStringAuditRequest(key: string, modiferKey: string, value: string, route: string, callback: (success: boolean) => void): Promise<void> {
  let response = await axios.patch(route, { UID: key, ModiferUID: modiferKey, Value: value }, { validateStatus: () => true });

  if (callback) {
    callback(response.status === 202);
  }
}

export async function PatchNumberAuditRequest(key: string, modiferKey: string, value: number, route: string, callback: (success: boolean) => void): Promise<void> {
  let response = await axios.patch(route, { UID: key, ModiferUID: modiferKey, Value: value }, { validateStatus: () => true });

  if(callback) {
    callback(response.status === 202);
  }
}
export async function PatchBooleanAuditRequest(key: string, modiferKey: string, value: boolean, route: string, callback: (success: boolean) => void): Promise<void> {
  let response = await axios.patch(route, { UID: key, ModiferUID: modiferKey, Value: value }, { validateStatus: () => true });

  if (callback) {
    callback(response.status === 202);
  }
}