import * as React from 'react'
import { CNAInspectionReportWidgetProps, CNAInspectionReportWidgetState } from './CNAInspectionReportWidget.constants';
import { Col, Row } from 'reactstrap';
import { DatePicker } from '../../../CoreComponents/DateComponents';
import axios from 'axios';


//This needs to render the Inspection Report. Date of Inspection and the inspector

export default class CNAInspectionReportWidget extends React.Component<CNAInspectionReportWidgetProps, CNAInspectionReportWidgetState>{

  constructor(props: CNAInspectionReportWidgetProps) {
    super(props);
    this.state = {
      date: new Date(),
      name: "",
      cnaUID: props.cnaUID
    }

    this.changeDate = this.changeDate.bind(this);
    this.changeName = this.changeName.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const response = await axios.get('./api/capital-needs-assessment/inspection-data/' + this.props.cnaUID)

    if (response.status === 200) {
      this.setState({
        name: response.data.inspector,
        date: new Date(response.data.inspectionDate)
      })
    }
    else {
      //TODO ERROR HANDLE
    }
  }

  async changeDate(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ date: new Date(event.target.valueAsDate) })

    const data = {
      UID: this.state.cnaUID,
      InspectionDate: event.target.valueAsDate.toISOString()
    }
    await axios.patch('./api/capital-needs-assessment/inspector-date-patch', data)

  }

  async changeName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ name: event.target.value })

    const data = {
      UID: this.state.cnaUID,
      Inspector: event.target.value
    }
    await axios.patch('./api/capital-needs-assessment/inspector-name-patch', data)

  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h4>Inspection Report</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Date</h5>
          </Col>
          <Col>
            <DatePicker className="standard-input" onChange={this.changeDate} value={this.state.date} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Inspector</h5>
          </Col>
          <Col>
            <input type="text" className="standard-input" onChange={this.changeName} value={this.state.name} />
          </Col>
        </Row>
      </>
    )
  }
}