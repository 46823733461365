import axios from 'axios';
import * as React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export class DocumentViewModal extends React.Component<{}, { uid: string, showing: boolean, path: string }> {

  constructor(props: {}) {
    super(props);
    this.state = {
      uid: "",
      showing: false,
      path: ""
    }
    this.show = this.show.bind(this);
  }

  async show(uid: string): Promise<void>  {
    let response = await axios.get('./api/saved-files/' + uid + "/pdf", { responseType: 'blob' });
    this.setState({
      uid: uid,
      showing: true,
      path: URL.createObjectURL(response.data)
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ backgroundColor: "#ccc"}} fullscreen={true} toggle={() => this.setState({ showing: false })}>
        <ModalHeader style={{ height: "1vh", backgroundColor: "#ccc" }} toggle={() => this.setState({ showing: false}) }>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#ccc"} }>
          <embed src={this.state.path} style={{ height: "92vh", minWidth: "100%" }} />
        </ModalBody>
      </Modal>
    )
  }
}