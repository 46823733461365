import * as React from 'react';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { pullProperties, pullPropertiesForRegionals } from '../../../functions/fetchLinkedObjects';
import { PropertyAllocationsWidget } from './PropertyAllocationsWidget';

interface PropertyAllocationSelectionWidgetProps {
  useRegional: boolean;
}

interface PropertyAllocationSelectionWidgetState {
  property: SelectOptions;
  properties: Array<SelectOptions>;
}

export class PropertyAllocationSelectionWidget extends React.Component<PropertyAllocationSelectionWidgetProps, PropertyAllocationSelectionWidgetState> {

  constructor(props: PropertyAllocationSelectionWidgetProps) {
    super(props);
    this.state = {
      properties: [],
      property: { label: "Select Property", value: "" }
    }
    this.changeProperty = this.changeProperty.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = this.props.useRegional ? await pullPropertiesForRegionals() : await pullProperties();
    this.setState({
      properties: properties
    })
  }

  changeProperty(event: SelectOptions | null) {
    if (event === null) { return; }
    this.setState({ property: event });
  }

  render(): JSX.Element {
    return (
      <>
        {this.state.property.value !== "" ? <PropertyAllocationsWidget key={this.state.property.value} uid={this.state.property.value} />
          : <h4 style={{ width: "100%", textAlign: "center"}}>Payroll Allocations</h4>
      }
        <Select
          options={this.state.properties}
          styles={reactSelectBasicStyle}
          value={this.state.property}
          onChange={this.changeProperty}
          menuPlacement="auto"
        />
      </>
    )
  }
}