import { SelectOptions } from "../interfaces/CoreInterfaces"

export const RelatedPortfolios: Array<string> = ['AB Chase', 'IPA AM', 'CDI', 'Fleet', 'Viridian Consulting', "Viridian Management"]
export const RelatedPortfolios_Select: Array<SelectOptions> = [
  { label: "AB Chase", value: "0" },
  { label: "IPA AM", value: "1" },
  { label: "CDI", value: "2" },
  { label: "Fleet", value: "3" },
  { label: "Viridian Consulting", value: "4" },
  { label: "Viridian Management", value: "5" },
]

export const RelatedPortfolios_FilterSelect: Array<SelectOptions> = [
  { label: "ALL", value: "-1" },
  { label: "AB Chase", value: "0" },
  { label: "IPA AM", value: "1" },
  { label: "CDI", value: "2" },
  { label: "Fleet", value: "3" },
  { label: "Viridian Consulting", value: "4" },
  { label: "Viridian Management", value: "5" },
]

export const EmployeeCompany: any = {
  CDI: 0,
  VMI: 1,
  MCI: 2,
  FLEET: 3,
  ABC: 4,
  VCON: 5,
  VCL: 6,
  IPA: 7
}

export const CompanyNames: Array<string> = ['Chrisman Development', 'Viridian Management', 'MCI', 'Fleet', 'ABChase', 'Viridian Consulting', 'Viridian Construction', 'IPA'];

export const CompanyNames_Select: Array<SelectOptions> =
  [
    { label: 'All', value: "-1" },
    { label: 'Chrisman Development', value: "0" },
    { label: 'Viridian Management', value: "1" },
    //{ label: 'MCI', value: "2" },
    { label: 'Fleet', value: "3" },
    { label: 'ABChase', value: "4" },
    { label: 'Viridian Consulting', value: "5" },
    //{ label: 'Viridian Construction', value: "6" },
    { label: 'IPA', value: "7" },
  ];

export const ContactTypes: Array<SelectOptions> = [
  { label: "Misc", value: "0" },
  { label: "Owner", value: "1" },
  { label: "Tax Investor", value: "2" }
]


export const EmployeeAuthorizationTypes: Array<SelectOptions> = [
  { label: "Admin", value: "0" },
  { label: "User", value: "1" }
]

export const NewEmployeeRoles: Array<SelectOptions> = [
  { label: "CFO", value: "0" },
  { label: "COO", value: "1" },
  { label: "President", value: "2" },
  { label: "HR", value: "3" },
  { label: "Recruiter", value: "4" },
  { label: "Accountant", value: "5" },
  { label: "Supervisor", value: "6" },
  { label: "Instructor", value: "7" },
  { label: "Administrator", value: "8" },
  { label: "Solar", value: "9" },
  { label: "Legal Analyst", value: "10" },
  { label: "Project Analyst", value: "11" },
  { label: "Technology", value: "12" },
  { label: "Marketing", value: "13" },
  { label: "Finance", value: "14" },
  { label: "Project Manager", value: "15" },
  { label: "Compliance", value: "16" },
  { label: "Regional", value: "17" },
  { label: "Administrative", value: "18" },
  { label: "Controller", value: "19" },
  { label: "Project Coordinator", value: "20" },
  { label: "Foreman", value: "21" },
  { label: "VP Operations", value: "22" },
  { label: "Technology Lead", value: "23" },
  { label: "Site Manager", value: "24" },
  { label: "Maintenance Tech", value: "25" },
  { label: "Crew Member (Construction, Obsolete)", value: "26" },
  { label: "Head of Maintenance", value: "27"}
]

export const NewEmployeeRolesExecutive: Array<SelectOptions> = [
  { label: "CFO", value: "0" },
  { label: "COO", value: "1" },
  { label: "President", value: "2" }
]

export const NewEmployeeRolesHumanResources: Array<SelectOptions> = [
  { label: "HR", value: "3" },
  { label: "Recruiter", value: "4" }
]

export const NewEmployeeRolesConstruction: Array<SelectOptions> = [
  { label: "President", value: "2" },
  { label: "Accountant", value: "5" },
  { label: "Supervisor", value: "6" },
  { label: "Coordinator", value: "20" },
  { label: "Foreman", value: "21" }
]

export const NewEmployeeRolesMCI: Array<SelectOptions> = [
  { label: "President", value: "2" },
  { label: "Instructor", value: "7" },
  { label: "Administrator", value: "8" }
]

export const NewEmployeeRolesFleet: Array<SelectOptions> = [
  { label: "President", value: "2" },
  { label: "Solar", value: "9" }
]

export const NewEmployeeRolesAssetManagement: Array<SelectOptions> = [
  { label: "Legal Analyst", value: "10" },
  { label: "Project Analyst", value: "11" }
]

export const NewEmployeeRolesServices: Array<SelectOptions> = [
  { label: "Technology", value: "12" },
  { label: "Marketing", value: "13" },
  { label: "Technology Lead", value: "23" }
]

export const NewEmployeeRolesDevelopment: Array<SelectOptions> = [
  { label: "Accountant", value: "5" },
  { label: "Finance", value: "14" },
  { label: "Project Manager", value: "15" },
  { label: "Administrative", value: "18" }
]

export const NewEmployeeRolesPropertyManagement: Array<SelectOptions> = [
  { label: "Accountant", value: "5" },
  { label: "Compliance", value: "16" },
  { label: "Regional", value: "17" },
  { label: "Vice President", value: "22" },
  { label: "Site Manager", value: "24" },
  { label: "Maintenance Tech", value: "25" }
]

export const CompanyTeams: Array<SelectOptions> = [
  { label: "Executive", value: "0" },
  { label: "Human Resources", value: "1" },
  { label: "Construction", value: "2" },
  { label: "MCI", value: "3" },
  { label: "Fleet", value: "4" },
  { label: "Asset Management", value: "5" },
  { label: "Services", value: "6" },
  { label: "Development", value: "7" },
  { label: "Property Management", value: "8" },
]

export const ExternalCompanyTypes: Array<SelectOptions> = [
  { label: "Select Type", value: "" },
  { label: "Management", value: "0" },
  { label: "Solar Contractor", value: "1" },
  { label: "General Contractor", value: "2" },
  { label: "ABChase Subcontractor", value: "3" },
  { label: "IPA AM Property Manager", value: "4" },
  { label: "Power Company", value: "6" },
  { label: "Miscellanenous", value: "5" }
]

export const ExternalCompanyTypes_List: Array<string> = [
  "Management",
  "Solar Contractor",
  "General Contractor",
  "ABChase Subcontractor",
  "IPA AM Property Manager",
  "",
  "Power Company"
]

export const CompanyBillingType: Array<SelectOptions> = [
  { label: "Select", value: "" },
  { label: "Both", value: "0" },
  { label: "Customer", value: "1" },
  { label: "Vendor", value: "2" },
  { label: "None", value: "3" }
]

export const EnergyEfficiencyEquipmentList: Array<SelectOptions> = [
  { label: "Select Type", value: "" },
  { label: "Dryer", value: "0" },
  { label: "Water Heater", value: "1" },
  { label: "HVAC", value: "2" },
  { label: "Lighting", value: "3" },
  { label: "Insulation", value: "4" },
  { label: "Windows", value: "5" },
  { label: "Bath Fans", value: "6" },
  { label: "Refrigerator", value: "7" },
  { label: "Dishwasher", value: "8" },
  { label: "Doors", value: "9" },
  { label: "Air Sealing", value: "10" },
  { label: "ERC / HRV", value: "11" },
]


export const YesNoAll: Array<SelectOptions> = [
  { label: "All", value: "0" },
  { label: "No", value: "1" },
  { label: "Yes", value: "2" },
]
export const YesNo: Array<SelectOptions> = [
  { label: "No", value: "0" },
  { label: "Yes", value: "1" },
]


//#region Property Enum Constants
export const PropertyTypes: Array<SelectOptions> = [
  { label: "Family", value: "0" },
  { label: "Senior & Disabled", value: "1" },
  { label: "Farm Labor", value: "2" },
]

export const PropertyFundingTypes: Array<SelectOptions> = [
  { label: "HUD", value: "0" },
  { label: "RD", value: "1" },
  { label: "LIHTC", value: "2" },
  { label: "HOME", value: "3" },
  { label: "LIFT", value: "4" }
]

export const AllocationRolesSelect : Array<SelectOptions> = [
  { label: "N/A", value: "-1" },
  { value: "0", label: "Maintenance" },
  { value: "1", label: "Assistant SM" },
  { value: "2", label: "Site Manager" },
  { value: "3", label: "Regional Manager" },
  { value: "4", label: "Food Services" },
  { value: "5", label: "Accounts Payable" },
  { value: "6", label: "Accounts Receivable" },
  { value: "7", label: "Compliance Specialist"}
]

export const AllocationRoles: Array<string> = [
  "Maintenance",
  "Assistant SM",
  "Site Manager",
  "Regional Manager",
  "Food Services",
  "Accounts Payable",
  "Accounts Receivable",
  "Compliance Specialist"
]


export const PropertyTypes_List: Array<string> = ["Family", "Senior & Disabled", "Farm Labor"]
//#endregion




