import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { pullBanks } from '../../../functions/fetchLinkedObjects';
import axios from 'axios';
import { Bank_Account_Types } from '../../CoreComponents/CoreStructs/BankConstants';

interface EntityBankAccountManagementModalProps {
  uid: string;
}

interface EntityBankAccountManagementModalState {
  show: boolean;
  banks: Array<SelectOptions>;
  bank: SelectOptions;
  type: SelectOptions;
  otherType: string;
  accountNumber: string;
}

export default class EntityBankAccountManagementModal extends React.Component<EntityBankAccountManagementModalProps, EntityBankAccountManagementModalState> {

  TYPES = [
    { label: "Operating", value: "0" },
    { label: "Reserve", value: "1" },
    { label: "Other", value: "2" }
  ]

  constructor(props: EntityBankAccountManagementModalProps) {
    super(props);
    this.state = {
      show: false,
      banks: [],
      bank: { label: "Select Option", value: "" },
      type: { label: "Select Option", value: "" },
      otherType: "",
      accountNumber: ""
    }
    this.show = this.show.bind(this);
    this.changeBank = this.changeBank.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeOtherType = this.changeOtherType.bind(this);
    this.changeAccountNumber = this.changeAccountNumber.bind(this);
    this.save = this.save.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let banks = await pullBanks();
    this.setState({ banks: banks });
  }

  forceClose(): void {
    this.setState({
      show: false
    })
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  changeBank(event: SelectOptions | null): void {
    this.setState({ bank: event });
  }

  changeType(event: SelectOptions | null): void {
    this.setState({ type: event });
  }

  changeOtherType(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ otherType: event.target.value });
  }

  changeAccountNumber(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ accountNumber: event.target.value });
  }

  async save(): Promise<void> {
    const data = {
      bankUID: this.state.bank.value,
      entityUID: this.props.uid,
      accountType: this.state.type.value === '2' ? this.state.otherType : this.state.type.label,
      bankAccountNumber: this.state.accountNumber
    };
    let response = await axios.post('./api/bank-account-entity/', data, { validateStatus: () => true });
    if (response.status === 200) {
      window.location.reload();
    }
    else {
      // TODO: Handle Error Handling
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Manage Bank Account
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Bank</h5>
              </Col>
              <Col>
                <Select
                  options={this.state.banks}
                  value={this.state.bank}
                  onChange={this.changeBank}
                  styles={reactSelectBasicStyle}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Type</h5>
              </Col>
              <Col>
                <Select
                  options={Bank_Account_Types}
                  value={this.state.type}
                  onChange={this.changeType}
                  styles={reactSelectBasicStyle}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            {this.state.type.value === '2' &&
              <>
                <Row>
                  <Col xs='4'>
                    <h5 style={{ marginTop: "7px" }}>Account Type</h5>
                  </Col>
                  <Col>
                    <input type="text" value={this.state.otherType} className="standard-input" onChange={this.changeOtherType} />
                  </Col>
                </Row>
                <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              </>
            }
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Account #</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.accountNumber} className="standard-input" onChange={this.changeAccountNumber} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Save Bank Account" className="standard-input" onClick={this.save} />
          </ModalBody>
        </div>
      </Modal >
    )
  }
}