import { AuthState } from "../../../functions/authActions";
import { SavedFileImageData } from "../../../functions/imageTools";
import { SelectOptions, SelectOptionsProperty } from "../../../interfaces/CoreInterfaces";
import { TicketSubmissionSettings } from "../../../reducers/TicketTableSettings";

export interface TicketSubmissionPageProps {
  ticketSubmissionState: TicketSubmissionSettings;
  changeValues: (state: TicketSubmissionSettings) => void;
}

export interface TicketSubmissionPageState {
  ticketSubmissionState: TicketSubmissionSettings;
  failedSubmit: boolean;
  failedSubmitText: string;
  currentTab: number;
  submitted: boolean;
  properties: Array<SelectOptions>;
  propertySelect: SelectOptions;
  emailReady: boolean;
  phoneReady: boolean;
  problemSummary: string;
  img: string;
  imgUID: string;
  loading: boolean;
  submitDisabled: boolean;
  equipmentType: SelectOptions;
  regionals: Array<SelectOptions>;
  regional: SelectOptions;
  photos: Array<SavedFileImageData>;
  photoUIDs: Array<string>;
  supers: Array<SelectOptions>;
  super: SelectOptions;
}


export interface TicketSubmissionPageAuthState {
  ticketSubmissionState: TicketSubmissionSettings;
  failedSubmit: boolean;
  failedSubmitText: string;
  currentTab: number;
  submitted: boolean;
  properties: Array<SelectOptionsProperty>;
  propertySelect: SelectOptionsProperty;
  problemSummary: string;
  loading: boolean;
  submitDisabled: boolean;
  photos: Array<SavedFileImageData>;
  photoUIDs: Array<string>;
  auth: AuthState;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface TicketSubmissionProps {
  ticketSubmissionState: TicketSubmissionSettings;
  changeValues: (state: any) => void;
}

export interface TicketSubmissionState {
  problemSummary: string;
  submitted: boolean;
  phoneReady: boolean;
  emailReady: boolean;
  failedSubmit: boolean;
  failedSubmitText: string;
  ticketSubmissionState: TicketSubmissionSettings;
  img: string;
  imgUID: string;
  submitDisabled: boolean;
}

export interface ServerTicketWorkLogHistory {
  StartTime: Date;
  EndTime: Date;
  TicketLogHistory: string;
  TicketUID: string;
}

export function DarkLightMode(): any {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return ticketSelectMenuDark
  }
  else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
    return ticketSelectMenuLight
  }

}

const ticketSelectMenuDark = {
  menu: (styles: any) => ({
    ...styles,
    background: "#647C90",
  }),
  control: (base: any) => ({
    ...base,
    background: "#647C90",
    color: "#E2DED0",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#647C90",
      color: "#E2DED0",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#E2DED0"
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#647C90",
      color: "#E2DED0"
    }
  }
}

const ticketSelectMenuLight = {
  menu: (styles: any) => ({
    ...styles,
    background: "#fffefc",
  }),
  control: (base: any) => ({
    ...base,
    background: "#fffefc",
    color: "#4E4F50",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#fffefc",
      color: "#4E4F50",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#4E4F50"
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#fffefc",
      color: "#4E4F50"
    }
  }
}

export const EquipmentTypeList: Array<SelectOptions> = [
  { value: "0", label: "Computer" },
  { value: "1", label: "Phone" },
  { value: "2", label: "Security/Wifi" },
  { value: "3", label: "Other" }
]