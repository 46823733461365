import { SelectOptions } from "../../../interfaces/CoreInterfaces";
import { EntityData } from "../Structs";
/**
 * Related Portfolio's enum. This may become core at some point
 */
export const OwnershipTypeMap: Array<string> = ["", "Annual Profit & Loss", "Capital", "Both"];

export const OwnershipTypes: Array<SelectOptions> = [
  { label: "N/A", value: '0' },
  { label: "Annual Profit & Loss", value: '1' },
  { label: "Capital", value: '2' },
  { label: "Both", value: '3' }
]

/**
 * Get Empty Entity values, this is extremely useful for Entity Table code size reduction 
 */
export function getEmptyEntity(): EntityData {
  return {
    uid: "",
    name: "",
    uniqueCode: "",
    isIndividual: false,
    jurisdiction: { label: "Alabama", value: "0" },
    firm: { label: "N/A", value: "" },
    type: { label: "Limited Liability Company", value: "1" },
    fein: "",
    duns: "",
    uei: "",
    cage: "",
    cageExpiration: new Date(),
    sosRenew: new Date(),
    sCorpElection: { label: "N/A", value: "0" },
    status: { label: "Active", value: "1" },
    formationDate: new Date(),
    accountant: { label: "Select Accountant", value: "" },
    portfolio: { label: "Select Manager", value: '' },
    yardiAccount: { label: "VMI", value: "0" },
    isFirstYearReturn: { label: "No", value: "0" },
    isFinalYearReturn: { label: "No", value: "0" },
    notes: "",
    agent: { label: "Select Agent", value: "" },
    authorizedSigners: [],
    ownership: "",
    managingMember: "",
    address: {
      address: "",
      city: "",
      state: { label: "Select State", value: "-1" },
      zipcode: ""
    },
    streetAddress: ""
  }
}