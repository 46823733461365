import * as React from "react";
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldDate, DataEntryModalFieldHidden, DataEntryModalFieldStatic, DataEntryModalFieldText } from "../../CoreComponents/DataEntryModal";
import { PropertyBuildingModalData, PropertyBuildingWidgetModalProps, PropertyBuildingWidgetModalState } from "./PropertyBuildingWidget.modals.constants";
import { pullPropertyName } from "../../../functions/fetchObjectNameFunctions";

export default class PropertyBuildingWidgetModal extends React.Component<PropertyBuildingWidgetModalProps, PropertyBuildingWidgetModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: PropertyBuildingWidgetModalProps) {
    super(props);
    this.state = {
      data: {
        uid: "",
        propertyUID: "",
        propertyName: "",
        name: "",
        roof: new Date(),
        lastRehab: new Date(),
        unitCount: 0
      }
    }
  }

  async show(propertyUID: string, data: PropertyBuildingModalData = null): Promise<void> {
    let propertyName = await pullPropertyName(propertyUID);
    if (data === null) {
      data = this.state.data;
      data.propertyName = propertyName;
      data.propertyUID = propertyUID;
      this.setState({ data: data }, async () => await this.generateData());
    }
    else {
      data.propertyName = propertyName;
      this.setState({ data: data }, async () => await this.generateData());
    }

  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "property", name: "Property", value: this.state.data.propertyName, static: true } as DataEntryModalFieldStatic);
    data.push({ key: "propertyUID", name: "propertyUID", value: this.state.data.propertyUID, hidden: true } as DataEntryModalFieldHidden);
    data.push({ key: "name", name: "Name", patchUrl: './api/buildings/name', value: this.state.data.name, placeholder: "A", type: "text" } as DataEntryModalFieldText);
    data.push({ key: "unitCount", name: "Unit Count", patchUrl: './api/buildings/unit-count', value: this.state.data.unitCount, placeholder: "10", type: 'text' } as DataEntryModalFieldText);
    data.push({ key: "roofReplacedLast", name: "Last Roof Work", patchUrl: './api/buildings/last-roof-replacement', value: this.state.data.roof } as DataEntryModalFieldDate);
    data.push({ key: "lastRehab", name: "Last Rehab", patchUrl: './api/buildings/last-rehab', value: this.state.data.lastRehab } as DataEntryModalFieldDate);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Manage Building", this.state.data.uid, './api/buildings');
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} />
      </>
    )
  }
}