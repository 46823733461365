import axios from 'axios';
import * as React from 'react';
import { getUserID } from '../../../functions/authActions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import * as Structs from '../Structs';
import { pullEmployeeName, pullEmployeeUseOldConstruction } from '../../../functions/fetchObjectNameFunctions';

class ActiveJobRow extends React.Component<Structs.ActiveJobRowProps, Structs.ActiveJobRowState> {

  constructor(props: Structs.ActiveJobRowProps) {
    super(props);
    this.state = {
      data: props.data,
      href: ""
    }
  }

  async componentDidMount(): Promise<void> {
    let useOld = await pullEmployeeUseOldConstruction(await getUserID());
    let href = useOld ? '/active-job-management/old-system?uid=' : '/active-job-management/dashboard?uid=';
    this.setState({ href: href });
  }

  render(): JSX.Element {
    return (
      <>
        <tr>
          <td className="fixedTableCellFirst">
            <a href={this.state.href + this.props.data.jobUID}
              style={{ backgroundColor: "#15405c" }}
              target="_blank">
              {this.props.data.jobName}
            </a>
          </td>
          <td className="fixedTableCell">
            {this.props.data.entity.label}
          </td>
          <td className="fixedTableCell">
            {this.props.data.contractor.label}
          </td>
          <td className="fixedTableCell">
            {this.props.data.contractDate.toLocaleDateString()}
          </td>
          <td className="fixedTableCell" style={{ textAlign: "center" }}>
            {this.props.data.projectCount}
          </td>
        </tr>
      </>
    )
  }
}

export default class ActiveJobManagementTable extends React.Component<Structs.ActiveJobManagementProps, Structs.ActiveJobManagementState> {

  constructor(props: Structs.ActiveJobManagementProps) {
    super(props);
    this.state = {
      userName: '',
      rows: [],
      owner: "",
      generalContractor: "",
      headers: []
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.updateJobs = this.updateJobs.bind(this);
    this.updateOwner = this.updateOwner.bind(this);
    this.updateContractor = this.updateContractor.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let name = await pullEmployeeName(getUserID());
    this.setState({ userName: name });
    this.generateHeaders();
  }

  async updateJobs(): Promise<void> {
    const data = {
      Owner: this.state.owner,
      Contractor: this.state.generalContractor,
      Page: 0,
      PageCount: 25
    };
    let response = await axios.post('/api/managed-jobs/filter', data);
    if (response.status === 200) {
      const rows: Array<Structs.ActiveJobTableData> = [];
      response.data.forEach(function (job: any) {
        rows.push({
          jobUID: job.uid,
          jobName: job.name,
          entity: { label: job.entityName, value: job.entityUID },
          contractor: { label: job.contractorName, value: job.contractorUID },
          contractDate: new Date(job.contractDate),
          projectCount: job.projectCount
        })
      })
      this.setState({
        rows: rows
      })
    }
  }

  generateHeaders(): void {
    const headers = [
      {
        columnName: "Job Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Owner Entity",
        setting: true,
        filter: {
          type: "text",
          value: this.state.owner,
          name: "owner",
          changeFilter: this.updateOwner,
          placeHolder: "Filter by Owner",
        }
      },
      {
        columnName: "General Contractor",
        setting: true,
        filter: {
          type: "text",
          value: this.state.generalContractor,
          name: "generalContractor",
          changeFilter: this.updateContractor,
          placeHolder: "Filter by GC"
        }
      },
      {
        columnName: "Contract Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "# of Sites",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      }
    ]
    this.setState({
      headers: headers
    }, this.updateJobs);
  }

  updateOwner(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      owner: event.target.value
    }, this.generateHeaders);
  }

  updateContractor(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      generalContractor: event.target.value
    }, this.generateHeaders);
  }

  render(): JSX.Element {
    return (
      <div style={{ minHeight: "88vh", maxHeight: "88vh", overflow: "scroll" }}>
        <h3>Active Jobs</h3>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.rows.map((item, idx) => (
                <ActiveJobRow key={item.jobName + idx} data={item} />
              ))
            }
            <TableAdditionRow length={5} marginLeft="43%" onClick={() => window.location.replace("/create-new-job")} />
          </tbody>
        </table>
      </div>
    )
  }
}