import axios from 'axios';
import * as React from 'react';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { pullComputerTracking } from '../../functions/fetchLinkedObjects';
import { ComputerAccessoryTrackingSummary } from './ComputerAccessoryTrackingManagementWidget';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldDate, DataEntryModalFieldSelect, DataEntryModalFieldText } from '../CoreComponents/DataEntryModal';

export const AccessoryTypeSelect: Array<SelectOptions> = [
  { label: "All", value: "-1"},
  { label: "Monitor", value: "0" },
  { label: "Keyboard / Mouse", value: "1" },
  { label: "Printer", value: "2" },
  { label: "Headphones", value: "3" },
  { label: "Battery Backup", value: "4" },
  { label: "Webcam", value: "5" },
  { label: "Other", value: "6"}
]


interface ComputerAccessoryTrackingModalProps {
  callback: (edit: boolean) => void;
}

interface ComputerAccessoryTrackingModalState {
  show: boolean;
  uid: string;
  computer: SelectOptions;
  computers: Array<SelectOptions>;
  description: string;
  brand: string;
  type: SelectOptions;
  installDate: Date;
  cost: number;
  patch: boolean;
}

export default class ComputerAccessoryTrackingModal extends React.Component<ComputerAccessoryTrackingModalProps, ComputerAccessoryTrackingModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: ComputerAccessoryTrackingModalProps) {
    super(props);
    this.state = {
      show: false,
      uid: "",
      computer: { label: "Select Computer", value: "" },
      computers: [],
      description: "",
      brand: "",
      type: { label: "Select Type", value: "" },
      installDate: new Date(),
      cost: 0.0,
      patch: false
    }

    this.forceClose = this.forceClose.bind(this);
    this.callback = this.callback.bind(this);
    this.hide = this.hide.bind(this);
    this.generateData = this.generateData.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let computers = await pullComputerTracking();
    this.setState({ computers: computers });
  }
  
  show(edit: ComputerAccessoryTrackingSummary = null): void {
    if (edit !== null) {
      this.setState({
        show: true,
        patch: true,
        uid: edit.uid,
        computer: edit.computer,
        description: edit.description,
        brand: edit.brand,
        type: edit.type,
        installDate: edit.installDate,
        cost: edit.cost
      }, () => this.generateData());
    }
    else {
      this.setState({
        show: true,
        patch: false
      }, () => this.generateData());
    }
  }

  hide(): void {
    this.setState({
      show: false
    })
  }

  forceClose(): void {
    this.setState({ show: false });
  }

  generateData(): void {
    let data: Array<DataEntryModalField> = [];
    let text: DataEntryModalFieldText = {
      key: "description", name: "Description", patchUrl: './api/computer-accessory-tracking/description',
      type: "text", placeholder: "Add Description", value: this.state.description
    };
    data.push(text);
    let select: DataEntryModalFieldSelect = {
      key: "computerTrackingUID", name: "Computer", patchUrl: './api/computer-accessory-tracking/computer',
      isMulti: false, value: this.state.computer, options: this.state.computers
    }
    data.push(select);
    text = {
      key: "brand", name: "Brand", patchUrl: './api/computer-accessory-tracking/brand',
      type: "text", placeholder: "Brand", value: this.state.brand
    }
    data.push(text);
    select = {
      key: "accessoryType", name: "Accessory Type", patchUrl: './api/computer-accessory-tracking/type',
      options: AccessoryTypeSelect, value: this.state.type, isMulti: false
    }
    data.push(select);
    let date: DataEntryModalFieldDate = {
      key: "installationDate", name: "Install Date", patchUrl: './api/computer-accessory-tracking/install-date',
      value: this.state.installDate
    }
    data.push(date);
    text = {
      key: "accessoryCost", name: "Cost", patchUrl: './api/computer-accessory-tracking/cost',
      type: "text", placeholder: "Cost", value: this.state.cost
    }
    data.push(text);
    this.entryModal.current.show("Computer Accessory Management", this.state.uid, "./api/computer-accessory-tracking");
    this.entryModal.current.update(data);
  }

  async callback(success: boolean): Promise<void> {
    this.props.callback(success);
  }

  render(): JSX.Element {
    return (
      <DataEntryModal ref={this.entryModal} callback={this.callback} />
    )
  }
}
