import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { Jobs, Teams, ValidateAccess } from '../../../functions/authActions';
import axios from 'axios';
import { DownloadNamedFile } from '../../../functions/documentTools';

interface BankAccountReviewRows {
  uid: string;
  bank: string;
  type: string;
  number: string;
  hasDocuments: boolean;
}

interface BankAccountReviewRequestModalState {
  uid: string;
  entityName: string;
  headers: Array<FilterAndSettingField>;
  rows: Array<BankAccountReviewRows>;
  show: boolean;
  uploadDocuments: boolean;
  currentAccount: string;
  currentIdx: number;
  currentFiles: FileList;
}

export default class BankAccountReviewRequestModal extends React.Component<{}, BankAccountReviewRequestModalState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      uid: "",
      entityName: "",
      headers: [],
      rows: [],
      show: false,
      uploadDocuments: false,
      currentAccount: "",
      currentIdx: -1,
      currentFiles: null
    }
    this.forceClose = this.forceClose.bind(this);
    this.uploadDocuments = this.uploadDocuments.bind(this);
    this.saveUploadedDocuments = this.saveUploadedDocuments.bind(this);
    this.completeRequest = this.completeRequest.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async show(uid: string): Promise<void> {
    let response = await axios.get('./api/bank-account-entity-request/' + uid);
    if (response.status !== 200) {

    }
    else {
      this.setState({
        uid: response.data.uid,
        rows: response.data.rows,
        entityName: response.data.entityName,
        show: true
      })
    }
  }

  forceClose(): void {
    this.setState({
      show: false
    })
  }

  async generateHeaders(): Promise<void> {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Bank", setting: true, filter: {}
      },
      {
        columnName: "Type", setting: true, filter: {}
      },
      {
        columnName: "Number", setting: true, filter: {}
      },
      {
        columnName: "Documents", setting: true, filter: {}
      }
    ];
    this.setState({ headers: headers });
  }

  async updateBankAccountNumber(event: React.ChangeEvent<HTMLInputElement>, key: string): Promise<void> {
    let rows = this.state.rows;
    let row = this.state.rows.find(u => u.uid == key);
    let idx = rows.findIndex(u => u.uid == key);
    row.number = event.target.value;
    rows[idx] = row;
    this.setState({ rows: rows });
    const data = { uid: row.uid, value: row.number };
    let response = await axios.patch('./api/bank-account-entity-request-information/account-number', data, { validateStatus: () => true });
    if (response.status !== 202) { console.error("Unable to Patch Bank Account Number"); }
  }

  uploadDocuments(event: React.ChangeEvent<HTMLInputElement>): void {
    var files = event.target.files;
    this.setState({ currentFiles: files});
  }

  async saveUploadedDocuments(): Promise<void> {
    for (var item in this.state.currentFiles) {
      if (item === 'length' || item == 'item') { continue; }
      let data = new FormData();
      data.append('file', this.state.currentFiles[item]);
      data.append('uid', this.state.currentAccount);
      let response = await axios.patch('./api/bank-account-entity-request-information/add-file', data, { responseType: 'blob', validateStatus: () => true});
      if (response.status !== 202)
      {
        return;
      }
    }
    const data = { uid: this.state.currentAccount, value: true };
    let response = await axios.patch('./api/bank-account-entity-request-information/set-has-documents', data);
    if (response.status !== 202) {
      // TODO: Throw Error
    }
    else {
      this.setState({ uploadDocuments: false })
    }
    let row = this.state.rows[this.state.currentIdx];
    row.hasDocuments = true;
    this.state.rows[this.state.currentIdx] = row;
    this.setState({});
  }

  async downloadDocuments(uid: string): Promise<void> {
    let response = await axios.get('./api/bank-account-entity-request-information/documents/' + uid, { responseType: 'blob'});
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
    else {
      // TODO: Throw Error
    }
  }

  async completeRequest(): Promise<void> {
    let response = await axios.patch('./api/bank-account-entity-request/complete/' + this.state.uid);
    if (response.status === 202) {
      window.location.reload();
    }
    else {
      // TODO: Throw Error
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })} size="md">
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Request Bank Account
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Entity</h5>
              </Col>
              <Col>
                <h5 style={{ marginTop: "7px", textAlign: "right", width: "100%" }}>{this.state.entityName}</h5>
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <div>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
                </thead>
                <tbody>
                  {
                    this.state.rows.map((item: BankAccountReviewRows, idx: number) => (
                      <tr key={item.uid}>
                        <td className="fixedTableCellFirst">{item.bank}</td>
                        <td className="fixedTableCell">{item.type}</td>
                        <td className="fixedTableCell">{
                          ValidateAccess([Jobs.CFO, Jobs.Accountant, Jobs.Technology_Lead], [Teams.Services, Teams.Executive]) &&
                          <input type="string" value={item.number} className="standard-input" onChange={(e) => this.updateBankAccountNumber(e, item.uid)} />
                        }</td>
                        <td className="fixedTableCell">
                          {item.hasDocuments
                            ? <input type="button" value="Download" className="standard-input" onClick={(e) => this.downloadDocuments(item.uid)} />
                            : <input type="button" value="Upload" className="standard-input" onClick={() => this.setState({ uploadDocuments: true, currentAccount: item.uid, currentIdx: idx })} />
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            {
              this.state.uploadDocuments &&
              <>
                <input type="file" onChange={this.uploadDocuments} accept=".docx, .doc, .pdf" className="standard-input"
                  style={{ border: "solid", height: "20vh", marginLeft: "3%", paddingLeft: "7vw", paddingTop: "7vh", width: "94%", marginBottom: "5px" }}
                  multiple />
                <Row>
                  <Col>
                    <input type="button" value="Save Documents" className="standard-input" onClick={this.saveUploadedDocuments}
                      style={{ width: "94%", marginLeft: "3%" }}
                    />
                  </Col>
                </Row>
                <hr style={{ height: "3px", padding: "0px", marginBottom: "8px" }} />
              </>
            }

          <Row>
            <Col>
              <input type="button" value="Complete Request" className="standard-input" onClick={this.completeRequest} />
            </Col>
            <Col></Col>
            <Col>
              <input type="button" value="Close" className="standard-input" onClick={this.forceClose} />
            </Col>
          </Row>
        </ModalBody>
      </div>
      </Modal >
    )
  }
}