import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { pullEntities } from '../../../../functions/fetchLinkedObjects';
import FilteredWithSettingsTableHeader from '../../../CoreComponents/CoreTableHeaders';
import { CheckboxFieldProps, FilterAndSettingField } from '../../../CoreComponents/interfaces';
import { EntityBankAccountType_NewRequest, EntityBankName_NewRequest } from '../../Structs';

interface EntityRequestReviewMemberBankingProps {
  uid: string;
}

interface EntityRequestReviewMemberBankingState {
  uid: string;
  initialContribution: number;
  members: Array<any>;
  memberHeaders: Array<FilterAndSettingField>;
  banking: Array<any>;
  bankHeaders: Array<FilterAndSettingField>;
}

export default class EntityRequestReviewMemberBanking extends React.Component<EntityRequestReviewMemberBankingProps, EntityRequestReviewMemberBankingState>{

  constructor(props: EntityRequestReviewMemberBankingProps) {
    super(props);
    this.state = {
      uid: props.uid,
      initialContribution: 0,
      members: [],
      memberHeaders: [],
      banking: [],
      bankHeaders: []
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.makeManager = this.makeManager.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const entities = await pullEntities(true);
    const members: any = [];
    const accounts: any = [];

    await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
      if (response.data.newEntityMembers.length > 0) {
        response.data.newEntityMembers.forEach((item: any) => {
          item.entity = { label: entities.filter(u => u.value === item.entityUID)[0].label, value: item.entityUID }
          members.push({
            entity: item.entity,
            capitalPercentage: item.capitalOwnershipPercentage,
            annualPercentage: item.annualOwnershipPercentage,
            isManager: item.isManager
          })
        })
      }

      response.data.bankingInformation.forEach((item: any) => {
        accounts.push({
          type: { label: EntityBankAccountType_NewRequest.filter(u => parseInt(u.value) === item.bankAccountType)[0].label, value: item.bankAccountType },
          signers: item.authorizedSigners,
          bank: { label: EntityBankName_NewRequest.filter(u => parseInt(u.value) === item.bankName)[0].label, value: item.bankName }
        })
      })

      this.setState({
        members: members,
        banking: accounts,
        initialContribution: response.data.initialCapital
      })
    })
  }

  componentDidUpdate(): void {

  }

  generateHeaders(): void {
    const memberHeaders: Array<FilterAndSettingField> = [
      {
        columnName: 'Member Name',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Contributions',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Capital Percentage',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Annual Percentage',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Is Manager',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
    ]
    const bankHeaders: Array<FilterAndSettingField> = [
      {
        columnName: 'Bank Type',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Signers',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Bank Name',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      }
    ]

    this.setState({
      memberHeaders: memberHeaders,
      bankHeaders: bankHeaders
    })
  }

  async makeManager(event: React.ChangeEvent<CheckboxFieldProps>, idx: number): Promise<void> {
    const members = this.state.members;
    members[idx].isManager = event.target.checked;
    this.setState({ members: members })

    const data = {
      NewEntityUID: this.state.uid,
      EntityUID: this.state.members[idx].entity.value
    }

    if (event.target.checked) {
      await axios.patch("./api/new-entity-request/make-member-manager", data).then((response) => {
        CreateGlobalAlert("Member Made Manager", 2500);
      })
    }

    if (!event.target.checked) {
      await axios.patch("./api/new-entity-request/make-manager-member", data).then((response) => {
        CreateGlobalAlert("Manager Made Member", 2500);
      })
    }
  }

  render(): JSX.Element {
    return (
      <Row style={{ width: "98%", marginLeft: "1%", border: "3px solid #d7c7a7", backgroundColor: "#4C4A42", marginBottom: "2vh", paddingTop: "1vh", paddingBottom: "2vh" }}>
        <Col>
          <div>
            <h3 style={{ display: "inline-block" }}>Member Data</h3>
          </div>
          <div id="new-entity-request-members" style={{ overflow: "auto" }}>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.memberHeaders} icons={0} />
              </thead>
              <tbody>
                {this.state.members.map((item: any, idx: any) =>
                  <tr key={idx}>
                    <td className="fixedTableCellFirst">{item.entity.label}</td>
                    <td className="fixedTableCell">$ {(item.capitalPercentage / 100) * this.state.initialContribution}</td>
                    <td className="fixedTableCell">{item.capitalPercentage} %</td>
                    <td className="fixedTableCell">{item.annualPercentage} %</td>
                    {item.isManager ?
                      <td className="fixedTableCell"><input type="checkbox" checked={item.isManager} style={{ marginLeft: "3vw" }} onChange={(e) => this.makeManager(e, idx)} /></td>
                      :
                      <td className="fixedTableCell"></td>
                    }
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Col>
        <Col>
          <div>
            <h3 style={{ display: "inline-block" }}>Banking Data</h3>
          </div>
          <div id="new-entity-request-banking" style={{ overflow: "auto" }}>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.bankHeaders} icons={0} />
              </thead>
              <tbody>
                {this.state.banking.map((item: any, idx: any) =>
                  <tr key={idx}>
                    <td className="fixedTableCellFirst">{item.type.label}</td>
                    <td className="fixedTableCell">{item.signers}</td>
                    <td className="fixedTableCell">{item.bank.label}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    )
  }
}