
import axios from 'axios';
import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getUserID } from '../../../functions/authActions';

interface CloseTicketStatusModalState {
  showing: boolean;
  ticketUID: string;
  notes: string;
}

export class CloseTicketStatusModal extends React.Component<{ callback: () => void; }, CloseTicketStatusModalState> {

  constructor(props: { callback: () => void; }) {
    super(props);
    this.state = {
      showing: false,
      ticketUID: "",
      notes: ""
    }
    this.show = this.show.bind(this);
    this.submit = this.submit.bind(this);
    this.changeNotes = this.changeNotes.bind(this);
    this.hide = this.hide.bind(this);
  }

  show(ticketUID: string): void {
    this.setState({
      showing: true,
      ticketUID: ticketUID
    })
  }

  hide(): void {
    this.setState({ showing: true })
  }

  changeNotes(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      notes: event.target.value
    })
  }

  async submit(): Promise<void> {
    const data = {
      TicketUID: this.state.ticketUID,
      ClosingNotes: this.state.notes,
      ModifierUID: getUserID(),
      NewStatus: 2
    }
    await axios.patch('./api/tickets/status-change', data);
    this.props.callback();
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderRadius: "15%" }}>
        <div style={{ background: "#4c4a42", color: "#c2a877" }}>
          <ModalHeader toggle={this.hide}>
            <div style={{ color: "#c2a877" }}>
              Close Ticket
            </div>
          </ModalHeader>
          <ModalBody>
            <h5>Closing Notes</h5>
            <textarea style={{ height: "15vh", wordBreak: "break-word", whiteSpace: "pre-wrap" }} value={this.state.notes} onChange={this.changeNotes} className="standard-input" />
          </ModalBody>
          <ModalFooter>
            <input type="button" value="Submit" className="standard-input" onClick={this.submit} />
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}