import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { MCIStudentManagerProps, MCIStudentManagerState, StudentProfile } from '../constants';

export default class StudentManager extends React.Component<MCIStudentManagerProps, MCIStudentManagerState> {

  constructor(props: MCIStudentManagerProps) {
    super(props);
    this.state = {
      headers: this.generateHeaders(),
      rows: []
    }
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    await axios.get("api/student-profile").then(function (response) {
      const rows: Array<StudentProfile> = [];
      response.data.forEach(function (item: StudentProfile) {
        rows.push(item);
      });
      self.setState({
        rows: rows
      })
    });
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: 'Student Name',
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Key',
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Email',
        setting: true,
        filter: {

        }
      },
      {
        columnName: "",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <>
        <div style={{ height: "82vh", overflow: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: StudentProfile) => (
                  <tr key={Math.random()} >
                    <td className="fixedTableCellFirst" style={{ wordBreak: "break-word" }}>{item.fullName}</td>
                    <td className="fixedTableCell" style={{ wordBreak: "break-word" }}>{item.key}</td>
                    <td className="fixedTableCell" style={{ wordBreak: "break-word", }}>{item.email}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faCog} className="fa-icon" style={{ marginLeft: "33%" }}
                        onClick={() => window.location.assign("./mci-student-profile?key=" + item.key)}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </>
    )
  }

}