import axios, { AxiosResponse } from "axios";


async function pullSavedFile(uid: string): Promise<AxiosResponse<any, any>> {
  let response = await axios.get(getFileLink(uid), { responseType: 'blob' });
  return response;
}

export function getFileLink(uid: string): string {
  return './api/saved-files/' + uid + "/pull";
}

export async function DownloadSavedFile(uid: string): Promise<void> {
  let response = await pullSavedFile(uid);
  
}

export async function UploadSavedFile(url: string, name: string, file: File): Promise<boolean> {
  const formData = new FormData();
  formData.append("File", file);
  formData.append("Name", name);
  let response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    validateStatus: () => true
  });
  return response.status === 202;
}

export async function DownloadNamedFile(response: AxiosResponse<any, any>): Promise<void> {
  const FileDownload = require('js-file-download');
  const content = response.headers['content-disposition'];
  const startIndex = content.lastIndexOf('\'\'') + 2;
  const name = content.substring(startIndex, content.length);
  FileDownload(response.data, decodeURI(name));
}