import * as React from "react";
import Select from "react-select";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { EMPTY_GUID } from "../../../constants/DefaultConstants";
import { pullDrawsBySite } from "../../../functions/fetchLinkedObjects";
import { SelectOptions } from "../../../interfaces/CoreInterfaces";
import { reactSelectBasicStyle } from "../../../style/select-constants";
import { ScheduleOfValuesLineItemRow } from "../../CoreComponents/ScheduleOfValues/ScheduleOfValuesLineItem";

//#region Change Orders
export interface CreateChangeOrderRequest {
  drawUID: string;
  generalRequirements: number;
  overhead: number;
  bond: number;
  profit: number;
}

export interface EditSoftCostRequest {
  changeOrderUID: string;
  generalRequirements: number;
  overhead: number;
  bond: number;
  profit: number;
}


export interface ChangeOrderActionModalProps {
  createCallback: (request: CreateChangeOrderRequest) => void;
  editCallback: (request: EditSoftCostRequest) => void;
  moveCallback: () => void;
  deleteCallback: () => void;
}

export interface ChangeOrderActionModalState {
  show: boolean;
  draw: SelectOptions;
  draws: SelectOptions[];
  generalRequirements: number;
  generalOverhead: number;
  bondSurety: number;
  profit: number;
  action: SelectOptions;
  changeOrderUID: string;
}

export class ChangeOrderActionModal extends React.Component<ChangeOrderActionModalProps, ChangeOrderActionModalState> {

  constructor(props: ChangeOrderActionModalProps) {
    super(props);
    this.state = {
      show: false,
      draw: { label: "Select Draw", value: "" },
      draws: [],
      generalRequirements: 0.0,
      generalOverhead: 0.0,
      profit: 0.0,
      bondSurety: 0.0,
      action: { label: "Create", value: "0" },
      changeOrderUID: ""
    }
    this.show = this.show.bind(this);
    this.changeGeneralRequirements = this.changeGeneralRequirements.bind(this);
    this.changeDraw = this.changeDraw.bind(this);
    this.changeAction = this.changeAction.bind(this);
    this.changeGeneralOverhead = this.changeGeneralOverhead.bind(this);
    this.changeProfit = this.changeProfit.bind(this);
    this.changeBondSurety = this.changeBondSurety.bind(this);
    this.createCallback = this.createCallback.bind(this);
    this.moveCallback = this.moveCallback.bind(this);
    this.editSoftCostCallback = this.editSoftCostCallback.bind(this);
    this.deleteCallback = this.deleteCallback.bind(this);
  }

  async show(siteUID: string, genReqs: number, genOver: number, bond: number, profit: number, changeOrderUID: string=""): Promise<void> {
    let draws = await pullDrawsBySite(siteUID);
    this.setState({
      show: true,
      draws: draws,
      generalOverhead: genOver,
      generalRequirements: genReqs,
      bondSurety: bond,
      profit: profit,
      changeOrderUID: changeOrderUID
    })
  }

  changeAction(event: SelectOptions | null): void {
    this.setState({
      action: event
    })
  }

  changeDraw(event: SelectOptions | null): void {
    this.setState({
      draw: event
    })
  }

  changeGeneralRequirements(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      generalRequirements: parseFloat(event.target.value)
    });
  }

  changeGeneralOverhead(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      generalOverhead: parseFloat(event.target.value)
    });
  }

  changeProfit(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      profit: parseFloat(event.target.value)
    });
  }

  changeBondSurety(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      bondSurety: parseFloat(event.target.value)
    });
  }

  createCallback() {
    const data: CreateChangeOrderRequest = {
      drawUID: this.state.draw.value,
      generalRequirements: this.state.generalRequirements,
      overhead: this.state.generalOverhead,
      bond: this.state.bondSurety,
      profit: this.state.profit
    }
    this.props.createCallback && this.props.createCallback(data);
    this.setState({
      show: false
    })
  }

  editSoftCostCallback() {
    const data: EditSoftCostRequest = {
      changeOrderUID: this.state.changeOrderUID,
      generalRequirements: this.state.generalRequirements,
      overhead: this.state.generalOverhead,
      bond: this.state.bondSurety,
      profit: this.state.profit
    }
    this.props.editCallback && this.props.editCallback(data);
    this.setState({
      show: false
    })
  }

  moveCallback() {
    this.props.moveCallback && this.props.moveCallback();
    this.setState({
      show: false
    })
  }

  deleteCallback() {
    this.props.deleteCallback && this.props.deleteCallback();
    this.setState({
      show: false
    })
  }

  getSubmit(): JSX.Element {
    switch (this.state.action.value) {
      case "0":
        return <input type="button" className="standard-input" style={{ width: "14vw" }} value="Create Change Order" onClick={this.createCallback} />;
      case "1":
        return <input type="button" className="standard-input" style={{ width: "14vw" }} value="Update Soft Costs" onClick={this.editSoftCostCallback} />
      case "2":
        return <input type="button" className="standard-input" style={{ width: "14vw" }} value="Confirm" onClick={this.moveCallback} />
      case "3":
        return <input type="button" className="standard-input" style={{ width: "14vw" }} value="Delete Change Order" onClick={this.deleteCallback} />
    }
  }

  getJSXData(): JSX.Element {
    switch (this.state.action.value) {
      case "0":
        return this.getCreateJSX();
      case "1":
        return this.getEditJSX();
      case "2":
        return <label>You are about to move the values from the current period to previously billed, this cannot be undone. Are you sure you wish to proceed?</label>
      case "3":
        return this.getDeleteJSX();
    }
  }

  getCreateJSX(): JSX.Element {
    return <>
      <>
        <h5>Draw</h5>
        <Select
          value={this.state.draw}
          options={this.state.draws}
          styles={reactSelectBasicStyle}
          onChange={this.changeDraw}
        />
      </>
      <>
        <h5>General Requirements</h5>
        <input type="number" value={this.state.generalRequirements} className="standard-input" onChange={this.changeGeneralRequirements} />
      </>
      <>
        <h5>General Overhead</h5>
        <input type="number" value={this.state.generalOverhead} className="standard-input" onChange={this.changeGeneralOverhead} />
      </>
      <>
        <h5>Profits</h5>
        <input type="number" value={this.state.profit} className="standard-input" onChange={this.changeProfit} />
      </>
      <>
        <h5>Bond / Surety</h5>
        <input type="number" value={this.state.bondSurety} className="standard-input" onChange={this.changeBondSurety} />
      </>
    </>
  }

  getEditJSX(): JSX.Element {
    return (
      <>
        <>
          <h5>General Requirements</h5>
          <input type="number" value={this.state.generalRequirements} className="standard-input" onChange={this.changeGeneralRequirements} />
        </>
        <>
          <h5>General Overhead</h5>
          <input type="number" value={this.state.generalOverhead} className="standard-input" onChange={this.changeGeneralOverhead} />
        </>
        <>
          <h5>Bond / Surety</h5>
          <input type="number" value={this.state.bondSurety} className="standard-input" onChange={this.changeBondSurety} />
        </>
        <>
          <h5>Profits</h5>
          <input type="number" value={this.state.profit} className="standard-input" onChange={this.changeProfit} />
        </>
      </>
    )
  }

  getDeleteJSX(): JSX.Element {
    return (
      <>
        <h6>
          You are about to delete the change order with the highest number for this site. If you want to delete a different change order please contact
          John Green as he will be able to assist you with this problem. 
        </h6>
      </>      
    )
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%" }} >
        <div style={{ background: "#4c4a42", color: "#c2a877" }}>
          <ModalHeader toggle={() => this.setState({ show: false })}>
            <b style={{ color: "#c2a877" }}>Create New Change Order</b>
            <br />
            <em style={{ fontSize: "9px", color: "#c2a877" }}></em>
          </ModalHeader>
          <ModalBody>
            <h5>Action</h5>
            <>
              <Select
                value={this.state.action}
                options={[
                  { label: "Create Change Order", value: "0" },
                  { label: "Edit Soft Costs", value: "1" },
                  { label: "Move Current to Previous", value: "2" },
                  { label: "Delete Change Order", value: "3"}
                ]}
                styles={reactSelectBasicStyle}
                onChange={this.changeAction}
              />
            </>
            {
              this.getJSXData()
            }
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                {this.getSubmit()}
              </Col>
            </Row>
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}

//#endregion

export interface CreateChangeEventRequest {
  uid: string;
  changeOrderUID: string;
  eventNumber: number;
  type: string;
  description: string;
  scheduledValues: number;
}

export interface CreateChangeEventState {
  eventNumber: number;
  type: SelectOptions;
  description: string;
  scheduleValues: number;
  show: boolean;
  callback: (request: CreateChangeEventRequest) => void;
}

export class CreateChangeEventModal extends React.Component<{}, CreateChangeEventState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      show: false,
      eventNumber: -1,
      type: { label: "Select Type", value: "" },
      description: "",
      scheduleValues: 0.0,
      callback: undefined
    }
    this.show = this.show.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeEventNumber = this.changeEventNumber.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeScheduledValues = this.changeScheduledValues.bind(this);
    this.finish = this.finish.bind(this);
  }

  async show(callback: (data: CreateChangeEventRequest) => void): Promise<void> {
    this.setState({
      show: true,
      callback: callback
    })
  }

  changeType(event: SelectOptions | null): void {
    this.setState({
      type: event
    })
  }

  changeEventNumber(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      eventNumber: parseInt(event.target.value)
    });
  }

  changeDescription(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      description: event.target.value
    });
  }

  changeScheduledValues(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      scheduleValues: parseFloat(event.target.value)
    });
  }

  finish() {
    const data: CreateChangeEventRequest = {
      uid: EMPTY_GUID,
      changeOrderUID: EMPTY_GUID,
      eventNumber: this.state.eventNumber,
      type: this.state.type.value,
      description: this.state.description,
      scheduledValues: this.state.scheduleValues
    }
    this.state.callback && this.state.callback(data);
    this.setState({
      show: false
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%" }} >
        <div style={{ background: "#4c4a42", color: "#c2a877" }}>
          <ModalHeader toggle={() => this.setState({ show: false })}>
            <b style={{ color: "#c2a877" }}>Create New Change Event</b>
            <br />
            <em style={{ fontSize: "9px", color: "#c2a877" }}></em>
          </ModalHeader>
          <ModalBody>
            <>
              <>
                <h5>Change Event Number</h5>
                <input type="number" value={this.state.eventNumber} className="standard-input" onChange={this.changeEventNumber} />
              </>
              <h5>Type</h5>
              <Select
                value={this.state.type}
                options={ScheduleOfValuesLineItemRow.Map.map(function (item) { return { label: item.description, value: item.item } })}
                styles={reactSelectBasicStyle}
                onChange={this.changeType}
              />
            </>
            <>
              <h5>Description</h5>
              <input type="text" value={this.state.description} className="standard-input" onChange={this.changeDescription} />
            </>
            <>
              <h5>Scheduled Values</h5>
              <input type="number" value={this.state.scheduleValues} className="standard-input" onChange={this.changeScheduledValues} />
            </>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <input type="button" className="standard-input" style={{ width: "14vw" }} value="Create Event" onClick={this.finish} />
              </Col>
            </Row>
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}