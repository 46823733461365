import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { WarningModal } from '../../CoreComponents/Modals';

interface RegisterStudentWidgetState {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}

export class RegisterStudentWidget extends React.Component<{}, RegisterStudentWidgetState> {

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
    }
    this.changeValue = this.changeValue.bind(this);
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  //#region Functions
  changeValue(event: React.ChangeEvent<HTMLInputElement>): void {
    //@ts-ignore
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  reset(): void {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
    })
  }

  async submit(): Promise<void> {
    const data = {
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      Email: this.state.email,
      CompanyName: this.state.companyName,
    };
    const self = this;
    let response = await axios.post('./api/student-profile', data, { validateStatus: () => true});
    if (response.status === 202) {
      this.warningModal.current.show("Student has been registered.", "Success", () => this.reset());
    }
    else {
      this.warningModal.current.show("Error while creating the student, please contact the tech team to resolve this issue.", "Error.", () => this.reset());
    }
  }

  //#endregion

  render(): JSX.Element {
    return (
      <>
        <h4 style={{ width: "100%", textAlign: "center" }}>Student Signup</h4>
        <WarningModal ref={this.warningModal} />
        <hr />
        <Row>
          <Col>
            <input type="text" name="firstName" className="standard-input" value={this.state.firstName} onChange={this.changeValue} placeholder="First Name" />
          </Col>
          <Col>
            <input type="text" name="lastName" className="standard-input" value={this.state.lastName} onChange={this.changeValue} placeholder="Last Name" />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <input type="text" name="email" className="standard-input" value={this.state.email} onChange={this.changeValue} placeholder="Email" />
          </Col>
          <Col>
            <input type="text" name="companyName" className="standard-input" value={this.state.companyName} onChange={this.changeValue} placeholder="Company Name" />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <input type="button" value="Register" onClick={this.submit} className="standard-input" />
          </Col>
        </Row>
      </>
    )
  }

}