

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
// straight from stack overflow
// a and b are javascript Date objects
export function dateDiffInDays(a: Date, b: Date) : number {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(new Date(a).getFullYear(), new Date(a).getMonth(), new Date(a).getDate());
  const utc2 = Date.UTC(new Date(b).getFullYear(), new Date(b).getMonth(), new Date(b).getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

/**
 *
 * @param {Date} a
 * @param {Date} b
 */
export function dateDiffInMonths(a: Date, b: Date): number {
  let months;
  months = (new Date(b).getFullYear() - new Date(a).getFullYear()) * 12;
  months -= new Date(a).getMonth();
  months += new Date(b).getMonth();
  return months <= 0 ? 0 : months;
}

/**
 * 
 * @param date
 * @param m
 */
export function addMonths(date: Date, m: number) : Date {
  const years = Math.floor(m / 12);
  const months = m - (years * 12);
  if (years) date.setFullYear(date.getFullYear() + years);
  if (months) date.setMonth(date.getMonth() + months);
  return date;
}

export function convertUTCDateToLocalDate(date: Date): Date {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate;
}

export function getDayTag(day: number): string {
  let tag = "";
  if (day > 3 && day < 21) {
    tag = "th";
  }
  else if (day <= 3) {
    tag = day === 3 ? "rd" : day === 2 ? "nd" : "st";
  }
  else {
    day = day % 10;
    if (day <= 3) {
      tag = day === 3 ? "rd" : day === 2 ? "nd" : "st";
    }
    else {
      tag = "th";
    }
  }
  return tag
}
