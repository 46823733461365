import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField, StreetAddress } from '../../CoreComponents/interfaces';
import BankManagementModal from './BankManagementWidget.Modal';
import axios from 'axios';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface BankManagementWidgetData {
  uid: string;
  address: string;
  name: string;
  addressUID: string;
}

interface BankManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  rows: Array<BankManagementWidgetData>;
}

export default class BankManagementWidget extends React.Component<{}, BankManagementWidgetState>{


  managementModal: React.RefObject<BankManagementModal> = React.createRef<BankManagementModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      headers: [],
      rows: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    let response = await axios.get('./api/banks/widget', { validateStatus: () => true});
    if (response.status === 200) {
      let rows: Array<BankManagementWidgetData> = [];
      response.data.forEach((item: BankManagementWidgetData) => (
        rows.push({
          uid: item.uid,
          address: item.address,
          addressUID: item.addressUID,
          name: item.name
        })
      ))
      this.setState({ rows: rows });
    }
    else {
      //TODO: Error Handling
    }
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Bank",
        filter: {},
        setting: true,
        additionalClasses: "twenty"
      },
      {
        columnName: "Address",
        filter: {},
        setting: true,
      }
    ]
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <div>
        <BankManagementModal ref={this.managementModal} />
        {RenderHeader("Bank Management")}
        <div style={{ height: "25vh"}}>
          <table>
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{item.address}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Add Bank" className="standard-input" onClick={() => this.managementModal.current.show()} />
      </div>
    )
  }

} 