import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { CostAnalysisManagement } from './CostAnalysisManagement';
import { UnitLicenseManagement } from './UnitLicenseManagement';
import { UserLicenseManagement } from './UserLicenseManagement';


interface SoftwareManagementScreenState {
  uid: string;
  currentTab: number;
  companyName: string;
}

export class SoftwareManagementScreen extends React.Component<{}, SoftwareManagementScreenState> {

  constructor(props: {}) {
    let params = new URLSearchParams(window.location.search);
    let uid = params.get('uid');
    super(props);
    this.state = {
      uid: uid,
      currentTab: params.has('currentTab') ? parseInt(params.get('currentTab')) : 0,
      companyName: ""
    }
  }

  async componentDidMount(): Promise<void> {
    await axios.get('./api/software-billing-setup/' + this.state.uid).then((response) => {
      this.setState({
        companyName: response.data.softwareCompany
      })
    })
  }

  getTab(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <UnitLicenseManagement softwareUid={this.state.uid} />
      case 1:
        return <UserLicenseManagement softwareUID={this.state.uid} />
      case 2:
        throw new Error("Error");
      case 3:
        return <CostAnalysisManagement softwareUID={this.state.uid} />
    }
  }

  setTab(tab: number): void {
    var params = new URLSearchParams(window.location.search);
    params.set('currentTab', tab.toFixed(0));
    window.location.search = params.toString();
    this.setState({ currentTab: tab })
  }

  render(): JSX.Element {
    return (
      <div>
        <Row>
          <Col>
            <h3>Software Billing License Management - {this.state.companyName}</h3>
          </Col>
          <Col xs='1'>
            
          </Col>
        </Row>
        <Row>
          <Col>
            <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Unit Licenses" onClick={() => this.setTab(0)} />
            <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "25%" }} value="User Licenses" onClick={() => this.setTab(1)} />
            <input type="button" className={this.state.currentTab === 2 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Transaction Licenses" onClick={() => this.setTab(2)} />
            <input type="button" className={this.state.currentTab === 3 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Cost Analysis" onClick={() => this.setTab(3)} />
          </Col>
        </Row>
        <Row>
          {this.getTab()}
        </Row>
      </div>
    )
  }
}

