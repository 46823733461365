import axios from "axios";
import * as React from "react";
import { getBearerToken } from "../../../functions/authActions";
import FilteredWithSettingsTableHeader from "../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { UnitInterface } from "../PropertyDetailPage.constants";
import { ProperyUnitWidgetProps, ProperyUnitWidgetState, UnitStatusList } from "./PropertyUnitWidget.constants";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";
import UnitManagementModal, { UnitManagementModalData } from "./PropertyUnitWidget.modals";

export default class PropertyUnitWidget extends React.Component<ProperyUnitWidgetProps, ProperyUnitWidgetState> {

  modal: React.RefObject<UnitManagementModal> = React.createRef<UnitManagementModal>();

  constructor(props: ProperyUnitWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      unitHeaders: [],
      units: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.setHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let response = await axios.get('./api/units/property/' + this.state.uid);
    this.setState({
      units: response.data
    })
  }

  setHeaders(): void {
    const unitHeaders: Array<FilterAndSettingField> = [
      {
        columnName: "Name",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Status",
        additionalClasses: "thirty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Building",
        setting: true,
        filter: {}
      },
      {
        columnName: "Bedrooms",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Bathrooms",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "SQFT",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Is ADA",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      }
    ]

    this.setState({
      unitHeaders: unitHeaders
    })
  }

  render(): JSX.Element {
    return (
      <>
        <UnitManagementModal ref={this.modal} />
        {RenderHeader("Unit Information Table")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.unitHeaders} icons={0} />
            </thead>
            <tbody>
              {this.state.units.map((unit: UnitManagementModalData) =>
                <tr key={unit.name}>
                  <td className="fixedTableCellFirst">{unit.name}</td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell">{unit.building.label}</td>
                  <td className="fixedTableCell">{unit.bedrooms}</td>
                  <td className="fixedTableCell">{unit.bathrooms}</td>
                  <td className="fixedTableCell">{unit.squareFeet}</td>
                  <td className="fixedTableCell">{unit.isADA ? "Yes" : "No"}</td>
                </tr>
              )}

            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Add Unit" className="standard-input" onClick={() => this.modal.current.show(this.state.uid)} />
      </>
    )
  }
}