import * as React from 'react';
import { Col, Row } from 'reactstrap';
import * as Structs from '../Structs';
import { ActiveJobSettings } from './ActiveJobSettings';
import { ProjectsTable } from './ProjectsTable';

export class JobInformationTable extends React.Component<Structs.JobInformationTableProps, Structs.JobInformationTableState> {

  constructor(props: Structs.JobInformationTableProps) {
    super(props);
    let uid = props.uid;
    uid = uid ? uid : '';
    if (uid === '') {
      let params = new URLSearchParams(window.location.search);
      uid = params.has('uid') ? params.get('uid') : '';
    }
    this.state = {
      uid: uid,
    }
  }

  render(): JSX.Element {
    return (
      <Row>
        <Col>
          {this.state.uid !== undefined && <ActiveJobSettings uid={this.state.uid} />}
        </Col>
        <Col>
          {this.state.uid !== undefined && this.state.uid !== '' && <ProjectsTable uid={this.state.uid} />}
        </Col>
      </Row>
    )
  }
}