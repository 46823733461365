import * as React from 'react';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { DashboardOrganizer, DefaultPageSettings } from '../../functions/WidgetDashboardOrganizer';
import { GetSettings } from '../../functions/StateManagement/StateStorageFunctions';
import { DashboardManagementModal } from '../CoreComponents/Modals/DashboardManagementModal';
import { Col, Row } from 'reactstrap';
import ComputerConfigurationManagementWidget from './ComputerConfigurationManagementWidget';
import ComputerTrackingManagementWidget from './ComputerTrackingManagementWidget';
import ComputerAccessoryTrackingManagementWidget from './ComputerAccessoryTrackingManagementWidget';
import PhoneTrackingManagementWidget from './PhoneTrackingManagementWidget';
import PhoneCheckoutManagementWidget from './PhoneCheckoutManagementWidget';
import ScannerTrackingManagementWidget from './ScannerTrackingManagementWidget';
import { RenderSettings } from '../../interfaces/WidgetSettingsInterfaces';

interface EquipmentManagementTrackingDashboardState {
  showModal: boolean;
  renderSettings: RenderSettings;
  widgetList: Array<SelectOptions>;
  tabList: { [key: number]: JSX.Element };
}

export default class EquipmentManagementTrackingDashboard extends React.Component<{}, EquipmentManagementTrackingDashboardState> {

  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  constructor(props: {}) {
    super(props);
    let settings: RenderSettings = GetSettings<RenderSettings>("equipmentTrackingDashboard");
    let showModal: boolean = false;
    if (settings == null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      showModal: showModal,
      renderSettings: settings,
      tabList: [],
      widgetList: []
    }
    this.updateUI = this.updateUI.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDashboard();
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show("equipmentTrackingDashboard", this.state.widgetList, this.updateUI);
    }
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  async prepareDashboard(): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      0: <ComputerConfigurationManagementWidget />,
      1: <ComputerTrackingManagementWidget />,
      2: <ComputerAccessoryTrackingManagementWidget />,
      3: <PhoneTrackingManagementWidget />,
      4: <PhoneCheckoutManagementWidget />,
      5: <ScannerTrackingManagementWidget />
    };
    let widgetList = [
      { label: "Computer Configuration Editor", value: "0" },
      { label: "Computer Tracking Editor", value: "1" },
      { label: "Computer Accessory Tracking Editor", value: "2" },
      { label: "Phone Tracking Management Widget", value: "3" },
      { label: "Phone Checkout Management Widget", value: "4" },
      { label: "Scanner Tracking Management Widget", value: "5"}
    ];
    this.setState({
      widgetList: widgetList,
      tabList: tabList
    })
  }

  render(): JSX.Element {
    return (
      <>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>Equipment Management Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show("equipmentTrackingDashboard", this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
      </>
    )
  }
}