import axios from 'axios';
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { getUserID } from '../../functions/authActions';

interface KnowledgeBaseArticleEditorProps {

}

interface KnowledgeBaseArticleEditorState {
  uid: string;
  articleURL: string;
  title: string;
  tags: Array<any>;
  author: string;
  updated: string;
}


export default class KnowledgeBaseArticleEditor extends React.Component<KnowledgeBaseArticleEditorProps, KnowledgeBaseArticleEditorState> {

  constructor(props: KnowledgeBaseArticleEditorProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const articleUID = params.has("articleUID") ? params.get("articleUID") : "";
    this.state = {
      uid: articleUID,
      articleURL: 'https://docs.google.com/document/d/14wJM8wOLdTQq9azYUyk22f8dStZvvTtgvNxoooyN7Nc/pub?embedded=true',
      title: '',
      tags: [],
      author: getUserID(),
      updated: ''
    }
    this.updateURL = this.updateURL.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.updateArticle = this.updateArticle.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    if (this.state.uid !== '') {
      await axios.get("api/knowledgeBase/" + this.state.uid).then(function (response) {
        self.setState({
          title: response.data.title,
          author: response.data.author,
          articleURL: response.data.articleURL,
          updated: response.data.googleLastUpdated
        })
      });
    }
  }

  componentDidUpdate(): void {

  }

  updateURL(event: React.ChangeEvent<HTMLInputElement>): void {
    let value = event.target.value;
    value = value.replace("<iframe src=\"", "").replace("\"></iframe>", "")
      .replace("edit#", "pub")
      .replace("edit", "pub")
      .replace("view", "pub")
      .replace("?usp=sharing", "");
    if (value !== '') {
      value += "?embedded=true";
    }
    this.setState({
      articleURL: value
    })
  }

  updateTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    this.setState({ title: value })
  }

  async updateArticle(): Promise<void> {
    const data = {
      ArticleUID: this.state.uid,
      Title: this.state.title,
      ArticleURL: this.state.articleURL,
      AuthorUID: getUserID()
    }
    if (this.state.uid === '') {
      await axios.post("./api/knowledgeBase", data).then(function (response) {
      })
    }
    else {
      await axios.put("./api/knowledgeBase", data).then(function (response) {

      })
    }
    window.location.assign("./knowledge-base-home")
  }

  cancelEditor(): void {
    window.location.assign("./knowledge-base-home");
  }

  render(): JSX.Element {
    return (
      <>
        <h2 style={{ marginLeft: "5%", marginTop: "1em" }}>Article Editor</h2>
        <hr />
        <Row style={{ border: "5px solid #d7c7a7", background: "#4C4A42", marginLeft: "10%", width: "80%" }}>
          <Col md="3" style={{ marginRight: "4em" }}>
            <h4 style={{ marginTop: "1vh" }}>Title</h4>
            <input type="text" className="standard-input" value={this.state.title} onChange={this.updateTitle} />
            <h4 style={{ marginTop: "1vh" }}>URL</h4>
            <input type="text" className="standard-input" value={this.state.articleURL} onChange={this.updateURL} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <input type="button" value="Submit" className="standard-input" onClick={this.updateArticle} />
            <br />
            <br />
            <input type="button" value="Cancel" className="standard-input" style={{ marginBottom: "1em" }} onClick={this.cancelEditor} />
          </Col>
          <Col>
            <iframe src={this.state.articleURL} height="700" width="100%" style={{ marginTop: "1em" }} />
          </Col>
        </Row>
        <Row style={{ marginTop: "2%", marginLeft: "5%", marginRight: "5%" }}>
          <Col>

          </Col>

          <Col>

          </Col>
        </Row>
      </>
    )
  }
}