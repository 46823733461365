import { StylesConfig } from "react-select"
import { Collapse } from "reactstrap"

export const reactSelectBasicStyle: StylesConfig = {
  menu: (styles: any) => ({
    ...styles,
    background: "#d7c7a7",
  }),
  control: (base: any, state: any) => ({
    ...base,
    background: "#d7c7a7",
    color: "#15405c",
    border: state.selectProps.required ? "1px solid #F24236" : ""
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#d7c7a7",
      color: "#15405c",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "14px",
    color: "#15405c",
    borderBottom: "1px solid #15405c",
    padding: "0", 
    margin: "0",
    marginRight: "0.35vw",
    marginLeft: "0.35vw",
    paddingLeft: "0.01vw",
    textAlign: "center"
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#b89961",
      color: "#15405c"
    }
  }
}

export const reactSelectBasicStyle_75Width = {
  menu: (styles: any) => ({
    ...styles,
    background: "#d7c7a7",
    width: "75%",
  }),
  control: (base: any) => ({
    ...base,
    background: "#d7c7a7",
    color: "#15405c",
    width: "75%",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#d7c7a7",
      color: "#15405c",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#15405c"
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#b89961",
      color: "#15405c"
    }
  }
}

export const reactSelectMCIBasicStyle = {
  menu: (styles: any) => ({
    ...styles,
    background: "#D5E5F5",
    zIndex: 25
  }),
  control: (base: any) => ({
    ...base,
    background: "#D5E5F5",
    color: "#1F2B37",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#D5E5F5",
      color: "#1F2B37",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#1F2B37c"
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#D5E5F5",
      color: "#1F2B37"
    }
  }
}

export const reactSelectMCIStyle_Thin = {
  menu: (styles: any) => ({
    ...styles,
    background: "#D5E5F5",
    zIndex: 25,
    width: "80%",
    marginLeft: "10%"
  }),
  control: (base: any) => ({
    ...base,
    background: "#D5E5F5",
    color: "#1F2B37",
    width: "80%",
    marginLeft: "10%"
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#D5E5F5",
      color: "#1F2B37",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#1F2B37c"
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#D5E5F5",
      color: "#1F2B37"
    }
  }
}

export const reactSelectBasicStyle_95Width = {
  menu: (styles: any) => ({
    ...styles,
    background: "#d7c7a7",
    zIndex: 25,
    width: "95%",
  }),
  control: (base: any) => ({
    ...base,
    background: "#d7c7a7",
    color: "#15405c",
    width: "95%",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#d7c7a7",
      color: "#15405c",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#15405c",
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#b89961",
      color: "#15405c",
    }
  }
}

export const reactSelectBasicStyle_Filter = {
  container: (style: any) => ({
    width: "90%"
  }),
  menu: (styles: any) => ({
    ...styles,
    background: "#d7c7a7",
    zIndex: 25
  }),
  control: (base: any) => ({
    ...base,
    background: "#d7c7a7",
    color: "#15405c",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#d7c7a7",
      color: "#15405c",
      fontSize: "13px"
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#15405c"
  }),
  singleValue: (styles: any) => ({
    ...styles,
    fontSize: '13px'
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#b89961",
      color: "#15405c"
    }
  }
}
export const reactSelectBasicStyle_FullFilter = {
  container: (style: any) => ({
    width: "100%"
  }),
  menu: (styles: any) => ({
    ...styles,
    background: "#d7c7a7",
    zIndex: 25
  }),
  control: (base: any) => ({
    ...base,
    background: "#d7c7a7",
    color: "#15405c",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#d7c7a7",
      color: "#15405c",
      fontSize: "13px"
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#15405c"
  }),
  singleValue: (styles: any) => ({
    ...styles,
    fontSize: '13px'
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#b89961",
      color: "#15405c"
    }
  }
}

export const anonymousFormSelect = {
  menu: (styles: any) => ({
    ...styles,
    background: "#647C90",
  }),
  control: (base: any) => ({
    ...base,
    background: "#647C90",
    color: "#E2DED0",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      background: "#647C90",
      color: "#E2DED0",
      fontSize: "13px",
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '13px',
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    fontSize: "15px",
    color: "#E2DED0"
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      background: "#647C90",
      color: "#E2DED0"
    }
  }
}