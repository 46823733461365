import * as React from 'react';
import { SupervisorEmployeeManagementWidget } from './SupervisorEmployeeManagementWidget';
import Select from 'react-select';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { pullFleetEmployee } from '../../../functions/fetchLinkedObjects';

interface SupervisorSelectEmployeeManagementWidgetState {
  employees: Array<SelectOptions>;
  supervisor: SelectOptions;
}

export class SupervisorSelectEmployeeManagementWidget extends React.Component<{}, SupervisorSelectEmployeeManagementWidgetState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      employees: [],
      supervisor: { label: "Supervisor", value: ""}
    }
    this.changeEmployee = this.changeEmployee.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var employees = await pullFleetEmployee();
    this.setState({
      employees: employees
    })
    this.changeEmployee = this.changeEmployee.bind(this);
  }

  changeEmployee(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      supervisor: event
    })
  }

  render(): JSX.Element {
    return (
      <>
        {this.state.supervisor.value !== "" ?
          <>
            <SupervisorEmployeeManagementWidget key={this.state.supervisor.value} supervisorUID={this.state.supervisor.value} />\
            <hr />
          </>
          :
          <h4 style={{ width: "100%", textAlign: "center" }}>View & Manage Supervisors</h4>
        }
        <Select
          options={this.state.employees}
          styles={reactSelectBasicStyle}
          value={this.state.supervisor}
          onChange={this.changeEmployee}
          menuPlacement="auto"
        />
      </>
    )
  }
}