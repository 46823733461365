import axios from "axios";
import GoogleMapReact from "google-map-react";
import * as React from "react";
import { getBearerToken } from "../../../functions/authActions";
import { LocationMarkersMultiInfoHidden } from "../../OtherTools/NofaComponents/TableRowConsts";
import { SinglePropertyMapWidgetProps, SinglePropertyMapWidgetState } from "./SinglePropertyMapWidget.constatnts";

export default class SinglePropertyMapWidget extends React.Component<SinglePropertyMapWidgetProps, SinglePropertyMapWidgetState> {

  map: any;

  constructor(props: SinglePropertyMapWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      property: undefined,
      zoom: 13,
      apiLoaded: false,
      loaded: false
    }
    this.pullPropertyInfo = this.pullPropertyInfo.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  async pullPropertyInfo(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const getPropertyResponse = (await axios.get('./api/property/' + this.state.uid)).data;
    const property: any = {
      latitude: parseFloat(getPropertyResponse.latitude),
      longitude: parseFloat(getPropertyResponse.longitude),
      code: getPropertyResponse.code,
      center: { lat: parseFloat(getPropertyResponse.latitude), lng: parseFloat(getPropertyResponse.longitude) }
    }
    this.map.panTo({ lat: property.latitude, lng: property.longitude })
    this.setState({
      property: property,
      loaded: true
    })
  }

  setApiLoaded(): void {
    this.setState({
      apiLoaded: true
    }, () => this.pullPropertyInfo())
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "36vh" }}>
        <GoogleMapReact
          key="map"
          bootstrapURLKeys={{
            key: "AIzaSyDF_xQZ5PZZnYBNn-V4-btiQD3Az9zJ3Qo",
            libraries: ['places']
          }}
          defaultCenter={{
            lat: 45.425280,
            lng: -117.275910
          }}
          defaultZoom={this.state.zoom}
          //@ts-ignore
          onGoogleApiLoaded={({ map, maps }) => {
            this.map = map;
            this.setApiLoaded()
          }}
          yesIWantToUseGoogleMapApiInternals
          options={{
            disableDefaultUI: true,
            mapTypeId: "terrain",
            mapTypeControl: true,
            streetViewControl: true,
            scaleControl: true,
            fullscreenControl: true,
            mapTypeControlOptions: {
              style: 2,
              mapTypeIds: [
                "terrain",
                "satellite",
                "roadmap"
              ]
            }
          }}
        >
          {this.state.loaded && <LocationMarkersMultiInfoHidden
            key={this.state.uid}
            name={this.state.property?.name}
            lng={this.state.property.center.lng}
            lat={this.state.property.center.lat}
            color={this.state.property?.hexadecimalColor !== "" ? "#0000FF" : this.state.property?.hexadecimalColor}
            unitCount={this.state.property?.unitCount}
            code={this.state.property.code.toUpperCase()}
            regional={undefined}
            site={undefined}
            maintenance={undefined}
            onFocus={() => undefined}
          />}
        </GoogleMapReact>
      </div>
    )
  }

}