import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { getUserID, getUserJob } from "../../../../functions/authActions";
import { pullFleetEmployee, pullStates } from "../../../../functions/fetchLinkedObjects";
import FilteredWithSettingsTableHeader from "../../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../../CoreComponents/interfaces";
import { EntityPurpose_NewRequest } from "../../Structs";

export interface NewEntityRequestDraftsTableProps {

}
export interface NewEntityRequestDraftsTableState {
  headers: any;
  draftsList: Array<any>;
  employees: Array<any>;
}

export default class NewEntityRequestDraftsTable extends React.Component<NewEntityRequestDraftsTableProps, NewEntityRequestDraftsTableState> {


  constructor(props: NewEntityRequestDraftsTableProps) {
    super(props);
    this.state = {
      headers: [],
      draftsList: [],
      employees: [],
    }
    this.generateHeaders = this.generateHeaders.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders()
    const employees = await pullFleetEmployee();
    const states = await pullStates();

    await axios.get('./api/new-entity-workflow/requested').then((response) => {
      const drafts: any = [];

      response.data.forEach((item: any) => {
        drafts.push({
          uid: item.entity.uid,
          entityName: item.entity.entityName,
          requestor: { label: employees.filter(u => u.value === item.entity.requestorUID)[0].label, value: item.entity.requestorUID }.label,
          formationState: { label: states.filter(u => parseInt(u.value) === item.entity.formationState)[0].label, value: item.entity.formationState.toString() }.label,
          entityPurpose: { label: EntityPurpose_NewRequest.filter(u => parseInt(u.value) === item.entity.entityPurpose)[0].label, value: item.entity.entityPurpose.toString() }.label
        })
      })

      this.setState({ draftsList: drafts, employees: employees })
    })
  }

  componentDidUpdate(): void {

  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Entity Name',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: 'Requestor',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: 'Formation State',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: 'Purpose',
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      }
    ]
    this.setState({ headers: headers })
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "78vh", overflowY: "auto", overflowX: "hidden", paddingBottom: "5vh" }}>
        <Row>
          <Col>
            <div id="new-entity-request-drafts-table">
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
                </thead>
                <tbody>
                  <>
                    {this.state.draftsList.map((item, idx) =>
                      <>{(item.requestor === this.state.employees.filter(u => u.value === getUserID())[0].label ||
                        getUserJob() === 9
                      ) && <NewEntityRequestDraftRow key={idx} draft={item} />}</>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

interface NewEntityRequestDraftRowProps {
  draft: any
}
interface NewEntityRequestDraftRowState {
  draft: any
}

class NewEntityRequestDraftRow extends React.Component<NewEntityRequestDraftRowProps, NewEntityRequestDraftRowState>{
  constructor(props: NewEntityRequestDraftRowProps) {
    super(props);
    this.state = {
      draft: props.draft,
    }
    this.editDraft = this.editDraft.bind(this);
  }

  editDraft(): void {
    window.location.assign("./entity-request-form?draftUID=" + this.state.draft.uid);
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.state.draft.entityName}</td>
        <td className="fixedTableCell">{this.state.draft.requestor}</td>
        <td className="fixedTableCell">{this.state.draft.formationState}</td>
        <td className="fixedTableCell">{this.state.draft.entityPurpose}</td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faCog} style={{ marginLeft: "30%" }} onClick={this.editDraft} />
        </td>
      </tr>
    )
  }
}