import * as React from 'react';
import { DashboardManagementModal } from '../CoreComponents/Modals/DashboardManagementModal';
import { DashboardOrganizer } from '../../functions/WidgetDashboardOrganizer';
import Select from 'react-select';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { RenderSettings } from '../../interfaces/WidgetSettingsInterfaces';
import { GetSettings } from '../../functions/StateManagement/StateStorageFunctions';
import { pullManagedJobs } from '../../functions/fetchLinkedObjects';
import { DrawRequestSummaryTableInternal } from './DashboardWidgets/DrawRequestWorkflowSummaryInternal';
import { SiteSettingsManagementWidget } from './DashboardWidgets/SiteSettingsManagementWidget';
import { GeneralContractorWaiverTable } from './ContractorComponents/GeneralContractorWaiverTable';
import { JobReportingGeneratorWidget } from './DashboardWidgets/JobReportingGeneratorWidget';
import SignerSettingsWidget from './DashboardWidgets/SignerSettingsWidget';
import JobSettingsWidget from './DashboardWidgets/JobSettingsWidget';
import SubcontractingWaiverManagement from './DashboardWidgets/SubcontractingWaiverManagement';
import JobInformationManagementFunctions from './DashboardWidgets/JobInformationManagementFunctions';

interface JobManagementDashboardState {
  jobs: Array<SelectOptions>;
  job: SelectOptions;
  widgetList: Array<SelectOptions>;
  tabList: { [key: number]: JSX.Element };
  renderSettings: RenderSettings;
  uid: string;
}

export default class JobManagementDashboard extends React.Component<{ uid?: string }, JobManagementDashboardState> {

  widgetSettingsModalRef: React.RefObject<DashboardManagementModal> = React.createRef<DashboardManagementModal>();
  settingsString: string = "jobManagementDashboardSettings";

  constructor(props: { uid?: string }) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const uid = props.uid ? props.uid : params.get('uid');
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal = false;
    if (settings == null) {
      showModal = true
      settings = {
        rows: [
          { col: 2, widgets: [0, 4] },
          { col: 3, widgets: [5, 3, 3] }
        ]
      }
    }
    this.state = {
      jobs: [],
      job: { label: "Select Options", value: "" },
      tabList: {},
      widgetList: [],
      renderSettings: settings,
      uid: uid
    }
    this.setJob = this.setJob.bind(this);
    this.updateJob = this.updateJob.bind(this);
    this.updateUI = this.updateUI.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let jobs = await pullManagedJobs();
    console.log(jobs, this.state.uid);
    let job = jobs.filter(u => u.value.toLowerCase() === this.state.uid.toLowerCase())[0];
    console.log(job);
    this.setState({ jobs: jobs, job: job });
    await this.updateJob();
  }

  setJob(event: SelectOptions): void {
    this.setState({ job: event });
  }

  async updateJob(): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      0: <DrawRequestSummaryTableInternal uid={this.state.job.value} />,
      1: <JobSettingsWidget uid={this.state.job.value} />,
      2: <SignerSettingsWidget uid={this.state.job.value} />,
      3: <SiteSettingsManagementWidget uid={this.state.job.value} />,
      4: <GeneralContractorWaiverTable uid={this.state.job.value} />,
      5: <JobReportingGeneratorWidget uid={this.state.job.value} />,
      6: <SubcontractingWaiverManagement uid={this.state.job.value} />,
      7: <JobInformationManagementFunctions uid={this.state.job.value} />
    };
    let widgetList: Array<SelectOptions> = [
      { label: "Draw Requests", value: "0" },
      { label: "Job Settings", value: "1" },
      { label: "Signer Settings", value: "2" },
      { label: "Site Settings", value: "3" },
      { label: "GC Waivers", value: "4" },
      { label: "Reporting Functions", value: "5" },
      { label: "Subcontracting Waivers", value: "6" },
      { label: "Management Functions", value: "7"}
    ];

    this.setState({ tabList: tabList, widgetList: widgetList });
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  render(): JSX.Element {
    return (
      <div style={{ overflowX: "hidden", overflowY: "scroll", height: "90vh" }}>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "10vw", paddingTop: "2vh" }}>
            <Select
              options={this.state.jobs}
              styles={reactSelectBasicStyle}
              value={this.state.job}
              onChange={this.setJob}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "62vw" }}>
            <h5 style={{ textAlign: "center" }}>- Manage General Contracting Job -</h5>
            <h3 style={{ textAlign: "center" }}>{this.state.job.label}</h3>
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", marginLeft: "2%", paddingTop: "2vh" }}>
            <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div>
          {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
        </div>
      </div>
    )
  }
}