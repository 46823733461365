import * as React from 'react';
import axios from 'axios';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';

interface UtilityRowProps {
  uid: string;
  name: string;
  count: number;
  netMeterCap: number;
}

const UtilityRow = (props: UtilityRowProps) => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.name}</td>
      <td className="fixedTableCell">{props.count}</td>
      <td className="fixedTableCell">{props.netMeterCap}</td>
      <td className="fixedTableCell"><a href={"./create-electric-rates?utility=" + props.uid}>Add Rate</a></td>
      <td className="fixedTableCell"><a href={"./manage-electric-rates?utility=" + props.uid}>View Rates</a></td>
      <td className="fixedTableCell"><a href={"./create-electric-utility?provider=" + props.uid}>Edit Provider</a></td>
    </tr>
  )
}

interface ElectricUtilityManagerProps {

}

interface ElectricUtilityManagerState {
  providers: Array<any>;
  headers: Array<any>;
  currentStatus: number;
}

export class ElectricUtilityManager extends React.Component<ElectricUtilityManagerProps, ElectricUtilityManagerState> {

  constructor(props: ElectricUtilityManagerProps) {
    super(props);
    this.state = {
      providers: [],
      headers: this.generateHeaders(),
      currentStatus: 0
    }
    this.createNew = this.createNew.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    await axios.get("./api/electric-utility-providers").then(function (response) {
      var providers: Array<UtilityRowProps> = [];
      response.data.forEach(function (index: any) {
        providers.push({
          uid: index.uid,
          netMeterCap: index.netMeterCap,
          name: index.name,
          count: index.rates.length
        })
      });
      self.setState({
        providers: providers
      })
    });
  }

  createNew(event: React.MouseEvent<HTMLInputElement>): void {
    window.location.replace("./create-electric-utility");
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "# Active Rates",
        setting: true,
        filter: {}
      },
      {
        columnName: "Net Meter Cap",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div id="electric-utility-manager-container">
        <h3>Electric Utility Manager</h3>
        <div id="table-container" style={{ maxHeight: "82vh", minHeight: "82vh", maxWidth: "100%", overflowY: "scroll" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody id="list-of-providers">
              {
                this.state.providers.map((item: UtilityRowProps) => (
                  <UtilityRow key={item.uid} name={item.name} count={item.count} uid={item.uid} netMeterCap={item.netMeterCap} />
                ))
              }
            </tbody>
          </table>
        </div>
        <input type="button" className="standard-input" value="Add New Electric Utility" onClick={this.createNew} />
      </div>
    )
  }
}