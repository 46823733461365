
export const StatusMap: Array<string> = [
  'Payment Requested',
  'Waiting on Finance Lead',
  'Waiting for Owners\'s Signature',
  'Waiting for Architects',
  'Waiting for Contractor Signature',
  'Waiting for Notarization',
  'Waiting for Payment',
  "All Steps Completed, Waiting For Signatures",
  "Completed",
  "Terminated"
];
