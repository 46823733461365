import * as React from 'react';
import { Row, Col } from 'reactstrap';
import * as Structs from './NavBarStructs';
import { ActiveUserSettingsState } from '../../reducers/activeUserSettingsReducer';
import { Jobs, Teams, ValidateAccess } from '../../functions/authActions';
import { GetSettings, SetSettings } from '../../functions/StateManagement/StateStorageFunctions';

export const defaultNavBarSettings: ActiveUserSettingsState = {
  uid: null,
  propertiesSetting: true,
  propPortfoliosSetting: true,
  propPropertiesSetting: true,
  propBuildingsSetting: true,
  propLocationAnalysisSetting: true,
  propCaptialNeedsAssessmentSetting: true,
  entitiesSetting: true,
  entiCDIEmployeesSetting: true,
  entiContactsSetting: true,
  entiExternalCompaniesSetting: true,
  entiMasterEntityListSetting: true,
  entiEntityOwnershipSetting: true,
  entiRequestEntitySetting: true,
  jobManagementSetting: true,
  jobmActiveJobsSetting: true,
  jobmPayAppReportSetting: true,
  taxManagementSetting: true,
  taxmTaxReturnManagementSetting: true,
  otherToolsSetting: true,
  otheNOFAMapperSetting: true,
  otheInvoiceGeneratorsSetting: true,
  otheRequestDataStorageSetting: true,
  techTeamSetting: true,
  techKnowledgeBaseSetting: true,
  techTicketSubmissionSetting: true,
  techEquipmentSetting: true,
  techTeamProjectsSetting: true,
  techSoftwareBillingSetting: true,
  showSidebar: true
}

export default class EmployeeNavBarSettings extends React.Component<Structs.NavBarSettingsProps, Structs.NavBarSettingsState> {

  settingsKey: string = "navigationBarSettings";

  constructor(props: Structs.NavBarSettingsProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const employeeUID: string = params.has("uid") ? params.get("uid") : "";
    let settings: ActiveUserSettingsState = GetSettings<ActiveUserSettingsState>(this.settingsKey);
    if (settings === null) {
      settings = defaultNavBarSettings
      SetSettings(this.settingsKey, settings);
    }
    this.state = {
      employeeUID: employeeUID,
      settingsUID: '',
      settings: settings
    };

    this.changePropertySetting = this.changePropertySetting.bind(this);
    this.changeEntitySettings = this.changeEntitySettings.bind(this);
    this.changeJobManagementSettings = this.changeJobManagementSettings.bind(this);
    this.changeTaxManagementSettings = this.changeTaxManagementSettings.bind(this);
    this.changeOtherToolsSetting = this.changeOtherToolsSetting.bind(this);
    this.changeTechTeamSettings = this.changeTechTeamSettings.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var settings: ActiveUserSettingsState = GetSettings(this.settingsKey);
    if (settings == null) {
      settings = defaultNavBarSettings;
    }
    this.setState({ settings: settings });
  }

  changePropertySetting(event: React.ChangeEvent<HTMLInputElement>): void {
    const settings: ActiveUserSettingsState = this.state.settings;
    const name: string = event.target.name;
    //@ts-ignore
    settings[name] = event.target.checked;
    settings.propertiesSetting = settings.propPortfoliosSetting || settings.propPropertiesSetting || settings.propBuildingsSetting || settings.propLocationAnalysisSetting;
    SetSettings(this.settingsKey, settings);
    this.setState({ settings: settings });
  }

  changeEntitySettings(event: React.ChangeEvent<HTMLInputElement>): void {
    const settings: ActiveUserSettingsState = this.state.settings;
    const name: string = event.target.name;
    //@ts-ignore
    settings[name] = event.target.checked;
    settings.entitiesSetting = settings.entiCDIEmployeesSetting || settings.entiContactsSetting || settings.entiExternalCompaniesSetting
      || settings.entiMasterEntityListSetting || settings.entiEntityOwnershipSetting
      || settings.entiRequestEntitySetting;
    SetSettings(this.settingsKey, settings);
    this.setState({ settings: settings });
  }

  changeJobManagementSettings(event: React.ChangeEvent<HTMLInputElement>): void {
    const settings: ActiveUserSettingsState = this.state.settings;
    const name: string = event.target.name;
    //@ts-ignore
    settings[name] = event.target.checked;
    settings.jobManagementSetting = settings.jobmActiveJobsSetting || settings.jobmPayAppReportSetting;
    SetSettings(this.settingsKey, settings);
    this.setState({ settings: settings });
  }

  changeTaxManagementSettings(event: React.ChangeEvent<HTMLInputElement>): void {
    const settings: ActiveUserSettingsState = this.state.settings;
    const name: string = event.target.name;
    //@ts-ignore
    settings[name] = event.target.checked;
    settings.taxManagementSetting = settings.taxmTaxReturnManagementSetting;
    SetSettings(this.settingsKey, settings);
    this.setState({ settings: settings });
  }

  changeOtherToolsSetting(event: React.ChangeEvent<HTMLInputElement>): void {
    const settings: ActiveUserSettingsState = this.state.settings;
    const name: string = event.target.name;
    //@ts-ignore
    settings[name] = event.target.checked;
    settings.otherToolsSetting = settings.otheNOFAMapperSetting || settings.otheInvoiceGeneratorsSetting || settings.otheRequestDataStorageSetting;
    SetSettings(this.settingsKey, settings);
    this.setState({ settings: settings });
  }

  changeTechTeamSettings(event: React.ChangeEvent<HTMLInputElement>): void {
    const settings: ActiveUserSettingsState = this.state.settings;
    const name: string = event.target.name;
    //@ts-ignore
    settings[name] = event.target.checked;
    settings.techTeamSetting = settings.techKnowledgeBaseSetting
      || settings.techTicketSubmissionSetting || settings.techEquipmentSetting
      || settings.techTeamProjectsSetting || settings.techSoftwareBillingSetting;
    SetSettings(this.settingsKey, settings);
    this.setState({ settings: settings });
  }

  render(): JSX.Element {
    return (
      <>
        <Row style={{ border: "3px solid #d7c7a7", backgroundColor: "#4C4A42" }}>
          <h4 style={{ marginTop: "1vh" }}>Nav Bar Settings</h4>
          <hr />
          <Row>
            <Col xs="3">
              <h6>Properties</h6>
              <h6 style={{ marginLeft: "2em" }}>Properties</h6>
              <h6 style={{ marginLeft: "2em" }}>Location Analysis</h6>
              <h6 style={{ marginLeft: "2em" }}>Capital Needs Assessment</h6>
            </Col>
            <Col xs="1">
              <input type="checkbox" name="propertiesSetting" checked={this.state.settings.propertiesSetting}
                style={{ height: "1em", width: "1em" }} hidden readOnly />
              <br />
              <input type="checkbox" name="propPropertiesSetting" onChange={this.changePropertySetting} checked={this.state.settings.propPropertiesSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="propLocationAnalysisSetting" onChange={this.changePropertySetting} checked={this.state.settings.propLocationAnalysisSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="propCaptialNeedsAssessmentSetting" onChange={this.changePropertySetting} checked={this.state.settings.propCaptialNeedsAssessmentSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
            </Col>
            <Col xs="3">
              <h6>Entities</h6>
              <h6 style={{ marginLeft: "2em" }}>Contacts</h6>
              <h6 style={{ marginLeft: "2em" }}>External Companies</h6>
              <h6 style={{ marginLeft: "2em" }}>Master Entity List</h6>
              <h6 style={{ marginLeft: "4em" }}>Entity Ownership</h6>
              <h6 style={{ marginLeft: "2em" }}>Request Entity</h6>
            </Col>
            <Col xs="1">
              <input type="checkbox" name="entitiesSetting" checked={this.state.settings.entitiesSetting}
                style={{ height: "1em", width: "1em" }} hidden readOnly />
              <br />
              <input type="checkbox" name="entiContactsSetting" onChange={this.changeEntitySettings} checked={this.state.settings.entiContactsSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="entiExternalCompaniesSetting" onChange={this.changeEntitySettings} checked={this.state.settings.entiExternalCompaniesSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="entiMasterEntityListSetting" onChange={this.changeEntitySettings} checked={this.state.settings.entiMasterEntityListSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="entiEntityOwnershipSetting" onChange={this.changeEntitySettings} checked={this.state.settings.entiEntityOwnershipSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="entiRequestEntitySetting" onChange={this.changeEntitySettings} checked={this.state.settings.entiRequestEntitySetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
            </Col>
            <Col xs="3">
              <h6>Job Management</h6>
              <h6 style={{ marginLeft: "2em" }}>Active Jobs</h6>
              <h6 style={{ marginLeft: "2em" }}>Pay App Report</h6>
            </Col>
            <Col xs="1">
              <input type="checkbox" name="jobManagementSetting" checked={this.state.settings.jobManagementSetting}
                style={{ height: "1em", width: "1em" }} hidden readOnly />
              <br />
              <input type="checkbox" name="jobmActiveJobsSetting" onChange={this.changeJobManagementSettings} checked={this.state.settings.jobmActiveJobsSetting}
                style={{ height: "1em", width: "1em", marginTop: "5%" }} />
              <br />
              <input type="checkbox" name="jobmPayAppReportSetting" onChange={this.changeJobManagementSettings} checked={this.state.settings.jobmPayAppReportSetting}
                style={{ height: "1em", width: "1em", marginTop: "5%" }} />
              <br />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs="3">
              <h6>Other Tools</h6>
              <h6 style={{ marginLeft: "2em" }}>NOFA Mapper</h6>
              <h6 style={{ marginLeft: "2em" }}>Invoice Generators</h6>
              <h6 style={{ marginLeft: "2em" }}>Prorate Tool</h6>
            </Col>
            <Col xs="1">
              <input type="checkbox" name="otherToolsSetting" checked={this.state.settings.otherToolsSetting}
                style={{ height: "1em", width: "1em" }} hidden readOnly />
              <br />
              <input type="checkbox" name="otheNOFAMapperSetting" onChange={this.changeOtherToolsSetting} checked={this.state.settings.otheNOFAMapperSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="otheInvoiceGeneratorsSetting" onChange={this.changeOtherToolsSetting} checked={this.state.settings.otheInvoiceGeneratorsSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
            </Col>
            <Col xs="3">
              <h6>Tech Team</h6>
              <h6 style={{ marginLeft: "2em" }}>Knowledge Base</h6>
              <h6 style={{ marginLeft: "2em" }}>Ticket Submission</h6>
              <h6 style={{ marginLeft: "2em" }} hidden={!ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Technology], [Teams.Executive, Teams.Services])}>Tech Equipment</h6>
              <h6 style={{ marginLeft: "2em" }} hidden={!ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Technology], [Teams.Executive, Teams.Services])}>Ticket Manager</h6>
              <h6 style={{ marginLeft: "2em" }} hidden={!ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Technology], [Teams.Executive, Teams.Services])}>Tech Team Projects</h6>
              <h6 style={{ marginLeft: "2em" }} hidden={!ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Technology], [Teams.Executive, Teams.Services])}>Software Billing</h6>
            </Col>
            <Col xs="1">
              <input type="checkbox" name="techTeamSetting" checked={this.state.settings.techTeamSetting}
                style={{ height: "1em", width: "1em" }} hidden readOnly />
              <br />
              <input type="checkbox" name="techKnowledgeBaseSetting" onChange={this.changeTechTeamSettings} checked={this.state.settings.techKnowledgeBaseSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="techTicketSubmissionSetting" onChange={this.changeTechTeamSettings} checked={this.state.settings.techTicketSubmissionSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} />
              <br />
              <input type="checkbox" name="techEquipmentSetting" onChange={this.changeTechTeamSettings} checked={this.state.settings.techEquipmentSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} hidden={!ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Technology], [Teams.Executive, Teams.Services])} />
              <br />
              <input type="checkbox" name="techTeamProjectsSetting" onChange={this.changeTechTeamSettings} checked={this.state.settings.techTeamProjectsSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} hidden={!ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Technology], [Teams.Executive, Teams.Services])} />
              <br />
              <input type="checkbox" name="techSoftwareBillingSetting" onChange={this.changeTechTeamSettings} checked={this.state.settings.techSoftwareBillingSetting}
                style={{ height: "1em", width: "1em", marginTop: "7%" }} hidden={!ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Technology], [Teams.Executive, Teams.Services])} />
              <br />
            </Col>
            <Col xs="3">

            </Col>
            <Col xs="1">

            </Col>
          </Row>
        </Row>
      </>
    )
  }
}
