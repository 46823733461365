import * as React from 'react';
import { KnowledgebaseCreationWidgetProps, KnowledgebaseCreationWidgetState } from './KnowledgebaseCreationWidget.constants';
import { Col, Row } from 'reactstrap';
import { getUserID } from '../../../functions/authActions';
import axios from 'axios';

export default class KnowledgebaseCreationWidget extends React.Component<KnowledgebaseCreationWidgetProps, KnowledgebaseCreationWidgetState> {
  constructor(props: KnowledgebaseCreationWidgetProps) {
    super(props);
    this.state = {
      title: "",
      articleURL: ""
    }
    this.updateTitle = this.updateTitle.bind(this);
    this.updateURL = this.updateURL.bind(this);
    this.updateArticle = this.updateArticle.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  updateTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    this.setState({ title: value })
  }

  updateURL(event: React.ChangeEvent<HTMLInputElement>): void {
    let value = event.target.value;
    value = value.replace("<iframe src=\"", "").replace("\"></iframe>", "")
      .replace("edit#", "pub")
      .replace("edit", "pub")
      .replace("view", "pub")
      .replace("?usp=sharing", "");
    if (value !== '') {
      value += "?embedded=true";
    }
    this.setState({
      articleURL: value
    })
  }

  clearForm(): void {
    this.setState({
      title: "",
      articleURL: ""
    })
  }

  async updateArticle(): Promise<void> {
    const data = {
      Title: this.state.title,
      ArticleURL: this.state.articleURL,
      AuthorUID: getUserID()
    }
    await axios.post("./api/knowledgeBase", data)

    this.setState({
      title: "",
      articleURL: ""
    })
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "69vh" }}>
        <Row>
          <Col><h4>Knowledgebase Article Creation</h4></Col>
        </Row>
        <hr />
        <Row>
          <Col md="3" style={{ marginRight: "4em" }}>
            <h4 style={{ marginTop: "1vh" }}>Title</h4>
            <input type="text" className="standard-input" value={this.state.title} onChange={this.updateTitle} />
            <h4 style={{ marginTop: "1vh" }}>URL</h4>
            <input type="text" className="standard-input" value={this.state.articleURL} onChange={this.updateURL} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <input type="button" value="Submit" className="standard-input" onClick={this.updateArticle} />
            <br />
            <br />
            <input type="button" value="Cancel" className="standard-input" style={{ marginBottom: "1em" }} onClick={this.clearForm} />
          </Col>
          <Col>
            <iframe src={this.state.articleURL} height="700" width="100%" style={{ marginTop: "1em" }} />
          </Col>
        </Row>
      </div>
    )
  }
}