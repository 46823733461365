import axios from 'axios';
import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullPrimaryCategories } from '../../../functions/fetchLinkedObjects';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';

interface TicketSecondaryCategoryModalState {
  show: boolean;
  name: string;
  primary: SelectOptions;
  primaries: Array<SelectOptions>;
}

export default class TicketSecondaryCategoriesModal extends React.Component<{}, TicketSecondaryCategoryModalState> {

  constructor(props: {}) {
    super(props);

    this.state = {
      show: false,
      name: "",
      primary: { label: "Select Primary", value: "" },
      primaries: []
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.save = this.save.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changePrimary = this.changePrimary.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let primarys = await pullPrimaryCategories();
    this.setState({
      primaries: primarys
    })
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  hide(): void {
    this.setState({
      show: false
    }, () => window.location.reload())
  }

  async changeName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ name: event.target.value });
  }

  async changePrimary(event: SelectOptions | null): Promise<void> {
    this.setState({ primary: event });
  }

  async save(): Promise<void> {
    const data = {
      name: this.state.name,
      primaryUID: this.state.primary.value
    }
    let response = await axios.post('./api/secondary-ticket-category', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.hide();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            New Secondary Category
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Name</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.name} className="standard-input" onChange={this.changeName} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Primary Category</h5>
              </Col>
              <Col>
                <Select
                  options={this.state.primaries}
                  value={this.state.primary}
                  styles={reactSelectBasicStyle}
                  onChange={this.changePrimary}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <input type="button" value="Save Secondary" className="standard-input" onClick={this.save} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}