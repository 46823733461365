import * as React from 'react';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { DatePicker } from './DateComponents';
import axios from 'axios';
import { FilterUsedOptions, RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { getUserID } from '../../functions/authActions';


/**
 * Defines the root values of the Modal Fields
 * Key: If using the default save, this needs to be the field the server uses
 * Name: Display Name
 * Patch URL: url for the patch function, use undefined if not needed
 */
export interface DataEntryModalField {
  key: string;
  name: string;
  patchUrl: string;
  tracked?: boolean;
}

/**
 * 
 * 
 */
export interface DataEntryModalFieldText extends DataEntryModalField {
  value: number | string;
  type: "text" | "number";
  placeholder: string;
  callback?: (event: React.ChangeEvent<HTMLInputElement>, data: Array<DataEntryModalField>) => void;
  validation?: typeof IsEmailValidation | typeof IsNotEmptyValidation;
}

/**
 * Creates a Select Options Data Field
 * 
 * Value: SelectOptions | SelectOptions[]
 * isMulti: boolean
 * isDisabled (Optional): boolean
 */
export interface DataEntryModalFieldSelect extends DataEntryModalField {
  options: Array<SelectOptions>;
  value: SelectOptions | SelectOptions[];
  isMulti: boolean;
  isDisabled?: true;
  callback?: (e: SelectOptions | SelectOptions[]) => void;
  validation?: typeof IsSetValidation;
}

/**
 * 
 * 
 */
export interface DataEntryModalFieldDate extends DataEntryModalField {
  value: Date;
  callback?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface DataEntryModalFieldCheckbox extends DataEntryModalField {
  checkbox: boolean;
  callback?: () => void;
}

export interface DataEntryModalFieldFile extends DataEntryModalField {
  fileKey: string;
  single: boolean;
  value: FileList;
  postUrl: string;
}

/**
 * Static is for values to be displayed but not to be changed, this field is used
 * to help keep data in view without being able to manage it. In addition this value
 * is not passed to the server during save or during updating
 */
export interface DataEntryModalFieldStatic extends DataEntryModalField {
  static: true;
  value: string;
}

/**
 * This field type is used to mask information in the modal that is required
 * for posting, but 
 * 
 */
export interface DataEntryModalFieldHidden extends DataEntryModalField {
  hidden: true;
  value: string;
}

/**
 *
 */
export interface DataEntryModalFieldFunction extends DataEntryModalField {
  function: () => void;
  value: string;
  disabled: boolean;
}

interface DataEntryModalState {
  data: Array<DataEntryModalField>;
  show: boolean;
  title: string;
  uid: string;
  postUrl: string;
  overrideSave?: (data: Array<DataEntryModalField>) => void;
  previousController: AbortController;
}

interface DataEntryModalProps {
  callback: (success: boolean) => void,
  overrideSave?: (data: Array<DataEntryModalField>) => void;
  overrideSaveText?: string
}

/**
 * The Data Entry Modal renders a standard modal that can render fields for data management with the server, please review
 * the interfaces for the field types in order 
 * 
 * It handles the following field types: Text / Number, Select, Date, Checkbox, Static, Hidden, Function
 * Each of the field types above describes it's unique uses in it's interface
 */
export default class DataEntryModal extends React.Component<DataEntryModalProps, DataEntryModalState> {

  constructor(props: DataEntryModalProps) {
    super(props);
    this.state = {
      data: [],
      show: false,
      title: "",
      uid: "",
      postUrl: "",
      overrideSave: props.overrideSave,
      previousController: null
    }
    this.updateSelectField = this.updateSelectField.bind(this);
    this.updateTextOrDatePickerField = this.updateTextOrDatePickerField.bind(this);
    this.updateCheckboxField = this.updateCheckboxField.bind(this);
    this.save = this.save.bind(this);
    this.hide = this.hide.bind(this);
  }

  /**
   * 
   * @param title
   * @param uid
   * @param postUrl
   */
  show(title: string, uid: string, postUrl: string): void {
    this.setState({
      uid: uid,
      title: title,
      show: true,
      postUrl: postUrl
    })
  }

  update(data: Array<DataEntryModalField>): void {
    this.setState({ data: data });
  }

  hide(): void {
    this.setState({ show: false });
  }

  async save(): Promise<void> {
    if (this.state.overrideSave) {
      this.hide();
      this.state.overrideSave(this.state.data);
      return;
    }
    if (this.state.uid !== "") {
      if (this.props.callback) {
        this.props.callback(true);
      }
      else {
        window.location.reload();
      }
      return;
    }
    let data: any = {};
    for (let key in this.state.data) {
      let item = this.state.data[key];
      if ('static' in item) {
        // do nothing
      }
      else if ('isMulti' in item) {
        let _item = item as DataEntryModalFieldSelect;
        data[item.key] = ('value' in _item.value) ? (_item.value as SelectOptions).value : _item.value.map(u => u.value);
      }
      else if ('type' in item) {
        let _item = item as DataEntryModalFieldText;
        data[item.key] = _item.value;
      }
      else if ('hidden' in item) {
        let _item = item as DataEntryModalFieldHidden;
        data[item.key] = _item.value;
      }
      else if ('checkbox' in item) {
        let _item = item as DataEntryModalFieldCheckbox;
        data[item.key] = _item.checkbox;
      }
      else if ('fileKey' in item) {
        let _item = item as DataEntryModalFieldFile;
        if (!(item.key in data)) {
          data[item.key] = new Array<string>();
        }
        let uids = await this.uploadFilesAndReturnKeys(_item);
        for (let uid in uids) {
          data[item.key].push(uids[uid]);
        }
      }
      else if ('function' in item) {
        // do nothing
      }
      else {
        let _item = item as DataEntryModalFieldDate;
        data[item.key] = _item.value.toISOString();
      }
    }
    let response = await axios.post(this.state.postUrl, data);
    this.hide();
    if (response.status >= 200 && response.status <= 202) {
      this.props.callback && this.props.callback(false);
    }
    else {
      console.error(response);
    }
  }

  async uploadFilesAndReturnKeys(field: DataEntryModalFieldFile): Promise<Array<string>> {
    let output = new Array<string>();
    if (field.value !== null) {
      for (let i = 0; i < field.value.length; ++i) {
        let data = new FormData();
        data.append('name', field.value[i].name);
        data.append('file', field.value[i]);
        let response = await axios.post(field.postUrl, data);
        output.push(response.data);
      }
    }
    return output;
  }

  async updateSelectField(event: SelectOptions | SelectOptions[] | null, field: DataEntryModalFieldSelect, idx: number): Promise<void> {
    if (event === null) { return; }
    field.value = event;
    let fields = this.state.data;
    fields[idx] = field;
    this.setState({ data: fields }, async () => {
      if (field.callback) {
        await field.callback(event)
      }
    });
    if (this.state.uid !== "" && field.patchUrl !== undefined) {
      if ('value' in event) {
        let data: any = { UID: this.state.uid, Value: event.value };
        if (field.tracked) {
          data['EmployeeUID'] = await getUserID(); 
        }
        let response = await axios.patch(field.patchUrl, data);
        if (response.status !== 202) {
          // TODO - Handle Error
        }
      }
      else {
        let data: any = { UID: this.state.uid, Values: [] }
        if (field.tracked) {
          data['EmployeeUID'] = await getUserID();
        }
        event.forEach((item) => {
          data.Values.push(item.value);
        })
        let response = await axios.patch(field.patchUrl, data);
        if (response.status !== 202) {
          // TODO - Handle Error
        }
      }
    }
  }

  async updateTextOrDatePickerField(event: React.ChangeEvent<HTMLInputElement>, field: DataEntryModalFieldText | DataEntryModalFieldDate, idx: number): Promise<void> {
    field.value = 'type' in field ? event.target.value : event.target.valueAsDate;
    let fields = this.state.data;
    fields[idx] = field;
    this.setState({ data: fields }, () => field.callback && field.callback(event, this.state.data));
    if (this.state.uid !== "" && field.patchUrl !== undefined) {
      let data: any = { UID: this.state.uid, value: ('type' in field ? (field.type === "number" ? parseFloat(event.target.value) : event.target.value) : event.target.valueAsDate.toISOString()) };
      if (field.tracked) {
        data['EmployeeUID'] = await getUserID();
      }
      let previousController = new AbortController();
      if (this.state.previousController !== null) { this.state.previousController.abort(); }
      this.setState({ previousController: previousController })
      let response = await axios.patch(field.patchUrl, data, { signal: previousController.signal});
      if (response.status !== 202) {
        // TODO - Handle Error
      }
    }
    else {
      field.callback && field.callback(event, this.state.data);
    }
  }

  async updateCheckboxField(event: React.ChangeEvent<HTMLInputElement>, field: DataEntryModalFieldCheckbox, idx: number): Promise<void> {
    field.checkbox = event.target.checked;
    let fields = this.state.data;
    fields[idx] = field;
    this.setState({ data: fields });
    if (this.state.uid !== "" && field.patchUrl !== undefined) {
      let data: any = { UID: this.state.uid, value: event.target.checked };
      if (field.tracked) {
        data['EmployeeUID'] = await getUserID();
      }
      let response = await axios.patch(field.patchUrl, data);
      if (response.status !== 202) {
        // TODO - Handle Error
      }
      else {
        field.callback && field.callback();
      }
    }
    else {
      field.callback && field.callback();
    }
  }

  async updateFileField(event: React.ChangeEvent<HTMLInputElement>, field: DataEntryModalFieldFile, idx: number) {
    if (field.single) {
      field.value = new FileList();
      field.value[0] = event.target.files[0];
    }
    else {
      field.value = event.target.files;
    }
    let fields = this.state.data;
    fields[idx] = field;
    this.setState({ data: fields });
    if (this.state.uid !== "" && field.patchUrl !== undefined) { }
  }

  async callFunction(field: DataEntryModalFieldFunction, idx: number) {
    field.disabled = true;
    let fields = this.state.data;
    fields[idx] = field;
    this.setState({ data: fields }, () => field.function());
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h4" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c", textAlign: "center" }}>
            {this.state.title}
          </ModalHeader>
        </div>
        <div key="modal-body" style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            {
              this.state.data.map((item: DataEntryModalField, idx: number) => {
                let field: JSX.Element;
                let active: Array<SelectOptions> = [];
                
                if ('isMulti' in item) {
                  let _item = item as DataEntryModalFieldSelect;
                  if ('value' in _item.value) {
                    active.push(_item.value)
                  }
                  else {
                    active.concat(_item.value)
                  }
                  field = <Select
                    key={_item.key}
                    options={FilterUsedOptions(_item.options, active)}
                    value={_item.value}
                    isMulti={_item.isMulti}
                    onChange={(e: SelectOptions | SelectOptions[]) => this.updateSelectField(e, _item, idx)}
                    styles={reactSelectBasicStyle}
                    isDisabled={_item.isDisabled === true}
                    onFocus={RemoveStickyOverlays}
                    onBlur={RestoreStickyOverlays}
                  />
                }
                else if ('fileKey' in item) {
                  let _item = item as DataEntryModalFieldFile;
                  let value = "";
                  if (_item.value !== null) {
                    for (let i = 0; i < _item.value.length; ++i) { value += _item.value[i].name + ","; }
                    value = value.substring(0, value.length - 1);
                  }
                  field = <>
                    <input type="button" className="standard-input" value="Upload File(s)" onClick={() => document.getElementById(_item.fileKey).click() } />
                    <input id={_item.fileKey} type="file" className="standard-input" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFileField(e, _item, idx)} hidden
                      multiple={!_item.single}
                    />
                    <label style={{fontSize: "8px"}}>{value}</label>
                  </>
                }
                else if ('hidden' in item) {
                  return <></>
                }
                else if ('type' in item) {
                  let _item = item as DataEntryModalFieldText;
                  field = <input key={_item.key} type={_item.type} value={_item.value} className="standard-input" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateTextOrDatePickerField(e, _item, idx)} placeholder={_item.placeholder}
                    style={{ textAlign: (_item.type === "number" ? "right" : "left") }} required={_item.validation ? _item.validation(_item.value) : false}
                  />
                }
                else if ('static' in item) {
                  let _item = item as DataEntryModalFieldStatic;
                  field = <h5 key={_item.key} style={{ marginTop: "7px", width: "100%", textAlign: "right" }}>{_item.value}</h5>
                }
                else if ('checkbox' in item) {
                  let _item = item as DataEntryModalFieldCheckbox;
                  field = <input key={_item.key} type="checkbox" checked={_item.checkbox} style={{ marginTop: "1vh", marginLeft: "45%" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateCheckboxField(e, _item, idx)}  />
                }
                else if ('function' in item) {
                  let _item = item as DataEntryModalFieldFunction;
                  field = <input key={_item.key} type="button" className="standard-input" value={_item.value} onClick={() => this.callFunction(_item, idx)} disabled={_item.disabled}  />
                }
                else {
                  let _item = item as DataEntryModalFieldDate;
                  field = <DatePicker key={_item.key} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateTextOrDatePickerField(e, _item, idx)} value={_item.value} style={{width: "100%", textAlign: "right"}}  />
                }
                return (
                  <>
                    <Row>
                      <Col xs='5'>
                        <h5 style={{ marginTop: "7px" }}>{item.name}</h5>
                      </Col>
                      <Col>
                        {
                          field
                        }
                      </Col>
                    </Row>
                    <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                  </>
                )
              })
            }
            <input type="button" value={this.props.overrideSaveText ? this.props.overrideSaveText : "Save"} className="standard-input" onClick={this.save} />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}


/**
 * Validates that the string or number input is not 'Empty'
 * Empty means that the value is either 0 for a number or "" for a string
 * Undefined & Null values are also defined as 'Empty'
 * @param val
 * @returns
 */
export function IsNotEmptyValidation(val: string | number): boolean {
  return !(val !== "" && val !== undefined && val !== null && val !== 0);
}

/**
 * 
 * @param val
 * @returns
 */
export function IsEmailValidation(val: string | number): boolean {
  if (typeof val === "number") { throw Error("Invalid Request for Email Validation. This shouldn't be a number"); }
  let reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return !reg.test(val);
}

/**
 * 
 * @param val
 * @returns
 */
export function IsPhoneNumberValidation(val: string | null): boolean {
  if (typeof val === "number") { throw Error("Invalid Request for Phone Validation. This shouldn't be a number"); }
  let reg = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  return !reg.test(val);
}

/**
 * 
 * @param val
 * @returns
 */
export function IsSetValidation(val: SelectOptions | SelectOptions[]): boolean {
  if ('value' in val) {
    return !(val.value !== "" && val.value !== undefined && val.value !== null);
  } else {
    return val.length > 0;
  }
}