import * as React from 'react';
import { RenderSettings } from '../../interfaces/WidgetSettingsInterfaces';
import { GetSettings } from '../../functions/StateManagement/StateStorageFunctions';
import { DashboardManagementModal, WidgetSelectOptions } from '../CoreComponents/Modals/DashboardManagementModal';
import { DashboardOrganizer, DefaultPageSettings } from '../../functions/WidgetDashboardOrganizer';
import axios from 'axios';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { Jobs, Teams, ValidateAccess, getBearerToken } from '../../functions/authActions';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import EntityAmortizationScheduleWidget from './EntityManagementWidgets/EntityAmortizationScheduleWidget';
import { pullEntitiesWithIndividuals } from '../../functions/fetchLinkedObjects';
import EntityPropertyOwnershipTableWidget from './EntityManagementWidgets/EntityPropertyOwnershipTableWidget';
import EntityOwnedByTableWidget from './EntityManagementWidgets/EntityOwnedByTableWidget';
import EntityBankManagementWidget from './EntityManagementWidgets/EntityBankAccountManagementWidget';
import BankManagementWidget from '../DashboardComponents/Widgets/BankManagementWidget';
import BankAccountEntityWorkflowRequests from '../DashboardComponents/Widgets/BankAccountWorkflowEntityRequestWidget';
import DocumentListWidget from '../CoreComponents/Widgets/DocumentListWidget';


interface DocumentData {
  uid: string;
  name: string;
  size: string;
}

interface EntityDetailDashboardState {
  uid: string;
  showModal: boolean;
  renderSettings: RenderSettings;
  entities: Array<SelectOptions>;
  entity: SelectOptions;
  widgetList: Array<SelectOptions>;
  tabList: { [key: number]: JSX.Element };
}

export default class EntityDetailDashboard extends React.Component<{}, EntityDetailDashboardState> {

  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  documentRef: React.RefObject<DocumentListWidget<DocumentData>> = React.createRef<DocumentListWidget<DocumentData>>();
  settingsString = "entityDashboard";

  constructor(props: {}) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal = false;
    if (settings == null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      uid: params.get('uid'),
      showModal: showModal,
      entities: [],
      renderSettings: settings,
      entity: { label: "Select Options", value: "" },
      tabList: {},
      widgetList: []
    }
    this.updateUI = this.updateUI.bind(this);
    this.updateEntity = this.updateEntity.bind(this);
    this.changeEntity = this.changeEntity.bind(this);
    this.pullDocumentsForEntity = this.pullDocumentsForEntity.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const entities = await pullEntitiesWithIndividuals();
    const entity = entities.filter(u => u.value === this.state.uid)[0]
    this.setState({ entities: entities, entity: entity });
    await this.updateEntity();
    this.documentRef.current.build(this.pullDocumentsForEntity);
  }

  async componentDidUpdate(): Promise<void> {
    this.documentRef.current?.build(this.pullDocumentsForEntity);
  }

  async updateEntity(): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      0: <EntityAmortizationScheduleWidget uid={this.state.uid} />,
      1: <EntityPropertyOwnershipTableWidget uid={this.state.uid} />,
      2: <EntityOwnedByTableWidget uid={this.state.uid} />,
      3: <EntityBankManagementWidget uid={this.state.uid} />,
      6: <DocumentListWidget ref={this.documentRef} title={"Entity Documents"} uploadURL={'api/entity-documents/' + this.state.uid} />,
      7: <DocumentListWidget title={"Entity Bank Documents"} uploadURL={'api/bank-account-entity-documents/' + this.state.uid}  />
    };
    let widgetList: Array<WidgetSelectOptions> = [
      { label: "Amortization Schedules", value: "0", group: "Amortizations" },
      { label: "Property Ownership", value: "1", group: "Ownership Information" },
      { label: "Entity Owned By", value: "2", group: "Ownership Information" },
      { label: "Bank Accounts", value: "3", group: "Bank Management" },
      { label: "Entity Documents", value: "6", group: "Document Management" },
      { label: "Entity Bank Documents", value: "7", group: "Bank Management"}
    ];
    if (ValidateAccess([Jobs.Technology_Lead, Jobs.CFO, Jobs.Controller], [Teams.Services, Teams.Executive])) {
      tabList[4] = <BankManagementWidget />;
      widgetList.push({ label: "Manage Banks", value: "4", group: "Bank Management" })

      tabList[5] = <BankAccountEntityWorkflowRequests uid={this.state.uid} />
      widgetList.push({ label: "Bank Account Requests", value: "5", group: "Bank Management" });
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    this.setState({ tabList: tabList, widgetList: widgetList });
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  async changeEntity(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({
      entity: event
    }, async () => await this.updateEntity());
  }

  async pullDocumentsForEntity(): Promise<Array<any>> {
    let response = await axios.get('./api/entity-documents/' + this.state.uid);
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw Error("Invalid Request Response")
    }
  }

  render(): JSX.Element {
    return (
      <div style={{ overflowX: "hidden", overflowY: "scroll", height: "90vh" }}>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "10vw", paddingTop: "2vh" }}>
            <Select
              options={this.state.entities}
              styles={reactSelectBasicStyle}
              value={this.state.entity}
              onChange={this.changeEntity}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "62vw" }}>
            <h5 style={{ textAlign: "center" }}>- Manage Entity -</h5>
            <h3 style={{ textAlign: "center" }}>{this.state.entity.label}</h3>
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", marginLeft: "2%", paddingTop: "2vh" }}>
            <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div>
          {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
        </div>
      </div>
    )
  }
}