import axios from 'axios';
import * as React from 'react';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { getUserID } from '../../functions/authActions';

interface KnowledgeBaseArticleRowProps {
  uid: string;
  title: string;
  author: string;
  updated: string;
}
interface KnowledgeBaseArticleRowState {
  uid: string;
  title: string;
  author: string;
  updated: string;
  canCreate: boolean;
}

class KnowledgeBaseArticleRow extends React.Component<KnowledgeBaseArticleRowProps, KnowledgeBaseArticleRowState> {
  constructor(props: KnowledgeBaseArticleRowProps) {
    super(props);
    this.state = {
      uid: props.uid,
      title: props.title,
      author: props.author,
      updated: props.updated,
      canCreate: getUserID() !== undefined
    }
    this.goToArticle = this.goToArticle.bind(this);
    this.goToArticleEditor = this.goToArticleEditor.bind(this);
  }

  componentDidMount(): void {
  }

  componentDidUpdate(): void {
  }

  async goToArticle(): Promise<void> {
    await axios.get("api/knowledgeBase/" + this.state.uid).then((response) => {
      window.open('https://docs.google.com/document/d/e/' + response.data.articleKey + '/pub', '_blank');
    })
  }

  goToArticleEditor(): void {
    window.location.assign("./knowledge-base-article-editor?articleUID=" + this.state.uid);
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.state.title}</td>
        <td className="fixedTableCell">{this.state.author}</td>
        <td className="fixedTableCell">{new Date(this.state.updated).toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' })}</td>
        <td className="fixedTableCell">
          <input type="button" value="View Article" className="standard-input" onClick={this.goToArticle} />
        </td>
        {
          this.state.canCreate && <td className="fixedTableCell">
            <input type="button" value="Edit Article" className="standard-input" onClick={this.goToArticleEditor} />
          </td>
        }
      </tr>
    )
  }
}

interface KnowledgeBaseSearchState {
  headers: Array<any>;
  articles: Array<any>;
  query: string;
  previousController: AbortController;
  canCreate: boolean;
}

export default class KnowledgeBaseSearch extends React.Component<{}, KnowledgeBaseSearchState> {

  constructor(props: {}) {
    super(props);
    let params = new URLSearchParams(window.location.search);
    this.state = {
      headers: [],
      articles: [],
      query: params.has('search') ? params.get('search') : "",
      previousController: null,
      canCreate: getUserID() !== undefined
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.updateSearchString = this.updateSearchString.bind(this);
    this.search = this.search.bind(this);

  }

  async componentDidMount(): Promise<void> {
    const self = this;
    if (this.state.query === "") {
      await axios.get('./api/knowledgeBase/articles/random').then(function (response) {
        const articles: Array<any> = [];
        const server: Array<any> = response.data;
        server.forEach(function (item) {
          articles.push({
            uid: item.uid,
            title: item.title,
            author: item.author.fullName,
            updated: item.lastUpdated
          })
        })
        self.setState({
          articles: articles
        }, self.generateHeaders)
      });
    }
    else {
      this.search();
    }
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Title',
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Author',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Last Updated',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      }
    ]
    if (this.state.canCreate) {
      headers.push({
        columnName: '',
        additionalClasses: "ten",
        setting: true,
        filter: {}
      });
    }
    this.setState({
      headers: headers
    })
    //#endregion
  }

  createArticle(): void {
    window.location.assign("./knowledge-base-article-editor");
  }

  async updateSearchString(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ query: event.target.value }, () => this.search())
  }

  async search(): Promise<void> {
    let previousController = new AbortController();
    try {
      if (this.state.query === "") { return; }
      if (this.state.previousController !== null) { this.state.previousController.abort(); }
      this.setState({ previousController: previousController })
      let response = await axios.get("./api/knowledgebase/search/" + this.state.query, { signal: previousController.signal, validateStatus: () => true });
      if (response.status === 200) {
        const articles: Array<any> = [];
        const server: Array<any> = response.data;
        server.forEach(function (item) {
          articles.push({
            uid: item.uid,
            title: item.title,
            author: item.author.fullName,
            updated: item.lastUpdated
          })
        })
        this.setState({ articles: articles }, this.generateHeaders)
      }
      else {
        // TODO: Show Error
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  render(): JSX.Element {
    return (
      <>
        <div style={{ marginLeft: "22%", width: "56%", marginTop: "7em", background: "#4C4A42", border: "5px solid #d7c7a7" }}>
          <h3 style={{ textAlign: "center", marginTop: "1em" }}>Knowledge Base Search</h3>
          <input
            type="text"
            className="standard-input"
            onKeyDown={async (e) => e.key === 'Enter' && await this.search()}
            style={{ width: "30%", marginLeft: "35%", marginTop: "1em", marginBottom: "1em" }}
            value={this.state.query}
            onChange={this.updateSearchString}
            placeholder="Search for Articles"
          />
          <div style={{ width: "90%", marginLeft: "5%", marginTop: "1em", maxHeight: "50vh", overflowX: "hidden", overflowY: "auto" }}>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.articles.map((item: any) => (
                    <KnowledgeBaseArticleRow key={item.uid} uid={item.uid} title={item.title} author={item.author} updated={item.updated} />
                  ))
                }
              </tbody>
            </table>
          </div>
          {this.state.canCreate && <input type="button" className="standard-input" value="Create New Article" onClick={this.createArticle}
            style={{ width: "60%", marginLeft: "20%", marginTop: "1em", marginBottom: "1em" }}
          />}
        </div>
      </>
    )
  }
}
