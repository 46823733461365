import * as React from "react";
import axios from 'axios';
import { CreateGlobalAlert } from "../../functions/CreateGlobalAlerts";
import { FilterAndSettingField } from "../CoreComponents/interfaces";
import FilteredWithSettingsTableHeader from "../CoreComponents/CoreTableHeaders";
import Select from "react-select";
import { reactSelectBasicStyle } from "../../style/select-constants";
import { SelectOptions } from "../../interfaces/CoreInterfaces";
import * as Structs from './Structs';

export class MeteringSettings extends React.Component<Structs.MeteringSettingsProps, Structs.MeteringSettingsState> {
  static displayName = MeteringSettings.name;

  constructor(props: Structs.MeteringSettingsProps) {
    super(props);
    this.state = {
      property: props.property,
      screener: props.screener,
      settings: props.settings,
      commercialRates: [],
      masterCommercialRate: { label: "Select Rate", value: "" },
      aggregateCommercialOnlyRate: { label: "Select Rate", value: "" },
      aggregateCommercialRate: { label: "Select Rate", value: "" },
      aggregateResidentialRate: { label: "Select Rate", value: "" },
      nemCommercialRate: { label: "Select Rate", value: "" },
      nemResidentialRate: { label: "Select Rate", value: "" },
      residentialRates: [],
      masterMeterCommercial: 0,
      aggregateCommercial: 0,
      aggregateResidential: 0,
      aggregateCommercialOnly: 0,
      NemCommercial: 0,
      NemResidential: 0,
      headers: this.generateHeaders(),
      masterMeterMonthlyFee: 0,
      aggregateMonthlyFee: 0,
      aggregateOnlyMonthlyFee: 0
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.calculate = this.calculate.bind(this);
    this.updateMasterMetering = this.updateMasterMetering.bind(this);
    this.updateMasterMeteringCount = this.updateMasterMeteringCount.bind(this);
    this.updateMasterMeteringRate = this.updateMasterMeteringRate.bind(this);
    this.updateAggregateMetering = this.updateAggregateMetering.bind(this);
    this.updateAggregateCommercialMeteringCount = this.updateAggregateCommercialMeteringCount.bind(this);
    this.updateAggregateCommercialMeteringRate = this.updateAggregateCommercialMeteringRate.bind(this);
    this.updateAggregateResidentialMeteringCount = this.updateAggregateResidentialMeteringCount.bind(this);
    this.updateAggregateResidentialMeteringRate = this.updateAggregateResidentialMeteringRate.bind(this);
    this.updateAggregateOnlyMetering = this.updateAggregateOnlyMetering.bind(this);
    this.updateAggregateCommercialOnlyMeteringCount = this.updateAggregateCommercialOnlyMeteringCount.bind(this);
    this.updateAggregateCommercialOnlyMeteringRate = this.updateAggregateCommercialOnlyMeteringRate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    await axios.get("./api/electric-utility-providers/property/" + this.state.property + "/active-only").then(function (response) {
      var resi: any = [];
      var comm: any = [];
      response.data.rates.forEach(function (item: any) {
        if (!item.isActive) {
          return;
        }
        if (item.type == 0) {
          resi.push({
            value: item.uid,
            label: item.name,
            consumption: item.weightedConsumptionCharge,
            demand: item.weightedDemandCharge,
            tax: item.totalTaxPercentage,
            flat: item.totalFlatCharges
          })
        }
        else {
          comm.push({
            value: item.uid,
            label: item.name,
            consumption: item.weightedConsumptionCharge,
            demand: item.weightedDemandCharge,
            tax: item.totalTaxPercentage,
            flat: item.totalFlatCharges
          })
        }
      })
      self.setState({
        commercialRates: comm,
        residentialRates: resi
      })
    }).catch(function (response) {
      CreateGlobalAlert(`Please set the Electric Provider for the utility, redirecting to Property Page`, 1500, () => window.location.assign("./manage-property?uid=" + self.state.property));
    })
    if (this.state.settings !== '') {
      await axios.get("./api/meter-settings/" + this.state.settings).then(function (response) {
        self.setState({
          masterMeterCommercial: response.data.masterCommercial,
          aggregateCommercial: response.data.aggregateCommercial,
          aggregateResidential: response.data.aggregateResidential,
          aggregateCommercialOnly: response.data.aggregateCommercialOnly,
          NemCommercial: response.data.tenantNEMCommercial,
          NemResidential: response.data.tenantNEMResidential,
          masterCommercialRate: { label: response.data.masterRateName, value: response.data.masterCommercialRateUID },
          aggregateCommercialRate: { label: response.data.aggregateCommercialRateName, value: response.data.aggregateCommercialRateUID },
          aggregateResidentialRate: { label: response.data.aggregateResidentialRateName, value: response.data.aggregateResidentialRateUID },
          aggregateCommercialOnlyRate: { label: response.data.aggregateCommercialOnlyRateName, value: response.data.aggregateCommercialOnlyRateUID },
          nemCommercialRate: { label: response.data.tenantNEMCommercialRateName, value: response.data.tenantNEMCommercialRateUID },
          nemResidentialRate: { label: response.data.tenantNEMResidentialRateName, value: response.data.tenantNEMResidentialRateUID }
        }, self.calculate)
      });
    }
  }

  calculate(): void {
    var masterMeters = this.state.masterCommercialRate;
    var masterMeterWithData = this.state.commercialRates.filter(u => u.value === masterMeters.value)[0];
    if (masterMeterWithData === undefined) {
      return;
    }
    var masterMeter = this.state.masterMeterCommercial * masterMeterWithData.flat;

    var aggComm = this.state.aggregateCommercialRate;
    var aggCommData = this.state.commercialRates.filter(u => u.value === aggComm.value)[0];
    var aggCommValue = this.state.aggregateCommercial * aggCommData.flat;

    var aggRes = this.state.aggregateResidentialRate;
    var aggResData = this.state.residentialRates.filter(u => u.value === aggRes.value)[0];
    var aggResValue = this.state.aggregateResidential * aggResData.flat;

    var aggCommOnly = this.state.aggregateCommercialOnlyRate;
    var aggCommOnlyData = this.state.commercialRates.filter(u => u.value === aggCommOnly.value)[0];
    var aggCommOnlyValue = this.state.aggregateCommercialOnly * aggCommOnlyData.flat;

    this.setState({
      masterMeterMonthlyFee: masterMeter,
      aggregateMonthlyFee: aggCommValue + aggResValue,
      aggregateOnlyMonthlyFee: aggCommOnlyValue
    })
  }

  submit(): void {
    const data = {
      SolarScreenerUID: this.state.screener,
      MasterCommercial: this.state.masterMeterCommercial,
      AggregateCommercial: this.state.aggregateCommercial,
      AggregateResidential: this.state.aggregateResidential,
      AggregateCommercialOnly: this.state.aggregateCommercialOnly,
      TenantNEMCommercial: this.state.NemCommercial,
      TenantNEMResidential: this.state.NemResidential,
      MasterCommercialRateUID: this.state.masterCommercialRate.value,
      AggregateCommercialRateUID: this.state.aggregateCommercialRate.value,
      AggregateResidentialRateUID: this.state.aggregateResidentialRate.value,
      AggregateCommercialOnlyRateUID: this.state.aggregateCommercialOnlyRate.value,
      TenantNEMCommercialRateUID: this.state.nemCommercialRate.value,
      TenantNEMResidentialRateUID: this.state.nemResidentialRate.value
    }
    if (this.state.settings !== '') {
      throw new Error("Invalid Put Request. Put has been disabled")
    }
    else {
      axios.post('./api/meter-settings', data).then(function (response) {
        CreateGlobalAlert(`Meter Settings have been created and saved`, 2000, () => window.location.reload());
      }).catch(function (response) {
        CreateGlobalAlert("Unable to Create Meter Settings. Please Make sure all Schedules have a value", 2000);
      })
    }
  }

  //#region Change Master Metering
  async updateMasterMeteringCount(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      masterMeterCommercial: parseInt(event.target.value)
    })
    await this.updateMasterMetering();
  }

  async updateMasterMeteringRate(event: Structs.RateSelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({
      masterCommercialRate: event
    });
    await this.updateMasterMetering()
  }

  async updateMasterMetering(): Promise<void> {
    const data = {
      MeteringUID: this.state.settings,
      MasterMeters: this.state.masterMeterCommercial,
      MasterRateUID: this.state.masterCommercialRate.value
    }
    const self = this;
    await axios.patch("/api/meter-settings/master-metering", data).then(function (response) {
      CreateGlobalAlert("Master Metering Settings hvae been updated", 2500);
      self.calculate();
    })
  }
  //#endregion

  //#region Change Aggregate Metering
  async updateAggregateCommercialMeteringCount(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      aggregateCommercial: parseInt(event.target.value)
    })
    await this.updateAggregateMetering();
  }

  async updateAggregateCommercialMeteringRate(event: Structs.RateSelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({
      aggregateCommercialRate: event
    });
    await this.updateAggregateMetering();
  }

  async updateAggregateResidentialMeteringCount(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      aggregateResidential: parseInt(event.target.value)
    })
    await this.updateAggregateMetering();
  }

  async updateAggregateResidentialMeteringRate(event: Structs.RateSelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({
      aggregateResidentialRate: event
    });
    await this.updateAggregateMetering();
  }

  async updateAggregateMetering(): Promise<void> {
    const data = {
      MeteringUID: this.state.settings,
      AggregateCommercialMeters: this.state.aggregateCommercial,
      AggregateCommercialRateUID: this.state.aggregateCommercialRate.value,
      AggregateResidentialMeters: this.state.aggregateResidential,
      AggregateResidentialRateUID: this.state.aggregateResidentialRate.value
    }
    const self = this;
    await axios.patch("/api/meter-settings/aggregate-metering", data).then(function (response) {
      CreateGlobalAlert("Aggregate Metering Settings hvae been updated", 2500);
      self.calculate();
    })
  }
  //#endregion

  //#region Change Aggregate Only Metering
  async updateAggregateCommercialOnlyMeteringCount(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      aggregateCommercialOnly: parseInt(event.target.value)
    })
    await this.updateAggregateMetering();
  }

  async updateAggregateCommercialOnlyMeteringRate(event: Structs.RateSelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({
      aggregateCommercialOnlyRate: event
    });
    await this.updateAggregateMetering();
  }

  async updateAggregateOnlyMetering(): Promise<void> {
    const data = {
      MeteringUID: this.state.settings,
      AggregateCommercialOnlyMeters: this.state.aggregateCommercialOnly,
      AggregateCommercialOnlyRateUID: this.state.aggregateCommercialOnlyRate.value,
    }
    const self = this;
    await axios.patch("/api/meter-settings/aggregate-only-metering", data).then(function (response) {
      CreateGlobalAlert("Aggregate Only Metering Settings have been updated", 2500);
      self.calculate();
    })
  }
  //#endregion

  handleScheduleChange(event: SelectOptions | null, name: string): void {
    if (event == null) {
      return;
    }
    //@ts-ignore
    this.setState({ [name]: event })
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    //var value;
    //if (event.target.type === "checkbox") {
    //  value = event.target.checked;
    //}
    //else {
    //  value = event.target.type === "number" || (event.target.type === "select-one" && event.target.value === parseInt(event.target.value, 10))
    //    ? parseInt(event.target.value) : event.target.value;
    //}
    ////@ts-ignore
    //this.setState({ [event.target.name]: value }, this.calculate);
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Metering Type",
        setting: true,
        filter: {}
      },
      {
        columnName: "Number of Commercial Meters",
        setting: true,
        filter: {}
      },
      {
        columnName: "Commercial Schedule",
        setting: true,
        filter: {}
      },
      {
        columnName: "Number of Residential Meters",
        setting: true,
        filter: {}
      },
      {
        columnName: "Residential Schedule",
        setting: true,
        filter: {}
      },
      {
        columnName: "Meter Fees (Monthly)",
        setting: true,
        filter: {}
      },
      {
        columnName: "Meter Fees (Annual)",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div id="metering-settings" style={{ marginLeft: "-15px" }}>
        <div id="table-container" style={{ minHeight: "82vh", maxHeight: "82vh" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              <tr>
                <td className="fixedTableCellFirst">Master</td>
                <td className="fixedTableCell">
                  <input className="standard-input" type="number" name="masterMeterCommercial" id="master-meter-commercial-meters"
                    placeholder="1" value={this.state.masterMeterCommercial} onChange={this.updateMasterMeteringCount} />
                </td>
                <td className="fixedTableCell">
                  <Select
                    options={this.state.commercialRates}
                    value={this.state.masterCommercialRate}
                    styles={reactSelectBasicStyle}
                    onChange={this.updateMasterMeteringRate}
                  />
                </td>
                <td className="fixedTableCell">0</td>
                <td className="fixedTableCell">
                  N/A
                </td>
                <td id="master-meter-meter-fees" className="fixedTableCell">${this.state.masterMeterMonthlyFee}</td>
                <td id="master-meter-meter-fees-annual" className="fixedTableCell">${this.state.masterMeterMonthlyFee * 12}</td>
              </tr>
              <tr>
                <td className="fixedTableCellFirst">Aggregate</td>
                <td className="fixedTableCell"><input type="number" className="standard-input" name="aggregateCommercial"
                  id="aggregate-commercial-meters" placeholder="1" value={this.state.aggregateCommercial} onChange={this.updateAggregateCommercialMeteringCount} /></td>
                <td className="fixedTableCell">
                  <Select
                    options={this.state.commercialRates}
                    value={this.state.aggregateCommercialRate}
                    styles={reactSelectBasicStyle}
                    onChange={this.updateAggregateCommercialMeteringRate}
                  />
                </td>
                <td className="fixedTableCell"><input className="standard-input" type="number" id="aggregate-residential-meters" name="aggregateResidential"
                  placeholder="36" value={this.state.aggregateResidential} onChange={this.updateAggregateResidentialMeteringCount} /></td>
                <td className="fixedTableCell">
                  <Select
                    options={this.state.residentialRates}
                    value={this.state.aggregateResidentialRate}
                    styles={reactSelectBasicStyle}
                    onChange={this.updateAggregateResidentialMeteringRate}
                  />
                </td>
                <td id="aggregate-meter-fees" className="fixedTableCell">${this.state.aggregateMonthlyFee}</td>
                <td id="aggregate-meter-fees-annual" className="fixedTableCell">${this.state.aggregateMonthlyFee * 12}</td>
              </tr>
              <tr>
                <td className="fixedTableCellFirst">Aggregate - Commercial Only</td>
                <td className="fixedTableCell"><input className="standard-input" type="number" name="aggregateCommercialOnly"
                  id="aggregate-commercial-only-meters" placeholder="1" value={this.state.aggregateCommercialOnly} onChange={this.updateAggregateCommercialOnlyMeteringCount} /></td>
                <td className="fixedTableCell">
                  <Select
                    options={this.state.commercialRates}
                    value={this.state.aggregateCommercialOnlyRate}
                    styles={reactSelectBasicStyle}
                    onChange={this.updateAggregateCommercialOnlyMeteringRate}
                  />
                </td>
                <td className="fixedTableCell">0</td>
                <td className="fixedTableCell">
                  N/A
                </td>
                <td id="aggregate-only-meter-fees" className="fixedTableCell">${this.state.aggregateOnlyMonthlyFee}</td>
                <td id="aggregate-only-meter-fees-annual" className="fixedTableCell">${this.state.aggregateOnlyMonthlyFee * 12}</td>
              </tr>
              <tr>
                <td className="fixedTableCellFirst">Tenant NEM</td>
                <td className="fixedTableCell"><input className="standard-input" type="number" id="tenant-nem-residential" name="NemCommercial" placeholder="1" value={this.state.NemCommercial} onChange={this.handleInputChange} /></td>
                <td className="fixedTableCell">
                  <Select
                    options={this.state.commercialRates}
                    value={this.state.nemCommercialRate}
                    styles={reactSelectBasicStyle}
                    onChange={(e: SelectOptions) => this.handleScheduleChange(e, "nemCommercialRate")}
                  />
                </td>
                <td className="fixedTableCell"><input type="number" className="standard-input" id="tenant-nem-residential" name="NemResidential" placeholder="36" value={this.state.NemResidential} onChange={this.handleInputChange} /></td>
                <td className="fixedTableCell">
                  <Select
                    options={this.state.residentialRates}
                    value={this.state.nemResidentialRate}
                    styles={reactSelectBasicStyle}
                    onChange={(e: SelectOptions) => this.handleScheduleChange(e, "nemResidentialRate")}
                  />
                </td>
                <td id="nem-meter-fees" className="fixedTableCell">$0.00</td>
                <td id="nem-meter-fees-annual" className="fixedTableCell">$0.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        {this.state.settings === '' && <input type="button" value="Save Meter Settings" style={{ width: "100%" }} onClick={this.submit} />}
      </div>
    )
  }
}