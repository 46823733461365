import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { AssignedTicketsWidget } from '../Widgets/AssignedTicketsWidget';
import { UsefulLinksWidget } from '../../CoreComponents/UsefulLinksWidget';

export class PropertyAccountantDashboard extends React.Component<{}, {}> {

  constructor(props: {}) {
    super(props);
    this.state = {

    }
  }

  render(): JSX.Element {
    return (
      <div style={{ width: "101%", marginLeft: "-1%", height: "80vh", background: "#4C4A42", border: "2px solid #d7c7a7" }}>
        <h4 style={{ width: "100%", textAlign: "center" }}>Property Accounting Dashboard</h4>
        <Row>
          <Col>
            <AssignedTicketsWidget />
          </Col>
          <Col>
            
          </Col>
        </Row>
        <hr style={{ height: "5px", marginLeft: "1%", marginRight: "1%" }} />
        <Row>
          <Col style={{ marginLeft: "1%" }}>
            <UsefulLinksWidget /> 
          </Col>
          <Col>
          </Col>
          <Col>
          </Col>
          <Col style={{ marginRight: "1%" }}>
          </Col>
        </Row>
      </div>
    )
  }
}