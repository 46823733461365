import * as React from 'react';
import ComputerConfigurationCreationModal from './ComputerConfigurationManagementWidget.modals';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { WarningModal } from '../CoreComponents/Modals';
import axios from 'axios';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface ComputerConfigurationSummary{
  uid: string;
  brand: string;
  cpu: string;
  name: string;
  os: string;
  ramSize: string;
  storageSize: string;
}
interface ComputerConfigurationManagementWidgetState
{
  headers: Array<FilterAndSettingField>;
  data: Array<ComputerConfigurationSummary>;
}

export default class ComputerConfigurationManagementWidget extends React.Component<{}, ComputerConfigurationManagementWidgetState> {

  configurationModal: React.RefObject<ComputerConfigurationCreationModal> = React.createRef<ComputerConfigurationCreationModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  container: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  constructor(props: {}) {
    super(props);

    this.state = {
      headers: [],
      data: []
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    let response = await axios.get('./api/computer-configuration-tracking', { validateStatus: () => true });
    if (response.status === 200) {
      let data: Array<ComputerConfigurationSummary> = [];
      response.data.forEach((item: any) => {
        data.push({
          uid: item.uid,
          name: item.name,
          brand: item.computerBrand,
          os: item.operatingSystem,
          cpu: item.cpu,
          ramSize: item.ramSize,
          storageSize: item.storageSize
        })
      })
      this.setState({ data: data });
    }
    else {
      this.warningModal.current.show("There was an error loading data. Please reach out to the tech team to let them know.", "Error");
    }
  }

  async generateHeaders(): Promise<void> {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Brand",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      }
    ]
    if (cols < 4) {
      headers.push({
        columnName: "CPU",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      })
    }
    if (cols == 2) {
      headers.push({
        columnName: "Ram Size",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      })
      headers.push({
        columnName: "Storage",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      })
    }
    headers.push({
      columnName: "OS",
      setting: true,
      additionalClasses: "fifteen",
      filter: {}
    })
    headers.push({
      columnName: "",
      setting: true,
      additionalClasses: "ten",
      filter: {}
    })
    this.setState({ headers: headers });
  }

  callback(): void {
    this.configurationModal.current.hide();
    this.warningModal.current.show("Configuration has been created. It can now be used to tie to computers", "Config Created");
  }

  getRow(item: ComputerConfigurationSummary): JSX.Element {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    return (
      <tr key={item.uid}>
        <td className="fixedTableCellFirst">{item.name}</td>
        <td className="fixedTableCell">{item.brand}</td>
        {cols < 4 && <td className="fixedTableCell">{item.cpu}</td>}
        {cols === 2 && <td className="fixedTableCell">{item.ramSize}</td>}
        {cols === 2 && <td className="fixedTableCell">{item.storageSize}</td>}
        <td className="fixedTableCell">{item.os}</td>
        <td className="fixedTableCell">
          <input type="button" value="View" className="standard-input" />
        </td>
      </tr>
    )
  }

  render(): JSX.Element {
    return (
      <div ref={this.container}>
        <WarningModal ref={this.warningModal} />
        <ComputerConfigurationCreationModal ref={this.configurationModal} callback={this.callback} />
        {RenderHeader("Computer Configurations")}
        <div style={{ height: "25vh", overflowY: "scroll" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  this.getRow(item)
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create" className="standard-input" onClick={() => this.configurationModal.current.show()} />
      </div>
    )
  }
}