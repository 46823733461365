import * as React from 'react';
import { pullEntitiesWithIndividuals } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { getUserID } from '../../../functions/authActions';
import axios from 'axios';

interface FinancialsRequestModalState {
  show: boolean;
  entities: Array<SelectOptions>;
  entityList: Array<SelectOptions>;
  reasons: Array<SelectOptions>;
  additionalReasons: string;
  otherInformation: string;
  errorMessage: string;
}

export default class FinancialsRequestModal extends React.Component<{}, FinancialsRequestModalState> {

  REASONS: Array<SelectOptions> = [
    { label: "Underwriting", value: "1" },
    { label: "Other", value: "0" }
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      entities: [],
      entityList: [],
      show: false,
      reasons: [],
      additionalReasons: "",
      otherInformation: "",
      errorMessage: ""
    }
    this.changeEntity = this.changeEntity.bind(this);
    this.changeReasons = this.changeReasons.bind(this);
    this.changeAdditionalReasons = this.changeAdditionalReasons.bind(this);
    this.changeOtherInformation = this.changeOtherInformation.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let entities = await pullEntitiesWithIndividuals();
    this.setState({ entities: entities });
  }

  show(): void {
    this.setState({ show: true });
  }

  hide(): void {
    this.setState({ show: false });
  }

  changeEntity(event: SelectOptions[] | null): void {
    if (event === null) { return; }
    this.setState({ entityList: event });
  }

  changeReasons(event: SelectOptions[] | null): void {
    if (event === null) { return; }
    this.setState({ reasons: event });
  }

  changeAdditionalReasons(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ additionalReasons: event.target.value });
  }

  changeOtherInformation(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ otherInformation: event.target.value });
  }

  async submit(): Promise<void> {
    const data = {
      createdBy: getUserID(),
      entities: this.state.entityList.map(u => u.label).join("<br />"),
      reasons: this.state.reasons.map(u => u.label).join("<br />"),
      additionalReasons: this.state.additionalReasons,
      otherInformation: this.state.otherInformation
    }
    let response = await axios.post('./api/tickets/financial-request', data, { validateStatus: () => true });
    if (response.status !== 202) {
      this.setState({ errorMessage: "Error Submitting Request. Please reach out to the tech team who can assist you with getting the error resolved." });
    }
    else {
      window.location.reload();
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
          <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
            <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
              Request Financial Documents
            </ModalHeader>
          </div>
          <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
            <ModalBody style={{ border: "0px solid #15405c" }}>
              {this.state.errorMessage !== "" &&
                <>
                  <Row>
                    <Col>
                      {this.state.errorMessage}
                    </Col>
                  </Row>
                  <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                </>
              }
              <Row>
                <Col>
                  <h5 style={{ marginTop: "7px" }}>Entity List</h5>
                </Col>
                <Col>
                  <Select
                    options={this.state.entities}
                    value={this.state.entityList}
                    styles={reactSelectBasicStyle}
                    onChange={this.changeEntity}
                    isMulti
                  />
                </Col>
              </Row>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              <Row>
                <Col>
                  <h5 style={{ marginTop: "7px" }}>Reason for Request</h5>
                </Col>
                <Col>
                  <Select
                    options={this.REASONS}
                    value={this.state.reasons}
                    styles={reactSelectBasicStyle}
                    onChange={this.changeReasons}
                    isMulti
                  />
                </Col>
              </Row>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              {this.state.reasons.filter(u => u.value === "0").length > 0 &&
                <>
                  <Row>
                    <Col>
                      <h5 style={{ marginTop: "7px" }}>Additional Reasons</h5>
                    </Col>
                    <Col>
                      <input type="text" value={this.state.additionalReasons} onChange={this.changeAdditionalReasons} className="standard-input" />
                    </Col>
                  </Row>
                  <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
                </>
              }
              <Row>
                <Col>
                  <h5 style={{ marginTop: "7px" }}>Other Information</h5>
                </Col>
                <Col>
                  <input type="text" value={this.state.otherInformation} onChange={this.changeOtherInformation} className="standard-input" />
                </Col>
              </Row>
              <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              <input type="button" className="standard-input" value="Submit" onClick={this.submit} />
            </ModalBody>
          </div>
        </Modal>
      </>
    )
  }
}