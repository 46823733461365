//#region Imports
import * as React from 'react';
import axios from 'axios';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { pullEntities } from '../../../functions/fetchLinkedObjects';
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';
import { OwnerRow, NewOwnerRow } from './TableRows';
import { EntityStructureOwnershipData, EntityStructureOwnershipFromDB, EntityStructureTableData, EntityOwnedByStructureOwnershipFromDB, ManagedAssetByStructureOwnershipFromDB } from '../Structs';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { Col, Row } from 'reactstrap';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { DownloadNamedFile } from '../../../functions/documentTools';
//#endregion

export class EntityStructureTable extends React.Component<Object, EntityStructureTableData> {

  constructor(props: Object) {
    super(props);
    this.state = {
      owners: [],
      ownedBy: [],
      creating: false,
      assets: [],
      assetId: '',
      headers: this.generateHeaders()
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.addRow = this.addRow.bind(this);
    this.saveRow = this.saveRow.bind(this);
    this.changeEntityId = this.changeEntityId.bind(this);
    this.delete = this.delete.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const entities = await pullEntities();
    this.setState({
      assets: entities
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Entity Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Percentage Owned",
        setting: true,
        filter: {}
      },
      {
        columnName: "Ownership Type",
        setting: true,
        filter: {}
      },
      {
        columnName: "Managing Member",
        setting: true,
        filter: {}
      }
    ]
  }

  //#region Functions

  addRow(): void {
    if (this.state.assetId === '') {
      return;
    }
    this.setState({
      creating: true
    })
  }

  async saveRow(name: string, percent: number, id: string, type: SelectOptions, isManagingMember: boolean): Promise<void> {
    const rows = this.state.owners;
    const data = {
      EntityId: this.state.assetId,
      OwnerEntityUID: id,
      PercentageOwned: percent,
      IsManagingMember: isManagingMember,
      OwnershipType: type.value
    }
    const self = this;
    await axios.post("./api/entity-structure", data).then(function (response) {
      CreateGlobalAlert("Successfully added Owner..", 1200);
      rows.push({
        name: name,
        percent: percent,
        id: id,
        isManagingMember: isManagingMember,
        type: parseInt(type.value),
        key: response.data.UID
      })
      self.setState({
        creating: false,
        owners: rows,
      })
    });

  }

  async changeEntityId(event: any): Promise<void> {
    const self = this;
    await axios.get("./api/entity-structure/" + event.value).then(function (response) {
      const rows: Array<EntityStructureOwnershipData> = [];
      response.data.forEach(function (item: EntityStructureOwnershipFromDB) {
        rows.push({
          key: item.uid,
          name: item.ownerEntity.name,
          percent: item.percentageOwned,
          id: item.ownerEntityId,
          isManagingMember: item.isManagingMember,
          type: item.ownershipType
        })
      })
      self.setState({
        owners: rows
      })
    });
    await axios.get("./api/entity-structure/" + event.value + "/owner").then(function (response) {
      const rows: Array<EntityStructureOwnershipData> = []
      response.data.forEach(function (item: EntityOwnedByStructureOwnershipFromDB) {
        rows.push({
          key: item.uid,
          name: item.entity.name,
          percent: item.percentageOwned,
          id: item.entityId,
          isManagingMember: item.isManagingMember,
          type: item.ownershipType
        })
      })
      self.setState({
        ownedBy: rows
      })
    });
    this.setState({
      "assetId": event.value,
      creating: false
    })
  }

  delete(uid: string): void {
    const self = this;
    axios.delete("./api/entity-structure/" + uid).then(function (response) {
      CreateGlobalAlert("Owner has been removed", 1500);
      const entity = self.state.owners.filter(item => item.key === uid)[0];
      const index = self.state.owners.findIndex(_ => _.key === entity.key);
      const entities = self.state.owners;
      entities.splice(index, 1);
      self.setState({
        owners: entities
      })
    });
  }

  async requestRoot(): Promise<void> {
    if (this.state.assetId === null) { return; }
    let response = await axios.get("/api/entity-structure/tree/" + this.state.assetId + "/true/", { responseType: 'blob' });
    await DownloadNamedFile(response);
  }

  render(): JSX.Element {
    return (
      <div>
        <h3>Entity Ownership Structures</h3>
        <Select
          options={this.state.assets}
          styles={reactSelectBasicStyle}
          onChange={this.changeEntityId}
          onBlur={RestoreStickyOverlays}
          onFocus={RemoveStickyOverlays}
        />
        <hr />
        <h5>Entities that own this Entity</h5>
        <div style={{ overflow: "auto", height: this.state.creating ? "66vh" : "33vh" }}>
          <table id="entity-structure" className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              {
                this.state.owners.map((item) => (
                  <OwnerRow uid={item.key} key={item.key} name={item.name} percent={item.percent} type={item.type ? item.type : 0}
                    isManagingMember={item.isManagingMember} deleteRow={this.delete} canArchive={true}
                  />
                ))
              }
              {!this.state.creating ? <TableAdditionRow length={5} onClick={this.addRow} marginLeft="35%" /> : <NewOwnerRow onClick={this.saveRow} />}
            </tbody>
          </table>
        </div>
        {!this.state.creating &&
          <>
            <h5>Entities that this Entity owns</h5>
            <div style={{ overflow: "auto", height: "33vh" }}>
              <table id="entity-structure" className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
                </thead>
                <tbody>
                  {
                    this.state.ownedBy.map((item) => (
                      <OwnerRow uid={item.id} key={item.key} name={item.name} percent={item.percent}
                        isManagingMember={item.isManagingMember} canArchive={false} type={item.type ? item.type : 0}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        }
        <br />
        <Row>
          <Col>
            <input type="button" className="standard-input" value="Get Ownership Structure Diagram - Root Only"
              onClick={() => this.requestRoot()} style={{ zIndex: 1 }} />
          </Col>
          <Col>
            <input type="button" className="standard-input" value="Get Ownership Structure Diagram - Full Structure" />
          </Col>
        </Row>
      </div>
    )
  }
}