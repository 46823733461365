import * as React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface TableRowProps {
  length: number;
  marginLeft: string;
  onClick: () => void;
}

export interface TableRowState {
  items: Array<string>;
  length: number;
  marginLeft: string;
}

export class TableAdditionRow extends React.Component<TableRowProps, TableRowState> {

  constructor(props: TableRowProps) {
    super(props);
    this.state = {
      length: props.length,
      items: [],
      marginLeft: props.marginLeft !== undefined ? props.marginLeft : "35%"
    }
  }

  async componentDidMount(): Promise<void> {
    const items = this.state.items;
    for (let i = 0; i < this.state.length; ++i) {
      items.push("");
    }
    this.setState({
      items: items
    })
  }

  render(): JSX.Element {
    return (
      <tr>
        {
          this.state.items.map((item, idx) => (
            idx === 0 ? <td key={idx} className="fixedTableCellFirst"></td> : (idx === this.state.items.length - 1) ?
              <td key={idx} className="fixedTableCell"><FontAwesomeIcon icon={faPlus} className="fa-icon" style={{ marginLeft: this.state.marginLeft, cursor: "pointer", color: "#b89961" }} onClick={this.props.onClick} /></td>
              : <td key={idx} className="fixedTableCell"></td>
          ))
        }
      </tr>
    )
  }
}