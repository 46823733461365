import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { Col, Row } from 'reactstrap';
import GoogleBillingUploadModal, { GoogleBillingUploadData } from './GoogleWorkspaceBillingProcessing.modals';
import axios from 'axios';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import { DownloadNamedFile } from '../../../functions/documentTools';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface GoogleWorkspaceBillingProcessingState {
  headers: Array<FilterAndSettingField>;
}

export default class GoogleWorkspaceBillingProcessing extends React.Component<{}, GoogleWorkspaceBillingProcessingState> {

  uploadModal: React.RefObject<GoogleBillingUploadModal> = React.createRef<GoogleBillingUploadModal>();
  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      headers: []
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async generateHeaders(): Promise<void> {
    let headers: Array<FilterAndSettingField> = [];
    headers.push({ columnName: "Bill Date", setting: true, filter: {} });
    headers.push({ columnName: "Emails", setting: true, filter: {} });
    headers.push({ columnName: "Unit Cost", setting: true, filter: {} });
    headers.push({ columnName: "Total", setting: true, filter: {} });
    headers.push({ columnName: "Output", setting: true, filter: {} });
    this.setState({ headers: headers });
  }

  async callback(data: GoogleBillingUploadData): Promise<void> {
    let key = await axios.get('./api/status-manager');
    let formInfo = new FormData();
    formInfo.append('BillingDate', data.billingDate.toISOString());
    formInfo.append('CSVData', data.csvFile);
    formInfo.append('AccountCharge', data.googleLicenseCost.toFixed(2))
    formInfo.append('StatusKey', key.data);
    this.statusModal.current.display("Processing Google Bill", "Currently Processing the google bill into uploads. This may take a minute.", key.data);    
    let response = await axios.post('./api/software-billing-tracking/google', formInfo, {
      responseType: 'blob',
      validateStatus: () => true,
      timeout: 240000,
      timeoutErrorMessage: "Generating Google Files took longer than 4 minutes, please contact the tech department"
    });
    this.statusModal.current.hide();
    if (response.status !== 200) {
      console.log(response);
      this.warningModal.current.show(
        "There was an error processing the google bill. Please contact the tech team so that they can investigate the concern: " + await response.data.text(),
        "Error.");
    }
    else {
      await DownloadNamedFile(response);
    }
  }

  render(): JSX.Element {
    return (
      <>
        <StatusModal ref={this.statusModal} />
        <WarningModal ref={this.warningModal} />
        <GoogleBillingUploadModal ref={this.uploadModal} callback={this.callback} />
        {RenderHeader("Google Workspace Billing Processing")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>

            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Run Google Billing Processing" className="standard-input" onClick={() => this.uploadModal.current.show()} />
          </Col>
          <Col>
            <input type="button" value="View Documentation" className="standard-input" onClick={() => window.location.assign('./knowledge-base-home?search=Google%20Workspace%20Billing')} />
          </Col>
        </Row>
      </>
    )
  }
}