import * as React from 'react';
import { faTrash, faSave, faTimes, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Structs from '../Structs';
import { calculateWithPrevious, calculateWithoutPrevious } from './CalculateFunctions';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { DatePicker } from '../../CoreComponents/DateComponents';

export const LineItemRow = (props: {
  row: Structs.AmortizationLineItem;
  index: number;
  editRow: (index: number) => void;
  removeRow: (index: number) => void;
  termLimit: number;
}) => {
  return (
    <tr style={{ backgroundColor: (props.row.isRateChange ? "#4C4A42" : "") }}>
      <td className="fixedTableCellFirst">
        {new Date(props.row.date).toLocaleDateString('en-US')} {props.row.isRateChange && <em style={{ color: "#EC0C34" }}>({props.row.newInterest}%)</em>}
      </td>
      <td className="fixedTableCell">
        {props.row.days}
      </td>
      <td className="fixedTableCell">
        {props.row.advance !== undefined && props.row.advance > 0 ? numberWithCommas(props.row.advance) : ''}
      </td>
      <td className="fixedTableCell">
        {props.row.p_balance > 0 ? numberWithCommas(props.row.p_balance) : ''}
      </td>
      {props.termLimit !== -1 &&
        <td className="fixedTableCell">{numberWithCommas(props.row.monthlyPayment)}</td>
      }
      <td className="fixedTableCell">
        {props.row.p_payment > 0 ? numberWithCommas(props.row.p_payment + props.row.i_payment) : ''}
      </td>
      <td className="fixedTableCell">
        {props.row.p_payment > 0 ? numberWithCommas(props.row.p_payment) : ''}
      </td>
      <td className="fixedTableCell">
        {props.row.i_payment > 0 ? numberWithCommas(props.row.i_payment) : ''}
      </td>
      <td className="fixedTableCell">
        {(props.row.interestAccumulated > 0) ? numberWithCommas(props.row.interestAccumulated) : ''}
      </td>
      <td className="fixedTableCell">
        {(props.row.i_balance > 0) ? numberWithCommas(props.row.i_balance) : ''}
      </td>
      <td className="fixedTableCell">
        {props.row.p_balance > 0 ? numberWithCommas(props.row.p_balance) : ''}
      </td>
      <td className="fixedTableCell">
        {(props.row.uid !== "" && props.row.uid !== EMPTY_GUID) && <FontAwesomeIcon icon={faCog} style={{ marginLeft: "4%" }} onClick={() => props.editRow(props.index)} />}
      </td>
      <td className="fixedTableCell no-print">
        {(props.row.uid !== "" && props.row.uid !== EMPTY_GUID && props.index !== 0 && props.row.date > new Date(Date.now())) && <FontAwesomeIcon icon={faTrash} style={{ marginLeft: "14%" }} onClick={() => props.removeRow(props.index)} />}
      </td>
    </tr>
  )
}

export class EditableLineItemRow extends React.Component<Structs.EditableLineState, Structs.EditableLineState> {

  constructor(props: Structs.EditableLineState) {
    super(props);
    this.state = {
      row: {
        uid: EMPTY_GUID,
        date: props.row.date ? props.row.date : new Date(),
        days: props.row.days ? props.row.days : 0,
        interestAccumulated: props.row.interestAccumulated ? props.row.interestAccumulated : 0,
        advance: props.row.advance ? props.row.advance : 0,
        i_payment: props.row.i_payment ? props.row.i_payment : 0,
        i_balance: props.row.i_balance ? props.row.i_balance : 0,
        p_payment: props.row.p_payment ? props.row.p_payment : 0,
        p_balance: props.row.p_balance ? props.row.p_balance : 0,
        index: props.row.index,
        previous: props.previous ? props.previous : undefined,
        monthlyPayment: 0,
        isRateChange: false,
        newInterest: 0,

      },
      funcs: {
        save: props.funcs.save,
        cancel: props.funcs.cancel
      },
      rate: props.rate,
      previous: props.previous,
      termLimit: props.termLimit,
      compoundType: props.compoundType,
      calculationMethod: props.calculationMethod,
      loanStartDate: props.loanStartDate
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
    this.calculate = this.calculate.bind(this);
    this.calculateMonthlyPayment = this.calculateMonthlyPayment.bind(this);
  }

  async componentDidMount(): Promise<void> {
    // for the first row
    if (this.state.row.previous === null) {
      const row = this.state.row;
      row.days = 0;
      row.interestAccumulated = 0;
      row.i_balance = 0;
      this.setState({
        row: row
      }, this.calculate);
    }
    else {
      this.calculate();
    }
  }

  //#region Functions

  handleDateChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const row = this.state.row;
    row.date = new Date(event.target.valueAsDate) === null ? new Date(1, 0, 1) : new Date(event.target.valueAsDate);
    this.setState({
      row: row
    }, this.calculate);
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const row: any = this.state.row;
    row[event.target.name] = parseFloat(event.target.value);
    this.setState({
      row: row
    }, this.calculate)
  }

  save(): void {
    const newRow: Structs.AmortizationLineItem = {
      uid: EMPTY_GUID,
      date: this.state.row.date,
      days: this.state.row.days,
      interestAccumulated: this.state.row.interestAccumulated,
      advance: this.state.row.advance,
      i_payment: this.state.row.i_payment,
      i_balance: this.state.row.i_balance,
      p_payment: this.state.row.p_payment,
      p_balance: this.state.row.p_balance,
      index: this.state.row.index,
      monthlyPayment: this.state.row.monthlyPayment,
      previous: this.state.previous,
      isRateChange: false,
      newInterest: 0
    }
    this.state.funcs.save(newRow, false);
  }

  calculate(): void {
    if (this.state.row.previous !== null && this.state.row.previous !== undefined) {
      const data = calculateWithPrevious(this.state.row.previous, this.state.row.date, this.state.rate,
        this.state.row.advance, this.state.row.p_payment, this.state.row.i_payment, this.state.compoundType, this.state.calculationMethod);
      const row = this.state.row;

      row.p_balance = data?.p_balance;
      row.days = data?.days;
      row.interestAccumulated = data?.interest;
      row.i_balance = data?.i_balance;
      this.setState({ row: row }, this.calculateMonthlyPayment);
    }
    else {
      const data = calculateWithoutPrevious(this.state.row.advance, this.state.rate, this.state.row.i_payment, this.state.calculationMethod);
      const row = this.state.row;
      row.p_balance = data.p_balance;
      row.days = data.days;
      row.interestAccumulated = data.interest;
      row.i_balance = data.i_balance;
      this.setState({ row: row }, this.calculateMonthlyPayment);
    }
  }

  calculateMonthlyPayment(): void {
    const row = this.state.row;
    //row.monthlyPayment = calculateMonthlyPayment(this.state.row.p_balance, this.state.term, this.state.rate);
    this.setState({
      row: row
    });
  }

  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <DatePicker className="standard-input" onChange={this.handleDateChange} value={this.state.row.date} />
        </td>
        <td className="fixedTableCell">
          {this.state.row.days}
        </td>
        <td className="fixedTableCell">
          <input name="advance" className="standard-input" type="number" value={this.state.row.advance} onChange={(e) => this.handleInputChange(e)} />
        </td>
        <td className="fixedTableCell">
          <input name="p_payment" className="standard-input" type="number" value={this.state.row.p_payment} onChange={this.handleInputChange} />
        </td>
        <td className="fixedTableCell">
          {this.state.row.p_balance?.toFixed(2)}
        </td>
        <td className="fixedTableCell">
          {this.state.row.interestAccumulated?.toFixed(2)}
        </td>
        <td className="fixedTableCell">
          <input name="i_payment" className="standard-input" type="number" value={this.state.row.i_payment} onChange={this.handleInputChange} />
        </td>
        <td className="fixedTableCell">
          {this.state.row.i_balance?.toFixed(2)}
        </td>
        {this.state.termLimit !== -1 &&
          <td className="fixedTableCell">{this.state.row.monthlyPayment.toFixed(2)}</td>
        }
        <td className="fixedTableCell no-print">
          <FontAwesomeIcon icon={faTimes} style={{ marginLeft: "22%" }} onClick={this.state.funcs.cancel} />
        </td>
        <td className="fixedTableCell no-print">
          <FontAwesomeIcon icon={faSave} style={{ marginLeft: "20%" }} onClick={this.save} />
        </td>
      </tr>
    )
  }
}

export class RateChange extends React.Component<Structs.RateChangeProperties, Structs.RateChangeState> {

  constructor(props: Structs.RateChangeProperties) {
    super(props);
    this.state = {
      date: new Date(),
      previous: props.previous,
      days: 0,
      interest: 0,
      i_balance: 0,
      index: props.index,
      newInterest: 0,
      rate: props.currentInterestRate,
      termLimit: props.termLimit,
      save: props.funcs.save,
      cancel: props.funcs.cancel,
      compoundType: this.state.compoundType,
      calculationMethod: this.state.calculationMethod,
      loanStartDate: props.loanStartDate
    }
    this.handleDateChange = this.handleDateChange.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.calculate = this.calculate.bind(this);
    this.save = this.save.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.calculate();
  }

  //#region Functions

  handleDateChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      date: new Date(event.target.valueAsDate) === null ? new Date(1, 0, 1) : new Date(event.target.valueAsDate)
    }, this.calculate);
  }

  changeInput(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      newInterest: parseFloat(event.target.value)
    })
  }

  calculate(): void {
    if (this.state.previous === undefined) {
      console.error("There should a definition to previous");
      return;
    }
    const data = calculateWithPrevious(this.state.previous, this.state.date, this.state.rate, 0, 0, 0, this.state.compoundType, this.state.calculationMethod);
    this.setState({
      days: data.days,
      interest: data.interest,
      i_balance: data.i_balance
    });
  }

  save(): void {
    const newRow: Structs.AmortizationLineItem = {
      uid: EMPTY_GUID,
      date: this.state.date,
      days: this.state.days,
      interestAccumulated: this.state.interest,
      advance: 0,
      i_payment: 0,
      i_balance: this.state.i_balance,
      p_payment: 0,
      p_balance: this.state.previous.p_balance,
      index: this.state.index,
      monthlyPayment: 0,
      isRateChange: true,
      newInterest: this.state.newInterest,
      previous: undefined
    }
    this.state.save(newRow, true);
  }

  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <DatePicker className="standard-input" onChange={this.handleDateChange} value={this.state.date} />
        </td>
        <td className="fixedTableCell" rowSpan={1}>
          {this.state.days}
        </td>
        <td className="fixedTableCell" rowSpan={1}>
          {this.state.interest}
        </td>
        <td className="fixedTableCell" rowSpan={1} colSpan={this.state.termLimit === -1 ? 5 : 6}>
          Next Rate: <input type="number" className="standard-input" style={{ width: "90%", textAlign: "right" }}
            name="newInterest" value={this.state.newInterest} onChange={this.changeInput}
          />
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faTimes} style={{ marginLeft: "22%" }} onClick={this.state.cancel} />
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faSave} style={{ marginLeft: "20%" }} onClick={this.save} />
        </td>
      </tr>
    )
  }

}