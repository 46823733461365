import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import axios from 'axios';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { PropertyAllocationsWidgetState } from './PropertyAllocationsWidget.constants';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface PropertyAllocationsWidgetProps {
  uid: string;
}

export class PropertyAllocationsWidget extends React.Component<PropertyAllocationsWidgetProps, PropertyAllocationsWidgetState> {

  headers: Array<FilterAndSettingField> = [
    { columnName: "Name", setting: true, filter: {} },
    { columnName: "Role", setting: true, filter: {} },
    { columnName: "%", setting: true, filter: {} }
  ]

  constructor(props: PropertyAllocationsWidgetProps) {
    super(props);
    this.state = {
      propertyUID: props.uid,
      rows: []
    }
  }

  async componentDidMount(): Promise<void> {
    let response = await axios.get('./api/property-employee-map/property/' + this.state.propertyUID, { validateStatus: () => true });
    if (response.status === 200) {
      this.setState({
        rows: response.data
      })
    }
  }

  render(): JSX.Element {
    return (
      <>
        {RenderHeader("Payroll Allocations")}
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.rows.map((item) => (
                <tr key={item.uid}>
                  <td className="fixedTableCellFirst">{item.fullName}</td>
                  <td className="fixedTableCellFirst">{item.role}</td>
                  <td className="fixedTableCellFirst">{item.allocation}%</td>
                </tr> 
              ))
            }
          </tbody>
        </table>
      </>
    )
  }
}
