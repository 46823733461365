import * as React from 'react';
import { getUserID } from '../../../functions/authActions';
import axios from 'axios';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';

interface RegionalAllocationData {
  name: string;
  property: string;
  allocation: number;
  role: string;
}

interface RegionalManagerRegionAllocationState {
  data: Array<RegionalAllocationData>;
  headers: Array<FilterAndSettingField>;
}

export default class RegionalManagerRegionAllocations extends React.Component<{}, RegionalManagerRegionAllocationState>{

  constructor(props: {}) {
    super(props);
    this.state = {
      data: [],
      headers: []
    }
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    let response = await axios.get('./api/property-employee-map/region/' + getUserID());
    console.log(response.data);
    this.setState({
      data: response.data
    })
  }

  async generateHeaders(): Promise<void> {
    let headers: Array<FilterAndSettingField> = [];
    headers.push({ columnName: "Name", setting: true, filter: {} });
    headers.push({ columnName: "Property", setting: true, filter: {} });
    headers.push({ columnName: "Allocation", setting: true, filter: {} });
    headers.push({ columnName: "Role", setting: true, filter: {} });
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <>
        {RenderHeader("Region Allocations")}
        <div>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item: RegionalAllocationData) => (
                  <tr key={item.name + item.property + item.role}>
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{item.property}</td>
                    <td className="fixedTableCell">{item.allocation}</td>
                    <td className="fixedTableCell">{item.role}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </>
    )
  }
}