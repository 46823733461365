import * as React from 'react'
import { CNAOwnersNarrativeWidgetProps, CNAOwnersNarrativeWidgetState } from './CNAOwnersNarrativeWidget.constants';
import { FileEntryModal, StatusModal, WarningModal } from '../../../CoreComponents/Modals';
import axios from 'axios';
import { getUserID } from '../../../../functions/authActions';

export default class CNAOwnersNarrativeWidget extends React.Component<CNAOwnersNarrativeWidgetProps, CNAOwnersNarrativeWidgetState> {

  statusRef = React.createRef<StatusModal>();
  warningRef = React.createRef<WarningModal>();
  fileRef = React.createRef<FileEntryModal>();

  constructor(props: CNAOwnersNarrativeWidgetProps) {
    super(props);
    this.state = {
      cnaUID: props.cnaUID,
      narrativeUID: null,
      filePath: ""
    }
    this.uploadNarrative = this.uploadNarrative.bind(this)
  }

  async componentDidMount(): Promise<void> {
    const narrativeResponse = await axios.get('./api/capital-needs-assessment/narrative/' + this.state.cnaUID)

    if (narrativeResponse.status === 200) {
      this.setState({ narrativeUID: narrativeResponse.data.ownerNarrativeDocumentUID })
    }
    else {
      //TODO ERROR HANDLE
    }

    if (this.state.narrativeUID !== null) {
      const documentResponse = await axios.get('./api/capital-needs-assessment/narrative/get-file-path/' + this.state.cnaUID, { responseType: 'blob' })
      if (documentResponse.status === 200) {
        this.setState({ filePath: URL.createObjectURL(documentResponse.data) })
      }
      else {
        //TODO ERROR HANDLE
      }
    }
  }

  async uploadNarrative(): Promise<void> {
    this.statusRef.current.display("Uploading Narrative", "Please Wait")

    const data = new FormData();
    data.append("inputFile", this.fileRef.current.state.file);
    const narrativeResponse = await axios.patch('./api/capital-needs-assessment/narrative/' + this.state.cnaUID, data)
    if (narrativeResponse.status === 200) {
      this.setState({ narrativeUID: narrativeResponse.data.ownerNarrativeDocumentUID })
    }
    else {
      //TODO ERROR HANDLE
    }

    const changeData = {
      CNAUID: this.state.cnaUID,
      FleetEmployeeUID: await getUserID(),
      OldData: "",
      NewData: "Narrartive has been changed"
    }

    await axios.post('./api/cna-change-log', changeData)

    if (this.state.narrativeUID !== null) {
      const documentResponse = await axios.get('./api/capital-needs-assessment/narrative/get-file-path/' + this.state.cnaUID, { responseType: 'blob' })
      if (documentResponse.status === 200) {
        this.setState({ filePath: URL.createObjectURL(documentResponse.data) })
      }
      else {
        //TODO ERROR HANDLE
      }
    }

    this.statusRef.current.hide()
  }

  render(): JSX.Element {
    return (
      <div>
        <FileEntryModal ref={this.fileRef} />
        <StatusModal ref={this.statusRef} />
        {
          this.state.narrativeUID === null ?
            <div>
              <h3 style={{ textAlign: "center" }}>Please Upload Narrative</h3>
              <input type="button" className="standard-input" style={{ width: "50%", marginLeft: "25%" }} value="Upload" onClick={() => this.fileRef.current.show("Upload Owners Narrartive", ".doc, .docx", this.uploadNarrative,)} />
            </div>
            :
            <div style={{ width: "100%" }}>
              <embed src={this.state.filePath} style={{ minHeight: "70vh", width: "100%" }} />
            </div>
        }
      </div>
    )
  }
}