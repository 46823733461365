//#region Imports
import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { EmployeeCompany } from '../../constants/EnumConstants';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { ManageDistributionRecordsModal, ManageEntityEmployeeModal, ManagePropertyEmployeeModal } from './DistributionRecordCollector.modals';
import * as Structs from './Structs';
import { StatusModal } from '../CoreComponents/Modals';
import { pullProperties } from '../../functions/fetchLinkedObjects';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { DownloadNamedFile } from '../../functions/documentTools';
import { Jobs, Teams, ValidateAccess } from '../../functions/authActions';
//#endregion

export function getCompany(company: string): number {
  let _company: number;
  switch (company.toLocaleLowerCase()) {
    case "abc":
      _company = EmployeeCompany.ABC;
      break;
    case "cdi":
      _company = EmployeeCompany.CDI;
      break;
    //case "vcl":
    //  _company = EmployeeCompany.VCL;
    //  break;
    case "vmi":
      _company = EmployeeCompany.VMI;
      break;
    //case "mci":
    //  _company = EmployeeCompany.MCI;
    //  break;
    case "vcon":
      _company = EmployeeCompany.VCON;
      break;
    default:
      throw new Error(`Invalid Company Distribution: ${company} If you are receiving this error, please contact Johnny Green`);
  }
  return _company;
}

class DistributionRecord extends React.Component<Structs.DistributionRecordProps, Structs.DistributionRecordState> {

  constructor(props: Structs.DistributionRecordProps) {
    super(props);
    this.state = {
      item: props.item,
      index: props.index
    }
  }

  render(): JSX.Element {
    return (
      <tr key={this.state.item.uid}>
        <td className="fixedTableCellFirst">{this.state.item.employee.label} </td>
        <td className="fixedTableCell">{this.state.item.payrollId} </td>
        <td className="fixedTableCell">{this.state.item.company}</td>
        <td className="fixedTableCell">{this.state.item.jobCode}</td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>{this.state.item.distribution}%</td>
        <td className="fixedTableCell">{this.state.item.allocationCode}</td>
        <td className="fixedTableCell">
          <input type="button" value="Edit" className="standard-input" onClick={() => this.props.edit(this.state.item.uid)} />
        </td>
      </tr>
    )
  }
}

export default class DistributionRecordCollector extends React.Component<Structs.DistributionRecordCollectorProps, Structs.DistributionRecordCollectorState> {

  statusModal = React.createRef<StatusModal>();
  managementModal: React.RefObject<ManagePropertyEmployeeModal> = React.createRef<ManagePropertyEmployeeModal>();
  entityModal: React.RefObject<ManageEntityEmployeeModal> = React.createRef<ManageEntityEmployeeModal>();
  distModals: React.RefObject<ManageDistributionRecordsModal> = React.createRef<ManageDistributionRecordsModal>();

  static AllocationRoles = {
    "-1": "N/A",
    "0": "MT",
    "1": "ASM",
    "2": "SM",
    "3": "RM",
    "4": "FS",
    "5": "AP",
    "6": "AR",
    "7": "CS",
  }

  static AllocationRolesSelect = [
    { label: "N/A", value: "-1" },
    { value: "0", label: "MT" },
    { value: "1", label: "ASM" },
    { value: "2", label: "SM" },
    { value: "3", label: "RM" },
    { value: "4", label: "FS" },
    { value: "5", label: "AP" },
    { value: "6", label: "AR" },
    { value: "7", label: "CS" }
  ]

  constructor(props: Structs.DistributionRecordCollectorProps) {
    super(props);
    let params = new URLSearchParams(window.location.search);
    let company = params.get('company');
    this.state = {
      company: company.toLocaleLowerCase(),
      headers: [],
      isEditing: false,
      saved: false,
      currentIndex: -1,
      rows: [],
      error: false,
      errorMessage: "",
      saving: false,
      employee: "",
      property: "",
      role: DistributionRecordCollector.AllocationRolesSelect[0]
    }
    this.edit = this.edit.bind(this);
    this.checkRows = this.checkRows.bind(this);
    this.addEmployee = this.addEmployee.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
    this.exportAllocations = this.exportAllocations.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let isValid = await ValidateAccess([Jobs.Technology_Lead, Jobs.HR, Jobs.Technology ], [Teams.Services])
    if (!isValid) {
      window.location.replace("/");
    }
    let properties = await pullProperties();
    this.generateHeaders();
  }

  async updateRecords(): Promise<void> {
    let companyValue = getCompany(this.state.company);
    let data = {
      Page: 0,
      PageCount: 0,
      Employee: this.state.employee,
      Role: -1,
      Property: ""
    }
    if (companyValue == EmployeeCompany.VMI) {
      data.Role = parseInt(this.state.role.value);
      data.Property = this.state.property;
      let response = await axios.post("./api/distribution-records/vmi/filter", data, { validateStatus: () => true });
      let rows: Array<any> = [];
      response.data.forEach((item: any) => {
        rows.push({
          uid: item.uid,
          employee: item.employee ? { label: item.employee, value: item.employeeUID } : { label: item.empireEmployee, value: item.empireEmployeeUID },
          jobCode: item.code,
          distribution: item.distribution,
          company: this.state.company,
          payrollId: item.payrollID,
          //@ts-ignore
          allocationCode: DistributionRecordCollector.AllocationRoles[item.role.toString()]
        });
      })
      this.setState({
        rows: rows
      }, () => this.checkRows());
    }
    else if (companyValue === EmployeeCompany.CDI) {
      let response = await axios.post('./api/distribution-records/' + companyValue + "/filter", data, { validateStatus: () => true });
      let rows: Array<any> = [];
      response.data.forEach((item: any) => {
        rows.push({
          uid: item.uid,
          employee: { label: item.employee, value: item.employeeUID },
          jobCode: item.code,
          distribution: item.distribution,
          company: this.state.company,
          payrollId: item.payrollID,
          allocationCode: ""
        });
      })
      this.setState({
        rows: rows
      }, () => this.checkRows());
    }
    else {
      let response = await axios.post('./api/distribution-records/' + companyValue + "/filter", data, { validateStatus: () => true });
      let rows: Array<any> = [];
      response.data.forEach((item: any) => {
        rows.push({
          uid: item.uid,
          employee: { label: item.employee, value: item.employeeUID },
          jobCode: item.code,
          distribution: item.distribution,
          company: this.state.company,
          payrollId: item.payrollID,
          allocationCode: item.jobCode
        });
      })
      this.setState({
        rows: rows
      }, () => this.checkRows());
    }
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Employee",
        setting: true,
        filter: {
          value: this.state.employee,
          changeFilter: this.changeEmployee,
          type: "text",
          placeHolder: "Search for Employee"
        }
      },
      {
        columnName: "Payroll ID",
        setting: true,
        filter: {}
      },
      {
        columnName: "Company",
        setting: true,
        filter: {}
      },
      {
        columnName: "Job Code",
        setting: true,
        filter: {
          placeholder: "Filter by Property Name Or Code",
          value: this.state.property,
          changeFilter: this.changeProperty,
          type: "text"
        },
        additionalClasses: "fifteen"
      },
      {
        columnName: "Distribution",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Role",
        setting: true,
        additionalClasses: "ten",
        filter: {
          options: DistributionRecordCollector.AllocationRolesSelect,
          value: this.state.role,
          changeFilter: this.changeRole,
          multi: false
        }
      },
      {
        columnName: "",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      }
    ]
    this.setState({
      headers: headers
    }, () => this.updateRecords())
  }

  async changeEmployee(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      employee: event.target.value
    }, () => this.generateHeaders())
  }

  async changeProperty(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      property: event.target.value
    }, () => this.generateHeaders())
  }

  async changeRole(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({
      role: event
    }, () => this.generateHeaders())
  }

  async exportAllocations(): Promise<void> {
    this.statusModal.current.display("Exporting Allocations.", "Please wait while we prepare the allocations for export");
    let response = await axios.get('./api/payroll/vmi-allocations', { validateStatus: () => true });
    if (response.status === 200) {
      await DownloadNamedFile(response);
      this.statusModal.current.hide();
    }
  }

  edit(uid: string): void {
    var employee = this.state.rows.filter(u => u.uid === uid)[0];
    switch (getCompany(this.state.company)) {
      case EmployeeCompany.VMI:
        this.managementModal.current.show(employee.employee.label, employee.employee.value, employee.payrollId, getCompany(this.state.company));
        break;
      case EmployeeCompany.CDI:
        this.entityModal.current.show(employee.employee.label, employee.employee.value, employee.payrollId, getCompany(this.state.company));
        break;
      case EmployeeCompany.ABC:
        this.distModals.current.show(employee.employee.label, employee.employee.value, employee.payrollId, getCompany(this.state.company));
        break;
    }
  }

  checkRows(): void {
    let rows = this.state.rows;
    let errStr = "Invalid Distributions for the following People:";
    let err: boolean = false;
    var employeeIDs: Array<any> = [];
    for (let i = 0; i < rows.length; ++i) {
      if (!employeeIDs.includes(rows[i].employee.value)) {
        employeeIDs.push(rows[i].employee.value)
      }
    }
    employeeIDs.forEach(function (id: any) {
      let values = rows.filter((u: any) => u.employee.value === id);
      let total = 0;
      values.forEach(function (data: any) {
        total += data.distribution;
      });
      if (total != 100) {
        errStr += " " + values[0].employee.label + " (" + total + "%),";
        err = true
      }
    })
    this.setState({
      errorMessage: errStr.substring(0, errStr.length - 1),
      error: err
    })
  }

  addEmployee(): void {
    switch (getCompany(this.state.company)) {
      case EmployeeCompany.VMI:
        this.managementModal.current.show("", "", 0, getCompany(this.state.company));
        break;
      case EmployeeCompany.CDI:
        this.entityModal.current.show("", "", 0, getCompany(this.state.company));
        break;
      case EmployeeCompany.ABC:
        this.distModals.current.show("", "", 0, getCompany(this.state.company));
        break;
    }
  }

  render(): JSX.Element {
    return (
      <div>
        {this.state.error && <h5 style={{ color: "#ff9999" }}>{this.state.errorMessage}</h5>}
        <StatusModal ref={this.statusModal} />
        <ManagePropertyEmployeeModal ref={this.managementModal} />
        <ManageEntityEmployeeModal ref={this.entityModal} />
        <ManageDistributionRecordsModal ref={this.distModals} />
        <div style={{ height: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: any, idx: number) => (
                  <DistributionRecord key={item.uid} item={item} edit={this.edit} index={idx} />
                ))
              }
            </tbody>
          </table>
        </div>
        <Row style={{ marginTop: "1%" }}>
          {!this.state.saving &&
            <>
              <Col>
                <input type="button" value="Add Employee" className="standard-input" style={{ marginLeft: "1%" }} onClick={this.addEmployee} />
              </Col>
              <Col>
                <input type="button" value="Export" className="standard-input" onClick={this.exportAllocations} />
              </Col>
            </>
          }
        </Row>
      </div>
    )
  }
}

