//#region Imports
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
//@ts-ignore
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { pullEntities } from '../../../../functions/fetchLinkedObjects';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../../functions/selectTools';
import { SelectOptions } from '../../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../../style/select-constants';
import { CheckboxFieldProps } from '../../../CoreComponents/interfaces';
//#endregion

export interface MemberDataTabProps {
  uid: string;
  checkValid: (checkedValue: boolean) => void;
}

export interface MemberDataTabState {
  uid: string;
  managementType: number;
  members: Array<EntityMember>;
  isCapitalValid: boolean;
  isAnnualValid: boolean;
  totalCapitalPercentage: number;
  totalAnnualPercentage: number;
  totalContributions: number;
  currentShareholder: number;
  entities: Array<SelectOptions>;
  missing: string;
}

export interface EntityMember {
  entity: SelectOptions;
  capitalPercentage: number;
  annualPercentage: number;
  isManager: boolean;
}

export class EntityMemberInformation extends React.Component<MemberDataTabProps, MemberDataTabState>{

  constructor(props: MemberDataTabProps) {
    super(props);
    this.state = {
      uid: props.uid,
      managementType: -1,
      members: [{
        entity: { label: "Select Entity", value: "-1" },
        capitalPercentage: 0,
        annualPercentage: 0,
        isManager: false
      }],
      totalCapitalPercentage: 0,
      totalAnnualPercentage: 0,
      totalContributions: 2000,
      isCapitalValid: true,
      isAnnualValid: true,
      currentShareholder: 0,
      entities: [],
      missing: "Missing"
    }
    this.checkPercentages = this.checkPercentages.bind(this);

    this.addMember = this.addMember.bind(this);
    this.changeShareholder = this.changeShareholder.bind(this);
    this.changeCapitalPercentage = this.changeCapitalPercentage.bind(this);
    this.changeAnnualPercentage = this.changeAnnualPercentage.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.makeManager = this.makeManager.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const entities = await pullEntities(true);
    this.setState({ entities: entities });

    if (this.state.uid !== '') {
      await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
        const members: any = [];

        if (response.data.newEntityMembers.length > 0) {
          response.data.newEntityMembers.forEach((item: any) => {
            item.entity = { label: entities.filter(u => u.value === item.entityUID)[0].label, value: item.entityUID }
            members.push({
              entity: item.entity,
              totalContributions: item.initalCapital,
              capitalPercentage: item.capitalOwnershipPercentage,
              annualPercentage: item.annualOwnershipPercentage,
              isManager: item.isManager
            })
          })
        } else {
          members.push({
            entity: { label: "Select Entity", value: "-1" },
            totalContributions: 2000,
            capitalPercentage: 0,
            annualPercentage: 0
          })
        }

        this.setState({
          members: members,
          managementType: response.data.entityManagementType
        })
      })
    }

    this.checkPercentages(this.state.members);
  }

  async componentDidUpdate(): Promise<void> {

  }

  checkPercentages(_members: any): void {
    const members = _members
    let capitalValid = this.state.isCapitalValid;
    let annualValid = this.state.isAnnualValid;
    let capitalCheck = 0;
    let annualCheck = 0;
    let check = false;

    members.forEach(function (item: any) {
      capitalCheck += parseInt(item.capitalPercentage);
      annualCheck += parseInt(item.annualPercentage);
    })

    if (capitalCheck === 100) {
      capitalValid = true
    } else {
      capitalValid = false
    }

    if (annualCheck === 100) {
      annualValid = true
    } else {
      annualValid = false
    }
    if (capitalValid && annualValid) { check = true }
    else { check = false }

    let count = 0;
    this.state.members.forEach((member: EntityMember) => {
      count += (member.capitalPercentage / 100) * this.state.totalContributions;
    })
    if (count - this.state.totalContributions < 0) {
      this.setState({ missing: "Missing" })
    }
    else if (count - this.state.totalContributions === 0) {
      this.setState({ missing: "Perfect" })
    }
    else if (count - this.state.totalContributions > 0) {
      this.setState({ missing: "Overage" })
    }

    this.setState({
      totalCapitalPercentage: capitalCheck,
      isCapitalValid: capitalValid,
      totalAnnualPercentage: annualCheck,
      isAnnualValid: annualValid
    }, () => this.props.checkValid(check))
  }

  addMember(): void {
    const members = this.state.members;
    const current = members.length;
    members.push({
      entity: { label: "Select Entity", value: "-1" },
      capitalPercentage: 0,
      annualPercentage: 0,
      isManager: false
    })

    this.setState({
      currentShareholder: current,
      members: members
    })

    this.checkPercentages(this.state.members);
  }

  async changeShareholder(event: SelectOptions | null): Promise<void> {
    const members = this.state.members;
    members[this.state.currentShareholder].entity = event;
    this.setState({ members: members })

    this.checkPercentages(this.state.members);

    const data = {
      NewEntityUID: this.state.uid,
      EntityUID: event.value,
      CapitalOwnershipPercentage: this.state.members[this.state.currentShareholder].capitalPercentage,
      AnnualOwnershipPercentage: this.state.members[this.state.currentShareholder].annualPercentage
    }

    await axios.post("./api/new-entity-request/add-new-member", data).then((response) => {
      CreateGlobalAlert("New Member Added", 2500);
    })
  }

  async changeCapitalPercentage(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const members = this.state.members;
    let value = event.target.value;

    if (event.target.value === "") {
      value = "0";
    }
    members[this.state.currentShareholder].capitalPercentage = parseInt(value)
    this.setState({ members: members })

    let both = false;
    if (this.state.members[this.state.currentShareholder].capitalPercentage === this.state.members[this.state.currentShareholder].annualPercentage) {
      both = true;
    }

    this.checkPercentages(this.state.members);

    const data = {
      NewEntityUID: this.state.uid,
      EntityUID: this.state.members[this.state.currentShareholder].entity.value,
      Percentage: this.state.members[this.state.currentShareholder].capitalPercentage,
      Both: both
    }

    await axios.patch("./api/new-entity-request/change-member-capital-percentage", data).then((response) => {
      CreateGlobalAlert("Capital Percentage Changed", 2500);
    })
  }

  async changeAnnualPercentage(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const members = this.state.members;
    let value = event.target.value;

    if (event.target.value === "") {
      value = "0";
    }

    members[this.state.currentShareholder].annualPercentage = parseInt(value)
    this.setState({ members: members })

    let both = false;
    if (this.state.members[this.state.currentShareholder].capitalPercentage === this.state.members[this.state.currentShareholder].annualPercentage) {
      both = true;
    }

    this.checkPercentages(this.state.members);

    const data = {
      NewEntityUID: this.state.uid,
      EntityUID: this.state.members[this.state.currentShareholder].entity.value,
      Percentage: this.state.members[this.state.currentShareholder].annualPercentage,
      Both: both
    }

    await axios.patch("./api/new-entity-request/change-member-annual-percentage", data).then((response) => {
      CreateGlobalAlert("Annual Percentage Changed", 2500);
    })
  }

  async removeMember(): Promise<void> {
    const data = {
      NewEntityUID: this.state.uid,
      EntityUID: this.state.members[this.state.currentShareholder].entity.value
    }

    const members = this.state.members;
    members.splice(this.state.currentShareholder, 1);
    if (this.state.members.length === 0) {
      members.push({
        entity: { label: "Select Entity", value: "-1" },
        capitalPercentage: 0,
        annualPercentage: 0,
        isManager: false
      })
    }
    this.setState({ members: members })

    if (this.state.currentShareholder >= members.length) {
      this.setState({ currentShareholder: 0 })
    }

    this.checkPercentages(this.state.members);

    await axios.patch("./api/new-entity-request/remove-new-member", data).then((response) => {
      CreateGlobalAlert("New Member Removed", 2500);
    })
  }

  totalContributions(): JSX.Element {
    let count = 0;
    this.state.members.forEach((member: EntityMember) => {
      count += (member.capitalPercentage / 100) * this.state.totalContributions;
    })

    return <>{count}</>
  }

  missingContributions(): JSX.Element {
    let count = 0;
    this.state.members.forEach((member: EntityMember) => {
      count += (member.capitalPercentage / 100) * this.state.totalContributions;
    })

    return <>{count - this.state.totalContributions}</>
  }

  async makeManager(event: React.ChangeEvent<CheckboxFieldProps>): Promise<void> {
    const members = this.state.members;
    members[this.state.currentShareholder].isManager = event.target.checked;
    this.setState({ members: members })

    const data = {
      NewEntityUID: this.state.uid,
      EntityUID: this.state.members[this.state.currentShareholder].entity.value
    }

    if (event.target.checked) {
      await axios.patch("./api/new-entity-request/make-member-manager", data).then((response) => {
        CreateGlobalAlert("Member Made Manager", 2500);
      })
    }

    if (!event.target.checked) {
      await axios.patch("./api/new-entity-request/make-manager-member", data).then((response) => {
        CreateGlobalAlert("Manager Made Member", 2500);
      })
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h2>Member Information</h2>
          </Col>
          <Col xs="3">
            <h6>{"Valid Capital Distribution: " + this.state.isCapitalValid}</h6>
            <label>{"Capital: " + this.state.totalCapitalPercentage + "%"}</label>
          </Col>
          <Col xs="3">
            <h6>{"Valid Annual Distribution: " + this.state.isAnnualValid}</h6>
            <label>{"Annual: " + this.state.totalAnnualPercentage + "%"}</label>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Row>
              <h3>Member #{this.state.currentShareholder + 1}</h3>
              <Col>
                <Select
                  options={this.state.entities}
                  value={this.state.members[this.state.currentShareholder].entity}
                  styles={reactSelectBasicStyle}
                  onChange={this.changeShareholder}
                  onFocus={RemoveStickyOverlays}
                  onBlur={RestoreStickyOverlays}
                />
              </Col>
              <Col>
                {this.state.managementType === 1 &&
                  <>
                    <label>Is Manager?</label>
                    <input type="checkbox" style={{ marginLeft: "2vw" }} checked={this.state.members[this.state.currentShareholder].isManager} onChange={this.makeManager} />
                  </>
                }
              </Col>
              <Col>
                <input type="button" className="standard-input" value="Remove Member" style={{ width: "80%", marginLeft: "10%", marginBottom: "1vh", height: "4vh" }} onClick={this.removeMember} hidden={parseInt(this.state.members[this.state.currentShareholder]?.entity.value) === -1} />
              </Col>
              <hr />
            </Row>
            <div hidden={parseInt(this.state.members[this.state.currentShareholder].entity.value) === -1}>
              <Row>
                <Col><label>Capital Ownership Percentage</label>
                  <input type="text" className="standard-input" style={{ height: "4vh" }} value={this.state.members[this.state.currentShareholder].capitalPercentage}
                    onChange={this.changeCapitalPercentage}
                  />
                </Col>
                <Col>
                  <label>Initial Capital Contributions</label><br />
                  <label>{(this.state.members[this.state.currentShareholder].capitalPercentage / 100) * this.state.totalContributions}</label>
                </Col>
                <Col>
                  <>
                    <Row>
                      <Col><label>Total: </label></Col>
                      <Col><label>{this.totalContributions()}</label></Col>
                      <Col><label>{this.state.missing}: </label></Col>
                      <Col><label>{this.missingContributions()}</label></Col>
                    </Row>
                    {
                      this.state.members.map((member: EntityMember) =>
                        <Row>
                          <Col><label>{member.entity.label}</label></Col>
                          <Col><label>$ {(member.capitalPercentage / 100) * this.state.totalContributions}</label></Col>
                        </Row>
                      )
                    }
                  </>
                </Col>
              </Row>
              <Row>
                <Col><label>Annual Ownership Percentage</label>
                  <input type="text" className="standard-input" style={{ height: "4vh" }} value={this.state.members[this.state.currentShareholder].annualPercentage}
                    onChange={this.changeAnnualPercentage}
                  />
                </Col>
                <Col>
                </Col>
                <Col>
                </Col>
              </Row>
            </div>
            <hr />
          </Col>
        </Row >
        <Row style={{ marginTop: "25px" }}>
          <Col>
            {
              this.state.members.map((item, idx) => (
                <input key={idx} type="button" className={idx === this.state.currentShareholder ? "tabActive" : "tab"}
                  value={item.entity.label + " " + item.capitalPercentage + "% (Capital) " + item.annualPercentage + "% (Annual)"}
                  onClick={() => this.setState({ currentShareholder: idx })}
                />
              ))
            }
            <input type="button" className={"tabAlt"} value="Add New Member" onClick={this.addMember} />
          </Col>
        </Row>
      </>
    )
  }

}