import * as React from 'react';
import { ServerTicket } from '../TicketWork/TicketWorkManager.constants';
import axios from 'axios';
import { getUserID } from '../../../functions/authActions';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketSummaryWidgetProps {
  uid: string
}
interface TicketSummaryWidgetState {
  uid: string;
  summary: string;
  actual: string;
  closingNotes: string;
  controller: AbortController;
}

export default class TicketSummaryWidget extends React.Component<TicketSummaryWidgetProps, TicketSummaryWidgetState> {

  constructor(props: TicketSummaryWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      summary: "",
      actual: "",
      closingNotes: "",
      controller: null
    }
    this.updateActualProblem = this.updateActualProblem.bind(this);
    this.changeActualProblem = this.changeActualProblem.bind(this);
  }
  
  async componentDidMount(): Promise<void> {
    const ticket: ServerTicket = (await axios.get("api/tickets/" + this.state.uid)).data;
    this.setState({
      summary: ticket.problemSummary,
      closingNotes: ticket.closingNotes,
      actual: ticket.actualProblemSummary
    })
  }

  changeActualProblem(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ actual: event.target.value });
  }

  async updateActualProblem(): Promise<void> {
    const data = { ProblemSummary: this.state.actual, TicketUID: this.state.uid, ModifierUID: getUserID() };
    var controller = new AbortController();
    if (this.state.controller !== null) {
      this.state.controller.abort();
    }
    this.setState({ controller: controller });
    await axios.patch('./api/tickets/actual-problem-summary', data, { signal: controller.signal});
  }

  render(): JSX.Element {
    return (
      <div >
        {RenderHeader("Ticket Summaries")}
        <div>
          <h5>Submission Summary</h5>
          <div style={{ maxHeight: "15vh", minHeight: "15vh", overflowY: "scroll", color: "#d7c7a7"  }}  dangerouslySetInnerHTML={{ __html: this.state.summary }}>
          </div>
          <h5>Actual Problem</h5>
          <textarea style={{ maxHeight: "12vh", minHeight: "14vh" }} value={this.state.actual} className="standard-input" onChange={this.changeActualProblem} onBlur={this.updateActualProblem} />
        </div>
        <div style={{ marginTop: "20px"}}>
          <h5 style={{  }}>Closing Notes</h5>
          <h6>{this.state.closingNotes}</h6>
        </div>
      </div>
    )
  }
}