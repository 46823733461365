import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import axios from 'axios';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { pullEmployeeAllocationArchiveDates, pullViridianMaintenanceManagers, pullViridianSiteManagers } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { Col, Row } from 'reactstrap';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface EmployeeArchiveAllocationWidgetRow {
  uid: string;
  propertyName: string;
  allocation: number;
  role: string;
}

interface EmployeeArchiveAllocationWidgetState {
  employees: Array<SelectOptions>;
  employee: SelectOptions;
  rows: Array<EmployeeArchiveAllocationWidgetRow>;
  dates: Array<SelectOptions>;
  date: SelectOptions;
}

export class EmployeeArchiveAllocationsWidget extends React.Component<{}, EmployeeArchiveAllocationWidgetState> {

  headers: Array<FilterAndSettingField> = [
    { columnName: "Property", setting: true, filter: {} },
    { columnName: "Role", setting: true, filter: {} },
    { columnName: "%", setting: true, filter: {} }
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      employees: [],
      employee: { label: "Select Employee", value: "" },
      rows: [],
      dates: [],
      date: { label: "Select Date", value: "" }
    }
    this.changeEmployee = this.changeEmployee.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var employees = (await pullViridianSiteManagers()).concat(await pullViridianMaintenanceManagers());
    var dates = await pullEmployeeAllocationArchiveDates();
    this.setState({ employees: employees, dates: dates })
  }

  changeEmployee(event: SelectOptions | null) {
    if (event === null) { return; }
    this.setState({ employee: event }, () => this.pullEmployeeData());
  }

  changeDate(event: SelectOptions | null) {
    if (event === null) { return; }
    this.setState({ date: event }, () => this.pullEmployeeData());
  }

  async pullEmployeeData(): Promise<void> {
    if (this.state.employee.value === "" || this.state.date.value === "") { return; }
    let response = await axios.get('./api/property-employee-map-archive/employee-allocations/' + this.state.employee.value + "/" + this.state.date.value, { validateStatus: () => true });
    if (response.status === 200) {
      this.setState({
        rows: response.data
      })
    }
  }

  render(): JSX.Element {
    return (
      <>
        {this.state.employee.value !== "" &&
          <>
            {RenderHeader("Employee Allocations")}
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.rows.map((item) => (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst">{item.propertyName}</td>
                      <td className="fixedTableCellFirst">{item.role}</td>
                      <td className="fixedTableCellFirst">{item.allocation}%</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </>
        }
        <hr />
        <Row>
          <Col>
            <Select
              options={this.state.employees}
              styles={reactSelectBasicStyle}
              value={this.state.employee}
              onChange={this.changeEmployee}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </Col>
          <Col>
            <Select
              options={this.state.dates}
              styles={reactSelectBasicStyle}
              value={this.state.date}
              onChange={this.changeDate}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </Col>
        </Row>

      </>
    )
  }
}