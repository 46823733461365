import * as React from 'react';
import { SelectableLegendTableRow } from './SelectableLegendTableRow';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';

interface ServicesTableProps {
  markers: any;
}

interface ServicesTableState {
  markers: any;
  headers: Array<FilterAndSettingField>;
}

export class ServicesTable extends React.Component<ServicesTableProps, ServicesTableState>{

  constructor(props: ServicesTableProps) {
    super(props);
    let markers = props.markers
      .filter((item: { name: string; }) => item.name !== 'property')
      .sort(function (a: any, b: any) { return a.index > b.index ? 1 : -1 })
    this.state = {
      markers: markers,
      headers: []
    }
    this.changeComments = this.changeComments.bind(this);
    this.changeSelectedItem = this.changeSelectedItem.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Index",
        additionalClasses: "ten",
        setting: true,
        filter: {}
      },
      {
        columnName: "Name", 
        setting: true,
        filter: {}
      },
      {
        columnName: "Override",
        setting: true,
        filter: {}
      },
      {
        columnName: "Distance",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      },
      {
        columnName: "Comments",
        setting: true,
        additionalClasses: "twenty",
        filter: {},
      }
    ]
    this.setState({ headers: headers})
  }

  changeSelectedItem(event: SelectOptions, index: number, override: string): void {
    let currentMarkers = this.state.markers;
    let current = currentMarkers[index];
    let currentItem = current.items[event.value];
    if (currentItem === undefined) { return; }
    current.currentIndex = event.value;
    current.name = currentItem.name;
    current.override = override;
    current.distance = currentItem.distance;
    current.lat = currentItem.lat;
    current.lng = currentItem.lng;
    currentMarkers[index] = current;
    this.setState({
      markers: currentMarkers
    })
  }

  changeComments(comments: any, index: any): void {
    let currentMarkers = this.state.markers;
    let current = currentMarkers[index];
    current.comments = comments;
    currentMarkers[index] = current;
    this.setState({
      markers: currentMarkers
    })
  }

  render(): JSX.Element {
    return (
      <div id="services-table" style={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "42vh" }}>
        <h5 style={{ marginTop: "5px" }}>Services</h5>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            {this.state.markers.length >= 2 &&
              this.state.markers.map((item: any) => (
                  <SelectableLegendTableRow key={item.name + item.index} currentIndex={item.currentIndex} index={item.index-1} items={item.items} name={item.name} distance={item.distance}
                    comments={item.comments} onChange={this.changeSelectedItem} updateComments={this.changeComments}
                    override={item.override}
                  />
                ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}