import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import axios from 'axios';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { Col, Row } from 'reactstrap';
import EntityAmortizationCreationModal from '../EntityAmortizationSchedule/EntityAmortizationCreationModal';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface EntityAmoritizationRowData {
  uid: string;
  title: string;
  lender: string;
  borrower: string;
  isLoan: boolean;
  type: string;
  interest: number;
  balance: number;
}

interface EntityAmortizationScheduleWidgetState {
  headers: Array<FilterAndSettingField>;
  rows: Array<EntityAmoritizationRowData>;
  entities: Array<SelectOptions>;
  uid: string;
}

export interface AmortizationPostData {
  LoanTitle: string;
  CompoundingType: string;
  InterestMethod: string;
  StartingInterestRate: number;
  PrincipalLimit: number;
  StartDate: Date;
  Term: number;
  IgnorePrincipal: boolean;
  BallonTerm: number;
  LenderUID: string;
  BorrowerUID: string;
  IsLoan: boolean;
}

export default class EntityAmortizationScheduleWidget extends React.Component<{ uid: string }, EntityAmortizationScheduleWidgetState> {

  newAmortizationModal: React.RefObject<EntityAmortizationCreationModal> = React.createRef<EntityAmortizationCreationModal>();
  container: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  constructor(props: { uid: string }) {
    super(props);
    this.state = {
      headers: [],
      rows: [],
      entities: [],
      uid: props.uid
    }
    this.postNewAmortizationSchedule = this.postNewAmortizationSchedule.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
  }

  async generateHeaders(): Promise<void> {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Title",
        setting: true,
        filter: {}
      },
      {
        columnName: "Lendor",
        setting: true,
        filter: {}
      },
      {
        columnName: "Borrower",
        setting: true,
        filter: {}
      }
    ]
    if (cols === 2) {
      headers.push({
        columnName: "Loan / Credit",
        setting: true,
        filter: {}
      })
      headers.push({
        columnName: "Type",
        setting: true,
        additionalClasses: "twenty-five",
        filter: {}
      })
    }
    if (cols <= 3) {
      headers.push({
        columnName: "Interest",
        setting: true,
        filter: {}
      })
      headers.push({
        columnName: "Principal",
        setting: true,
        filter: {}
      })
    }
    this.setState({ headers: headers }, async () => await this.getAmortSchedules());
  }

  async getAmortSchedules(): Promise<void> {
    await axios.get('./api/entity-amortization-schedule/table/' + this.state.uid).then((response: any) => {
      const amortizations: Array<EntityAmoritizationRowData> = [];
      response.data.forEach((item: any) => {
        const type = this.state.uid === item.borrower.uid ? "Payable" : "Receivable";
        amortizations.push({
          uid: item.amortization.uid,
          lender: item.lender.name,
          borrower: item.borrower.name,
          balance: item.amortization.principalBalance,
          interest: item.amortization.interestAccrued,
          title: item.amortization.loanTitle,
          type: type,
          isLoan: item.amortization.isLoan,
        })
      })
      this.setState({
        rows: amortizations
      })
    })
  }

  async postNewAmortizationSchedule(data: AmortizationPostData): Promise<void> {
    const response = await axios.post('./api/entity-amortization-schedule', data)
    await this.getAmortSchedules();
  }

  getRow(item: EntityAmoritizationRowData): JSX.Element {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    return (
      <tr key={item.uid} >
        <td className="fixedTableCellFirst"
          style={{ cursor: "pointer", textDecoration: "underline", color: "#b89961" }}
          onClick={() => window.location.assign('./amortization-schedules?uid=' + item.uid)}
        >
          {item.title}
        </td>
        <td className="fixedTableCell">{item.lender}</td>
        <td className="fixedTableCell">{item.borrower}</td>
        {cols === 2 && <td className="fixedTableCell">{(item.isLoan ? "Loan" : "Line Of Credit")}</td>}
        {cols === 2 && <td className="fixedTableCell">{item.type}</td>}
        {cols <= 3 && <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.interest)}</td>}
        {cols <= 3 && <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.balance)}</td>}
      </tr >
    )
  }

  render(): JSX.Element {
    return (
      <div ref={this.container}>
        <EntityAmortizationCreationModal ref={this.newAmortizationModal} />
        {RenderHeader("Amortization Schedules")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: EntityAmoritizationRowData) => (
                  this.getRow(item)
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Add Amoritization Schedule" className="standard-input" onClick={() => this.newAmortizationModal.current.show("Create New Amortization Schedule", this.postNewAmortizationSchedule)} />
          </Col>
          <Col>

          </Col>
        </Row>
      </div>
    )
  }
}