import * as React from 'react';
import { PropertyPayrollWidget } from './PropertyPayrollWidget';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { pullProperties, pullPropertiesForRegionals, pullPropertyPayrollDates } from '../../../functions/fetchLinkedObjects';
import { Col, Row } from 'reactstrap';

interface PropertyPayrollSelectionWidgetProps {
  useRegional: boolean;
}

interface PropertyPayrollSelectionWidgetState {
  property: SelectOptions;
  properties: Array<SelectOptions>;
  dates: Array<SelectOptions>;
  date: SelectOptions;
}

export class PropertyPayrollSelectionWidget extends React.Component<PropertyPayrollSelectionWidgetProps, PropertyPayrollSelectionWidgetState> {

  constructor(props: PropertyPayrollSelectionWidgetProps) {
    super(props);
    this.state = {
      properties: [],
      property: { label: "Select Property", value: "" },
      dates: [],
      date: { label: "Select Date", value: "" }
    }
    this.changeProperty = this.changeProperty.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = this.props.useRegional ? await pullPropertiesForRegionals() :  await pullProperties();
    this.setState({
      properties: properties
    })
  }

  async changeProperty(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    let dates = await pullPropertyPayrollDates(event.value);
    this.setState({ property: event, dates: dates, date: { label: "Select Date", value: "" } });
  }

  changeDate(event: SelectOptions | null) {
    this.setState({ date: event });
  }

  render(): JSX.Element {
    return (
      <>
        {this.state.property.value !== "" ? <PropertyPayrollWidget key={this.state.property.value + this.state.date.value} uid={this.state.property.value} date={this.state.date.value}  />
          : <h4 style={{ width: "100%", textAlign: "center" }}>Payroll Tracking</h4>
        }
        <Row>
          <Col>
            <Select
              options={this.state.properties}
              styles={reactSelectBasicStyle}
              value={this.state.property}
              onChange={this.changeProperty}
              menuPlacement="auto"
            />
          </Col>
          <Col>
            <Select
              options={this.state.dates}
              styles={reactSelectBasicStyle}
              value={this.state.date}
              onChange={this.changeDate}
              menuPlacement="top"
            />
          </Col>
        </Row>
        
      </>
    )
  }
}