import * as React from 'react'
import { CNAPhotosWidgetProps, CNAPhotosWidgetState } from './CNAPhotosWidget.constants';
import { StatusModal, WarningModal } from '../../../CoreComponents/Modals';
import { SavedFileImageData, generateImageGrid } from '../../../../functions/imageTools';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { Col, Row } from 'reactstrap';

export default class CNAPhotosWidget extends React.Component<CNAPhotosWidgetProps, CNAPhotosWidgetState>{

  statusRef: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningRef: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: CNAPhotosWidgetProps) {
    super(props);
    this.state = {
      cnaUID: props.cnaUID,
      photos: [],
      loading: true
    }
    this.addImages = this.addImages.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.getPhotos();
  }

  async getPhotos(): Promise<void> {
    const photos: Array<SavedFileImageData> = [];
    const maps: Array<any> = []
    const photoPathResponse = await axios.get('./api/cna-photos/' + this.state.cnaUID)
    if (photoPathResponse.status === 200) {
      photoPathResponse.data.forEach((map: any) => { maps.push(map.savedFileUID) })
    }
    else {
      //TODO ERROR HANDLE
    }

    await maps.forEach(async (imgUID) => {
      const imageResponse = await axios.get('./api/saved-files/image/' + imgUID, { responseType: "blob" })
      if (imageResponse.status === 200) {
        const img = URL.createObjectURL(imageResponse.data);
        photos.push({
          img: img,
          imgUID: imgUID
        })
        this.setState({ photos: photos, loading: false })
      }
    })
  }

  async addImages(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const files = event.target.files;
    for (let i = 0; i < files.length; ++i) {
      let file = files.item(i);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      const image = await imageCompression(file, options);
      const formData = new FormData();
      formData.append("File", image);
      const response = await axios.post('./api/saved-files/image/cna', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response.status === 200) {
        const photoMapData = {
          CNAUID: this.state.cnaUID,
          PhotoUID: response.data
        }
        await axios.post('./api/cna-photos', photoMapData)
      }
      else {
        //TODO ERROR HANDLE
      }
    }
    await this.getPhotos();
  }

  render(): JSX.Element {
    return (
      <Row>
        <Col>
          <h3>Photos</h3>
          <Row>
            <h5>Add Photo <em style={{ fontSize: "9px", verticalAlign: 'center' }}> (JPG or PNG only)</em></h5>
            <input type="file" onChange={this.addImages} id="cna-picture" accept="image/png, image/gif, image/jpeg" className="standard-input" style={{ border: "solid", height: "20vh", paddingLeft: "13vw", paddingTop: "9vh", width: "50%", marginLeft: "25%", marginBottom: "2vh" }} multiple />
          </Row>
          {
            !this.state.loading && generateImageGrid(this.state.photos, 4)
          }
        </Col>
      </Row>
    )
  }
}