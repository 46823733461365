import * as React from 'react';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArchive } from '@fortawesome/free-solid-svg-icons';
import { pullEntitiesWithIndividuals } from '../../../functions/fetchLinkedObjects';
import { EntityStructureNewRowProps, EntityStructureNewRowState, EntityStructureRowData, EntityStructureRowProps } from '../Structs';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { OwnershipTypeMap } from '../EntityManagementTable/constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';

export class OwnerRow extends React.Component<EntityStructureRowProps, EntityStructureRowData> {

  constructor(props: EntityStructureRowProps) {
    super(props);
    this.state = {
      name: props.name,
      percent: props.percent,
      isManagingMember: props.isManagingMember,
      canArchive: props.canArchive,
      type: props.type,
      uid: props.uid,
      deleteRow: props.deleteRow ? props.deleteRow : this.defaultDelete
    }
  }

  defaultDelete(uid: string): void {
    // do nothing
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.state.name}</td>
        <td className="fixedTableCell">{this.state.percent}%</td>
        <td className="fixedTableCell">{OwnershipTypeMap[this.state.type]}</td>
        <td className="fixedTableCell">{this.state.isManagingMember ? "Yes" : ""}</td>

        <td className="fixedTableCell">
          {this.state.canArchive && <FontAwesomeIcon icon={faArchive} style={{ marginLeft: "35%", cursor: "pointer", color: "#b89961" }}
            onClick={() => this.state.deleteRow(this.state.uid)} />}
        </td>
      </tr >
    )
  }
}

export class NewOwnerRow extends React.Component<EntityStructureNewRowProps, EntityStructureNewRowState> {
  static OwnershipTypes: Array<SelectOptions> = [
    { label: "N/A", value: '0' },
    { label: "Annual Profit & Loss", value: '1' },
    { label: "Capital", value: '2' },
    { label: "Both", value: '3' }
  ]

  constructor(props: EntityStructureNewRowProps) {
    super(props);
    this.state = {
      entities: [],
      entityName: "",
      entityId: "",
      percent: 0.0,
      isManagingMember: false,
      type: { label: 'N/A', value: '0' }
    }
    this.handlePercentChange = this.handlePercentChange.bind(this);
    this.handleEntityChange = this.handleEntityChange.bind(this);
    this.handleManagingMemberChange = this.handleManagingMemberChange.bind(this);
    this.changeOwnershipType = this.changeOwnershipType.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const entities = await pullEntitiesWithIndividuals();
    this.setState({
      entities: entities
    })
  }

  handlePercentChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      percent: parseFloat(event.target.value)
    })
  }

  handleEntityChange(event: SelectOptions | null): void {
    if (event === null) { return; }
    const name = this.state.entities.filter(item => item.value === event.value)[0].label;
    const id = this.state.entities.filter(item => item.value === event.value)[0].value;
    this.setState({
      entityName: name,
      entityId: id
    })
  }

  handleManagingMemberChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      isManagingMember: event.target.checked
    })
  }

  changeOwnershipType(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      type: event
    })
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <Select
            options={this.state.entities}
            styles={reactSelectBasicStyle}
            onChange={this.handleEntityChange}
            onBlur={RestoreStickyOverlays}
            onFocus={RemoveStickyOverlays}
          />
        </td>
        <td className="fixedTableCell">
          <input type="number" className="standard-input" step="0.01" min="0" max="100" style={{ width: "90%" }} value={this.state.percent} onChange={this.handlePercentChange} /> %
        </td>
        <td className="fixedTableCell">
          <Select
            options={NewOwnerRow.OwnershipTypes}
            styles={reactSelectBasicStyle}
            value={this.state.type}
            onChange={this.changeOwnershipType}
            onBlur={RestoreStickyOverlays}
            onFocus={RemoveStickyOverlays}
          />
        </td>
        <td className="fixedTableCell">
          <input type="checkbox" checked={this.state.isManagingMember} style={{ marginLeft: "40%" }} onChange={this.handleManagingMemberChange} />
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faSave} style={{ marginLeft: "35%", cursor: "pointer", color: "#b89961" }}
            onClick={() => this.props.onClick(this.state.entityName, this.state.percent, this.state.entityId, this.state.type, this.state.isManagingMember)} />
        </td>
      </tr>
    )
  }
}