import * as React from 'react';
import { GetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DashboardOrganizer, DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { DashboardManagementModal } from '../../CoreComponents/Modals/DashboardManagementModal';
import NewTicketWidget from '../../TicketingSystemComponents/TicketWidgets/NewTicketWidget';
import { TechnologyTeamLeadDashboardProps, TechnologyTeamLeadDashboardState } from './TechnologyTeamLeadDashboard.constants';
import { SupervisorEmployeeManagementWidget } from '../Widgets/SupervisorEmployeeManagementWidget';
import { getUserID } from '../../../functions/authActions';
import { Col, Row } from 'reactstrap';
import EmployeePositionChangeRequestModal from '../../PayrollComponents/EmployeeChangeRequest/EmployeePositionChangeModal';
import OnboardingAndOffboardingTriggerWidget from '../Widgets/OnboardingAndOffboardingTriggerWidget';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';
import ReasonableAccomodationWidget from '../Widgets/ReasonableAccomodationWidget';
import TicketSummaryLookupWidget from '../../TicketingSystemComponents/TicketWidgets/TicketSummaryLookupWidget';
import UtilityAnalysisWidget from '../../WorkflowComponents/DashboardWidgets/UtilityAnalysisWidget';
import ARReportWidget from '../../WorkflowComponents/DashboardWidgets/ARReportWidget';

export default class TechnologyTeamLeadDashboard extends React.Component<TechnologyTeamLeadDashboardProps, TechnologyTeamLeadDashboardState> {

  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  settingsString: string = "techLeadTicketDashboard";

  constructor(props: TechnologyTeamLeadDashboardProps) {
    super(props);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal: boolean = false;
    if (settings === null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      showModal: showModal,
      renderSettings: settings,
      displayWidgets: [],
      widgetList: []
    }
    this.updateUI = this.updateUI.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDasboard();
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI);
    }
  }

  async prepareDasboard(): Promise<void> {
    let displayWidgets: { [key: number]: JSX.Element } = {
      0: <NewTicketWidget />,
      1: <SupervisorEmployeeManagementWidget supervisorUID={getUserID()} />,
      5: <OnboardingAndOffboardingTriggerWidget />,
      6: <ReasonableAccomodationWidget />,
      7: <TicketSummaryLookupWidget />,
      8: <UtilityAnalysisWidget />,
      9: <ARReportWidget />
    }
    let widgetList = [
      { label: "New Tickets", value: "0" },
      { label: "Employee Management", value: "1" },
      { label: "Start Onboard/Offboard", value: "5" },
      { label: "Reasonable Accomodations", value: "6" },
      { label: "Ticket Summary Lookup", value: "7" },
      { label: "Utility Analysis", value: "8" },
      { label: "AR Reports", value: "9"}
    ]
    this.setState({
      displayWidgets: displayWidgets,
      widgetList: widgetList
    })
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  render(): JSX.Element {
    return (
      <>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <EmployeePositionChangeRequestModal />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>Technology Lead Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        {DashboardOrganizer(this.state.displayWidgets, this.state.renderSettings)}
      </>
    )
  }
}