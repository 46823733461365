//#region Imports
import * as React from 'react';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import { pullProperties } from '../../functions/fetchLinkedObjects';
import { reactSelectBasicStyle } from '../../style/select-constants';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { DownloadNamedFile } from '../../functions/documentTools';
//#endregion

interface WarningModalProps {
  returnToOwner: boolean;
  rentIncrease: boolean;
}

interface WarningModalState {
  showing: boolean;
}

interface PropertyDocumentTableProps {

}

interface PropertyDocumentTableState {
  property: SelectOptions;
  propertyList: Array<SelectOptions>;
  year: SelectOptions;
  years: Array<SelectOptions>;
  rd3560_7: string;
  fileId: string;
  uploadRD3560_7: boolean;
  returnToOwner: boolean;
  rentIncrease: boolean;
  filePathShort: string;
  invalid: boolean;
  headers: Array<FilterAndSettingField>;
}

class WarningModal extends React.Component<WarningModalProps, WarningModalState> {

  constructor(props: WarningModalProps) {
    super(props);
    this.state = {
      showing: false,
    }
    this.show = this.show.bind(this);
  }

  show(): void {
    this.setState({
      showing: true
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing}>
        <ModalHeader style={{ color: "#15405c" }} tag="h3" toggle={() => this.setState({ showing: false })}>
          Warning Messages
        </ModalHeader>
        <ModalBody>
          {this.props.returnToOwner ?
            <h5 style={{ color: "#cc3300" }}>Return to Owner is $0. Please review Return to Owner budget line</h5> :
            <h5 style={{ color: "#15405c" }}>Return to Owner has no concerns</h5>
          }
          {this.props.rentIncrease ?
            <h5 style={{ color: "#cc3300" }}>There is no increase to rent. Please review the Proposed Rents for the upcoming year</h5> :
            <h5 style={{ color: "#15405c" }}>Rent Increase has no concerns</h5>
          }
        </ModalBody>
        <ModalFooter>
          <input type="button" value="Close" style={{
            backgroundColor: "#15405c",
            color: "#c2a877"
          }} onClick={() => this.setState({ showing: false })} />
        </ModalFooter>
      </Modal>
    )
  }
}

export class PropertyDocumentTable extends React.Component<PropertyDocumentTableProps, PropertyDocumentTableState> {
  modalRef = React.createRef<WarningModal>();
  fileRef = React.createRef<HTMLInputElement>();

  constructor(props: PropertyDocumentTableProps) {
    super(props);
    this.state = {
      property: { label: 'Select Property', value: '' },
      propertyList: [],
      years: [],
      rd3560_7: "",
      fileId: "",
      year: { label: new Date().getFullYear().toFixed(0), value: new Date().getFullYear().toFixed(0) },
      uploadRD3560_7: false,
      returnToOwner: false,
      rentIncrease: false,
      invalid: false,
      filePathShort: "",
      headers: this.generateHeaders()
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.handlePropertyChange = this.handlePropertyChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.updateFile = this.updateFile.bind(this);
    this.updateFileText = this.updateFileText.bind(this);
    this.uploadFileToServer = this.uploadFileToServer.bind(this);
    this.updateDocuments = this.updateDocuments.bind(this);
    this.downloadCurrent = this.downloadCurrent.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const properties = await pullProperties();
    const years: Array<SelectOptions> = [];
    for (let i = 2016; i < new Date().getFullYear() + 2; ++i) {
      years.push({ label: i.toFixed(0), value: i.toFixed(0) });
    }
    this.setState({
      years: years,
      propertyList: properties
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Document Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Year",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
  }

  //#region Functions

  handlePropertyChange(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      property: event
    }, this.updateDocuments)
  }

  handleYearChange(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      year: event
    }, this.updateDocuments)
  }

  updateFile(): void {
    this.fileRef.current?.click();
  }

  updateFileText(): void {
    //@ts-ignore
    const filePath = this.fileRef.current?.files[0].name;
    if (filePath === undefined) { return; }
    this.setState({
      filePathShort: filePath
    })
  }

  uploadFileToServer(): void {
    const self = this;
    const formData = new FormData();
    //@ts-ignore
    const file = this.fileRef.current?.files[0]; //$("#fileUpload")[0].files[0];
    if (file === undefined) { return; }
    formData.append("File", file);
    axios.post("/api/RD3560_7/" + this.state.property.value + "/" + this.state.year.value, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(function (response) {
      self.setState({
        rd3560_7: response.data.UID,
        uploadRD3560_7: false,
        fileId: response.data.SavedFileUID,
        returnToOwner: response.data.InvalidReturnToOwner,
        rentIncrease: response.data.InvalidRentIncrease,
        invalid: response.data.InvalidReturnToOwner || response.data.InvalidRentIncrease
      }, self.showModal)
    })
  }

  async updateDocuments(): Promise<void> {
    if (this.state.property.value === '') { return; }
    const self = this;
    await axios.get("/api/RD3560_7/" + this.state.property.value + "/" + this.state.year.value + "/property").then(function (response) {
      if (response.data !== null) {
        self.setState({
          rd3560_7: response.data.UID,
          fileId: response.data.SavedFileUID,
          invalid: response.data.InvalidReturnToOwner || response.data.InvalidRentIncrease
        })
      }
      else {
        self.setState({ rd3560_7: "", invalid: false })
      }
    })
  }

  async downloadCurrent(): Promise<void> {
    if (this.state.fileId === null) { return; }
    let response = await axios.get("./api/saved-files/" + this.state.fileId + "/xlsx", { responseType: 'blob' });
    await DownloadNamedFile(response);
  }

  showModal(): void {
    this.modalRef.current?.show();
  }

  //#endregion

  render(): JSX.Element {
    return (
      <div>
        <WarningModal ref={this.modalRef} returnToOwner={this.state.returnToOwner} rentIncrease={this.state.rentIncrease} />
        <h3>Property Documents</h3>
        <Select
          options={this.state.propertyList}
          styles={reactSelectBasicStyle}
          onChange={this.handlePropertyChange}
          value={this.state.property}
          onFocus={RemoveStickyOverlays}
          onBlur={RestoreStickyOverlays}
        />
        {!this.state.uploadRD3560_7 &&
          <div id="table-container" className="scrollable-table-container" style={{ minHeight: "65vh", maxHeight: "75vh" }}>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
              </thead>
              <tbody>
                <tr>
                  <td className="fixedTableCellFirst" style={{ backgroundColor: this.state.invalid ? "#cc3300" : "#4C4A42" }}>RD 3560-7</td>
                  <td className="fixedTableCell">
                    <Select
                      options={this.state.years}
                      styles={reactSelectBasicStyle}
                      defaultValue={{
                        label: new Date().getFullYear().toFixed(0),
                        value: new Date().getFullYear().toFixed(0)
                      }}
                      value={this.state.year}
                      onChange={this.handleYearChange}
                      onFocus={RemoveStickyOverlays}
                      onBlur={RestoreStickyOverlays}
                    />
                  </td>
                  <td className="fixedTableCell">
                    {this.state.rd3560_7 !== null ?
                      <input type="button" value="Download Current" className="standard-input" onClick={this.downloadCurrent} /> :
                      <h5>No Document Currently Exists</h5>
                    }
                  </td>
                  <td className="fixedTableCell">
                    <FontAwesomeIcon icon={faPlus} style={{ marginLeft: "40%", color: "#b89961", cursor: "pointer" }}
                      onClick={() => this.setState({ uploadRD3560_7: true })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
        {this.state.uploadRD3560_7 &&
          <Row style={{ marginTop: "3%" }}>
            <Col>
              <input type="button" value="Upload New RD 3560-7" onClick={this.updateFile} className="standard-input" style={{ width: "60%" }} />
              <input id="fileUpload" type="file" accept=".xlsx" hidden onChange={this.updateFileText}
                ref={this.fileRef}
              />
              <input type="text" className="standard-input" value={this.state.filePathShort}
                disabled style={{ marginLeft: "1%", marginBottom: "20px", width: "29%" }} />
              <input type="button" value="Process" className="standard-input" style={{ marginLeft: "1%", width: "9%" }}
                onClick={this.uploadFileToServer}
              />
            </Col>
          </Row>
        }
      </div>
    )
  }
}