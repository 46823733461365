import axios from "axios";
import { FilterAndSettingField } from "../../CoreComponents/interfaces"
import { CNALineActions, CNALineCondtions, CNALineItem, CNALineItemSelectOptions, CNALineQuantityTypes } from "./CNALineItemDisplay.constants";

export function getCNALineDetailHeaders(): Array<FilterAndSettingField> {
  const headers: Array<FilterAndSettingField> = [
    {
      columnName: 'Item Description',
      additionalClasses: "twenty",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Estimated Useful Life',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Age',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Remining Useful Life',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Is Health and Safety Issue',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Condition',
      additionalClasses: "fifteen",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Action',
      additionalClasses: "fifteen",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Duration',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Quantity',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Unit',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Unit Cost',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Total Cost',
      additionalClasses: "ten",
      setting: true,
      filter: {

      }
    },
    {
      columnName: 'Comments',
      additionalClasses: "forty",
      setting: true,
      filter: {

      }
    }
  ]

  return headers;
}

export async function getLineOptions(category: string): Promise<Array<CNALineItemSelectOptions>> {

  const response = await axios.get('./api/cna-line-item/' + category + '-line-item-list')

  const list: Array<CNALineItemSelectOptions> = [];

  if (response.status == 200) {
    response.data.forEach((line: any) => {
      list.push({
        label: line.label,
        value: line.label,
        family: line.family,
        elderly: line.elderly
      })
    })
  }

  return list;
}

export async function getLineItems(cnaUID: string, category: string): Promise<Array<CNALineItem>> {

  const response = await axios.get('./api/cna-line-item/' + category + '/' + cnaUID)

  const lines: Array<CNALineItem> = []

  if (response.status == 200) {

    response.data.forEach((line: any, idx: number) => {
      lines.push({
        uid: line.uid,
        item: { label: line.minorCategory, value: idx.toFixed(0), family: line.eul, elderly: line.eul },
        eul: line.eul,
        age: line.age,
        rul: line.rul,
        isHealthAndSafetyIssue: line.isHealthAndSafetyIssue,
        condition: CNALineCondtions.filter(u => u.value === line.condition.toString())[0],
        action: CNALineActions.filter(u => u.value === line.action.toString())[0],
        duration: line.duration,
        qty: line.quantity,
        unit: CNALineQuantityTypes.filter(u => u.value === line.quantityUnitType.toString())[0],
        unitCost: line.unitCost,
        totalCost: (line.quantity * line.unitCost).toString(),
        comments: line.comments
      })
    })
  }

  return lines;

}
