import * as React from "react"
import { ListGroup, ListGroupItem } from "reactstrap";
import { getUserJobFromServer, getUserTeamFromServer, Jobs, Teams } from "../../functions/authActions";

export class UsefulLinksWidget extends React.Component<{}, {team: number, job: number}> {

  constructor(props: {}) {
    super(props);
    this.state = {
      team: -1,
      job: -1
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({
      team: await getUserTeamFromServer(),
      job: await getUserJobFromServer()
    })
  }

  getVicePresidentOpertions(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./location-analysis" style={{ backgroundColor: "#4C4A42" }}>Location Analysis</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getRegionalManager(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./location-analysis" style={{ backgroundColor: "#4C4A42" }}>Location Analysis</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getPropertyAccounting(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./location-analysis" style={{ backgroundColor: "#4C4A42" }}>Location Analysis</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getHumanResources(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./payroll-processing" style={{ backgroundColor: "#4C4A42" }}>Payroll Processing</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./ticket-submission" style={{ backgroundColor: "#4C4A42" }}>Submit a Ticket</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getConstructionAccounting(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42"} }>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px"}}>
          <a href="./active-job-management" style={{ backgroundColor: "#4C4A42" }}>General Contracting Jobs</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./subcontracting-job-management" style={{ backgroundColor: "#4C4A42" }}>Subcontracting Jobs</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./active-job-reporting" style={{ backgroundColor: "#4C4A42" }}>General Contracting Reporting</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./ticket-submission" style={{ backgroundColor: "#4C4A42" }}>Submit a Ticket</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getDevelopmentAccounting(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./entity-manager" style={{ backgroundColor: "#4C4A42" }}>Entity Management</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./vendor-dashboard" style={{ backgroundColor: "#4C4A42" }}>Vendors / External Companies</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./active-job-management" style={{ backgroundColor: "#4C4A42" }}>Job Management</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./ticket-submission" style={{ backgroundColor: "#4C4A42" }}>Submit a Ticket</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getCFO(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./active-job-management" style={{ backgroundColor: "#4C4A42" }}>General Contracting Jobs</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./subcontracting-job-management" style={{ backgroundColor: "#4C4A42" }}>Subcontracting Jobs</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./active-job-reporting" style={{ backgroundColor: "#4C4A42" }}>General Contracting Reporting</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./entity-manager" style={{ backgroundColor: "#4C4A42" }}>Master Entity List</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./entity-request-form" style={{ backgroundColor: "#4C4A42" }}>Request New Entity</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./ticket-submission" style={{ backgroundColor: "#4C4A42" }}>Submit a Ticket</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getDevelopmentFinance(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./active-job-management" style={{ backgroundColor: "#4C4A42" }}>General Contracting Jobs</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./subcontracting-job-management" style={{ backgroundColor: "#4C4A42" }}>Subcontracting Jobs</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./active-job-reporting" style={{ backgroundColor: "#4C4A42" }}>General Contracting Reporting</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./ticket-submission" style={{ backgroundColor: "#4C4A42" }}>Submit a Ticket</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getProjectManagement(): JSX.Element {
    return (
      <ListGroup style={{ backgroundColor: "#4C4A42" }}>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./location-analysis" style={{ backgroundColor: "#4C4A42" }}>Location Analysis</a>
        </ListGroupItem>
        <ListGroupItem style={{ backgroundColor: "#4C4A42", border: "0px" }}>
          <a href="./submit-supply-request" style={{ backgroundColor: "#4C4A42" }}>Request Office Supplies</a>
        </ListGroupItem>
      </ListGroup>
    )
  }

  getLinks(): JSX.Element {
    switch (this.state.team) {
      case Teams.Human_Resources:
        switch (this.state.job) {
          case Jobs.HR:
            return this.getHumanResources();
        }
        break;
      case Teams.Construction:
        switch (this.state.job) {
          case Jobs.Accountant:
            return this.getConstructionAccounting();
        };
        break;
      case Teams.Executive:
        switch (this.state.job) {
          case Jobs.CFO:
            return this.getCFO();
        }
        break;
      case Teams.Development:
        switch (this.state.job) {
          case Jobs.Finance:
            return this.getDevelopmentFinance();
          case Jobs.Project_Manager:
            return this.getProjectManagement();
          case Jobs.Accountant:
            return this.getDevelopmentAccounting();
        };
        break;
      case Teams.Property_Management:
        switch (this.state.job) {
          case Jobs.Accountant:
            return this.getPropertyAccounting();
          case Jobs.VP_Operations:
            return this.getVicePresidentOpertions();
          case Jobs.Regional:
            return this.getRegionalManager();
        }
    }
  }

  render(): JSX.Element {
    return (
      <>
        <h5 style={{ width: "100%", textAlign: "center"}}>Useful Links</h5>
      {
        this.getLinks()
      }
      </>
    )
  }
}