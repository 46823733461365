import * as React from 'react';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { WarningModal } from '../CoreComponents/Modals';
import axios from 'axios';
import ComputerTrackingModal from './ComputerTrackingManagementWidget.modals';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { numberWithCommas } from '../../functions/numberFunctions';
import { YesNoAll } from '../../constants/EnumConstants';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

export interface ComputerTrackingSummary {
  uid: string;
  config: SelectOptions;  
  computerName: string;
  operatingSystemKey: string;
  costAtPurchase: number;
  islRegistered: boolean;
  gravityZoneInstalled: boolean;
}

interface ComputerTrackingManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<ComputerTrackingSummary>;
  computerName: string;
  operatingSystemKey: string;
  isl: SelectOptions;
  gravityZone: SelectOptions;
}

export default class ComputerTrackingManagementWidget extends React.Component<{}, ComputerTrackingManagementWidgetState> {

  trackingModal: React.RefObject<ComputerTrackingModal> = React.createRef<ComputerTrackingModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  container: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  constructor(props: {}) {
    super(props);
    this.state = {
      headers: [],
      data: [],
      computerName: "",
      operatingSystemKey: "",
      isl: { label: "All", value: "0" },
      gravityZone: { label: "All", value: "0" },
    }
    this.callback = this.callback.bind(this);
    this.changeComputerName = this.changeComputerName.bind(this);
    this.changeOperatingSystemKey = this.changeOperatingSystemKey.bind(this);
    this.changeISL = this.changeISL.bind(this);
    this.changeGravityZone = this.changeGravityZone.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async updateData(): Promise<void> {
    const data = {
      page: 0,
      pageCount: 0,
      computerName: this.state.computerName,
      operatingSystemKey: this.state.operatingSystemKey,
      isl: this.state.isl.value,
      gravityZone: this.state.gravityZone.value
    }
    let response = await axios.post('./api/computer-tracking/filter', data, { validateStatus: () => true });
    if (response.status === 200) {
      let data: Array<ComputerTrackingSummary> = [];
      response.data.forEach((item: any) => {
        data.push({
          uid: item.uid,
          config: { label: item.computerConfiguration.name, value: item.computerConfiguration.uid },
          computerName: item.computerName,
          operatingSystemKey: item.operatingSystemKey,
          costAtPurchase: item.costAtPurchase,
          islRegistered: item.islRegistered,
          gravityZoneInstalled: item.gravityZoneInstalled
        })
      })
      this.setState({ data: data });
    }
    else {
      //this.warningModal.current.show("There was an error loading data. Please reach out to the tech team to let them know.", "Error");
    }
  }

  async generateHeaders(): Promise<void> {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Computer Name",
        setting: true,
        filter: {
          type: "text",
          name: "computerName",
          value: this.state.computerName,
          changeFilter: this.changeComputerName,
          placeHolder: "Filter Computers"
        }
      },
      {
        columnName: "OS Key",
        setting: true,
        additionalClasses: "twenty",
        filter: {
          type: "text",
          name: "operatingSystemKey",
          value: this.state.operatingSystemKey,
          changeFilter: this.changeOperatingSystemKey,
          placeHolder: "Filter OS Key"
        }
      }
    ]
    if (cols < 4) {
      headers.push({
          columnName: "Cost",
          setting: true,
          additionalClasses: "fifteen",
          filter: {}
        })
    }
    if (cols === 2) {
      headers.push({
        columnName: "ISL",
        setting: true,
        additionalClasses: "ten",
        filter: {
          name: "isl",
          value: this.state.isl,
          changeFilter: this.changeISL,
          options: YesNoAll,
          multi: false
        }
      })
      headers.push({
        columnName: "Gravity Zone",
        setting: true,
        filter: {
          name: "gravityZone",
          value: this.state.gravityZone,
          changeFilter: this.changeGravityZone,
          options: YesNoAll,
          multi: false
        },
        additionalClasses: "fifteen"
      })
    }
    headers.push({
      columnName: "",
      setting: true,
      additionalClasses: "ten",
      filter: {}
    })
    this.setState({ headers: headers }, async () => await this.updateData());
  }

  async changeComputerName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ computerName: event.target.value }, async () => await this.generateHeaders());
  }

  async changeOperatingSystemKey(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ operatingSystemKey: event.target.value }, async () => await this.generateHeaders());
  }

  async changeISL(event: SelectOptions): Promise<void> {
    this.setState({ isl: event }, async () => await this.generateHeaders());
  }

  async changeGravityZone(event: SelectOptions): Promise<void> {
    this.setState({ gravityZone: event }, async () => await this.generateHeaders());
  }

  callback(edit: boolean): void {
    this.trackingModal.current.hide();
    if (!edit) {
      this.warningModal.current.show("Computer has been created.", "Computer Tracking Created", () => window.location.reload());
    }
    else {
      window.location.reload();
    }
  }

  getRow(item: ComputerTrackingSummary): JSX.Element {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    return (
      <tr key={item.uid}>
        <td className="fixedTableCellFirst">{item.computerName}</td>
        <td className="fixedTableCell">{item.operatingSystemKey}</td>
        {cols < 4 && <td className="fixedTableCell">{numberWithCommas(item.costAtPurchase, 2)}</td>}
        {cols === 2 && <td className="fixedTableCell">{item.islRegistered ? "Yes" : "No"}</td>}
        {cols === 2 && <td className="fixedTableCell">{item.gravityZoneInstalled ? "Yes" : "No"}</td>}
        <td className="fixedTableCell">
          <input type="button" value="View" className="standard-input" onClick={() => this.trackingModal.current.show(item)} />
        </td>
      </tr>
    )
  }

  render(): JSX.Element {
    return (
      <div ref={this.container}>
        <WarningModal ref={this.warningModal} />
        <ComputerTrackingModal ref={this.trackingModal} callback={this.callback} />
        {RenderHeader("Computer Tracking")}
        <div style={{ height: "25vh", overflowY: "scroll" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  this.getRow(item)
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create" className="standard-input" onClick={() => this.trackingModal.current.show()} />
      </div>
    )
  }
}