import * as React from 'react';
import DataEntryModal, {
  DataEntryModalField,
  DataEntryModalFieldSelect, 
  DataEntryModalFieldText, 
  IsEmailValidation,
  IsNotEmptyValidation, 
  IsPhoneNumberValidation, 
  IsSetValidation
} from '../CoreComponents/DataEntryModal';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { ContactTypes } from '../../constants/EnumConstants';
import { pullStates } from '../../functions/fetchLinkedObjects';

export interface ContactManagementModalData {
  uid: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  title: string;
  contactType: SelectOptions;
  streetAddress: string;
  city: string;
  state: SelectOptions;
  zipCode: string;
}

interface ContactManagementModalState {
  data: ContactManagementModalData;
  states: Array<SelectOptions>;
}

export default class ContactManagementModal extends React.Component<{}, ContactManagementModalState> {

  managementModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  static DefaultData = {
    uid: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    title: "",
    contactType: { label: "Select Type", value: "" },
    streetAddress: "",
    city: "",
    state: { label: "Oregon", value: "36" },
    zipCode: ""
  }

  constructor(props: {}) {
    super(props);
    this.state = {
      states: [],
      data: ContactManagementModal.DefaultData
    }
  }

  async componentDidMount(): Promise<void> {
    let states = await pullStates();
    this.setState({ states: states });
  }

  show(data?: ContactManagementModalData): void {
    if (data === null || data === undefined) {
      this.setState({
        data: ContactManagementModal.DefaultData
      }, () => this.generateData());
    }
    else {
      this.setState({
        data: data
      }, () => this.generateData());
    }
  }

  generateData(): void {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "firstName", name: "First Name", patchUrl: 'api/contacts/first-name', type: "text", placeholder: "Doug", value: this.state.data.firstName, validation: IsNotEmptyValidation } as DataEntryModalFieldText);
    data.push({ key: "lastName", name: "Last Name", patchUrl: 'api/contacts/last-name', type: "text", placeholder: "Chrisman", value: this.state.data.lastName, validation: IsNotEmptyValidation } as DataEntryModalFieldText);
    data.push({ key: "phoneNumber", name: "Phone Number", patchUrl: 'api/contacts/phone-number', type: "text", placeholder: "541-426-3820", value: this.state.data.phoneNumber, validation: IsPhoneNumberValidation } as DataEntryModalFieldText);
    data.push({ key: "emailAddress", name: "Email", patchUrl: 'api/contacts/email-address', type: "text", placeholder: "vmi@cdinc.io", value: this.state.data.emailAddress, validation: IsEmailValidation } as DataEntryModalFieldText);
    data.push({ key: "title", name: "Title", patchUrl: 'api/contacts/title', type: "text", placeholder: "Site Manager", value: this.state.data.title, validation: IsNotEmptyValidation } as DataEntryModalFieldText);
    //data.push({ key: "contactType", name: "Type", patchUrl: 'api/contacts/type', value: this.state.data.contactType, isMulti: false, options: ContactTypes, validation: IsSetValidation } as DataEntryModalFieldSelect);
    data.push({ key: "address", name: "Street Address", patchUrl: 'api/contacts/address', type: "text", value: this.state.data.streetAddress, placeholder: "200 E Main Street", validation: IsNotEmptyValidation } as DataEntryModalFieldText);
    data.push({ key: "city", name: "City", patchUrl: 'api/contacts/city', type: "text", value: this.state.data.city, placeholder: "Enterprise", validation: IsNotEmptyValidation } as DataEntryModalFieldText);
    data.push({ key: "state", name: "State", patchUrl: 'api/contacts/state', isMulti: false, value: this.state.data.state, options: this.state.states } as DataEntryModalFieldSelect);
    data.push({ key: "zipCode", name: "ZipCode", patchUrl: 'api/contacts/zip-code', type: "text", value: this.state.data.zipCode, placeholder: "97828", validation: IsNotEmptyValidation } as DataEntryModalFieldText);
    this.managementModal.current.update(data);
    this.managementModal.current.show("Contact Management", this.state.data.uid, 'api/contacts');
  }

  render(): JSX.Element {
    return (
      <DataEntryModal ref={this.managementModal} callback={() => window.location.reload()} />
    )
  }
}
