import axios from "axios";
import * as React from "react";
import { getBearerToken } from "../../../functions/authActions";
import FilteredWithSettingsTableHeader from "../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { EmployeePropertyManager } from "../PropertyDetailPage.constants";
import { PropertyStaffInformationWidgetProps, PropertyStaffInformationWidgetState } from "./PropertyStaffInformationWidget.constants";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";
import PropertyStaffEditModal from "./PropertyStaffInformationWidget.modals";
import { AllocationRoles } from "../../../constants/EnumConstants";

export default class PropertyStaffInformationWidget extends React.Component<PropertyStaffInformationWidgetProps, PropertyStaffInformationWidgetState> {

  editModal: React.RefObject<PropertyStaffEditModal> = React.createRef<PropertyStaffEditModal>();

  constructor(props: PropertyStaffInformationWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      employeeHeaders: [],
      employees: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.setHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const getManagersResponse = (await axios.get('./api/property-employee-map/' + this.state.uid)).data;

    this.setState({
      employees: getManagersResponse
    })
  }

  setHeaders(): void {
    const employeeHeaders: Array<FilterAndSettingField> = [
      {
        columnName: "Role",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Name",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Phone",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Email",
        additionalClasses: "thirty",
        setting: true,
        filter: {
        }
      }
    ]

    this.setState({
      employeeHeaders: employeeHeaders
    })
  }

  render(): JSX.Element {
    return (
      <>
        <PropertyStaffEditModal ref={this.editModal} />
        {RenderHeader("Staff Information")}
        <div style={{ maxHeight: "25vh", minHeight: "25vh", overflowY: "auto"}}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.employeeHeaders} icons={0} />
            </thead>
            <tbody>
              {this.state.employees.map((employee: EmployeePropertyManager) =>
                <tr key={employee.employee.fullName + employee.employeeSiteRole}>
                  <td className="fixedTableCellFirst">
                    {AllocationRoles[employee.employeeSiteRole]}
                  </td>
                  <td className="fixedTableCell" style={{ background: "#15405c" }}>{employee.employee.fullName}</td>
                  <td className="fixedTableCell" style={{ background: "#15405c" }}>{employee.employee.phoneNumber}</td>
                  <td className="fixedTableCell" style={{ background: "#15405c" }}>{employee.employee.email}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <h6 style={{ fontSize: "10px", textAlign: "center" }} >
          <i>Staff Management is done in the allocations menu. If you have need to update the staff please let the tech team know by submitting
            a ticket and they will handle it.
          </i>
        </h6>
      </>
    )
  }
}