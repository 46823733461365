import * as React from 'react';
import { WarningModal } from './CoreComponents/Modals';

export class AnonymouseConfirmationPage extends React.Component<{}, {message: string, title: string}>{

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    let params = new URLSearchParams(window.location.search);
    let message = params.get('message');
    let title = params.get('title');
    this.state = {
      message: message,
      title: title
    }
  }

  async componentDidMount(): Promise<void> {
    this.warningModal.current.show(this.state.message, this.state.title);
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
      </>
    )
  }
}