import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { pullEntitiesCustomerType, pullEntitiesVendorType, pullExternalCompaniesCustomerType, pullExternalCompaniesVendorType, pullProperties } from '../../functions/fetchLinkedObjects';
import Select from 'react-select';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { DatePicker } from '../CoreComponents/DateComponents';

interface InvoiceCreationModalState {
  show: boolean;

  internalVendors: Array<SelectOptions>;
  externalVendors: Array<SelectOptions>;
  internalCustomers: Array<SelectOptions>;
  externalCustomers: Array<SelectOptions>;
  properties: Array<SelectOptions>;

  vendor: SelectOptions;
  customer: SelectOptions;

  invoiceDate: Date;
}

export default class InvoiceCreationModal extends React.Component<{}, InvoiceCreationModalState>{


  constructor(props: {}) {
    super(props);
    this.state = {
      show: false,
      internalVendors: [],
      externalVendors: [],
      internalCustomers: [],
      externalCustomers: [],
      properties: [],
      vendor: { label: "Select Vendor", value: "" },
      customer: { label: "Select Customer", value: "" },
      invoiceDate: new Date()
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.changeVendor = this.changeVendor.bind(this);
    this.changeCustomer = this.changeCustomer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const internalVendors = await pullEntitiesVendorType();
    const externalVendors = await pullExternalCompaniesVendorType();
    const internalCustomers = await pullEntitiesCustomerType();
    const externalCustomers = await pullExternalCompaniesCustomerType();
    const properties = await pullProperties();
    this.setState({
      internalVendors: internalVendors,
      externalVendors: externalVendors,
      internalCustomers: internalCustomers,
      externalCustomers: externalCustomers,
      properties: properties
    });
  }

  show(): void {
    this.setState({ show: true });
  }

  hide(): void {
    this.setState({ show: false });
  }

  changeVendor(vendor: SelectOptions | null) {
    if (vendor === null) { return; }
    this.setState({ vendor: vendor });
  }

  changeCustomer(customer: SelectOptions | null) {
    if (customer === null) { return; }
    this.setState({ customer: customer });
  }

  changeDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ invoiceDate: event.target.valueAsDate });
  }

  render(): JSX.Element {
    return (
      <Modal
        isOpen={this.state.show}
        style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }}
        toggle={() => this.setState({ show: false })}
        size="lg"
      >
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h4" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c", textAlign: "center" }}>
            Create Invoice
          </ModalHeader>
        </div>
        <div key="modal-body" style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5>Billing Entity</h5>
              </Col>
              <Col>
                <Select 
                  options={[
                    { label: "Entities", options: this.state.internalVendors },
                    { label: "External Companies", options: this.state.externalVendors },
                    { label: "Properties", options: this.state.properties }
                  ]}
                  styles={reactSelectBasicStyle}
                  value={this.state.vendor}
                  onChange={this.changeVendor}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Bill To Entity</h5>
              </Col>
              <Col>
                <Select 
                  options={[
                    { label: "Entities", options: this.state.internalCustomers },
                    { label: "External Companies", options: this.state.externalCustomers },
                    { label: "Properties", options: this.state.properties }
                  ]}
                  styles={reactSelectBasicStyle}
                  value={this.state.customer}
                  onChange={this.changeCustomer}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5>Invoice Date</h5>
              </Col>
              <Col>
                <DatePicker value={this.state.invoiceDate} onChange={null} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}