import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldCheckbox, DataEntryModalFieldSelect, DataEntryModalFieldStatic, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullPropertyName } from '../../../functions/fetchObjectNameFunctions';
import { pullBuildingByPropertyMinimal } from '../../../functions/fetchLinkedObjects';

export interface UnitManagementModalData {
  uid: string;
  property: string;
  propertyUID: string;
  building: SelectOptions;
  name: string;
  squareFeet: number;
  bedrooms: number;
  bathrooms: number;
  halfbaths: number;
  isADA: boolean;
}

interface UnitManagementModalState {
  data: UnitManagementModalData;
  buildings: Array<SelectOptions>;
}

export default class UnitManagementModal extends React.Component<{}, UnitManagementModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      data: {
        uid: "",
        property: "",
        propertyUID: "",
        building: { label: "Select Building", value: "" },
        name: "",
        squareFeet: 0,
        bedrooms: 0,
        bathrooms: 0,
        halfbaths: 0,
        isADA: false
      },
      buildings: []
    }
  }

  async componentDidMount(): Promise<void> {

  }

  async show(propertyUID: string): Promise<void> {
    let property = await pullPropertyName(propertyUID);
    let buildings = await pullBuildingByPropertyMinimal(propertyUID);
    let data = this.state.data;
    data.property = property;
    this.setState({ data: data, buildings: buildings }, async () => this.generateData());
  }

  generateData(): void {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "property", name: "Property", patchUrl: undefined, value: this.state.data.property, static: true } as DataEntryModalFieldStatic);
    data.push({ key: "buildingUID", name: "Building", patchUrl: undefined, value: this.state.data.building, options: this.state.buildings, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "name", name: "Name", patchUrl: undefined, value: this.state.data.name, type: "text", placeholder: "Unit 34" } as DataEntryModalFieldText);
    data.push({ key: "squareFeet", name: "Sq Feet", patchUrl: undefined, value: this.state.data.squareFeet, type: "number" } as DataEntryModalFieldText);
    data.push({ key: "bedrooms", name: "Bedrooms", patchUrl: undefined, value: this.state.data.bedrooms, type: "number" } as DataEntryModalFieldText);
    data.push({ key: "bathrooms", name: "Bathrooms", patchUrl: undefined, value: this.state.data.bathrooms, type: "number" } as DataEntryModalFieldText);
    data.push({ key: "halfbaths", name: "Half Baths", patchUrl: undefined, value: this.state.data.halfbaths, type: "number" } as DataEntryModalFieldText);
    data.push({ key: "isADA", name: "Is ADA", patchUrl: undefined, checkbox: this.state.data.isADA } as DataEntryModalFieldCheckbox);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Unit Management", this.state.data.uid, "./api/units");
  }

  render(): JSX.Element {
    return (
      <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} />
    )
  }
}