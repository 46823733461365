import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { getUserRole, getUserLastLogin, LogoutUser, getBearerToken, AuthState, AUTHENTICATION_SETTINGS_STRING } from '../functions/authActions';
import { GetSettings } from '../functions/StateManagement/StateStorageFunctions';
import axios from 'axios';

export default class AuthenticationContainer extends React.Component<{ children: any }> {

  constructor(props: { children: any }) {
    super(props);
    this.state = { }
  }

  async componentDidMount(): Promise<void> {
    if (window.location.hostname == "trainmci.io" && getUserRole() === -1) {
      window.location.replace("/mci-student-login");
    }
    if (getUserRole() === -1) {
      LogoutUser();
    }
    if (getUserLastLogin().getTime() + (30 * 24 * 60 * 60 * 1000) < Date.now()) {
      // force logout if the user has been logged in for more than 30 days
      LogoutUser();
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  }

  render(): JSX.Element {
    var settings = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING, true);
    // should logout the user
    if (settings === null || settings === undefined) { window.location.assign("/login"); }
    const isAuth = settings.isAuthenticated;
    return (
      !isAuth ?
        <Redirect to="/login" />
        :
        <>
          {this.props.children}
        </>
    )
  }
}
