import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { Jobs, Teams, ValidateAccess } from '../../../functions/authActions';
import PropertyPayrollBudgetModal from './PropertyPayrollBudgetWidget.Modals';
import { WarningModal } from '../../CoreComponents/Modals';

interface PropertyPayrollBudgetWidgetProps {
  uid: string;
}

interface PropertyPayrollBudgetWidgetState {
  headers: Array<FilterAndSettingField>;
  uid: string;
  year: SelectOptions;
  years: Array<SelectOptions>;
  siteStaff: number;
  siteStaffActual: number;
  mainStaff: number;
  mainStaffActual: number;
  phone: number;
  phoneActual: number;
  expense: number;
  expenseActual: number;
  auto: number;
  autoActual: number;
  canChange: boolean;
  canSet: boolean;
}

export default class PropertyPayrollBudgetWidget extends React.Component<PropertyPayrollBudgetWidgetProps, PropertyPayrollBudgetWidgetState> {

  budgetModal: React.RefObject<PropertyPayrollBudgetModal> = React.createRef<PropertyPayrollBudgetModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: PropertyPayrollBudgetWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      uid: props.uid,
      year: { label: new Date().getFullYear().toString(), value: new Date().getFullYear().toString() },
      years: [],
      siteStaff: 0,
      siteStaffActual: 0,
      mainStaff: 0,
      mainStaffActual: 0,
      phone: 0,
      phoneActual: 0,
      auto: 0,
      autoActual: 0,
      expense: 0,
      expenseActual: 0,
      canChange: false,
      canSet: false
    }
    this.changeYear = this.changeYear.bind(this);
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    let years = [];
    for (let i = 2023; i <= new Date().getFullYear() + 1; ++i) {
      years.push({ label: i.toString(), value: i.toString() });
    }
    let canChange = await ValidateAccess([Jobs.Accountant, Jobs.Technology_Lead], [Teams.Property_Management, Teams.Services]);
    this.setState({ years: years, canChange: canChange }, async () => await this.updateData());
  }

  generateHeaders(): void {
    const headers = [
      {
        columnName: "Type",
        setting: true,
        filter: {}
      },
      {
        columnName: "Budget",
        setting: true,
        filter: {}
      },
      {
        columnName: "Year To Date",
        setting: true,
        filter: {}
      }
    ]
    this.setState({ headers: headers });
  }

  async changeYear(event: SelectOptions | null): Promise<void> {
    this.setState({ year: event }, async () => this.updateData());
  }

  async updateData(): Promise<void> {
    let response = await axios.get('./api/property-payroll-budget/' + this.state.uid + "/" + this.state.year.value, { validateStatus: () => true });
    if (response.status === 200 || response.status === 204) {
      if (response.status === 200) {
        this.setState({
          canSet: false,
          siteStaff: response.data.siteManagementBudget,
          mainStaff: response.data.maintenanceTechBudget,
          phone: response.data.phoneBudget,
          expense: response.data.expenseAllowance,
          auto: response.data.autoAllowance
        });
      }
      else {
        this.setState({ canSet: true, siteStaff: 0, mainStaff: 0 });
      }
    }
    else {
      this.warningModal.current.show("Error loading data, please reach out to the tech team about this.", "Error");
    }
    let _response = await axios.get('./api/property-payroll-tracking/year-to-date/' + this.state.uid + "/" + this.state.year.value, { validateStatus: () => true });
    if (_response.status === 200) {
      this.setState({
        siteStaffActual: _response.data.site,
        mainStaffActual: _response.data.maintenance,
        phoneActual: _response.data.phone,
        expenseActual: _response.data.expense,
        autoActual: _response.data.auto
      })
    }
  }

  callback(success: boolean): void {
    if (success) {
      this.warningModal.current.show("Property Budget has been added", "Success");
    }
    else {
      this.warningModal.current.show("Error adding property budget, please contact the tech team about this issue", "Error");
    }
  } 

  render(): JSX.Element {
    return (
      <div>
        <PropertyPayrollBudgetModal ref={this.budgetModal} uid={this.state.uid} callback={this.callback} />
        <WarningModal ref={this.warningModal} />
        <h5 style={{ textAlign: "center", width: "100%" }}>Property Payroll Budgetting</h5>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <div style={{ height: "26vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              <tr key={this.state.year + "site"}>
                <td className="fixedTableCellFirst">Site Manager</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.siteStaff)}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.siteStaffActual)}</td>
              </tr>
              <tr key={this.state.year + "maintenance"}>
                <td className="fixedTableCellFirst">Maintenance</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.mainStaff)}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.mainStaffActual)}</td>
              </tr>
              <tr key={this.state.year + "phone"}>
                <td className="fixedTableCellFirst">Phone Allowance</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.phone)}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.phoneActual)}</td>
              </tr>
              <tr key={this.state.year + "expense"}>
                <td className="fixedTableCellFirst">Expense Reimbursements</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.expense)}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.expenseActual)}</td>
              </tr>
              <tr key={this.state.year + "auto"}>
                <td className="fixedTableCellFirst">Auto Allowance</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.auto)}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.autoActual)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row>
          <Col>
            <Select
              options={this.state.years}
              styles={reactSelectBasicStyle}
              value={this.state.year}
              onChange={this.changeYear}
              menuPlacement="top"
              menuPosition="fixed"
            />
          </Col>
          <Col>
            {this.state.canChange && this.state.canSet && <input type="button" value="Set Budget" className="standard-input" onClick={() => this.budgetModal.current.show()} />}
          </Col>
        </Row>
      </div>
    )
  }
}