import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import AddSubcontractorToDrawModal, { AddSubcontractorToDrawModalData } from '../Modals/AddSubcontractorToDrawModal';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { YesNoAll } from '../../../constants/EnumConstants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface SubcontractingWaiverManagementProps {
  uid: string;
}

interface SubcontractingWaiverManagementState {
  headers: Array<FilterAndSettingField>;
  data: Array<AddSubcontractorToDrawModalData>;
  subcontractorName: string;
  siteCode: string;
  draw: number;
  isPaid: SelectOptions;
  isFinal: SelectOptions;
}

export default class SubcontractingWaiverManagement extends React.Component<SubcontractingWaiverManagementProps, SubcontractingWaiverManagementState> {

  managementModal: React.RefObject<AddSubcontractorToDrawModal> = React.createRef<AddSubcontractorToDrawModal>();

  constructor(props: SubcontractingWaiverManagementProps) {
    super(props);
    this.state = {
      headers: [],
      data: [],
      subcontractorName: "",
      siteCode: "",
      draw: 0,
      isPaid: { label: "All", value: "0" },
      isFinal: { label: "All", value: "0" }
    }
    this.setSubcontractorName = this.setSubcontractorName.bind(this);
    this.setSiteCode = this.setSiteCode.bind(this);
    this.setDraw = this.setDraw.bind(this);
    this.setIsPaid = this.setIsPaid.bind(this);
    this.setIsFinal = this.setIsFinal.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async pullData(): Promise<void> {
    const data = {
      JobUID: this.props.uid,
      SubcontractorName: this.state.subcontractorName,
      SiteCode: this.state.siteCode,
      Draw: this.state.draw,
      IsPaid: this.state.isPaid.value,
      IsFinal: this.state.isFinal.value
    }
    let response = await axios.post('./api/subcontractor-setup/job/filter', data);
    this.setState({ data: response.data });
  }

  setSubcontractorName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ subcontractorName: event.target.value }, async () => await this.generateHeaders());
  }

  setSiteCode(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ siteCode: event.target.value }, async () => await this.generateHeaders());
  }

  setDraw(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ draw: parseInt(event.target.value) || 0  }, async () => await this.generateHeaders());
  }

  setIsPaid(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ isPaid: event }, async () => await this.generateHeaders());
  }

  setIsFinal(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ isFinal: event }, async () => await this.generateHeaders());
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Subcontractor",
        setting: true,
        filter: {
          value: this.state.subcontractorName,
          changeFilter: this.setSubcontractorName,
          type: "text",
          placeHolder: "Subcontractor",
          name: "subcontractor"
        },
        additionalClasses: "twenty"
      },
      {
        columnName: "Site",
        setting: true,
        filter: {
          value: this.state.siteCode,
          changeFilter: this.setSiteCode,
          type: "text",
          placeHolder: "vmi",
          name: "site"
        },
        additionalClasses: "ten"
      },
      {
        columnName: "Draw",
        setting: true,
        filter: {
          value: this.state.draw,
          changeFilter: this.setDraw,
          type: "number",
          placeHolder: "0",
          name: "draw"
        },
        additionalClasses: "seven"
      },
      {
        columnName: "Gross",
        setting: true,
        filter: {},
        additionalClasses: "seven"
      },
      {
        columnName: "Net",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      },
      {
        columnName: "Paid",
        setting: true,
        additionalClasses: "seven",
        filter: {
          options: YesNoAll,
          value: this.state.isPaid,
          changeFilter: this.setIsPaid,
          multi: false,
          name: "isPaid"
        },
      },
      {
        columnName: "Final",
        setting: true,
        filter: {
          options: YesNoAll,
          value: this.state.isFinal,
          changeFilter: this.setIsFinal,
          multi: false,
          name: "isFinal"
        },
        additionalClasses: "seven"
      },
      {
        columnName: "",
        setting: true,
        filter: {},
        additionalClasses: "icon"
      }
    ]
    this.setState({ headers: headers }, async () => await this.pullData());
  }

  render(): JSX.Element {
    return (
      <>
        <AddSubcontractorToDrawModal ref={this.managementModal} uid={this.props.uid} oldSystem={false} />
        {RenderHeader("Subcontractor Waiver")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.subcontractor.label}</td>
                    <td className="fixedTableCell">{item.site.label}</td>
                    <td className="fixedTableCell">{item.draw.label}</td>
                    <td className="fixedTableCell">${numberWithCommas(item.grossAmount, 2)}</td>
                    <td className="fixedTableCell">${numberWithCommas(item.netAmount, 2)}</td>
                    <td className="fixedTableCell">{item.isPaid ? "Yes" : "No"}</td>
                    <td className="fixedTableCell">{item.isFinal ? "Yes" : "No"}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faCog} onClick={() => this.managementModal.current.show(item, { label: "Select Site", value: "" }, { label: "Select Draw", value: ""})} style={{ marginLeft: "25%" }} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Add Subcontractor" className="standard-input" onClick={() => this.managementModal.current.show(null, { label: "Select Site", value: "" }, { label: "Select Draw", value: "" })} />
      </>

    )
  }
}