

export function SetSettings<Type>(key: string, settings: Type) {
  window.localStorage.setItem(key, JSON.stringify(settings));
}

export function GetSettings<Type>(key: string, disableLogging: boolean = false): Type {
  return JSON.parse(window.localStorage.getItem(key)) as Type;
}

export function SetSettingKey<GroupType, SettingType>(settingKey: string, valueKey: string, settingValue: SettingType) {
  var settings = GetSettings<GroupType>(settingKey);
  //@ts-ignore
  settings[valueKey] = settingValue;
  SetSettings(settingKey, settings);
}

