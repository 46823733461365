import * as React from 'react';
import { Redirect } from "react-router-dom";
import { LogoutUser } from '../../functions/authActions';

interface LogoutProps {
  logout: () => void;
}

interface LogoutState {

}

export default class Logout extends React.Component<LogoutProps, LogoutState>{
  async componentDidMount(): Promise<void> {
    LogoutUser();
  }

  render() {
    return (
      <div><Redirect to={{
        pathname: '/'
      }} /></div>
    );
  }
};
