import axios from "axios";
import { SelectOptions } from "../interfaces/CoreInterfaces";

export async function PullRegionalManager(uid: string): Promise<SelectOptions> {
  let response = await axios.get('./api/property-employee-map/regionals/' + uid);
  return response.data;
}

export async function PullSiteManagers(uid: string): Promise<Array<SelectOptions>> {
  let response = await axios.get('./api/property-employee-map/site-managers/' + uid);
  return response.data;
}