import * as React from 'react';
import { Row, Col } from "reactstrap";
import axios from 'axios';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { EMPTY_GUID } from '../../constants/DefaultConstants';
import ElectricUtilityRateTable from './ElectricUtilityRateTable';
import { pullStates } from '../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../style/select-constants';
import Select from 'react-select';
import ElectricDataRow, { ElectricRow } from './ElectricDataRow';

interface ElectricUtilityRateFormProps {

}

interface ElectricUtilityRateFormState {
  utility: string;
  rate: string;
  state: SelectOptions;
  states: Array<SelectOptions>;
  residentialRates: Array<any>;
  commercialRates: Array<any>;
  consumption: number;
  demand: number;
  utilityName: string;
  rateName: string;
  rateType: SelectOptions;
  rows: Array<ElectricRow>;

  weightedConsumption: number;
  weightedDemand: number;
  flatFees: number;
  taxRate: number;
  totalCost: number;

  loaded: boolean;
  firstTime: boolean;
}

export class ElectricUtilityRateForm extends React.Component<ElectricUtilityRateFormProps, ElectricUtilityRateFormState> {

  static displayName = ElectricUtilityRateForm.name;

  static RateTypes = [
    { label: "Residential", value: "0" },
    { label: "Commercial", value: "1" }
  ]

  constructor(props: ElectricUtilityRateFormProps) {
    super(props);
    let params = new URLSearchParams(window.location.search);
    let rate = params.has('rate') ? params.get('rate') : '';
    let utility = params.has('utility') ? params.get('utility') : '';
    this.state = {
      utility: utility,
      rate: rate,
      state: { label: "Oregon", value: "36" },
      states: [],
      residentialRates: [],
      commercialRates: [],
      rows: [],
      consumption: -1,
      demand: -1,
      utilityName: '',
      rateName: '',
      rateType: { label: "Residential", value: "0" },
      weightedConsumption: 0.0,
      weightedDemand: 0.0,
      flatFees: 0.0,
      taxRate: 0.0,
      totalCost: 0.0,
      loaded: false,
      firstTime: true
    }
    this.calculate = this.calculate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setInitialData = this.setInitialData.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.changeRateName = this.changeRateName.bind(this);
    this.changeState = this.changeState.bind(this);
    this.changeRateType = this.changeRateType.bind(this);
    this.changeConsumption = this.changeConsumption.bind(this);
    this.changeDemand = this.changeDemand.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    const states = await pullStates();
    this.setState({
      states: states
    })
    await axios.get("./api/electric-utility-providers/" + this.state.utility).then(function (response) {
      self.setState({
        utilityName: response.data.name
      })
    });
    if (this.state.rate !== '') {
      await axios.get('./api/electric-rate/' + this.state.rate).then(function (response) {
        self.setInitialData(response.data);
      })
    }
  }

  /**
   * Get the effective weight as compared to the start & end barriers
   * with the current consumption.
   * @param start
   * @param end
   * @param consumption
   */
  getEffectiveWeight(start: number, end: number, consumption: number): any {
    var ret = 0;
    if (end == -1) {
      ret = consumption - start;
    }
    else {
      var tmp = end - start;
      ret = consumption < tmp ? consumption : (end - start);
    }
    return ret;
  }

  updateRow(row: ElectricRow, idx: number): void {
    let rows: Array<ElectricRow> = this.state.rows;
    rows[idx] = row;
    this.setState({
      loaded: false,
      rows: rows
    }, this.calculate)
  }

  calculate(): void {
    let rows: Array<ElectricRow> = this.state.rows;
    let consumption = this.state.consumption;
    let demand = this.state.demand;
    if (consumption < 0 || demand < 0) {
      CreateGlobalAlert(`Demand & Consumption must be filled in before Calculating Rates.`, 3000);
      return;
    }
    let self = this;
    let total = 0;
    let flat = 0;
    let consumptionRates: any = [];
    let demandRates: any = [];
    let queue: any = [];
    rows.forEach(function (element: ElectricRow, idx: number) {
      let rate = element.rate;
      let startRate = element.start;
      let endRate = element.end;
      let type = element.calculationType?.value;
      if (type === '2') {
        total += rate;
        flat += rate;
        element.estimatedCost = rate;
      }
      else if (type === '1') {
        let consumptionFactor = self.getEffectiveWeight(startRate, endRate, demand);
        let cost = (rate * consumptionFactor);
        total += cost;
        element.estimatedCost = cost;
        demandRates.push({
          factor: consumptionFactor,
          rate: rate
        });
      }
      else if (type === '3') {
        queue.push(element);
      }
      else {
        let consumptionFactor = self.getEffectiveWeight(startRate, endRate, consumption);
        let cost = (rate * consumptionFactor);
        total += cost;
        element.estimatedCost = cost;
        consumptionRates.push({
          factor: consumptionFactor,
          rate: rate
        });
      }
      rows[idx] = element;
    });
    /*
     * We cache total here so we don't add basis to tax
     */
    let tmpTotal = total;
    let taxRate = 0;
    queue.forEach(function (element: ElectricRow) {
      var rate = element.rate;
      taxRate += rate;
      var tax = rate * total;
      element.estimatedCost = tax;
      tmpTotal += tax;
    })
    total = tmpTotal;
    let consumptionRate = 0;
    consumptionRates.forEach(function (item: any) {
      consumptionRate += item.rate * (item.factor / consumption);
    });
    let demandRate = 0;
    demandRates.forEach(function (item: any) {
      demandRate += item.rate * (item.factor / demand);
    });
    this.setState({
      loaded: true,
      rows: rows,
      weightedConsumption: consumptionRate,
      weightedDemand: demandRate,
      flatFees: flat,
      taxRate: taxRate,
      totalCost: total
    })
    if (this.state.firstTime) {
      this.setState({
        firstTime: false
      })
    }
  }

  changeRateName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      rateName: event.target.value
    })
  }

  changeState(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      state: event
    })
  }

  changeRateType(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      rateType: event
    })
  }

  changeConsumption(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      consumption: parseFloat(event.target.value)
    }, () => this.calculate)
  }

  changeDemand(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      demand: parseFloat(event.target.value)
    }, this.calculate)
  }

  async onSubmit(): Promise<void> {
    const data = {
      UID: EMPTY_GUID,
      Name: this.state.rateName,
      WeightedConsumptionCharge: 0,
      WeightedDemandCharge: 0,
      Type: this.state.rateType.value,
      IsActive: true,
      State: this.state.state.value,
      ElectricUtilityProviderUID: this.state.utility,
      ExampleConsumption: this.state.consumption,
      ExampleDemand: this.state.demand,
      TotalTaxPercentage: this.state.taxRate,
      TotalFlatCharges: this.state.flatFees
    }
    var self = this;
    if (this.state.rate === '') {
      await axios.post('./api/electric-rate', data).then(function (response) {
        self.setState({ rate: response.data });
        self.addLineItem(response.data);
      });
    }
    else {
      data.UID = this.state.rate;
      await axios.post('./api/electric-rate/' + this.state.rate + "/clear", data).then(() => (
        this.addLineItem(self.state.rate)
      ))
    }
  }

  async addLineItem(rate: string): Promise<void> {
    let output: Array<any> = [];
    this.state.rows.forEach(function (element, idx) {
      let data = {
        ElectricRateUID: rate,
        Description: element.description,
        Rate: element.rate,
        StartKwh: element.start,
        EndKwh: element.end,
        LineType: element.calculationType.value
      };
      output.push(data);
    });
    await axios.put("./api/electric-rate/" + rate + "/add-lines", output).then(function (response) {
      CreateGlobalAlert(`Rate saved successfully!`, 3000);
    });
  }

  setInitialData(data: any): void {
    let rows: Array<ElectricRow> = [];
    data.rateLines.forEach(function (value: any) {
      rows.push({
        description: value.description,
        rate: value.rate,
        start: value.startKwh,
        end: value.endKwh,
        calculationType: ElectricDataRow.CalculationTypes[value.lineType],
        estimatedCost: 0.0
      });
    })
    this.setState({
      consumption: data.exampleConsumption,
      demand: data.exampleDemand,
      rows: rows,
      state: this.state.states[data.state],
      rateType: ElectricUtilityRateForm.RateTypes[data.type],
      rateName: data.name,
      loaded: true
    }, this.calculate);
  }

  render(): JSX.Element {
    return (
      <div id="electric-utility-manager">
        <h3 id="utility-name">{this.state.utilityName}</h3>
        <Row>
          <Col>
            <label>Name</label>
            <br />
            <input id="name" className="standard-input" name="name" type="text" placeholder="Schedule 28" value={this.state.rateName}
              onChange={this.changeRateName}
            />
          </Col>
          <Col>
            <label>State</label>
            <br />
            <Select
              options={this.state.states}
              value={this.state.state}
              styles={reactSelectBasicStyle}
              onChange={this.changeState}
            />
          </Col>
          <Col>
            <label>Rate Type</label>
            <br />
            <Select
              options={ElectricUtilityRateForm.RateTypes}
              value={this.state.rateType}
              styles={reactSelectBasicStyle}
              onChange={this.changeRateType}
            />
          </Col>
          <Col>
            <label>Example Consumption</label>
            <br />
            <input id="consumption" className="standard-input" type="number" placeholder="1000" onChange={this.changeConsumption}
              value={this.state.consumption}
            />
          </Col>
          <Col>
            <label>Example Demand</label>
            <br />
            <input id="demand" className="standard-input" type="number" placeholder="20" onChange={this.changeDemand}
              value={this.state.demand}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Rate Table</h5>
            <div id="utility-management-table-container" style={{ maxHeight: "72vh", overflow: "scroll" }}>
              {this.state.loaded ?
                <ElectricUtilityRateTable rows={this.state.rows} updateRow={this.updateRow}
                  flatFees={this.state.flatFees} taxRate={this.state.taxRate} consumption={this.state.weightedConsumption}
                  demand={this.state.weightedDemand} estimatedCost={this.state.totalCost}
                />
                :
                <div className="lds-dual-ring" style={{ marginLeft: "50%", marginTop: "15%" }}>

                </div>
              }
            </div>
            <input type="button" value="Save" style={{ width: "100%", marginTop: "20px" }} onClick={this.onSubmit} />
          </Col>
        </Row>
      </div>
    )
  }
}