import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import TicketPrimaryCategoryModal from './TicketPrimaryCategoriesWidget.modals';
import axios from 'axios';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketPrimaryCategoriesWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<TicketPrimaryCategoryData>;
}

interface TicketPrimaryCategoryData {
  uid: string;
  name: string;
  totalCount: number;
  secondaries: number;
}

export default class TicketPrimaryCategoriesWidget extends React.Component<{}, TicketPrimaryCategoriesWidgetState> {

  modal: React.RefObject<TicketPrimaryCategoryModal> = React.createRef<TicketPrimaryCategoryModal>();

  constructor(props: {}) {
    super(props);

    this.state = {
      headers: [],
      data: []
    }
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Category",
        filter: {},
        setting: true
      },
      {
        columnName: "Secondaries",
        filter: {},
        setting: true
      },
      {
        columnName: "Tickets",
        filter: {},
        setting: true
      },
      {
        columnName: "",
        filter: {},
        setting: true
      }
    ]
    this.setState({ headers: headers }, async () => await this.updateData());
  }

  async updateData(): Promise<void> {
    let response = await axios.get('./api/primary-ticket-category', { validateStatus: () => true });
    if (response.status === 200) {
      let data: Array<TicketPrimaryCategoryData> = [];
      response.data.forEach((item: any) => {
        data.push({
          uid: item.uid,
          name: item.name,
          totalCount: item.totalCount,
          secondaries: item.secondaryTicketCategories.length
        })
      });
      this.setState({ data: data });
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <TicketPrimaryCategoryModal ref={this.modal} />
        {RenderHeader("Primary Categories")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{item.secondaries}</td>
                    <td className="fixedTableCell">{item.totalCount}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Review" className="standard-input" />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create" className="standard-input" onClick={() => this.modal.current.show()} />
      </div>
    )
  }
}