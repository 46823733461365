import * as React from 'react';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import PhoneCheckoutModal from './PhoneCheckoutManagementWidget.modals';
import { WarningModal } from '../CoreComponents/Modals';
import axios from 'axios';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';


export interface PhoneCheckoutSummary {
  uid: string;
  phoneTracking: SelectOptions;
  employee: SelectOptions;
  reason: string;
  returnDate: Date;
  checkedOutDate: Date;
}

interface PhoneCheckoutManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<PhoneCheckoutSummary>
}

export default class PhoneCheckoutManagementWidget extends React.Component<{}, PhoneCheckoutManagementWidgetState> {

  checkoutModal: React.RefObject<PhoneCheckoutModal> = React.createRef<PhoneCheckoutModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  constructor(props: {}) {
    super(props);

    this.state = {
      headers: [],
      data: []
    }
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async updateData(): Promise<void> {
    const data = {
      page: 0,
      pageCount: 0,
    }
    let response = await axios.post('./api/phone-checkout/filter', data, { validateStatus: () => true });
    if (response.status === 200) {
      console.log(response.data);
      let data: Array<PhoneCheckoutSummary> = [];
      response.data.forEach((item: any) => {
        data.push({
          employee: { label: item.employee.fullName, value: item.employee.uid },
          uid: item.uid,
          phoneTracking: { label: item.phoneTracking.phoneNumber, value: item.phoneTracking.uid },
          reason: item.reason,
          returnDate: new Date(item.returnDate),
          checkedOutDate: new Date(item.checkedOutDate)
        })
      })
      this.setState({ data: data });
    }
    else {
      this.warningModal.current.show("There was an error loading data. Please reach out to the tech team to let them know.", "Error");
    }
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Phone Number",
        setting: true,
        filter: {}
      },
      {
        columnName: "Employee",
        setting: true,
        filter: {}
      },
      {
        columnName: "Reason",
        setting: true,
        filter: {}
      },
      {
        columnName: "Checked Out",
        setting: true,
        filter: {}
      },
      {
        columnName: "Return Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
    this.setState({ headers: headers }, async () => await this.updateData());
  }

  callback(edit: boolean): void {
    this.checkoutModal.current.hide();
    if (!edit) {
      this.warningModal.current.show("Phone Checkout Tracking has been created.", "Phone Checkout Tracking Created", () => window.location.reload());
    }
    else {
      window.location.reload();
    }
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        <PhoneCheckoutModal ref={this.checkoutModal} callback={this.callback} />
        {RenderHeader("Phone Checking Tracking")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.phoneTracking.label}</td>
                    <td className="fixedTableCell">{item.employee.label}</td>
                    <td className="fixedTableCell">{item.reason}</td>
                    <td className="fixedTableCell">{item.checkedOutDate.toLocaleDateString('en-CA')}</td>
                    <td className="fixedTableCell">{item.returnDate.toLocaleDateString('en-CA')}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Returned" className="standard-input" onClick={null} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create" className="standard-input" onClick={() => this.checkoutModal.current.show()} />
      </>
    )
  }
}