import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullNonPropertyPaidProperties } from '../../../functions/fetchLinkedObjects';
import { DatePicker } from '../../CoreComponents/DateComponents';
import axios from 'axios';
import { getUserID } from '../../../functions/authActions';
import { DownloadNamedFile } from '../../../functions/documentTools';
import { numberWithCommas } from '../../../functions/numberFunctions';

interface TenantReleaseFileLoad {
  uid: string;
  name: string;
  size: string;
}

interface UtilityAnalysisWorkflowModalProps {
  callback(successful: boolean, message?: string): void;
}

interface UtilityAnalysisWorkflowModalState {
  uid: string;
  show: boolean;
  properties: Array<SelectOptions>;
  property: SelectOptions;
  screen: number;
  status: number;

  utilityCompany: string;
  utilityContact: string;
  currentAssigneeUID: string;
  propertyForm: File;

  tenantReleaseFiles: Array<File>;
  tenantReleaseFileNames: Array<TenantReleaseFileLoad>;

  usageDataReceived: Date;
  dateSubmitted: Date;
  utilityAnalysisCompleted: Date;

  usageDataController: AbortController;
  dateSubmittedController: AbortController;
  completedController: AbortController;
}

export default class UtilityAnalyisWorkflowModal extends React.Component<UtilityAnalysisWorkflowModalProps, UtilityAnalysisWorkflowModalState>{

  constructor(props: UtilityAnalysisWorkflowModalProps) {
    super(props);
    this.state = {
      uid: "",
      show: false,
      properties: [],
      property: { label: "Select Property", value: "" },
      screen: 0,
      status: 0,
      utilityCompany: "",
      utilityContact: "",
      currentAssigneeUID: "",
      propertyForm: null,
      tenantReleaseFiles: [],
      tenantReleaseFileNames: [],
      usageDataReceived: new Date(),
      dateSubmitted: new Date(),
      utilityAnalysisCompleted: new Date(),
      usageDataController: null,
      dateSubmittedController: null,
      completedController: null
    }
    this.hide = this.hide.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goForward = this.goForward.bind(this);
    this.changeUtilityCompany = this.changeUtilityCompany.bind(this);
    this.changeUtilityContact = this.changeUtilityContact.bind(this);
    this.changeUsageData = this.changeUsageData.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
    this.changePropertyForm = this.changePropertyForm.bind(this);
    this.changeUsageData = this.changeUsageData.bind(this);
    this.changeDateSubmitted = this.changeDateSubmitted.bind(this);
    this.changeUACompleted = this.changeUACompleted.bind(this);
    this.updateUsageData = this.updateUsageData.bind(this);
    this.updateDateSubmitted = this.updateDateSubmitted.bind(this);
    this.updateUACompleted = this.updateUACompleted.bind(this);
    this.downloadPropertyForm = this.downloadPropertyForm.bind(this);
    this.addTenantReleaseFile = this.addTenantReleaseFile.bind(this);
    this.removeTenantReleaseFile = this.removeTenantReleaseFile.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullNonPropertyPaidProperties();
    this.setState({ properties: properties });
  }

  async show(uid: string): Promise<void> {
    if (uid !== "") {
      let response = await axios.get('./api/utility-analysis-workflow/' + uid);
      this.setState({
        uid: uid,
        status: response.data.status,
        utilityCompany: response.data.utilityCompany,
        utilityContact: response.data.utilityContact,
        property: response.data.property,
        currentAssigneeUID: response.data.currentAssigneeUID,
        tenantReleaseFileNames: response.data.tenantReleases,
        usageDataReceived: new Date(response.data.usageDataReceived),
        dateSubmitted: new Date(response.data.dateSubmitted),
        utilityAnalysisCompleted: new Date(response.data.utilityAnalysisComplete),
        show: true
      });
    }
    else {
      this.setState({ show: true });
    }
  }

  async downloadPropertyForm(): Promise<void> {
    let response = await axios.get('./api/utility-analysis-workflow/property-form/' + this.state.uid, { responseType: "blob" });
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
  }

  async addTenantReleaseFile(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    let files = event.target.files;
    let currentFiles = this.state.tenantReleaseFiles;
    for (let i = 0; i < files.length; ++i) {
      let file = files[i];
      currentFiles.push(file);
    }
    this.setState({ tenantReleaseFiles: currentFiles });
  }

  hide(): void {
    this.setState({ show: false });
  }

  changeProperty(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ property: event });
  }

  getScreen(): JSX.Element {
    switch (this.state.screen) {
      case 0:
        return this.getSubmissionScreen();
      case 1:
        return this.getTenantUtilityReleases();
      case 2:
        return this.getCompleteHUDRDScreen();
    }
  }

  goBack() {
    let screen = this.state.screen;
    screen--;
    this.setState({ screen: screen });
  }

  goForward() {
    let screen = this.state.screen;
    screen++;
    this.setState({ screen: screen });
  }

  changeUtilityCompany(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ utilityCompany: event.target.value });
  }

  changeUtilityContact(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ utilityContact: event.target.value });
  }

  changePropertyForm(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ propertyForm: event.target.files[0] });
  }

  changeUsageData(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ usageDataReceived: event.target.valueAsDate });
  }

  async updateUsageData(): Promise<void> {
    let controller = new AbortController();
    if (this.state.usageDataController !== null) { this.state.usageDataController.abort(); }
    this.setState({ usageDataController: controller });
    let data = {
      uid: this.state.uid,
      value: this.state.usageDataReceived.toISOString()
    }
    let response = await axios.patch('./api/utility-analysis-workflow/patch-usage-data', data, { signal: controller.signal, validateStatus: () => true });
  }

  changeDateSubmitted(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ dateSubmitted: event.target.valueAsDate });
  }

  async updateDateSubmitted(): Promise<void> {
    let controller = new AbortController();
    if (this.state.dateSubmittedController !== null) { this.state.dateSubmittedController.abort(); }
    this.setState({ dateSubmittedController: controller });
    let data = {
      uid: this.state.uid,
      value: this.state.dateSubmitted.toISOString()
    }
    let response = await axios.patch('./api/utility-analysis-workflow/patch-date-submitted', data, { signal: controller.signal, validateStatus: () => true });
  }

  changeUACompleted(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ utilityAnalysisCompleted: event.target.valueAsDate });
  }

  async updateUACompleted(): Promise<void> {
    let controller = new AbortController();
    if (this.state.completedController !== null) { this.state.completedController.abort(); }
    this.setState({ completedController: controller });
    let data = {
      uid: this.state.uid,
      value: this.state.utilityAnalysisCompleted.toISOString()
    }
    let response = await axios.patch('./api/utility-analysis-workflow/patch-ua-completed', data, { signal: controller.signal, validateStatus: () => true });
  }

  removeTenantReleaseFile(idx: number) {
    let files = this.state.tenantReleaseFiles;
    files.splice(idx, 1);
    this.setState({ tenantReleaseFiles: files });
  }

  getSubmissionScreen(): JSX.Element {
    return (
      <>
        <h5>Annual Pre-Budget Utility Usage Analysis</h5>
        <p>The HUD Utility Schedule Model (HUSM) is used to calculate the utility allowance for the following types of properties with tenant-paid utilities:</p>
        <ul>
          <li>
            <p>RD</p>
          </li>
          <li>
            <p>HUD</p>
          </li>
          <li>
            <p>HOME, Home/Tax Credit (with post 08/24/2013 HOME funding)</p>
          </li>
        </ul>
        <p>
          HOME, Home/Tax Credit (with pre 08/23/2013 HOME funding) and Tax Credit only properties
          will use the published Housing Authority utility allowances.
        </p>
        <br />
        <p>
          <i>
            *calculated for the property, but voucher holders will utilize the published Housing Authority utility allowance.
          </i>
        </p>
        <h5>Summary Chart</h5>
        <table className="fixedTable">
          <thead>
            <tr>
              <th className="fixedTableHeaderFirst">Funding Type</th>
              <th className="fixedTableHeader">No Voucher</th>
              <th className="fixedTableHeader">Voucher</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fixedTableCellFirst">RD</td>
              <td className="fixedTableCell">HUSM</td>
              <td className="fixedTableCell">HUSM</td>
            </tr>
            <tr>
              <td className="fixedTableCellFirst">HUD</td>
              <td className="fixedTableCell">HUSM</td>
              <td className="fixedTableCell">HUSM</td>
            </tr>
            <tr>
              <td className="fixedTableCellFirst">HOME (funded pre 08/23/2013)</td>
              <td className="fixedTableCell">PHA</td>
              <td className="fixedTableCell">PHA</td>
            </tr>
            <tr>
              <td className="fixedTableCellFirst">HOME (funded after 08/23/2013)</td>
              <td className="fixedTableCell">HUSM</td>
              <td className="fixedTableCell">PHA</td>
            </tr>
            <tr>
              <td className="fixedTableCellFirst">HOME/LIHTC (pre 08/23/2013)</td>
              <td className="fixedTableCell">PHA</td>
              <td className="fixedTableCell">PHA</td>
            </tr>
            <tr>
              <td className="fixedTableCellFirst">HOME/LIHTC (post 08/23/2013)</td>
              <td className="fixedTableCell">HUSM</td>
              <td className="fixedTableCell">PHA</td>
            </tr>
          </tbody>
        </table>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <label>Property</label>
            {
              this.state.status === 0
                ?
                <Select
                  options={this.state.properties}
                  value={this.state.property}
                  onChange={this.changeProperty}
                  styles={reactSelectBasicStyle}
                  required={this.state.property.value === ""}
                />
                :
                <>
                  <br />
                  <label>{this.state.property.label}</label>
                </>
            }
          </Col>
          <Col>
            <label>Form For Property to Fill Out</label>
            {this.state.status === 0 ?
              <>
                <input type="file" className="standard-input" style={{ paddingTop: "0.4vh" }} onChange={this.changePropertyForm}
                  required={this.state.propertyForm === null} />
                <h6 style={{ fontSize: "12px" }}>Please Note - If no form is attached please use move-in files</h6>
              </>
              :
              <input type="button" className="standard-input" value="Download" onClick={this.downloadPropertyForm} />
            }
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <label>Utility Company</label>
            {
              this.state.status === 0
                ?
                <input type="text" value={this.state.utilityCompany} className="standard-input" onChange={this.changeUtilityCompany}
                  required={this.state.utilityCompany === ""}
                />
                :
                <>
                  <br />
                  <label>{this.state.utilityCompany}</label>
                </>
            }
          </Col>
          <Col>
            <label>Utility Contact</label>
            {
              this.state.status === 0
                ?
                <input type="text" value={this.state.utilityContact} className="standard-input" onChange={this.changeUtilityContact}
                  required={this.state.utilityContact === ""}
                />
                :
                <>
                  <br />
                  <label>{this.state.utilityContact}</label>
                </>
            }

          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
      </>
    )
  }

  getTenantUtilityReleases(): JSX.Element {
    return (
      <>
        <h5>Attach Tenant Utility Releases</h5>
        <br />
        <p>Select the attached PDF and have the tenant that pays the bill sign the form.</p>
        <p>*Please note*</p>
        <p>If the tenant who signs the form is NOT the same person as the electric bill, the form will be denied</p>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        {
          this.state.status === 1 &&
          <>
            <input type="file" className="standard-input" style={{ paddingTop: "0.4vh" }} multiple onChange={this.addTenantReleaseFile} />
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          </>
        }
        <div style={{ minHeight: "40vh", maxHeight: "40vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">File</th>
                <th className="fixedTableHeader icon">Size</th>
                {this.state.status === 1 && <th className="fixedTableHeader ten"></th>}
              </tr>
            </thead>
            <tbody>
              {
                this.state.status === 1
                  ?
                  this.state.tenantReleaseFiles.map((item: File, idx: number) => (
                    <tr key={item.name}>
                      <td className="fixedTableCellFirst">{item.name}</td>
                      <td className="fixedTableCell">{numberWithCommas(item.size / 1000, 0)} KB</td>
                      <td className="fixedTableCell">
                        <input type="button" value="Remove" onClick={() => this.removeTenantReleaseFile(idx)} className="standard-input" />
                      </td>
                    </tr>
                  ))
                  :
                  this.state.tenantReleaseFileNames.map((item: TenantReleaseFileLoad, idx: number) => (
                    <tr key={item.name}>
                      <td className="fixedTableCellFirst">{item.name}</td>
                      <td className="fixedTableCell">{item.size}</td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
      </>
    )
  }

  getCompleteHUDRDScreen(): JSX.Element {
    return (
      <>
        <h5>Complete HUD/RD Utility Analysis Form</h5>
        <br />
        <div style={{ maxHeight: "45vh", overflowY: "auto", overflowX: "hidden" }}>
          <p>
            Save the data received from the utility company to the Utility Analysis Folder under Budget Docs. Name the file "[PROPERTY CODE] Usage Data [Year]".
            <br />
            <br />
            Locate the Excel file <b>F:\Viridian Management\Viridian Operations\AA-Utility Analysis\2023 Utility Analysis Documents\HUD Utility Analysis Form 09.2015.</b>
            <br />
            <br />
            Copy and save the file to the Utility Analysis folder under Budget Docs for each property.
            Name the file "[PROPERTY CODE] Utility Analysis [Year]"
            <br />
            <br />
            To Complete the Analysis:
          </p>
          <ol>
            <li>
              <p>Fill in Property Name, Contract Number, Project Number, and Date</p>
            </li>
            <li>
              <p>
                The spreadsheet will determine sample size by entering the number of units the property has in each unit size category. For RD properties, this information can be found on Part IV RENT SCHEDULE of the budget. For HUD properties, this can be found on Part A of the Rent Schedule.
              </p>
            </li>
            <li>
              <p>
                Complete each applicable analysis tab for each unit size with the required number of sample units.
              </p>
              <ol type="a">
                <li>
                  <p>
                    Rename the Date Column headers with the usage month
                  </p>
                </li>
                <li>
                  <p>
                    Fill in the billed usage data for each unit per month
                  </p>
                </li>
                <li>
                  <p>
                    If a unit was vacant and you are required to post all of the units because the amount of units for that unit type was under 20, write 'vacant' in the first column
                  </p>
                </li>
                <li>
                  <p>
                    If a unit does not have at least 10 months of utility data, write '&lt;10 months' in the first column
                  </p>
                </li>
                <li>
                  <p>A unit should be excluded from the sample if:</p>
                  <ul>
                    <li>
                      <p>
                        It has been vacant for 2 or more months in the year. Units included in the sample must have at least 10 months
                        of occupancy.
                      </p>
                    </li>
                    <li>
                      <p>
                        It is receiving an increased utility allowance as a reasonable accomodation
                      </p>
                    </li>
                    <li>
                      <p>
                        It is receiving a flay utility rate as part of a low-income rate assistance utility program.
                      </p>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <p>
                The spreadsheet calculates the average cost for each unit type. Round this amount to the nearest whole dollar and enter it for each bedroom size under Proposed Utility Allowance on the first tab.
              </p>
            </li>
            <li>
              <p>
                Complete the Current Utility Allowance information. For RD properties, this information can be found on Part IV RENT SCHEDULE of the current approved budget. For HUD properties, this can be found on Part A of the current Rent Schedule.
              </p>
            </li>
            <li>
              <p>
                Compare the current utility allowance with the proposed utility allowance. If the difference seems large, and you have double-checked your entries on the analysis tabs, have another Property Accountant review.
              </p>
            </li>
            <li>
              <p>
                Save and enter the completion date below.
              </p>
            </li>
          </ol>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <label>Usage Data Received From Utility Company</label>
            <DatePicker value={this.state.usageDataReceived} onChange={this.changeUsageData} onBlur={this.updateUsageData} />
          </Col>
          <Col>
            <label>Date Submitted to Utility Company</label>
            <DatePicker value={this.state.dateSubmitted} onChange={this.changeDateSubmitted} onBlur={this.updateDateSubmitted} />
          </Col>
          <Col>
            <label>Utility Analysis Completion Date</label>
            <DatePicker value={this.state.utilityAnalysisCompleted} onChange={this.changeUACompleted} onBlur={this.updateUACompleted} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
      </>
    )
  }

  async submit(): Promise<void> {
    if (this.state.status === 0) {
      let data = new FormData();
      data.append("property", this.state.property.value);
      data.append("utilityCompany", this.state.utilityCompany);
      data.append("utilityContact", this.state.utilityContact);
      data.append("propertyForm", this.state.propertyForm);
      data.append("currentAssignee", getUserID());
      this.hide();
      let response = await axios.post('./api/utility-analysis-workflow', data);
      if (response.status === 202) {
        this.props.callback(true, "Utility Analysis has been started, and the relevant parties have been notified");
      }
      else {
        this.props.callback(false);
      }
    }
    else if (this.state.status === 1) {
      this.hide();
      let fileUIDs: Array<string> = [];
      for (let i = 0; i < this.state.tenantReleaseFiles.length; ++i) {
        let file = this.state.tenantReleaseFiles[i];
        let form = new FormData();
        form.append("file", file);
        let fileReturn = await axios.post('./api/utility-analysis-workflow/add-tenant-release-file', form);
        fileUIDs.push(fileReturn.data);
      }
      let data = {
        uid: this.state.uid,
        tenantReleaseFiles: fileUIDs
      }

      let response = await axios.post('./api/utility-analysis-workflow/tenant-release-files', data);
      if (response.status === 202) {
        this.props.callback(true, "Utility Analysis has successfully attached tenant release files. Thank you for submitting these files");
      }
      else {
        this.props.callback(false);
      }
    }
    else if (this.state.status === 2) {
      let data = { WorkflowUID: this.state.uid };
      let response = await axios.post('./api/utility-analysis-workflow/complete-workflow', data);
      if (response.status === 202) {
        this.props.callback(true, "Workflow has been marked as completed and will now be closed.");
      }
      else {
        this.props.callback(false);
      }
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }}
        toggle={this.hide} size="lg">
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h4" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c", textAlign: "center" }}>
            Utility Analysis Workflow
          </ModalHeader>
          <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
            <ModalBody style={{ border: "0px solid #15405c" }}>
              {
                this.getScreen()
              }
              <Row>
                <Col>
                  {this.state.screen > 0 && <input type="button" value="Back" className="standard-input" onClick={this.goBack} />}
                </Col>
                <Col>
                  {this.state.screen < this.state.status
                    ? <input type="button" value="Next" className="standard-input" onClick={this.goForward} />
                    : <input type="button" value="Submit UA" className="standard-input" onClick={this.submit} />
                  }
                </Col>
              </Row>

              {/**/}
            </ModalBody>
          </div>
        </div>
      </Modal>
    )
  }
}