import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { DrawRequestSummaryTableInternal } from '../DashboardWidgets/DrawRequestWorkflowSummaryInternal';
import SignerSettingsWidget from '../DashboardWidgets/SignerSettingsWidget';
import JobSettingsWidget from '../DashboardWidgets/JobSettingsWidget';
import { SiteSettingsManagementWidget } from '../DashboardWidgets/SiteSettingsManagementWidget';

interface JobDashboardOldSystemProps {
  uid: string
}
export default class JobDashboardOldSystem extends React.Component<JobDashboardOldSystemProps, {}> {

  constructor(props: JobDashboardOldSystemProps) {
    super(props);
    this.state = {

    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col style={{ backgroundColor: "#4c4a42", paddingTop: "1vh", border: "2px solid #c2a877", marginLeft: "1%" }}>
            <DrawRequestSummaryTableInternal uid={this.props.uid} />
          </Col>
        </Row>
        <Row style={{ marginTop: "2vh"}} >
          <Col style={{ backgroundColor: "#4c4a42", paddingTop: "1vh", border: "2px solid #c2a877", marginLeft: "1%" }}>
            <JobSettingsWidget uid={this.props.uid} height={"30vh"} />
          </Col>
          <Col style={{ backgroundColor: "#4c4a42", paddingTop: "1vh", border: "2px solid #c2a877", marginLeft: "1%" }}>
            <SignerSettingsWidget uid={this.props.uid} height={"30vh"} />
          </Col>
          <Col style={{ backgroundColor: "#4c4a42", paddingTop: "1vh", border: "2px solid #c2a877", marginLeft: "1%", marginRight: "1%"}}>
            <SiteSettingsManagementWidget uid={this.props.uid} height={"30vh"} />
          </Col>
        </Row>
      </>
    )
  }
}