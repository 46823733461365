import axios from "axios";

export async function PropertyInformationPull(uid: string): Promise<any> {
  let response = await axios.get('./api/property/information/' + uid);
  if (response.status === 200) {
    return response.data;
  }
  else {
    return "Invalid Request";
  }
}