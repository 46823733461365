import * as React from 'react';
import { RelatedPortfolios } from "../../../constants/EnumConstants";
import { EntityEdge, EntityNode, EntityTypes } from "../Structs";

export const checkElementExists = (elements: Array<any>, id: string): boolean => {
  return elements.filter(item => item.id === id).length === 1
}

export const processTree = (nodes: Array<any>): Array<any> => {
  const elements: Array<EntityNode | EntityEdge> = [];
  nodes.forEach(function (item) {
    const type = item.type === 3 ? "dualDouble"
      : item.type === 1 ? "sourceDouble"
        : item.type === 2 ? "targetDouble"
          : "single";
    const element = {
      id: item.entity.name,
      type: type,
      data: {
        label: (
          <>
            {item.entity.name}
          </>
        ),
        id: item.entity.name,
        entityId: item.entity.uid,
        portfolio: RelatedPortfolios[item.entity.relatedPortfolio],
        accountant: item.entity.accountant.fullName,
        notes: item.entity.notes,
        address: item.entity.streetAddress.fullAddress,
        agent: item.entity.registeredAgent?.name,
        firm: item.entity.firm.name,
        type: EntityTypes[item.entity.type],
        fein: item.entity.corporateData.fein,
        sosRenewalDate: item.entity.corporateData.sosRenewalDateShort,
        duns: item.entity.duns,
        samCageCode: item.entity.sam_CAGE_CODE
      },
      position: { x: item.entity.positionX, y: item.entity.positionY },
      isHidden: false
    };
    for (const _idx in item.parents) {
      const parent = item.parents[_idx];
      if (parent.parent === undefined) {
        continue;
      }
      else if (parent.parent.entity === null) {
        continue;
      }
      if (checkElementExists(elements, item.entity.name + "_" + parent.parent.entity.name + "_" + parent.ownershipData.ownershipType)) {
        continue
      }
      const _thisParent: boolean = item.parents.filter((_item: any) => (
        _item.ownershipData?.ownerEntity.Name === parent.parent.entity.name
      )).length === 2;
      elements.push({
        id: item.entity.name + "_" + parent.parent.entity.name + "_" + parent.ownershipData.ownershipType,
        source: parent.parent.entity.name,
        target: item.entity.name,
        sourceHandle: (parent.ownershipData.ownershipType === 2 && _thisParent)
          ? "source_two" : "source_one",
        targetHandle: (parent.ownershipData.ownershipType === 2 && _thisParent)
          ? "target_two" : "target_one",
        type: (parent.ownershipData.ownershipType === 2 && _thisParent)
          ? 'ownershipEdgeOffset' : "ownershipEdge",
        data: {
          label: '',
          uid: parent.ownershipData.UID,
          ownerId: parent.ownershipData.ownerEntityUID,
          entityId: parent.ownershipData.entityId,
          ownershipType: parent.ownershipData.ownershipType,
          ownershipPercentage: parent.ownershipData.percentageOwned,
          isManagingMember: parent.ownershipData.isManagingMember,
          purchaseDate: new Date(parent.ownershipData.startOwnership),
          dateOfSale: new Date(parent.ownershipData.endOwnership),
          parentName: parent.parent.entity.name,
          childName: item.entity.name,
        },
        isHidden: false
      });
    }
    elements.push(element);
  });
  return elements;
}