import * as React from 'react';
import { Col, Row } from 'reactstrap';
import TaxReportProcessorModal from './TaxUploadProcessorWidget.UploadModal';
import { WarningModal } from '../../CoreComponents/Modals';


export default class TaxReportProcessors extends React.Component<{}, {}> {

  processorModal: React.RefObject<TaxReportProcessorModal> = React.createRef<TaxReportProcessorModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = {}

    this.throwError = this.throwError.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  throwError(): void {
    this.warningModal.current.show("Error processing tax upload, can you please contact Johnny so that he can assist in getting it working.", "Error");
  }

  render(): JSX.Element {
    return (
      <>
        <TaxReportProcessorModal ref={this.processorModal} throwError={this.throwError} />
        <WarningModal ref={this.warningModal} />
        <Row>
          <Col>
            <h5>Property Tax Processor</h5>
          </Col>
          <Col>
            <input type="button" value="Run Property Tax Processor" onClick={() => this.processorModal.current.show()} className="standard-input" />
          </Col>
        </Row>
        
      </>
    )
  }
}