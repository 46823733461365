import * as React from 'react'
import Select from 'react-select';
import { LineItemNewModalProps, LineItemNewModalState } from './CNALineItemDisplay.LineItemNewModal.constants';
import { CNALineActions, CNALineCondtions, CNALineItemSelectOptions, CNALineQuantityTypes } from './CNALineItemDisplay.constants';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { YesNo } from '../../../constants/EnumConstants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { PostNewCNALineItem } from './CNALineItemDisplay.LineItemNewModal.functions';
import LineDetails from './CNALineItemDisplay.linedetails';

export default class LineItemNewModal extends React.Component<LineItemNewModalProps, LineItemNewModalState>{

  lineDetailRef: React.RefObject<LineDetails> = React.createRef<LineDetails>();

  constructor(props: LineItemNewModalProps) {
    super(props);
    this.state = {
      show: false,
      title: "",
      lineData: {
        uid: "",
        item: { label: "Please Select Option", value: "", family: 0, elderly: 0 },
        eul: 0,
        age: "",
        rul: "",
        isHealthAndSafetyIssue: { label: "No", value: "0" },
        condition: { label: "Please Select Option", value: "" },
        action: { label: "Please Select Option", value: "" },
        duration: "",
        qty: "",
        unit: { label: "Please Select Option", value: "" },
        unitCost: "",
        totalCost: "",
        comments: ""
      },
      lineOptions: [],
      propType: -1,
      cnaUID: props.cnaUID,
      categoryType: "",
      otherLineType: ""
    }
    this.changeMinorCategory = this.changeMinorCategory.bind(this);
    this.changeOtherLineType = this.changeOtherLineType.bind(this);
    this.changeEUL = this.changeEUL.bind(this);
    this.changeAge = this.changeAge.bind(this);
    this.changeRUL = this.changeRUL.bind(this);
    this.changeIsHealthAndSafetyIssue = this.changeIsHealthAndSafetyIssue.bind(this);
    this.changeCondition = this.changeCondition.bind(this);
    this.changeAction = this.changeAction.bind(this);
    this.changeDuration = this.changeDuration.bind(this);
    this.changeQty = this.changeQty.bind(this);
    this.changeUnit = this.changeUnit.bind(this);
    this.changeUnitCost = this.changeUnitCost.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.callback = this.callback.bind(this);
  }

  show(title: string, lineOptions: Array<CNALineItemSelectOptions>, propType: number, categoryType: string) {

    this.setState({
      show: true,
      title: title,
      lineOptions: lineOptions,
      propType: propType,
      categoryType: categoryType
    })
  }

  changeMinorCategory(event: CNALineItemSelectOptions | null): void {
    if (event === null) { return }
    const item = this.state.lineData
    item.item = event
    this.setState({ lineData: item })
  }

  changeOtherLineType(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ otherLineType: event.target.value })
  }

  changeEUL(event: React.ChangeEvent<HTMLInputElement>): void {
    const item = this.state.lineData
    item.eul = parseInt(event.target.value);
    this.setState({ lineData: item })
  }

  changeAge(event: React.ChangeEvent<HTMLInputElement>): void {
    const item = this.state.lineData
    item.age = event.target.value;
    this.setState({ lineData: item })
  }

  changeRUL(event: React.ChangeEvent<HTMLInputElement>): void {
    const item = this.state.lineData
    item.rul = event.target.value;
    this.setState({ lineData: item })
  }

  changeIsHealthAndSafetyIssue(event: SelectOptions | null): void {
    const item = this.state.lineData
    item.isHealthAndSafetyIssue = event
    let value = false;
    if (event.value === '1') {
      value = true
    }
    this.setState({ lineData: item })
  }

  changeCondition(event: SelectOptions | null): void {
    if (event === null) { return }
    const item = this.state.lineData
    item.condition = event
    this.setState({ lineData: item })
  }

  changeAction(event: SelectOptions | null): void {
    if (event === null) { return }
    const item = this.state.lineData
    item.action = event
    this.setState({ lineData: item })
  }

  changeDuration(event: React.ChangeEvent<HTMLInputElement>): void {
    const item = this.state.lineData
    item.duration = event.target.value;
    this.setState({ lineData: item })
  }

  changeQty(event: React.ChangeEvent<HTMLInputElement>): void {
    const item = this.state.lineData
    item.qty = event.target.value;
    item.totalCost = (parseInt(item.qty) * parseInt(item.unitCost)).toString()
    this.setState({ lineData: item })
  }

  changeUnit(event: SelectOptions | null): void {
    if (event === null) { return }
    const item = this.state.lineData
    item.unit = event
    this.setState({ lineData: item })
  }

  changeUnitCost(event: React.ChangeEvent<HTMLInputElement>): void {
    const item = this.state.lineData
    item.unitCost = event.target.value;
    item.totalCost = (parseInt(item.qty) * parseInt(item.unitCost)).toString()
    this.setState({ lineData: item })
  }

  changeComments(event: React.ChangeEvent<HTMLInputElement>): void {
    const item = this.state.lineData
    item.comments = event.target.value;
    this.setState({ lineData: item })
  }

  async callback(): Promise<void> {

    let health = true

    if (this.state.lineData.isHealthAndSafetyIssue.value) {
      health = false;
    }

    let cat = this.state.lineData.item.label

    if (cat === "Other") {
      cat = "Other: " + this.state.otherLineType
    }

    const data = {
      CNAUID: this.state.cnaUID,
      MajorCategory: this.state.categoryType,
      Minorcategory: cat,
      EUL: this.state.lineData.eul,
      Age: parseInt(this.state.lineData.age),
      RUL: parseInt(this.state.lineData.rul),
      IsHealthAndSafetyIssue: health,
      Condition: parseInt(this.state.lineData.condition.value),
      Action: parseInt(this.state.lineData.action.value),
      Duration: parseInt(this.state.lineData.duration),
      Quantity: parseInt(this.state.lineData.qty),
      QuantityUnitType: parseInt(this.state.lineData.unit.value),
      UnitCost: parseInt(this.state.lineData.unitCost),
      Comments: this.state.lineData.comments,
      Category: this.state.categoryType,
      TotalCost: (parseInt(this.state.lineData.unitCost) * parseInt(this.state.lineData.qty))
    }

    await PostNewCNALineItem(data, "cna-line-item");

    this.setState({
      show: false
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <ModalHeader style={{ backgroundColor: "#4C4A42" }}>
          <h3 style={{ color: "#d7c7a7" }}>{this.state.title}</h3>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          <Row>
            <Col>Line Category:</Col>
            <Col>
              <Select
                value={this.state.lineData.item}
                styles={reactSelectBasicStyle}
                options={this.state.lineOptions}
                onChange={this.changeMinorCategory}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          {this.state.lineData.item.label === "Other" && <><br /><Row>
            <Col>Custom Category:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.otherLineType} onChange={this.changeOtherLineType} />
            </Col>
          </Row></>}
          <br />
          <Row>
            <Col>EUL:</Col>
            <Col>
              {this.state.lineData.item.label !== "Other" && <>{this.state.propType === 0 && <h4>{this.state.lineData.item.family}</h4>}</>}
              {this.state.lineData.item.label !== "Other" && <>{this.state.propType === 1 && <h4>{this.state.lineData.item.elderly}</h4>}</>}
              {this.state.lineData.item.label === "Other" && <input type="number" className="standard-input" value={this.state.lineData.eul} onChange={this.changeEUL} />}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Age:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.age} onChange={this.changeAge} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>RUL:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.rul} onChange={this.changeRUL} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Is Health And Safety Issue:</Col>
            <Col>
              <Select
                value={this.state.lineData.isHealthAndSafetyIssue}
                styles={reactSelectBasicStyle}
                options={YesNo}
                onChange={this.changeIsHealthAndSafetyIssue}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Condition:</Col>
            <Col>
              <Select
                value={this.state.lineData.condition}
                styles={reactSelectBasicStyle}
                options={CNALineCondtions}
                onChange={this.changeCondition}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Action:</Col>
            <Col>
              <Select
                value={this.state.lineData.action}
                styles={reactSelectBasicStyle}
                options={CNALineActions}
                onChange={this.changeAction}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Duration:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.duration} onChange={this.changeDuration} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>QTY:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.qty} onChange={this.changeQty} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Unit:</Col>
            <Col>
              <Select
                value={this.state.lineData.unit}
                styles={reactSelectBasicStyle}
                options={CNALineQuantityTypes}
                onChange={this.changeUnit}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Unit Cost:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.unitCost} onChange={this.changeUnitCost} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Comments:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.lineData.comments} onChange={this.changeComments} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
          <input type="button" value="Submit" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
          />
        </ModalFooter>
      </Modal>
    )
  }
}