import axios from 'axios';
import * as React from 'react';
import { getUserID } from '../../../functions/authActions';
//@ts-ignore
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';
import { numberWithCommas } from '../../../functions/numberFunctions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { DatePicker } from '../../CoreComponents/DateComponents';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { PayAppReport, PayAppReportingProps, PayAppReportingRowProps, PayAppReportingRowState, PayAppReportingState, ServerPayAppReport } from '../Structs';
import { pullEmployeeEmail } from '../../../functions/fetchObjectNameFunctions';

class PayApplicationReportRow extends React.Component<PayAppReportingRowProps, PayAppReportingRowState> {

  constructor(props: PayAppReportingRowProps) {
    super(props);
    this.state = {
      payApp: props.payApp,
      editing: props.editing
    }
    this.changePayAppDistributed = this.changePayAppDistributed.bind(this);
    this.changePayAppFullyExecuted = this.changePayAppFullyExecuted.bind(this);
    this.changePayAppAccuredInYardi = this.changePayAppAccuredInYardi.bind(this);
    this.changeDrawSubmitted = this.changeDrawSubmitted.bind(this);
    this.changeDrawFullyExecuted = this.changeDrawFullyExecuted.bind(this);
    this.changeDrawFund = this.changeDrawFund.bind(this);
    this.changeDeveloperFee = this.changeDeveloperFee.bind(this);
    this.changeGeneralContractorPaid = this.changeGeneralContractorPaid.bind(this);
    this.changeDrawProcessed = this.changeDrawProcessed.bind(this);
    this.changeCDIReimbursed = this.changeCDIReimbursed.bind(this);
    this.changeDrawTiedOut = this.changeDrawTiedOut.bind(this);
    this.changeCDIGLReview = this.changeCDIGLReview.bind(this);
  }

  //#region Change Functions
  async changePayAppDistributed(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.payAppDistributed = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/pay-app-distributed/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Pay App Distributed Date updated.", 2000);
      })
  }

  async changePayAppFullyExecuted(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.payAppFullySigned = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/pay-app-fully-executed/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Pay App Fully Executed Date updated.", 2000);
      })
  }

  async changePayAppAccuredInYardi(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.payAppAccuredInYardi = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/pay-app-accrued/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Pay App Accrual Date updated.", 2000);
      })
  }

  async changeDrawSubmitted(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.drawSubmitted = new Date(event.target.valueAsDate)
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/draw-submitted/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Draw Submitted Date updated.", 2000);
      })
  }

  async changeDrawFullyExecuted(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.drawFullyExecuted = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/draw-fully-executed/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Draw Fully Executed Date updated.", 2000);
      })
  }

  async changeDrawFund(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.drawFunded = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/draw-funded/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Draw Funded Date updated.", 2000);
      })
  }

  async changeDeveloperFee(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.developerFee = parseFloat(event.target.value);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/developer-fee/' + this.state.payApp.uid + "/" + this.state.payApp.developerFee).
      then(function (response) {
        CreateGlobalAlert("Developer Fee updated.", 2000);
      })
  }

  async changeGeneralContractorPaid(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.generalContractorPaid = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/general-contractor-paid/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("General Contractor Paid Updated.", 2000);
      })
  }

  async changeDrawProcessed(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.drawProcessed = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/draw-processed/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Draw Processed Date Updated.", 2000);
      })
  }

  async changeCDIReimbursed(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.cdiReimbursed = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/cdi-reimbursed/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("CDI Reimbursed Date Updated.", 2000);
      })
  }

  async changeDrawTiedOut(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.drawTiedOut = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/draw-tied-out/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("Draw Tied Out Updated.", 2000);
      })
  }

  async changeCDIGLReview(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.payApp.cdiGLReview = new Date(event.target.valueAsDate);
    this.setState({});
    await axios.patch('./api/request-for-payment-workflow/cdi-gl-review/' + this.state.payApp.uid + "/" + new Date(event.target.valueAsDate).toISOString()).
      then(function (response) {
        CreateGlobalAlert("CDI GL Review Updated.", 2000);
      })
  }

  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">{this.state.payApp.job}</td>
        <td className="fixedTableCell">{this.state.payApp.number + 1}</td>
        {!this.state.editing ?
          <>
            <td className="fixedTableCell">
              {this.state.payApp.payAppDistributed.toLocaleDateString() != "1/1/1" && this.state.payApp.payAppDistributed.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.payAppFullySigned.toLocaleDateString() != "1/1/1" && this.state.payApp.payAppFullySigned.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.payAppAccuredInYardi.toLocaleDateString() != "1/1/1" && this.state.payApp.payAppAccuredInYardi.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.drawSubmitted.toLocaleDateString() != "1/1/1" && this.state.payApp.drawSubmitted.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.drawFullyExecuted.toLocaleDateString() != "1/1/1" && this.state.payApp.drawFullyExecuted.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.drawFunded.toLocaleDateString() != "1/1/1" && this.state.payApp.drawFunded.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.developerFee > 0 && "$" + numberWithCommas(this.state.payApp.developerFee)}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.generalContractorPaid.toLocaleDateString() != "1/1/1" && this.state.payApp.generalContractorPaid.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.drawProcessed.toLocaleDateString() != "1/1/1" && this.state.payApp.drawProcessed.toLocaleDateString()}</td>
            <td className="fixedTableCell">
              {this.state.payApp.cdiReimbursed.toLocaleDateString() != "1/1/1" && this.state.payApp.cdiReimbursed.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.drawTiedOut.toLocaleDateString() != "1/1/1" && this.state.payApp.drawTiedOut.toLocaleDateString()}
            </td>
            <td className="fixedTableCell">
              {this.state.payApp.cdiGLReview.toLocaleDateString() != "1/1/1" && this.state.payApp.cdiGLReview.toLocaleDateString()}
            </td>
          </>
          :
          <>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changePayAppDistributed} value={this.state.payApp.payAppDistributed} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changePayAppFullyExecuted} value={this.state.payApp.payAppFullySigned} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changePayAppAccuredInYardi} value={this.state.payApp.payAppAccuredInYardi} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeDrawSubmitted} value={this.state.payApp.drawSubmitted} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeDrawFullyExecuted} value={this.state.payApp.drawFullyExecuted} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeDrawFund} value={this.state.payApp.drawFunded} />
            </td>
            <td className="fixedTableCell">
              <input type="number" value={this.state.payApp.developerFee} onChange={this.changeDeveloperFee}
                className="standard-input"
              />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeGeneralContractorPaid} value={this.state.payApp.generalContractorPaid} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeDrawProcessed} value={this.state.payApp.drawProcessed} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeCDIReimbursed} value={this.state.payApp.cdiReimbursed} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeDrawTiedOut} value={this.state.payApp.drawTiedOut} />
            </td>
            <td className="fixedTableCell">
              <DatePicker className="standard-input" onChange={this.changeCDIGLReview} value={this.state.payApp.cdiGLReview} />
            </td>

          </>
        }
      </tr>
    )
  }
}

export default class PayApplicationDateDashboard extends React.Component<PayAppReportingProps, PayAppReportingState>{

  constructor(props: PayAppReportingProps) {
    super(props);
    this.state = {
      headers: [],
      editing: false,
      payApps: [],
      userEmail: '',
      job: ''
    }
    this.changeJob = this.changeJob.bind(this);
    this.processPayApps = this.processPayApps.bind(this);
    this.pullFilteredJobs = this.pullFilteredJobs.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    let email = await pullEmployeeEmail(getUserID());
    this.setState({ userEmail: email });
    await axios.get("./api/managed-jobs/reporting/null").then(function (response) {
      self.processPayApps(response.data);
    });
  }

  processPayApps(data: any) {
    let payApps: Array<PayAppReport> = [];
    data.forEach(function (item: ServerPayAppReport) {
      payApps.push({
        uid: item.uid,
        job: item.job,
        number: item.number,
        payAppDistributed: new Date(item.payAppDistributed),
        payAppFullySigned: new Date(item.payAppFullSigned),
        payAppAccuredInYardi: new Date(item.payAppAccuredInYardi),
        drawSubmitted: new Date(item.drawSubmitted),
        drawFullyExecuted: new Date(item.drawFullyExecuted),
        drawFunded: new Date(item.drawFunded),
        developerFee: item.developerFee,
        generalContractorPaid: new Date(item.generalContractorPaid),
        drawProcessed: new Date(item.drawProcessed),
        cdiReimbursed: new Date(item.cdiReimbursed),
        drawTiedOut: new Date(item.drawTiedOut),
        cdiGLReview: new Date(item.cdiGLReview)
      })
    });
    this.setState({
      payApps: payApps,
      headers: this.generateHeaders()
    })
  }

  async pullFilteredJobs(): Promise<void> {
    var self = this;
    await axios.get("./api/managed-jobs/reporting/" + (this.state.job ? this.state.job : "null")).then(function (response) {
      self.processPayApps(response.data);
    });
  }

  changeJob(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      job: event.target.value
    }, this.pullFilteredJobs)
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Job",
        setting: true,
        additionalClasses: "fifteen",
        filter: {
          value: this.state.job,
          type: "text",
          name: "job",
          changeFilter: this.changeJob,
          placeHolder: "Filter By Job"
        }
      },
      {
        columnName: "Draw #",
        setting: true,
        additionalClasses: "ten",
        filter: {}
      },
      {
        columnName: "Pay App Dist.",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Pay App Exe.",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Accrued in Yardi",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Draw Submitted",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Draw Exe.",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Draw Fund",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Developer Fee",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "GC Paid",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Draw Processed",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "CDI Reimbursed",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "Draw Tied Out",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      },
      {
        columnName: "CDI GL Review",
        setting: true,
        additionalClasses: "fifteen",
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <>
        <div style={{ height: "83vh", overflow: "auto" }}>
          <h3>Draw / Pay App Tracker</h3>
          {
            (this.state.userEmail === "tanner.gore@chrismandm.com"
              || this.state.userEmail === "emily.robinson@abchase.org"
              || this.state.userEmail === "john.green@cdinc.io")
            &&
            (!this.state.editing ? <input type="button" className="standard-input" value="Edit Dates" style={{ width: "25%" }}
              onClick={() => this.setState({ editing: true })} /> :
              <input type="button" className="standard-input" value="Stop Editing" style={{ width: "25%" }}
                onClick={() => this.setState({ editing: false })} />
            )
          }
          <table className="fixedTable" style={{ width: "120%" }}>
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.payApps.map((item: PayAppReport) => (
                  <PayApplicationReportRow key={item.job + "_" + item.number + "_" + this.state.editing} payApp={item} editing={this.state.editing} />
                ))
              }
            </tbody>
          </table>
        </div>

      </>
    )
  }

}