import * as React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HaversineDistance } from '../../../functions/MapFunctions';
import { LegendTableRow } from './TableRowConsts';

interface WaterFeaturesTableProps {
  map: any;
  water: Array<any>;
  forcedWater: Array<any>;
  center: any;
  property: any;
  updateWater: (water: Array<any>) => void;
  updateForcedWater: (water: Array<any>) => void;
}

interface WaterFeaturesTableState {
  map: any;
  water: Array<any>;
  forcedWater: Array<any>;
  center: any;
  property: any;
}


export default class WaterFeaturesTable extends React.Component<WaterFeaturesTableProps, WaterFeaturesTableState> {

  constructor(props: WaterFeaturesTableProps) {
    super(props);
    this.state = {
      water: props.water,
      map: props.map,
      forcedWater: props.forcedWater,
      center: props.center,
      property: props.property
    }
    this.removeWaterAtIndex = this.removeWaterAtIndex.bind(this);
    this.addWater = this.addWater.bind(this);
    this.changeCustomWaterName = this.changeCustomWaterName.bind(this);
    this.removeCustomWaterAtIndex = this.removeCustomWaterAtIndex.bind(this);
  }

  removeWaterAtIndex(idx: number): void {
    const self = this;
    //this.state.water[idx].marker.setMap(null);
    this.state.water.splice(idx-1, 1);
    this.state.water.forEach(function (item, _idx) {
      item.index = _idx+1;
      self.state.water[_idx] = item;
    });
    this.setState({}, () => this.props.updateWater(this.state.water))
  }

  removeCustomWaterAtIndex(idx: number): void {
    var self = this;
    var water = this.state.forcedWater;
    var marker = water.splice(idx - this.state.water.length - 1, 1);
    marker[0].marker.setMap(null);
    water.forEach(function (item: any, idx: number) {
      item.index = idx + self.state.water.length + 1;
      water[idx] = item;
    });
    this.setState({
      forcedWater: water
    })
  }

  addWater(): void {
    var self = this;
    //@ts-ignore
    var newMarker = new window.google.maps.Marker({
      position: { lat: this.state.center.lat, lng: this.state.center.lng },
      map: this.state.map,
      draggable: true,
      //@ts-ignore
      color: "purple"
    });
    this.state.forcedWater.push({
      index: this.state.water.length + this.state.forcedWater.length+1,
      lat: this.state.center.lat,
      lng: this.state.center.lng,
      //id: id,
      name: "",
      distance: HaversineDistance(this.state.center, this.state.center),
      marker: newMarker
    })
    this.setState({})
    this.props.updateForcedWater(this.state.forcedWater);
    let index = this.state.forcedWater.length - 1;
    newMarker.addListener("drag", function (event: any) {
      let tmp = self.state.forcedWater;
      let id = tmp[index].id;
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();
      tmp[index] = {
        lat: lat,
        lng: lng,
        name: tmp[index].name,
        id: id,
        index: tmp[index].index,
        distance: HaversineDistance({ lat, lng }, self.state.center),
        marker: tmp[index].marker
      };
      self.setState({
        forcedWater: tmp
      }, () => self.props.updateForcedWater(self.state.forcedWater));
    });
  }

  changeCustomWaterName(event: any, index: any): void {
    var item = this.state.forcedWater.filter(function (item) { return item.index === index; })[0];
    var list = this.state.forcedWater;
    var _idx = list.findIndex(function (item) { return item.index === index });
    item.name = event.target.value;
    list[_idx] = item;
    this.setState({
      forcedWater: list
    });
  }

  render(): JSX.Element {
    return (
      <div id="water-features-table" style={{ "overflow": "scroll", height: "41vh" }}>
        <h5 style={{ marginTop: "5px" }}>Water Features</h5>
        <table className="fixedTable">
          <thead>
            <tr>
              <th className="fixedTableHeaderFirst">Index</th>
              <th className="fixedTableHeader">Name</th>
              <th className="fixedTableHeader">Distance</th>
              <th className="icon fixedTableHeader"></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.water.filter(item => item.name !== 'property').map((item) => (
                <LegendTableRow key={item.name} index={item.index} name={item.name} distance={item.distance} canRemove={true} onClick={this.removeWaterAtIndex} />
              ))
            }
            {
              this.state.forcedWater.map((item) =>
              (
                <LegendTableRow key={item.id} index={item.index} name={item.name} distance={item.distance} editable={true} canRemove={true} onChange={this.changeCustomWaterName}
                  onClick={this.removeCustomWaterAtIndex} />
              ))
            }
            <tr>
              <td className="fixedTableCellFirst"></td>
              <td className="fixedTableCell"></td>
              <td className="fixedTableCell"></td>
              <td className="fixedTableCell"><FontAwesomeIcon icon={faPlus} style={{ marginLeft: "8px", cursor: 'pointer' }} onClick={this.addWater} /></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}


