import { SelectGraphOptions } from "../../interfaces/CoreInterfaces";

/**
 * This data is obsolete for pie graphs, please use PieGraphData 
 */
export interface GraphData {
  label: string;
  value: number;
  checked: boolean;
  key: number;
}

export const TestRadiusOptions: Array<SelectGraphOptions> = [
  {
    label: 'Course 1',
    value: '1',
    data: {
      label: 'Course 1',
      value: 55,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Course 2',
    value: '2',
    data: {
      label: 'Course 2',
      value: 80,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Course 3',
    value: '3',
    data: {
      label: 'Course 3',
      value: 10,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Course 4',
    value: '4',
    data: {
      label: 'Course 4',
      value: 44,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Course 5',
    value: '5',
    data: {
      label: 'Course 5',
      value: 70,
      checked: false,
      key: 0
    }
  }
]

export const TestPieOptions: Array<SelectGraphOptions> = [
  {
    label: 'Construction Account',
    value: '1',
    data: {
      label: 'Construction Account',
      value: 5000000,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Solar Account',
    value: '2',
    data: {
      label: 'Solar Account',
      value: 3000000,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Administration Account',
    value: '3',
    data: {
      label: 'Administration Account',
      value: 10000000,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Insititute Account',
    value: '4',
    data: {
      label: 'Insititute Account',
      value: 1500000,
      checked: false,
      key: 0
    }
  },
  {
    label: 'Extra Funds',
    value: '5',
    data: {
      label: 'Extra Funds',
      value: 500000,
      checked: false,
      key: 0
    }
  }
]

export const TestBarOptions: Array<SelectGraphOptions> = [
  {
    label: 'January',
    value: '1',
    data: {
      label: 'January',
      value: 500,
      checked: false,
      key: 0
    }
  },
  {
    label: 'February',
    value: '2',
    data: {
      label: 'February',
      value: 650,
      checked: false,
      key: 0
    }
  },
  {
    label: 'March',
    value: '3',
    data: {
      label: 'March',
      value: 550,
      checked: false,
      key: 0
    }
  },
  {
    label: 'April',
    value: '4',
    data: {
      label: 'April',
      value: 700,
      checked: false,
      key: 0
    }
  },
  {
    label: 'May',
    value: '5',
    data: {
      label: 'May',
      value: 680,
      checked: false,
      key: 0
    }
  },
  {
    label: 'June',
    value: '6',
    data: {
      label: 'June',
      value: 1000,
      checked: false,
      key: 0
    }
  },
  {
    label: 'July',
    value: '7',
    data: {
      label: 'July',
      value: 1500,
      checked: false,
      key: 0
    }
  },
  {
    label: 'August',
    value: '8',
    data: {
      label: 'August',
      value: 1300,
      checked: false,
      key: 0
    }
  },
  {
    label: 'September',
    value: '9',
    data: {
      label: 'September',
      value: 900,
      checked: false,
      key: 0
    }
  },
  {
    label: 'October',
    value: '10',
    data: {
      label: 'October',
      value: 880,
      checked: false,
      key: 0
    }
  },
  {
    label: 'November',
    value: '11',
    data: {
      label: 'November',
      value: 490,
      checked: false,
      key: 0
    }
  },
  {
    label: 'December',
    value: '12',
    data: {
      label: 'December',
      value: 520,
      checked: false,
      key: 0
    }
  }
]

export const TestScatterOptions: Array<SelectGraphOptions> = [
  {
    label: 'January',
    value: '1',
    data: {
      label: 'January',
      value: 500,
      checked: false,
      key: 0
    }
  },
  {
    label: 'February',
    value: '2',
    data: {
      label: 'February',
      value: 650,
      checked: false,
      key: 0
    }
  },
  {
    label: 'March',
    value: '3',
    data: {
      label: 'March',
      value: 550,
      checked: false,
      key: 0
    }
  },
  {
    label: 'April',
    value: '4',
    data: {
      label: 'April',
      value: 700,
      checked: false,
      key: 0
    }
  },
  {
    label: 'May',
    value: '5',
    data: {
      label: 'May',
      value: 680,
      checked: false,
      key: 0
    }
  },
  {
    label: 'June',
    value: '6',
    data: {
      label: 'June',
      value: 1000,
      checked: false,
      key: 0
    }
  },
  {
    label: 'July',
    value: '7',
    data: {
      label: 'July',
      value: 1500,
      checked: false,
      key: 0
    }
  },
  {
    label: 'August',
    value: '8',
    data: {
      label: 'August',
      value: 1300,
      checked: false,
      key: 0
    }
  },
  {
    label: 'September',
    value: '9',
    data: {
      label: 'September',
      value: 900,
      checked: false,
      key: 0
    }
  },
  {
    label: 'October',
    value: '10',
    data: {
      label: 'October',
      value: 880,
      checked: false,
      key: 0
    }
  },
  {
    label: 'November',
    value: '11',
    data: {
      label: 'November',
      value: 490,
      checked: false,
      key: 0
    }
  },
  {
    label: 'December',
    value: '12',
    data: {
      label: 'December',
      value: 520,
      checked: false,
      key: 0
    }
  }
]