import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { GroupOptions, SelectOptions, SelectOptionsProperty } from "../interfaces/CoreInterfaces";


export function RemoveStickyOverlays(): void {
  const cellCollection = document.querySelectorAll(".fixedTableCellFirst");
  for (let i = 0; i < cellCollection.length; i++) {
    let item = cellCollection[i];
    item.className = item.className.replace("fixedTableCellFirst", "fixedTableCellFirstNoStick");
  }

  const headerCollection = document.querySelectorAll(".fixedTableHeaderFirst");
  for (let i = 0; i < headerCollection.length; i++) {
    let item = headerCollection[i];
    item.className = item.className.replace("fixedTableHeaderFirst", "fixedTableHeaderFirstNoStick");
  }
}

export function RestoreStickyOverlays(): void {
  const collection = document.querySelectorAll(".fixedTableCellFirstNoStick");
  for (let i = 0; i < collection.length; i++) {
    let item = collection[i];
    item.className = item.className.replace("fixedTableCellFirstNoStick", "fixedTableCellFirst");
  }

  const headerCollection = document.querySelectorAll(".fixedTableHeaderFirst");
  for (let i = 0; i < headerCollection.length; i++) {
    let item = headerCollection[i];
    item.className = item.className.replace("fixedTableHeaderFirstNoStick", "fixedTableHeaderFirst");
  }
}

/**
 * Filter SelectOptions with already used items. Cleans up select options when we are adding maps to the 
 * database
 * @param entire
 * @param active
 * @returns
 */
export function FilterUsedOptions(entire: Array<SelectOptions>, active: Array<SelectOptions>): Array<SelectOptions> {
  active.forEach((active) => {
    const course = entire.filter(u => u.value === active.value)[0];
    const idx = entire.indexOf(course);
    if (idx !== -1) {
      entire.splice(idx, 1);
    }
  });
  return entire;
}

export function FilterWithPropertyCode(option: FilterOptionOption<SelectOptions>, inputValue: string): boolean {
  return option.label.toLowerCase().trim().includes(inputValue.toLowerCase().trim()) || (option.data as SelectOptionsProperty).code.includes(inputValue)
}

export function SortSelectOptionByLabel(u: SelectOptions, v: SelectOptions): number {
    let x = u.label.toLowerCase();
    let y = v.label.toLowerCase();
    let ret = 0;
    if (x > y) { ret = 1; }
    else if (x < y) { ret = -1; }
    return ret;
}

export function SortGroupOptionByLabel(u: GroupOptions, v: GroupOptions): number {
  let x = u.label.toLowerCase();
  let y = v.label.toLowerCase();
  let ret = 0;
  if (x > y) { ret = 1; }
  else if (x < y) { ret = -1; }
  return ret;
}