import { faArrowLeft, faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Col, Row } from "reactstrap";

//#region Calendar

const CalendarMonths = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' }
]

const CalendarDaysOfWeek = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tues' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thurs' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' }
]

interface CalendarProps {
  calendarSettings: CalendarSettingsInterface;
  keyDates: Array<any>;
}

interface CalendarState {
  fullDate: Date;
  daysInMonth: number;
  calendarSettings: CalendarSettingsInterface;
  keyDates: Array<any>;
}

interface CalendarSettingsInterface {
  iconStyle: React.CSSProperties;
  rowStyle: React.CSSProperties;
  blockStyle: React.CSSProperties;
  dateTextStyle: React.CSSProperties;
  headerStyle: React.CSSProperties;
  headerIconStyle: React.CSSProperties;
  titleStyle: React.CSSProperties;
  dayOfWeekTitleRowStyle: React.CSSProperties;
  dayOfWeekTitleTextStyle: React.CSSProperties;
}

export class Calendar extends React.Component<CalendarProps, CalendarState> {

  constructor(props: CalendarProps) {
    super(props);
    this.state = {
      fullDate: new Date(Date.now()),
      daysInMonth: 0,
      calendarSettings: props.calendarSettings,
      keyDates: props.keyDates
    }
    this.setDaysInMonth = this.setDaysInMonth.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
  }

  componentDidMount(): void {
    this.setState({ fullDate: new Date(this.state.fullDate.setDate(1)) })
    this.setDaysInMonth();
  }

  setDaysInMonth(): void {
    let days = 0;
    if (this.state.fullDate.getMonth() === 0 || this.state.fullDate.getMonth() === 2 || this.state.fullDate.getMonth() === 4 || this.state.fullDate.getMonth() === 6 ||
      this.state.fullDate.getMonth() === 7 || this.state.fullDate.getMonth() === 9 || this.state.fullDate.getMonth() === 11) {
      days = 31
    } else if (this.state.fullDate.getMonth() === 3 || this.state.fullDate.getMonth() === 5 || this.state.fullDate.getMonth() === 8 || this.state.fullDate.getMonth() === 10) {
      days = 30
    } else if (this.state.fullDate.getMonth() === 1 && (this.state.fullDate.getFullYear() % 4) === 0) {
      days = 29
    } else if (this.state.fullDate.getMonth() === 1 && (this.state.fullDate.getFullYear() % 4) !== 0) {
      days = 28
    }
    this.setState({ daysInMonth: days })
  }

  prevMonth(): void {
    this.setState({ fullDate: new Date(this.state.fullDate.setMonth(this.state.fullDate.getMonth() - 1)) })
    this.setDaysInMonth();
  }

  render(): JSX.Element {
    return (
      <div>
        <Row key={Math.random()} style={this.state.calendarSettings.headerStyle}>
          <Col key={Math.random()}>
            <FontAwesomeIcon icon={faArrowLeft} style={this.state.calendarSettings.headerIconStyle}
              onClick={this.prevMonth}
            />
          </Col>
          <Col key={Math.random()} xs="8">
            <h4 style={this.state.calendarSettings.titleStyle}>
              {CalendarMonths.filter(u => u.value === this.state.fullDate.getMonth())[0].label + " " + this.state.fullDate.getFullYear()}
            </h4>
          </Col>
          <Col key={Math.random()}>
            <FontAwesomeIcon icon={faArrowRight} style={this.state.calendarSettings.headerIconStyle}
              onClick={() => this.setState({
                fullDate: new Date(this.state.fullDate.setMonth(this.state.fullDate.getMonth() + 1))
              }, () => this.setDaysInMonth())}
            />
          </Col>
        </Row>
        <Row key={Math.random()} style={this.state.calendarSettings.dayOfWeekTitleRowStyle}>
          {CalendarDaysOfWeek.map((item) =>
            <Col key={item.value}>
              <h6 style={this.state.calendarSettings.dayOfWeekTitleTextStyle}>{item.label}</h6>
            </Col>
          )}
        </Row>
        <Row key={Math.random()}>
          <CalendarDay key={Math.random()} fullDate={this.state.fullDate} dayCount={this.state.daysInMonth} keyDates={this.state.keyDates} calendarSettings={this.state.calendarSettings} />
        </Row>
      </div>
    )
  }
}

interface CalendarDayProps {
  keyDates: Array<CalendarDateInterface>;
  fullDate: Date;
  calendarSettings: CalendarSettingsInterface;
  dayCount: number;
}

interface CalendarDayState {
  keyDates: Array<CalendarDateInterface>;
  fullDate: Date;
  calendarSettings: CalendarSettingsInterface;
  daysInMonth: number;
}

export interface CalendarDateInterface {
  displayDate: Date;
  name: string;
}

class CalendarDay extends React.Component<CalendarDayProps, CalendarDayState>{

  constructor(props: CalendarDayProps) {
    super(props);
    this.state = {
      keyDates: props.keyDates,
      fullDate: props.fullDate,
      calendarSettings: props.calendarSettings,
      daysInMonth: props.dayCount
    }
    this.setUpcoming = this.setUpcoming.bind(this);
  }

  componentDidMount(): void {

  }

  setUpcoming(dayCount: number): JSX.Element {
    return (
      <div>
        {this.state.keyDates.map((date: CalendarDateInterface) => (
          <div key={Math.random()}>
            <>{date.displayDate.getDate() === dayCount && date.displayDate.getMonth() === this.state.fullDate.getMonth() && date.displayDate.getFullYear() === this.props.fullDate.getFullYear() &&
              <div key={Math.random()} className="calendarToolTip">
                <FontAwesomeIcon icon={faStar} style={this.state.calendarSettings.iconStyle} />
                <h6 className="calendarToolTipText">{date.name}</h6>
              </div>
            }</>
          </div>
        ))}
      </div>
    )
  }

  render(): JSX.Element {
    let dayCount = 1;
    return (
      <>
        <Row key={Math.random()} style={this.state.calendarSettings.rowStyle}>
          {
            CalendarDaysOfWeek.map((item) =>
              <Col key={Math.random()} style={this.state.calendarSettings.blockStyle}>
                {
                  item.value === this.state.fullDate.getDay() ?
                    <div key={Math.random()}>
                      <h6 style={this.state.calendarSettings.dateTextStyle}>{dayCount++}</h6>
                      <>{this.setUpcoming(dayCount)}</>
                    </div>
                    :
                    <>
                      {
                        item.value > this.state.fullDate.getDay() && item.value < 7 &&
                        <div key={Math.random()}>
                          <h6 style={this.state.calendarSettings.dateTextStyle}>{dayCount++}</h6>
                          <>{this.setUpcoming(dayCount)}</>
                        </div>
                      }
                    </>
                }
              </Col>
            )}
        </Row>
        <Row key={Math.random()} style={this.state.calendarSettings.rowStyle}>
          {
            CalendarDaysOfWeek.map((item) =>
              <Col key={Math.random()} style={this.state.calendarSettings.blockStyle}>
                <div key={Math.random()}>
                  <h6 style={this.state.calendarSettings.dateTextStyle}>{dayCount++}</h6>
                  <>{this.setUpcoming(dayCount)}</>
                </div>
              </Col>
            )}
        </Row>
        <Row key={Math.random()} style={this.state.calendarSettings.rowStyle}>
          {
            CalendarDaysOfWeek.map((item) =>
              <Col key={Math.random()} style={this.state.calendarSettings.blockStyle}>
                <div key={Math.random()}>
                  <h6 style={this.state.calendarSettings.dateTextStyle}>{dayCount++}</h6>
                  <>{this.setUpcoming(dayCount)}</>
                </div>
              </Col>
            )}
        </Row>
        <Row key={Math.random()} style={this.state.calendarSettings.rowStyle}>
          {
            CalendarDaysOfWeek.map((item) =>
              <Col key={Math.random()} style={this.state.calendarSettings.blockStyle}>
                <div key={Math.random()}>
                  <h6 style={this.state.calendarSettings.dateTextStyle}>{dayCount++}</h6>
                  <>{this.setUpcoming(dayCount)}</>
                </div>
              </Col>
            )}
        </Row>
        <Row key={Math.random()} style={this.state.calendarSettings.rowStyle}>
          {
            CalendarDaysOfWeek.map((item) =>
              <Col key={Math.random()} style={this.state.calendarSettings.blockStyle}>
                {dayCount <= this.state.daysInMonth &&
                  <div key={Math.random()}>
                    <h6 style={this.state.calendarSettings.dateTextStyle}>{dayCount++}</h6>
                    <>{this.setUpcoming(dayCount)}</>
                  </div>
                }
              </Col>
            )}
        </Row>
        <Row key={Math.random()} style={this.state.calendarSettings.rowStyle}>
          {
            CalendarDaysOfWeek.map((item) =>
              <Col key={Math.random()} style={this.state.calendarSettings.blockStyle}>
                {dayCount <= this.state.daysInMonth &&
                  <div key={Math.random()}>
                    <h6 style={this.state.calendarSettings.dateTextStyle}>{dayCount++}</h6>
                    <>{this.setUpcoming(dayCount)}</>
                  </div>
                }
              </Col>
            )}
        </Row>
      </>
    )
  }
}

//#endregion

//#region DatePicker

/**
 * Fields to customize the datePicker 
 */
interface DatePickerProps {
  className?: string;
  onChange: (e: any) => void;
  value: Date;
  onBlur?: () => void;
  style?: any;
  id?: string;
}

interface DatePickerState {

}
/**
 * 
 */
export class DatePicker extends React.Component<DatePickerProps, DatePickerState>{

  constructor(props: DatePickerProps) {
    super(props);
    this.state = {

    }
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (new Date(event.target.value).toString() === "Invalid Date") {
      return;
    }
    this.props.onChange(event);
  }

  onBlur(): void {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render(): JSX.Element {
    return (
      <>
        <input type="date" id={this.props.id ? this.props.id : Math.random().toString()} className={this.props.className ? this.props.className : "standard-input"} min="1977-05-25" max="2163-07-04"
          style={this.props.style ? this.props.style : {}}
          onChange={this.onChange} value={new Date(this.props.value).toString() !== "Invalid Date" && new Date(this.props.value)?.toISOString().split('T')[0]} onBlur={this.onBlur} />
      </>
    )
  }
}

export class DateTimePicker extends React.Component<DatePickerProps, DatePickerState>{

  constructor(props: DatePickerProps) {
    super(props);
    this.state = {

    }
    this.onChange = this.onChange.bind(this);
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (new Date(event.target.value).toString() === "Invalid Date") {
      return;
    }
    this.props.onChange(event);
  }

  render(): JSX.Element {
    return (
      <>
        <>{console.log(new Date(this.props.value)?.toISOString())}</>
        <input type="datetime-local" className={this.props.className ? this.props.className : "standard-input"} min="1977-05-25" max="2163-07-04"
          onChange={this.onChange} value={new Date(this.props.value).toString() !== "Invalid Date" ? new Date(this.props.value)?.toISOString() : ""} onBlur={() => (this.props.onBlur && this.props.onBlur())} />
      </>
    )
  }
}

//#endregion