import * as React from 'react';
import TicketSummaryOnlyWidget from './TicketSummaryOnlyWidget';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullCompletedTicketMinimal } from '../../../functions/fetchLinkedObjects';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';


export default class TicketSummaryLookupWidget extends React.Component<{}, { ticket: SelectOptions, tickets: Array<SelectOptions> }> {

  constructor(props: {}) {
    super(props);
    this.state = {
      ticket: { label: "Select Ticket", value: "" },
      tickets: []
    }
    this.setTicket = this.setTicket.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let tickets = await pullCompletedTicketMinimal();
    this.setState({ tickets: tickets });
  }

  setTicket(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ ticket: event });
  }

  render(): JSX.Element {
    return (
      <>
        <Select
          options={this.state.tickets}
          value={this.state.ticket}
          onChange={this.setTicket}
          styles={reactSelectBasicStyle}
        />
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <div>
          {this.state.ticket.value && <TicketSummaryOnlyWidget key={this.state.ticket.value}  uid={this.state.ticket.value} />}
        </div>

      </>
    )
  }
}