import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { pullFleetEmployee, pullRegisteredAgents, pullStates } from '../../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../../interfaces/CoreInterfaces';
import { StreetAddress } from '../../../CoreComponents/interfaces';
import { EntityManagementType_NewRequest, EntityPurpose_NewRequest, EntitySCorp_NewRequest, EntityTypes_NewRequest } from '../../Structs';
import EntityRequestEditModal from './EntityRequestEditModal';

interface EntityRequestReviewFormationProps {
  uid: string;
}

interface EntityRequestReviewFormationState {
  uid: string;
  entityType: SelectOptions;
  formationState: SelectOptions;
  sosRegistrations: Array<SelectOptions>;
  entityPurpose: SelectOptions;
  initialContribution: number;
  employees: Array<SelectOptions>;
  projectLeads: Array<SelectOptions>;
  financeLeads: Array<SelectOptions>;
  ccList: Array<SelectOptions>;
  requiredReviewers: Array<SelectOptions>;
  states: Array<SelectOptions>;
  streetAddress: StreetAddress;
  president: string;
  vicePresident: string;
  secretary: string;
  treasurer: string;
  sCorpElection: SelectOptions;
  management: SelectOptions;
  registeredAgents: Array<SelectOptions>;
  registeredAgent: SelectOptions;
}

export default class EntityRequestReviewFormation extends React.Component<EntityRequestReviewFormationProps, EntityRequestReviewFormationState>{

  modalRef = React.createRef<EntityRequestEditModal>();

  constructor(props: EntityRequestReviewFormationProps) {
    super(props);
    this.state = {
      uid: props.uid,
      entityType: { label: 'Corporation', value: "0" },
      formationState: { label: "Select Formation State", value: "-1" },
      sosRegistrations: [],
      entityPurpose: { label: "Select Purpose", value: "" },
      initialContribution: 2000,
      employees: [],
      projectLeads: [],
      financeLeads: [],
      ccList: [],
      requiredReviewers: [],
      states: [],
      streetAddress: {
        address: "200 E Main Street",
        city: "Enterprise",
        state: { label: "Oregon", value: "36" },
        zipcode: "97828"
      },
      president: '',
      vicePresident: '',
      secretary: '',
      treasurer: '',
      sCorpElection: { label: "No", value: "0" },
      management: { label: "Select Management Type", value: "-1" },
      registeredAgents: [],
      registeredAgent: { label: "Select Registered Agent", value: "-1" },
    }
    this.editStringEntry = this.editStringEntry.bind(this);
    this.editSelectEntry = this.editSelectEntry.bind(this);
    this.editMultiSelectEntry = this.editMultiSelectEntry.bind(this);
    this.editAddressEntry = this.editAddressEntry.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const sos: SelectOptions[] = [];
    const states = await pullStates();
    const employees = await pullFleetEmployee();
    const projectLeads: SelectOptions[] = [];
    const finanaceLeads: SelectOptions[] = [];
    const reviewers: SelectOptions[] = [];
    const ccList: SelectOptions[] = [];
    const registeredAgents = await pullRegisteredAgents();

    await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
      response.data.sosRegistrationStates.forEach((item: number) => {
        sos.push({ label: states.filter(u => parseInt(u.value) === item)[0].label, value: item.toString() });
      })

      if (sos.length < 1) {
        sos.push({ label: "Select Formation State", value: "-1" })
      }

      response.data.emailList.forEach((item: any) => {
        if (item.emailListType === 0) { projectLeads.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.fleetEmployeeUID }) }
        else if (item.emailListType === 1) { finanaceLeads.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.fleetEmployeeUID }) }
        else if (item.emailListType === 3) { reviewers.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.fleetEmployeeUID }) }
        else if (item.emailListType === 2) { ccList.push({ label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.fleetEmployeeUID }) }
      })

      const agentIsNull = response.data.registeredAgentUID ? {
        label: registeredAgents.filter(u => u.value === response.data.registeredAgentUID)[0].label, value: response.data.registeredAgentUID
      } : { label: registeredAgents.filter(u => parseInt(u.value) === response.data.newRegisteredAgent)[0].label, value: response.data.newRegisteredAgent.toString() }

      this.setState({
        entityType: { label: EntityTypes_NewRequest.filter(u => parseInt(u.value) === response.data.type)[0].label, value: response.data.type.toString() },
        formationState: { label: states.filter(u => parseInt(u.value) === response.data.formationState)[0].label, value: response.data.formationState.toString() },
        sosRegistrations: sos,
        entityPurpose: { label: EntityPurpose_NewRequest.filter(u => parseInt(u.value) === response.data.entityPurpose)[0].label, value: response.data.entityPurpose.toString() },
        initialContribution: response.data.initialCapital,
        employees: employees,
        projectLeads: projectLeads,
        financeLeads: finanaceLeads,
        ccList: ccList,
        requiredReviewers: reviewers,
        states: states,
        streetAddress: {
          address: response.data.address.streetAddress,
          city: response.data.address.city,
          state: { label: states.filter(u => parseInt(u.value) === response.data.address.state)[0].label, value: response.data.address.state.toString() },
          zipcode: response.data.address.zipCode
        },
        president: response.data.president,
        vicePresident: response.data.vicePresident,
        secretary: response.data.secretary,
        treasurer: response.data.treasurer,
        sCorpElection: { label: EntitySCorp_NewRequest.filter(u => parseInt(u.value) === response.data.sCorpElection)[0].label, value: response.data.sCorpElection.toString() },
        management: { label: EntityManagementType_NewRequest.filter(u => parseInt(u.value) === response.data.entityManagementType)[0].label, value: response.data.entityManagementType.toString() },
        registeredAgents: registeredAgents,
        registeredAgent: agentIsNull
      })
    })
  }

  editStringEntry(field: string, data: string, saveType: string): void {
    this.modalRef.current?.showStringEdit(
      this.state.uid,
      field,
      data,
      saveType,
    )
  }

  editSelectEntry(field: string, data: SelectOptions, saveType: string, options: Array<SelectOptions>): void {
    this.modalRef.current?.showSelectEdit(
      this.state.uid,
      field,
      data,
      saveType,
      options
    )
  }

  editMultiSelectEntry(field: string, data: Array<SelectOptions>, saveType: string, options: Array<SelectOptions>): void {
    this.modalRef.current?.showMultiSelectEdit(
      this.state.uid,
      field,
      data,
      saveType,
      options
    )
  }

  editAddressEntry(field: string, data: StreetAddress, saveType: string, options: Array<SelectOptions>): void {
    this.modalRef.current?.showAddressEdit(
      this.state.uid,
      field,
      data,
      saveType,
      options
    )
  }

  render(): JSX.Element {
    return (
      <Row style={{ width: "98%", marginLeft: "1%", border: "3px solid #d7c7a7", backgroundColor: "#4C4A42", marginBottom: "2vh", paddingTop: "1vh" }}>
        <EntityRequestEditModal ref={this.modalRef} />
        <Row>
          <Col>
            <h3>Formation Data</h3>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Entity Type</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editSelectEntry(
                      "Entity Type",
                      this.state.entityType,
                      'Type',
                      EntityTypes_NewRequest
                    )} />
                </div>
              </Col>
              <Col>
                <h5>{this.state.entityType.label}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Entity Purpose</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editSelectEntry(
                      "Entity Purpose",
                      this.state.entityPurpose,
                      'EntityPurpose',
                      EntityPurpose_NewRequest
                    )} />
                </div>
              </Col>
              <Col>
                <h5>{this.state.entityPurpose.label}</h5>
              </Col></Row>
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Formation State</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editSelectEntry(
                      "Formation State",
                      this.state.formationState,
                      'FormationState',
                      this.state.states
                    )} />
                </div>
              </Col>
              <Col>
                <h5>{this.state.formationState.label}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Foriegn SOS Registration</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editMultiSelectEntry(
                      "Sos Registration States",
                      this.state.sosRegistrations,
                      'SosRegistrationStates',
                      this.state.states
                    )} />
                </div>
              </Col>
              <Col>
                {this.state.sosRegistrations.map((item, idx) =>
                  <h5>
                    {item.label}
                    {idx < this.state.sosRegistrations.length - 1 && <>{","}</>}
                  </h5>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Initial Contribution</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editStringEntry(
                      "Initial Capital",
                      this.state.initialContribution.toString(),
                      'InitialCapital'
                    )} />
                </div>
              </Col>
              <Col>
                <h5>{this.state.initialContribution}</h5>
              </Col>
            </Row>
          </Col>
          <Col xs="2">
            <div>
              <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Principal Address</h5>
              <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                onClick={() => this.editAddressEntry(
                  "Address",
                  this.state.streetAddress,
                  'Address',
                  this.state.states
                )} />
            </div>
          </Col>
          <Col>
            <h5>{this.state.streetAddress.address}</h5>
            <h5>{this.state.streetAddress.city}</h5>
            <h5>{this.state.streetAddress.state.label}</h5>
            <h5>{this.state.streetAddress.zipcode}</h5>
          </Col>
          <hr />
        </Row>
        <Row>
          <Col xs="6">
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Project Leads</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editMultiSelectEntry(
                      "Project Leads",
                      this.state.projectLeads,
                      'ProjectLeads',
                      this.state.employees
                    )}
                  />
                </div>
              </Col>
              <Col>
                {this.state.projectLeads.map((item, idx) =>
                  <h5>
                    {item.label}
                    {idx < this.state.projectLeads.length - 1 && <>{","}</>}
                  </h5>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Finanace Leads</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editMultiSelectEntry(
                      "Finance Leads",
                      this.state.financeLeads,
                      'FinanceLeads',
                      this.state.employees
                    )}
                  />
                </div>
              </Col>
              <Col>
                {this.state.financeLeads.map((item, idx) =>
                  <h5>
                    {item.label}
                    {idx < this.state.financeLeads.length - 1 && <>{","}</>}
                  </h5>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Required Reviewers</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editMultiSelectEntry(
                      "Required Reviewers",
                      this.state.requiredReviewers,
                      'RequiredReviewers',
                      this.state.employees
                    )}
                  />
                </div>
              </Col>
              <Col>
                {this.state.requiredReviewers.map((item, idx) =>
                  <h5>
                    {item.label}
                    {idx < this.state.requiredReviewers.length - 1 && <>{","}</>}
                  </h5>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>CC List</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editMultiSelectEntry(
                      "CC List",
                      this.state.ccList,
                      'CCList',
                      this.state.employees
                    )}
                  />
                </div>
              </Col>
              <Col>
                {this.state.ccList.map((item, idx) =>
                  <h5>
                    {item.label}
                    {idx < this.state.ccList.length - 1 && <>{","}</>}
                  </h5>
                )}
              </Col>
            </Row>
          </Col>
          {(parseInt(this.state.entityType.value) === 2 || parseInt(this.state.entityType.value) === 7) && <Col>
            <div>
              <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>President</h5>
              <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                onClick={() => this.editStringEntry(
                  "President",
                  this.state.president,
                  'President'
                )}
              />
            </div>
            <div>
              <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Vice President</h5>
              <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                onClick={() => this.editStringEntry(
                  "Vice President",
                  this.state.vicePresident,
                  'VicePresident'
                )}
              />
            </div>
            <div>
              <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Secretary</h5>
              <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                onClick={() => this.editStringEntry(
                  "Secretary",
                  this.state.secretary,
                  'Secretary'
                )}
              />
            </div>
            <div>
              <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Treasurer</h5>
              <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                onClick={() => this.editStringEntry(
                  "Treasurer",
                  this.state.treasurer,
                  'Treasurer'
                )}
              />
            </div>
          </Col>}
          {(parseInt(this.state.entityType.value) === 2 || parseInt(this.state.entityType.value) === 7) && <Col>
            <h5>{this.state.president}</h5>
            <h5>{this.state.vicePresident}</h5>
            <h5>{this.state.secretary}</h5>
            <h5>{this.state.treasurer}</h5>
          </Col>}
          {(parseInt(this.state.entityType.value) === 1) &&
            <>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>S-Corp Election</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editSelectEntry(
                      "S-Corp Election",
                      this.state.sCorpElection,
                      'SCorpElection',
                      EntitySCorp_NewRequest
                    )}
                  />
                </div>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Is Managed</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editSelectEntry(
                      "Is Managed",
                      this.state.management,
                      'EntityManagementType',
                      EntityManagementType_NewRequest
                    )}
                  />
                </div>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>Registered Agent</h5>
                  <FontAwesomeIcon icon={faCog} style={{ display: "inline-block", marginLeft: "1vw" }}
                    onClick={() => this.editSelectEntry(
                      "Registered Agent",
                      this.state.registeredAgent,
                      'RegisteredAgentUID',
                      this.state.registeredAgents
                    )}
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>{this.state.sCorpElection.label}</h5>
                </div>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>{this.state.management.label}</h5>
                </div>
                <div>
                  <h5 style={{ display: "inline-block", marginLeft: "1vw" }}>{this.state.registeredAgent.label}</h5>
                </div>
              </Col>
            </>}
        </Row>
        <hr />
      </Row>
    )
  }
}