import axios from "axios";
import * as React from "react";
import { numberWithCommas } from "../../../functions/numberFunctions";
import * as Structs from "../Structs";
import FilteredWithSettingsTableHeader from "../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { getBearerToken } from "../../../functions/authActions";

export class GeneralContractorWaiverTable extends React.Component<Structs.GeneralWaiverTableProps, Structs.GeneralWaiverTableState> {

  constructor(props: Structs.GeneralWaiverTableProps) {
    super(props);
    this.state = {
      uid: props.uid,
      waivers: [],
      headers: this.generateHeaders(),
    }
    this.generateHeaders = this.generateHeaders.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let response = await axios.get('./api/job-waiver-tracking/' + this.state.uid + "/true", { validateStatus: () => true });
    if (response.status === 200) {
      console.log(response.data);
      const waivers: Array<any> = [];
      response.data.forEach(function (waiver: any) {
        if (waiver.status === 1) {
          return;
        }
        waivers.push({
          uid: waiver.uid,
          draw: waiver.drawNumber + 1,
          amount: waiver.paymentDue,
          status: waiver.status === 0 ? "Sent for Signature" : (waiver.status === 1 ? "Signed" : "Recalled"),
          type: waiver.isFinal ? "Final" : "Progress",
          conditional: waiver.isConditional ? "Conditional" : "Unconditional"
        });
      });
      this.setState({
        waivers: waivers,
      });
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Draw Number",
        setting: true,
        filter: {}
      },
      {
        columnName: "Amount",
        setting: true,
        filter: {}
      },
      {
        columnName: "Status",
        setting: true,
        filter: {}
      },
      {
        columnName: "Waiver",
        setting: true,
        filter: {}
      },
      {
        columnName: "Type",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <>
        <h5 style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>General Contracting Waivers</h5>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <div style={{ height: "25vh", overflowY: "auto", overflowX: "hidden" }}>
          {
            <>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
                </thead>
                <tbody>
                  {this.state.waivers.map((item: any) => (

                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst">{item.draw}</td>
                      <td className="fixedTableCell">${numberWithCommas(item.amount)}</td>
                      <td className="fixedTableCell">{item.status}</td>
                      <td className="fixedTableCell">{item.type}</td>
                      <td className="fixedTableCell">{item.conditional}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          }
        </div>
      </>
    )
  }
}