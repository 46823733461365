import { SelectOptions } from "../../../interfaces/CoreInterfaces";

export interface TechTeamDashboardManagerProps {

}

export interface TechTeamDashboardManagerState {
  dashboard: SelectOptions
}

export const TechDashboardOptions: Array<SelectOptions> = [
  { label: "Tech Team", value: "0" },
  { label: "MCI Instructor", value: "1" },
  { label: "Property Accounting", value: "2" },
  { label: "Regional Manager", value: "3" },
  { label: "Vice President of Opertaions", value: "4" },
]
