import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import axios from 'axios';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { pullViridianMaintenanceManagers, pullViridianSiteManagers } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface EmployeeAllocationWidgetRow {
  uid: string;
  propertyName: string;
  allocation: number;
  role: string;
}

interface EmployeeAllocationWidgetState {
  employees: Array<SelectOptions>;
  employee: SelectOptions;
  rows: Array<EmployeeAllocationWidgetRow>;
}

export class EmployeeAllocationsWidget extends React.Component<{}, EmployeeAllocationWidgetState> {

  headers: Array<FilterAndSettingField> = [
    { columnName: "Property", setting: true, filter: {} },
    { columnName: "Role", setting: true, filter: {} },
    { columnName: "%", setting: true, filter: {} }
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      employees: [],
      employee: { label: "Select Employee", value: "" },
      rows: []
    }
    this.changeEmployee = this.changeEmployee.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var employees = (await pullViridianSiteManagers()).concat(await pullViridianMaintenanceManagers());
    this.setState({ employees: employees })
  }

  changeEmployee(event: SelectOptions | null) {
    if (event === null) { return; }
    this.setState({ employee: event }, () => this.pullEmployeeData());
  }

  async pullEmployeeData(): Promise<void> {
    let response = await axios.get('./api/property-employee-map/employee-allocations/' + this.state.employee.value, { validateStatus: () => true });
    if (response.status === 200) {
      this.setState({
        rows: response.data
      })
    }
  }

  render(): JSX.Element {
    return (
      <>
        {this.state.employee.value !== "" &&
          <>
            {RenderHeader("Employee Allocations")}
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.rows.map((item) => (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst">{item.propertyName}</td>
                      <td className="fixedTableCellFirst">{item.role}</td>
                      <td className="fixedTableCellFirst">{item.allocation}%</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </>
        }
        <hr />
        <Select
          options={this.state.employees}
          styles={reactSelectBasicStyle}
          value={this.state.employee}
          onChange={this.changeEmployee}
          menuPlacement="auto"
          menuPosition="fixed"
        />
      </>
    )
  }
}