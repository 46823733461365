import * as React from 'react';
import { PropertyInformationWidgetData } from '../PropertyWidgets/PropertyInformationwidget.constants';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldCheckbox, DataEntryModalFieldDate, DataEntryModalFieldSelect, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import { RelatedPortfolios_Select } from '../../../constants/EnumConstants';
import { PropertyTypes } from '../../../constants/EnumConstants';
import { PropertyFundingTypes } from '../../../constants/EnumConstants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullStates } from '../../../functions/fetchLinkedObjects';


interface PropertyInformationEditModalProps {

}

interface PropertyInformationEditModalState {
  property: PropertyInformationWidgetData;
  states: Array<SelectOptions>;
}

export default class PropertyInformationEditModal extends React.Component<PropertyInformationEditModalProps, PropertyInformationEditModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  static DefaultPropertySettings: PropertyInformationWidgetData = {
    uid: "",
    name: "",
    code: "",
    streetAddress: "",
    city: "",
    state: { label: "Select State", value: "" },
    zipCode: "",
    county: "",
    relatedPortfolio: { label: "Select Portfolio", value: "" },
    type: { label: "Select Type", value: "" },
    lastRehab: new Date(),
    buildings: 0,
    unitCount: 0,
    totalSQFT: 0,
    fundingTypes: [],
    isPPU: false
  }

  constructor(props: PropertyInformationEditModalProps) {
    super(props);
    this.state = {
      states: [],
      property: PropertyInformationEditModal.DefaultPropertySettings,
    }
  }

  async componentDidMount(): Promise<void> {
    let states = await pullStates();
    this.setState({ states: states})
  }

  show(property?: PropertyInformationWidgetData): void {
    if (property !== undefined) {
      this.setState({
        property: property,
      }, () => this.generateData());
    }
    else {
      this.setState({
        property: PropertyInformationEditModal.DefaultPropertySettings
      }, () => this.generateData());
    }
  }

  generateData(): void {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "name", name: "Property", patchUrl: './api/property/patch-name', value: this.state.property.name, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "code", name: "Code", patchUrl: './api/property/patch-code', value: this.state.property.code, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "county", name: "County", patchUrl: './api/property/patch-county', value: this.state.property.county, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "relatedPortfolio", name: "Related Portfolio", patchUrl: './api/property/patch-related-portfolio', value: this.state.property.relatedPortfolio, options: RelatedPortfolios_Select, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "type", name: "Type", patchUrl: './api/property/patch-type', value: this.state.property.type, options: PropertyTypes, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "lastFullRehab", name: "Last Rehab", patchUrl: './api/property/patch-last-full-rehab', value: new Date(this.state.property.lastRehab) } as DataEntryModalFieldDate);
    data.push({ key: "unitCount", name: "Unit Count", patchUrl: './api/property/patch-unit-count', value: this.state.property.unitCount, type: "number" } as DataEntryModalFieldText);
    data.push({ key: "fundingTypes", name: "Funding", patchUrl: "./api/property/patch-funding-types", value: this.state.property.fundingTypes, options: PropertyFundingTypes, isMulti: true } as DataEntryModalFieldSelect);
    data.push({ key: "StreetAddress", name: "Street Address", patchUrl: "./api/property/patch-street-address", value: this.state.property.streetAddress, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "city", name: "City", patchUrl: "./api/property/patch-city", value: this.state.property.city, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "state", name: "State", patchUrl: './api/property/patch-state', value: this.state.property.state, options: this.state.states, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "zipCode", name: "Zipcode", patchUrl: './api/property/patch-zipcode', value: this.state.property.zipCode, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "isPropertyPaidUtilities", name: "Is PPU", patchUrl: null, value: this.state.property.isPPU, checkbox: this.state.property.isPPU } as DataEntryModalFieldCheckbox);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Edit Property", this.state.property.uid, "./api/property");
  }

  render(): JSX.Element {
    return (
      <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} />
    )
  }
}