import * as React from 'react';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import UtilityAnalyisWorkflowModal from './UtilityAnalysisWidget.modals';
import { WarningModal } from '../../CoreComponents/Modals';
import axios from 'axios';
import { DownloadNamedFile } from '../../../functions/documentTools';

interface UtilityAnalysisWidgetData {
  uid: string;
  property: string;
  utility: string;
  status: string;
  assignee: string;
}

interface UtilityAnalysisWidgetState {
  utilities: Array<UtilityAnalysisWidgetData>;
}

export default class UtilityAnalysisWidget extends React.Component<{}, UtilityAnalysisWidgetState> {

  utilityAnalysisModel: React.RefObject<UtilityAnalyisWorkflowModal> = React.createRef<UtilityAnalyisWorkflowModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      utilities: []
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let utilities = await axios.get('./api/utility-analysis-workflow');
    this.setState({ utilities: utilities.data });
  }

  /**
   * Only needs message set on successful submissions
   * @param successful
   * @param message
   */
  callback(successful: boolean, message: string = ""): void {
    if (successful) {
      this.warningModal.current.show(message, "Utility Analysis Submitted", () => window.location.reload());
    }
    else {
      this.warningModal.current.show("Error while submitting Utility Analysis, please contact the tech team", "Error!", () => window.location.reload());
    }
  }

  async downloadFiles(uid: string): Promise<void> {
    let response = await axios.get('./api/utility-analysis-workflow/files/' + uid, {responseType: "blob"});
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
    else {
      this.warningModal.current.show("There was an error downloading the documents. Please submit a support ticket to the tech team so that they can investigate the issue", "Error.");
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <UtilityAnalyisWorkflowModal ref={this.utilityAnalysisModel} callback={this.callback} />
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Utility Analysis")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh", overflowY: "auto", overflowX: "hidden"}}>
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Property</th>
                <th className="fixedTableHeader">Assignee</th>
                <th className="fixedTableHeader">Utility</th>
                <th className="fixedTableHeader">Status</th>
                <th className="fixedTableHeader">Files</th>
                <th className="fixedTableHeader"></th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.utilities.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.property}</td>
                    <td className="fixedTableCell">{item.assignee}</td>
                    <td className="fixedTableCell">{item.utility}</td>
                    <td className="fixedTableCell">{item.status}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Download" onClick={() => this.downloadFiles(item.uid)} className="standard-input"/>
                    </td>
                    <td className="fixedTableCell">
                      <input type="button" value="Continue" onClick={() => this.utilityAnalysisModel.current.show(item.uid)}
                        className="standard-input"
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Start UA" onClick={() => this.utilityAnalysisModel.current.show("")} className="standard-input" />
      </div>
    )
  }

}