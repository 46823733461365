//#region Imports
import { faCog, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { getUserID } from "../../../../functions/authActions";
import { pullFleetEmployee } from "../../../../functions/fetchLinkedObjects";
import { SelectOptions } from "../../../../interfaces/CoreInterfaces";
import FilteredWithSettingsTableHeader from "../../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../../CoreComponents/interfaces";
//#endregion

export interface NewEntityTrackingInformationProps {
  uid: string;
}

export interface NewEntityTrackingInformationState {
  uid: string;
  headers: Array<any>;
  trackingInformation: Array<any>;
  isAdding: boolean;
  author: string;
  notes: string;
  employees: Array<any>;
  isEditing: boolean;
  editingNote: string;
  trackingType: SelectOptions;
}

export const EntityTrackingTypes: Array<SelectOptions> = [
  { label: "Notes Added", value: "0" },
  { label: "Entity Name Changed", value: "1" },
  { label: "Entity Type Changed", value: "2" },
  { label: "Entity Purpose Changed", value: "3" },
  { label: "Entity Formation State Changed", value: "4" },
  { label: "Entity SOS Registration Changed", value: "5" },
  { label: "Entity Initial Contribution Changed", value: "6" },
  { label: "Entity Address Changed", value: "7" },
  { label: "Entity Project Leads Changed", value: "8" },
  { label: "Entity Finance Leads Changed", value: "9" },
  { label: "Entity Required Reviewers Changed", value: "10" },
  { label: "Entity CC List Changed", value: "11" },
  { label: "Entity President Changed", value: "12" },
  { label: "Entity Vice President Changed", value: "13" },
  { label: "Entity Secretary Changed", value: "14" },
  { label: "Entity Treasurer Changed", value: "15" },
  { label: "Entity S Corp Election Changed", value: "16" },
  { label: "Entity Management Type Changed", value: "17" },
  { label: "Entity Registered Agent Changed", value: "18" },
  { label: "Notes Updated", value: "19" },
  { label: "Draft Rejected", value: "20" }

]

export class EntityCreationTrackingInfo extends React.Component<NewEntityTrackingInformationProps, NewEntityTrackingInformationState>{

  constructor(props: NewEntityTrackingInformationProps) {
    super(props);
    this.state = {
      uid: props.uid,
      headers: [],
      trackingInformation: [],
      isAdding: false,
      author: '',
      notes: '',
      employees: [],
      isEditing: false,
      editingNote: '',
      trackingType: { label: "", value: "" },
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.newNote = this.newNote.bind(this);
    this.changeAuthor = this.changeAuthor.bind(this);
    this.changeNotes = this.changeNotes.bind(this);
    this.saveNote = this.saveNote.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    const employees = await pullFleetEmployee();
    const user = { label: employees.filter(u => u.value === getUserID())[0].label, value: getUserID() }
    this.setState({ author: user.label })

    if (this.state.uid !== '') {
      await axios.get("./api/new-entity-request/" + this.state.uid).then((response) => {
        const trackingInformation: any = [];

        response.data.trackingInformation.forEach((item: any) => {
          item.fleetEmployee = { label: employees.filter(u => u.value === item.fleetEmployeeUID)[0].label, value: item.fleetEmployeeUID }.label;
          trackingInformation.push({
            trackUID: item.uid,
            author: item.fleetEmployee,
            notes: item.notes,
            entityTrackingType: { label: EntityTrackingTypes.filter(u => u.value === item.entityTrackingType.toString())[0].label, value: item.entityTrackingType.toString() }
          })
        })
        this.setState({
          trackingInformation: trackingInformation,
          employees: employees
        })
      })
    }
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Author',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Tracking Log',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Notes',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      }
    ]
    this.setState({ headers: headers })
  }

  newNote(): void {
    this.setState({ isAdding: true })
  }

  editNote(note: any): void {
    this.setState({
      isEditing: true,
      editingNote: note.trackUID,
      notes: note.notes
    })
  }

  changeAuthor(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ author: event.target.value })
  }

  changeNotes(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ notes: event.target.value })
  }

  async saveNote(note: any, idx: number): Promise<void> {
    if (this.state.isAdding) {
      const data = {
        NewEntityUID: this.state.uid,
        EmployeeUID: getUserID(),
        Notes: this.state.notes,
        EntityTrackingType: 0
      }

      await axios.post("./api/new-entity-request/new-tracking-information", data).then((response) => {
        const _tracking = this.state.trackingInformation;
        _tracking.push({
          trackUID: response.data.uid,
          author: { label: this.state.employees.filter(u => u.value === response.data.fleetEmployeeUID)[0].label, value: response.data.fleetEmployeeUID }.label,
          notes: response.data.notes,
          entityTrackingType: { label: EntityTrackingTypes.filter(u => u.value === response.data.entityTrackingType.toString())[0].label, value: response.data.entityTrackingType.toString() }
        });
        this.setState({ trackingInformation: _tracking });
      });
    }
    if (this.state.isEditing) {
      const _tracking = this.state.trackingInformation;
      _tracking[idx] = {
        trackUID: _tracking[idx].trackUID,
        author: this.state.author,
        notes: this.state.notes,
        entityTrackingType: { label: "Notes Updated", value: "19" }
      }
      this.setState({ trackingInformation: _tracking });
      const data = {
        UID: note.trackUID,
        NewEntityUID: this.state.uid,
        FleetEmployeeUID: getUserID(),
        Notes: this.state.notes,
        EntityTrackingType: 19
      }
      await axios.patch("./api/new-entity-request/update-tracking-information", data).then((response) => {

      })
    }

    this.setState({ isAdding: false, isEditing: false, editingNote: '' })
  }

  render(): JSX.Element {
    return (
      <Row>
        <Col style={{ height: "78vh", overflow: "auto", paddingBottom: "5vh" }} >
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              {this.state.trackingInformation.map((item: any, idx: number) =>
                <>
                  {this.state.isEditing && this.state.editingNote === item.trackUID ?
                    <tr style={{ height: "13vh" }}>
                      <td className="fixedTableCellFirst">{this.state.author}</td>
                      <td className="fixedTableCell"></td>
                      <td className="fixedTableCell">
                        <textarea className="standardInput" value={this.state.notes} style={{ height: "13vh" }} onChange={this.changeNotes} />
                      </td>
                      <td className="fixedTableCell">
                        <FontAwesomeIcon icon={faSave} style={{ marginLeft: "15%" }} onClick={() => this.saveNote(item, idx)} />
                      </td>
                    </tr> :
                    <tr style={{ height: "13vh" }}>
                      <td className="fixedTableCellFirst">{item.author}</td>
                      <td className="fixedTableCell">{item.entityTrackingType?.label}</td>
                      <td className="fixedTableCell" style={{ whiteSpace: "pre-wrap", maxHeight: "13vh", overflow: "auto" }}>{item.notes}</td>
                      <td className="fixedTableCell">
                        {(item.author === this.state.employees.filter(u => u.value === getUserID())[0].label && !this.state.isAdding && !this.state.isEditing) && <FontAwesomeIcon icon={faCog} style={{ marginLeft: "15%" }} onClick={() => this.editNote(item)} />}
                      </td>
                    </tr>
                  }
                </>
              )}
              {(this.state.isAdding && !this.state.isEditing) ?
                <tr style={{ height: "13vh" }}>
                  <td className="fixedTableCellFirst">{this.state.author}</td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell"><textarea className="standardInput" style={{ height: "13vh" }} onChange={this.changeNotes} /></td>
                  <td className="fixedTableCell">
                    <FontAwesomeIcon icon={faSave} style={{ marginLeft: "15%" }} onClick={() => this.saveNote({ trackUID: '', author: '', notes: '' }, -1)} />
                  </td>
                </tr> :
                <tr>
                  <td className="fixedTableCellFirst"></td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell">
                    <FontAwesomeIcon icon={faPlus} style={{ marginLeft: "15%" }} onClick={this.newNote} />
                  </td>
                </tr>}
            </tbody>
          </table>
        </Col>
      </Row>
    )
  }
}