//#region Imports
import * as React from 'react';
import Select from 'react-select';
import * as PullObjects from '../../../functions/fetchLinkedObjects';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { EntityData, EntityTypes_Select } from '../Structs';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { DatePicker } from '../../CoreComponents/DateComponents';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { getEmptyEntity } from './constants';
import * as funcs from '../../../functions/patchFunctions';
import { pullEntityByUID } from './EntityManagementModal.functions';
//#endregion

interface EntityManagementModalProps {
  saveRow: (entity: EntityData) => void;
}

interface EntityManagementModalState {
  showing: boolean;
  uid?: string;
  entity: EntityData;
  accountingFirms: Array<SelectOptions>;
  accountants: Array<SelectOptions>;
  states: Array<SelectOptions>;
  registered_agents: Array<SelectOptions>;
  contacts: Array<SelectOptions>;
  managers: Array<SelectOptions>;
  saveRow: (entity: EntityData) => void;
}

export class EntityManagementModal extends React.Component<EntityManagementModalProps, EntityManagementModalState> {

  static AccountOptions: Array<SelectOptions> = [
    { label: "VMI", value: "0" },
    { label: "CDI", value: "1" }
  ]

  static SCorpElections: Array<SelectOptions> = [
    { label: "N/A", value: "0" },
    { label: "Yes", value: "1" },
    { label: "No", value: "2" },
  ]

  static IsActive: Array<SelectOptions> = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" }
  ]

  static YesNoOptions: Array<SelectOptions> = [
    { label: "No", value: "0" },
    { label: "Yes", value: "1" }
  ]

  constructor(props: EntityManagementModalProps) {
    super(props);
    this.state = {
      showing: false,
      uid: null,
      entity: null,
      accountingFirms: [],
      accountants: [],
      states: [],
      registered_agents: [],
      managers: [],
      contacts: [],
      saveRow: props.saveRow
    }

    this.show = this.show.bind(this);
    this.forceClose = this.forceClose.bind(this);
    this.changeEntityName = this.changeEntityName.bind(this);
    this.updateEntityName = this.updateEntityName.bind(this);
    this.updateAccountingFirm = this.updateAccountingFirm.bind(this);
    this.changeEntityCode = this.changeEntityCode.bind(this);
    this.updateEntityCode = this.updateEntityCode.bind(this);
    this.updateYardiAccount = this.updateYardiAccount.bind(this);
    this.changeTaxID = this.changeTaxID.bind(this);
    this.updateTaxID = this.updateTaxID.bind(this);
    this.changeDuns = this.changeDuns.bind(this);
    this.updateDuns = this.updateDuns.bind(this);
    this.changeUei = this.changeUei.bind(this);
    this.updateUei = this.updateUei.bind(this);
    this.changeCageCode = this.changeCageCode.bind(this);
    this.updateCageCode = this.updateCageCode.bind(this);
    this.changeSosRenewalExpiration = this.changeSosRenewalExpiration.bind(this);
    this.changeCageExpiration = this.changeCageExpiration.bind(this);
    this.updateCageExpiration = this.updateCageExpiration.bind(this);
    this.updateAuthorizedSigners = this.updateAuthorizedSigners.bind(this);
    this.updateSosRenewalExpiration = this.updateSosRenewalExpiration.bind(this);
    this.updateFormationState = this.updateFormationState.bind(this);
    this.updateEntityType = this.updateEntityType.bind(this);
    this.updateSCorpElection = this.updateSCorpElection.bind(this);
    this.updateEntityStatus = this.updateEntityStatus.bind(this);
    this.changeFormationDate = this.changeFormationDate.bind(this);
    this.updateFormationDate = this.updateFormationDate.bind(this);
    this.updateRegisteredAgent = this.updateRegisteredAgent.bind(this);
    this.updateFirstYear = this.updateFirstYear.bind(this);
    this.updateFinalYear = this.updateFinalYear.bind(this);
    this.updateEntityManager = this.updateEntityManager.bind(this);
    this.updateAccountant = this.updateAccountant.bind(this);
    this.changeNotes = this.changeNotes.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const states = await PullObjects.pullStates();
    const firms = await PullObjects.pullAccountingFirms();
    const accountants = await PullObjects.pullAccountants();
    const contacts = await PullObjects.pullContacts();
    const registeredAgents = await PullObjects.pullRegisteredAgents();
    const managers = await PullObjects.pullEntityManagers();
    this.setState({
      states: states,
      accountingFirms: firms,
      accountants: accountants,
      contacts: contacts,
      registered_agents: registeredAgents,
      managers: managers
    });
  }

  //#region Updates From Entity Name to Tax ID
  async changeEntityName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.name = event.target.value;
    this.setState({})
  }

  async updateEntityName(): Promise<void> {
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.name, './api/entities/update-name');
  }

  async updateAccountingFirm(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.state.entity.firm = event;
    this.setState({});
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.firm.value, './api/entities/update-firm');
  }

  async changeEntityCode(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.uniqueCode = event.target.value;
    this.setState({});
  }

  async updateEntityCode(): Promise<void> {
    // skip if creating new entity
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.uniqueCode, './api/entities/update-code');
  }

  async updateYardiAccount(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.state.entity.yardiAccount = event;
    this.setState({});
    // skip if creating new entity
    await funcs.patchBooleanValue(this.state.entity.uid, this.state.entity.yardiAccount.value === "1", './api/entities/update-account');
  }

  async changeTaxID(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.fein = event.target.value;
    this.setState({})
  }

  async updateTaxID(): Promise<void> {
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.fein, './api/entities/update-tax-id');
  }

  async changeDuns(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.duns = event.target.value;
    this.setState({})
  }

  async updateDuns(): Promise<void> {
    // skip if creating new entity'
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.duns, './api/entities/update-duns');
  }
  //#endregion

  //#region From UEI To Entity Type
  async changeUei(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    let entity = this.state.entity;
    entity.uei = event.target.value;
    this.setState({ entity: entity });
  }

  async updateUei(): Promise<void> {
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.uei, './api/entities/update-uei');
  }

  async changeCageCode(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.cage = event.target.value;
    this.setState({})
  }

  async updateCageCode(): Promise<void> {
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.cage, './api/entities/update-cage');
  }

  async changeCageExpiration(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.cageExpiration = new Date(event.target.valueAsDate);
    this.setState({});
  }

  async updateCageExpiration(): Promise<void> {
    await funcs.patchDateValue(this.state.entity.uid, this.state.entity.cageExpiration, './api/entities/update-cage-expiration');
  }

  async updateAuthorizedSigners(event: Array<SelectOptions>): Promise<void> {
    this.state.entity.authorizedSigners = event;
    this.setState(
      {},
      async () => await funcs.patchListSelectOptions(this.state.entity.uid, this.state.entity.authorizedSigners, './api/entities/update-authorized-signers')
    );
  }

  async changeSosRenewalExpiration(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.sosRenew = new Date(event.target.valueAsDate);
    this.setState({});
  }

  async updateSosRenewalExpiration(): Promise<void> {
    await funcs.patchDateValue(this.state.entity.uid, this.state.entity.sosRenew, './api/entities/update-sos-renewal');
  }

  async updateFormationState(event: SelectOptions | null): Promise<void> {
    if (event === null) { return };
    this.state.entity.jurisdiction = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.jurisdiction, './api/entities/update-formation-state'));
  }

  async updateEntityType(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.state.entity.type = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.type, './api/entities/update-entity-type'));
  }
  //#endregion

  //#region From S Corp To End
  async updateSCorpElection(event: SelectOptions | null): Promise<void> {
    if (event === null) { return };
    this.state.entity.sCorpElection = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.sCorpElection, './api/entities/update-scorp'));
  }

  async updateEntityStatus(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.state.entity.status = event;
    this.setState({}, async () => await funcs.patchBooleanValue(this.state.entity.uid, this.state.entity.status.value === "1", './api/entities/update-account'));
  }

  async changeFormationDate(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.formationDate = new Date(event.target.valueAsDate);
    this.setState({});
  }

  async updateFormationDate(): Promise<void> {
    await funcs.patchDateValue(this.state.entity.uid, this.state.entity.formationDate, './api/entities/update-formation-date');
  }

  async updateRegisteredAgent(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.state.entity.agent = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.agent, './api/entities/update-registered-agent'));
  }

  async updateFirstYear(event: SelectOptions | null): Promise<void> {
    if (event === null) { return };
    this.state.entity.isFirstYearReturn = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.isFirstYearReturn, './api/entities/update-first-year'));
  }

  async updateFinalYear(event: SelectOptions | null): Promise<void> {
    if (event === null) { return };
    this.state.entity.isFinalYearReturn = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.isFinalYearReturn, './api/entities/update-final-year'));
  }

  async updateEntityManager(event: SelectOptions | null): Promise<void> {
    if (event === null) {
      return;
    }
    this.state.entity.portfolio = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.portfolio, './api/entities/update-entity-manager'));
  }

  async updateAccountant(event: SelectOptions | null): Promise<void> {
    if (event === null) {
      return;
    }
    this.state.entity.accountant = event;
    this.setState({}, async () => await funcs.patchSelectOptions(this.state.entity.uid, this.state.entity.accountant, './api/entities/update-accountant'));
  }

  async changeNotes(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.state.entity.notes = event.target.value;
    this.setState({})
  }

  async updateNotes(): Promise<void> {
    await funcs.patchStringValue(this.state.entity.uid, this.state.entity.notes, './api/entities/update-notes');
  }
  //#endregion

  async show(uid: string = null): Promise<void> {
    if (uid === null) {
      this.setState({
        showing: true,
        uid: null,
        entity: getEmptyEntity()
      })
    }
    else {
      let entity = await pullEntityByUID(uid);
      // pull entity
      this.setState({
        showing: true,
        uid: uid,
        entity: entity
      })
    }
  }

  forceClose(): void {
    this.setState({
      showing: false
    })
    window.location.reload();
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} size="md" style={{ borderBottom: "0px solid #15405c" }}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            {this.state.uid === null ? "Create" : "Update"} Entity
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                {this.state.uid === null ?
                  <>
                    {RenderEntityField_Text("Entity Name", this.state.entity?.name, this.changeEntityName, this.updateEntityName)}
                  </>
                  :
                  <>
                    <Row>
                      <Col>
                        <label>Entity Name</label>
                      </Col>
                      <Col>
                        <label style={{ width: "100%", textAlign: "right" }}>{this.state.entity.name}</label>
                      </Col>
                    </Row>
                  </>
                }
              </Col>
            </Row>
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Accounting Firm", this.state.entity?.firm, this.state.accountingFirms, this.updateAccountingFirm)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_Text("Yardi Code", this.state.entity?.uniqueCode ?? "", this.changeEntityCode, this.updateEntityCode)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Yardi Account", this.state.entity?.yardiAccount, EntityManagementModal.AccountOptions, this.updateYardiAccount)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_Text("Tax ID", this.state.entity?.fein, this.changeTaxID, this.updateTaxID)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_Text("DUNS", this.state.entity?.duns, this.changeDuns, this.updateDuns)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_Text("UEI", this.state.entity?.uei, this.changeUei, this.updateUei)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_Text("Cage Code", this.state.entity?.cage, this.changeCageCode, this.updateCageCode)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_DatePicker("Cage Code Expiration", this.state.entity?.cageExpiration, this.changeCageExpiration, this.updateCageExpiration)}
            <hr style={{ height: "1px" }} />
            <Row>
              <Col>
                <label>Authorized Signers</label>
              </Col>
              <Col>
                <Select
                  name="signerNameIds"
                  value={this.state.entity?.authorizedSigners}
                  options={this.state.contacts}
                  styles={reactSelectBasicStyle}
                  onChange={this.updateAuthorizedSigners}
                  components={{ DropdownIndicator: () => null, ClearIndicator: () => null }}
                  isMulti
                  menuPlacement="top"
                />
              </Col>
            </Row>
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Formation State", this.state.entity?.jurisdiction, this.state.states, this.updateFormationState)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_DatePicker("SOS Renewal Date", this.state.entity?.sosRenew, this.changeSosRenewalExpiration, this.updateSosRenewalExpiration)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Entity Type", this.state.entity?.type, EntityTypes_Select, this.updateEntityType)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("SCorp Election", this.state.entity?.sCorpElection, EntityManagementModal.SCorpElections, this.updateSCorpElection)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Entity Status", this.state.entity?.status, EntityManagementModal.IsActive, this.updateEntityStatus)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_DatePicker("Formation Date", this.state.entity?.formationDate, this.changeFormationDate, this.updateFormationDate) }
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Registered Agents", this.state.entity?.agent, this.state.registered_agents, this.updateRegisteredAgent)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("First Year Tax Return", this.state.entity?.isFirstYearReturn, EntityManagementModal.YesNoOptions, this.updateFirstYear)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Final Year Tax Return", this.state.entity?.isFinalYearReturn, EntityManagementModal.YesNoOptions, this.updateFinalYear)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Entity Manager", this.state.entity?.portfolio, this.state.managers, this.updateEntityManager)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_SelectOptions("Accountant", this.state.entity?.accountant, this.state.accountants, this.updateAccountant)}
            <hr style={{ height: "1px" }} />
            {RenderEntityField_Text("Notes", this.state.entity?.notes, this.changeNotes, this.updateNotes)}
            {this.state.uid == null ? <Row>
              <Col>
                <hr style={{ height: "1px" }} />
                <input type="button" value="Save" onClick={() => this.state.saveRow(this.state.entity)} className="standard-input" />
              </Col>
            </Row>
              :
              <Row>
                <Col>
                  <hr style={{ height: "1px" }} />
                  <input type="button" value="Close" onClick={() => window.location.reload()} className="standard-input" />
                </Col>
              </Row>
            }
          </ModalBody>
        </div>
      </Modal>

    )
  }
}

/**
 * Render a Text Field for the Entity Management Modal..
 * This reduces the amount of code in the modal render function
 * @param label
 * @param value
 * @param change
 * @param blur
 * @returns
 */
export function RenderEntityField_Text(label: string, value: string, change: (event: React.ChangeEvent<HTMLInputElement>) => void, blur: () => void): JSX.Element {
  return (
    <Row>
      <Col>
        <label>{label}</label>
      </Col>
      <Col>
        <input type="text" className="standard-input" name="code"
          value={value} onChange={change}
          onBlur={blur}
        />
      </Col>
    </Row>
  )
}

/**
 * Render a Select Options for the Entity Management Modal..
 * This should reduce the amount of code in the render function
 * @param label
 * @param value
 * @param values
 * @param change
 * @returns
 */
export function RenderEntityField_SelectOptions(
  label: string,
  value: SelectOptions,
  values: Array<SelectOptions>,
  change: (event: SelectOptions | null) => void,
): JSX.Element {
  return (
    <Row>
      <Col>
        <label>{label}</label>
      </Col>
      <Col>
        <Select
          options={values}
          value={value}
          styles={reactSelectBasicStyle}
          onChange={change}
          menuPlacement="top"
        />
      </Col>
    </Row>
  )
}

export function RenderEntityField_DatePicker(
  label: string,
  value: Date,
  change: (event: React.ChangeEvent<HTMLInputElement>) => void,
  blur: () => void
) {
  return (
    <Row>
      <Col>
        <label>{label}</label>
      </Col>
      <Col>
        <DatePicker className="standard-input"
          onChange={change}
          value={value}
          onBlur={blur}
        />
      </Col>
    </Row>
  )
}