import * as React from 'react';
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Property } from '../../PropertyComponents/LocationAnalysis';
import { Col, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';

export const NumberedMarker = (props: any) => {
  var paddingLeft = "3px";
  if (props.index > 9) {
    paddingLeft = "1px";
  }
  return (
    <div
      className="markerNoBorder"
      style={{ backgroundColor: props.color, cursor: 'pointer' }}
      title={props.name}
      onClick={props.onClick}
    >
      <p style={{ paddingLeft: paddingLeft }}>{props.index}</p>
    </div>
  )
}

export const DraggableNumberedMarker = (props: any) => {
  var paddingLeft = "3px";
  if (props.index > 9) {
    paddingLeft = "1px";
  }

  return (
    <div
      draggable
      className="markerNoBorder"
      style={{ backgroundColor: props.color, cursor: 'pointer' }}
      title={props.name}
      onClick={props.onClick}
    >
      <p style={{ paddingLeft: paddingLeft }}>{props.index}</p>
    </div>
  )
}

export const LegendTableRow = (props: any) => {
  var distance = props.distance !== undefined ? `${props.distance} Miles` : '';

  return (
    <tr>

      <td className="fixedTableCellFirst">{props.index}</td>
      {props.editable ?
        <td className="fixedTableCell">
          <input value={props.name} type="text" onChange={(e) => props.onChange(e, props.index)} className="standard-input" />
        </td>
        : <td className="fixedTableCell">
          {props.name}
        </td>
      }
      <td className="fixedTableCell">{distance}</td>
      {props.canRemove &&
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faMinus} style={{ marginLeft: "8px", cursor: 'pointer' }} onClick={(e) => props.onClick(props.index)} /></td>
      }
    </tr>
  )
}

//interface LocationMarkerProps {
//  lng: number;
//  lat: number;
//  color: string;
//  name: string;
//  unitCount: number;
//  code: string;
//  regional: Array<SelectOptions>;
//  site: Array<SelectOptions>;
//  maintenance: Array<SelectOptions>;
//  onFocus: () => void;
//}

interface LocationMarkerMultiProps {
  lng: number;
  lat: number;
  color: string;
  name: string;
  unitCount: number;
  code: string;
  regional: Array<SelectOptions>;
  site: Array<SelectOptions>;
  maintenance: Array<SelectOptions>;
  onFocus: () => void;
}

export const LocationMarkersMultiInfoShow = (props: LocationMarkerMultiProps) => {

  return (
    <div
      style={{ backgroundColor: props.color, cursor: 'pointer', border: "solid " + props.color, width: "18vw",  position: "relative" }}
      onMouseLeave={props.onFocus}
    >
      <div style={{ color: "#ffffff", padding: "10px", fontSize: "14px" }}>
        <Row>
          <Col xs="2">Name:</Col>
          <Col style={{ textAlign: "right" }}>{props.name}</Col>
        </Row>
        <hr style={{ height: "1px" }} />
        <Row>
          <Col xs="2">Code:</Col>
          <Col style={{ textAlign: "right" }}>{props.code}</Col>
        </Row>
        <hr style={{ height: "1px" }} />
        <Row>
          <Col xs="4">Unit Count:</Col>
          <Col style={{ textAlign: "right" }}>{props.unitCount}</Col>
        </Row>
        <hr style={{ height: "1px" }} />
        <Row>
          <Col xs="2">Regional:</Col>
          <Col style={{ textAlign: "right" }}>{props.regional.map(u => u.label).join(",")}</Col>
        </Row>
        <hr style={{ height: "1px" }} />
        <Row>
          <Col xs="2">Site:</Col>
          <Col style={{ textAlign: "right" }}>{props.site.map(u => u.label).join(",")}</Col>
        </Row>
        <hr style={{ height: "1px" }} />
        <Row>
          <Col xs="2">Maintenance:</Col>
          <Col style={{ textAlign: "right" }}>{props.maintenance.map(u => u.label).join(",")}</Col>
        </Row>
      </div>
    </div >
  )
}

export const LocationMarkersMultiInfoHidden = (props: LocationMarkerMultiProps) => {
  return (
    <div
      style={{ backgroundColor: props.color, cursor: 'pointer', border: "2px solid black", width: "42px", borderRadius: "10%", position: "absolute" }}
      onMouseEnter={props.onFocus}
    >
      <div style={{ color: "#ffffff", margin: "5px", textAlign: "center" }}>
        {props.code}
      </div>

    </div>
  )
}