import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { Col,Row } from 'reactstrap';
import axios from 'axios';
import { getUserID } from '../../../functions/authActions';
import EmployeePositionChangeRequestModal from '../EmployeeChangeRequest/EmployeePositionChangeModal';
import { WarningModal } from '../../CoreComponents/Modals';
import { pullEmployeeName } from '../../../functions/fetchObjectNameFunctions';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface AllocationChangeRequestData {
  uid: string;
  regional: string;
  employee: string;
  date: string;
  status: string;
  intStatus: number;
}

interface AllocationChangeRequestWidgetProps {
  isRegional: boolean;
}

interface AllocationChangeRequestWidgetState {
  rows: Array<AllocationChangeRequestData>;
  userName: string;
}

export class EmployeeChangeRequestWidget extends React.Component<AllocationChangeRequestWidgetProps, AllocationChangeRequestWidgetState> {

  allocationChangeRequestModal: React.RefObject<EmployeePositionChangeRequestModal> = React.createRef<EmployeePositionChangeRequestModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  headers: Array<FilterAndSettingField> = [
    { columnName: "Requestor", setting: true, filter: {} },
    { columnName: "Employee", setting: true, filter: {} },
    { columnName: "Effective Date", setting: true, filter: {} },
    { columnName: "Status", setting: true, filter: {} },
    { columnName: "", setting: true, filter: {} }
  ]

  constructor(props: AllocationChangeRequestWidgetProps) {
    super(props);
    this.state = {
      rows: [],
      userName: ""
    }
  }

  async componentDidMount(): Promise<void> {
    let url = !this.props.isRegional ? "./api/regional-manager-allocation-change" : "./api/regional-manager-allocation-change/" + getUserID();
    let response = await axios.get(url);
    if (response.status !== 200) {

    }
    else {
      let userName = await pullEmployeeName(getUserID());
      this.setState({ rows: response.data, userName: userName });
    }
  }

  async approve(uid: string): Promise<void> {
    await axios.get('./api/regional-manager-allocation-change/confirm/' + uid, { validateStatus: () => true });
  }

  render(): JSX.Element {
    return (
      <div>
        <WarningModal ref={this.warningModal} /> 
        <EmployeePositionChangeRequestModal ref={this.allocationChangeRequestModal} />
        {RenderHeader("Active Employee Change Requests")}
        <div style={{ maxHeight: "23vh", overflowY: "auto", paddingBottom: "1vh" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: AllocationChangeRequestData) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.regional}</td>
                    <td className="fixedTableCell">{item.employee}</td>
                    <td className="fixedTableCell">{item.date}</td>
                    <td className="fixedTableCell">{item.status}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Review" className="standard-input" onClick={() => this.warningModal.current.show("Feature coming soon. If you are seeing this message, we haven't had time to implement this feature, we are working on it and will update it as soon as we can.", "Feature Pending")} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr />
        <Row>
          <Col>
            <input type="button" value="Start New" className="standard-input" onClick={() => this.allocationChangeRequestModal.current.show()} />
          </Col>
        </Row>
      </div>
    )
  }
}
