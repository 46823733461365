import * as React from 'react';
import { DashboardManagementModal } from '../CoreComponents/Modals/DashboardManagementModal';
import { RenderSettings } from '../../interfaces/WidgetSettingsInterfaces';
import { DashboardOrganizer, DefaultPageSettings } from '../../functions/WidgetDashboardOrganizer';
import { GetSettings } from '../../functions/StateManagement/StateStorageFunctions';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { pullEmpireEmployees } from '../../functions/fetchLinkedObjects';
import AdobeSignManagementWidget from './Widgets/AdobeSignManagementWidget';

interface EmployeeManagementDashboardState {
  uid: string;
  showModal: boolean;
  renderSettings: RenderSettings;
  widgetList: Array<SelectOptions>;
  tabList: { [key: number]: JSX.Element };
  employee: SelectOptions;
  employees: Array<SelectOptions>;
}

export default class EmployeeManagementDashboard extends React.Component<{}, EmployeeManagementDashboardState> {

  widgetSettingsModalRef: React.RefObject<DashboardManagementModal> = React.createRef<DashboardManagementModal>();
  settingsString: string = "employeeManagementDashbaord";

  constructor(props: {}) {
    super(props);
    let params = new URLSearchParams(window.location.search);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal: boolean = false;
    if (settings === null) {
      showModal = true
      settings = DefaultPageSettings
    }
    let uid = params.has('uid') ? params.get('uid') : "INVALID";
    if (uid === "INVALID") { throw Error("Invalid UID Requested during State Initialization") }
    this.state = {
      uid: uid,
      showModal: showModal,
      renderSettings: settings,
      tabList: [],
      widgetList: [],
      employee: { label: "Select Employee", value: "" },
      employees: []
    }
    this.updateUI = this.updateUI.bind(this);
    this.setEmployee = this.setEmployee.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDashboard();
    let employees = await pullEmpireEmployees();
    let employee = employees.filter(u => u.value === this.state.uid)[0];
    this.setState({ employees: employees, employee: employee });
  }

  async prepareDashboard(): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      0: <AdobeSignManagementWidget uid={this.state.uid} />
    }
    let widgetList = [
      { label: "Adobe Sign Setup", value: "0" }
    ]
    this.setState({
      widgetList: widgetList,
      tabList: tabList
    })
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  setEmployee(event: SelectOptions | null): void {
    let params = new URLSearchParams(window.location.search);
    params.set('uid', event.value);
    window.location.search = params.toString();
    this.setState({ employee: event }, () => this.prepareDashboard());
  }

  render(): JSX.Element {
    return (
      <div style={{ overflowX: "hidden", overflowY: "scroll", height: "90vh" }}>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "10vw", paddingTop: "2vh" }}>
            <Select
              options={this.state.employees}
              styles={reactSelectBasicStyle}
              value={this.state.employee}
              onChange={this.setEmployee}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", width: "62vw" }}>
            <h5 style={{ textAlign: "center" }}>- Manage Employee -</h5>
            <h3 style={{ textAlign: "center" }}>{this.state.employee.label}</h3>
          </div>
          <div style={{ display: "block", height: "10vh", float: "left", marginLeft: "2%", paddingTop: "2vh" }}>
            <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div>
          {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
        </div>
      </div>
    )
  }
}