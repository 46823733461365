//#region Imports
import * as React from 'react';
import axios from 'axios';
import { ContactTypes } from '../../constants/EnumConstants';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import * as Structs from './interfaces';
import { pullStates } from '../../functions/fetchLinkedObjects';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { getBearerToken } from '../../functions/authActions';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import ContactManagementModal, { ContactManagementModalData } from './ContactManagementModal';
import { WarningModal } from '../CoreComponents/Modals';
//#endregion

export class ContactList extends React.Component<Structs.ContactListDataProps, Structs.ContactListDataState> {

  managementModal: React.RefObject<ContactManagementModal> = React.createRef<ContactManagementModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: Structs.ContactListDataProps) {
    super(props);
    this.state = {
      contacts: [],
      states: [],
      headers: [],
      name: "",
      email: "",
      title: ""
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.pullContactInformation = this.pullContactInformation.bind(this);
    this.setName = this.setName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setTitle = this.setTitle.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let states = await pullStates();
    this.setState({ states: states }, async () => await this.generateHeaders());
  }

  async pullContactInformation(): Promise<void> {
    const data = {
      page: -1,
      pageCount: -1,
      name: this.state.name,
      email: this.state.email,
      title: this.state.title
    };
    let response = await axios.post('./api/contacts/filter', data);
    if (response.status === 200) {
      const contacts: Array<ContactManagementModalData> = [];
      const states = this.state.states;
      response.data.forEach(function (item: Structs.ServerContactResponse) {
        contacts.push({
          uid: item.uid,
          phoneNumber: item.phoneNumber,
          emailAddress: item.emailAddress,
          firstName: item.firstName,
          lastName: item.lastName,
          title: item.title,
          contactType: ContactTypes[parseInt(item.contactType)],
          streetAddress: item.streetAddress,
          city: item.city,
          zipCode: item.zipCode,
          state: states.filter(u => parseInt(u.value) === parseInt(item.state))[0]
        });
      })
      this.setState({
        contacts: contacts
      })
    }
  }

  async generateHeaders(): Promise<void>  {
    const headers: Array<FilterAndSettingField> =  [
      {
        columnName: "Name",
        setting: true,
        filter: {
          type: "text",
          changeFilter: this.setName,
          value: this.state.name
        }
      },
      {
        columnName: "Email",
        setting: true,
        filter: {
          type: "text",
          changeFilter: this.setEmail,
          value: this.state.email
        }
      },
      {
        columnName: "Title",
        setting: true,
        filter: {
          type: "text",
          changeFilter: this.setTitle,
          value: this.state.title
        }
      },
      {
        columnName: "",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      },
      {
        columnName: "",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      }
    ]
    this.setState({ headers: headers }, async () => await this.pullContactInformation());
  }

  async setName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ name: event.target.value }, async () => await this.generateHeaders());
  }

  async setEmail(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ email: event.target.value }, async () => await this.generateHeaders());
  }

  async setTitle(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ title: event.target.value }, async () => await this.generateHeaders());
  }

  async archiveProperty(uid: string) {
    let response = await axios.delete('./api/contacts/' + uid);
    if (response.status !== 202) {
      this.warningModal.current.show("Error archiving contact. Please reach out to the tech team so we can investigate the reason.", "Error");
    }
    else {
      window.location.reload();
    }
  }

  /**
   * Render Contact List
   */
  render(): JSX.Element {
    return (
      <div>
        <ContactManagementModal ref={this.managementModal} />
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Contacts")}
        <div style={{ minHeight: "82vh", maxHeight: "82vh", overflow: "scroll" }} >
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.contacts.map((item: ContactManagementModalData) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.firstName + " " + item.lastName}</td>
                    <td className="fixedTableCell">{item.emailAddress}</td>
                    <td className="fixedTableCell">{item.title}</td>
                    <td className="icon fixedTableCell">
                      <input type="button" value="Edit" className="standard-input" onClick={() => this.managementModal.current.show(item) } />
                    </td>
                    <td className="icon fixedTableCell">
                      <input type="button" value="Archive" className="standard-input" onClick={() => this.archiveProperty(item.uid)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <input type="button" value="Add New Contact" className="standard-input" style={{ width: "25%" }} onClick={() => this.managementModal.current.show()} />
      </div>
    )
  }
}