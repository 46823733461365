import axios, { AxiosError } from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { DatePicker } from '../CoreComponents/DateComponents';
import { StatusModal, WarningModal } from '../CoreComponents/Modals';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface VMIPayrollProps {

}

interface VMIPayrollState {
  laborFile: string;
  overrideDistributions: string;
  payDate: Date;
  test: boolean;
}

export default class VMIPayroll extends React.Component<VMIPayrollProps, VMIPayrollState> {

  warningModal = React.createRef<WarningModal>();
  statusModal = React.createRef<StatusModal>();

  constructor(props: VMIPayrollProps) {
    super(props);
    this.state = {
      laborFile: '',
      overrideDistributions: null,
      payDate: new Date(),
      test: false
    }
    this.changeLaborFile = this.changeLaborFile.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeIsTest = this.changeIsTest.bind(this);
    this.processPayroll = this.processPayroll.bind(this);
    this.changeDistributionFile = this.changeDistributionFile.bind(this);
  }

  changeLaborFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      //@ts-ignore
      laborFile: event.target.files[0]
    })
  }

  changeDistributionFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      //@ts-ignore
      overrideDistributions: event.target.files[0]
    })
  }

  changeDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      payDate: new Date(event.target.valueAsDate) === null ? new Date(1, 0, 1) : new Date(event.target.valueAsDate)
    })
  }

  changeIsTest(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ test: event.target.checked });
  }

  async processPayroll(): Promise<void> {
    const formData = new FormData();
    let key = await axios.get('./api/status-manager');
    formData.append("labor", this.state.laborFile);
    formData.append("payrollDate", this.state.payDate.toISOString());
    formData.append("statusKey", key.data);
    formData.append("test", this.state.test.toString());
    formData.append("distributions", this.state.overrideDistributions);
    this.statusModal.current.display("Processing Viridian Payroll", "Viridian Payroll is current processing.", key.data, 2500);
    let response = await axios.post('./api/payroll/vmi', formData, {
      responseType: 'blob',
      validateStatus: () => true,
      timeout: 240000,
      timeoutErrorMessage: "Payroll Processing Took more than 4 minutes, please contact the tech department"
    });
    this.statusModal.current.hide();
    if (response.status === 200) {
      const FileDownload = require('js-file-download');
      const content = response.headers['content-disposition'];
      const startIndex = content.lastIndexOf('\'\'') + 2;
      const name = content.substring(startIndex, content.length);
      FileDownload(response.data, decodeURI(name));
    }
    else if (response.status === 412) {
      this.warningModal.current.show("Payroll Threw the Following Error: " + await response.data.text());
    }
    else {
      this.warningModal.current.show("Unhandled error. Call Johnny or Tom: " + await response.data.text());
    }
  }

  render(): JSX.Element {
    return (
      <div style={{ width: "50%", border: "2px solid #c2a877", backgroundColor: "#4c4a42", paddingTop: "1%", marginTop: "1vh" }}>
        <WarningModal ref={this.warningModal} />
        <StatusModal ref={this.statusModal} />
        {RenderHeader("Viridian Payroll")}
        <Row>
          <Col>
            <h5 style={{ marginLeft: "1%"}}>Labor Distribution Report</h5>
          </Col>
          <Col>
            <input type="file" accept='.xlsx' onChange={this.changeLaborFile} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginLeft: "1%" }}>Payroll Date</h5>
          </Col>
          <Col>
            <DatePicker onChange={this.changeDate} value={this.state.payDate} style={{ textAlign: "right"}} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginLeft: "1%" }}>Override System Allocations</h5>
          </Col>
          <Col>
            <input type="file" accept='.csv' onChange={this.changeDistributionFile} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginLeft: "1%" }}>Run as Test</h5>
          </Col>
          <Col>
            <input type="checkbox" checked={this.state.test} style={{ marginLeft: "10vw", marginTop: "1vh" }}
              onChange={this.changeIsTest}
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col></Col>
          <Col>
            <input type="button" className="standard-input" value="Process" onClick={this.processPayroll} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row style={{ marginBottom: "1vh"}}>
          <Col>
            <input type="button" className="standard-input" value="View Records" onClick={() => window.location.assign("/payroll-distribution?company=VMI")} />
          </Col>
          <Col>
            <input type="button" className="standard-input" value="View Documentation"
              onClick={() => window.open("https://docs.google.com/document/d/1_3ubSuEdZmqFX-M1NPJPV42zzXJ9FkQ0LBV7glsG8Dg/edit?usp=sharing")}
            />
          </Col>
        </Row>
        
      </div>
    )
  }

}