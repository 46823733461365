import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { BankingInformation } from './EntityBankingInformationRequest';
import { FormationDataTab } from './EntityFormationData';
import { EntityMemberInformation } from './EntityMemberInformation';
import { EntityCreationTrackingInfo } from './EntityCreationTrackingInfo';
import axios from 'axios';
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { getBearerToken, getUserID } from '../../../../functions/authActions';

interface EntityRequestFormProps {

}

interface EntityRequestFormState {
  uid: string;
  currentTab: number;
  formationValid: boolean;
  memberValid: boolean;
  bankValid: boolean;
}

/**
 * Entity Request Form
 * This class manages the page for creating a New Entity Request.
 * 
 * First the constructor looks at the url to see if there is a UID of a New Request. If there is an UID then it will take the UID and set it as the states uid variable.
 * If there is no UID present then the uid variable will be left as ''.
 * 
 * Once the class has made this decision it will continue
 * 
 * If a UID is present and has been set to the states uid variable it will then send the uid to the childeren classes for the data to be called from the database and
 * allow the child to manipulate the Entity Request
 * 
 * Formation Data
 * This child is responible for loading or creating a New Entity Request
 * 
 * Memeber Information
 * This child will only appear in the UI and work if a New Entity Request has been created
 * It allows for mapping Entities to the New Entity Request
 * 
 * Banking Information
 * This child will only appear in the UI and work if a New Entity Request has been created
 * It allows for creating and mapping Bank information to the New Entity Request
 * 
 * Tracking Information
 * This child will only appear in the UI and work if a New Entity Request has been created
 * It allows for notes to be created and mapped to the New Entity Request to enable users to post notes about concerns of the New Entity Request
 * 
 * */
export default class EntityRequestForm extends React.Component<EntityRequestFormProps, EntityRequestFormState>{

  constructor(props: EntityRequestFormProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const draftUID = params.has("draftUID") ? params.get("draftUID") : "";

    this.state = {
      uid: draftUID,
      currentTab: 0,
      formationValid: false,
      memberValid: false,
      bankValid: false
    }
    this.checkValid = this.checkValid.bind(this);
    this.getTab = this.getTab.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  async componentDidUpdate(): Promise<void> {

  }

  checkValid(checkedValue: boolean): void {
    if (this.state.currentTab === 0) { this.setState({ formationValid: checkedValue }) }
    else if (this.state.currentTab === 1) { this.setState({ memberValid: checkedValue }) }
    else if (this.state.currentTab === 2) { this.setState({ bankValid: checkedValue }) }
  }

  getTab(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <FormationDataTab uid={this.state.uid} checkValid={this.checkValid} />
      case 1:
        return <EntityMemberInformation uid={this.state.uid} checkValid={this.checkValid} />
      case 2:
        return <BankingInformation uid={this.state.uid} checkValid={this.checkValid} />
      case 3:
        return <EntityCreationTrackingInfo uid={this.state.uid} />
      default:
        console.error("Someone has figured out how to choose an incorrect tab");
    }
  }

  async submit(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();

    const workflowData = {
      UID: this.state.uid,
      RequestorUID: getUserID()
    }

    await axios.patch('./api/new-entity-workflow/next-node-entity', workflowData).then((respsonse) => {

    })

    await axios.patch("./api/new-entity-request/submit-request/" + this.state.uid).then((response) => {
      CreateGlobalAlert("Draft has been submitted, redirecting to Drafts Table", 2500);
      window.location.replace("./entity-request-drafts")
    });
  }

  render(): JSX.Element {
    return (
      <div>
        <Row>
          <Col>
            <h2>New Entity Request Form</h2>
          </Col>
          <Col>
            {(this.state.formationValid && this.state.memberValid && this.state.bankValid) && <input type="button" className="standard-input" value="Submit Request" style={{ width: "60%", height: "5vh", marginBottom: "1vh", float: "right" }} onClick={this.submit} />}
          </Col>
        </Row>
        <hr />
        <Row >
          <Col style={{ marginBottom: "10px" }}>
            <input type="button" className={this.state.currentTab === 0 ? "tabActive" : "tab"} style={{ width: "25%" }} value="Formation Data" onClick={() => this.setState({ currentTab: 0 })} />
            <input type="button" className={this.state.currentTab === 1 ? "tabActive" : "tab"} style={{ width: "25%" }} value="Members" onClick={() => this.setState({ currentTab: 1 })} hidden={!this.state.formationValid} />
            <input type="button" className={this.state.currentTab === 2 ? "tabActive" : "tab"} style={{ width: "25%" }} value="Banking" onClick={() => this.setState({ currentTab: 2 })} hidden={!this.state.memberValid} />
            <input type="button" className={this.state.currentTab === 3 ? "tabActive" : "tab"} style={{ width: "25%" }} value="Tracking Information" onClick={() => this.setState({ currentTab: 3 })} hidden={this.state.uid === ''} />
          </Col>
        </Row>
        <Row style={{ width: "98%", marginLeft: "1%" }}>
          <Col>
            {
              this.getTab()
            }
          </Col>
        </Row>
      </div>
    )
  }
}