import * as React from 'react';
import { StatusModal, WarningModal } from '../CoreComponents/Modals';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import PhoneTrackingModal from './PhoneTrackingManagementWidget.modals';
import axios from 'axios';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { Col, Row } from 'reactstrap';
import { DownloadNamedFile } from '../../functions/documentTools';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';



export interface PhoneTrackingSummary {
  uid: string;
  brand: string;
  model: string;
  phoneNumber: string;
  monthlyCost: number;
  purchaseCost: number;
  employee: SelectOptions;
  deviceID: string;
  imei: string;
  description: string;
}

interface PhoneTrackingManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<PhoneTrackingSummary>;
  brand: string;
  model: string;
  employee: string;
  phoneNumber: string;
  description: string;
}

export default class PhoneTrackingManagementWidget extends React.Component<{}, PhoneTrackingManagementWidgetState> {

  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  trackingModal: React.RefObject<PhoneTrackingModal> = React.createRef<PhoneTrackingModal>();
  container: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  constructor(props: {}) {
    super(props);

    this.state = {
      headers: [],
      data: [],
      model: "",
      brand: "",
      employee: "",
      phoneNumber: "",
      description: ""
    }
    this.callback = this.callback.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeBrand = this.changeBrand.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.changePhoneNumber = this.changePhoneNumber.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async updateData(): Promise<void> {
    const data = {
      page: 0,
      pageCount: 0,
      model: this.state.model,
      brand: this.state.brand,
      employee: this.state.employee,
      phoneNumber: this.state.phoneNumber,
      description: this.state.description
    }
    let response = await axios.post('./api/phone-tracking/filter', data, { validateStatus: () => true });
    if (response.status === 200) {
      let data: Array<PhoneTrackingSummary> = [];
      console.log(response.data);
      response.data.forEach((item: any) => {
        data.push({
          employee: { label: item.employee.fullName, value: item.employee.uid },
          uid: item.uid,
          brand: item.brand,
          model: item.model,
          phoneNumber: item.phoneNumber,
          monthlyCost: item.monthlyCost,
          purchaseCost: item.purchaseCost,
          deviceID: item.deviceID,
          imei: item.imei,
          description: item.description
        })
      })
      this.setState({ data: data });
    }
    else {
      this.warningModal.current.show("There was an error loading data. Please reach out to the tech team to let them know.", "Error");
    }
  }

  async changeEmployee(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ employee: event.target.value }, async () => await this.generateHeaders());
  }

  async changeDescription(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ description: event.target.value }, async () => await this.generateHeaders());
  }

  async changeBrand(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ brand: event.target.value }, async () => await this.generateHeaders());
  }

  async changeModel(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ model: event.target.value }, async () => await this.generateHeaders());
  }

  async changePhoneNumber(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ phoneNumber: event.target.value }, async () => await this.generateHeaders());
  }

  async generateHeaders(): Promise<void> {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Employee",
        setting: true,
        filter: {
          type: "text",
          name: "employee",
          value: this.state.employee,
          changeFilter: this.changeEmployee,
          placeHolder: "Filter Employees"
        }
      },
      {
        columnName: "Description",
        additionalClasses: "twenty",
        setting: true,
        filter: {
          type: "text",
          name: "description",
          value: this.state.description,
          changeFilter: this.changeDescription,
          placeHolder: "Filter Description"
        }
      },
    ]
    if (cols < 4) {
      headers.push({
        columnName: "Brand",
        setting: true,
        additionalClasses: "twenty",
        filter: {
          type: "text",
          name: "brand",
          value: this.state.brand,
          changeFilter: this.changeBrand,
          placeHolder: "Filter Brands"
        }
      })
    }
    if (cols === 2) {
      headers.push({
        columnName: "Model",
        setting: true,
        additionalClasses: "fifteen",
        filter: {
          type: "text",
          name: "model",
          value: this.state.model,
          changeFilter: this.changeModel,
          placeHolder: "Filter Model"
        }
      })
    }
    headers.push({
      columnName: "Number",
      setting: true,
      additionalClasses: "twenty",
      filter: {
        type: "text",
        name: "phoneNumber",
        value: this.state.phoneNumber,
        changeFilter: this.changePhoneNumber,
        placeHolder: "Filter Phone Number"
      }
    })
    if (cols < 4) {
      headers.push({
        columnName: "Monthly Cost",
        setting: true,
        additionalClasses: "twenty",
        filter: {}
      })
    }
    headers.push({
      columnName: "",
      setting: true,
      additionalClasses: "ten",
      filter: {}
    })
    this.setState({ headers: headers }, async () => await this.updateData());
  }

  callback(edit: boolean): void {
    this.trackingModal.current.hide();
    if (!edit) {
      this.warningModal.current.show("Phone Tracking has been created.", "Phone Tracking Created", () => window.location.reload());
    }
    else {
      window.location.reload();
    }
  }

  getRow(item: PhoneTrackingSummary): JSX.Element {
    let width = this.container.current.parentElement.style.width;
    let cols = width === "24%" ? 4 : (width === "49%" ? 2 : 3);
    return (<tr key={item.uid}>
      <td className="fixedTableCellFirst">{item.employee.label}</td>
      <td className="fixedTableCell">{item.description}</td>
      {cols < 4 && <td className="fixedTableCell">{item.brand}</td>}
      {cols === 2 && <td className="fixedTableCell">{item.model}</td>}
      <td className="fixedTableCell">{item.phoneNumber}</td>
      {cols < 4 && <td className="fixedTableCell">{item.monthlyCost}</td>}
      <td className="fixedTableCell">
        <input type="button" value="View" className="standard-input" onClick={() => this.trackingModal.current.show(item)} />
      </td>
    </tr>)

  }

  async exportFilter(): Promise<void> {
    const data = {
      page: 0,
      pageCount: 0,
      model: this.state.model,
      brand: this.state.brand,
      employee: this.state.employee,
      phoneNumber: this.state.phoneNumber,
      description: this.state.description
    }
    this.statusModal.current.display("Exporting Phone Tracking", "");
    let response = await axios.post('./api/phone-tracking/filter/export', data, { validateStatus: () => true });
    if (response.status === 200) {
      await DownloadNamedFile(response);
      this.statusModal.current.hide();
    }
  }

  render(): JSX.Element {
    return (
      <div ref={this.container}>
        <StatusModal ref={this.statusModal} />
        <WarningModal ref={this.warningModal} />
        <PhoneTrackingModal ref={this.trackingModal} callback={this.callback} />
        {RenderHeader("Phone Tracking")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  this.getRow(item)
                ))
              } 
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Create" className="standard-input" onClick={() => this.trackingModal.current.show()} />
          </Col>
          <Col>
            <input type="button" value="Export Filtered" className="standard-input" onClick={() => this.exportFilter()} />
          </Col>
        </Row>
      </div>
    )
  }
}