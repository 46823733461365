import * as React from 'react';
import { numberWithCommas } from '../../functions/numberFunctions';
import ElectricDataRow, { ElectricRow } from './ElectricDataRow';

interface ElectricUtilityTableProps {
  updateRow: (row: ElectricRow, idx: number) => void;
  rows: Array<any>;
  flatFees: number;
  taxRate: number;
  consumption: number;
  demand: number;
  estimatedCost: number;
}

interface ElectricUtilityTableState {
  rows: Array<any>;
  flatFees: number;
  taxRate: number;
  consumption: number;
  demand: number;
  estimatedCost: number;
}

export default class ElectricUtilityRateTable extends React.Component<ElectricUtilityTableProps, ElectricUtilityTableState> {

  constructor(props: ElectricUtilityTableProps) {
    super(props);
    this.state = {
      rows: props.rows,
      flatFees: props.flatFees,
      taxRate: props.taxRate,
      consumption: props.consumption,
      demand: props.demand,
      estimatedCost: props.estimatedCost
    }
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
  }

  componentDidUpdate(nextProps: ElectricUtilityTableProps): void {
    if (this.state.flatFees != nextProps.flatFees) {
      this.setState({
        flatFees: nextProps.flatFees,
        rows: nextProps.rows
      })
    }
    if (this.state.taxRate != nextProps.taxRate) {
      this.setState({
        taxRate: nextProps.taxRate,
        rows: nextProps.rows
      })
    }
    if (this.state.consumption != nextProps.consumption) {
      this.setState({
        consumption: nextProps.consumption,
        rows: nextProps.rows
      })
    }
    if (this.state.demand != nextProps.demand) {
      this.setState({
        demand: nextProps.demand,
        rows: nextProps.rows
      })
    }
  }

  addRow(): void {
    var tmp = this.state.rows;
    tmp.push({ "Key": Math.random().toString(36).substring(7) });
    this.setState({ rows: tmp });
  }

  removeRow(id: string): void {
    var tmp = this.state.rows.filter(function (item) {
      return item.Key !== id
    })
    this.setState({ rows: tmp });
  }

  render(): JSX.Element {
    return (
      <table id="rate-table" style={{ tableLayout: "fixed", fontSize: "14px" }}>
        <thead>
          <tr>
            <th className="fixedTableHeaderFirst">Description</th>
            <th className="fixedTableHeader">Rate</th>
            <th className="fixedTableHeader">Start kWh</th>
            <th className="fixedTableHeader">End kWh</th>
            <th className="fixedTableHeader">Type</th>
            <th className="fixedTableHeader">Cost</th>
            <th className="fixedTableHeader"></th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.rows.map((item: ElectricRow, index: number) =>
            (
              <ElectricDataRow key={index} id={item.description} removeRow={this.removeRow} updateRow={this.props.updateRow}
                row={item} index={index}
              />
            ))
          }
          <tr id="effective-row">
            <td className="fixedTableCellFirst">Flat Rate:</td>
            <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(this.state.flatFees)}</td>
            <td className="fixedTableCell">Tax Percentage: </td>
            <td id="tax-rate" className="fixedTableCell" style={{ textAlign: "right" }}>
              {this.state.taxRate}%
            </td>
            <td className="fixedTableCell"></td>
            <td></td>
            <td className="fixedTableCell"></td>
          </tr>
          <tr id="effective-row">
            <td className="fixedTableCellFirst">Consumption Rate:</td>
            <td className="fixedTableCell" id="effective-rate" style={{ textAlign: "right" }}>
              ${this.state.consumption.toFixed(4)}
            </td>
            <td className="fixedTableCell">Demand Rate: </td>
            <td id="demand-rate" className="fixedTableCell" style={{ textAlign: "right" }}>
              ${this.state.demand.toFixed(4)}
            </td>
            <td className="fixedTableCell">Estimated Cost: </td>
            <td id="effective-cost" className="fixedTableCell">
              ${this.state.estimatedCost.toFixed(2)}
            </td>
            <td className="fixedTableCell">
              <input type="button" value="+" style={{ borderRadius: "15px", width: "100%" }} onClick={this.addRow} />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}