import axios from 'axios';
import * as React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getBearerToken } from '../../functions/authActions';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { StatusModal, WarningModal } from '../CoreComponents/Modals';
import EmployeeDataEntryModal from './EmpireEmployeeListAuth.DataEntryModal';
import { CompanyNames_Select, CompanyTeams, NewEmployeeRoles } from '../../constants/EnumConstants';

interface ViridianEmployeeListProps {

}

interface ViridianEmployeeListState {
  headers: Array<FilterAndSettingField>;
  employees: Array<Employee>;
  employeeName: string;
  company: SelectOptions;
  team: SelectOptions;
}

export interface Employee {
  uid: string;
  firstName: string;
  alternativeNames: string;
  lastName: string;
  company: SelectOptions;
  team: SelectOptions;
  role: SelectOptions;
  email: string;
  phoneNumber: string;
  isAdmin: boolean;
  isKBEditor: boolean;
  sendHandbook: boolean;
}

export const EmployeeCompany: Array<SelectOptions> = [
  { label: "All", value: "-1" },
  { label: "CDI", value: "0" },
  { label: "VMI", value: "1" },
  { label: "MCI", value: "2" },
  { label: "Fleet", value: "3" },
  { label: "ABC", value: "4" },
  { label: "VCON", value: "5" },
  { label: "VCL", value: "6" },
  { label: "IPA", value: "7" }
]

export default class EmpireEmployeeListAuth extends React.Component<ViridianEmployeeListProps, ViridianEmployeeListState>{

  statusRef = React.createRef<StatusModal>();
  warningRef = React.createRef<WarningModal>();
  dataRef = React.createRef<EmployeeDataEntryModal>();
  termRef = React.createRef<EmployeeTermModal>();

  constructor(props: ViridianEmployeeListProps) {
    super(props);
    this.state = {
      headers: [],
      employees: [],
      employeeName: "",
      company: CompanyNames_Select[0],
      team: { label: "All", value: "-1"}
    }
    this.editEmpireEmployee = this.editEmpireEmployee.bind(this);
    this.update = this.update.bind(this);
    this.doneUpdating = this.doneUpdating.bind(this);
    this.doneTerminating = this.doneTerminating.bind(this);
    this.changeEmployeeName = this.changeEmployeeName.bind(this);
    this.changeCompany = this.changeCompany.bind(this);
    this.changeTeam = this.changeTeam.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    this.headers();
  }

  async update(): Promise<void> {
    const data = {
      Page: 0,
      PageCount: 0,
      EmployeeName: this.state.employeeName,
      Company: this.state.company.value,
      Team: this.state.team.value
    }
    const response = await axios.post('api/empire/auth/filter', data);
    const employees: Array<Employee> = [];
    response.data.forEach((employee: any) => {
      employees.push({
        uid: employee.uid,
        firstName: employee.firstName,
        lastName: employee.lastName,
        alternativeNames: employee.alternativeNames,
        company: EmployeeCompany.filter(u => u.value === employee.company.toString())[0],
        team: CompanyTeams.filter(u => u.value === employee.team.toString())[0],
        role: NewEmployeeRoles.filter(u => u.value === employee.role.toString())[0],
        email: employee.email,
        phoneNumber: employee.phoneNumber,
        isAdmin: employee.isAdmin,
        isKBEditor: employee.isKBEditor,
        sendHandbook: employee.sendHandbook
      })
    })
    this.setState({ employees: employees });
  }

  async changeEmployeeName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({
      employeeName: event.target.value
    }, async () => await this.headers())
  }

  async changeCompany(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ company: event}, async () => this.headers())
  }

  async changeTeam(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ team: event }, async () => this.headers())
  }

  async headers(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Employee Name',
        additionalClasses: "fifteen",
        setting: true,
        filter: {
          type: "text",
          name: "employeeName",
          value: this.state.employeeName,
          changeFilter: this.changeEmployeeName,
          placeHolder: "Filter Employee Name",
          
        }
      },
      {
        columnName: 'Company',
        additionalClasses: "ten",
        setting: true,
        filter: {
          name: "company",
          value: this.state.company,
          changeFilter: this.changeCompany,
          options: CompanyNames_Select,
          multi: false
        }
      },
      {
        columnName: 'Team',
        additionalClasses: "ten",
        setting: true,
        filter: {
          name: "team",
          value: this.state.team,
          changeFilter: this.changeTeam,
          options: [{ label: "All", value: "-1" }].concat(CompanyTeams),
          multi: false
        }
      },
      {
        columnName: 'Role',
        additionalClasses: "ten",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'Email',
        additionalClasses: "twenty",
        setting: true,
        filter: {

        }
      },
    ]
    this.setState({ headers: headers }, async () => await this.update())
  }

  editEmpireEmployee(employee: Employee): void {
    this.dataRef.current.display(employee, this.doneUpdating)
  }

  async doneUpdating(): Promise<void> {
    this.warningRef.current.show("Employee has been updated", "Employee Updated Correctly");
    await this.update();
  }

  async doneTerminating(): Promise<void> {
    this.warningRef.current.show("Employee has been archived & terminated", "Employee Terminated");
    await this.update();
  }

  term(employee: Employee): void {
    this.termRef.current.show(employee, this.doneTerminating);
  }

  render(): JSX.Element {
    return (
      <div>
        <WarningModal ref={this.warningRef} />
        <EmployeeDataEntryModal ref={this.dataRef} />
        <EmployeeTermModal ref={this.termRef} />
        <h3>Empire Employees</h3>
        <div style={{ maxHeight: "78vh", minHeight: "78vh", overflowX: "hidden", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={2} />
            </thead>
            <tbody>
              <>
                {this.state.employees.map((employee: Employee) =>
                  <tr key={employee.uid}>
                    <td className="fixedTableCellFirst" onClick={() => window.location.assign('employee-management?uid=' + employee.uid)} style={{ cursor: 'pointer'}}> 
                      {employee.firstName + " " + employee?.lastName}
                    </td>
                    <td className="fixedTableCell">{employee.company.label}</td>
                    <td className="fixedTableCell">{employee.team.label}</td>
                    <td className="fixedTableCell">{employee.role.label}</td>
                    <td className="fixedTableCell">{employee.email}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Edit" className="standard-input" onClick={() => this.editEmpireEmployee(employee)} />
                    </td>
                    <td className="fixedTableCell">
                      <input type="button" value="Terminate" onClick={() => this.term(employee)} className="standard-input" />
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="fixedTableCellFirst"></td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell"></td>
                  <td className="fixedTableCell">
                    <input type="button" value="Create" className="standard-input" onClick={() => this.dataRef.current.display(null, this.update)} />
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

interface EmployeeTermModalProps {

}

interface EmployeeTermModalState {
  showing: boolean;
  employee: Employee;
  callback: () => void;
}

class EmployeeTermModal extends React.Component<EmployeeTermModalProps, EmployeeTermModalState> {

  constructor(props: EmployeeTermModalProps) {
    super(props);
    this.state = {
      showing: false,
      employee: undefined,
      callback: undefined
    }
    this.callback = this.callback.bind(this);
    this.term = this.term.bind(this);
  }

  show(employee: Employee, callback: () => void): void {
    this.setState({
      showing: true,
      employee: employee,
      callback: callback
    })
  }

  callback(): void {
    this.setState({
      showing: false
    });
    if (this.state.callback) {
      this.state.callback();
    }
  }

  async term(): Promise<void> {
    const response = await axios.patch('./api/empire/auth/term/' + this.state.employee.uid)
    this.setState({ showing: false })
    if (this.state.callback) {
      this.state.callback();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing}>
        <div style={{ background: "#4c4a42" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ showing: false })}>
            Terminate Employee
          </ModalHeader>
          <ModalBody>
            <h4>Are you sure you want to termiante: {this.state.employee?.firstName} {this.state.employee?.lastName}</h4>
          </ModalBody>
          <ModalFooter>
            <input type="button" value="Yes" className="standard-input" onClick={this.term}
              style={{
                backgroundColor: "#c2a877",
                color: "#15405c"
              }}
            />
            <input type="button" value="No" className="standard-input" onClick={this.callback}
              style={{
                backgroundColor: "#c2a877",
                color: "#15405c"
              }}
            />
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}