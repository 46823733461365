import axios from 'axios';
import * as React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getUserID } from '../../../../functions/authActions';
import { CreateGlobalAlert } from '../../../../functions/CreateGlobalAlerts';
import { StatusModal } from '../../../CoreComponents/Modals';
import { NewEntityDraftPatchData } from './NewEntityRequestSubmittedDraft';

interface EntityRequestSubmitModalProps {

}

interface EntityRequestSubmitModalState {
  uid: string;
  show: boolean;
  stateEntityName: string;
  actName: string;
  interestGroup: string;
  isQualifiedIncome: boolean;
  isRepresented: boolean;
  representor: string;
  isRD: boolean;
  callback: (data: NewEntityDraftPatchData) => void;
}

export default class EntityRequestSubmitModal extends React.Component<EntityRequestSubmitModalProps, EntityRequestSubmitModalState>{

  constructor(props: EntityRequestSubmitModalProps) {
    super(props);
    this.state = {
      show: false,
      uid: "",
      stateEntityName: "",
      actName: "",
      interestGroup: "",
      isQualifiedIncome: false,
      isRepresented: false,
      representor: "",
      isRD: false,
      callback: undefined
    }
    this.changeStateEntityName = this.changeStateEntityName.bind(this);
    this.changeActName = this.changeActName.bind(this);
    this.changeInterestGroupName = this.changeInterestGroupName.bind(this);
    this.changeIsIncomeQualified = this.changeIsIncomeQualified.bind(this);
    this.changeIsRepresented = this.changeIsRepresented.bind(this);
    this.changeRepresentorsName = this.changeRepresentorsName.bind(this);
    this.changeIsRuralDevelopment = this.changeIsRuralDevelopment.bind(this);
    this.callback = this.callback.bind(this)
  }

  display(uid: string, stateEntityName: string, actName: string, interestGroup: string, isQualifiedIncome: boolean, isRepresented: boolean, representor: string, isRD: boolean, callback: (data: NewEntityDraftPatchData) => void): void {
    this.setState({
      show: true,
      uid: uid,
      stateEntityName: stateEntityName,
      actName: actName,
      interestGroup: interestGroup,
      isQualifiedIncome: isQualifiedIncome,
      isRepresented: isRepresented,
      representor: representor,
      isRD: isRD,
      callback: callback
    })
  }

  callback(): void {

    const data: NewEntityDraftPatchData = {
      uid: this.state.uid,
      stateEntityName: this.state.stateEntityName,
      actName: this.state.actName,
      interestGroup: this.state.interestGroup,
      isQualifiedIncome: this.state.isQualifiedIncome,
      isRepresented: this.state.isRepresented,
      isRD: this.state.isRD,
      representor: this.state.representor
    }

    this.setState({
      show: false
    });
    if (this.state.callback) {
      this.state.callback(data);
    }
  }

  changeStateEntityName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ stateEntityName: event.target.value });
  }

  changeActName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ actName: event.target.value });
  }

  changeInterestGroupName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ interestGroup: event.target.value });
  }

  changeIsIncomeQualified(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ isQualifiedIncome: event.target.checked });
  }

  changeIsRepresented(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ isRepresented: event.target.checked });
  }

  changeRepresentorsName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ representor: event.target.value });
  }

  changeIsRuralDevelopment(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ isRD: event.target.checked });
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%" }}>
        <div style={{ background: "#4c4a42", color: "#c2a877" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ show: false })}>
            <div style={{ color: "#c2a877" }}>
              Final Information For Document Draft
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col><h4>State Entity Name</h4></Col>
              <Col><input type="text" value={this.state.stateEntityName} className="standard-input" onChange={this.changeStateEntityName} /></Col>
            </Row>
            <Row style={{ marginTop: "2vh" }}>
              <Col><h4>Act Name</h4></Col>
              <Col><input type="text" value={this.state.actName} className="standard-input" onChange={this.changeActName} /></Col>
            </Row>
            <Row style={{ marginTop: "2vh" }}>
              <Col><h4>Interest Group</h4></Col>
              <Col><input type="text" value={this.state.interestGroup} className="standard-input" onChange={this.changeInterestGroupName} /></Col>
            </Row>
            <Row style={{ marginTop: "2vh" }}>
              <Col><h4>Is Income Qualified</h4></Col>
              <Col><input type="checkbox" checked={this.state.isQualifiedIncome} style={{ height: "3vh" }} className="standard-input" onChange={this.changeIsIncomeQualified} /></Col>
            </Row>
            <Row style={{ marginTop: "2vh" }}>
              <Col><h4>Is Represented</h4></Col>
              <Col><input type="checkbox" checked={this.state.isRepresented} style={{ height: "3vh" }} className="standard-input" onChange={this.changeIsRepresented} /></Col>
            </Row>
            {this.state.isRepresented &&
              <Row style={{ marginTop: "2vh" }}>
                <Col><h4>Representor</h4></Col>
                <Col><input type="text" value={this.state.representor} className="standard-input" onChange={this.changeRepresentorsName} /></Col>
              </Row>
            }
            <Row style={{ marginTop: "2vh" }}>
              <Col><h4>Is RD</h4></Col>
              <Col><input type="checkbox" checked={this.state.isRD} style={{ height: "3vh" }} className="standard-input" onChange={this.changeIsRuralDevelopment} /></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <input type="button" className="standard-input" value="Submit" onClick={this.callback} />
          </ModalFooter>
        </div>
      </Modal>
    )
  }

}