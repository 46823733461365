import axios from "axios";
import { getBearerToken } from "./authActions";
import { SelectOptions } from "../interfaces/CoreInterfaces";


/**
 * Pull The Entity Name by UID
 * @param uid
 * @returns Entity Name of the given UID
 */
export async function pullEntityName(uid: string): Promise<string> {
  let response = await axios.get('./api/entities/name/' + uid);
  if (response.status !== 200) {
    console.error("Invalid UID When requesting Entity Name in Pull Entity Name");
    return "Invalid Entity Name";
  }
  return response.data;
}

/**
 * Pull the Empire Employee FullName (First + Last)
 * @param uid 
 * @returns Full Name of the Empire Employee tied to the UID
 */
export async function pullEmployeeName(uid: string): Promise<string> {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get('/api/empire/auth/name/' + uid);
  if (response.status !== 200) {
    console.error("Invalid UID When requesting Employee Name in Pull Employee Name");
    return "Invalid Employee";
  }
  return response.data;
}

/**
 * Pull the Empire Employee Email (First + Last)
 * @param uid 
 * @returns Full Name of the Empire Employee tied to the UID
 */
export async function pullEmployeeEmail(uid: string): Promise<string> {
  let response = await axios.get('/api/empire/auth/email/' + uid);
  if (response.status !== 200) {
    console.error("Invalid UID When requesting Employee Email in Pull Employee Email");
    return "Invalid Employee";
  }
  return response.data;
}

/**
 * Pull the Empire Employee Use Old Construction
 * @param uid 
 * @returns Full Name of the Empire Employee tied to the UID
 */
export async function pullEmployeeUseOldConstruction(uid: string): Promise<boolean> {
  let response = await axios.get('/api/empire/auth/use-old-construction/' + uid);
  if (response.status !== 200) {
    console.error("Invalid UID When requesting Employee UseOldConstruction System in Pull Employee Email");
    return false;
  }
  return response.data;
}


/**
 * Pulls the GC job Name (ManagedJob Table) so that it can be used for display
 * 
 * @param uid
 * @returns
 */
export async function pullGeneralContractingJobName(uid: string): Promise<string> {
  let response = await axios.get('./api/managed-jobs/name/' + uid);
  if (response.status !== 200) {
    console.error("Invalid UID when requesting employee email in pull employee email");
    return "Invalid Job";
  }
  return response.data;
}

export async function pullPropertyName(uid: string): Promise<string> {
  let response = await axios.get('./api/property/name/' + uid);
  if (response.status !== 200) {
    console.error("Invalid UID when requesting property name in Pull Property Name function");
    return "Invalid Property";
  }
  return response.data;
}

export async function pullBuildingName(uid: string): Promise<string> {
  let response = await axios.get('./api/building/name/' + uid);
  if (response.status !== 200) {
    console.error("Invalid UID when requesting property name in Pull Property Name function");
    return "Invalid Property";
  }
  return response.data;
}

  /**
   * 
   */
export async function pullTicketCategory(uid: string): Promise<SelectOptions> {
  let response = await axios.get('./api/tickets/category/' + uid + "/minimal");
  if (response.status !== 200) {
    console.error("Invalid Ticket UID when requesting Ticket Category");
    return { label: "Invalid UID", value: "" };
  }
  return response.data;
}