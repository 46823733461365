import axios from 'axios';
import * as React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { pullEmployeeName } from '../../../functions/fetchObjectNameFunctions';
import { getUserID } from '../../../functions/authActions';

export interface WorkLogComment {
  uid: string;
  assignee: string;
  comment: string;
  estimatedTimeInMinutes: number;
}

interface WorkLogModalProps {
  callback: () => void;
}

interface WorkLogModalState {
  title: string;
  showing: boolean;
  comment: WorkLogComment;
  ticketUID: string;
}

export class WorkLogModal extends React.Component<WorkLogModalProps, WorkLogModalState> {

  constructor(props: WorkLogModalProps) {
    super(props);
    this.state = {
      title: "Edit Comment",
      showing: false,
      comment: { assignee: "", comment: "", estimatedTimeInMinutes: 0, uid: "" },
      ticketUID: ""
    }
    this.changeEstimatedTime = this.changeEstimatedTime.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.saveComment = this.saveComment.bind(this);
  }

  async show(ticketUID: string, comment: WorkLogComment): Promise<void> {
    if (comment === null) { comment = { assignee: await pullEmployeeName(getUserID()) , comment: "", estimatedTimeInMinutes: 0, uid: ""}}
    this.setState({
      showing: true,
      comment: comment,
      ticketUID: ticketUID
    })
  }

  async changeEstimatedTime(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    let comment = this.state.comment;
    comment.estimatedTimeInMinutes = parseFloat(event.target.value);
    this.setState({
      comment: comment
    })
  }

  async changeComments(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    let comment = this.state.comment;
    comment.comment = event.target.value;
    this.setState({
      comment: comment
    })
  }

  async saveComment(): Promise<void> {
    const commentData = {
      TicketUID: this.state.ticketUID,
      CommentUID: this.state.comment.uid,
      Comment: this.state.comment.comment,
      EstimatedTime: this.state.comment.estimatedTimeInMinutes,
      Assignee: this.state.comment.assignee
    }
    await axios.post("./api/ticket-comments", commentData);
    this.props.callback();
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderRadius: "15%" }} size="lg">
        <div style={{ background: "#4c4a42", color: "#c2a877" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ showing: false })}>
            <div style={{ color: "#c2a877" }}>
              {this.state.title}
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs='4'>
                <h6>Assignee</h6>
              </Col>
              <Col>
                <h6>{this.state.comment.assignee}</h6>
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h6 style={{ marginTop: "8px" }}>Work Comments</h6>
              </Col>
              <Col>
                <input type="text" className="standard-input" value={this.state.comment.comment} onChange={this.changeComments} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h6 style={{ marginTop: "10px" }}>Estimated Time (Minutes)</h6>
              </Col>
              <Col>
                <input type="number" className="standard-input" value={this.state.comment.estimatedTimeInMinutes} onChange={this.changeEstimatedTime} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {
              this.state.comment.uid === "" ?
                <input type="button" value="Create" className="standard-input" onClick={this.saveComment} /> :
                <input type="button" value="Close" className="standard-input" onClick={() => this.setState({ showing: false})} />
            }
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}

