import * as React from "react";
import { Col, Row } from "reactstrap";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";
export default class JobInformationManagementFunctions extends React.Component<{uid: string}, {}> {


  constructor(props: { uid: string}) {
    super(props);
    this.state = {}
  }

  async componentDidMount(): Promise<void> {

  }


  render(): JSX.Element {
    return (
      <>
        {RenderHeader("Manage Draws & Change Orders")}
        <Row>
          <Col>
            <input type="button" value="Draws" className="standard-input" onClick={() => window.location.assign('./active-job-management/draws?uid=' + this.props.uid)} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Change Orders" className="standard-input" onClick={() => window.location.assign('./active-job-management/change-orders?uid=' + this.props.uid)} />
          </Col>
        </Row>
      </>
    )
  }
}