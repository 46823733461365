import * as React from 'react';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { WarningModal } from '../../CoreComponents/Modals';
import { DashboardManagementModal } from '../../CoreComponents/Modals/DashboardManagementModal';
import { GetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DashboardOrganizer, DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { AssignedTicketsWidget } from '../Widgets/AssignedTicketsWidget';
import { UsefulLinksWidget } from '../../CoreComponents/UsefulLinksWidget';
import { PropertyPayrollSelectionWidget } from '../../PropertyComponents/PropertyWidgets/PropertyPayrollSelectionWidget';
import { PropertyAllocationSelectionWidget } from '../../PropertyComponents/PropertyWidgets/PropertyAllocationSelectionWidget';
import { RegionalManagerApprovalWorkflowWidget } from '../../PayrollComponents/Widgets/RegionalManagerApprovalWorkflowWidget';
import { EmployeeAllocationsWidget } from '../../PayrollComponents/Widgets/EmployeeAllocationsWidget';
import { SupervisorSelectEmployeeManagementWidget } from '../Widgets/SupervisorSelectEmployeeManagementWidget';
import { PayrollBreakdownWidget } from '../Widgets/PayrollBreakdownWidget';
import { Col, Row } from 'reactstrap';
import { EmployeeChangeRequestWidget } from '../../PayrollComponents/Widgets/EmployeeChangeRequestWidget';
import { EmptyWidget } from '../Widgets/EmptyWidget';
import { EmployeeArchiveAllocationsWidget } from '../../PayrollComponents/Widgets/EmployeeArchiveAllocationsWidget';
import { EmployeePastAllocationReportGenerator } from '../../PayrollComponents/Widgets/EmployeePastAllocationReportGenerator';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';

export interface WorkflowStatus {
  status: number;
  workflowName: string;
  uid: string;
  site: string;
}

interface HumanResourcesDashboardState {
  showingRFP: string;
  showStatus: number;

  showModal: boolean;
  renderSettings: RenderSettings;
  widgetList: Array<SelectOptions>;
  tabList: { [key: number]: JSX.Element };
}

export class HumanResourcesDashboard extends React.Component<{}, HumanResourcesDashboardState> {

  warningModal = React.createRef<WarningModal>();

  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  settingsString: string = "humanResourcesDashboard";

  constructor(props: {}) {
    super(props);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal: boolean = false;
    if (settings == null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      showingRFP: "",
      showStatus: 0,
      showModal: showModal,
      renderSettings: settings,
      tabList: [],
      widgetList: []
    }

    this.triggerWarningModal = this.triggerWarningModal.bind(this);
    this.updateUI = this.updateUI.bind(this);
    DashboardOrganizer.bind(this)
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDashboard();
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI);
    }
  }

  async prepareDashboard(): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      1: <AssignedTicketsWidget />,
      2: <UsefulLinksWidget />,
      3: <PropertyPayrollSelectionWidget useRegional={false} />,
      4: <PropertyAllocationSelectionWidget useRegional={false} />,
      5: <RegionalManagerApprovalWorkflowWidget isRegional={false} />,
      6: <EmployeeAllocationsWidget />,
      7: <SupervisorSelectEmployeeManagementWidget />,
      8: <PayrollBreakdownWidget />,
      9: <EmployeeChangeRequestWidget isRegional={false} />,
      10: <EmptyWidget />,
      11: <EmployeeArchiveAllocationsWidget />,
      12: <EmployeePastAllocationReportGenerator />
    };
    let widgetList = [
      { label: "Leave Blank", value: "10" },
      { label: "Assigned Tickets", value: "1" },
      { label: "Useful Links", value: "2" },
      { label: "Property Payroll", value: "3" },
      { label: "Property Allocations", value: "4" },
      { label: "Regional Payroll Approvals", value: "5" },
      { label: "Employee Allocation Search", value: "6" },
      { label: "Employee Past Allocations", value: "11" },
      { label: "Past Allocation Report Generator", value: "12"},
      { label: "Supervisor Assignment", value: "7" },
      { label: "Payroll Breakdown Widget", value: "8" },
      { label: "Employee Change Requests", value: "9" }
    ];
    this.setState({
      widgetList: widgetList,
      tabList: tabList
    })
  }
  
  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  triggerWarningModal(message: string, title: string): void {
    this.warningModal.current.show(message, title, () => window.location.reload());
  }

  render(): JSX.Element {
    return (
      <div>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <WarningModal ref={this.warningModal} />
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>Human Resources Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        {DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
      </div>
    )
  }
}

