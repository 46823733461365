import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { SelectGraphOptions } from "../../interfaces/CoreInterfaces";
import * as Structs from '../CoreComponents/GraphConstants';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { BarGraph, BarGraphState, PieGraph, PieGraphState, RadiusGraph, RadiusGraphState, ScatterGraph, ScatterGraphState } from '../CoreComponents/GraphComponents';

interface SVGTestProps {

}

interface SVGTestState {
  radiusGraph: RadiusGraphState;
  tempRadiusTitle: string;
  tempRadiusValue: number;
  radiusSelect: SelectGraphOptions;
  pieGraph: PieGraphState;
  tempPieTitle: string;
  tempPieValue: number;
  pieSelect: SelectGraphOptions;
  barGraph: BarGraphState;
  tempBarTitle: string;
  tempBarValue: number;
  barSelect: SelectGraphOptions;
  scatterGraph: ScatterGraphState;
  tempScatterTitle: string;
  tempScatterValue: number;
  scatterSelect: SelectGraphOptions;
}

export class SVGTest extends React.Component<SVGTestProps, SVGTestState> {

  constructor(props: SVGTestProps) {
    super(props);
    this.state = {
      //#region Radius
      //Graph
      radiusGraph: {
        width: "350px",
        height: "350px",
        values: [],
        baseStroke: "#ffffff",
        colors: [
          "#0dff00",
          "#00ddff",
          "#00ff56",
          "#00e4fe",
          "#00fe80",
          "#00eaf8",
          "#00fca3",
          "#00f0eb",
          "#00f9c0",
          "#00f5d9"
        ]
      },
      //SVG Test Only
      tempRadiusTitle: 'Title',
      tempRadiusValue: 0,
      radiusSelect: {
        label: 'Please Select Option',
        value: "0",
        data: {
          label: "Please Select Option",
          value: 0,
          checked: false,
          key: 0
        }
      },
      //#endregion
      //#region Pie
      //Graph
      pieGraph: {
        width: "350px",
        height: "350px",
        colors: [
          "#0dff00",
          "#00ddff",
          "#00ff56",
          "#00e4fe",
          "#00fe80",
          "#00eaf8",
          "#00fca3",
          "#00f0eb",
          "#00f9c0",
          "#00f5d9"
        ],
        values: []
      },
      //SVG Test Only
      tempPieTitle: 'Title',
      tempPieValue: 0,
      pieSelect: {
        label: 'Please Select Option',
        value: "0",
        data: {
          label: "Please Select Option",
          value: 0,
          checked: false,
          key: 0
        }
      },
      //#endregion
      //#region Bar
      barGraph: {
        width: "1000px",
        height: "400px",
        colors: [
          "#0dff00",
          "#00ddff",
          "#00ff56",
          "#00e4fe",
          "#00fe80",
          "#00eaf8",
          "#00fca3",
          "#00f0eb",
          "#00f9c0",
          "#00f5d9",
          "#ffffff",
          "#000000"
        ],
        values: [],
        textColor: "#FFFFFF",
        yAxisLabel: "Y-Axis",
        xAxisLabel: "X-Axis"
      },
      tempBarTitle: "",
      tempBarValue: 0,
      barSelect: {
        label: 'Please Select Option',
        value: "0",
        data: {
          label: "Please Select Option",
          value: 0,
          checked: false,
          key: 0
        }
      },
      //#endregion
      //#region Scatter
      scatterGraph: {
        width: "900px",
        height: "400px",
        xAxisLabel: "X-Label",
        yAxisLabel: "Y-Label",
        values: [],
        checkedValues: []
      },
      tempScatterTitle: 'Title',
      tempScatterValue: 0,
      scatterSelect: {
        label: 'Please Select Option',
        value: "0",
        data: {
          label: "Please Select Option",
          value: 0,
          checked: false,
          key: 0
        }
      }
      //#endregion
    }
    //#region Radius
    this.addRadiusValue = this.addRadiusValue.bind(this);
    this.changeRadiusValue = this.changeRadiusValue.bind(this);
    this.changeRadiusTitle = this.changeRadiusTitle.bind(this);
    this.changeGraphRadiusValue = this.changeGraphRadiusValue.bind(this);
    this.changeGraphRadiusTitle = this.changeGraphRadiusTitle.bind(this);
    this.deleteRadiusValue = this.deleteRadiusValue.bind(this);
    this.addRadiusOption = this.addRadiusOption.bind(this);
    //#endregion

    //#region Pie
    this.addPieValue = this.addPieValue.bind(this);
    this.changePieValue = this.changePieValue.bind(this);
    this.changePieTitle = this.changePieTitle.bind(this);
    this.changeGraphPieValue = this.changeGraphPieValue.bind(this);
    this.changeGraphPieTitle = this.changeGraphPieTitle.bind(this);
    this.deletePieValue = this.deletePieValue.bind(this);
    this.addPieOption = this.addPieOption.bind(this);
    //#endregion

    //#region Bar
    this.addBarValue = this.addBarValue.bind(this);
    this.changeBarValue = this.changeBarValue.bind(this);
    this.changeBarTitle = this.changeBarTitle.bind(this);
    this.changeGraphBarValue = this.changeGraphBarValue.bind(this);
    this.changeGraphBarTitle = this.changeGraphBarTitle.bind(this);
    this.deleteBarValue = this.deleteBarValue.bind(this);
    this.addBarOption = this.addBarOption.bind(this);
    //#endregion

    //#region Scatter
    this.addScatterValue = this.addScatterValue.bind(this);
    this.changeScatterXLabel = this.changeScatterXLabel.bind(this);
    this.changeScatterYLabel = this.changeScatterYLabel.bind(this);
    this.changeScatterValue = this.changeScatterValue.bind(this);
    this.changeScatterTitle = this.changeScatterTitle.bind(this);
    this.changeGraphScatterValue = this.changeGraphScatterValue.bind(this);
    this.changeGraphScatterTitle = this.changeGraphScatterTitle.bind(this);
    this.deleteScatterValue = this.deleteScatterValue.bind(this);
    this.updateChecked = this.updateChecked.bind(this);
    this.addScatterOption = this.addScatterOption.bind(this);
    //#endregion
  }

  //#region Radius
  addRadiusValue(): void {
    const newValues = this.state.radiusGraph;
    newValues.values.splice(
      newValues.values.length,
      0,
      {
        label: this.state.tempRadiusTitle,
        value: this.state.tempRadiusValue,
        checked: false,
        key: 0
      }
    )
    newValues.values.sort(function (a: any, b: any) { return b.value - a.value })
    this.setState({ radiusGraph: newValues })
  }
  changeRadiusValue(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempRadiusValue: parseInt(event.target.value) })
  }
  changeRadiusTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempRadiusTitle: event.target.value })
  }
  changeGraphRadiusValue(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValues = this.state.radiusGraph;
    //@ts-ignore
    newValues.values[event.target.id].value = event.target.value;
    newValues.values.sort(function (a: any, b: any) { return b.value - a.value })
    this.setState({ radiusGraph: newValues })
  }
  changeGraphRadiusTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.radiusGraph;
    //@ts-ignore
    temp.values[event.target.id].title = event.target.value;
    this.setState({ radiusGraph: temp })
  }
  deleteRadiusValue(idx: number): void {
    const newValues = this.state.radiusGraph;
    newValues.values.splice(idx, 1);
    newValues.values.sort(function (a: any, b: any) { return b.value - a.value })
    this.setState({ radiusGraph: newValues })
  }
  addRadiusOption(event: SelectGraphOptions | null): void {
    if (event === null) { return; }
    const newValues = this.state.radiusGraph;
    newValues.values.push(event.data);
    newValues.values.sort(function (a: any, b: any) { return b.value - a.value })
    this.setState({ radiusGraph: newValues })
  }
  //#endregion

  //#region Pie
  addPieValue(): void {
    const newValues = this.state.pieGraph;
    newValues.values.splice(
      newValues.values.length,
      0,
      {
        label: this.state.tempPieTitle,
        value: this.state.tempPieValue,
        checked: false,
        key: 0
      }
    )
    this.setState({ pieGraph: newValues })
  }
  changePieValue(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempPieValue: parseInt(event.target.value) })
  }
  changePieTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempPieTitle: event.target.value })
  }
  changeGraphPieValue(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.pieGraph;
    //@ts-ignore
    temp.values[event.target.id].value = event.target.value;
    this.setState({ pieGraph: temp })
  }
  changeGraphPieTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.pieGraph;
    //@ts-ignore
    temp.values[event.target.id].title = event.target.value;
    this.setState({ pieGraph: temp })
  }
  deletePieValue(idx: number): void {
    const newValues = this.state.pieGraph;
    newValues.values.splice(idx, 1);
    this.setState({ pieGraph: newValues })
  }
  addPieOption(event: SelectGraphOptions | null): void {
    if (event === null) { return; }
    const newValues = this.state.pieGraph;
    newValues.values.push(event.data);
    this.setState({ pieGraph: newValues })
  }
  //#endregion

  //#region Bar
  addBarValue(): void {
    const newValues = this.state.barGraph;
    newValues.values.splice(
      newValues.values.length,
      0,
      {
        label: this.state.tempBarTitle,
        value: this.state.tempBarValue,
        checked: false,
        key: 0
      }
    )
    this.setState({ barGraph: newValues })
  }
  changeBarValue(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempBarValue: parseInt(event.target.value) })
  }
  changeBarTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempBarTitle: event.target.value })
  }
  changeGraphBarValue(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.barGraph;
    //@ts-ignore
    temp.values[event.target.id].value = event.target.value;
    this.setState({ barGraph: temp })
  }
  changeGraphBarTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.barGraph;
    //@ts-ignore
    temp.values[event.target.id].title = event.target.value;
    this.setState({ barGraph: temp })
  }
  deleteBarValue(idx: number): void {
    const newValues = this.state.barGraph;
    newValues.values.splice(idx, 1);
    this.setState({ barGraph: newValues })
  }
  addBarOption(event: SelectGraphOptions | null): void {
    if (event === null) { return; }
    const newValues = this.state.barGraph;
    newValues.values.push(event.data);
    this.setState({ barGraph: newValues })
  }
  //#endregion

  //#region Scatter
  addScatterValue(): void {
    const newValues = this.state.scatterGraph;
    newValues.values.splice(
      newValues.values.length,
      0,
      {
        label: this.state.tempScatterTitle,
        value: this.state.tempScatterValue,
        checked: false,
        key: 0
      }
    )
    this.setState({ scatterGraph: newValues })
  }
  changeScatterXLabel(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.scatterGraph;
    temp.xAxisLabel = event.target.value;
    this.setState({ scatterGraph: temp })
  }
  changeScatterYLabel(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.scatterGraph;
    temp.yAxisLabel = event.target.value;
    this.setState({ scatterGraph: temp })
  }
  changeScatterValue(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempScatterValue: parseInt(event.target.value) })
  }
  changeScatterTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ tempScatterTitle: event.target.value })
  }
  changeGraphScatterValue(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.scatterGraph;
    //@ts-ignore
    temp.values[event.target.id].value = event.target.value;
    this.setState({ scatterGraph: temp })
  }
  changeGraphScatterTitle(event: React.ChangeEvent<HTMLInputElement>): void {
    const temp = this.state.scatterGraph;
    //@ts-ignore
    temp.values[event.target.id].title = event.target.value;
    this.setState({ scatterGraph: temp })
  }
  deleteScatterValue(idx: number): void {
    const newValues = this.state.scatterGraph;
    newValues.values.splice(idx, 1);
    newValues.values.forEach((item: any, nIdx: any) => {
      item.key = nIdx
    })
    this.setState({ scatterGraph: newValues })
  }
  updateChecked(idx: number): void {
    const newValues = this.state.scatterGraph;
    if (!newValues.values[idx].checked) {
      newValues.values[idx].key = idx;
      newValues.values[idx].checked = true;
      newValues.checkedValues.push(newValues.values[idx]);
      newValues.checkedValues.sort(function (a: any, b: any) { return a.key - b.key })
      this.setState({ scatterGraph: newValues })
    } else {
      newValues.checkedValues.forEach((item: any, tIdx: any) => {
        if (item.key === idx) {
          newValues.checkedValues.splice(tIdx, 1)
          newValues.values[idx].checked = false;
          this.setState({ scatterGraph: newValues })
        }
      })
    }
  }
  addScatterOption(event: SelectGraphOptions | null): void {
    if (event === null) { return; }
    const newValues = this.state.scatterGraph;
    newValues.values.push(event.data);
    this.setState({ scatterGraph: newValues })
  }
  //#endregion

  render(): JSX.Element {
    return (
      <>
        <Row style={{ height: "90vh", overflow: "scroll" }}>
          <Col>
            <Row>
              {/* Radius */}
              <Col>
                <Row>
                  <Col>
                    <h4>Radius Graph</h4>
                  </Col>
                  <Col>
                    <Select
                      value={this.state.radiusSelect}
                      styles={reactSelectBasicStyle}
                      options={Structs.TestRadiusOptions}
                      onChange={this.addRadiusOption}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vh" }}>
                  <Col xs="5">
                    <input type="text" className="standard-input" value={this.state.tempRadiusTitle} onChange={this.changeRadiusTitle} />
                  </Col>
                  <Col xs="5">
                    <input type="number" className="standard-input" value={this.state.tempRadiusValue} onChange={this.changeRadiusValue} />
                  </Col>
                  <Col>
                    <FontAwesomeIcon icon={faPlus} style={{ marginTop: "1vh" }} onClick={this.addRadiusValue} />
                  </Col>
                </Row>
                {this.state.radiusGraph.values.map((item: any, idx: any) =>
                  <>
                    <Row>
                      <Col xs="5">
                        <input id={idx} type="text" className="standard-input" value={item.label} onChange={this.changeGraphRadiusTitle} />
                      </Col>
                      <Col xs="5">
                        <input id={idx} type="number" className="standard-input" value={item.value} onChange={this.changeGraphRadiusValue} />
                      </Col>
                      <Col>
                        <FontAwesomeIcon icon={faTrash} style={{ marginTop: "1vh" }} onClick={() => this.deleteRadiusValue(idx)} />
                      </Col>
                    </Row>

                  </>
                )}
              </Col>
              <Col>
                <RadiusGraph radiusGraphProps={this.state.radiusGraph} />
              </Col>
              {/* Pie */}
              <Col>
                <Row>
                  <Col>
                    <h4>Pie Graph</h4>
                  </Col>
                  <Col>
                    <Select
                      value={this.state.pieSelect}
                      styles={reactSelectBasicStyle}
                      options={Structs.TestPieOptions}
                      onChange={this.addPieOption}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vh" }}>
                  <Col xs="5">
                    <input type="text" className="standard-input" value={this.state.tempPieTitle} onChange={this.changePieTitle} />
                  </Col>
                  <Col xs="5">
                    <input type="number" className="standard-input" value={this.state.tempPieValue} onChange={this.changePieValue} />
                  </Col>
                  <Col>
                    <FontAwesomeIcon icon={faPlus} style={{ marginTop: "1vh" }} onClick={this.addPieValue} />
                  </Col>
                </Row>
                {this.state.pieGraph.values.map((item: any, idx: any) =>
                  <>
                    <Row>
                      <Col xs="5">
                        <input id={idx} type="text" className="standard-input" value={item.label} onChange={this.changeGraphPieTitle} />
                      </Col>
                      <Col xs="5">
                        <input id={idx} type="number" className="standard-input" value={item.value} onChange={this.changeGraphPieValue} />
                      </Col>
                      <Col>
                        <FontAwesomeIcon icon={faTrash} style={{ marginTop: "1vh" }} onClick={() => this.deletePieValue(idx)} />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col>
                <PieGraph pieGraphProps={this.state.pieGraph} />
              </Col>
            </Row>
            {/* Bar */}
            <Row style={{ marginTop: "1vh" }}>
              <Col xs="2" style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                <Row>
                  <Col>
                    <h4>Bar Graph</h4>
                  </Col>
                  <Col>
                    <Select
                      value={this.state.barSelect}
                      styles={reactSelectBasicStyle}
                      options={Structs.TestBarOptions}
                      onChange={this.addBarOption}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vh" }}>
                  <Col xs="5">
                    <input type="text" className="standard-input" value={this.state.tempBarTitle} onChange={this.changeBarTitle} />
                  </Col>
                  <Col xs="5">
                    <input type="number" className="standard-input" value={this.state.tempBarValue} onChange={this.changeBarValue} />
                  </Col>
                  <Col>
                    <FontAwesomeIcon icon={faPlus} style={{ marginTop: "1vh" }} onClick={this.addBarValue} />
                  </Col>
                </Row>
                {this.state.barGraph.values.map((item: any, idx: any) =>
                  <>
                    <Row>
                      <Col xs="5">
                        <input id={idx} type="text" className="standard-input" value={item.label} onChange={this.changeGraphBarTitle} />
                      </Col>
                      <Col xs="5">
                        <input id={idx} type="number" className="standard-input" value={item.value} onChange={this.changeGraphBarValue} />
                      </Col>
                      <Col>
                        <FontAwesomeIcon icon={faTrash} style={{ marginTop: "1vh" }} onClick={() => this.deleteBarValue(idx)} />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col>
                <BarGraph barGraphProps={this.state.barGraph} />
              </Col>
            </Row>

            {/* Scatter */}
            <Row style={{ marginTop: "1vh" }}>
              <Col xs="3" style={{ maxHeight: "40vh", overflow: "scroll" }}>
                <Row>
                  <Col>
                    <h4>Scatter Graph</h4>
                  </Col>
                  <Col>
                    <Select
                      value={this.state.scatterSelect}
                      styles={reactSelectBasicStyle}
                      options={Structs.TestScatterOptions}
                      onChange={this.addScatterOption}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vh" }}>
                  <Col xs="5">
                    <h6>X-Label</h6>
                    <input type="text" className="standard-input" value={this.state.scatterGraph.xAxisLabel} onChange={this.changeScatterXLabel} />
                  </Col>
                  <Col xs="5">
                    <h6>Y-Label</h6>
                    <input type="text" className="standard-input" value={this.state.scatterGraph.yAxisLabel} onChange={this.changeScatterYLabel} />
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vh" }}>
                  <Col xs="5">
                    <h6>Title</h6>
                    <input type="text" className="standard-input" value={this.state.tempScatterTitle} onChange={this.changeScatterTitle} />
                  </Col>
                  <Col xs="5">
                    <h6>Value</h6>
                    <input type="number" className="standard-input" value={this.state.tempScatterValue} onChange={this.changeScatterValue} />
                  </Col>
                  <Col>
                    <h6>Add</h6>
                    <FontAwesomeIcon icon={faPlus} style={{ marginTop: "1vh" }} onClick={this.addScatterValue} />
                  </Col>
                </Row>
                {this.state.scatterGraph.values.map((item: any, idx: any) =>
                  <>
                    <Row>
                      <Col xs="5">
                        <input id={idx} type="text" className="standard-input" value={item.label} onChange={this.changeGraphScatterTitle} />
                      </Col>
                      <Col xs="4">
                        <input id={idx} type="number" className="standard-input" value={item.value} onChange={this.changeGraphScatterValue} />
                      </Col>
                      <Col style={{ marginTop: "1vh" }}>
                        <input id={idx} type="checkbox" onChange={() => this.updateChecked(idx)} />
                      </Col>
                      <Col>
                        <>
                          {!item.checked ?
                            <>
                              <FontAwesomeIcon icon={faTrash} style={{ marginTop: "1vh" }} onClick={() => this.deleteScatterValue(idx)} />
                            </> :
                            <>
                            </>
                          }
                        </>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col>
                <ScatterGraph scatterGraphProps={this.state.scatterGraph} />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}