import * as React from 'react';
import axios from 'axios';
import { pullSolarPropertiesForSelect, pullVendorsForSelect } from '../../functions/fetchLinkedObjects';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import FilteredWithSettingsTableHeader from "../CoreComponents/CoreTableHeaders";
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { FilterAndSettingField } from '../CoreComponents/interfaces';

interface ClientChoiceProps {
  show: boolean;
}

interface ClientChoiceState {
  show: boolean;
  name: string;
  submitUrl: string;
  client: SelectOptions;
  clients: Array<SelectOptions>;
}

interface SolarDocumentTableProps {

}

interface SolarDocumentTableState {
  solarDevAgreementId: string;
  masterEPCId: string;
  property: SelectOptions;
  properties: Array<SelectOptions>;
  createDisabled: boolean;
  showRateTable: boolean;
  headers: Array<FilterAndSettingField>;
}

class ClientChoice extends React.Component<ClientChoiceProps, ClientChoiceState> {

  constructor(props: ClientChoiceProps) {
    super(props);
    this.state = {
      show: props.show,
      name: '',
      submitUrl: '',
      clients: [],
      client: { label: 'Select', value: '' }
    }
    this.submit = this.submit.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.changeClient = this.changeClient.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const vendors = await pullVendorsForSelect();
    this.setState({
      clients: vendors
    })
  }

  //#region Functions

  async submit(): Promise<void> {
    const id = this.state.client.value;
    //const _id = $("#properties option:selected").val();
    //await axios.get(this.state.submitUrl + id + "/" + _id)
    //    .then(function (response) {
    //        CreateGlobalAlert("Creating Document was Successful", 2000, () => window.location.reload());
    //    });
  }

  show(name: string, submitUrl: string): void {
    this.setState({
      show: true,
      name: name,
      submitUrl: submitUrl
    })
  }

  hide(): void {
    this.setState({
      show: false
    })
  }

  changeClient(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ client: event })
  }

  //#endregion

  render(): JSX.Element {
    return (
      <div id="client-choice" style={{ display: this.state.show ? "block" : "none" }} >
        <h3 id="document-name">{this.state.name}</h3>
        <Select
          value={this.state.client}
          options={this.state.clients}
          styles={reactSelectBasicStyle}
          onChange={this.changeClient}
        />
        <br />
        <input type="button" value="Create" onClick={this.submit} />
      </div>
    )
  }
}

export class SolarDocumentTable extends React.Component<SolarDocumentTableProps, SolarDocumentTableState> {

  clientChoice = React.createRef<ClientChoice>();

  constructor(props: SolarDocumentTableProps) {
    super(props);
    this.state = {
      solarDevAgreementId: '',
      masterEPCId: '',
      property: { label: "Select Property", value: "" },
      properties: [],
      createDisabled: true,
      showRateTable: true,
      headers: this.generateHeaders()
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.showComponent = this.showComponent.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
    this.submitPropertyOnly = this.submitPropertyOnly.bind(this);
    this.setPropertyDocumentData = this.setPropertyDocumentData.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const properties = await pullSolarPropertiesForSelect();
    this.setState({
      properties: properties
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Document",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      },
      {
        columnName: "Document",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      },
      {
        columnName: "Document",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
  }

  //#region Functions

  showComponent(id: any, name: string, submitUrl: string): void {
    id.current.show(name, submitUrl);
    this.setState({
      showRateTable: false
    })
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.type === "number" || event.target.type === "select-one" ? parseInt(event.target.value) : event.target.value;
    //@ts-ignore
    this.setState({ [event.target.name]: value });
  }

  changeProperty(event: SelectOptions | null): void {
    if (event === null) { return; }
    const self = this;
    this.setState({
      property: event,
      createDisabled: false
    });
    axios.get('./api/solar-contracts/' + event.value)
      .then(function (response) {
        if (response.data === null) {
          self.setState({
            solarDevAgreementId: '',
            masterEPCId: ''
          });
          return;
        }
        self.setPropertyDocumentData(self, response.data);
      });
  }

  submitPropertyOnly(submitUrl: string): void {
    const id = this.state.property
    axios.get(submitUrl + "/" + id)
      .then(function (response) {
      });
  }

  setPropertyDocumentData(self: any, data: any): void {
    self.setState({
      solarDevAgreementId: data.SolarDevelopmentAgreementUID === null ? "" : data.SolarDevelopmentAgreementUID,
      masterEPCId: data.MasterEPCUID === null ? "" : data.MasterEPCUID
    });
  }

  downloadDocument(id: string): void {
    axios.get("./api/saved-files/" + id, {
      responseType: 'blob'
    })
      .then(function (response) {
        var FileDownload = require('js-file-download');
        FileDownload(response.data, `${id}.docx`);
      });
  }

  //#endregion

  render(): JSX.Element {
    return (
      <div id="container">
        <Select
          value={this.state.property}
          onChange={this.changeProperty}
          options={this.state.properties}
          styles={reactSelectBasicStyle}
        />
        <br />
        {this.state.showRateTable &&
          <table id="rate-table" className="fixedTable" style={{ fontSize: "14px" }}>
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              <tr id="effective-row">
                <td className="fixedTableCellHeader">Solar Development Agreement</td>
                <td className="fixedTableCell">
                  {this.state.solarDevAgreementId === '' ?
                    < input id="create-solar-development-agreement" className="createDoc" type="button" value="Create"
                      onClick={(e) => this.showComponent(this.clientChoice, 'Solar Development Agreement',
                        './document/solar-development-agreement/')} style={{ width: "100%" }} disabled={this.state.createDisabled} />
                    :
                    < input id="download-solar-development-agreement" className="downloadDoc" type="button" value="Download"
                      onClick={(e) => this.downloadDocument(this.state.solarDevAgreementId)} style={{ width: "100%" }} />
                  }

                </td>
                <td className="fixedTableCell">Master EPC</td>
                <td className="fixedTableCell">
                  {this.state.masterEPCId === '' ?
                    <input type="button" className="createDoc" value="Create"
                      onClick={(e) => this.submitPropertyOnly('./document/master-epc-agreement')}
                      style={{ width: "100%" }} disabled={this.state.createDisabled} />
                    :
                    < input id="download-mpc-agreement" className="downloadDoc" type="button" value="Download"
                      onClick={(e) => this.downloadDocument(this.state.masterEPCId)} style={{ width: "100%" }} />
                  }
                </td>
                <td className="fixedTableCell"></td>
                <td className="fixedTableCell"></td>
              </tr>
            </tbody>
          </table>
        }
        <ClientChoice show={false} ref={this.clientChoice} />
      </div>
    )
  }
}