import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { pullEmpireEmployees } from '../../functions/fetchLinkedObjects';
import axios from 'axios';
import { PhoneTrackingSummary } from './PhoneTrackingManagementWidget';
import { patchNumberValue, patchStringValue } from '../../functions/patchFunctions';

interface PhoneTrackingModalProps{
  callback: (edit: boolean) => void;
}


interface PhoneTrackingModalState {
  show: boolean;
  patch: boolean;
  uid: string;
  employee: SelectOptions;
  employees: Array<SelectOptions>;
  brand: string;
  model: string;
  phoneNumber: string;
  deviceID: string;
  imei: string;
  monthlyCost: number;
  purchaseCost: number;
  description: string;
}

export default class PhoneTrackingModal extends React.Component<PhoneTrackingModalProps, PhoneTrackingModalState>{

  constructor(props: PhoneTrackingModalProps) {
    super(props);
    this.state = {
      show: false,
      patch: false,
      uid: "",
      employee: { label: "Select Options", value: "" },
      employees: [],
      brand: "",
      model: "",
      phoneNumber: "",
      deviceID: "",
      imei: "",
      monthlyCost: 0,
      purchaseCost: 0,
      description: ""
    }
    this.save = this.save.bind(this);
    this.hide = this.hide.bind(this);
    this.closeEdit = this.closeEdit.bind(this);
    this.forceClose = this.forceClose.bind(this);
    this.changeBrand = this.changeBrand.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeModel = this.changeModel.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
    this.changeDeviceID = this.changeDeviceID.bind(this);
    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    this.changePurchaseCost = this.changePurchaseCost.bind(this);
    this.changeMonthlyCost = this.changeMonthlyCost.bind(this);
    this.changeIMEI = this.changeIMEI.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let employees = await pullEmpireEmployees();
    this.setState({
      employees: employees
    })
  }

  show(edit: PhoneTrackingSummary = null): void {
    if (edit !== null) {
      this.setState({
        show: true,
        patch: true,
        uid: edit.uid,
        employee: edit.employee,
        brand: edit.brand,
        model: edit.model,
        phoneNumber: edit.phoneNumber,
        monthlyCost: edit.monthlyCost,
        purchaseCost: edit.purchaseCost,
        deviceID: edit.deviceID,
        description: edit.description,
        imei: edit.imei
      })
    }
    else {
      this.setState({
        show: true,
        patch: false,
        uid: "",
        employee: { label: "Select Employee", value: ""},
        brand: "",
        model: "",
        phoneNumber: "",
        monthlyCost: 0,
        purchaseCost: 0,
        deviceID: "",
        description: "",
        imei: ""
      })
    }
  }

  hide(): void {
    this.setState({
      show: false,
      patch: false
    })
  }


  forceClose(): void {
    this.setState({ show: false });
  }

  async changeEmployee(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ employee: event });
    if (this.state.patch) {
      await patchStringValue(this.state.uid, event.value, './api/phone-tracking/employee');
    }
  }

  async changeBrand(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ brand: event.target.value });
    if (this.state.patch) {
      await patchStringValue(this.state.uid, event.target.value, './api/phone-tracking/brand');
    }
  }

  async changeDescription(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ description: event.target.value });
    if (this.state.patch) {
      await patchStringValue(this.state.uid, event.target.value, './api/phone-tracking/description');
    }
  }

  async changeModel(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ model: event.target.value });
    if (this.state.patch) {
      await patchStringValue(this.state.uid, event.target.value, './api/phone-tracking/model');
    }
  }

  async changePhoneNumber(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ phoneNumber: event.target.value });
    if (this.state.patch) {
      await patchStringValue(this.state.uid, event.target.value, './api/phone-tracking/phone-number');
    }
  }

  async changeDeviceID(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ deviceID: event.target.value });
    if (this.state.patch) {
      const data = { uid: this.state.uid, deviceID: event.target.value };
      let response = await axios.patch('./api/phone-tracking/device-id', data, { validateStatus: () => true });
      if (response.status !== 202) {
        // TODO - Throw Error
      }
    }
  }

  async changeIMEI(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ imei: event.target.value });
    if (this.state.patch) {
      await patchStringValue(this.state.uid, event.target.value, './api/phone-tracking/imei');
    }
  }


  async changeMonthlyCost(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ monthlyCost: parseFloat(event.target.value) });
    if (this.state.patch) {
      await patchNumberValue(this.state.uid, event.target.valueAsNumber, './api/phone-tracking/monthly-cost');
    }
  }

  async changePurchaseCost(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ purchaseCost: parseFloat(event.target.value) });
    if (this.state.patch) {
      await patchNumberValue(this.state.uid, event.target.valueAsNumber, './api/phone-tracking/purchase-cost');
    }
  }


  async save(): Promise<void> {
    const data = {
      employeeUID: this.state.employee.value,
      brand: this.state.brand,
      model: this.state.model,
      phoneNumber: this.state.phoneNumber,
      deviceID: this.state.deviceID,
      monthlyCost: this.state.monthlyCost,
      purchaseCost: this.state.purchaseCost,
      description: this.state.description,
      imei: this.state.imei
    };
    let response = await axios.post('./api/phone-tracking', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.props.callback(false);
    }
  }

  async closeEdit(): Promise<void> {
    this.setState({
      show: false,
      patch: false
    }, () => this.props.callback(true))
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            {this.state.patch ? "Update" : "Create"} Phone Tracking
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Employee</h5>
              </Col>
              <Col>
                <Select
                  styles={reactSelectBasicStyle}
                  value={this.state.employee}
                  options={this.state.employees}
                  onChange={this.changeEmployee}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Description</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.description} className="standard-input" onChange={this.changeDescription} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Brand</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.brand} className="standard-input" onChange={this.changeBrand} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Model</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.model} className="standard-input" onChange={this.changeModel} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Phone Number</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.phoneNumber} className="standard-input" onChange={this.changePhoneNumber} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Device ID</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.deviceID} className="standard-input" onChange={this.changeDeviceID} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>IMEI</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.imei} className="standard-input" onChange={this.changeIMEI} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Monthly Cost</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.monthlyCost} className="standard-input" onChange={this.changeMonthlyCost} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Purchase Cost</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.purchaseCost} className="standard-input" onChange={this.changePurchaseCost} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                {!this.state.patch
                  ? <input type="button" value="Save" className="standard-input" onClick={this.save} />
                  : <input type="button" value="Close" className="standard-input" onClick={this.closeEdit} />
                }
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}