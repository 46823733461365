import axios from 'axios';
import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

interface ComputerConfigurationCreationModalProps {
  callback: () => void;
}

interface ComputerConfigurationCreationModalState {
  show: boolean;
  name: string;
  computerBrand: string;
  motherboard: string;
  cpu: string;
  ram: string;
  ramSize: string;
  storageBrand: string;
  storageSize: string;
  gpu: string;
  operatingSystem: string;
  estimatedCost: number; 
  patch: boolean;
}

export default class ComputerConfigurationCreationModal extends React.Component<ComputerConfigurationCreationModalProps, ComputerConfigurationCreationModalState> {

  constructor(props: ComputerConfigurationCreationModalProps) {
    super(props);

    this.state = {
      show: false,
      name: "",
      computerBrand: "",
      motherboard: "",
      cpu: "",
      ram: "",
      ramSize: "",
      storageBrand: "",
      storageSize: "",
      gpu: "",
      operatingSystem: "",
      estimatedCost: 0,
      patch: false
    }

    this.forceClose = this.forceClose.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeComputerBrand = this.changeComputerBrand.bind(this);
    this.changeMotherboard = this.changeMotherboard.bind(this);
    this.changeCPU = this.changeCPU.bind(this);
    this.changeRAM = this.changeRAM.bind(this);
    this.changeRAMSize = this.changeRAMSize.bind(this);
    this.changeStorageBrand = this.changeStorageBrand.bind(this);
    this.changeStorageSize = this.changeStorageSize.bind(this);
    this.changeGPU = this.changeGPU.bind(this);
    this.changeOperatingSystem = this.changeOperatingSystem.bind(this);
    this.changeEstimatedCost = this.changeEstimatedCost.bind(this);
    this.save = this.save.bind(this);
    this.hide = this.hide.bind(this);
  }
  
  show(): void {
    this.setState({
      show: true
    })
  }

  hide(): void {
    this.setState({
      show: false
    })
  }

  forceClose(): void {
    this.setState({ show: false })
  }

  changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ name: event.target.value });
  }

  changeComputerBrand(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ computerBrand: event.target.value });
  }

  changeMotherboard(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ motherboard: event.target.value });
  }

  changeCPU(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ cpu: event.target.value });
  }

  changeRAM(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ ram: event.target.value });
  }

  changeRAMSize(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ ramSize: event.target.value });
  }

  changeStorageBrand(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ storageBrand: event.target.value });
  }

  changeStorageSize(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ storageSize: event.target.value });
  }

  changeGPU(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ gpu: event.target.value });
  }

  changeOperatingSystem(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ operatingSystem: event.target.value });
  }

  changeEstimatedCost(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ estimatedCost: parseFloat(event.target.value) });
  }

  async save(): Promise<void> {
    const data = {
      name: this.state.name,
      computerBrand: this.state.computerBrand,
      motherboard: this.state.motherboard,
      cpu: this.state.cpu,
      ram: this.state.ram,
      ramSize: this.state.ramSize,
      storageBrand: this.state.storageBrand,
      storageSize: this.state.storageSize,
      gpu: this.state.gpu,
      operatingSystem: this.state.operatingSystem,
      estimatedCost: this.state.estimatedCost
    };
    let response = await axios.post('./api/computer-configuration-tracking', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.props.callback();
    }
    else {
      // TODO - Handle Error
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            {this.state.patch ? "Update" : "Create"} Computer Configuration
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px"}}>Config Name</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.name} className="standard-input" onChange={this.changeName} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Computer Brand</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.computerBrand} className="standard-input" onChange={this.changeComputerBrand}  />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Motherboard</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.motherboard} className="standard-input" onChange={this.changeMotherboard}  />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>CPU</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.cpu} className="standard-input" onChange={this.changeCPU} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>RAM</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.ram} className="standard-input" onChange={this.changeRAM} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>RAM Size</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.ramSize} className="standard-input" onChange={this.changeRAMSize} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Storage Brand</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.storageBrand} className="standard-input" onChange={this.changeStorageBrand} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Storage Size</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.storageSize} className="standard-input" onChange={this.changeStorageSize} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>GPU</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.gpu} className="standard-input" onChange={this.changeGPU} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Operating System</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.operatingSystem} className="standard-input" onChange={this.changeOperatingSystem}  />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Estimated Cost</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.estimatedCost} className="standard-input" onChange={this.changeEstimatedCost } />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <input type="button" value="Save" className="standard-input" onClick={this.save} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}