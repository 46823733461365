import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Jobs, Teams, ValidateAccess } from '../../../functions/authActions';
import { ActiveCourseTable } from './ActiveCourseTable';
import { MCIAdminPortalScreenProps, MCIAdminPortalScreenState } from './AdminPortalScreen.constants';
import { CourseManager } from './CourseManager';
import MCIReporting from './MCIReporting';
import StudentManager from './StudentCourseManager';

export default class AdminPortalScreen extends React.Component<MCIAdminPortalScreenProps, MCIAdminPortalScreenState> {

  constructor(props: MCIAdminPortalScreenProps) {
    super(props);
    this.state = {
      currentTab: 0,
      access: false
    }
  }

  async componentDidMount(): Promise<void> {
    let access = await ValidateAccess([Jobs.President, Jobs.Administrator, Jobs.Technology], [Teams.MCI, Teams.Services]);
    this.setState({
      access: access
      })
  }

  getSwitch(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <ActiveCourseTable />
      case 1:
        return <CourseManager />
      case 2:
        return <StudentManager />
      case 3:
        return <MCIReporting />
      default:
        console.error("This isn't supposed to happen");
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col style={{ marginBottom: "10px" }}>
            <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Course Manager" onClick={() => this.setState({ currentTab: 0 })} />
            {this.state.access &&
              <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Class Manager" onClick={() => this.setState({ currentTab: 1 })} />
            }
              <input type="button" className={this.state.currentTab === 2 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Student Manager" onClick={() => this.setState({ currentTab: 2 })} />
            {this.state.access &&
              <input type="button" className={this.state.currentTab === 3 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Reporting" onClick={() => this.setState({ currentTab: 3 })} />
            }
          </Col>
        </Row>
        {
          this.getSwitch()
        }
      </>
    )
  }

}