import * as React from 'react'
import { CNAPropertyInformationWidgetProps, CNAPropertyInformationWidgetState } from './CNAPropertyInformationWidget.constans';
import axios from 'axios';
import { Col, Row } from 'reactstrap';

export default class CNAPropertyInformationWidget extends React.Component<CNAPropertyInformationWidgetProps, CNAPropertyInformationWidgetState>{

  constructor(props: CNAPropertyInformationWidgetProps) {
    super(props);
    this.state = {
      cnaUID: props.cnaUID,
      propType: 0,
      rehabYear: new Date(1977, 4, 25),
      totalUnits: 0
    }
  }

  async componentDidMount(): Promise<void> {
    const response = await axios.get('./api/capital-needs-assessment/property-information/' + this.state.cnaUID)

    if (response.status === 200) {
      this.setState({
        propType: response.data.propertyType,
        rehabYear: response.data.rehabYear,
        totalUnits: response.data.totalUnits
      })
    }
    else {
      //TODO ERROR HANDLE
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h4>Property and Unit Summary</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Type</h5>
          </Col>
          <Col>
            <h6>{this.state.propType}</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Year Built</h5>
          </Col>
          <Col>
            <h6>NEED</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Last Renovated</h5>
          </Col>
          <Col>
            <h6>{new Date(this.state.rehabYear).getFullYear().toString()}</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>0 Bedroom</h5>
          </Col>
          <Col>
            <h6>0</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>1 Bedroom</h5>
          </Col>
          <Col>
            <h6>0</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>2 Bedroom</h5>
          </Col>
          <Col>
            <h6>0</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>3 Bedroom</h5>
          </Col>
          <Col>
            <h6>0</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>4 Bedroom</h5>
          </Col>
          <Col>
            <h6>0</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Fully ADA</h5>
          </Col>
          <Col>
            <h6>0</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Total Units</h5>
          </Col>
          <Col>
            <h6>{this.state.totalUnits}</h6>
          </Col>
        </Row>
      </>
    )
  }

}