import * as React from 'react'
import { CNAData, CNAActiveTableWidgetProps, CNAActiveTableWidgetState } from './CNAActiveTableWidget.constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import { pullExternalCompaniesVendorType } from '../../../functions/fetchLinkedObjects';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { getBearerToken } from '../../../functions/authActions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import NewCNAModal from './CNAActiveTableWidget.NewCNAModal';

export default class CNAActiveTableWidget extends React.Component<CNAActiveTableWidgetProps, CNAActiveTableWidgetState> {

  statusRef = React.createRef<StatusModal>();
  warningRef = React.createRef<WarningModal>();
  newCNA = React.createRef<NewCNAModal>();

  constructor(props: CNAActiveTableWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      cnas: [],
      providers: [],
      owner: "",
      ownerUID: "",
      propertyUID: props.propertyUID
    }

  }

  async componentDidMount(): Promise<void> {
    const providers = await pullExternalCompaniesVendorType();
    await this.getCNAs();
    this.getHeaders();

    this.setState({
      providers: providers
    })
  }

  getHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Project Name',
        additionalClasses: "twenty",
        setting: true,
        filter: {}
      },
      {
        columnName: 'Property',
        additionalClasses: "twenty",
        setting: true,
        filter: {}
      },
      {
        columnName: 'Version',
        additionalClasses: "twenty",
        setting: true,
        filter: {}
      }
    ]
    this.setState({ headers: headers })
  }

  async getCNAs(): Promise<void> {
    const cnas: Array<any> = [];

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const response = await axios.get('./api/capital-needs-assessment/get-by-property/' + this.state.propertyUID);

    if (response.status == 200) {
      response.data.forEach((cna: any) => {
        cnas.push({
          cnaUID: cna.cnauid,
          projectName: cna.cnaProjectName,
          ownerName: cna.ownerName,
          providerName: cna.providerName,
          propertyName: cna.propertyName,
          version: cna.cnaVersion
        })
      })
    }
    else {
      //TODO ERROR HANDLE
    }

    this.setState({ cnas: cnas })
  }

  viewCNA(cnaUID: string): void {
    window.open("./capital-needs-assessment-viewer?uid=" + cnaUID)
  }

  showNewCnaModal(): void {
    this.newCNA.current.show(
      "New CNA",
      this.state.propertyUID,
      this.state.ownerUID,
      this.createNewCNA)
  }

  async createNewCNA(projectName: string, provider: string, propertyUID: string): Promise<void> {
    const data = {
      ProviderUID: provider,
      PropertyUID: propertyUID,
      ProjectName: projectName,
      Version: 1
    }
    await axios.post('./api/capital-needs-assessment', data);
    window.location.reload()
  }

  render(): JSX.Element {
    return (
      <div>
        <NewCNAModal ref={this.newCNA} />
        <Row>
          <Col>
            <h5>Capital Needs Assessment Table</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
              </thead>
              <tbody>
                <>{
                  this.state.cnas.map((cna: CNAData) => (
                    <tr key={cna.cnaUID}>
                      <td className="fixedTableCellFirst">{cna.projectName}</td>
                      <td className="fixedTableCell">{cna.propertyName}</td>
                      <td className="fixedTableCell">{cna.version}</td>
                      <td className="fixedTableCell">
                        <FontAwesomeIcon icon={faBinoculars} style={{ marginLeft: "30%" }} onClick={() => this.viewCNA(cna.cnaUID)}
                        />
                      </td>
                    </tr>
                  ))
                }
                </>
              </tbody>
            </table>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <input type="button" value="New CNA" className="standard-input"
              style={{ backgroundColor: "#c2a877", color: "#15405c" }}
              onClick={() => this.showNewCnaModal()}
            />
          </Col>
        </Row>
      </div>
    )
  }

}