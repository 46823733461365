import * as React from 'react'
import { CNALineItemDisplayProps, CNALineItemDisplayState } from './CNALineItemDisplay.constants';
import { Col, Row } from 'reactstrap';
import LineDetails from './CNALineItemDisplay.linedetails';
import LineItemNewModal from './CNALineItemDisplay.LineItemNewModal';
import { getLineOptions } from './CNALineItemDisplay.functions';
import YearData from './CNALineItemDisplay.YearData';


export default class CNALineItemDisplay extends React.Component<CNALineItemDisplayProps, CNALineItemDisplayState>{

  newCNALineItem: React.RefObject<LineItemNewModal> = React.createRef<LineItemNewModal>();

  constructor(props: CNALineItemDisplayProps) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    const cnaUID = params.has("uid") ? params.get("uid") : "";
    if (cnaUID === '') {
      window.location.replace("./property-summary-table");
      return;
    };

    this.state = {
      categoryType: props.categoryType,
      cnaUID: cnaUID,
      propType: props.propType,
      tab: 0,
      lineItems: []
    }

    this.newLineItem = this.newLineItem.bind(this)
  }

  async componentDidMount(): Promise<void> {

  }

  async newLineItem(): Promise<void> {
    const options = await getLineOptions(this.state.categoryType);
    this.newCNALineItem.current.show(
      "New Line Item",
      options,
      this.state.propType,
      this.state.categoryType
    )
  }

  getSwitch(): JSX.Element {
    switch (this.state.tab) {
      case 0:
        return <LineDetails key={this.state.categoryType} cnaUID={this.state.cnaUID} propType={this.props.propType} categoryType={this.state.categoryType} lineItems={this.state.lineItems} />
      case 1:
        return <YearData key={this.state.categoryType} cnaUID={this.state.cnaUID} cnaLineItemCategory={this.state.categoryType} />
      default:
        console.error("This isn't supposed to happen");
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <LineItemNewModal ref={this.newCNALineItem} cnaUID={this.state.cnaUID} />
        <Row>
          <Col xs="3"><h4>{this.state.categoryType.toUpperCase()}</h4></Col>
          <div style={{ width: "1vw" }}></div>
          <Col xs="4">
            <input type="button" className={this.state.tab === 0 ? "tab-active" : "tab"} style={{ width: "50%" }} value="Line Details" onClick={() => this.setState({ tab: 0 })} />
            <input type="button" className={this.state.tab === 1 ? "tab-active" : "tab"} style={{ width: "50%" }} value="20 Year Plan" onClick={() => this.setState({ tab: 1 })} />
          </Col>
          <div style={{ width: "19vw" }}></div>
          <Col xs="2">
            <input type="button" className="standard-input" value="New Line Item" onClick={() => this.newLineItem()} />
          </Col>
        </Row>
        {this.getSwitch()}
      </div>
    )
  }
}