import * as React from 'react';
import { Col, Row } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';

interface SiteListProps {

}

interface SiteListState {
  headers: Array<FilterAndSettingField>;
}

class SiteList extends React.Component<SiteListProps, SiteListState> {

  constructor(props: SiteListProps) {
    super(props);
    this.state = {
      headers: this.generateHeaders()
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Site",
        setting: true,
        filter: {}
      },
      {
        columnName: "Address",
        setting: true,
        filter: {}
      },
      {
        columnName: "County",
        setting: true,
        filter: {}
      },
      {
        columnName: "Year Built",
        setting: true,
        filter: {}
      },
      {
        columnName: "Units",
        setting: true,
        filter: {}
      },
      {
        columnName: "RD Rental Assistance",
        setting: true,
        filter: {}
      },
      {
        columnName: "Section 8 PDRA",
        setting: true,
        filter: {}
      },
      {
        columnName: "Pre-Acq Mgr Unit",
        setting: true,
        filter: {}
      },
      {
        columnName: "Previous LIHTC",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project Type",
        setting: true,
        filter: {}
      },
      {
        columnName: "Rent Unit Control",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "30vh" }} >
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
        </table>
      </div>
    )
  }
}

interface ProjectBenchmarksProps {

}

interface ProjectBenchmarksState {
  headers: Array<FilterAndSettingField>;
}

class ProjectBenchmarks extends React.Component<ProjectBenchmarksProps, ProjectBenchmarksState> {

  constructor(props: ProjectBenchmarksProps) {
    super(props);
    this.state = {
      headers: this.generateHeaders()
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Estimated Project Benchmarks",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 1",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 2",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 3",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 4",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 5",
        setting: true,
        filter: {}
      },
      {
        columnName: "Installment",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
        </table>
      </div>
    )
  }
}

interface PartnershipTaxCreditsAndEquityProps {

}

interface PartnershipTaxCreditsAndEquityState {
  headers: Array<FilterAndSettingField>;
}

class PartnershipTaxCreditsAndEquity extends React.Component<PartnershipTaxCreditsAndEquityProps, PartnershipTaxCreditsAndEquityState> {

  constructor(props: PartnershipTaxCreditsAndEquityProps) {
    super(props);
    this.state = {
      headers: this.generateHeaders()
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Partnership Tax Credits / Equity",
        setting: true,
        filter: {}
      },
      {
        columnName: "Acquisition",
        setting: true,
        filter: {}
      },
      {
        columnName: "Rehab",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "25vh" }}>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
        </table>
      </div>
    )
  }
}

interface DistributionOfCashFlowProps {

}

interface DistributionOfCashFlowState {
  headers: Array<FilterAndSettingField>;
}

class DistributionOfCashFlow extends React.Component<DistributionOfCashFlowProps, DistributionOfCashFlowState>{

  constructor(props: DistributionOfCashFlowProps) {
    super(props);
    this.state = {
      headers: this.generateHeaders()
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Distribution of Cash Project Cash Flow",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 1",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 2",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 3",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 4",
        setting: true,
        filter: {}
      },
      {
        columnName: "Project 5",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            <tr>
              <td className="fixedTableCellFirst">Investor Services Fee - cumulative</td>
              <td className="fixedTableCell"></td>
              <td className="fixedTableCell"></td>
              <td className="fixedTableCell"></td>
              <td className="fixedTableCell"></td>
              <td className="fixedTableCell"></td>
              <td className="fixedTableCell"></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

interface FinancialProformaProjectOverviewProps {

}

interface FinancialProformaProjectOverviewState {

}

export class FinancialProformaProjectOverview extends React.Component<FinancialProformaProjectOverviewProps, FinancialProformaProjectOverviewState> {

  constructor(props: FinancialProformaProjectOverviewProps) {
    super(props);
    this.state = {

    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <SiteList />
          </Col>
        </Row>
        <Row>
          <Col>
            <ProjectBenchmarks />
          </Col>
          <Col>
            <PartnershipTaxCreditsAndEquity />
          </Col>
        </Row>
        <Row>
          <Col>
            <DistributionOfCashFlow />
          </Col>
        </Row>
      </>
    )
  }
}