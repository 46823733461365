//#region Imports
import axios from 'axios';
import * as React from 'react';
import ReactFlow, { addEdge, Background, Connection, Controls, Edge, getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer'
import Select from 'react-select';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { OwnershipTypeMap, OwnershipTypes } from '../EntityManagementTable/constants';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';
import { YesNoModal } from '../../CoreComponents/Modals';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { pullEntitiesWithIndividuals } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { EntityEdge, EntityNode, EntitySidebar, FlowMapProps, FlowMapState, OwnerSidebar } from '../Structs';
import { processTree } from './OwnershipMapFunctions';
import { DatePicker } from '../../CoreComponents/DateComponents';
//#endregion

const edgeTypes = {
  ownershipEdge: OwnershipEdge,
  ownershipEdgeOffset: OwnershipEdge_Offset
}

export class EntityOwnershipFlowMap extends React.Component<FlowMapProps, FlowMapState> {

  constructor(props: FlowMapProps) {
    super(props);
    this.state = {
      elements: [],
      loaded: false,
      entities: []
    }
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    const entities = await pullEntitiesWithIndividuals();
    this.setState({
      entities: entities
    })
    await axios.get("./api/entity-structure/tree").then(function (response) {
      const rootOfTree = response.data;
      const elements = processTree(rootOfTree);
      self.setState({
        elements: elements,
        loaded: true
      })
    })
  }

  render(): JSX.Element {
    return (
      <>
        {this.state.loaded ?
          <ReactFlowMap initialElements={this.state.elements} entities={this.state.entities} /> :
          <div className="lds-dual-ring" style={{ marginLeft: "45%", marginTop: "15%" }}>

          </div>
        }
      </>
    )
  }
}

const ReactFlowMap = (props: any) => {
  const reactFlowWrapper: any = React.useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null);
  const [elements, setElements] = React.useState(props.initialElements);
  const [sideBar, setSideBar] = React.useState(props.initialElements[0]);
  const [isEditing, setIsEditing] = React.useState(false);
  const [rootId, setRootId] = React.useState(EMPTY_GUID);

  const onLoad = (_instance: any): void => setReactFlowInstance(_instance);

  const onNodeDragStop = (event: any, node: any): void => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper?.current.getBoundingClientRect();
    const position = (reactFlowInstance).project({
      x: event.clientX - reactFlowBounds.left - 60,
      y: event.clientY - reactFlowBounds.top - 25
    });
    const data = {
      RootId: rootId,
      EntityId: node.data.entityId,
      PositionX: position.x,
      PositionY: position.y
    }
    axios.put('./api/entities/map', data).then(function (response) {
      CreateGlobalAlert("Position Updated", 1000);
    })
    setElements((els: Array<EntityNode | EntityEdge>) => els.map((item: EntityNode | EntityEdge) => {
      if (item.id === node.data.id) {
        //@ts-ignore
        item.position = position;
      }
      return item;
    }));
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    //@ts-ignore
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode: EntityNode = {
      id: "brand-new-node",
      type: 'dualDouble',
      position,
      data: {
        label: `Choose Entity`,
        id: 'brand-new-node',
        portfolio: "",
        accountant: "Unassigned",
        entityId: EMPTY_GUID,
        notes: "No Notes",
        address: "",
        agent: "",
        firm: "",
        type: "",
        fein: "",
        sosRenewalDate: "",
        duns: "",
        samCageCode: ""
      },
      isHidden: false
    };
    setElements((els: Array<EntityNode | EntityEdge>) => els.concat(newNode));
    setIsEditing(() => true);
    setSideBar(() => newNode);
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  const onConnect = (params: Edge | Connection) => {
    const ownerId = elements.filter((item: EntityNode | EntityEdge) => item.id === params.source)[0].data.entityId;
    const targetId = elements.filter((item: EntityNode | EntityEdge) => item.id === params.target)[0].data.entityId;
    //@ts-ignore
    params.type = 'ownershipEdge';
    //@ts-ignore
    params.data = {
      uid: EMPTY_GUID,
      ownerId: ownerId,
      entityId: targetId,
      ownershipType: 0,
      ownershipPercentage: 0,
      isManagingMember: false,
      purchaseDate: new Date(),
      dateOfSale: new Date(1, 0, 1),
      parentName: params.source,
      childName: params.target,
      creating: true
    }
    setSideBar(() => params);
    setIsEditing(() => true);
    setElements((els: Array<EntityNode | EntityEdge>) => addEdge(params, els));
  }

  const onElementClick = (event: React.MouseEvent<Element, MouseEvent>, _element: Edge<any> | any) => {
    setIsEditing(() => false);
    setSideBar(() => _element);
  }

  const changeRoot = (event: SelectOptions | null) => {
    if (event === null) return;
    setRootId(() => event.value);
    axios.get("./api/entity-structure/tree/" + event.value).then(function (response) {
      setElements(() => processTree(response.data))
    });
  }

  return (
    <Row >

    </Row>
  )
}

function OwnershipEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}: any) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={150}
        height={100}
        x={edgeCenterX - 75}
        y={edgeCenterY - 50}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div style={{ border: "2px solid #1861ac", backgroundColor: '#c2a877', fontSize: "10px", color: 'black' }}>
          <div style={{ fontSize: "10px" }}>{data.parentName}</div>
          <div style={{ fontSize: "10px" }}>{data.childName}</div>
          <div style={{ fontSize: "10px" }}>Percentage: {data.ownershipPercentage}%</div>
          <div style={{ fontSize: "10px" }}>
            {OwnershipTypeMap[data.ownershipType] === "" ? "N/A" : OwnershipTypeMap[data.ownershipType]}
          </div>
          {
            data.isManagingMember &&
            <div style={{ fontSize: "10px" }}>
              Managing Member
            </div>
          }
        </div>
      </foreignObject>
    </>
  );
}

function OwnershipEdge_Offset({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}: any) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={150}
        height={100}
        x={edgeCenterX - 75}
        y={edgeCenterY + 25}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div style={{ border: "2px solid #1861ac", backgroundColor: '#c2a877', fontSize: "10px", color: 'black' }}>
          <div style={{ fontSize: "10px" }}>{data.parentName}</div>
          <div style={{ fontSize: "10px" }}>{data.childName}</div>
          <div style={{ fontSize: "10px" }}>Percentage: {data.ownershipPercentage}%</div>
          <div style={{ fontSize: "10px" }}>
            {OwnershipTypeMap[data.ownershipType] === "" ? "N/A" : OwnershipTypeMap[data.ownershipType]}
          </div>
          {
            data.isManagingMember &&
            <div style={{ fontSize: "10px" }}>
              Managing Member
            </div>
          }
        </div>
      </foreignObject>
    </>
  );
}

const EntitySideBar = (props: EntitySidebar) => {

  const modalRef = React.createRef<YesNoModal>();

  const [id, setId] = React.useState(props.element.id);
  const [entityId, setEntityId] = React.useState(props.element.data.entityId);
  const [label, setLabel] = React.useState(props.element.data.label);

  const checkRedirect = () => {
    modalRef.current?.show(
      "Open in New Tab or Current Tab",
      "Open New Tab?",
      () => window.location.assign('./entity-information-page?uid=' + props.element.data.entityId),
      () => window.open('./entity-information-page?uid=' + props.element.data.entityId),
      "New Tab",
      "Current Tab"
    )
  }

  const onChangeEntity = (event: SelectOptions | null) => {
    if (event === null) return;
    setId(() => event.label);
    setEntityId(() => event.value);
    setLabel(() => event.label);
    props.element.data.label = event.label;
    props.element.data.entityId = event.value;
    props.element.id = event.label;
  }

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.effectAllowed = 'move';
  }

  return (
    <div>
      <YesNoModal ref={modalRef} />
      <ListGroup style={{ height: "85vh", paddingBottom: "10px" }} className="side-menu-main-container-sizeless">
        <ListGroupItem className="side-menu-list">
          <div>
            {id === 'brand-new-node'
              ? <Select
                styles={reactSelectBasicStyle}
                options={props.entities}
                onChange={onChangeEntity}
              />
              : <b onClick={checkRedirect} style={{ cursor: 'pointer' }}>{id}</b>
            }
          </div>
        </ListGroupItem>
        {id !== 'brand-new-node' &&
          <>
            <ListGroupItem className="side-menu-list">
              <div>Tax ID (EIN)</div>
              <div>{props.element.data.fein}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>DUNS</div>
              <div>{props.element.data.duns}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>SAM Cage Code</div>
              <div>{props.element.data.samCageCode}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>SOS Renewal Date</div>
              <div>{props.element.data.sosRenewalDate}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>Entity Type</div>
              <div>{props.element.data.type}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>Portfolio</div>
              <div>{props.element.data.portfolio}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>Accountant</div>
              <div>{props.element.data.accountant}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>Accounting Firm</div>
              <div>{props.element.data.firm}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>Notes</div>
              <div>{props.element.data.notes}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>Address</div>
              <div>{props.element.data.address}</div>
            </ListGroupItem>
            <ListGroupItem className="side-menu-list">
              <div>Registered Agent</div>
              <div>{props.element.data.agent}</div>
            </ListGroupItem>
            <div className="standard-input"
              style={{
                border: "2px solid black", textAlign: 'center',
                backgroundColor: "#c2a877", paddingTop: "4px",
                height: "35px"
              }}
              onDragStart={(event) => onDragStart(event)}
              draggable>
              Add New Node
            </div>
          </>
        }

      </ListGroup>
    </div>
  )
}

const OwnerSideBar = (props: OwnerSidebar) => {
  const modalRef = React.createRef<YesNoModal>();

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.effectAllowed = 'move';
  }
  const [isManagingMember, setManagingMember] = React.useState(props.element.data.isManagingMember);
  const [purchaseDate, setPurchaseDate] = React.useState(props.element.data.purchaseDate);
  const [dateOfSale, setDateOfSale] = React.useState(props.element.data.dateOfSale);
  const [ownershipType, setOwnershipType] = React.useState(props.element.data.ownershipType);
  const [ownershipPercentage, setOwnershipPercentage] = React.useState(props.element.data.ownershipPercentage);

  const save = () => {
    const data = {
      UID: props.element.data.uid,
      OwnerEntityUID: props.element.data.ownerId,
      EntityId: props.element.data.entityId,
      PercentageOwned: ownershipPercentage,
      IsManagingMember: isManagingMember,
      OwnershipType: ownershipType,
      StartOwnership: purchaseDate,
      EndOwnership: dateOfSale
    };
    if (props.element.data.creating) {
      axios.post('./api/entity-structure/', data).then(function (response) {
        CreateGlobalAlert("Ownership has been created", 2000);
      })
    }
    else {
      axios.put('./api/entity-structure/', data).then(function (response) {
        CreateGlobalAlert("Ownership has been updated", 2000);
      })
    }
    props.element.data.ownershipPercentage = ownershipPercentage;
    props.element.data.ownershipType = ownershipType;
    props.element.data.isManagingMember = isManagingMember;
    props.element.data.purchaseDate = purchaseDate;
    props.element.data.dateOfSale = dateOfSale;
    props.Edit(() => false);
  }

  const updateOwnershipPercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    //@ts-ignore
    setOwnershipPercentage(() => val);
  }

  const checkRedirect = (id: string) => {
    modalRef.current?.show(
      "Open in New Tab or Current Tab",
      "Open New Tab?",
      () => window.location.assign('./entity-information-page?uid=' + id),
      () => window.open('./entity-information-page?uid=' + id),
      "New Tab",
      "Current Tab"
    )
  }

  return (
    <div >
      <YesNoModal ref={modalRef} />
      <ListGroup style={{ height: "85vh" }} className="side-menu-main-container-sizeless">
        <ListGroupItem className="side-menu-list">
          <div>Owner</div>
          <div><b style={{ cursor: 'pointer' }} onClick={() => checkRedirect(props.element.data.ownerId)}>{props.element.source}</b></div>
        </ListGroupItem>
        <ListGroupItem className="side-menu-list">
          <div>Entity</div>
          <div><b style={{ cursor: 'pointer' }} onClick={() => checkRedirect(props.element.data.entityId)}>{props.element.target}</b></div>
        </ListGroupItem>
        <ListGroupItem className="side-menu-list">
          <div>Percent Ownership</div>
          {
            props.isEditing
              ? <input type="number" name="ownershipPercentage" value={ownershipPercentage} className='standard-input'
                onChange={updateOwnershipPercentage}
              />
              : <div><b>{ownershipPercentage}%</b></div>

          }
        </ListGroupItem>
        <ListGroupItem className="side-menu-list">
          <div>Ownership Type</div>
          {
            props.isEditing
              ? <Select
                //@ts-ignore
                options={OwnershipTypes}
                styles={reactSelectBasicStyle}
                value={{
                  label: OwnershipTypeMap[ownershipType] === "" ? "N/A" : OwnershipTypeMap[ownershipType],
                  value: ownershipType
                }}
                //@ts-ignore
                onChange={(e) => setOwnershipType(() => e.value)}
              />
              : <div><b>{OwnershipTypeMap[ownershipType] === "" ? "N/A" : OwnershipTypeMap[ownershipType]}</b></div>
          }
        </ListGroupItem>
        <ListGroupItem className="side-menu-list">
          <div>Managing Member?</div>
          {
            props.isEditing
              ? <Select
                styles={reactSelectBasicStyle}
                options={[{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]}
                value={{
                  label: isManagingMember ? 'Yes' : 'No',
                  value: isManagingMember ? '1' : '0'
                }}
                //@ts-ignore
                onChange={(e) => setManagingMember(() => e.value === '1')}
              />
              : <div><b>{props.element.data.isManagingMember ? 'Yes' : 'No'}</b></div>
          }

        </ListGroupItem>
        <ListGroupItem className="side-menu-list">
          <div>Purchase Date</div>
          {
            props.isEditing
              ? <DatePicker className="standard-input" onChange={() => setPurchaseDate} value={purchaseDate} />
              : <div><b>{props.element.data.purchaseDate.toLocaleDateString()}</b></div>
          }
        </ListGroupItem>
        {
          props.isEditing &&
          <>
            <ListGroupItem className="side-menu-list">
              <div>Date Of Sale</div>
              <DatePicker className="standard-input" onChange={() => setDateOfSale} value={dateOfSale} />
            </ListGroupItem>
          </>
        }
        <ListGroupItem className="side-menu-list">
          {
            props.isEditing
              ? <input type="button" className="standard-input" value="Save" onClick={save} />
              : <input type="button" className="standard-input" value="Edit" onClick={() => props.Edit(true)} />
          }
        </ListGroupItem>
        <div className="standard-input"
          style={{
            border: "2px solid black", textAlign: 'center',
            backgroundColor: "#c2a877", paddingTop: "4px",
            marginTop: "30vh"
          }}
          onDragStart={(event) => onDragStart(event)}
          draggable>
          Add New Node
        </div>
      </ListGroup>

    </div>
  )
}

 //<Col>
 //               <div ref={reactFlowWrapper} style={{ height: "85vh" }}>
 //                   <ReactFlow
 //                       elements={elements}
 //                       edgeTypes={edgeTypes}
 //                       nodeTypes={{
 //                           dualDouble: DualDoubleNodeComponent,
 //                           single: SingleNodeComponent,
 //                           targetDouble: TargetDoubleNodeComponent,
 //                           sourceDouble: SourceDoubleNodeComponent
 //                       }}
 //                       onLoad={onLoad}
 //                       onDrop={onDrop}
 //                       onDragOver={onDragOver}
 //                       onNodeDragStop={onNodeDragStop}
 //                       onConnect={onConnect}
 //                       onElementClick={onElementClick}
 //                       style={{
 //                           border: "2px solid #d7c7a7",
 //                       }}
 //                       className="borderless"
 //                   >
 //                       <ReactToPrint
 //                           trigger={() =>
 //                               <button type="button" className='standard-input no-print'
 //                                   style={{ width: "35%", zIndex: 99, position: 'relative', float: 'right' }}>Print Map
 //                               </button>
 //                           }
 //                           content={() => reactFlowWrapper.current}
 //                       />
 //                       <div className='no-print' style={{ width: "35%", zIndex: 99, position: 'relative', float: 'left' }}>
 //                           <Select
 //                               styles={reactSelectBasicStyle}
 //                               options={props.entities}
 //                               onChange={changeRoot}
 //                           />
 //                       </div>
 //                       <Background className="no-print" />
 //                       <Controls
 //                           className="no-print"
 //                       />
 //                   </ReactFlow>
 //               </div>
 //           </Col>
 //           <Col xs='3'>
 //               {sideBar.source === undefined ?
 //                   <EntitySideBar key={sideBar.id} element={sideBar} entities={props.entities} />
 //                   :
 //                   <OwnerSideBar key={sideBar.id} element={sideBar} isEditing={isEditing} Edit={setIsEditing} />
 //               }
 //           </Col>