import { SelectOptions } from "../../../interfaces/CoreInterfaces";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { UnitManagementModalData } from "./PropertyUnitWidget.modals";

export interface ProperyUnitWidgetProps {
  uid: string;
}

export interface ProperyUnitWidgetState {
  uid: string;
  units: Array<UnitManagementModalData>;
  unitHeaders: Array<FilterAndSettingField>;
}

export const UnitStatusList: Array<SelectOptions> = [
  { label: "BAD DATA", value: "-1" },
  { label: "Occupied No Notice", value: "0" },
  { label: "Notice Unrented", value: "1" },
  { label: "Notice Rented", value: "2" },
  { label: "Vacant Rented Not Ready", value: "3" },
  { label: "Vacant Rented Not Not Ready", value: "4" },
  { label: "Vacant Unrented Ready", value: "5" },
  { label: "Vacant Unrented Not Ready", value: "6" },
  { label: "Waitlist", value: "7" },
  { label: "Down", value: "8" },
  { label: "Admin", value: "9" },
  { label: "Model", value: "10" },
  { label: "Excluded", value: "11" },
];