import { faCog, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import * as React from "react";
import { EMPTY_GUID } from "../../constants/DefaultConstants";
import { CreateGlobalAlert } from "../../functions/CreateGlobalAlerts";
import FilteredWithSettingsTableHeader from "../CoreComponents/CoreTableHeaders";
import { TableAdditionRow } from "../CoreComponents/CoreTableRows";
import { DatePicker } from "../CoreComponents/DateComponents";
import { FilterAndSettingField } from "../CoreComponents/interfaces";
import { EmployeeLicenseManagementTable } from "./EmployeeLicenseManagementTable";
import { StatusModal, WarningModal } from "../CoreComponents/Modals";
import { getBearerToken } from "../../functions/authActions";

interface UserLicenseRow {
  uid: string;
  softwareUid: string;
  description: string;
  licenseCost: number;
  totalLicenseCount: number;
  usedLicenseCount: number;
  // might want to do this as a select options
  isAnnual: boolean;
  contractDate: Date;
  nextContractDate: Date;
  notes: string;
}

interface EditableUserLicenseProps {
  data: UserLicenseRow;
  index: number;
  save: (row: UserLicenseRow, index: number) => void;
}

interface EditableUserLicenseState {
  data: UserLicenseRow;
}

interface UserLicenseRowProps {
  row: UserLicenseRow;
  index: number;
  edit: (uid: string) => void;
  update: (uid: string) => void;
  delete: (uid: string) => void;
}

interface UserLicenseRowState {
  row: UserLicenseRow;
  color: string;
}

interface UserLicenseManagementProps {
  softwareUID: string;
}

interface UserLicenseManagementState {
  headers: Array<FilterAndSettingField>;
  rows: Array<UserLicenseRow>;
  currentUID: string;
  softwareUID: string;
  currentIndex: number;
}

class UserLicenseDisplayRow extends React.Component<UserLicenseRowProps, UserLicenseRowState> {

  constructor(props: UserLicenseRowProps) {
    super(props);
    this.state = {
      row: props.row,
      color: '#d7c7a7'
    }
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst" onClick={() => this.props.update(this.state.row.uid)}
          style={{ color: this.state.color, cursor: "pointer" }}
          onMouseEnter={() => this.setState({ color: '#b89961' })} onMouseLeave={() => this.setState({ color: '#d7c7a7' })}>
          {this.state.row.description}
        </td>
        <td className="fixedTableCell">{this.state.row.licenseCost}</td>
        <td className="fixedTableCell">{this.state.row.totalLicenseCount}</td>
        <td className="fixedTableCell">{this.state.row.usedLicenseCount}</td>
        <td className="fixedTableCell">{new Date(this.state.row.contractDate).toLocaleDateString()}</td>
        <td className="fixedTableCell">{new Date(this.state.row.nextContractDate).toLocaleDateString()}</td>
        <td className="fixedTableCell">{this.state.row.isAnnual ? "Annual" : "Monthly"}</td>
        <td className="fixedTableCell">{this.state.row.notes}</td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faCog} onClick={() => this.props.edit(this.props.row.uid)} style={{ marginLeft: "5%" }} />
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faTrash} onClick={() => this.props.delete(this.props.row.uid)} style={{ marginLeft: "5%" }} />
        </td>
      </tr>
    )
  }
}

class UserLicenseEditableRow extends React.Component<EditableUserLicenseProps, EditableUserLicenseState> {

  constructor(props: EditableUserLicenseProps) {
    super(props);
    this.state = {
      data: props.data
    }

    this.changeDescription = this.changeDescription.bind(this);
    this.changeLicenseCost = this.changeLicenseCost.bind(this);
    this.changeTotalLicenseCount = this.changeTotalLicenseCount.bind(this);
    this.changeIsAnnual = this.changeIsAnnual.bind(this);
    this.changeContractDate = this.changeContractDate.bind(this);
    this.changeNextContractDate = this.changeNextContractDate.bind(this);
    this.changeNotes = this.changeNotes.bind(this);

    this.updateDescription = this.updateDescription.bind(this);
    this.updateLicenseCost = this.updateLicenseCost.bind(this);
    this.updateContractDate = this.updateContractDate.bind(this);
    this.updateNextContractDate = this.updateNextContractDate.bind(this);
    this.updateTotalLicenseCount = this.updateTotalLicenseCount.bind(this);
    this.updateIsAnnual = this.updateIsAnnual.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
  }

  //#region Description Functions
  changeDescription(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.data;
    row.description = event.target.value;
    this.setState({
      data: row
    })
  }

  async updateDescription(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      description: this.state.data.description
    };
    await axios.patch("./api/user-license/description", data);
  }
  //#endregion

  //#region License Cost
  changeLicenseCost(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.data;
    row.licenseCost = parseFloat(event.target.value);
    this.setState({
      data: row
    })
  }

  async updateLicenseCost(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      cost: this.state.data.licenseCost
    };
    await axios.patch("./api/user-license/license-cost", data);
  }
  //#endregion

  //#region Total License Count
  changeTotalLicenseCount(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.data;
    row.totalLicenseCount = parseInt(event.target.value);
    this.setState({
      data: row
    })
  }

  async updateTotalLicenseCount(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      count: this.state.data.totalLicenseCount
    };
    await axios.patch("./api/user-license/license-count", data);
  }
  //#endregion

  //#region Contract Date
  changeContractDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.state.data.contractDate = new Date(event.target.valueAsDate);
    this.setState({});
  }

  async updateContractDate(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      contractDate: this.state.data.contractDate.toISOString()
    };
    await axios.patch("./api/user-license/contract", data);
  }
  //#endregion

  //#region Next Contract Date
  changeNextContractDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.state.data.nextContractDate = new Date(event.target.valueAsDate);
    this.setState({});
  }

  async updateNextContractDate(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      nextContractDate: this.state.data.nextContractDate.toISOString()
    };
    await axios.patch("./api/user-license/next-contract", data);
  }
  //#endregion

  //#region Notes
  changeNotes(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.notes = event.target.value;
    this.setState({
      data: data
    });
  }

  async updateNotes(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      notes: this.state.data.notes
    };
    await axios.patch("./api/user-license/notes", data);
  }
  //#endregion

  //#region Is Annual
  changeIsAnnual(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.data;
    row.isAnnual = event.target.checked;
    this.setState({
      data: row
    }, () => this.updateIsAnnual())
  }

  async updateIsAnnual(): Promise<void> {
    const data = {
      uid: this.state.data.uid,
      isAnnual: this.state.data.isAnnual
    };
    await axios.patch("./api/user-license/is-annual", data);
  }
  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <input type="text" className="standard-input" value={this.state.data.description} placeholder="Software Name" onChange={this.changeDescription} onBlur={this.updateDescription} />
        </td>
        <td className="fixedTableCell">
          <input type="number" className="standard-input" value={this.state.data.licenseCost} onChange={this.changeLicenseCost} onBlur={this.updateLicenseCost} />
        </td>
        <td className="fixedTableCell">
          <input type="number" className="standard-input" value={this.state.data.totalLicenseCount} onChange={this.changeTotalLicenseCount} onBlur={this.updateTotalLicenseCount} />
        </td>
        <td className="fixedTableCell">
          {this.state.data.usedLicenseCount}
        </td>
        <td className="fixedTableCell">
          <DatePicker className="standard-input" onChange={this.changeContractDate} value={this.state.data.contractDate} onBlur={this.updateContractDate} />
        </td>
        <td className="fixedTableCell">
          <DatePicker className="standard-input" onChange={this.changeNextContractDate} value={this.state.data.nextContractDate} onBlur={this.updateNextContractDate} />
        </td>
        <td className="fixedTableCell">
          <input type="checkbox" checked={this.state.data.isAnnual} style={{ marginLeft: "35%" }} onChange={this.changeIsAnnual} />
        </td>
        <td className="fixedTableCell">
          <input type="text" className="standard-input" value={this.state.data.notes} onChange={this.changeNotes} onBlur={this.updateNotes} />
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faSave} onClick={() => this.props.save(this.state.data, this.props.index)} style={{ marginLeft: "25%" }} />
        </td>
      </tr>
    )
  }
}

export class UserLicenseManagement extends React.Component<UserLicenseManagementProps, UserLicenseManagementState> {

  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: UserLicenseManagementProps) {
    super(props);
    this.state = {
      headers: [],
      rows: [],
      softwareUID: props.softwareUID,
      currentUID: "",
      currentIndex: -1
    }
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.generateETL = this.generateETL.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('./api/user-license/softwareUID/' + this.state.softwareUID).then((response) => {
      this.setState({
        rows: response.data,
      })
    })
  }

  generateHeaders(): void {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Description",
        setting: true,
        filter: {}
      },
      {
        columnName: "License Cost",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total Licenses",
        setting: true,
        filter: {}
      },
      {
        columnName: "Used Licenses",
        setting: true,
        filter: {}
      },
      {
        columnName: "Contract Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "Renewal Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "Billing",
        setting: true,
        filter: {}
      },
      {
        columnName: "Notes",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        additionalClasses: "icon",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        additionalClasses: "icon",
        setting: true,
        filter: {}
      }
    ]
    this.setState({
      headers: headers
    })
  }

  add(): void {
    let rows: Array<UserLicenseRow> = this.state.rows;
    rows.push({
      uid: "",
      softwareUid: this.state.softwareUID,
      description: "",
      licenseCost: 0.00,
      totalLicenseCount: 0,
      usedLicenseCount: 0,
      isAnnual: true,
      notes: "",
      contractDate: new Date(),
      nextContractDate: new Date()
    })
    this.setState({
      rows: rows,
      currentIndex: rows.length - 1
    })
  }

  async save(data: UserLicenseRow, index: number): Promise<void> {
    if (data.uid !== "") {
      this.setState({
        currentIndex: -1,
      })
      this.warningModal.current.show("User License has been updated.", "User License Updated");
      return;
    }
    data.uid = EMPTY_GUID;
    data.softwareUid = this.state.softwareUID;
    await axios.post('./api/user-license', data).then((response) => {
      this.warningModal.current.show("User License has been created.", "User License Created");
      let rows = this.state.rows;
      rows[index] = response.data;
      this.setState({
        currentIndex: -1,
        rows: rows
      });
    })
  }

  edit(uid: string): void {
    var index = this.state.rows.findIndex(u => u.uid === uid);
    this.setState({
      currentIndex: index
    })
  }

  async delete(uid: string): Promise<void> {
    let item = this.state.rows.filter(u => u.uid === uid)[0];
    if (item.usedLicenseCount !== 0) {
      this.warningModal.current.show("Unable to delete license as there are active licenses", "Error.");
      return;
    }
    let response = await axios.post('./api/user-license/delete/' + uid, {}, { validateStatus: () => true });
    if (response.status === 202) {
      this.warningModal.current.show(`${item.description} has been deleted. This action cannot be undone, except by recreating the license`, "License Deleted", () => window.location.reload());
    }
    else {

    }
  }

  update(uid: string): void {
    this.setState({
      currentUID: uid
    })
  }

  async generateETL(): Promise<void> {
    let formData = new FormData();
    let key = await axios.get('./api/status-manager');
    this.statusModal.current.display("Generating ETL Export", "Please wait. This can take a minute due to the amount of users we have in the system", key.data)
    let response = await axios.post('./api/user-license/software-export/' + this.state.softwareUID + "/" + key.data, formData, { validateStatus: () => true, responseType: "blob" });
    this.statusModal.current.hide();
    if (response.status === 200) {
      const FileDownload = require('js-file-download');
      const content = response.headers['content-disposition'];
      const startIndex = content.lastIndexOf('\'\'') + 2;
      const name = content.substring(startIndex, content.length);
      FileDownload(response.data, decodeURI(name));
    }
    else {
      // need to handle error
    }
  }

  render(): JSX.Element {
    return (
      <div>
        {this.state.currentUID === "" ?
          <div>
            <WarningModal ref={this.warningModal} />
            <StatusModal ref={this.statusModal} />
            <h4>User Licenses</h4>
            <div style={{ height: "72vh", overflowY: "auto", overflowX: "hidden"}}>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.rows.map((item, index) => (
                    (this.state.currentIndex === -1 || index < this.state.currentIndex) &&
                    <UserLicenseDisplayRow key={item.uid} row={item} index={index} edit={this.edit} update={this.update} delete={this.delete} />
                  ))
                }
                {this.state.currentIndex !== -1 ?
                  <UserLicenseEditableRow data={this.state.rows[this.state.currentIndex]} index={this.state.currentIndex} save={this.save} /> :
                  <TableAdditionRow length={10} marginLeft="5%" onClick={this.add} />
                }
                {
                  this.state.rows.map((item, index) => (
                    (this.state.currentIndex !== -1 && index > this.state.currentIndex) &&
                    <UserLicenseDisplayRow key={item.uid} row={item} index={index} edit={this.edit} update={this.update} delete={this.delete} />
                  ))
                }
              </tbody>
              </table>
            </div>
            <input type="button" value="Generate ETL for All Softwares" className="standard-input" onClick={this.generateETL} />
          </div>
          :
          <>
            <EmployeeLicenseManagementTable update={this.update} licenseUID={this.state.currentUID} />
          </>
        }

      </div>
    )
  }
}
