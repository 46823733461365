import * as React from 'react';
import * as Structs from './Structs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faArchive } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { pullProperties } from '../../functions/fetchLinkedObjects';
import { reactSelectBasicStyle } from '../../style/select-constants';
import Select from 'react-select';
import { TableAdditionRow } from '../CoreComponents/CoreTableRows';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';

const BuildingRow = (props: Structs.BuildingRowProps) => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.name}</td>
      <td className="fixedTableCell">{props.roofWidth}</td>
      <td className="fixedTableCell">{props.roofLength}</td>
      <td className="fixedTableCell">{props.roofReplaced}</td>
      <td className="fixedTableCell">{props.lastRehab}</td>
      <td className="fixedTableCell">{props.unitCount}</td>
      <td className="fixedTableCell">
        <FontAwesomeIcon icon={faCog} style={{ marginLeft: "25%" }} onClick={() => window.open("/unit-manager/" + props.id)} />
      </td>
      <td className="fixedTableCell">
        <FontAwesomeIcon icon={faArchive} onClick={() => props.archive(props.id)} style={{ marginLeft: "25%", color: '#b89961', cursor: 'pointer' }} />
      </td>
    </tr>
  )
}

export class BuildingManager extends React.Component<Structs.BuildingManagerProps, Structs.BuildingManagerState> {

  constructor(props: Structs.BuildingManagerProps) {
    super(props);
    this.state = {
      property: { label: "Select Property", value: "" },
      properties: [],
      buildings: []
    }
    //this.updateProperty = this.updateProperty.bind(this);
    //this.updateBuildings = this.updateBuildings.bind(this);
    //this.createNewBuilding = this.createNewBuilding.bind(this);
    //this.archive = this.archive.bind(this);
  }

  //async componentDidMount(): Promise<void> {
  //  const properties = await pullProperties();
  //  this.setState({
  //    properties: properties
  //  })
  //}

  //updateProperty(event: SelectOptions | null): void {
  //  if (event === null) { return; }
  //  this.setState({
  //    property: event
  //  }, this.updateBuildings)
  //}

  //updateBuildings(): void {
  //  const self = this;
  //  if (this.state.property.value === '') {
  //    const buildings: Array<any> = [];
  //    this.setState({
  //      buildings: buildings
  //    })
  //    return;
  //  }
  //  axios.get('./api/buildings/property/' + this.state.property.value)
  //    .then(function (response) {
  //      const buildings: Array<any> = [];
  //      response.data.forEach(function (item: any) {
  //        buildings.push({
  //          name: item.name,
  //          key: item.uid,
  //          roofReplaced: item.roofReplacedShortDate,
  //          roofWidth: item.roofWidth,
  //          roofLength: item.roofLength,
  //          lastRehab: item.lastRehabShortDate,
  //          unitCount: item.unitCount
  //        })
  //      })
  //      self.setState({
  //        buildings: buildings
  //      });
  //    });
  //}

  //createNewBuilding(): void {
  //  if (this.state.property.value === '') {
  //    CreateGlobalAlert("Please select a property before attempting to create a building.", 1200);
  //    return;
  //  }
  //  const self = this;
  //  axios.post('./api/buildings/property/' + this.state.property.value + "/create-new")
  //    .then(function (response) {
  //      const buildings = self.state.buildings;
  //      buildings.push({
  //        name: response.data.name,
  //        key: response.data.uid,
  //        roofReplaced: response.data.roofReplacedShortDate,
  //        roofWidth: response.data.roofWidth,
  //        roofLength: response.data.roofLength,
  //        lastRehab: response.data.lastRehabShortDate,
  //        unitCount: response.data.unitCount
  //      })
  //      self.setState({
  //        buildings: buildings
  //      })
  //    });
  //}

  //archive(id: string): void {
  //  axios.post('/api/buildings/' + id + '/archive')
  //    .then(function (response) {
  //      CreateGlobalAlert("Building has been archived..", 1200);
  //    });
  //  let buildings = this.state.buildings;
  //  buildings = buildings.filter(item => item.key !== id);
  //  this.setState({
  //    buildings: buildings
  //  });
  //}

  render(): JSX.Element {
    return (
      <div>
        {/*<h3>Building Manager</h3>*/}
        {/*<br />*/}
        {/*<div style={{ marginLeft: "-7px" }}>*/}
        {/*  <Select*/}
        {/*    className="standard-input"*/}
        {/*    value={this.state.property}*/}
        {/*    onChange={this.updateProperty}*/}
        {/*    options={this.state.properties}*/}
        {/*    styles={reactSelectBasicStyle}*/}
        {/*    onFocus={RemoveStickyOverlays}*/}
        {/*    onBlur={RestoreStickyOverlays}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<br />*/}
        {/*<div style={{ minHeight: "64vh", maxHeight: "64vh" }}>*/}
        {/*  <table className="fixedTable">*/}
        {/*    <thead>*/}
        {/*      <tr>*/}
        {/*        <th className="fixedTableHeaderFirst">Name</th>*/}
        {/*        <th className="fixedTableHeader">Roof Width</th>*/}
        {/*        <th className="fixedTableHeader">Roof Length</th>*/}
        {/*        <th className="fixedTableHeader">Last Roof Replacement</th>*/}
        {/*        <th className="fixedTableHeader">Last Rehab</th>*/}
        {/*        <th className="fixedTableHeader">Unit Count</th>*/}
        {/*        <th className="icon fixedTableHeader"></th>*/}
        {/*        <th className="icon fixedTableHeader"></th>*/}
        {/*      </tr>*/}
        {/*    </thead>*/}
        {/*    <tbody>*/}
        {/*      {*/}
        {/*        this.state.buildings.map((item) => (*/}
        {/*            <BuildingRow key={item.key} id={item.key} name={item.name} roofReplaced={item.roofReplaced} roofWidth={item.roofWidth} roofLength={item.roofLength}*/}
        {/*              lastRehab={item.lastRehab} unitCount={item.unitCount} archive={this.archive}*/}
        {/*            />*/}
        {/*        ))*/}
        {/*      }*/}
        {/*      <TableAdditionRow length={8} onClick={this.createNewBuilding} marginLeft={"35%"} />*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*</div>*/}
      </div>
    )
  }
}