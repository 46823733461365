//#region Imports
import axios from 'axios';
import * as React from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { pullExternalCompaniesABCHASE } from '../../../functions/fetchLinkedObjects';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import * as Structs from '../Structs';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { getUserID } from '../../../functions/authActions';
import AddSubcontractorToDrawModal, { AddSubcontractorToDrawModalData } from '../Modals/AddSubcontractorToDrawModal';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
//#endregion

interface SubcontractorRowProps {
  row: AddSubcontractorToDrawModalData;
  modal: React.RefObject<AddSubcontractorToDrawModal>;
  site: SelectOptions;
  draw: SelectOptions;
}

class SubcontractorRow extends React.Component<SubcontractorRowProps, {}> {

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();

  constructor(props: SubcontractorRowProps) {
    super(props);
    this.state = { }
    this.sendConditional = this.sendConditional.bind(this);
    this.sendUnconditional = this.sendUnconditional.bind(this);
    this.cancelConditional = this.cancelConditional.bind(this);
    this.cancelUnconditional = this.cancelUnconditional.bind(this);
    this.generateConditionalFunction = this.generateConditionalFunction.bind(this);
    this.payContractor = this.payContractor.bind(this);
  }

  async sendConditional(): Promise<void> {

    let data = { subcontractorUID: this.props.row.uid, createdByUID: getUserID(), isConditional: true };
    this.statusModal.current.display("Sending Waiver", "Sending Adobe Sign Waiver to Subcontractor.");
    let response = await axios.post('./api/job-waiver-tracking/start-waiver', data, { validateStatus: () => true });
    this.statusModal.current.hide();
    if (response.status === 202) {
      let _data = this.props.row;
      _data.conditionalStatus === 0
      this.setState({ row: _data });
    }
    else {
      // TODO -  Handle Error
      console.error("Error sending the conditional waiver");
    }
  }

  async cancelConditional(): Promise<void> {
    let data = { subcontractorUID: this.props.row.uid, isConditional: true };
    this.statusModal.current.display("Sending Waiver", "Sending Adobe Sign Waiver to Subcontractor.");
    let response = await axios.post('./api/job-waiver-tracking/recall', data, { validateStatus: () => true });
    this.statusModal.current.hide();
    if (response.status === 202) {
      let _data = this.props.row;
      _data.conditionalStatus === -5
      this.setState({ row: _data });
    }
  }

  async sendUnconditional(): Promise<void> {
    let data = { subcontractorUID: this.props.row.uid, createdByUID: getUserID(), isConditional: false };
    this.statusModal.current.display("Sending Waiver", "Sending Adobe Sign Waiver to Subcontractor.");
    let response = await axios.post('./api/job-waiver-tracking/start-waiver', data, { validateStatus: () => true });
    this.statusModal.current.hide();
    if (response.status === 202) {
      let _data = this.props.row;
      _data.unconditionalStatus === 0;
      this.setState({ row: _data });
    }
    else {
      // TODO -  Handle Error
      console.error("Error sending the conditional waiver");
    }
  }

  async cancelUnconditional(): Promise<void> {
    let data = { subcontractorUID: this.props.row.uid, isConditional: false };
    this.statusModal.current.display("Sending Waiver", "Sending Adobe Sign Waiver to Subcontractor.");
    let response = await axios.post('./api/job-waiver-tracking/recall', data, { validateStatus: () => true });
    this.statusModal.current.hide();
    if (response.status === 202) {
      let _data = this.props.row;
      _data.unconditionalStatus === -5
      this.setState({ row: _data });
    }
  }

  generateConditionalFunction(): any {
    let _return: any = {
      conditional: { function: () => console.error("Default Error Called"), value: null, disabled: true },
      unconditional: { function: () => console.error("Default Error Called"), value: null, disabled: true }
    }
    if (this.props.row.conditionalStatus === -5 || this.props.row.conditionalStatus === 2) {
      _return.conditional = { function: this.sendConditional, value: "Send", disabled: false };
      _return.unconditional.disabled = true;
      _return.unconditional.value = "Conditional Required";
    }
    else if (this.props.row.conditionalStatus === 0) {
      _return.conditional = { function: this.cancelConditional, value: "Recall", disabled: false };
      _return.unconditional.disabled = true;
      _return.unconditional.value = "Conditional Needs Signature";
    }
    else if (this.props.row.conditionalStatus === 1 && !this.props.row.isPaid) {
      _return.conditional = { function: () => console.error("Default Error Called"), value: "Complete", disabled: true };
      _return.unconditional = { function: () => console.error("Default Error Called"), value: "Must Be Paid", disabled: true }
    }
    else if (this.props.row.conditionalStatus === 1 && this.props.row.isPaid && (this.props.row.unconditionalStatus === -5 || this.props.row.unconditionalStatus === 2)) {
      _return.unconditional = { function: this.sendUnconditional, value: "Send", disabled: false };
      _return.conditional.disabled = true;
      _return.conditional.value = "Complete";
    }
    else if (this.props.row.conditionalStatus === 1 && this.props.row.isPaid && this.props.row.unconditionalStatus === 0) {
      _return.unconditional = { function: this.cancelUnconditional, value: "Recall", disabled: false };
      _return.conditional.disabled = true;
      _return.conditional.value = "Complete";
    }
    else if (this.props.row.conditionalStatus === 1 && this.props.row.unconditionalStatus === 1) {
      _return.conditional = { function: () => console.error("Default Error Called"), value: "Complete", disabled: true };
      _return.unconditional = { function: () => console.error("Default Error Called"), value: "Complete", disabled: true };
    }
    return _return;
  }

  async payContractor(): Promise<void> {
    if (this.props.row.isPaid) { return; }
    this.props.row.isPaid = true;
    this.setState({});
    const data = { uid: this.props.row.uid, value: true };
    let response = await axios.patch("./api/subcontractor-setup/is-paid/", data);
    if (response.status === 202) {
      this.warningModal.current.show(`${this.props.row.subcontractor.label} has been paid ${'$' + numberWithCommas(this.props.row.netAmount)}`, 'Contractor has been paid');
    }
  }

  render(): JSX.Element {
    let buttonInfo = this.generateConditionalFunction();
    return (
      <tr>
        <WarningModal ref={this.warningModal} />
        <StatusModal ref={this.statusModal} />
        <td className="fixedTableCellFirst">{this.props.row.subcontractor.label}</td>
        <td className="fixedTableCell">${numberWithCommas(this.props.row.grossAmount)}</td>
        <td className="fixedTableCell">${numberWithCommas(this.props.row.netAmount)}</td>
        <td className="fixedTableCell" style={{ cursor: (!this.props.row.isPaid ? 'pointer' : 'default'), textDecoration: (!this.props.row.isPaid ? 'underline' : '') }} onClick={this.payContractor}>
          {this.props.row.isPaid ?
            "Yes" :
            "No"
          }
        </td>
        <td className="fixedTableCell">
          {this.props.row.isFinal ? "Final" : "Progress"}
        </td>
        <td className="fixedTableCell">
          <input key={Math.random()} type="button" className="standard-input" value={buttonInfo.conditional.value} onClick={buttonInfo.conditional.function} disabled={buttonInfo.conditional.disabled} />
        </td>
        <td className="fixedTableCell">
          <input key={Math.random()} type="button" className="standard-input" value={buttonInfo.unconditional.value} onClick={buttonInfo.unconditional.function} disabled={buttonInfo.unconditional.disabled} />
        </td>
        <td className="fixedTableCell">
          <input type="button" className="standard-input" value="Edit" onClick={() => this.props.modal.current.show(this.props.row, this.props.site, this.props.draw)} />
        </td>
      </tr>
    )
  }
}

export default class SubcontractorManager extends React.Component<Structs.SubcontractorProps, Structs.SubcontractorState>{

  managementModal: React.RefObject<AddSubcontractorToDrawModal> = React.createRef<AddSubcontractorToDrawModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: Structs.SubcontractorProps) {
    super(props);
    this.state = {
      uid: props.uid,
      sites: [],
      site: { label: "Select Site", value: "" },
      draws: [],
      draw: { label: "Select Draw Number", value: "" },
      contractors: [],
      grossAmount: 0,
      netAmount: 0,
      retainage: 0,
      isFinal: false,
      isPaid: false,
      contractor: { label: "Select Contractor", value: "" },
      subs: [],
      sub: null,
      currentIndex: 0,
      isEditing: false,
      drawsLoaded: false,
      subsLoaded: true,
      headers: this.generateHeaders()
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.changeSite = this.changeSite.bind(this);
    this.changeDraw = this.changeDraw.bind(this);
    this.updateList = this.updateList.bind(this);
    this.changeGrossAmount = this.changeGrossAmount.bind(this);
    this.changeRetainage = this.changeRetainage.bind(this);
    this.changeNetAmount = this.changeNetAmount.bind(this);
    this.setRetainage = this.setRetainage.bind(this);
    this.setNetAmount = this.setNetAmount.bind(this);
    this.changeSubcontractor = this.changeSubcontractor.bind(this);
    this.changeIsFinal = this.changeIsFinal.bind(this);
    this.changeIsPaid = this.changeIsPaid.bind(this);
    this.editSub = this.editSub.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    const contractors = await pullExternalCompaniesABCHASE();
    this.setState({
      contractors: contractors
    })
    await axios.get("./api/managed-jobs/projects/" + this.state.uid).then(function (response) {
      const data = response.data;
      let params = new URLSearchParams(window.location.search);
      let site: SelectOptions;
      if (params.has('site')) {
        site = data
          .map(function (item: any) { return { label: item.property, value: item.projectUID } })
          .filter((u: SelectOptions) => u.value === params.get('site'))[0];
      }
      else {
        site = data.map(function (item: any) { return { label: item.property, value: item.projectUID } })[0];
      }
      self.setState({
        sites: data.map(function (item: any) { return { label: item.property, value: item.projectUID } }),
        site: site,
        drawsLoaded: true
      }, () => self.changeSite(site))
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Subcontractor Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Gross Amount",
        setting: true,
        filter: {}
      },
      {
        columnName: "Amount",
        setting: true,
        filter: {}
      },
      {
        columnName: "Paid",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      },
      {
        columnName: "Type",
        setting: true,
        filter: {}
      },
      {
        columnName: "Conditional",
        setting: true,
        filter: {}
      },
      {
        columnName: "Unconditional",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
  }

  //#region Load Functions

  redirectToDraws(): void {
    let params = new URLSearchParams(window.location.search);
    //@ts-ignore
    params['currentTab'] = 1;
    window.location.search = params.toString();
  }

  async changeSite(event: SelectOptions | null): Promise<void> {
    let params = new URLSearchParams(window.location.search);
    if (params.get('site') !== event.value) {
      if (params.has('draw')) {
        params.delete('draw');
      }
      params.set('site', event.value);
      window.location.search = params.toString();
    }
    
    let response = await axios.get('./api/draw-requests/' + event.value + "/" + "true");
    if (response.status === 200) {
      const draws: Array<SelectOptions> = [];
      if (response.data.length === 0) {
        this.warningModal.current.show('You need to create a draw before you start adding subcontractors. Please add a draw before proceeding', "Error", this.redirectToDraws);
        return;
      }
      response.data.forEach(function (draw: any) {
        draws.push({
          label: draw.number + 1,
          value: draw.uid
        })
      })
      let draw = { label: "Select Draw", value: "" };
      if (params.has('draw')) {
        draw = draws.filter(u => u.value == params.get('draw'))[0];
      }
      this.setState({
        site: event,
        subsLoaded: false,
        draw: draw,
        draws: draws
      }, async () => await this.updateList());
    }
  }

  changeDraw(event: SelectOptions | null): void {
    let params = new URLSearchParams(window.location.search);
    if (params.get('draw') !== event.value) {
      params.set('draw', event.value);
      window.location.search = params.toString();
    }
    this.setState({
      draw: event,
      subsLoaded: false
    }, this.updateList)
  }

  async updateList(): Promise<void> {
    if (this.state.draw.value === '') {
      this.setState({
        subsLoaded: true,
        subs: []
      });
      return;
    }
    const draw = this.state.draw;
    const site = this.state.site;
    let response = await axios.get('./api/subcontractor-setup/' + site.value + "/" + draw.value);
    if (response.status === 200) {
      const subs: Array<AddSubcontractorToDrawModalData> = [];
      let site: SelectOptions = this.state.site;
      let draw: SelectOptions = this.state.draw;
      response.data.forEach(function (sub: AddSubcontractorToDrawModalData) {
        subs.push({
          uid: sub.uid,
          subcontractor: sub.subcontractor,
          site: site,
          draw: draw,
          grossAmount: sub.grossAmount,
          netAmount: sub.netAmount,
          isFinal: sub.isFinal,
          isPaid: sub.isPaid,
          conditionalStatus: sub.conditionalStatus,
          unconditionalStatus: sub.unconditionalStatus
        })
      })
      this.setState({
        subs: subs,
        subsLoaded: true
      })
    }
  }

  //#endregion

  //#region Change Functions
  changeGrossAmount(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = parseFloat(event.target.value);
    this.setState({
      grossAmount: value
    }, () => this.setRetainage(value * 0.05));
  }

  changeRetainage(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setRetainage(parseFloat(event.target.value));
  }

  changeNetAmount(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setNetAmount(parseFloat(event.target.value));
  }

  setRetainage(value: number): void {
    this.setState({
      retainage: value
    }, () => this.setNetAmount(this.state.grossAmount - this.state.retainage));
  }

  setNetAmount(value: number): void {
    this.setState({
      netAmount: value
    })
  }

  changeSubcontractor(event: SelectOptions | null): void {
    this.setState({
      contractor: event
    })
  }

  changeIsFinal(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      isFinal: event.target.checked
    })
  }

  changeIsPaid(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      isPaid: event.target.checked
    })
  }

  editSub(idx: number): void {
    const sub = this.state.subs[idx];
    // set State
    this.setState({
      isEditing: true,
      currentIndex: idx,
      contractor: { label: sub.name, value: sub.uid },
      grossAmount: sub.grossAmount,
      netAmount: sub.amount,
      retainage: sub.retainage,
      isPaid: sub.paid,
      isFinal: sub.type
    })
  }
  //#endregion

  render(): JSX.Element {
    return (
      <>
        <AddSubcontractorToDrawModal ref={this.managementModal} uid={this.state.uid} oldSystem={true} />
        <WarningModal ref={this.warningModal} />
        {
          this.state.drawsLoaded && this.state.subsLoaded && <>
            <Row>
              <Col>
                <h6 style={{ width: "100%", textAlign: "center"}}>Select Site</h6>
                <Select
                  styles={reactSelectBasicStyle}
                  options={this.state.sites}
                  value={this.state.site}
                  onChange={this.changeSite}
                  onBlur={RestoreStickyOverlays}
                  onFocus={RemoveStickyOverlays}
                />
              </Col>
              <Col>
                <h6 style={{ width: "100%", textAlign: "center" }}>Select Draw</h6>
                <Select
                  styles={reactSelectBasicStyle}
                  options={this.state.draws}
                  value={this.state.draw}
                  onChange={this.changeDraw}
                  onBlur={RestoreStickyOverlays}
                  onFocus={RemoveStickyOverlays}
                />
              </Col>
              <Col>
                <h6 style={{ width: "100%", textAlign: "center" }}>Add Subcontractor</h6>
                <input type="button" value="Add Subcontractor" className="standard-input" onClick={() => this.managementModal.current.show(null, this.state.site, this.state.draw)} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <div style={{ height: "70vh", overflowY: "scroll" }}>
                <table className="fixedTable">
                  <thead>
                    <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
                  </thead>
                  <tbody>
                    {
                      this.state.subs.map((item) => (
                        <SubcontractorRow key={item.uid} row={item} modal={this.managementModal} site={this.state.site} draw={this.state.draw} />
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </Row>
          </>
        }
      </>
    )
  }
}
