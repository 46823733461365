import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { Jobs, Teams, ValidateAuth } from '../../../functions/authActions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { StatusModal } from '../../CoreComponents/Modals';
import { CategoryList, MCICourseManagerProps, MCICourseManagerState } from '../constants';

export class CourseManager extends React.Component<MCICourseManagerProps, MCICourseManagerState> {

  statusModal = React.createRef<StatusModal>();

  constructor(props: MCICourseManagerProps) {
    super(props);
    this.state = {
      headers: [],
      courses: [],
      filterSettings: {
        name: ''
      }
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.getCourseList = this.getCourseList.bind(this);
    this.filterName = this.filterName.bind(this);
    this.export = this.export.bind(this);
  }

  async componentDidMount(): Promise<void> {
    ValidateAuth([Jobs.President, Jobs.Administrator], [Teams.MCI]);
    this.generateHeaders();
    await this.getCourseList();
  }

  async getCourseList(): Promise<void> {
    const data = {
      Page: 0,
      PageCount: 0,
      CourseName: this.state.filterSettings.name
    }
    await axios.post('./api/course-config/filter', data).then((response) => {
      const courses: any = [];
      response.data.forEach(function (item: any) {
        courses.push({
          uid: item.uid,
          name: item.name,
          category: CategoryList[item.category],
          exams: item.exams.length,
          cost: item.cost
        })
      });
      this.setState({
        courses: courses
      })
    });
  }

  generateHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Class Name",
        setting: true,
        filter: {
          type: "text",
          name: 'name',
          value: this.state.filterSettings.name,
          changeFilter: this.filterName,
          placeHolder: "Filter by Course Name"
        }
      },
      {
        columnName: "Course Category",
        setting: true,
        filter: {}
      },
      {
        columnName: "Number of Exams",
        setting: true,
        filter: {}
      },
      {
        columnName: "Course Cost",
        setting: true,
        filter: {}
      }
    ]
    this.setState({ headers: headers })
  }

  async filterName(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const filter = this.state.filterSettings;
    filter.name = event.target.value;
    this.setState({ filterSettings: filter })
    this.generateHeaders();
    await this.getCourseList();
  }

  async export(): Promise<void> {
    this.statusModal.current.display("Exporting Class List", "The MCI Class List is being prepared. Please wait a moment while it is generated.");
    await axios.get("./api/course-config/export").then((response) => {
      this.statusModal.current.hide();
      const fileDownload = require('js-file-download');
      fileDownload(response.data, `MCI Class List ${new Date().toLocaleDateString()}.csv`);
    })
  }

  render(): JSX.Element {
    return (
      <>
        <StatusModal ref={this.statusModal} />
        <div style={{ height: "80vh", overflowY: "scroll" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              {
                this.state.courses.map((item: any) => (
                  <tr key={item.uid} >
                    <td className="fixedTableCellFirst" style={{ wordBreak: "break-word" }}>{item.name}</td>
                    <td className="fixedTableCell" style={{ wordBreak: "break-word" }}>{item.category}</td>
                    <td className="fixedTableCell" style={{ wordBreak: "break-word" }}>{item.exams}</td>
                    <td className="fixedTableCell" style={{ wordBreak: "break-word", textAlign: "center" }}>${item.cost}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faCog} onClick={() => window.location.assign("./course-creator?uid=" + item.uid)}
                        style={{ marginLeft: "40%" }} className="fa-icon"
                      />
                    </td>
                  </tr>
                ))
              }
              <TableAdditionRow length={5} marginLeft={"40%"} onClick={() => window.location.assign("./course-creator")} />
            </tbody>
          </table>
        </div>
        <input type="button" value="Export" className="standard-input" style={{ marginLeft: "2%", width: "20%" }}
          onClick={this.export}
        />
      </>
    )
  }

}