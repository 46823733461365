import * as React from 'react';
import axios from 'axios';
import EntityManagementTable from './EntityComponents/EntityManagementTable/EntityManagementTable';
import ActiveJobManagementTable from './JobManagementComponents/ActiveJobManagementComponents/ActiveJobManagementTable';
import { SolarProjectList } from './ProjectLists';
import { getUserID, Jobs, Teams } from '../functions/authActions';
import AdminPortalScreen from './MCIComponents/AdminPanel/AdminPortalScreen';
import NewEntityRequestDraftManager from './EntityComponents/EntityRequestComponents/Drafts/NewEntityRequestDraftManager';
import { WarningModal } from './CoreComponents/Modals';
import { HumanResourcesDashboard } from './DashboardComponents/Dashboards/HumanResourcesDashboard';
import { MCIInstructorDashboard } from './DashboardComponents/Dashboards/MCIInstructorDashboard';
import { PropertyAccountantDashboard } from './DashboardComponents/Dashboards/PropertyAccountingDashboard';
import { RegionalManagerDashboard } from './DashboardComponents/Dashboards/RegionalManagerDashboard';
import { VicePresidentDashboard } from './DashboardComponents/Dashboards/VicePresidentOperationsDashboard';
import TechTeamDashboardManager from './TechTeamComponents/TechTeamDashboardManagerComponents/TechTeamDashboardManager';
import TechTeamLeadDashboardManager from './TechTeamComponents/TechTeamDashboardManagerComponents/TechLeadDashboardManager';
import { ChiefOperatingOfficerDashboard } from './DashboardComponents/Dashboards/ChiefOperatingOfficerDashboard';
import CFODashboardManager from './DashboardComponents/ChiefFinancialOfficerDashboardManager';
import ViridianPresidentDashboard from './DashboardComponents/Dashboards/ViridianPresidentDashboard';
import DevelopmentAccountantDashboard from './DashboardComponents/Dashboards/DevelopmentAccountantDashboard';
import DevelopmentFinanceDashboard from './SubcontractorManagementComponents/ConstructionDashboards/DevelopmentFinanceDashboard';

interface HomeProps {
  auth: any;
}

interface HomeState {
  userId: string;
  userJob: number;
  userTeam: number;
}

export default class Home extends React.Component<HomeProps, HomeState> {

  messageModal = React.createRef<WarningModal>();

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      userId: getUserID(),
      userJob: -1,
      userTeam: -1
    }
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    let params = new URLSearchParams(window.location.search);
    // we can create information 
    if (params.has('info') && params.has("title")) {
      let message = params.get('info');
      let title = params.get('title');
      this.messageModal.current.show(message, title);
    }
    await axios.get('/api/empire/auth/' + getUserID()).then(function (response) {
      // Replace this with calls to newer methods
      const userJob = response.data.newJob;
      const userTeam = response.data.team;
      if (userJob === Jobs.Site_Manager) {
        window.location.assign("/site-manager-dashboard");
      }
      if (userJob === Jobs.Maintenance_Tech) {
        window.location.assign("/maintenance-tech-dashboard");
      }
      self.setState({
        userJob: userJob,
        userTeam: userTeam
      })
    });
  }

  userSwitch(): JSX.Element {
    let value = (<></>);
    switch (this.state.userTeam) {
      case Teams.Executive:
        if (this.state.userJob === Jobs.CFO) {
          value = (<CFODashboardManager />)
        }
        else if (this.state.userJob === Jobs.COO) {
          value = (<ChiefOperatingOfficerDashboard />)
        }
        else if (this.state.userJob == Jobs.President) {
          value = (<ViridianPresidentDashboard />)
        }
        else {
          value = <EntityManagementTable />
        }
        break;
      case Teams.Human_Resources:
        if (this.state.userJob === Jobs.HR) {
          value = (<HumanResourcesDashboard />)
        } else {
          value = <EntityManagementTable />
        }
        break;
      case Teams.Construction:
        value = <EntityManagementTable />
        break;
      case Teams.MCI:
        switch (this.state.userJob) {
          case Jobs.Instructor:
            value = (<MCIInstructorDashboard />)
            break;
          default:
            value = (<AdminPortalScreen />)
            break;
        }
        break;
      case Teams.Fleet:
        if (this.state.userJob === Jobs.President) {
          value = (<SolarProjectList type={undefined} url={undefined} get={undefined} name={undefined} cellOne={undefined} cellTwo={undefined} cellThree={undefined} headerOne={undefined} headerTwo={undefined} headerThree={undefined} />)
        } else {
          value = <EntityManagementTable />
        }
        break;
      case Teams.Asset_Management:
        if (this.state.userJob === Jobs.Legal_Analyst) {
          value = (<NewEntityRequestDraftManager />)
        } else {
          value = <EntityManagementTable />
        }
        break;
      case Teams.Services:
        if (this.state.userJob === Jobs.Technology) {
          value = (<TechTeamDashboardManager />)
        }
        else if (this.state.userJob === Jobs.Technology_Lead) {
          value = (<TechTeamLeadDashboardManager />)
        }
        else if (this.state.userJob === Jobs.Marketing) {
          value = (<AdminPortalScreen />)
        } else {
          value = <EntityManagementTable />
        }
        break;
      case Teams.Development:
        if (this.state.userJob === Jobs.Project_Manager) {
          value = (<ActiveJobManagementTable />)
        }
        else if (this.state.userJob === Jobs.Administrative) {
          value = (<EntityManagementTable />)
        }
        else if (this.state.userJob === Jobs.Accountant) {
          value = (<DevelopmentAccountantDashboard />)
        }
        else if (this.state.userJob === Jobs.Finance) {
          value = (<DevelopmentFinanceDashboard />)
        }
        else {
          value = <EntityManagementTable />
        }
        break;
      case Teams.Property_Management:
        switch (this.state.userJob) {
          case Jobs.Accountant:
            value = <PropertyAccountantDashboard />;
            break;
          case Jobs.Regional:
            value = <RegionalManagerDashboard />
            break;
          case Jobs.VP_Operations:
            value = <VicePresidentDashboard />
            break;
          default:
            value = <EntityManagementTable />
            break;
        }
        break;
      default:
        break;
    }
    return value;
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.messageModal} callback={() => { window.location.replace("/") }} />
        {this.userSwitch()}
      </>
    )
  }
}