import * as React from 'react'
import { TechDashboardOptions, TechTeamDashboardManagerProps, TechTeamDashboardManagerState } from './TechTeamDashboardManager.constants';
import Select from 'react-select';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { MCIInstructorDashboard } from '../../DashboardComponents/Dashboards/MCIInstructorDashboard';
import { PropertyAccountantDashboard } from '../../DashboardComponents/Dashboards/PropertyAccountingDashboard';
import { RegionalManagerDashboard } from '../../DashboardComponents/Dashboards/RegionalManagerDashboard';
import { VicePresidentDashboard } from '../../DashboardComponents/Dashboards/VicePresidentOperationsDashboard';
import { HumanResourcesDashboard } from '../../DashboardComponents/Dashboards/HumanResourcesDashboard';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import TechnologyTeamDashboard from '../../DashboardComponents/Dashboards/TechnologyTeamDashboard';

export default class TechTeamDashboardManager extends React.Component<TechTeamDashboardManagerProps, TechTeamDashboardManagerState>{

  constructor(props: TechTeamDashboardManagerProps) {
    super(props);
    this.state = {
      dashboard: { value: "0", label: "Tech Team" }
    }
    this.dashboardSwitch = this.dashboardSwitch.bind(this);
    this.changeDashboard = this.changeDashboard.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  dashboardSwitch(): JSX.Element {
    switch (this.state.dashboard.value) {
      case "0":
        return <TechnologyTeamDashboard key={this.state.dashboard.value} />
      case "1":
        return <MCIInstructorDashboard key={this.state.dashboard.value} />
      case "2":
        return <PropertyAccountantDashboard key={this.state.dashboard.value} />
      case "3":
        return <RegionalManagerDashboard key={this.state.dashboard.value} />
      case "4":
        return <VicePresidentDashboard key={this.state.dashboard.value} />
      default:
        return <h3>ERROR</h3>
    }
  }

  changeDashboard(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ dashboard: event })
  }

  render(): JSX.Element {
    return (
      <div style={{ overflowY: "auto", height: "90vh", overflowX: "hidden" }}>
        {this.dashboardSwitch()}
        <div style={{ width: "25%", marginLeft: "75%" }}>
          <Select
            options={TechDashboardOptions}
            styles={reactSelectBasicStyle}
            value={this.state.dashboard}
            onChange={this.changeDashboard}
            menuPlacement="auto"
            menuPosition="fixed"
            onFocus={RemoveStickyOverlays}
            onBlur={RestoreStickyOverlays}
          />
        </div>
      </div>
    )
  }
}