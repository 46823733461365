import * as React from 'react';
import { Container, Col, Row } from 'reactstrap';
import NavMenu from './NavMenu';
import SideBar from './SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { getBearerToken } from '../functions/authActions';

interface LayoutProps {
  children: any;
}

interface LayoutState {
  showSidebar: boolean;
}

export default class Layout extends React.Component<{ children: any }, LayoutState> {

  static displayName = Layout.name;

  static SearchParameters: { [key: string] : string } = {
    'portfolio-manager': 'Portfolios',
    'manage-properties': "Properties",
    'building-manager': "Buildings",
    'electric-utility-manager': "Electric Utilities",
    'entity-manager': 'Entities',
    'active-job-management': "General Contracting Jobs",
    'active-job-management/dashboard': "General Contracting Jobs",
    "ticket-manager": "Tickets",
    "entity-request-form": "New Entity Request",
    "payroll-processing": "Payroll",
    "entity-request-drafts": "New Entity Request",
    'ticket-work-manager': "Task Manager Dashboard",
    'capital-needs-assessment-viewer': "CNA",
    'task-management': "Task Manager Dashboard"
  }

  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      showSidebar: true
    }
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  }

  changeSidebar(value: boolean): void {
    var values = true;
    //values.showSidebar = value;
    this.setState({ showSidebar: value });
  }

  getSearchParam() {
    var path: string = window.location.pathname.substring(1); // substring removes home delimiter
    if (path in Layout.SearchParameters) {
      return "?search=" + Layout.SearchParameters[path];
    }
    else {
      return "";
    }
  }

  render(): JSX.Element {
    return (
      <div style={{ overflow: "hidden"}}>
        <div id="global-alert" className="global-alert">
          <p id="alert-text" className="alert-text"> Global Alert </p>
        </div>
        <NavMenu />
        <Container fluid className="no-margin-container">
          <Row>
            {this.state.showSidebar ?
              <div className="no-margin-container" style={{ width: "17%" }}>
                <SideBar />
                <Col>
                  <input type="button" value="Knowledge Base Home" style={{ float: "right", marginTop: "-9.7vh", marginRight: "30%", width: "60%" }} className="standard-input"
                    onClick={() => window.open('/knowledge-base-home' + this.getSearchParam(), '_blank')}
                  />
                </Col>
                <Col>
                  <FontAwesomeIcon icon={faArrowLeft} style={{ float: "right", marginTop: "-9vh", marginRight: "10%" }} size="lg" onClick={() => this.changeSidebar(false)} />
                </Col>
              </div>
              :
              <div className="no-margin-container"
                style={{ borderRight: "1px solid #c2a877", height: "96vh", width: "2%", borderRadius: "1%" }} >
                <FontAwesomeIcon icon={faArrowRight} style={{ float: "right", marginTop: "87vh", marginRight: "10%", }} size="lg" onClick={() => this.changeSidebar(true)} />
              </div>
            }
            <div style={{ width: this.state.showSidebar ? "83%" : "98%", overflowY: "hidden", overflowX: "hidden" }}>
              <Container fluid>
                {this.props.children}
              </Container>
            </div>
          </Row>
        </Container>
      </div>
    )
  }
}
