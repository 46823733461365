import * as React from 'react';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullTicketCategory } from '../../../functions/fetchObjectNameFunctions';
import { TicketEmailSendEmailModal, TicketEmailViewBodyModal } from './TicketEmailManagerWidget.modals';
import axios from 'axios';

interface TicketEmailManagerWidgetData {
  uid: string;
  sender: string;
  timeStamp: Date;
  body: string;
}

interface TicketEmailManagerWidgetState {
  emails: Array<TicketEmailManagerWidgetData>;
  headers: Array<FilterAndSettingField>;
  category: SelectOptions;
}

export default class TicketEmailManagerWidget extends React.Component<{ uid: string}, TicketEmailManagerWidgetState> {

  emailSenderModal: React.RefObject<TicketEmailSendEmailModal> = React.createRef<TicketEmailSendEmailModal>();
  viewBodyModal: React.RefObject<TicketEmailViewBodyModal> = React.createRef<TicketEmailViewBodyModal>();

  constructor(props: { uid: string}) {
    super(props);
    this.state = {
      emails: [],
      headers: [],
      category: { label: "Select Category", value: ""}
    }
  }

  async componentDidMount(): Promise<void> {
    let category = await pullTicketCategory(this.props.uid);
    await this.pullEmails();
    this.setState({ category: category }, async () => await this.generateHeaders());
  }

  async pullEmails(): Promise<void> {
    let response = await axios.get('./api/ticket-email-correspondence/' + this.props.uid);
    if (response.status === 200) {
      this.setState({ emails: response.data });
    }
    else {
      // use a warning modal to create an error
    }
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [];
    headers.push({ columnName: "Sender", setting: true, filter: {} });
    headers.push({ columnName: "TimeStamp", setting: true, filter: {} });
    headers.push({ columnName: "Body", setting: true, filter: {} });
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <>
        <TicketEmailSendEmailModal ref={this.emailSenderModal} ticket={this.props.uid} />
        <TicketEmailViewBodyModal ref={this.viewBodyModal} />
        {RenderHeader("Emails")}
        <div style={{ height: "26vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.emails.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.sender}</td>
                    <td className="fixedTableCell">{new Date(item.timeStamp).toLocaleDateString("en-CA")}</td>
                    <td className="fixedTableCell" style={{ whiteSpace: 'nowrap', textOverflow: "ellipsis", cursor: "pointer" }} onClick={() => this.viewBodyModal.current.show(item.body)}>
                      {item.body}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <input type="button" value="Send Email" onClick={() => this.emailSenderModal.current.show()} className="standard-input" />
      </>
    )
  }
}