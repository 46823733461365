import * as React from 'react';
import { DashboardManagementModal } from '../../CoreComponents/Modals/DashboardManagementModal';
import { RenderSettings } from '../../../interfaces/WidgetSettingsInterfaces';
import { GetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { DashboardOrganizer, DefaultPageSettings } from '../../../functions/WidgetDashboardOrganizer';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { Col, Row } from 'reactstrap';
import { UsefulLinksWidget } from '../../CoreComponents/UsefulLinksWidget';
import TaxReportProcessors from '../../AccountingComponents/Widgets/TaxUploadProcessorWidget';
import GoogleWorkspaceBillingProcessing from '../../SoftwareBillingComponents/Widgets/GoogleWorkspaceBillingProcessing';
import { EmployeePastAllocationReportGenerator } from '../../PayrollComponents/Widgets/EmployeePastAllocationReportGenerator';

export interface DevelopmentAccountantDashboardState {
  showModal: boolean;
  renderSettings: RenderSettings;
  widgetList: Array<SelectOptions>;
  displayWidgets: { [key: number]: JSX.Element };
}

export default class DevelopmentAccountantDashboard extends React.Component<{}, DevelopmentAccountantDashboardState> {

  widgetSettingsModalRef = React.createRef<DashboardManagementModal>();
  settingsString: string = "developmentAccountantDashboard";

  constructor(props: {}) {
    super(props);
    let settings: RenderSettings = GetSettings<RenderSettings>(this.settingsString);
    let showModal: boolean = false;
    if (settings === null) {
      showModal = true
      settings = DefaultPageSettings
    }
    this.state = {
      showModal: showModal,
      renderSettings: settings,
      displayWidgets: [],
      widgetList: []
    }
    this.updateUI = this.updateUI.bind(this);
    DashboardOrganizer.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.prepareDasboard();
    if (this.state.showModal) {
      this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI);
    }
  }

  async prepareDasboard(): Promise<void> {
    let displayWidgets: { [key: number]: JSX.Element } = {
      0: <UsefulLinksWidget />,
      1: <TaxReportProcessors />,
      2: <GoogleWorkspaceBillingProcessing />,
      3: <EmployeePastAllocationReportGenerator />
    }
    let widgetList: Array<SelectOptions> = [
      { label: "Useful Links", value: "0" },
      { label: "Tax Report Processors", value: "1" },
      { label: "Google Workspace Billing", value: "2" },
      { label: "Allocation Reports", value: "3"}
    ]
    this.setState({
      displayWidgets: displayWidgets,
      widgetList: widgetList
    })
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  render(): JSX.Element {
    return (
      <>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>Development Accounting Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.settingsString, this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        {DashboardOrganizer(this.state.displayWidgets, this.state.renderSettings)}
      </>
    )
  }
}