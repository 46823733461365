import axios from "axios";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { getBearerToken } from "../../../functions/authActions";
import { PropertyInformationWidgetProps, PropertyInformationWidgetState } from "./PropertyInformationwidget.constants";
import { RenderHeader } from "../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode";
import { numberWithCommas } from "../../../functions/numberFunctions";
import { PropertyInformationPull } from "./PropertyInformationWidget.functions";
import PropertyInformationEditModal from "../PropertyModals/PropertyInformationEditModal";

export default class PropertyInformationWidget extends React.Component<PropertyInformationWidgetProps, PropertyInformationWidgetState> {

  propertyInformationEditModal = React.createRef<PropertyInformationEditModal>();

  constructor(props: PropertyInformationWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      property: {
        uid: "",
        name: "",
        code: "",
        streetAddress: "",
        city: "",
        state: { label: "Select State", value: "" },
        zipCode: "",
        county: "",
        relatedPortfolio: { label: "Select Portfolio", value: "" },
        type: { label: "Select Type", value: "" },
        lastRehab: new Date(),
        buildings: 0,
        unitCount: 0,
        totalSQFT: 0,
        fundingTypes: [],
        isPPU: false
      },
      loaded: false,
      callback: props.callback
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let data = await PropertyInformationPull(this.state.uid);
    if (data !== "Invalid Request") {
      this.setState({ property: data });
    }
    else {

    }
  }

  callback(uid: string): void {
    this.state.callback(uid)
  }

  render(): JSX.Element {
    return (
      <div>
        <PropertyInformationEditModal ref={this.propertyInformationEditModal} />
        {RenderHeader("Property Information")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <Row>
            <Col>
              <h6>Code</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.property.code}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Street Address</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{`${this.state.property.streetAddress} ${this.state.property.city}, ${this.state.property.state.label} ${this.state.property.zipCode}`}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>County</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.property.county}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Related Portfolio</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.property.relatedPortfolio.label}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Type</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.property.type.label}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Last Rehab</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{new Date(this.state.property.lastRehab).toLocaleDateString("en-CA")}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Buildings</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.property.buildings}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Unit Count</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.property.unitCount}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Total SQFT</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{numberWithCommas(this.state.property.totalSQFT, 0)}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Funding Types</h6>
            </Col>
            <Col>
              <h6 style={{ width: "100%", textAlign: "right" }}>{this.state.property.fundingTypes.map(u => u.label).join(", ")}</h6>
            </Col>
          </Row>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "3px", marginBottom: "8px" }} />
        <input type="button" value="Edit Information" className="standard-input" onClick={() => this.propertyInformationEditModal.current.show(this.state.property)} />
      </div>
    )
  }
}
