import * as React from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { getBearerToken } from '../../functions/authActions';

const UnitRow = (props: any) => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.name}</td>
      <td className="fixedTableCell">{props.bedrooms}</td>
      <td className="fixedTableCell">{props.bathrooms}</td>
      <td className="fixedTableCell">{props.sqft}</td>
      <td className="fixedTableCell">{props.meterSet}</td>
      <td className="fixedTableCell"><FontAwesomeIcon icon={faCog} style={{ marginLeft: "30%", cursor: 'pointer', color: '#b89961' }} onClick={() => props.onClick(props.id)} /></td>
    </tr>
  )
}

interface UnitManagerProps {

}

interface UnitManagerState {
  buildingId: string;
  buildingName: string;
  buildingWidth: string;
  buildingLength: string;
  buildingAzimuth: any;
  buildingData: any;
  units: Array<any>;
  editingUnit: boolean;
  currentUnit: any;
}

export class UnitManager extends React.Component<UnitManagerProps, UnitManagerState> {

  constructor(props: UnitManagerProps) {
    super(props);
    const pathList = window.location.pathname.split("/");
    const UID = pathList[pathList.length - 1] !== 'unit-manager' ? pathList[pathList.length - 1] : '';
    this.state = {
      buildingId: UID,
      buildingName: '',
      buildingWidth: '',
      buildingLength: '',
      buildingAzimuth: '',
      buildingData: {},
      units: [],
      editingUnit: false,
      currentUnit: {
        unit: {},
        meterSet: '',
        meter: '',
        meterNumber: ''
      }
    }
    //this.changeName = this.changeName.bind(this);
    //this.editUnit = this.editUnit.bind(this);
    //this.cancelEditUnit = this.cancelEditUnit.bind(this);
    //this.changeInt = this.changeInt.bind(this);
    //this.saveBuilding = this.saveBuilding.bind(this);
    //this.createNewUnit = this.createNewUnit.bind(this);
    //this.changeCurrentUnit = this.changeCurrentUnit.bind(this);
    //this.changeMeter = this.changeMeter.bind(this);
    //this.updateUnit = this.updateUnit.bind(this);
    //this.saveMeterId = this.saveMeterId.bind(this);
  }

  //async componentDidMount(): Promise<void> {
  //  const self = this;
  //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  //  await axios.get('./api/buildings/' + this.state.buildingId)
  //    .then(function (response) {
  //      self.setState({
  //        buildingName: response.data.name,
  //        buildingWidth: response.data.roofWidth,
  //        buildingLength: response.data.roofLength,
  //        buildingAzimuth: response.data.roofAzimuth,
  //        buildingData: response.data
  //      })
  //    })
  //  await axios.get('./api/units/building/' + this.state.buildingId)
  //    .then(function (response) {
  //      const units: Array<any> = [];
  //      response.data.forEach(function (item: any) {
  //        const meterSet = item.electricMeterId === null ? "No" : "Yes";
  //        units.push({
  //          unit: item,
  //          meterSet: meterSet,
  //          meter: item.electricMeter === null ? '' : item.electricMeter.meterId,
  //          meterNumber: item.electricMeter === null ? '' : item.electricMeter.meterLabel
  //        })
  //      });
  //      self.setState({
  //        units: units
  //      })
  //    });
  //}

  ////#region Change Functions

  //changeName(event: React.ChangeEvent<HTMLInputElement>): void {
  //  this.setState({
  //    buildingName: event.target.value
  //  })
  //}

  //editUnit(id: any): void {
  //  const unit = this.state.units.filter((item: any) => item.unit.uid === id);
  //  this.setState({ editingUnit: true, currentUnit: unit[0] });
  //}

  //cancelEditUnit(): void {
  //  this.setState({
  //    editingUnit: false, currentUnit: { unit: {}, meterSet: '', meter: '' }
  //  });
  //}

  //changeInt(event: React.ChangeEvent<HTMLInputElement>): void {
  //  //@ts-ignore
  //  this.setState({
  //    [event.target.name]: event.target.value
  //  })
  //}

  //async saveBuilding(): Promise<void> {
  //  const data = this.state.buildingData;
  //  data.Name = this.state.buildingName;
  //  data.RoofWidth = this.state.buildingWidth;
  //  data.RoofLength = this.state.buildingLength;
  //  data.RoofAzimuth = this.state.buildingAzimuth;
  //  await axios.put('./api/buildings/', data)
  //    .then(function (response) {
  //      CreateGlobalAlert("Building Updated Successfully, Reloading Page", 1200, () => window.location.reload());
  //    })
  //}

  //async createNewUnit(): Promise<void> {
  //  const self = this;
  //  await axios.post("./api/units/building/" + this.state.buildingId + "/create-new")
  //    .then(function (response) {
  //      const units = self.state.units;
  //      units.push({
  //        uid: response.data.uid,
  //        name: response.data.name,
  //        bedrooms: response.data.bedrooms,
  //        bathrooms: response.data.bathrooms,
  //        sqft: response.data.squareFeet,
  //        meterSet: "No",
  //        meter: null
  //      })
  //      self.setState({
  //        units: units
  //      })
  //    });
  //}

  //changeCurrentUnit(event: React.ChangeEvent<HTMLInputElement>): void {
  //  const currentUnit = this.state.currentUnit;
  //  currentUnit.unit[event.target.name] = event.target.value;
  //  this.setState({
  //    currentUnit: currentUnit
  //  });
  //}

  //changeMeter(event: React.ChangeEvent<HTMLInputElement>): void {
  //  const currentUnit = this.state.currentUnit;
  //  currentUnit[event.target.name] = event.target.value;
  //  this.setState({
  //    currentUnit: currentUnit
  //  });
  //}

  //updateUnit() {
  //  const data = this.state.currentUnit.unit;
  //  axios.put("./api/units/update", data)
  //    .then(function (response) {
  //      CreateGlobalAlert("Updated unit. Reloading Page", 1200, () => window.location.reload());
  //    })
  //}

  //saveMeterId(): void {
  //  const self = this;
  //  axios.post('./api/units/create-electric-meter/' + this.state.currentUnit.unit.uid
  //    + '/' + this.state.currentUnit.meter
  //    + "/" + this.state.currentUnit.meterNumber)
  //    .then(function (response) {
  //      CreateGlobalAlert("Meter created. The meter will now lock. If you need to correct the meter please contact Johnny.", 1500);
  //      const currentUnit = self.state.currentUnit;
  //      currentUnit.meterSet = "Yes";
  //      self.setState({ currentUnit: currentUnit });
  //    })
  //    .catch(function (response) {
  //      CreateGlobalAlert("The Meter Could not Be Created. Please add an Electric Provider to this property", 1500);
  //    });
  //}

  //#endregion

  render(): JSX.Element {
    return (
      <div>
        {/*<h3>Building</h3>*/}
        {/*<Row>*/}
        {/*  <Col>*/}
        {/*    <label>Name</label>*/}
        {/*    <br />*/}
        {/*    <input className="standard-input" type="text" value={this.state.buildingName} onChange={this.changeName} />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <label>Roof Width</label>*/}
        {/*    <br />*/}
        {/*    <input className="standard-input" name="buildingWidth" type="number" step="1" value={this.state.buildingWidth} onChange={this.changeInt} />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <label>Roof Length</label>*/}
        {/*    <br />*/}
        {/*    <input className="standard-input" name="buildingLength" type="number" step="1" value={this.state.buildingLength} onChange={this.changeInt} />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <label>Roof Azimuth</label>*/}
        {/*    <br />*/}
        {/*    <input className="standard-input" name="buildingAzimuth" type="number" step="1" value={this.state.buildingAzimuth} onChange={this.changeInt} />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        {/*<br />*/}
        {/*<input type="button" value="Save Building" onClick={this.saveBuilding} />*/}
        {/*<input type="button" value="Back to Building List" style={{ marginLeft: "15px" }} onClick={() => window.location.assign('building-manager')} />*/}
        {/*<hr />*/}
        {/*<div style={{ display: this.state.editingUnit ? "none" : "block" }}>*/}
        {/*  <h3>Units</h3>*/}
        {/*  <table className="fixedTable">*/}
        {/*    <thead>*/}
        {/*      <tr>*/}
        {/*        <th className="fixedTableHeaderFirst">Name</th>*/}
        {/*        <th className="fixedTableHeader">Bedrooms</th>*/}
        {/*        <th className="fixedTableHeader">Bathrooms</th>*/}
        {/*        <th className="fixedTableHeader">Square Feet</th>*/}
        {/*        <th className="fixedTableHeader">Electric Meter Set</th>*/}
        {/*        <th className="icon fixedTableHeader"></th>*/}
        {/*      </tr>*/}
        {/*    </thead>*/}
        {/*    <tbody>*/}
        {/*      {*/}
        {/*        this.state.units.map((item: any) => (*/}
        {/*          <>{console.log(item)}*/}
        {/*            <UnitRow*/}
        {/*              key={item.unit?.uid}*/}
        {/*              id={item.unit?.uid}*/}
        {/*              onClick={this.editUnit}*/}
        {/*              name={item.unit?.name}*/}
        {/*              bedrooms={item.unit?.bedrooms}*/}
        {/*              bathrooms={item.unit?.bathrooms}*/}
        {/*              sqft={item.unit?.squareFeet}*/}
        {/*              meterSet={item.meterSet} />*/}
        {/*          </>*/}
        {/*        ))*/}
        {/*      }*/}
        {/*      <tr>*/}
        {/*        <td className="fixedTableCellFirst"></td>*/}
        {/*        <td className="fixedTableCell"></td>*/}
        {/*        <td className="fixedTableCell"></td>*/}
        {/*        <td className="fixedTableCell"></td>*/}
        {/*        <td className="fixedTableCell"></td>*/}
        {/*        <td className="fixedTableCell"><FontAwesomeIcon icon={faPlus} style={{ marginLeft: "30%", cursor: 'pointer', color: '#b89961' }} onClick={this.createNewUnit} /></td>*/}
        {/*      </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*</div>*/}
        {/*<div style={{ display: this.state.editingUnit ? "block" : "none" }}>*/}
        {/*  <h3>Unit Form - {this.state.currentUnit.unit.name} </h3>*/}
        {/*  <Row>*/}
        {/*    <Col>*/}
        {/*      <label>Name</label>*/}
        {/*      <br />*/}
        {/*      <input className="standard-input" type="text" name="name" value={this.state.currentUnit.unit.name} onChange={this.changeCurrentUnit} />*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*      <label>Bedrooms</label>*/}
        {/*      <br />*/}
        {/*      <input className="standard-input" type="number" name="bedrooms" value={this.state.currentUnit.unit.bedrooms} onChange={this.changeCurrentUnit} />*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*      <label>Bathrooms</label>*/}
        {/*      <br />*/}
        {/*      <input className="standard-input" type="number" name="bathrooms" value={this.state.currentUnit.unit.bathrooms} onChange={this.changeCurrentUnit} />*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*      <label>Square Feet</label>*/}
        {/*      <br />*/}
        {/*      <input className="standard-input" type="number" name="squareFeet" value={this.state.currentUnit.unit.squareFeet} onChange={this.changeCurrentUnit} />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*  <br />*/}
        {/*  <Row>*/}
        {/*    <Col>*/}
        {/*      <input type="button" value="Save Unit Settings" onClick={this.updateUnit} />*/}
        {/*      <input type="button" value="Cancel" onClick={this.cancelEditUnit} style={{ marginLeft: "25px" }} />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*  <hr />*/}
        {/*  <h3>Unit Meter Settings</h3>*/}
        {/*  <Row>*/}
        {/*    <Col>*/}
        {/*      <label>Meter Identifier</label>*/}
        {/*      <br />*/}
        {/*      <input className="standard-input" type="text" name="meter" value={this.state.currentUnit.meter}*/}
        {/*        onChange={this.changeMeter} />*/}
        {/*      <br />*/}
        {/*      <br />*/}
        {/*      <input className="standard-input" type="button" value="Save Meter Identifier" onClick={this.saveMeterId} />*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*      <label>Meter Number</label>*/}
        {/*      <br />*/}
        {/*      <input className="standard-input" type="text" name="meterNumber" value={this.state.currentUnit.meterNumber}*/}
        {/*        onChange={this.changeMeter} />*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*      <p>Meter Identifier is used to label how the automated collector pulls the data. For example in PAC you have Item Number & Meter Number, but item number is*/}
        {/*        used by the automated collector to pull the data. See the documentation here for adding Electric Meters [link]*/}
        {/*      </p>*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</div>*/}
      </div>
    )
  }
}
/*disabled={this.state.currentUnit.meterSet === "Yes" ? true : false}*/