import * as React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { DataStorageRequest } from "./DataStorageRequest";
import Select from "react-select";
import { reactSelectBasicStyle } from "../../style/select-constants";
import { SelectOptions } from "../../interfaces/CoreInterfaces";
import { pullDatabaseClasses } from "../../functions/fetchLinkedObjects";

export interface GeneratedPropertyData {
  name: string;
  factoryIgnore: boolean;
  builderIgnore: boolean;
}
export interface StringPropertyData extends GeneratedPropertyData {
  maxLength: number;
}

export interface ForeignKeyPropertyData extends GeneratedPropertyData {
  referenceClass: SelectOptions;
  unique: boolean;
  ignoreLoad: boolean;
}

export interface IntPropertyData extends GeneratedPropertyData {
  defaultValue: number;
}

export interface DoublePropertyData extends GeneratedPropertyData {
  roundingPrecision: number;
  defaultValue: number;
}

export interface DecimalPropertyData extends GeneratedPropertyData {
  isDecimal: true;
}


export interface BooleanPropertyData extends GeneratedPropertyData {
  defaultToTrue: boolean;
}

export interface EnumPropertyData extends GeneratedPropertyData {
  enumValues: string;
}

interface DataStoragePropertyModalProps {
  callback: (field: GeneratedPropertyData) => void;
}

interface DataStoragePropertyModalState {
  show: boolean;
  type: SelectOptions;
  classes: Array<SelectOptions>;
  data: GeneratedPropertyData;
}

export default class DataStoragePropertyModal extends React.Component<DataStoragePropertyModalProps, DataStoragePropertyModalState> {

  constructor(props: DataStoragePropertyModalProps) {
    super(props);
    this.state = {
      show: false,
      type: { label: "String", value: "0" },
      data: {
        name: "",
        factoryIgnore: false,
        builderIgnore: false
      },
      classes: []
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.changeStringName = this.changeStringName.bind(this);
    this.changeMaxLength = this.changeMaxLength.bind(this);
    this.changeFKName = this.changeFKName.bind(this);
    this.changeFKClass = this.changeFKClass.bind(this);
    this.changeIntegerName = this.changeIntegerName.bind(this);
    this.changeIntDefaultValue = this.changeIntDefaultValue.bind(this);
    this.changeDoubleDefaultValue = this.changeDoubleDefaultValue.bind(this);
    this.changeDoubleName = this.changeDoubleName.bind(this);
    this.changePrecision = this.changePrecision.bind(this);
    this.changeDefaultToTrue = this.changeDefaultToTrue.bind(this);
    this.changeIgnoreLoadFunction = this.changeIgnoreLoadFunction.bind(this);
    this.changeUnique = this.changeUnique.bind(this);
    this.changeType = this.changeType.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let classes = await pullDatabaseClasses();
    this.setState({ classes: classes });
  }

  show(): void {
    let data = this.setTypeState({ label: "String", value: "0" });
    this.setState({ show: true, data: data });
  }

  hide(): void {
    this.props.callback(this.state.data);
    this.setState({ show: false });
  }

  changeType(event: SelectOptions | null): void {
    if (event === null) { return; }
    let data = this.setTypeState(event);
    this.setState({ data: data, type: event });
  }

  //#region String Functions
  changeStringName(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: StringPropertyData = this.state.data as StringPropertyData;
    data.name = event.target.value;
    this.setState({ data: data });
  }

  changeMaxLength(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: StringPropertyData = this.state.data as StringPropertyData;
    data.maxLength = parseInt(event.target.value);
    this.setState({ data: data });
  }

  getStringSettings(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Name</h5>
          </Col>
          <Col>
            <input type="text" value={this.state.data.name} className="standard-input" onChange={this.changeStringName} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Max Length</h5>
          </Col>
          <Col>
            <input type="number" step="1" min="-1" value={(this.state.data as StringPropertyData).maxLength} className="standard-input" onChange={this.changeMaxLength} />
          </Col>
        </Row>
      </>)
  }
  //#endregion

  //#region Foreign Key Settings

  changeFKName(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: ForeignKeyPropertyData = this.state.data as ForeignKeyPropertyData;
    data.name = event.target.value;
    this.setState({ data: data });
  }

  changeFKClass(event: SelectOptions | null): void {
    if (event === null) { return; }
    let data: ForeignKeyPropertyData = this.state.data as ForeignKeyPropertyData;
    data.referenceClass = event;
    this.setState({ data: data });
  }

  changeUnique(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event === null) { return; }
    let data: ForeignKeyPropertyData = this.state.data as ForeignKeyPropertyData;
    data.unique = event.target.checked;
    this.setState({ data: data });
  }

  changeIgnoreLoadFunction(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event === null) { return; }
    let data: ForeignKeyPropertyData = this.state.data as ForeignKeyPropertyData;
    data.ignoreLoad = event.target.checked;
    this.setState({ data: data });
  }

  getForeignKeySettings(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Name</h5>
          </Col>
          <Col>
            <input type="text" value={(this.state.data as ForeignKeyPropertyData).name} className="standard-input" onChange={this.changeFKName} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Foreign Class</h5>
          </Col>
          <Col>
            <Select 
              options={this.state.classes}
              value={(this.state.data as ForeignKeyPropertyData).referenceClass}
              styles={reactSelectBasicStyle}
              onChange={this.changeFKClass}
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Unique</h5>
          </Col>
          <Col>
            <input type="checkbox" checked={(this.state.data as ForeignKeyPropertyData).unique} onChange={this.changeUnique}
              style={{ marginLeft: "5.5vw", marginTop: "1.3vh" }}
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Ignore Load Function</h5>
          </Col>
          <Col>
            <input type="checkbox" checked={(this.state.data as ForeignKeyPropertyData).ignoreLoad} onChange={this.changeIgnoreLoadFunction}
              style={{ marginLeft: "5.5vw", marginTop: "1.3vh" }}
            />
          </Col>
        </Row>
      </>)
  }
  //#endregion

  //#region Integer Functions
  changeIntegerName(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: IntPropertyData = this.state.data as IntPropertyData;
    data.name = event.target.value;
    this.setState({ data: data });
  }

  changeIntDefaultValue(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: IntPropertyData = this.state.data as IntPropertyData;
    data.defaultValue = parseInt(event.target.value);
    this.setState({ data: data });
  }

  getIntegerSettings(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Name</h5>
          </Col>
          <Col>
            <input type="text" value={this.state.data.name} className="standard-input" onChange={this.changeIntegerName} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Default</h5>
          </Col>
          <Col>
            <input type="number" step="1" min="-1" value={(this.state.data as IntPropertyData).defaultValue} className="standard-input" onChange={this.changeIntDefaultValue} />
          </Col>
        </Row>
      </>
    )
  }
  //#endregion

  //#region Double Functions

  changeDoubleName(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: DoublePropertyData = this.state.data as DoublePropertyData;
    data.name = event.target.value;
    this.setState({ data: data });
  }

  changePrecision(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: DoublePropertyData = this.state.data as DoublePropertyData;
    data.roundingPrecision = Math.max(-1, Math.min(8, parseInt(event.target.value)));
    this.setState({ data: data });
  }

  changeDoubleDefaultValue(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: DoublePropertyData = this.state.data as DoublePropertyData;
    data.defaultValue = parseInt(event.target.value);
    this.setState({ data: data });
  }

  getDoubleSettings(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Name</h5>
          </Col>
          <Col>
            <input type="text" value={this.state.data.name} className="standard-input" onChange={this.changeDoubleName} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Precision</h5>
          </Col>
          <Col>
            <input type="number" step="1" min="-1" value={(this.state.data as DoublePropertyData).roundingPrecision} className="standard-input" onChange={this.changePrecision} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Default</h5>
          </Col>
          <Col>
            <input type="number" step="1" min="-1" value={(this.state.data as DoublePropertyData).defaultValue} className="standard-input" onChange={this.changeDoubleDefaultValue} />
          </Col>
        </Row>
      </>
    )
  }
  //#endregion

  //#region Boolean Functions

  changeDefaultToTrue(event: React.ChangeEvent<HTMLInputElement>): void {
    let data: BooleanPropertyData = this.state.data as BooleanPropertyData;
    data.defaultToTrue = event.target.checked;
    this.setState({ data: data });
  }

  getBooleanSettings(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Name</h5>
          </Col>
          <Col>
            <input type="text" value={this.state.data.name} className="standard-input" onChange={this.changeStringName} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h5 style={{ marginTop: "7px" }}>Default Value</h5>
          </Col>
          <Col>
            <input type="checkbox" checked={(this.state.data as BooleanPropertyData).defaultToTrue} onChange={this.changeDefaultToTrue}
              style={{ marginLeft: "5.5vw", marginTop: "1.3vh" }}
            />
          </Col>
        </Row>
      </>
    )
  }

  //#endregion

  //#region Type Functions
  setTypeState(type: SelectOptions): GeneratedPropertyData {
    switch (type.value) {
      case "0":
        let _string: StringPropertyData = { name: "", factoryIgnore: false, builderIgnore: false, maxLength: -1 };
        return _string;
      case "1":
        let _fk: ForeignKeyPropertyData = { name: "", factoryIgnore: false, builderIgnore: false, referenceClass: { label: "Select Class", value: "" } , unique: false, ignoreLoad: false };
        return _fk;
      case "2":
        let _int: IntPropertyData = { name: "", factoryIgnore: false, builderIgnore: false, defaultValue: 0 };
        return _int;
      case "3":
        let _double: DoublePropertyData = { name: "", factoryIgnore: false, builderIgnore: false, roundingPrecision: -1, defaultValue: 0.0 };
        return _double;
      case "4":
        let _decimal: DecimalPropertyData = { name: "", factoryIgnore: false, builderIgnore: false, isDecimal: true };
        return _decimal;
      case "5":
        let _bool: BooleanPropertyData = { name: "", factoryIgnore: false, builderIgnore: false, defaultToTrue: false };
        return _bool;
    }
  }

  getTypeSettings(): JSX.Element {
    switch (this.state.type.value) {
      case "0":
        return this.getStringSettings();
      case "1":
        return this.getForeignKeySettings();
      case "2":
        return this.getIntegerSettings();
      case "3":
        return this.getDoubleSettings();
      case "4":
        return this.getBooleanSettings();
    }
  }
  //#endregion

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h4" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c", textAlign: "center" }}>
            Add DB Modal Property
          </ModalHeader>
        </div>
        <div key="modal-body" style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Type</h5>
              </Col>
              <Col>
                <Select
                  options={DataStorageRequest.TYPES}
                  value={this.state.type}
                  onChange={this.changeType}
                  styles={reactSelectBasicStyle}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            {
              this.getTypeSettings()
            }
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Finish" onClick={this.hide} className="standard-input"
              
            />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}