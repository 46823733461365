import * as React from 'react';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { Col, Row } from 'reactstrap';
import { FileEntryModal, WarningModal } from '../../CoreComponents/Modals';
import axios from 'axios';
import TransitionPlanViewWorkOrderModal from './TransitionPlanManagementWidget.ViewWorkOrderModal';

interface TransitionPlanManagementWidgetData {
  uid: string;
  transitionPlanUID: string;
  year: number;
  siteVisit: Date;
  expiration: Date;
}

interface TransitionPlanManagementWidgetProps {
  propertyUID: string;
}

interface TransitionPlanManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<TransitionPlanManagementWidgetData>;
}

export default class TransitionPlanManagementWidget extends React.Component<TransitionPlanManagementWidgetProps, TransitionPlanManagementWidgetState> {

  fileUpload: React.RefObject<FileEntryModal> = React.createRef<FileEntryModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  workOrderModal: React.RefObject<TransitionPlanViewWorkOrderModal> = React.createRef<TransitionPlanViewWorkOrderModal>();


  constructor(props: TransitionPlanManagementWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      data: []
    }
    this.showFileEntry = this.showFileEntry.bind(this);
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [];
    headers.push({ columnName: "Year", setting: true, filter: {} });
    headers.push({ columnName: "Site Visit", setting: true, filter: {} });
    headers.push({ columnName: "Expiration", setting: true, filter: {} });
    headers.push({ columnName: "", setting: true, filter: {} });

    this.setState({ headers: headers }, async () => await this.pullData());
  }

  async pullData(): Promise<void> {
    let response = await axios.get('./api/property-transition-plan-maps/property/' + this.props.propertyUID);
    this.setState({ data: response.data });
  }

  showFileEntry(): void {
    this.fileUpload.current.show("Upload Transition Plan", ".csv", this.callback);
  }

  async callback(): Promise<void> {
    let _file = this.fileUpload.current.state.file;
    let data = new FormData();
    data.append("file", _file);
    data.append("propertyUID", this.props.propertyUID);

    let response = await axios.post('./api/transition-plan', data);
    if (response.status !== 200) {
      //@ts-ignore
      this.warningModal.current.show("Error while Parsing the Transition Plan: " + response.response.data, "Transition Plan Import Error");
    }
    else {
      window.location.reload();
    }
  }


  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        <FileEntryModal ref={this.fileUpload} />
        <TransitionPlanViewWorkOrderModal ref={this.workOrderModal} />
        {RenderHeader("Transition Plans")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.year}</td>
                    <td className="fixedTableCell">{new Date(item.siteVisit).toLocaleDateString("en-CA")}</td>
                    <td className="fixedTableCell">{new Date(item.expiration).toLocaleDateString("en-CA")}</td>
                    <td className="fixedTableCell">
                      <input type="button" className="standard-input" value="View Work Orders" onClick={() => this.workOrderModal.current.show(item.transitionPlanUID)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Import Transition Plan" className="standard-input" onClick={this.showFileEntry} />
          </Col>
        </Row>
      </>
    )
  }
}