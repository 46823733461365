import * as React from 'react';
import { Col, Row } from 'reactstrap';
import * as Structs from '../Structs';
import { DrawRequestManager } from './DrawRequestManagement';
import { ChangeOrderManagement } from './ChangeOrderManagement';
import SubcontractorManager from '../ContractorComponents/SubcontractorManager';
import JobDashboardOldSystem from './JobDashboardOldSystem';

export default class ActiveJobManagementScreen extends React.Component<Structs.PaymentApplicationScreenProps, Structs.ManagementScreenState> {

  constructor(props: Structs.PaymentApplicationScreenProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const uid = params.get('uid');
    const currentTab = params.has('currentTab') ? parseInt(params.get('currentTab')) : 0;
    this.state = {
      currentTab: currentTab,
      uid: uid,
      subContractorRows: [],
    }
  }

  async componentDidMount(): Promise<void> {
    window.onpopstate = () => {
      window.location.reload();
    }
  }

  componentWillUnmount() {

  }

  getTab(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return this.state.uid && <JobDashboardOldSystem uid={this.state.uid} />
      case 1:
        return this.state.uid && <DrawRequestManager uid={this.state.uid} />
      case 2:
        return this.state.uid && <ChangeOrderManagement uid={this.state.uid} />
      case 3:
        return this.state.uid && <SubcontractorManager uid={this.state.uid} />
      default:
        Error("Invalid Tab Request");
    }
  }

  onClick(idx: number): void {
    window.history.pushState({}, document.title, "active-job-management/old-system?uid=" + this.state.uid + "&currentTab=" + idx);
    this.setState({ currentTab: idx });
  }
  
  render(): JSX.Element {
    return (
      <>
        <>
          <Row>
            <Col style={{ marginBottom: "10px" }}>
              <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Job Dashboard" onClick={() => this.onClick(0)} />
              <input type="button" className={this.state.currentTab === 1 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Pay Applications" onClick={() => this.onClick(1)} />
              <input type="button" className={this.state.currentTab === 2 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Change Orders" onClick={() => this.onClick(2)} />
              <input type="button" className={this.state.currentTab === 3 ? "tab-active" : "tab"} style={{ width: "25%" }} value="Subcontractor Invoicing" onClick={() => this.onClick(3)} />
            </Col>
          </Row>
          {
            this.getTab()
          }
        </>
      </>
    )
  }
}