import * as React from 'react';
import { CredentialResponse, GoogleLogin as NewLogin } from '@react-oauth/google';
import axios from 'axios';
import { AuthState, LoginUser } from '../../functions/authActions';
import { GetSettings } from '../../functions/StateManagement/StateStorageFunctions';


interface LoginProps {
  auth: any;
  login: (token: string) => void;
}

interface LoginState {
  redirectURL: string;
}

async function credentialResponse(response: CredentialResponse, redirect: string): Promise<void> {
  if (!("credential" in response)) {
    console.error("Unable to get credential from Google", response);
    return;
  }
  if (!response.credential) {
    console.error("Unable to get credential from Google", response);
    return;
  }
  const options = {
    "tokenId": response.credential,
  }
  let url = "/api/empire/auth/google";
  await axios.post(url, options).then(async (response) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
    response.data.lastLogin = new Date();
    LoginUser(response.data, redirect);
  });
}

const getNewLogin = (redirect: string): JSX.Element => {
  return (
    <NewLogin
      size="large"
      onSuccess={(r) => credentialResponse(r, redirect)}
      onError={() => console.error("Invalid Request being processed using the new Google Auth")}
    />
  )
}
export default class Login extends React.Component<LoginProps, LoginState> {

  constructor(props: LoginProps) {
    let params = new URLSearchParams(window.location.search); 

    super(props);
    this.state = {
      redirectURL: params.has('redirect') ? params.get('redirect') : ''
    }
  }

  render(): JSX.Element {
    var isAuth = GetSettings<AuthState>("userAuthenticationSettings")?.isAuthenticated ?? false;
    if (isAuth) { window.location.assign("/"); }
    return (<div style={{ position: 'absolute', top: "25%", left: "35%", height: "30%", width: "30%", border: "2px solid #e7ddca", borderRadius: "15px" }}>
        <h4 style={{ width: "100%", textAlign: "center", marginTop: "6vh" }}>Command Center Login</h4>
        <div style={{ position: "absolute", left: "10vw", top: "40%"}}>
          {getNewLogin(this.state.redirectURL)}
        </div>
      </div>
    )
  }
}
