import * as React from 'react';
import logos from '../../../graphics/all-logos.png';
import { pullProperties, pullStates } from '../../../functions/fetchLinkedObjects';
import { SelectOptions, SelectOptionsProperty } from '../../../interfaces/CoreInterfaces';
import { SavedFileImageData, generateImageGrid } from '../../../functions/imageTools';
import { GetSettings, SetSettingKey, SetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { StreetAddressSettings, TicketSubmissionSettings } from '../../../reducers/TicketTableSettings';
import { DarkLightMode } from './TicketSubmissionPage.constants';
import { getUserFirstName, getUserID, getUserLastName } from '../../../functions/authActions';
import { StatusModal } from '../../CoreComponents/Modals';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { FilterWithPropertyCode } from '../../../functions/selectTools';
import { pullEmployeeEmail } from '../../../functions/fetchObjectNameFunctions';
import { reactSelectBasicStyle } from '../../../style/select-constants';

interface SupplyRequestSubmissionData {
  itemName: string;
  quantity: number;
  link: string;
  estimatedCost: number;
  comments: string;
}

export interface SupplyRequestSubmissionPageProps {
  changeValues: (state: TicketSubmissionSettings) => void;
}

export interface SupplyRequestSubmissionPageState {
  ticketSubmissionState: TicketSubmissionSettings;
  failedSubmit: boolean;
  failedSubmitText: string;
  currentTab: number;
  submitted: boolean;
  properties: Array<SelectOptionsProperty>;
  propertySelect: SelectOptionsProperty;
  problemSummary: string;
  loading: boolean;
  submitDisabled: boolean;
  photos: Array<SavedFileImageData>;
  photoUIDs: Array<string>;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  items: Array<SupplyRequestSubmissionData>;
  locked: boolean;
  states: Array<SelectOptions>;
  state: SelectOptions;
  city: string;
  streetAddress: string;
  zipCode: string;
}

export default class OfficeSuppliesRequestPage extends React.Component<SupplyRequestSubmissionPageProps, SupplyRequestSubmissionPageState> {

  emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  ticketSettings: string = "ticketSubmissionSettings";
  addressSettings: string = "requestTicketSettings";

  constructor(props: SupplyRequestSubmissionPageProps) {
    super(props);
    let settings: TicketSubmissionSettings = GetSettings<TicketSubmissionSettings>(this.ticketSettings);
    if (settings === null) {
      settings = { firstName: "", lastName: "", email: "", phoneNumber: "", property: "" };
      SetSettings(this.ticketSettings, settings);
    }
    let address: StreetAddressSettings = GetSettings<StreetAddressSettings>(this.addressSettings);
    if (address === null) {
      address = { state: { label: "Select State", value: "" }, streetAddress: "", city: "", zipCode: "" }
      SetSettings(this.addressSettings, address);
    }
    this.state = {
      ticketSubmissionState: settings,
      failedSubmit: false,
      failedSubmitText: "Please fill all required fields before submitting. If there is a red border, it's not ready to submit.",
      currentTab: 0,
      submitted: false,
      properties: [],
      propertySelect: { label: "Select Property", value: "", code: "" },
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      problemSummary: "",
      loading: false,
      submitDisabled: false,
      photos: [],
      photoUIDs: [],
      items: [{
        itemName: "",
        quantity: 1,
        link: "",
        estimatedCost: 0,
        comments: ""
      }],
      locked: false,
      states: [],
      state: address.state,
      streetAddress: address.streetAddress,
      city: address.city,
      zipCode: address.zipCode
    }
    this.selectProperty = this.selectProperty.bind(this);
    this.changeProblemSummary = this.changeProblemSummary.bind(this);
    this.preSubmit = this.preSubmit.bind(this);
    this.submit = this.submit.bind(this);
    this.submitAnother = this.submitAnother.bind(this);
    this.addImages = this.addImages.bind(this);
    this.changeItemName = this.changeItemName.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.changeEstimatedCost = this.changeEstimatedCost.bind(this);
    this.changeLink = this.changeLink.bind(this);
    this.changeStreetAddress = this.changeStreetAddress.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changeState = this.changeState.bind(this);
    this.changeZipCode = this.changeZipCode.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const allSites: Array<SelectOptionsProperty> = await pullProperties();
    let firstName = await getUserFirstName();
    let lastName = await getUserLastName();
    let email = await pullEmployeeEmail(getUserID());
    let states = await pullStates();
    let propertySelect = { label: "Select Property", value: "", code: "" }
    if (this.state.ticketSubmissionState.property !== "") {
      propertySelect = allSites.filter(u => u.value === this.state.ticketSubmissionState.property)[0];
    }
    this.setState({
      firstName: firstName,
      lastName: lastName,
      email: email,
      properties: allSites,
      propertySelect: propertySelect,
      states: states
    });
  }

  selectProperty(event: SelectOptionsProperty | null): void {
    if (event === null) { return; }
    this.setState({ propertySelect: event })
    const ticketState = this.state.ticketSubmissionState;
    ticketState.property = event.value;
    SetSettingKey(this.ticketSettings, "property", event.value);
    this.setState({
      ticketSubmissionState: ticketState
    })
  }

  changeProblemSummary(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      problemSummary: event.target.value
    })
  }

  async addImages(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.statusModal.current.display("Uploading Photos, Please Wait.", "Please wait while all photos are uploaded.")
    const files = event.target.files;
    for (let i = 0; i < files.length; ++i) {
      let file = files.item(i);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      const image = await imageCompression(file, options);
      const formData = new FormData();
      formData.append("File", image);
      let response = await axios.post("/api/saved-files/image/ticket", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 202) {
        const imageID = response.data;
        this.state.photoUIDs.push(imageID)
        this.setState({
          loading: false
        })
      }
    }
    await this.getPhotos();
    this.statusModal.current.hide();
  }

  async getPhotos(): Promise<void> {
    const photos: Array<SavedFileImageData> = [];
    await this.state.photoUIDs.forEach(async (imgUID) => {
      let response = await axios.get('./api/saved-files/image/' + imgUID, { responseType: "blob" });
      if (response.status === 200) {
        const img = URL.createObjectURL(response.data);
        photos.push({
          img: img,
          imgUID: imgUID
        })
        this.setState({ photos: photos, loading: false })
      }
    })
  }

  async preSubmit(): Promise<void> {
    this.setState({ locked: true }, async () => await this.submit());
  }

  async submit(): Promise<void> {
    this.setState({ submitDisabled: true }, async () => await this.submitHelper());
  }

  async submitHelper(): Promise<void> {
    if (this.state.propertySelect.value === '' || this.state.ticketSubmissionState.property === "") {
      this.setState({
        failedSubmit: true,
        submitDisabled: false,
        failedSubmitText: "In order to submit a ticket you need to select a property. Please do so before proceeding."
      });
      return;
    }
    let summary =
      document.getElementById("address-information").innerHTML +
      document.getElementById('problemSummary').innerHTML;
    const data = {
      PropertyUID: this.state.ticketSubmissionState.property,
      EmployeeUID: await getUserID(),
      ProblemSummary: summary
    }
    this.statusModal.current.display("Submitting Ticket.", "Please wait for a minute while we submit the ticket & link the photos to it");
    let response = await axios.post("./api/tickets/auth/supplies-request", data, { validateStatus: () => true });
    if (response.status === 202) {
      this.setState({
        submitted: true,
        failedSubmit: true,
        failedSubmitText: ""
      });
      for (var photo in this.state.photos) {
        const data = {
          TicketUID: response.data,
          SavedFileUID: this.state.photos[photo].imgUID
        };
        await axios.post('./api/tech-ticket-photo-map', data)
      }
      this.statusModal.current.hide();
    }
    else {
      this.statusModal.current.hide();
      this.setState({
        failedSubmit: true,
        submitDisabled: false,
        failedSubmitText: "We are having trouble verifying your information. Please ensure your email is correct and you have selected your property"
      })
    }
  }

  submitAnother(): void {
    this.setState({
      submitted: false,
      submitDisabled: false
    })
  }

  changeItemName(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let ls = this.state.items;
    let item = ls[idx];
    item.itemName = event.target.value;
    ls[idx] = item;
    this.setState({ items: ls });
  }

  changeQuantity(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let ls = this.state.items;
    let item = ls[idx];
    item.quantity = parseInt(event.target.value);
    ls[idx] = item;
    this.setState({ items: ls });
  }

  changeEstimatedCost(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let ls = this.state.items;
    let item = ls[idx];
    item.estimatedCost = parseFloat(event.target.value);
    ls[idx] = item;
    this.setState({ items: ls });
  }

  changeLink(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let ls = this.state.items;
    let item = ls[idx];
    item.link = event.target.value;
    ls[idx] = item;
    this.setState({ items: ls });
  }

  changeComments(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let ls = this.state.items;
    let item = ls[idx];
    item.comments = event.target.value;
    ls[idx] = item;
    this.setState({ items: ls });
  }

  changeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
    let address: StreetAddressSettings = GetSettings<StreetAddressSettings>(this.addressSettings);
    address.streetAddress = event.target.value;
    SetSettings(this.addressSettings, address);
    this.setState({ streetAddress: event.target.value });
  }

  changeCity(event: React.ChangeEvent<HTMLInputElement>): void {
    let address: StreetAddressSettings = GetSettings<StreetAddressSettings>(this.addressSettings);
    address.city = event.target.value;
    SetSettings(this.addressSettings, address);
    this.setState({ city: event.target.value });
  }

  changeState(event: SelectOptions | null) {
    if (event === null) { return; }
    let address: StreetAddressSettings = GetSettings<StreetAddressSettings>(this.addressSettings);
    address.state = event;
    SetSettings(this.addressSettings, address);
    this.setState({ state: event });
  }

  changeZipCode(event: React.ChangeEvent<HTMLInputElement>): void {
    let address: StreetAddressSettings = GetSettings<StreetAddressSettings>(this.addressSettings);
    address.zipCode = event.target.value;
    SetSettings(this.addressSettings, address);
    this.setState({ zipCode: event.target.value });
  }


  generateTable(): Array<JSX.Element> {
    let out: Array<JSX.Element> = [];
    for (let i = 0; i < this.state.items.length; ++i) {
      out.push(
        <tr key={i}>
          <td className="supply-table-cell-first">
            {
              this.state.locked ?
                <h6>{this.state.items[i].itemName}</h6>
                :
                <input
                  type="text"
                  value={this.state.items[i].itemName}
                  onChange={(e) => this.changeItemName(e, i)}
                  className="standard-input"
                  placeholder="Item Name"
                />
            }
          </td>
          <td className="supply-table-cell">
            {
              this.state.locked ?
                <h6>{this.state.items[i].quantity}</h6>
                :
                <input
                  type="number"
                  value={this.state.items[i].quantity}
                  onChange={(e) => this.changeQuantity(e, i)}
                  className="standard-input"
                  placeholder="5"
                  style={{ textAlign: "right" }}
                />
            }
          </td>
          <td className="supply-table-cell">
            {this.state.locked ?
              <h6>{this.state.items[i].estimatedCost}</h6>
              :
              <input
                type="number"
                value={this.state.items[i].estimatedCost}
                onChange={(e) => this.changeEstimatedCost(e, i)}
                className="standard-input"
                placeholder="5"
                style={{ textAlign: "right" }}
              />
            }
          </td>
          <td className="supply-table-cell">
            {this.state.locked ?
              <h6>
                {this.state.items[i].link === "" ? <></> : <a href={this.state.items[i].link}>Link</a>}
              </h6>
              :
              <input
                type="text"
                value={this.state.items[i].link}
                onChange={(e) => this.changeLink(e, i)}
                className="standard-input"
                style={{ textAlign: "right" }}
              />
            }
          </td>
          <td className="supply-table-cell">
            {this.state.locked ?
              <h6>
                <a href={this.state.items[i].comments}></a>
              </h6>
              :
              <input
                type="text"
                value={this.state.items[i].comments}
                onChange={(e) => this.changeComments(e, i)}
                className="standard-input"
                style={{ textAlign: "right" }}
              />
            }
          </td>
        </tr>
      )
    }
    return out;
  }

  addItem(): void {
    let items = this.state.items;
    items.push({ itemName: "", quantity: 1, link: "", estimatedCost: 0, comments: "" });
    this.setState({ items: items });
  }

  render(): JSX.Element {
    return (
      <div className="ticket-submission-form">
        <StatusModal ref={this.statusModal} />
        <div style={{ marginLeft: "25vw", marginRight: "25vw", overflowX: "hidden", overflowY: "auto" }}>
          <Row>
            <Col>
              <h3 style={{ marginTop: "5vh" }} className="ticket-text-heading">Office Supplies Request</h3>
              <h4 className="ticket-text-heading required-header">
                {this.state.failedSubmit && this.state.failedSubmitText}
              </h4>
              <img src={logos} style={{ width: "100%", height: "8em", paddingTop: "2em" }} />
            </Col>
          </Row>
          <hr />
          {!this.state.submitted && <>
            <Row>
              <Col>
                <h4 className="ticket-text-title">Contact Information</h4>
                <h6 className="ticket-text">Please fill out the fields below so that we may be able to contact you</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="ticket-text">First Name</h5>
                <p className="ticket-text">{this.state.firstName}</p>
              </Col>
              <Col>
                <h5 className="ticket-text">Last Name</h5>
                <p className="ticket-text">{this.state.lastName}</p>
              </Col>
              <Col>
                <h5 className="ticket-text">Property</h5>
                <Select
                  value={this.state.propertySelect}
                  styles={DarkLightMode()}
                  options={this.state.properties}
                  onChange={this.selectProperty}
                  menuPlacement="bottom"
                  required={(this.state.propertySelect?.value ?? "") === ''}
                  filterOption={FilterWithPropertyCode}
                />
              </Col>
              <Col>
                <h5 className="ticket-text">Email</h5>
                <p className="ticket-text">{this.state.email}</p>
              </Col>
              <Col>
                <h5 className="ticket-text">Phone Number</h5>
                <p className="ticket-text">{this.state.phone}</p>
              </Col>

            </Row>
            <hr />
            {!this.state.submitDisabled ?
              <div>
                <Row>
                  <Col>
                    <label className="ticket-text">Street Address</label>
                    <br />
                    <input type="text" value={this.state.streetAddress} className="standard-input" onChange={this.changeStreetAddress} />
                  </Col>
                  <Col>
                    <label className="ticket-text">City</label>
                    <br />
                    <input type="text" value={this.state.city} className="standard-input" onChange={this.changeCity} />
                  </Col>
                  <Col>
                    <label className="ticket-text">State</label>
                    <br />
                    <Select
                      options={this.state.states}
                      value={this.state.state}
                      onChange={this.changeState}
                      styles={reactSelectBasicStyle}
                    />
                  </Col>
                  <Col>
                    <label className="ticket-text"> ZipCode</label>
                    <br />
                    <input type="text" value={this.state.zipCode} className="standard-input" onChange={this.changeZipCode} />
                  </Col>
                </Row>
              </div>
              :
              <div id="address-information">
                <Row>
                  <Col>
                    <label>Street Address</label>
                    <br />
                    <label>{this.state.streetAddress}</label>
                  </Col>
                  <Col>
                    <label>City</label>
                    <br />
                    <label>{this.state.city}</label>
                  </Col>
                  <Col>
                    <label>State</label>
                    <br />
                    <label>{this.state.state.label}</label>
                  </Col>
                  <Col>
                    <label>ZipCode</label>
                    <br />
                    <label>{this.state.zipCode}</label>
                  </Col>
                </Row>
                <hr />
              </div>
            }

            <Row>
              <Col>
                <h5 className="ticket-text">List of Supplies Requested</h5>
                <div style={{ padding: "3px", height: "25vh", overflowY: "auto" }} id="problemSummary">
                  <table className="fixedTable">
                    <thead>
                      <tr>
                        <th className="supply-table-header-first">Item Name</th>
                        <th className="supply-table-header" >Amount Requested</th>
                        <th className="supply-table-header" >Estimated Cost</th>
                        <th className="supply-table-header" >Link (if applicable)</th>
                        <th className="supply-table-header" >Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.generateTable()
                      }
                    </tbody>
                  </table>
                </div>
                <hr style={{ height: "0.15vh" }} />
                <Row>
                  <Col>
                  </Col>
                  <Col>
                  </Col>
                  <Col>
                  </Col>
                  <Col>
                    <input type="button" value="Add Another Item" className="standard-input" onClick={() => this.addItem()} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                {!this.state.submitDisabled && !this.state.loading && <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Submit Request" onClick={this.preSubmit} />}
              </Col>
              <Col>
                <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Change Account" onClick={() => window.location.assign("/logout?")} />
              </Col>
            </Row>
            <br />
            <br />
            {
              !this.state.loading && generateImageGrid(this.state.photos, 4)
            }
          </>
          }
          {
            this.state.submitted && <>
              <h3 className="ticket-text-heading" style={{ width: "100%", textAlign: "center", paddingTop: "2vh" }}>Success!</h3>
              <h5 style={{ width: "100%", textAlign: "center", paddingTop: "1vh" }}>Your support request has been submitted. You should receive a confirmation email soon, and we will be in contact shortly.</h5>
              <h5 style={{ width: "100%", textAlign: "center", paddingTop: "1vh" }}>You may now close this window.</h5>
              <h3 className="ticket-text-heading" style={{ width: "100%", textAlign: "center", paddingTop: "1vh", marginBottom: "3vh" }}>Thank you!</h3>
              <hr />
              <Row>
                <Col>
                  <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Submit Another Request" onClick={this.submitAnother} />
                </Col>
                <Col>
                  <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Return to Home" onClick={() => window.location.assign("/")} />
                </Col>
              </Row>
            </>
          }
        </div >
      </div >
    )
  }
}
