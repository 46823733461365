import * as React from 'react';
import { Col, Row } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';

interface GeneralContractorInsuranceAndBondProps {

}

interface GeneralContractorInsuranceAndBondState {
  bondAndSuretyHeaders: Array<FilterAndSettingField>;
  insuranceHeaders: Array<FilterAndSettingField>;
  totalHeaders: Array<FilterAndSettingField>;
  bonds: any;
}

export default class GeneralContractorInsuranceAndBond extends React.Component<GeneralContractorInsuranceAndBondProps, GeneralContractorInsuranceAndBondState> {

  constructor(props: GeneralContractorInsuranceAndBondProps) {
    super(props);
    this.state = {
      bondAndSuretyHeaders: this.generateBondAndSuretyPremiumHeaders(),
      insuranceHeaders: this.generateInsuranceHeaders(),
      totalHeaders: this.generateTotalHeaders(),
      bonds: [
        { description: "Construction", percent: 100, siteOne: 0, siteTwo: 0, siteThree: 0, siteFour: 0, siteFive: 0, total: 0 },
        { description: "General Conditions", percent: 100, siteOne: 0, siteTwo: 0, siteThree: 0, siteFour: 0, siteFive: 0, total: 0 },
        { description: "Contractor Overhead", percent: 100, siteOne: 0, siteTwo: 0, siteThree: 0, siteFour: 0, siteFive: 0, total: 0 },
        { description: "Contractor Profit", percent: 100, siteOne: 0, siteTwo: 0, siteThree: 0, siteFour: 0, siteFive: 0, total: 0 },
        { description: "Total Construction Contract", percent: 100, siteOne: 0, siteTwo: 0, siteThree: 0, siteFour: 0, siteFive: 0, total: 0 },
        { description: "Contractor Liability Insurance", percent: 100, siteOne: 0, siteTwo: 0, siteThree: 0, siteFour: 0, siteFive: 0, total: 0 },
        { description: "Total for Surety Premium Calc", percent: 100, siteOne: 0, siteTwo: 0, siteThree: 0, siteFour: 0, siteFive: 0, total: 0 },
      ]
    }
  }

  generateBondAndSuretyPremiumHeaders(): Array<FilterAndSettingField> {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "",
        setting: true,
        filter: {}
      },
      {
        columnName: "%",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 1",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 2",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 3",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 4",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total",
        setting: true,
        filter: {}
      },
    ]
    return headers;
  }

  generateInsuranceHeaders(): Array<FilterAndSettingField> {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Class Code",
        setting: true,
        filter: {}
      },
      {
        columnName: "Description",
        setting: true,
        filter: {}
      },
      {
        columnName: "Payroll",
        setting: true,
        filter: {}
      },
      {
        columnName: "Cost",
        setting: true,
        filter: {}
      },
      {
        columnName: "Premise",
        setting: true,
        filter: {}
      },
      {
        columnName: "Products",
        setting: true,
        filter: {}
      },
      {
        columnName: "Operations Premium",
        setting: true,
        filter: {}
      },
      {
        columnName: "Completed Ops Premium",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total Premium",
        setting: true,
        filter: {}
      },
    ]
    return headers;
  }

  generateTotalHeaders(): Array<FilterAndSettingField> {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Description",
        setting: true,
        filter: {}
      },
      {
        columnName: "%",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 1",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 2",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 3",
        setting: true,
        filter: {}
      },
      {
        columnName: "Site 4",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total",
        setting: true,
        filter: {}
      },
    ]
    return headers;
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <div>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.bondAndSuretyHeaders} icons={0} />
                </thead>
                <tbody>
                  {
                    this.state.bonds.map((item: any) => (
                      <tr>
                        <td className="fixedTableCellFirst">{item.description}</td>
                        <td className="fixedTableCell">{item.percent}%</td>
                        <td className="fixedTableCell">{item.siteOne}</td>
                        <td className="fixedTableCell">{item.siteTwo}</td>
                        <td className="fixedTableCell">{item.siteThree}</td>
                        <td className="fixedTableCell">{item.siteFour}</td>
                        <td className="fixedTableCell">{item.total}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </Col>
          <Col>
            <div>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.insuranceHeaders} icons={0} />
                </thead>
                <tbody>

                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <div>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.totalHeaders} icons={0} />
                </thead>
              </table>
            </div>
          </Col>
          <Col>
          </Col>
        </Row>

      </>
    )
  }
}