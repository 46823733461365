import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldSelect } from '../CoreComponents/DataEntryModal';
import { pullAssetManagement, pullTechSupport } from '../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../interfaces/CoreInterfaces';

interface PropertyOffboardingAssignment {
  uid: string,
  helpdesk: SelectOptions;
  networkAdmin: SelectOptions;
  assetManagement: SelectOptions;
}

interface PropertyOffboardingConfigurationModalState {
  loadedAssignments: PropertyOffboardingAssignment;
  tech: Array<SelectOptions>;
}

export default class PropertyOffboardingConfigurationModal extends React.Component<{}, PropertyOffboardingConfigurationModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      loadedAssignments: {
        uid: "",
        helpdesk: { label: "Select Helpdesk", value: "" },
        networkAdmin: { label: "Select Network Admin", value: "" },
        assetManagement: { label: "Select Asset Management", value: "" },

      },
      tech: []
    }
  }

  async componentDidMount(): Promise<void> {
    let uid = "" //await axios.get('./api/property-offboard-configuration')
    let tech = await pullTechSupport();
    let asm = await pullAssetManagement();
    this.setState({ tech: tech });
  }

  show(): void {
    this.generateData();
  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "helpdeskAdminUID", name: "Helpdesk Admin", options: this.state.tech, value: this.state.loadedAssignments.helpdesk, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "networkAdminUID", name: "Network Admin", options: this.state.tech, value: this.state.loadedAssignments.networkAdmin, isMulti: false } as DataEntryModalFieldSelect);
    data.push({ key: "assetManagementUID", name: "Asset Management", options: this.state.tech, value: this.state.loadedAssignments.networkAdmin, isMulti: false } as DataEntryModalFieldSelect)
    data.push({ key: "networkAdminUID", name: "Network Admin", options: this.state.tech, value: this.state.loadedAssignments.networkAdmin, isMulti: false } as DataEntryModalFieldSelect)
    this.entryModal.current.update(data);
    this.entryModal.current.show("Property Offboarding Assignments", this.state.loadedAssignments.uid, undefined);
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={null} />
      </>
    )
  }
}