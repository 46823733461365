import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import axios from 'axios';

export default class TransitionPlanCategoryModal extends React.Component<{}, { uid: string, name: string}> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      uid: "",
      name: ""
    }
  }

  async show(uid: string = ""): Promise<void> {
    if (uid !== "") {
      let response = await axios.get('./api/transition-plan-categories/' + uid);
      this.setState({ name: response.data.label });
    }
    this.setState({ uid: uid }, async () => await this.generateData());
  }

  async componentDidMount(): Promise<void> {

  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "name", name: "Name", patchUrl: undefined, value: this.state.name, type: "text", placeholder: "Category Name" } as DataEntryModalFieldText);
    this.entryModal.current.update(data);
    this.entryModal.current.show("", this.state.uid, "./api/transition-plan-categories");
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} />
      </>
    )
  }

}