import * as React from 'react';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { pullComputerConfigurations } from '../../functions/fetchLinkedObjects';
import { ComputerTrackingSummary } from './ComputerTrackingManagementWidget';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldCheckbox, DataEntryModalFieldSelect, DataEntryModalFieldText } from '../CoreComponents/DataEntryModal';

interface ComputerTrackingModalProps {
  callback: (edit: boolean) => void;
}

interface ComputerTrackingModalState {
  show: boolean;
  uid: string;
  computerName: string;
  configs: Array<SelectOptions>;
  configuration: SelectOptions;
  operatingSystemKey: string;
  cost: number;
  isl: boolean;
  gravityZone: boolean;
  patch: boolean;
}

export default class ComputerTrackingModal extends React.Component<ComputerTrackingModalProps, ComputerTrackingModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: ComputerTrackingModalProps) {
    super(props);

    this.state = {
      show: false,
      uid: "",
      computerName: "",
      operatingSystemKey: "",
      cost: 0.0,
      isl: false,
      gravityZone: false,
      configuration: { label: "Select Config", value: "" },
      configs: [],
      patch: false
    }
    this.hide = this.hide.bind(this);
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let configs = await pullComputerConfigurations();
    this.setState({
      configs: configs
    })
  }

  show(edit: ComputerTrackingSummary = null): void {
    if (edit !== null) {
      this.setState({
        show: true,
        patch: true,
        uid: edit.uid,
        computerName: edit.computerName,
        configuration: edit.config,
        operatingSystemKey: edit.operatingSystemKey,
        cost: edit.costAtPurchase,
        isl: edit.islRegistered,
        gravityZone: edit.gravityZoneInstalled
      }, () => this.generateData());
    }
    else {
      this.setState({
        show: true,
        patch: false,
        uid: "",
        computerName: "",
        configuration: { label: "Select Config", value: "" },
        operatingSystemKey: "",
        cost: 0.0,
        isl: false,
        gravityZone: false
      }, () => this.generateData());
    }
    
  }

  hide(): void {
    this.setState({
      show: false
    })
  }

  generateData(): void {
    let data: Array<DataEntryModalField> = [];
    let text: DataEntryModalFieldText = {
      key: "computerName", name: "Name", patchUrl: './api/computer-tracking/computer-name', type: "text", placeholder: "Name", value: this.state.computerName
    }
    data.push(text);
    let select: DataEntryModalFieldSelect = {
      key: "computerConfigurationTrackingUID", name: "Configuration", patchUrl: './api/computer-tracking/computer-config', isMulti: false, value: this.state.configuration, options: this.state.configs
    }
    data.push(select);
    text = { key: 'operatingSystemKey', name: 'OS Key', patchUrl: './api/computer-tracking/operating-system-key', type: "text", placeholder: "Key", value: this.state.operatingSystemKey };
    data.push(text);
    text = { key: 'costAtPurchase', name: 'Cost', patchUrl: './api/computer-tracking/cost', type: "number", placeholder: "$0.00", value: this.state.cost };
    data.push(text);
    let check: DataEntryModalFieldCheckbox = { key: 'islRegistered', name: "ISL", patchUrl: './api/computer-tracking/isl', checkbox: this.state.isl };
    data.push(check);
    check = { key: 'gravityZoneInstalled', name: "Gravity Zone", patchUrl: './api/computer-tracking/gravity-zone', checkbox: this.state.gravityZone };
    data.push(check);
    this.entryModal.current.show("Computer Management", this.state.uid, './api/computer-tracking');
    this.entryModal.current.update(data);
  }
  callback(success: boolean): void {
    this.props.callback(success);
  }

  render(): JSX.Element {
    return (
      <DataEntryModal ref={this.entryModal} callback={this.callback} />
    )
  }
}
