import * as React from 'react';
import axios from "axios";
import imageCompression from "browser-image-compression";
import { Col, Modal, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faTimes } from '@fortawesome/free-solid-svg-icons';

export interface SavedFileImageData {
  img: string;
  imgUID: string;
}

export class DisplayImageModal extends React.Component<{}, { showing: boolean, img: string }>{

  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      img: ""
    }
    this.close = this.close.bind(this);
  }

  show(src: string): void {
    this.setState({
      showing: true,
      img: src
    })
  }

  close(): void {
    this.setState({
      showing: false,
      img: ""
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderBottom: "0px solid #15405c", borderRadius: "25%", marginTop: "12vh", paddingLeft: "0px", width: "80vw" }} size="xl" >
        <FontAwesomeIcon icon={faTimes} style={{ position: 'sticky', left: "77vw", top: '1vh', color: "#222" }} onClick={this.close} size="2x" />
        <img className="standard-input" style={{ border: "solid", height: "70vh", marginLeft: "0px", paddingLeft: "0px", marginTop: "-5vh" }} src={this.state.img} />
      </Modal>
    )
  }
}

export function generateImageGrid(importImageArray: Array<SavedFileImageData>, numCols: number, modalTrigger?: (src: string) => void, deleteImage?: (uid: string) => void): Array<JSX.Element> {
  const retObj: Array<JSX.Element> = [];
  const photos: Array<any> = [];

  importImageArray.map((item: SavedFileImageData) => {
    
    photos.push(
      <Col key={item.imgUID} style={{ paddingLeft: "0.6vw", paddingRight: "0.6vw" }}>
        <FontAwesomeIcon icon={faTimes} style={{ position: "relative", zIndex: "6", color: "#222", marginLeft: "85%" }} onClick={() => deleteImage && deleteImage(item.imgUID)} size="2x" />
        <img className="standard-input" style={{ zIndex: "5", border: "solid", height: "20vh", cursor: "pointer", paddingLeft: "0px", marginTop: "-5vh", marginBottom: "2vh" }} src={item.img} onClick={modalTrigger ? () => modalTrigger(item.img) : null} />
      </Col>)
  })

  const numRows = photos.length <= numCols ? 1 : Math.floor(photos.length / numCols) + 1
  const gridAdd = numCols - (photos.length % numCols)
  for (var i = 0; i < gridAdd; i++) {
    photos.push(<Col></Col>)
  }

  for (var rowCount = 0; rowCount < numRows; ++rowCount) {
    retObj.push(
      <div>
        <Row>
          {
            photos.map((photo, idx) => {
              if (idx >= (rowCount * numCols + (idx % numCols)) && idx < ((rowCount + 1) * numCols + (idx % numCols))) {
                return photo
              }
            })
          }
        </Row>
      </div>
    )
  }
  return retObj;
}


export async function addImages(event: React.ChangeEvent<HTMLInputElement>, filter: boolean = false): Promise<Array<SavedFileImageData>> {
  const files = event.target.files;
  let photos: Array<SavedFileImageData> = [];
  for (let i = 0; i < files.length; ++i) {
    let file = files.item(i);
    let ext = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
    if (filter && (ext != 'jpeg' && ext != "png" && ext != 'jpg' && ext != "jfif")) {
      continue;
    }
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    const image = await imageCompression(file, options);
    const formData = new FormData();
    formData.append("File", image);
    var response = await axios.post("/api/saved-files/image/request-for-proposal", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    const data = {
      img: URL.createObjectURL(file),
      imgUID: response.data
    }
    photos.push(data);
  }
  return photos;
}
