import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DatePicker } from '../../CoreComponents/DateComponents';

export interface MicrosoftBillingData {
  billingDate: Date;
  businessStandardCost: number;
  appsBusinessCost: number;
  csvData: File;
}

interface MicrosoftBillingProcessModalProps {
  callback(data: MicrosoftBillingData): void;

}

interface MicrosoftBillingProcessModalState {
  show: boolean;
  data: MicrosoftBillingData;

}

export default class MicrosoftBillingProcessingModal extends React.Component<MicrosoftBillingProcessModalProps, MicrosoftBillingProcessModalState> {

  constructor(props: MicrosoftBillingProcessModalProps) {
    super(props);
    this.state = {
      show: false,
      data: {
        billingDate: new Date(),
        businessStandardCost: 0,
        appsBusinessCost: 0,
        csvData: null
      }
    }
    this.show = this.show.bind(this);
    this.changeBillingDate = this.changeBillingDate.bind(this);
    this.changeBusinessStandard = this.changeBusinessStandard.bind(this);
    this.changeAppsBusiness = this.changeAppsBusiness.bind(this);
    this.changeFile = this.changeFile.bind(this);
  }

  show(): void {
    this.setState({ show: true });
  }

  changeBillingDate(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.billingDate = event.target.valueAsDate;
    this.setState({ data: data });
  }

  changeBusinessStandard(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.businessStandardCost = parseFloat(event.target.value);
    this.setState({ data: data });
  }

  changeAppsBusiness(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.appsBusinessCost = parseFloat(event.target.value);
    this.setState({ data: data });
  }

  changeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.csvData = event.target.files[0];
    this.setState({ data: data });
  }

  submit(): void {
    this.setState({ show: false });
    this.props.callback(this.state.data);
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ show: false })} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Microsoft Billing Processing
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Billing Date</h5>
              </Col>
              <Col>
                <DatePicker value={this.state.data.billingDate} onChange={this.changeBillingDate} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Business Standard Cost</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.data.businessStandardCost} onChange={this.changeBusinessStandard} className="standard-input" />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Apps for Business Cost</h5>
              </Col>
              <Col>
                <input type="number" value={this.state.data.appsBusinessCost} onChange={this.changeAppsBusiness} className="standard-input" />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Microsoft CSV</h5>
              </Col>
              <Col>
                <input type="file" className="standard-input" style={{ paddingTop: "0.5vh" }} accept=".csv"
                  onChange={this.changeFile}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Process" className="standard-input" onClick={() => this.submit()} />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}