import * as React from 'react'
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { NewCNAModalProps, NewCNAModalState } from './CNAActiveTableWidget.NewCNAModal.constasts';
import { SelectCourseOptions } from '../../../interfaces/CoreInterfaces';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../../functions/selectTools';
import { pullExternalCompaniesVendorType } from '../../../functions/fetchLinkedObjects';


export default class NewCNAModal extends React.Component<NewCNAModalProps, NewCNAModalState>{

  constructor(props: NewCNAModalProps) {
    super(props);
    this.state = {
      show: false,
      title: "",
      providers: [],
      projectName: "",
      propertyUID: "",
      ownerUID: "",
      provider: { label: "Please Select Provider", value: "" },
      callback: undefined
    }
    this.show = this.show.bind(this);
    this.callback = this.callback.bind(this);
    this.updateName = this.updateName.bind(this);
    this.changeProvider = this.changeProvider.bind(this);
  }

  async show(title: string, propertyUID: string, ownerUID: string, callback: (projectName: string, provider: string, propertyUID: string, ownerUID: string) => void) {
    const providers = await pullExternalCompaniesVendorType();

    this.setState({
      title: title,
      show: true,
      providers: providers,
      projectName: "",
      provider: { label: "Please Select Provider", value: "" },
      propertyUID: propertyUID,
      ownerUID: ownerUID,
      callback: callback
    })
  }

  callback(): void {
    this.setState({
      show: false
    });
    if (this.state.callback) {
      this.state.callback(
        this.state.projectName,
        this.state.provider.value,
        this.state.propertyUID,
        this.state.ownerUID
      );
    }
  }

  updateName(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    this.setState({ projectName: value })
  }

  changeProvider(event: SelectCourseOptions | null): void {
    if (event === null) { return }
    this.setState({ provider: event })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h4" style={{ border: "0px solid #15405c", color: "#15405c" }}>
            {this.state.title}
          </ModalHeader>
        </div>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          <Row>
            <Col>Project Name:</Col>
            <Col>
              <input type="text" className="standard-input" value={this.state.projectName} onChange={this.updateName} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Provider</Col>
            <Col>
              <Select
                options={this.state.providers}
                value={this.state.provider}
                styles={reactSelectBasicStyle}
                onChange={this.changeProvider}
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <hr />
          <input type="button" value="Submit" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
            disabled={this.state.projectName === "" || this.state.provider.value === ""}
          />
        </ModalBody>
      </Modal>

    )
  }

}