import TicketTableManager from "./TicketWork/TicketTableManager";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { TicketPageManagerProps, TicketPageManagerState } from "./TicketPageManager.constants";
import TaskCreationModal from "./TicketWork/TaskCreationModal";
import Select from "react-select";
import { reactSelectBasicStyle } from "../../style/select-constants";
import { SelectOptions } from "../../interfaces/CoreInterfaces";
import ProjectTableManager from "./TicketWork/ProjectTableManager";
import DocumentationTableManager from "./TicketWork/DocumentationTableManager";

const TicketPageManagerTableOptions: Array<SelectOptions> = [
  { label: "Tickets", value: "0" },
  { label: "Projects", value: "1" },
  { label: "Documentation", value: "2"}
]

export class TicketPageManager extends React.Component<TicketPageManagerProps, TicketPageManagerState> {

  taskCreationModal: React.RefObject<TaskCreationModal> = React.createRef<TaskCreationModal>();
  tableManager: React.RefObject<TicketTableManager> = React.createRef<TicketTableManager>();

  constructor(props: TicketPageManagerProps) {
    super(props);
    let params = new URLSearchParams(window.location.search); 
    this.state = {
      table: TicketPageManagerTableOptions[params.has('table') ? parseInt(params.get('table')) : 0]
    }
    this.changeTable = this.changeTable.bind(this);
  }

  getSwitch(): JSX.Element {
    switch (this.state.table.value) {
      case '0':
        return <TicketTableManager ref={this.tableManager} /> 
      case '1':
        return <ProjectTableManager />
      case '2':
        return <DocumentationTableManager />
      default:
        console.error("This isn't supposed to happen");
    }
  }

  changeTable(event: SelectOptions | null) {
    let params = new URLSearchParams(window.location.search)
    params.set('table', event.value);
    window.location.search = params.toString();
    this.setState({ table: event });
  }

  render(): JSX.Element {
    return (
      <>
        <TaskCreationModal ref={this.taskCreationModal} />
        {this.getSwitch()}
        <Row>
          <Col>
            <input type="button" className="standard-input" value="Create Task" style={{ width: "50%", height: "5vh", marginBottom: "1vh" }} onClick={() => this.taskCreationModal.current.show()} />
          </Col>
          <Col>
            <Select
              styles={reactSelectBasicStyle}
              options={TicketPageManagerTableOptions}
              value={this.state.table}
              onChange={this.changeTable}
              menuPlacement="top"
            />
          </Col>
          <Col>
            <input type="button" className="standard-input" value="Refresh Tickets" style={{ width: "50%", marginLeft: "50%", height: "5vh", marginBottom: "1vh" }} onClick={() => this.tableManager.current.refresh()} />
          </Col>
        </Row>
      </>
    )
  }
}