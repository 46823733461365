import axios from 'axios';
import * as React from 'react';
import ReactToPrint from 'react-to-print';
import { Col, Row } from 'reactstrap';
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';
import { addMonths, dateDiffInDays, dateDiffInMonths } from '../../../functions/dateFunctions';
import { pullEntitiesWithIndividuals } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import { AmortizationLineItem, AmortizationMap, AmortizationSchedule, CalculationMethods, CompoundingTypes } from '../Structs';
import AmortizationLineItemEditModal from './AmortizationLineItemEditModal';
import { LineItemRow } from './AmortizationLineItems';
import { calculateMonthlyPayment, calculateTotalDailyInterest, calculateWithoutPrevious, calculateWithPrevious } from './CalculateFunctions';
import NewAmortizationLineItemModal from './NewAmortizationLineItemModal';

interface AmortizationComponentManagerProps {

}

export interface AmortizationComponentManagerState {
  scheduleUID: string;
  entities: Array<SelectOptions>;
  message: string;
  showFields: boolean;
  monthlyHeaders: Array<FilterAndSettingField>;
  principleHeaders: Array<FilterAndSettingField>;
  rows: Array<AmortizationLineItem>;
  startingInterestRate: number;
  currentInterestRate: number;
  term: number;
  balloon: number;
  lender: SelectOptions;
  borrower: SelectOptions;
  principalCap: number;
  title: string;
  ignorePrincipal: boolean;
  display: Array<AmortizationLineItem>;
  loanStartDate: Date;
  compoundType: SelectOptions;
  calculationMethod: SelectOptions;
  isAdding: boolean;
  newRate: boolean;
  editableIndex: number;
  canEditRate: boolean;
  name: string;
}

export default class AmortizationComponentManager extends React.Component<AmortizationComponentManagerProps, AmortizationComponentManagerState> {

  printWrapper = React.createRef<any>();
  modalRef = React.createRef<WarningModal>();
  statusRef = React.createRef<StatusModal>();
  editLine = React.createRef<AmortizationLineItemEditModal>();
  newAmortizationLineItemModal = React.createRef<NewAmortizationLineItemModal>();

  constructor(props: AmortizationComponentManagerProps) {
    const params = new URLSearchParams(window.location.search);

    super(props);
    this.state = {
      scheduleUID: params.get('uid'),
      entities: [],
      message: "",
      showFields: true,
      monthlyHeaders: this.generateMonthlyHeaders(),
      principleHeaders: this.generatePrincipleHeaders(),
      rows: [],
      startingInterestRate: 0,
      currentInterestRate: 0,
      term: 0,
      balloon: 0,
      lender: { label: "Load Error", value: "" },
      borrower: { label: "Load Error", value: "" },
      principalCap: 0,
      title: "",
      ignorePrincipal: false,
      display: [],
      loanStartDate: new Date("1977/05/25"),
      compoundType: { label: "Error", value: "" },
      calculationMethod: { label: "Error", value: "" },
      isAdding: false,
      newRate: false,
      editableIndex: -1,
      canEditRate: false,
      name: ""
    }
    this.loadSchedule = this.loadSchedule.bind(this);
    this.saveNewRow = this.saveNewRow.bind(this);
    this.update = this.update.bind(this);
    this.updateRows = this.updateRows.bind(this);
    this.runTermLength = this.runTermLength.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.changePrincipal = this.changePrincipal.bind(this);
    this.savePrincipal = this.savePrincipal.bind(this);
    this.changeTerm = this.changeTerm.bind(this);
    this.changeBalloon = this.changeBalloon.bind(this);
    this.export = this.export.bind(this);
    //this.changeRate = this.changeRate.bind(this);
    //this.cancel = this.cancel.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const entities = await pullEntitiesWithIndividuals();
    this.setState({
      entities: entities
    }, this.loadSchedule)
  }

  generateMonthlyHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "Days",
        setting: true,
        filter: {}
      },
      {
        columnName: "Advance",
        setting: true,
        filter: {}
      },
      {
        columnName: "Principal Balance",
        setting: true,
        filter: {}
      },
      {
        columnName: "Caluclated Payment",
        setting: true,
        filter: {}
      },
      {
        columnName: "Actual Payment",
        setting: true,
        filter: {}
      },
      {
        columnName: "Principal Payment",
        setting: true,
        filter: {}
      },
      {
        columnName: "Interest Payment",
        setting: true,
        filter: {}
      },
      {
        columnName: "Interest Accumulated",
        setting: true,
        filter: {}
      },
      {
        columnName: "Interest Balance",
        setting: true,
        filter: {}
      },
      {
        columnName: "Ending Principal Balance",
        setting: true,
        filter: {}
      }
    ]
  }

  generatePrincipleHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "Days",
        setting: true,
        filter: {}
      },
      {
        columnName: "Advance",
        setting: true,
        filter: {}
      },
      {
        columnName: "Principal Balance",
        setting: true,
        filter: {}
      },
      {
        columnName: "Actual Payment",
        setting: true,
        filter: {}
      },
      {
        columnName: "Principal Payment",
        setting: true,
        filter: {}
      },
      {
        columnName: "Interest Payment",
        setting: true,
        filter: {}
      },
      {
        columnName: "Interest Accumulated",
        setting: true,
        filter: {}
      },
      {
        columnName: "Interest Balance",
        setting: true,
        filter: {}
      },
      {
        columnName: "Ending Principal Balance",
        setting: true,
        filter: {}
      }
    ]
  }

  async loadSchedule(): Promise<void> {
    this.statusRef.current.display("Loading", "Please wait");
    if (this.state.scheduleUID !== '') {
      const response = await axios.get('./api/entity-amortization-schedule/' + this.state.scheduleUID);

      const rows: Array<AmortizationLineItem> = [];
      const data: AmortizationSchedule = response.data.schedule;
      const map: AmortizationMap = response.data.map;
      const lender: SelectOptions = this.state.entities.filter(obj => obj.value === map.lenderUID)[0];
      const borrower: SelectOptions = this.state.entities.filter(obj => obj.value === map.borrowerUID)[0];

      const lineResponse = await axios.get('./api/entity-amortization-schedule-line-items/' + this.state.scheduleUID)

      lineResponse.data.forEach((item: any, idx: number) => {
        rows.push({
          uid: item.uid,
          date: new Date(item.date),
          days: item.days,
          interestAccumulated: item.interestAccumulated,
          advance: item.advance,
          i_payment: item.interestPayment,
          i_balance: 0,
          p_payment: item.principalPayment,
          p_balance: 0,
          monthlyPayment: 0,
          isRateChange: item.isRateChange,
          newInterest: item.rateChange,
          previous: undefined,
          index: idx
        })
      })

      this.setState({
        rows: rows,
        name: data.loanTitle,
        compoundType: CompoundingTypes.filter(u => u.value === data.compoundingType.toString())[0],
        calculationMethod: CalculationMethods.filter(u => u.value === data.interestMethod.toString())[0],
        startingInterestRate: data.startingInterestRate,
        currentInterestRate: data.startingInterestRate,
        term: data.term,
        balloon: data.balloonTerm,
        lender: lender,
        borrower: borrower,
        principalCap: data.principalLimit,
        title: data.loanTitle,
        ignorePrincipal: data.ignorePrincipal
      }, this.update)
    }

    this.statusRef.current.hide();
  }

  async update(): Promise<void> {
    const rows = this.state.rows;
    if (rows.length === 0) {
      return;
    }
    rows.sort((a: AmortizationLineItem, b: AmortizationLineItem) => {
      return a.date.getTime() - b.date.getTime();
    });

    let rateChanged = false;
    let newRate = this.state.startingInterestRate;

    rows.forEach((item) => {
      if (item.isRateChange) {
        rateChanged = true;
        newRate = item.newInterest;
      }

      if (rateChanged) {
        item.newInterest = newRate
      }
    })

    const newRows: Array<AmortizationLineItem> = await this.updateRows(rows);

    this.setState({
      display: newRows
    })
  }

  async updateRows(rows: Array<AmortizationLineItem>): Promise<Array<AmortizationLineItem>> {
    let previous: AmortizationLineItem = undefined;
    let currentInterest = this.state.currentInterestRate;
    let output: Array<AmortizationLineItem> = [];
    for (let i = 0; i < rows.length; ++i) {
      if (i === 0) {
        this.setState({
          loanStartDate: rows[i].date
        })
      }
      const current: AmortizationLineItem = rows[i];
      let data;
      let term = this.state.term;
      if (this.state.balloon > 0 && this.state.balloon < 100) {
        term = this.state.balloon;
      }
      if (previous !== undefined) {
        data = calculateWithPrevious(previous, current.date, currentInterest, current.advance, current.p_payment, current.i_payment, parseInt(this.state.compoundType.value), parseInt(this.state.calculationMethod.value));
        current.p_balance = data.p_balance;
        const months = ((term * 12) - dateDiffInMonths(rows[0].date, current.date)) / 12;
        current.monthlyPayment = calculateMonthlyPayment(current.p_balance, months, currentInterest);
      }
      else {
        data = calculateWithoutPrevious(current.advance, currentInterest, current.i_payment, parseInt(this.state.calculationMethod.value));
        current.p_balance = current.advance;
        current.monthlyPayment = calculateMonthlyPayment(current.p_balance, term, currentInterest);
      }
      current.days = data.days;
      current.interestAccumulated = data.interest;
      current.i_balance = data.i_balance;

      output.push(current);
      if (current.isRateChange) {
        currentInterest = current.newInterest;
      }
      previous = current;
    }

    if (this.state.term > 0 && this.state.term < 100) {
      output = await this.runTermLength(output);
    }
    return output;
  }

  async runTermLength(rows: Array<AmortizationLineItem>): Promise<Array<AmortizationLineItem>> {
    const output = rows;
    let previous: AmortizationLineItem = undefined;
    const currentInterest = this.state.currentInterestRate;
    let months = (this.state.term * 12);
    const start = this.state.rows[0];
    const adjust: AmortizationLineItem = this.state.rows[this.state.rows.length - 1];
    months -= dateDiffInMonths(start.date, adjust.date);
    let currentMonth = 1;
    previous = adjust;
    const currentRate = currentInterest;

    while (months > 0) {
      const nextMonth = addMonths(new Date(adjust.date), currentMonth);
      const daysInMonth = dateDiffInDays(previous.date, nextMonth);
      const newInterest = calculateTotalDailyInterest(previous.p_balance, previous.i_balance, currentRate, previous.date, daysInMonth, parseInt(this.state.compoundType.value), parseInt(this.state.calculationMethod.value));
      let balance = previous.p_balance - (previous.monthlyPayment - newInterest);



      if (this.state.ignorePrincipal) {
        let interest = calculateTotalDailyInterest(previous.p_balance, previous.i_balance, currentRate, previous.date, daysInMonth, parseInt(this.state.compoundType.value), parseInt(this.state.calculationMethod.value));

        const data = {
          AmortizationScheduleUID: this.state.scheduleUID,
          Date: nextMonth,
          Days: daysInMonth,
          InterestAccumulated: interest,
          Advance: 0,
          InterestPayment: 0,
          PrincipalPayment: 0,
          IsRateChange: false,
          RateChange: 0
        }

        const response = await axios.post('./api/entity-amortization-schedule-line-items', data)

        const newRow: AmortizationLineItem = {
          uid: response.data.uid,
          date: nextMonth,
          days: daysInMonth,
          interestAccumulated: interest,
          advance: 0,
          i_payment: 0,
          i_balance: previous.i_balance + interest,
          p_payment: 0,
          p_balance: previous.p_balance,
          monthlyPayment: 0,
          isRateChange: false,
          newInterest: 0,
          index: -1,
          previous: undefined
        };
        months--;
        currentMonth++;
        output.push(newRow);
        previous = newRow;
        continue;
      }
      else {

        let payment = previous.monthlyPayment - newInterest;
        let monthly = previous.monthlyPayment;
        let interestPayment = newInterest;
        let interest = 0;
        if (newInterest > monthly) {
          interestPayment = previous.monthlyPayment;
          payment = 0;
          balance = previous.p_balance;
          interest = previous.i_balance + newInterest - monthly;
        }
        else if (previous.i_balance > 0 && ((previous.i_balance + newInterest) < previous.monthlyPayment)) {
          interestPayment = previous.i_balance + newInterest;
          payment -= previous.i_balance;
          balance = previous.p_balance - payment;
          interest = 0;
        }
        else if (previous.i_balance > 0) {
          interest = previous.i_balance;
        }
        else if (balance < 0) {
          payment += balance;
          balance = 0;
          monthly = payment + newInterest
        }

        let _interest = calculateTotalDailyInterest(previous.p_balance, previous.i_balance, currentRate, previous.date, daysInMonth, parseInt(this.state.compoundType.value), parseInt(this.state.calculationMethod.value))

        const data = {
          AmortizationScheduleUID: this.state.scheduleUID,
          Date: nextMonth,
          Days: daysInMonth,
          InterestAccumulated: _interest,
          Advance: 0,
          InterestPayment: interestPayment,
          PrincipalPayment: payment,
          IsRateChange: false,
          RateChange: 0
        }

        const response = await axios.post('./api/entity-amortization-schedule-line-items', data)



        const newRow: AmortizationLineItem = {
          uid: response.data.uid,
          date: nextMonth,
          days: daysInMonth,
          interestAccumulated: _interest,
          advance: 0,
          i_payment: interestPayment,
          i_balance: interest,
          p_payment: payment,
          p_balance: balance,
          monthlyPayment: monthly,
          isRateChange: false,
          newInterest: 0,
          index: -1,
          previous: undefined
        };
        output.push(newRow);
        months--;
        currentMonth++;
        previous = newRow;
      }
    }
    let paid = 0;
    output.forEach((row, idx) => {


      if (idx === 0) {
        output[idx].interestAccumulated = 0
        output[idx].i_balance = 0
      }
      else {
        paid += output[idx].i_payment
        output[idx].interestAccumulated += output[idx - 1].interestAccumulated
        output[idx].i_balance = output[idx].interestAccumulated - paid
      }
    })

    return output;
  }

  async removeRow(index: number): Promise<void> {
    await axios.delete('./api/entity-amortization-schedule-line-items/' + this.state.rows[index].uid)

    let previous: AmortizationLineItem = this.state.rows[index - 1];

    const nextMonth = addMonths(new Date(previous.date), 1);
    const daysInMonth = dateDiffInDays(previous.date, nextMonth);
    const newInterest = calculateTotalDailyInterest(previous.p_balance, previous.i_balance, this.state.currentInterestRate, previous.date, daysInMonth, parseInt(this.state.compoundType.value), parseInt(this.state.calculationMethod.value));
    let balance = previous.p_balance - (previous.monthlyPayment - newInterest);

    let payment = previous.monthlyPayment - newInterest;
    let monthly = previous.monthlyPayment;
    let interestPayment = newInterest;
    let interest = 0;
    if (newInterest > monthly) {
      interestPayment = previous.monthlyPayment;
      payment = 0;
      balance = previous.p_balance;
      interest = previous.i_balance + newInterest - monthly;
    }
    else if (previous.i_balance > 0 && ((previous.i_balance + newInterest) < previous.monthlyPayment)) {
      interestPayment = previous.i_balance + newInterest;
      payment -= previous.i_balance;
      balance = previous.p_balance - payment;
      interest = 0;
    }
    else if (previous.i_balance > 0) {
      interest = previous.i_balance;
    }
    else if (balance < 0) {
      payment += balance;
      balance = 0;
      monthly = payment + newInterest
    }
    const data = {
      AmortizationScheduleUID: this.state.scheduleUID,
      Date: nextMonth,
      Days: daysInMonth,
      InterestAccumulated: interest,
      Advance: 0,
      InterestPayment: interestPayment,
      PrincipalPayment: payment,
      IsRateChange: false,
      RateChange: 0
    }

    const response = await axios.post('./api/entity-amortization-schedule-line-items', data)


    const newRow: AmortizationLineItem = {
      uid: response.data.uid,
      date: nextMonth,
      days: daysInMonth,
      interestAccumulated: calculateTotalDailyInterest(previous.p_balance, previous.i_balance, this.state.currentInterestRate, previous.date, daysInMonth, parseInt(this.state.compoundType.value), parseInt(this.state.calculationMethod.value)),
      advance: 0,
      i_payment: interestPayment,
      i_balance: interest,
      p_payment: payment,
      p_balance: balance,
      monthlyPayment: monthly,
      isRateChange: response.data.isRateChange,
      newInterest: response.data.rateChange,
      index: index,
      previous: undefined
    };

    const rows = this.state.rows;
    const display = this.state.display;
    if (index < rows.length) {
      rows[index] = newRow;
    }
    display[index] = newRow;
    this.setState({
      rows: rows,
      display: display
    });

    await this.loadSchedule();
  }

  async saveNewRow(newRow: AmortizationLineItem): Promise<void> {
    const rows = this.state.rows;
    this.statusRef.current.display("Loading", "Please wait");
    if (rows.length > 0 && newRow.advance + rows[rows.length - 1].p_balance > this.state.principalCap) {
      CreateGlobalAlert("Unable able to add row that exceeds Principal Limit. Please review the Principal Limit", 3000);
      return;
    }
    if (newRow.index === -1) {
      let isInterest = false;

      if (newRow.newInterest !== 0) {
        isInterest = true;
      }

      const data = {
        AmortizationScheduleUID: this.state.scheduleUID,
        Date: newRow.date,
        Days: newRow.days,
        InterestAccumulated: newRow.interestAccumulated,
        Advance: newRow.advance,
        InterestPayment: newRow.i_payment,
        PrincipalPayment: newRow.p_payment,
        IsRateChange: isInterest,
        RateChange: newRow.newInterest
      }

      const response = await axios.post('./api/entity-amortization-schedule-line-items', data)

      rows.push({
        uid: response.data.uid,
        date: new Date(response.data.date),
        days: response.data.days,
        interestAccumulated: response.data.interestPayment,
        advance: response.data.advance,
        i_payment: response.data.interestPayment,
        i_balance: newRow.i_balance,
        p_payment: response.data.principalPayment,
        p_balance: newRow.p_balance,
        monthlyPayment: newRow.monthlyPayment,
        index: -1,
        isRateChange: response.data.isRateChange,
        newInterest: response.data.rateChange,
        previous: undefined
      })
    }
    this.setState({
      rows: rows,
      isAdding: false,
      newRate: false,
      editableIndex: -1
    }, this.update)

    this.statusRef.current.hide();
  }

  async updateRow(row: AmortizationLineItem): Promise<void> {
    let isInterest = false;

    if (row.newInterest !== this.state.startingInterestRate) {
      isInterest = true;
    }

    const data = {
      UID: row.uid,
      AmortizationScheduleUID: this.state.scheduleUID,
      Date: row.date,
      Days: row.days,
      InterestAccumulated: row.interestAccumulated,
      Advance: row.advance,
      InterestPayment: row.i_payment,
      PrincipalPayment: row.p_payment,
      IsRateChange: isInterest,
      RateChange: row.newInterest
    }

    const response = await axios.put('./api/entity-amortization-schedule-line-items', data)

    this.setState({
      rows: [],
      display: []
    })

    await this.loadSchedule();
  }

  changePrincipal(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!Number.isNaN(parseFloat(event.target.value))) {
      this.setState({ principalCap: parseFloat(event.target.value) })
    }
  }

  async savePrincipal(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const data = {
      UID: this.state.scheduleUID,
      PrincipalLimit: this.state.principalCap
    }

    const response = await axios.patch('./api/entity-amortization-schedule/update-principal', data)

    CreateGlobalAlert("Principal Updated", 2500)
  }

  async changeTerm(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    if (!Number.isNaN(parseInt(event.target.value))) {
      this.setState({ term: parseInt(event.target.value) })

      const data = {
        UID: this.state.scheduleUID,
        Term: this.state.term
      }

      const response = await axios.patch('./api/entity-amortization-schedule/update-term', data)

    }
  }

  async changeBalloon(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    if (!Number.isNaN(parseInt(event.target.value))) {
      this.setState({ balloon: parseInt(event.target.value) })

      const data = {
        UID: this.state.scheduleUID,
        Balloon: this.state.balloon
      }

      const response = await axios.patch('./api/entity-amortization-schedule/update-balloon', data)

    }
  }

  async export(): Promise<void> {
    await axios.post("./api/entity-amortization-schedule/export-all/" + this.state.scheduleUID).then((response) => {
      const fileDownload = require('js-file-download');
      fileDownload(response.data, `Entity Amortization Schedule Export - ${this.state.title}: ${new Date().toLocaleDateString()}.csv`);
    })
  }

  render(): JSX.Element {
    return (
      <div>
        <StatusModal ref={this.statusRef} />
        <WarningModal message={this.state.message} ref={this.modalRef} />
        <AmortizationLineItemEditModal ref={this.editLine} />
        <NewAmortizationLineItemModal ref={this.newAmortizationLineItemModal} />
        <div style={{ width: "98%", marginLeft: "1%", border: "3px solid #d7c7a7", backgroundColor: "#4C4A42", marginBottom: "2vh", paddingTop: "1vh", padding: "1vh" }}>
          <Row style={{ marginTop: "1vh" }}>
            <Col>
              <h2 className="no-print">Amortization Schedules</h2>
              <h4 className="no-print">{this.state.name}</h4>
            </Col>
            <Col xs="2" ><h4>Principal Amount:</h4></Col>
            <Col xs="2"><input type="text" value={this.state.principalCap} className="standard-input" style={{ marginTop: "1%", float: "right" }} onChange={this.changePrincipal} onBlur={this.savePrincipal} /></Col>
            <Col xs="2"><input type="button" value="New Line Item" className="standard-input" style={{ marginTop: "1%", float: "right" }} onClick={() => this.newAmortizationLineItemModal.current.show("Create New Line Item", this.state.startingInterestRate, this.state.term, this.saveNewRow)} /></Col>
          </Row>
          <br />
        </div>
        <hr />
        <div className="scrollable-table-container print-overflow" style={{ minHeight: "65vh", maxHeight: "65vh" }}>
          <Row>
            <Col>
              {/*This is for loans that have no limitation of time*/}
              <>
                {this.state.term > 0 && this.state.term < 100 ?
                  <table className="fixedTable force-background">
                    <thead>
                      <FilteredWithSettingsTableHeader columns={this.state.monthlyHeaders} icons={2} />
                    </thead>
                    <tbody>
                      <>
                        {
                          this.state.display.map((item, idx) => (
                            <LineItemRow key={idx} row={item} editRow={() => this.editLine.current.show("Edit Line Item", item, this.state.startingInterestRate, this.state.term, this.updateRow)} removeRow={this.removeRow} index={idx} termLimit={this.state.term} />
                          ))
                        }
                      </>
                    </tbody>
                  </table>
                  :
                  <table className="fixedTable force-background">
                    <thead>
                      <FilteredWithSettingsTableHeader columns={this.state.principleHeaders}
                        icons={2}
                      />
                    </thead>
                    <tbody>
                      <>
                        {
                          this.state.rows.map((item, idx) => (
                            <LineItemRow key={idx} editRow={() => this.editLine.current.show("Edit Line Item", item, this.state.startingInterestRate, this.state.term, this.updateRow)} row={item} index={idx} removeRow={this.removeRow} termLimit={this.state.term} />
                          ))
                        }
                      </>
                    </tbody>
                  </table>
                }
              </>
            </Col>
          </Row>
        </div>
        <Row style={{ marginTop: "2vh" }}>
          <Col>
            <input type="button" className="standard-input no-print" value="Export" onClick={this.export} />
          </Col>
          <Col>
            {/*<ReactToPrint*/}
            {/*  trigger={() => <input type="button" className="standard-input no-print" value="Print" />}*/}
            {/*  content={() => this.printWrapper.current}*/}
            {/*/>*/}
          </Col>
        </Row >
      </div>
    )
  }

}


//<Row style={{ height: "100vh", marginTop: "-1em" }}>
//          <Col>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", marginTop: "1em" }}>Title</h4>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{this.state.title}</h4>
//          </Col>
//          <Col>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", marginTop: "1em" }}>Lender</h4>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{this.state.lender.label}</h4>
//          </Col>
//          <Col>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", marginTop: "1em" }}>Borrower</h4>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{this.state.borrower.label}</h4>
//          </Col>
//          <Col>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", marginTop: "1em" }}>Interest Rate</h4>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{numberWithCommas(this.state.currentInterestRate) + "%"}</h4>
//          </Col>
//        </Row>
//        <Row >
//          <Col>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", marginTop: "1em" }}>Principal Limit</h4>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{numberWithCommas(this.state.principalCap)}</h4>
//          </Col>
//          <Col>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", marginTop: "1em" }}>Loan Start Date</h4>
//            <h4 className="hidden-print" style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{((this.state.rows.length > 0 && this.state.rows[0].date) ? this.state.rows[0].date : new Date()).toLocaleDateString('en-US')}</h4>
//          </Col>
//          <Col>
//            <h4 className={this.state.term === -1 ? "" : "hidden-print"} style={{ display: "none", width: "100%", marginTop: "1em" }}>Term</h4>
//            <h4 className={this.state.term === -1 ? "" : "hidden-print"} style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{this.state.term}</h4>
//          </Col>
//          <Col>
//            <h4 className={this.state.balloon === -1 ? "" : "hidden-print"} style={{ display: "none", width: "100%", marginTop: "1em" }}>Balloon</h4>
//            <h4 className={this.state.balloon === -1 ? "" : "hidden-print"} style={{ display: "none", width: "100%", paddingLeft: "4px" }}>{this.state.balloon}</h4>
//          </Col>
//        </Row>

//<EditableLineItemRow previous={this.state.rows[this.state.rows.length - 1]}
//  rate={this.state.currentInterestRate} row={{
//    uid: EMPTY_GUID,
//    index: -1,
//    monthlyPayment: 0,
//    isInterest: false,
//    newInterest: 0,
//    termLimit: -1,
//    date: new Date(),
//    days: 0,
//    interestAccumulated: 0,
//    advance: 0,
//    i_payment: 0,
//    i_balance: 0,
//    p_payment: 0,
//    p_balance: 0,
//    previous: undefined
//  }} funcs={{ save: this.saveNewRow, cancel: this.cancel }} termLimit={this.state.term}
//  compoundType={parseInt(this.state.compoundType.value)} calculationMethod={parseInt(this.state.compoundType.value)}
//  loanStartDate={this.state.loanStartDate}
///>
//                    :
//this.state.newRate ?
//  <RateChange currentInterestRate={this.state.currentInterestRate} index={-1}
//    previous={this.state.rows[this.state.rows.length - 1]} termLimit={this.state.term} funcs={{ save: this.saveRateChange, cancel: this.cancel }}
//    compoundType={parseInt(this.state.compoundType.value)} calculationMethod={parseInt(this.state.compoundType.value)}
//    loanStartDate={this.state.loanStartDate}
//  />

//{
//  this.state.isAdding ?
//  <EditableLineItemRow previous={this.state.rows[this.state.rows.length - 1]}
//    rate={this.state.currentInterestRate} row={{
//      uid: EMPTY_GUID,
//      index: -1,
//      monthlyPayment: 0,
//      isInterest: false,
//      newInterest: 0,
//      termLimit: -1,
//      date: new Date(),
//      days: 0,
//      interestAccumulated: 0,
//      advance: 0,
//      i_payment: 0,
//      i_balance: 0,
//      p_payment: 0,
//      p_balance: 0,
//      previous: undefined
//    }} funcs={{ save: this.saveNewRow, cancel: this.cancel }} termLimit={this.state.term}
//    compoundType={parseInt(this.state.compoundType.value)} calculationMethod={parseInt(this.state.compoundType.value)}
//    loanStartDate={this.state.loanStartDate}
//  /> :
//  this.state.newRate ?
//    <RateChange currentInterestRate={this.state.currentInterestRate} index={-1}
//      previous={this.state.rows[this.state.rows.length - 1]} termLimit={-1} funcs={{ save: this.saveRateChange, cancel: this.cancel }}
//      compoundType={parseInt(this.state.compoundType.value)} calculationMethod={parseInt(this.state.compoundType.value)}
//      loanStartDate={this.state.loanStartDate}
//    />
//    : <TableAdditionRow key={Math.random()} length={10} marginLeft="14%" onClick={this.addRow} />

