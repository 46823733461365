import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DatePicker } from '../../CoreComponents/DateComponents';
import axios from 'axios';
import { DownloadNamedFile } from '../../../functions/documentTools';

interface TaxReportProcessorModalState {
  show: boolean;
  startDate: Date;
  reportFile: File;
}

export default class TaxReportProcessorModal extends React.Component<{ throwError: () => void; }, TaxReportProcessorModalState> {

  constructor(props: { throwError: () => void; }) {
    super(props);
    this.state = {
      show: false,
      startDate: new Date(),
      reportFile: null
    }
    this.setFile = this.setFile.bind(this);
    this.setBillingDate = this.setBillingDate.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  setFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ reportFile: event.target.files[0] });
  }

  setBillingDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ startDate: event.target.valueAsDate });
  }

  show(): void {
    this.setState({ show: true });
  }

  hide(): void {
    this.setState({ show: false });
  }

  async submit(): Promise<void> {
    var formData = new FormData();
    formData.append('StartDate', this.state.startDate.toISOString());
    formData.append('File', this.state.reportFile);

    let response = await axios.post('./api/manual/process-property-tax', formData, { responseType: 'blob' });
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
    else {
      this.hide();
      this.props.throwError();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ show: false })} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Upload Property Tax CSV
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Start Date</h5>
              </Col>
              <Col>
                <DatePicker value={this.state.startDate} onChange={this.setBillingDate} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Report CSV</h5>
              </Col>
              <Col>
                <input type="file" className="standard-input" style={{ paddingTop: "0.5vh" }} accept=".csv"
                  onChange={this.setFile}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Process" className="standard-input" onClick={() => this.submit()} />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}