import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { pullStates } from '../../../functions/fetchLinkedObjects';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import axios from 'axios';

interface BankManagementModalState {
  show: boolean;
  bank: string;
  street: string;
  city: string;
  state: SelectOptions;
  zipCode: string;
  states: Array<SelectOptions>;
}

export default class BankManagementModal extends React.Component<{}, BankManagementModalState>{

  constructor(props: {}) {
    super(props);
    this.state = {
      show: false,
      bank: "",
      street: "",
      city: "",
      state: { label: "Select State", value: "" },
      zipCode: "",
      states: []
    }
    this.changeBank = this.changeBank.bind(this);
    this.changeStreetAddress = this.changeStreetAddress.bind(this);
    this.changeState = this.changeState.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changeZipCode = this.changeZipCode.bind(this);
    this.save = this.save.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let states = await pullStates();
    this.setState({ states: states });
  }

  forceClose(): void {
    this.setState({
      show: false
    })
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  changeBank(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ bank: event.target.value });
  }

  changeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ street: event.target.value });
  }

  changeCity(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ city: event.target.value });
  }

  changeState(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ state: event });
  }

  changeZipCode(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ zipCode: event.target.value });
  }

  async save(): Promise<void> {
    const data = {
      Name: this.state.bank,
      Street: this.state.street,
      City: this.state.city,
      State: this.state.state.value,
      Zipcode: this.state.zipCode
    };
    let response = await axios.post('./api/banks', data, { validateStatus: () => true });
    if (response.status === 200) {
      window.location.reload();
    }
    else {
      // TODO: Error Handling
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Manage Bank
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Bank</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.bank} className="standard-input" onChange={this.changeBank} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Street Address</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.street} className="standard-input" onChange={this.changeStreetAddress} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>City</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.city} className="standard-input" onChange={this.changeCity} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>State</h5>
              </Col>
              <Col>
                <Select 
                  options={this.state.states}
                  value={this.state.state}
                  styles={reactSelectBasicStyle}
                  onChange={this.changeState}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Zipcode</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.zipCode} className="standard-input" onChange={this.changeZipCode} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Save Bank" className="standard-input" onClick={this.save} />
          </ModalBody>
        </div>
      </Modal >
    )
  }
}