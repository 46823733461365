import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { CompanyNames } from '../../constants/EnumConstants';
import { numberWithCommas } from '../../functions/numberFunctions';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { getBearerToken } from '../../functions/authActions';

interface TransactionAndUserLicense {
  uid: string;
  software: string;
  company: string;
  licenseCount: number;
  monthlyCost: number;
  annualCost: number;
  property: string;
}

interface PropertyLicense {
  uid: string;
  software: string;
  property: string;
  unitCount: number;
  unitCost: number;
  monthlyCost: number;
  annualCost: number;
}

interface UserLicenseManagementState {
  userHeaders: Array<FilterAndSettingField>;
  userLicenses: Array<TransactionAndUserLicense>;
  softwareUID: string;
}


interface TransactionLicenseManagementState {
  transactionHeaders: Array<FilterAndSettingField>;
  transactionLicenses: Array<TransactionAndUserLicense>;
  softwareUID: string;
}

interface CostAnalysisManagementState {
  softwareUID: string;
}

interface LicenseManagementProps {
  softwareUID: string;
}

class UnitLicenseCostManagement extends React.Component<LicenseManagementProps, any> {

  constructor(props: LicenseManagementProps) {
    super(props);

    this.state = {
      headers: [],
      rows: [],
      softwareUID: props.softwareUID
    }
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('api/unit-license/software/' + this.state.softwareUID).then((response) => {
      let rows: Array<PropertyLicense> = [];
      response.data.forEach((item: any) => {
        item.propertyLicenseMaps.forEach((license: any) => {
          let software = item.name;
          rows.push({
            uid: license.uid,
            property: license.property.name,
            software: software,
            unitCount: license.property.unitCount,
            unitCost: item.costPerUnit,
            monthlyCost: license.property.unitCount * item.costPerUnit,
            annualCost: license.property.unitCount * item.costPerUnit * 12,
          })
        })
      })
      this.setState({
        rows: rows
      }, () => this.generateHeaders());
    });
  }

  generateHeaders(): void {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Software",
        setting: true,
        filter: {}
      },
      {
        columnName: "Property",
        setting: true,
        // need to implement this filter sooner than later
        filter: {}
      },
      {
        columnName: "Unit Count",
        setting: true,
        filter: {}
      },
      {
        columnName: "Unit Cost",
        setting: true,
        filter: {}
      },
      {
        columnName: "Monthly Cost",
        setting: true,
        filter: {}
      },
      {
        columnName: "Annual Cost",
        setting: true,
        filter: {}
      }

    ];

    this.setState({
      headers: headers
    })
  }

  render(): JSX.Element {
    return (
      <div style={{ height: "75vh", overflowX: "hidden", overflowY: "auto" }}>
        <h4>Property Licensing Costs</h4>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            {this.state.rows.map((item: any) => (
              <tr key={item.uid}>
                <td className="fixedTableCellFirst">{item.software}</td>
                <td className="fixedTableCell">{item.property}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>{item.unitCount}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>{item.unitCost}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.monthlyCost)}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.annualCost)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

class TransactionLicenseManagement extends React.Component<LicenseManagementProps, TransactionLicenseManagementState> {

  constructor(props: LicenseManagementProps) {
    super(props);

    this.state = {
      transactionHeaders: [],
      transactionLicenses: [],
      softwareUID: props.softwareUID
    }
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('./api/transaction-license/softwareUID/' + this.state.softwareUID).then((response) => {
      let rows: Array<TransactionAndUserLicense> = [];
      response.data.forEach((item: any) => {
        item.licenseAllocations.forEach((license: any) => {
          let company = CompanyNames[license.company];
          let software = item.description;
          rows.push({
            uid: license.uid,
            company: company,
            software: software,
            monthlyCost: license.transactionAllocation * item.transactionCost,
            annualCost: license.transactionAllocation * item.transactionCost * 12,
            licenseCount: license.transactionAllocation,
            property: ""
          })
        })

      })
      this.setState({
        transactionLicenses: rows
      }, () => this.generateHeaders());
    });
  }

  generateHeaders(): void {
    let transactionHeaders: Array<FilterAndSettingField> = [
      {
        columnName: "Software",
        setting: true,
        filter: {}
      },
      {
        columnName: "Company",
        setting: true,
        additionalClasses: "fortyfive",
        filter: {}
      },
      {
        columnName: "Count",
        additionalClasses: "fifteen",
        setting: true,
        filter: {}
      },
      {
        columnName: "Monthly",
        setting: true,
        filter: {}
      },
      {
        columnName: "Annual",
        setting: true,
        filter: {}
      }
    ]
    this.setState({
      transactionHeaders: transactionHeaders
    })
  }

  render(): JSX.Element {
    return (<>
      <h4>Transaction Licensing Costs</h4>
      <table className="fixedTable">
        <thead>
          <FilteredWithSettingsTableHeader columns={this.state.transactionHeaders} icons={0} />
        </thead>
        <tbody>
          {this.state.transactionLicenses.map((item) => (
            <tr key={item.uid}>
              <td className="fixedTableCellFirst">{item.software}</td>
              <td className="fixedTableCell">{item.company}</td>
              <td className="fixedTableCell" style={{ textAlign: "right" }}>{item.licenseCount}</td>
              <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.monthlyCost)}</td>
              <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.annualCost)}</td>
            </tr>
          ))}

        </tbody>
      </table>
    </>)
  }
}

class UserLicenseManagement extends React.Component<LicenseManagementProps, UserLicenseManagementState> {

  constructor(props: LicenseManagementProps) {
    super(props);
    this.state = {
      userHeaders: [],
      userLicenses: [],
      softwareUID: props.softwareUID
    }
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('./api/user-license/softwareUID/' + this.state.softwareUID).then((response) => {
      let rows: Array<TransactionAndUserLicense> = [];
      response.data.forEach((item: any) => {
        item.licenses.forEach((license: any) => {
          if (license.isActive) {
            let _company = license.companyOverride === -1 ? license.employee.company : license.companyOverride;
            let company = CompanyNames[_company];
            let software = item.description;
            if (rows.filter(u => u.company == company && u.software == software).length > 0) {
              let row = rows.filter(u => u.company == company && u.software == software)[0];
              let rowIndex = rows.findIndex(u => u.company == company && u.software == software);
              row.licenseCount += 1;
              row.monthlyCost = row.licenseCount * item.licenseCost;
              row.annualCost = (row.licenseCount * item.licenseCost) * 12;
              rows[rowIndex] = row;
            }
            else {
              rows.push({
                uid: license.uid,
                company: company,
                software: software,
                monthlyCost: item.isAnnual ? item.licenseCost / 12 : item.licenseCost,
                annualCost: item.isAnnual ? item.licenseCost : item.licenseCost * 12,
                licenseCount: 1,
                property: ""
              })
            }
          }
        })
      })
      this.setState({
        userLicenses: rows
      }, () => this.generateHeaders());
    });
  }

  generateHeaders(): void {
    let userHeaders: Array<FilterAndSettingField> = [
      {
        columnName: "Software",
        setting: true,
        filter: {}
      },
      {
        columnName: "Company",
        setting: true,
        additionalClasses: "fortyfive",
        filter: {}
      },
      {
        columnName: "Property",
        setting: true,
        additionalClasses: "fortyfive",
        filter: {}
      },
      {
        columnName: "Count",
        additionalClasses: "fifteen",
        setting: true,
        filter: {}
      },
      {
        columnName: "Monthly",
        setting: true,
        filter: {}
      },
      {
        columnName: "Annual",
        setting: true,
        filter: {}
      }
    ]
    this.setState({
      userHeaders: userHeaders
    })
  }

  render(): JSX.Element {
    return (
      <div style={{ maxHeight: "40vh", minHeight: "40vh", overflowY: "auto", overflowX: "hidden" }}>
        <h4>User Licensing Costs</h4>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.userHeaders} icons={0} />
          </thead>
          <tbody>
            {this.state.userLicenses.map((item) => (
              <tr key={item.uid}>
                <td className="fixedTableCellFirst">{item.software}</td>
                <td className="fixedTableCell">{item.company}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>{item.property}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>{item.licenseCount}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.monthlyCost)}</td>
                <td className="fixedTableCell" style={{ textAlign: "right" }}>${numberWithCommas(item.annualCost)}</td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>)
  }
}

export class CostAnalysisManagement extends React.Component<LicenseManagementProps, CostAnalysisManagementState> {

  constructor(props: LicenseManagementProps) {
    super(props);
    this.state = {
      softwareUID: props.softwareUID
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <h2>Cost Analysis</h2>
        <Row>
          <Col>
            <Row>
              <Col>
                <UserLicenseManagement softwareUID={this.state.softwareUID} />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <TransactionLicenseManagement softwareUID={this.state.softwareUID} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <UnitLicenseCostManagement softwareUID={this.state.softwareUID} />
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    )
  }
}