import * as React from "react";
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { CreateGlobalAlert } from "../../functions/CreateGlobalAlerts";
import * as Structs from './Structs';

export class ScreenerFinancing extends React.Component<Structs.ScreenerFinancingProps, Structs.ScreenerFinancingState> {

  constructor(props: Structs.ScreenerFinancingProps) {
    super(props);
    this.state = {
      finance: props.finance,
      screener: props.screener,
      costPerWatt: 0.0,
      baseCost: 0.0,
      projectSize: 0,
      developerPercentage: 0.0,
      developerCost: 0.0,
      adminPercentage: 0.0,
      adminCost: 0.0,
      additionalPercentage: 0.0,
      additionalCost: 0.0,
      financingPercentage: 0.0,
      financingCost: 0.0,
      subTotalCost: 0.0,
      totalCost: 0.0,
      grants: 0.0,
      grantsPercentage: 0.0,
      rebates: 0.0,
      rebatePercentage: 0.0,
      propertyReserve: 0.0,
      propertyReservePercentage: 0.0,
      equity: 0.0,
      equityPercentage: 0.0,
      totalFunding: 0.0,
      totalFundingPercentage: 0.0,
      loan: 0.0,
      loanPercentage: 0.0
    }
    this.submit = this.submit.bind(this);
    this.changeDeveloperPercentage = this.changeDeveloperPercentage.bind(this);
    this.changeAdminPercentage = this.changeAdminPercentage.bind(this);
    this.changeAdditionalPercentage = this.changeAdditionalPercentage.bind(this);
    this.changeFinancePercentage = this.changeFinancePercentage.bind(this);
    this.changeGrants = this.changeGrants.bind(this);
    this.changeRebates = this.changeRebates.bind(this);
    this.changePropertyReserve = this.changePropertyReserve.bind(this);
    this.changeEquity = this.changeEquity.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    await axios.get('./api/solar-projects/' + this.state.screener + "/screener").then(function (response) {
      self.setState({
        projectSize: response.data.systemWattSize,
        baseCost: self.state.costPerWatt * response.data.systemWattSize
      }, self.calculateBaseCost);
    })

    if (this.state.finance !== '') {
      await axios.get("./api/solar-screener-financing/" + this.state.finance).then(function (response) {
        self.setState({
          costPerWatt: response.data.costPerWatt,
          developerPercentage: response.data.developerPercentage * 100,
          adminPercentage: response.data.adminCostPercentage * 100,
          additionalPercentage: response.data.additionalFeePercentage * 100,
          financingPercentage: response.data.financingCost,
          grants: response.data.grants,
          rebates: response.data.rebateIncentives,
          propertyReserve: response.data.propertyReserve,
          equity: response.data.equity
        }, self.calculateBaseCost)
      })
    }
  }

  changeCostPerWatt(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        costPerWatt: event.target.valueAsNumber,
      },
      this.calculateBaseCost
    );
  }
  //#region Change Functions
  changeDeveloperPercentage(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      developerPercentage: parseFloat(event.target.value)
    }, this.calculateFinancing)
  }

  changeAdminPercentage(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      developerPercentage: parseFloat(event.target.value)
    }, this.calculateFinancing)
  }

  changeAdditionalPercentage(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      additionalPercentage: parseFloat(event.target.value)
    }, this.calculateFinancing)
  }

  changeFinancePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      financingPercentage: parseFloat(event.target.value)
    }, this.calculateFinancing)
  }

  changeGrants(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      grants: parseFloat(event.target.value)
    }, this.calculateAllFunding)
  }

  changeRebates(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      rebates: parseFloat(event.target.value)
    }, this.calculateAllFunding)
  }

  changePropertyReserve(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      propertyReserve: parseFloat(event.target.value)
    }, this.calculateAllFunding)
  }

  changeEquity(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      equity: parseFloat(event.target.value)
    }, this.calculateAllFunding)
  }

  calculateBaseCost(): void {
    this.setState({
      baseCost: parseFloat((this.state.costPerWatt * this.state.projectSize).toFixed(2))
    }, this.calculateFinancing)
  }
  //#endregion

  //#region Calculate Functions

  calculateFinancing(): void {
    try {
      var baseCost = this.state.baseCost;
      this.setState({
        developerCost: parseFloat(((this.state.developerPercentage / 100) * baseCost).toFixed(2)),
        adminCost: parseFloat(((this.state.adminPercentage / 100) * baseCost).toFixed(2)),
        additionalCost: parseFloat(((this.state.additionalPercentage / 100) * baseCost).toFixed(2))
      }, this.calculateSubTotal)
    }
    catch {
      console.warn("There has been an issue calculating the values");
    }
  }

  calculateFinancingCost(): void {
    var baseCost = this.state.subTotalCost;
    this.setState({
      financingCost: parseFloat(((this.state.financingPercentage / 100) * baseCost).toFixed(2))
    }, this.calculateTotal)
  }

  calculateSubTotal(): void {
    try {
      var baseCost = this.state.baseCost;
      var developerCost = this.state.developerCost;
      var adminCost = this.state.adminCost;
      var additionalCost = this.state.additionalCost;
      this.setState({
        subTotalCost: parseFloat((baseCost + developerCost + adminCost + additionalCost).toFixed(2))
      }, this.calculateFinancingCost)
    }
    catch {
      // do nothing
    }
  }

  calculateTotal(): void {
    var subTotal = this.state.subTotalCost;
    var financing = this.state.financingCost;
    this.setState({
      totalCost: parseFloat((subTotal + financing).toFixed(2))
    }, this.calculateAllFunding)
  }

  calculateAllFunding(): void {
    try {
      var totalCost = this.state.totalCost;
      this.setState({
        grantsPercentage: parseFloat(((this.state.grants / totalCost) * 100).toFixed(2)),
        rebatePercentage: parseFloat(((this.state.rebates / totalCost) * 100).toFixed(2)),
        propertyReservePercentage: parseFloat(((this.state.propertyReserve / totalCost) * 100).toFixed(2)),
        equityPercentage: parseFloat(((this.state.equity / totalCost) * 100).toFixed(2))
      }, this.calculateFunding)
    }
    catch {
      // do nothing 
    }
  }

  calculateFunding(): void {
    var grants = this.state.grants;
    var rebates = this.state.rebates;
    var propertyReserve = this.state.propertyReserve;
    var equity = this.state.equity;
    this.setState({
      totalFunding: parseFloat((grants + rebates + propertyReserve + equity).toFixed(2)),
    }, this.calculateFundingPercentage)
  }

  calculateFundingPercentage(): void {
    try {
      var totalCost = this.state.totalCost;
      var totalFunding = this.state.totalFunding;
      var loan = totalCost - totalFunding;
      var loanPercentage = parseFloat(((loan / totalCost) * 100).toFixed(2));
      this.setState({
        totalFundingPercentage: parseFloat(((totalFunding / totalCost) * 100).toFixed(2)),
        loan: parseFloat(loan.toFixed(2)),
        loanPercentage: loanPercentage
      });
    }
    catch {
      // do nothing 
    }
  }

  //#endregion

  async submit(): Promise<void> {
    var self = this;
    const data = {
      CostPerWatt: this.state.costPerWatt,
      IsCostPerWattEstimate: true,
      DeveloperPercentage: this.state.developerPercentage / 100,
      AdminCostPercentage: this.state.adminPercentage / 100,
      AdditionalFeePercentage: this.state.additionalPercentage / 100,
      FinancingCost: this.state.financingPercentage,
      Grants: this.state.grants,
      RebateIncentives: this.state.rebates,
      PropertyReserve: this.state.propertyReserve,
      Equity: this.state.equity
    }
    if (this.state.finance !== '') {
      await axios.put('./api/solar-screener-financing/' + this.state.finance, data).then(function (response) {
        CreateGlobalAlert("Financing has been updated, reloading page with finance settings", 1200, () => window.location.reload());
      });
    }
    else {
      await axios.post('./api/solar-screener-financing/' + this.state.screener, data).then(function (response) {
        self.setState({ finance: response.data });
        CreateGlobalAlert("Financing has been created, reloading page with finance settings", 1200, () => window.location.reload());
      });
    }
  }

  render(): JSX.Element {
    return (
      <div id="screener-financing-container">
        <Row style={{ maxHeight: "82vh" }}>
          <Row>
            <Col>
              <h3>Install Costs</h3>
              <table className="fixedTable" >
                <thead>
                  <tr>
                    <th className="large fixedTableHeaderFirst">Mount Type</th>
                    <th className="large fixedTableHeader">Project Size</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fixedTableCellFirst">
                      <select className="standard-input" id="mounting-type" style={{ width: "96%" }}>
                        <option value='0'>Rooftop</option>
                        <option value='1'>Ground Mount</option>
                        <option value='2'>Carport</option>
                        <option value='3'>Combination</option>
                      </select>
                    </td>
                    <td>{this.state.projectSize}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
              <h3>Per Watt Cost</h3>
              <table className="fixedTable" >
                <thead>
                  <tr>
                    <th className="large fixedTableHeaderFirst">Cost Per Watt</th>
                    <th className="small fixedTableHeader">Estimate Cost Per Watt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      $<input className="standard-input" type="number" step="0.01" min="0.00" max="4.00" value={this.state.costPerWatt} onChange={this.changeCostPerWatt}
                        style={{ width: "92%", textAlign: "right", marginLeft: "10px" }} />
                    </td>
                    <td>
                      <input type="checkbox" style={{ marginLeft: "40%" }} defaultChecked />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <Row>
            <Col>
              <Row>
                <h3>Finance</h3>
                <table className="fixedTable">
                  <tbody>
                    <tr>
                      <td className="fixedTableHeaderSideFirst">Estimated Project Cost</td>
                      <td className="fixedTableCellTop"></td>
                      <td id="estimated-project-cost" className="fixedTableCellTop" style={{ textAlign: "right", paddingRight: "20px" }}>${this.state.baseCost}</td>
                    </tr>
                    <tr>
                      <td className="fixedTableHeaderSide">Developer Fee</td>
                      <td id="developer-fee-percentage" className="fixedTableCell">
                        <input className="standard-input" type="number" step="1" max="30" min="10" value={this.state.developerPercentage} onChange={this.changeDeveloperPercentage}
                          style={{ width: "90%", textAlign: "right" }} />%
                      </td>
                      <td id="developer-fee-cost" className="fixedTableCell" style={{ textAlign: "right", paddingRight: "20px" }}>${this.state.developerCost}</td>
                    </tr>
                    <tr>
                      <td className="fixedTableHeaderSide">Admin Costs</td>
                      <td id="admin-fee-percentage" className="fixedTableCell">
                        <input className="standard-input" type="number" step="1" max="100" min="0" value={this.state.adminPercentage} onChange={this.changeAdminPercentage}
                          style={{ width: "90%", textAlign: "right" }} />%
                      </td>
                      <td className="fixedTableCell" style={{ textAlign: "right", paddingRight: "20px" }}>${this.state.adminCost}</td>
                    </tr>
                    <tr>
                      <td className="fixedTableHeaderSide">Additional Fees</td>
                      <td id="additional-fee-percentage" className="fixedTableCell">
                        <input className="standard-input" type="number" step="1" max="100" min="0" value={this.state.additionalPercentage} onChange={this.changeAdditionalPercentage}
                          style={{ width: "90%", textAlign: "right" }} />%
                      </td>
                      <td className="fixedTableCell" style={{ textAlign: "right", paddingRight: "20px" }}>${this.state.additionalCost}</td>
                    </tr>
                    <tr>
                      <td className="fixedTableHeaderSide">Sub-Total Project Costs</td>
                      <td className="fixedTableCell">---------</td>
                      <td className="fixedTableCell" style={{ textAlign: "right", paddingRight: "20px" }}>${this.state.subTotalCost}</td>
                    </tr>
                    <tr>
                      <td className="fixedTableHeaderSide">Financing Cost</td>
                      <td id="origination-fee" className="fixedTableCell">
                        <input className="standard-input" type="number" step="1" max="100" min="10" value={this.state.financingPercentage} onChange={this.changeFinancePercentage}
                          style={{ width: "90%", textAlign: "right" }} />%
                      </td>
                      <td className="fixedTableCell" style={{ textAlign: "right", paddingRight: "20px" }}>${this.state.financingCost}</td>
                    </tr>
                    <tr>
                      <td className="fixedTableHeaderSide">Total Project Cost</td>
                      <td className="fixedTableCell">---------</td>
                      <td className="fixedTableCell" style={{ textAlign: "right", paddingRight: "20px" }}>${this.state.totalCost}</td>
                    </tr>
                  </tbody>
                </table>
              </Row>
              {/*<Row style={{ marginTop: "10px", marginLeft: "4px" }}>*/}
              {/*    <h3>Additional Fees</h3>*/}
              {/*</Row>*/}
            </Col>
            <Col>
              <h3>Outside Funding</h3>
              <table className="fixedTable">
                <tbody>
                  <tr>
                    <td className="fixedTableHeaderSideFirst">Grants</td>
                    <td className="fixedTableCellTop">
                      $<input className="standard-input" type="number" style={{ width: "95%" }} id="grants" value={this.state.grants} onChange={this.changeGrants} />
                    </td>
                    <td className="fixedTableCellTop">{this.state.grantsPercentage}%</td>
                  </tr>
                  <tr>
                    <td className="fixedTableHeaderSide">Rebates & Incentives</td>
                    <td className="fixedTableCell">
                      $<input className="standard-input" type="number" id="rebates" style={{ width: "95%" }} value={this.state.rebates} onChange={this.changeRebates} />
                    </td>
                    <td className="fixedTableCell">{this.state.rebatePercentage}%</td>
                  </tr>
                  <tr>
                    <td className="fixedTableHeaderSide">Property Reserve Contribution</td>
                    <td className="fixedTableCell">
                      $<input className="standard-input" type="number" style={{ width: "95%" }} id="propertyReserve" value={this.state.propertyReserve} onChange={this.changePropertyReserve} />
                    </td>
                    <td className="fixedTableCell">{this.state.propertyReservePercentage}%</td>
                  </tr>
                  <tr>
                    <td className="fixedTableHeaderSide">Equity Investment</td>
                    <td className="fixedTableCell">
                      $<input type="number" className="standard-input" style={{ width: "95%" }} id="equity" value={this.state.equity} onChange={this.changeEquity} />
                    </td>
                    <td className="fixedTableCell">{this.state.equityPercentage}%</td>
                  </tr>
                  <tr>
                    <td className="fixedTableHeaderSide">Total Outside Funding</td>
                    <td className="fixedTableCell">
                      ${this.state.totalFunding}
                    </td>
                    <td className="fixedTableCell">{this.state.totalFundingPercentage}%</td>
                  </tr>
                  <tr>
                    <td className="fixedTableHeaderSide">Loan</td>
                    <td className="fixedTableCell">
                      ${this.state.loan}
                    </td>
                    <td className="fixedTableCell">{this.state.loanPercentage}%</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Row>
        <input type="button" value="Save Financing" className="standard-input" onClick={this.submit} />
      </div >
    )
  }
}