//#region Imports
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import axios from 'axios';
import { TableAdditionRow } from '../CoreComponents/CoreTableRows';
import { faCog, faMinus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pullExternalCompaniesVendorType, pullExternalCompaniesCustomerType, pullEntitiesCustomerType, pullEntitiesVendorType, pullProperties } from '../../functions/fetchLinkedObjects';
import { reactSelectBasicStyle } from '../../style/select-constants';
import Select from 'react-select';
import { YesNoModal } from '../CoreComponents/Modals';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { numberWithCommas } from '../../functions/numberFunctions';
import { getUserID } from '../../functions/authActions';
import { DatePicker } from '../CoreComponents/DateComponents';
import { RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { DownloadNamedFile } from '../../functions/documentTools';
//#endregion

interface InvoiceDataRowProps {
  description: string;
  costPerUnit: number;
  isLocked: boolean;
  save: (description: string, costPerUnit: number, index: number) => void;
  edit: (description: string, costPerUnit: number, index: number) => void;
  remove: (index: number) => void;
  index: number;
  addTotal: (adding: string) => void;
}

interface InvoiceData {
  description: string;
  costPerUnit: number;
  isLocked: boolean;
}

interface InvoiceDataRowState {
  description: string;
  costPerUnit: string;
}

interface CustomInvoiceCreatorProps {

}

interface CustomInvoiceCreatorState {
  rows: Array<InvoiceData>;
  internalVendors: Array<SelectOptions>;
  externalVendors: Array<SelectOptions>;
  internalCustomers: Array<SelectOptions>;
  externalCustomers: Array<SelectOptions>;
  properties: Array<SelectOptions>;
  customer: SelectOptions;
  vendor: SelectOptions;
  invoiceDate: Date;
  total: number;
  memo: string;
}

class InvoiceDataRow extends React.Component<InvoiceDataRowProps, InvoiceDataRowState> {

  constructor(props: InvoiceDataRowProps) {
    super(props);
    this.state = {
      description: props.description,
      costPerUnit: props.costPerUnit.toString()
    };
    this.changeDescription = this.changeDescription.bind(this);
    this.changeCostPerUnit = this.changeCostPerUnit.bind(this);
  }

  changeDescription(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      description: event.target.value
    })
  }

  changeCostPerUnit(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      costPerUnit: event.target.value
    })
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          {this.props.isLocked ? this.props.description
            : <input
              className="standard-input"
              type="text"
              name="description"
              value={this.state.description}
              onChange={this.changeDescription}
            />
          }
        </td>
        <td className="fixedTableCell">
          ${this.props.isLocked
            //@ts-ignore
            ? numberWithCommas(parseFloat(this.props.costPerUnit))
            : <input
              className="standard-input"
              type="string"
              name="costPerUnit"
              value={this.state.costPerUnit}
              onChange={this.changeCostPerUnit}
              style={{ width: "95%" }}
            />
          }
        </td>
        <td className="fixedTableCell">
          {!this.props.isLocked ?
            <FontAwesomeIcon icon={faMinus} onClick={() => this.props.remove(this.props.index)}
              style={{ marginLeft: "35%" }}
            />
            :
            <FontAwesomeIcon icon={faCog} style={{ marginLeft: "35%" }}
              onClick={() => this.props.edit(this.state.description, parseFloat(this.state.costPerUnit), this.props.index)} />
          }
        </td>
        <td className="fixedTableCell">
          {!this.props.isLocked
            ? <FontAwesomeIcon icon={faSave} style={{ marginLeft: "35%" }}
              onClick={() => this.props.save(this.state.description, parseFloat(this.state.costPerUnit), this.props.index)}
            />
            : <FontAwesomeIcon icon={faMinus} onClick={() => this.props.remove(this.props.index)}
              style={{ marginLeft: "35%" }}
            />
          }
        </td>
      </tr>
    )
  }
}

export class CustomInvoiceCreator extends React.Component<CustomInvoiceCreatorProps, CustomInvoiceCreatorState> {
  modalRef = React.createRef<YesNoModal>();

  constructor(props: CustomInvoiceCreatorProps) {
    super(props);
    this.state = {
      rows: [],
      internalVendors: [],
      externalVendors: [],
      internalCustomers: [],
      externalCustomers: [],
      properties: [],
      customer: { label: "Select Bill To", value: "" },
      vendor: { label: "Select Billing Entity", value: "" },
      invoiceDate: new Date(),
      total: 0,
      memo: ""
    }
    this.addRow = this.addRow.bind(this);
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
    this.generate = this.generate.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setVendor = this.setVendor.bind(this);
    this.generateDbInvoice = this.generateDbInvoice.bind(this);
    this.previewInvoice = this.previewInvoice.bind(this);
    this.showModal = this.showModal.bind(this);
    this.setInvoiceDate = this.setInvoiceDate.bind(this);
    this.addTotal = this.addTotal.bind(this);
    this.updateMemoLine = this.updateMemoLine.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const internalVendors = await pullEntitiesVendorType();
    const internalCustomers = await pullEntitiesCustomerType();
    const externalCustomers = await pullExternalCompaniesCustomerType();
    const externalVendors = await pullExternalCompaniesVendorType();
    const properties = await pullProperties();
    this.setState({
      externalVendors: externalVendors,
      externalCustomers: externalCustomers,
      internalCustomers: internalCustomers,
      internalVendors: internalVendors,
      properties: properties
    })
  }

  //#region Functions

  addRow(): void {
    const rows = this.state.rows;
    if (rows.length >= 20) {
      CreateGlobalAlert("Invoices are limited to 20 line items, if you need more please contact Johnny Green.", 2000);
      return;
    }
    const newRow: InvoiceData = {
      description: "",
      costPerUnit: 0,
      isLocked: false
    };
    rows.push(newRow);
    this.setState({
      rows: rows
    })
  }

  save(description: string, costPerUnit: number, index: number): void {
    const rows = this.state.rows;
    const row = rows[index];
    row.description = description;
    row.costPerUnit = costPerUnit;
    row.isLocked = true;
    rows[index] = row;
    this.setState({
      rows: rows
    }, () => this.addTotal())
  }

  edit(description: string, costPerUnit: number, index: number): void {
    const rows = this.state.rows;
    const row = rows[index];
    row.description = description;
    row.costPerUnit = costPerUnit;
    row.isLocked = false;
    rows[index] = row;
    this.setState({
      rows: rows
    })
  }

  remove(index: number): void {
    const rows = this.state.rows;
    const total = this.state.total - rows[index].costPerUnit;

    rows.splice(index, 1);
    this.setState({
      rows: rows,
      total: total
    })
  }

  async generate(url: string): Promise<void> {
    if (this.state.vendor.value === "" || this.state.customer.value === "") {
      CreateGlobalAlert("Please Check Customer and Vendor Fields", 2500)
      return
    }

    const rows = this.state.rows;
    const tableData = [];
    for (let i = 0; i < rows.length; ++i) {
      tableData.push({
        Description: rows[i].description,
        CostPerUnit: rows[i].costPerUnit
      })
    }
    const data = {
      TableData: tableData,
      Customer: this.state.customer.value,
      Vendor: this.state.vendor.value,
      EmployeeId: getUserID(),
      InvoiceDate: this.state.invoiceDate.toISOString(),
      Memo: this.state.memo
    }
    let response = await axios.post(url, data, { responseType: 'blob' });
    await DownloadNamedFile(response);
  }

  setCustomer(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      customer: event
    });
  }

  setVendor(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      vendor: event
    });
  }

  setInvoiceDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      invoiceDate: new Date(event.target.valueAsDate)
    })
  }

  generateDbInvoice(): void {
    this.generate('./api/get-invoice/custom');
  }

  previewInvoice(): void {
    this.generate('./api/get-invoice/custom/preview');
  }

  showModal(): void {
    this.modalRef.current?.show(
      "Once this invoice is generated you will no longer be able to edit it. Please make sure you are confident the invoice is correct.",
      "Are you sure the invoice is ready?",
      () => console.log('nope'),
      () => this.generateDbInvoice(),
    )
  }

  addTotal(): void {
    let total = 0;
    this.state.rows.forEach((item) => {
      total += item.costPerUnit
    })
    this.setState({ total: total })
  }

  updateMemoLine(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ memo: event.target.value })
  }

  //#endregion

  render(): JSX.Element {
    return (
      <div>
        <YesNoModal ref={this.modalRef} />
        <Row>
          <Col>
            <label>Billing Entity</label>
            <br />
            <Select
              options={[
                { label: "Entities", options: this.state.internalVendors },
                { label: "External Companies", options: this.state.externalVendors },
                { label: "Properties", options: this.state.properties }
              ]}
              styles={reactSelectBasicStyle}
              value={this.state.vendor}
              onChange={this.setVendor}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
          <Col>
            <label>Bill To</label>
            <br />
            <Select
              options={[
                { label: "Entities", options: this.state.internalCustomers },
                { label: "External Companies", options: this.state.externalCustomers },
                { label: "Properties", options: this.state.properties }
              ]}
              styles={reactSelectBasicStyle}
              value={this.state.customer}
              onChange={this.setCustomer}
              onFocus={RemoveStickyOverlays}
              onBlur={RestoreStickyOverlays}
            />
          </Col>
          <Col>
            <label>Invoice Date</label>
            <br />
            <DatePicker onChange={this.setInvoiceDate} value={this.state.invoiceDate} />
          </Col>
        </Row>
        <Row>
          <Col><label>Memo:</label></Col>
          <Col></Col>
        </Row>
        <Row>
          <Col><input type="text" className="standard-input" style={{ width: "26vw" }} value={this.state.memo} onChange={this.updateMemoLine} /></Col>
          <Col></Col>
        </Row>
        <hr />
        <div className="scrollable-table-container" style={{ minHeight: "0vh", maxHeight: "70vh" }}>
          <table id="invoice-table" className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Description</th>
                <th className="fixedTableHeader">Amount</th>
                <th className="ten fixedTableHeader">Edit</th>
                <th className="ten fixedTableHeader">Add/Remove</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.rows.map((item, idx) => (
                  <InvoiceDataRow key={idx} description={item.description}
                    costPerUnit={item.costPerUnit} save={this.save} remove={this.remove} isLocked={item.isLocked}
                    index={idx} addTotal={this.addTotal} edit={this.edit}
                  />
                ))
              }
              <TableAdditionRow onClick={this.addRow} length={4} marginLeft={"35%"} />
            </tbody>
          </table>
        </div>
        <Row style={{ marginRight: "8vw" }}>
          <label style={{ textAlign: "right" }}>Total: $ {numberWithCommas(this.state.total)}</label>
        </Row>
        <br />
        <Row>
          <Col>
            <input className="standard-input" type="button" value="Preview Invoice" onClick={this.previewInvoice} />
          </Col>
          <Col>
            <input className="standard-input" type="button" value="Generate Invoice" onClick={this.showModal} />
          </Col>
        </Row>
        <br />
        <input className="standard-input" type="button" style={{ width: "100%" }}
          onClick={() => window.location.assign("./invoice-list")} value="View Generated Invoices"
        />
      </div>
    )
  }
}