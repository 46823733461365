import axios from 'axios';
import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Structs from './interfaces';

//#region Warning Modal
export class WarningModal extends React.Component<Structs.WarningModalData, Structs.WarningModalData> {

  constructor(props: Structs.WarningModalData) {
    super(props);
    this.state = {
      showing: false,
      message: props.message,
      title: "Warning",
      callback: props.callback,
      closeText: "Close",
      disableForceClose: false,
      innerHtml: ""
    };
    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
    this.forceClose = this.forceClose.bind(this);
  }

  show(message: string, title: string = "Warning", callback: () => void = undefined, closeText: string = "Close", disableForceClose: boolean = false, innerHtml: string = ""): void {
    this.setState({
      showing: true,
      message: message,
      title: title,
      callback: callback,
      closeText: closeText,
      disableForceClose: disableForceClose,
      innerHtml: innerHtml
    })
  }

  forceClose(): void {
    if (this.state.disableForceClose) { return; }
    this.setState({ showing: false })
  }

  close(): void {
    if (this.state.callback) {
      this.state.callback();
    }
    this.setState({ showing: false })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderBottom: "0px solid #15405c", borderRadius: "25%" }} backdrop="static">
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.forceClose} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            {this.state.title}
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          {!this.state.innerHtml ?
            <ModalBody style={{ border: "0px solid #15405c" }}>
              <h5 style={{ color: "#c2a877", width: "100%", textAlign: "center" }}>{this.state.message}</h5>
            </ModalBody>
            :
            <ModalBody style={{ border: "0px solid #15405c" }} dangerouslySetInnerHTML={{ __html: this.state.innerHtml && this.state.innerHtml }}>
            </ModalBody>
          }

          <ModalFooter style={{ border: "0px solid black" }}>
            <input type="button" value={this.state.closeText} className="standard-input" style={{
              backgroundColor: "#15405c",
              color: "#b89961",
            }} onClick={this.close} />
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}
//#endregion

export class YesNoModal extends React.Component<Structs.YesNoModalDataProps, Structs.YesNoModalDataState> {

  constructor(props: Structs.YesNoModalDataProps) {
    super(props);
    this.state = {
      showing: false,
      title: '',
      message: '',
      negFunc: this.default,
      yesFunc: this.default,
      yesValue: "Yes",
      noValue: "No"
    }
    this.show = this.show.bind(this);
  }

  default(): void {

  }
  /**
   * 
   * @param message -> Message of the Modal
   * @param title -> Title of the Modal
   * @param negFunc -> Function to run if the No button is hit
   * @param yesFunc -> Function to run if the Yes button is hit
   * @param yesValue -> Text of the Yes Button
   * @param noValue -> Text of the No Button
   */
  show(message: string, title: string, negFunc: () => void, yesFunc: () => void, yesValue: string = "Yes", noValue: string = "No"): void {
    this.setState({
      message: message,
      title: title,
      negFunc: negFunc,
      yesFunc: yesFunc,
      yesValue: yesValue,
      noValue: noValue,
      showing: true
    })
  }

  hide(): void {
    this.setState({
      message: "",
      title: "",
      negFunc: () => console.log("Yes No Modal Not Initialized"),
      yesFunc: () => console.log("Yes No Modal Not Initialized"),
      yesValue: "Yes",
      noValue: "No",
      showing: false
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing}>
        <ModalHeader style={{ color: "#15405c" }} tag="h3" toggle={() => this.setState({ showing: false })}>
          {this.state.title}
        </ModalHeader>
        <ModalBody>
          <h5 style={{ color: "#15405c", width: "100%", textAlign: "center" }}>{this.state.message}</h5>
        </ModalBody>
        <ModalFooter>
          <input type="button" value={this.state.noValue} style={{
            backgroundColor: "#15405c",
            color: "#c2a877"
          }} onClick={() => this.setState({ showing: false }, this.state.negFunc)} />
          <input type="button" value={this.state.yesValue} style={{
            backgroundColor: "#15405c",
            color: "#c2a877"
          }} onClick={() => this.setState({ showing: false }, this.state.yesFunc)} />
        </ModalFooter>
      </Modal>
    )
  }
}

//#region Status Modal
interface StatusModalProps {

}

interface StatusModalState {
  showing: boolean;
  title: string;
  message: string;
  key: string;
  statusStep: string;
  statusValue: number;
  abortController: AbortController;
}

export class StatusModal extends React.Component<StatusModalProps, StatusModalState>{

  intervalCheck: NodeJS.Timer;

  constructor(props: StatusModalProps) {
    super(props);
    this.state = {
      showing: false,
      title: "",
      message: "",
      key: "",
      statusStep: "",
      statusValue: 0,
      abortController: null
    }
    this.checkKey = this.checkKey.bind(this);
  }

  display(title: string, message: string, key: string = "", interval: number = 750): void {
    this.setState({
      showing: true,
      title: title,
      message: message,
      key: key
    });
    if (key !== "") {
      this.intervalCheck = setInterval(this.checkKey, interval);
    }
  }

  hide(): void {
    if (this.state.key !== "") {
      clearInterval(this.intervalCheck);
    }
    this.setState({
      showing: false,
      statusStep: "",
      statusValue: 0,
      key: ""
    })
  }

  async checkKey(): Promise<void> {
    if (this.state.key !== "") {
      if (this.state.abortController) { this.state.abortController.abort() };
      let controller = new AbortController();
      this.setState({ abortController: controller });
      let response = await axios.get('./api/status-manager/' + this.state.key, { signal: this.state.abortController.signal });
      if (response.status === 200) {
        this.setState({
          statusStep: response.data.step,
          statusValue: response.data.progress
        })
      }
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing}>
        <div style={{ background: "#4c4a42" }}>
          <ModalHeader style={{ backgroundColor: "#c2a877" }} tag="h3">
            {this.state.title}
          </ModalHeader>
          <ModalBody>
            <h5>{this.state.message}</h5>
            <div className="lds-dual-ring" style={{ marginLeft: "42%", marginTop: "3%", verticalAlign: "center" }}></div>
            <div>
              {this.state.key !== "" &&
                <>
                  <progress id="progress-bar" className="progress-bar" value={this.state.statusValue} max="100" style={{}}>
                  </progress>
                  <label>{this.state.statusStep}</label>
                </>
              }
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}

//#endregion

interface FileEntryModalProps {

}

interface FileEntryModalState {
  show: boolean;
  title: string;
  file: File;
  fileType: string;
  callback: () => void;
}

export class FileEntryModal extends React.Component<FileEntryModalProps, FileEntryModalState> {

  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      title: "",
      file: null,
      fileType: "",
      callback: undefined
    }
    this.changeFile = this.changeFile.bind(this);
    this.show = this.show.bind(this);
    this.callback = this.callback.bind(this);
  }

  changeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      file: event.target.files[0]
    })
  }

  show(title: string, fileType: string, callback: () => void) {
    this.setState({
      title: title,
      show: true,
      fileType: fileType,
      callback: callback
    })
  }

  callback(): void {
    this.setState({
      show: false
    });
    if (this.state.callback) {
      this.state.callback();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <ModalHeader style={{ backgroundColor: "#4C4A42" }}>
          <h3 style={{ color: "#d7c7a7" }}>{this.state.title}</h3>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42" }}>
          <input type="file" accept={this.state.fileType} onChange={this.changeFile} />
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
          <input type="button" value="Submit" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
          />
        </ModalFooter>
      </Modal>

    )
  }
}