import axios from "axios";
import { SelectOptions } from "../interfaces/CoreInterfaces";


/**
 * 
 * @param uid
 * @param value
 * @param url
 * @returns
 */
export async function patchStringValue(uid: string, value: string, url: string): Promise<boolean> {
  // skip if creating new entity
  if (uid === "") { return; }
  const data = { UID: uid, Value: value };
  let response = await axios.patch(url, data);
  return response.status === 202;
}

/**
 * 
 * @param uid
 * @param value
 * @param url
 * @returns
 */
export async function patchNumberValue(uid: string, value: number, url: string): Promise<boolean> {
  // skip if creating new entity
  if (uid === "") { return; }
  const data = { UID: uid, Value: value };
  let response = await axios.patch(url, data);
  return response.status === 202;
}

/**
 * 
 * @param uid
 * @param name
 * @param url
 * @returns
 */
export async function patchBooleanValue(uid: string, value: boolean, url: string): Promise<boolean> {
  // skip if creating new entity
  if (uid === "") { return; }
  const data = { UID: uid, Value: value };
  let response = await axios.patch(url, data);
  return response.status === 202;
}

/**
 * 
 * @param uid
 * @param cage
 * @returns
 */
export async function patchDateValue(uid: string, value: Date, url: string): Promise<boolean> {
  if (uid === "") { return; }
  const data = { UID: uid, Value: value.toISOString() };
  let response = await axios.patch(url, data);
  return response.status === 202;
}

/**
 * 
 * @param uid
 * @param signers
 * @returns
 */
export async function patchListSelectOptions(uid: string, values: Array<SelectOptions>, url: string): Promise<boolean> {
  if (uid === "") { return; }
  const data = { UID: uid, Values: values.map(u => u.value) };
  let response = await axios.patch(url, data);
  return response.status === 202;
}


/**
* 
* @param uid
* @param signers
* @returns
*/
export async function patchSelectOptions(uid: string, value: SelectOptions, url: string): Promise<boolean> {
  if (uid === "") { return; }
  const data = { UID: uid, Values: value };
  let response = await axios.patch(url, data);
  return response.status === 202;
}
