import * as React from 'react';
import { Col, Row } from 'reactstrap';
import PropertyOnboardingModal from './OnboardingAndOffboardingTriggerWidget.PropertyOnboardingModal';
import PropertyOffboardingModal from './OnboardingAndOffboardingTriggerWidget.PropertyOffboardingModal';
import { WarningModal } from '../../CoreComponents/Modals';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface OnboardingAndOffboardingTriggerWidgetState {

}

export default class OnboardingAndOffboardingTriggerWidget extends React.Component<{}, OnboardingAndOffboardingTriggerWidgetState> {

  propertyOnboardingModal: React.RefObject<PropertyOnboardingModal> = React.createRef<PropertyOnboardingModal>();
  propertyOffboardingModal: React.RefObject<PropertyOffboardingModal> = React.createRef<PropertyOffboardingModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = {}
    this.propertyOnboardingCallback = this.propertyOnboardingCallback.bind(this);
    this.propertyOffboardingCallback = this.propertyOffboardingCallback.bind(this);
  }

  propertyOffboardingCallback(success: boolean) {
    this.propertyOffboardingModal.current.hide();
    if (success) {
      this.warningModal.current.show("Property Offboarding has been started. All parties have been emailed with a notification of property offboard", "Property Offboard Created");
    }
    else {
      this.warningModal.current.show("Error creating property offboard. Please contact the tech team so they can investigate", "Error.");
    }
  }

  propertyOnboardingCallback(success: boolean) {
    this.propertyOnboardingModal.current.hide();
    if (success) {
      this.warningModal.current.show("Property Onboarding has been started. All parties have been emailed with a notification of property onboard", "Property Onboard Created");
    }
    else {
      this.warningModal.current.show("Error creating property onboard. Please contact the tech team so they can investigate", "Error.");
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <PropertyOnboardingModal ref={this.propertyOnboardingModal} callback={this.propertyOnboardingCallback} />
        <PropertyOffboardingModal ref={this.propertyOffboardingModal} callback={this.propertyOffboardingCallback} />
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Start Onboard or Offboard")}
        <Row>
          <Col>
            <h6 style={{ marginTop: "5px" }}>Property Onboard</h6>
          </Col>
          <Col>
            <input type="button" value="Start" className="standard-input" onClick={() => this.propertyOnboardingModal.current.show()} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6 style={{ marginTop: "5px" }}>Property Offboard</h6>
          </Col>
          <Col>
            <input type="button" value="Start" className="standard-input" onClick={() => this.propertyOffboardingModal.current.show()} />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6 style={{ marginTop: "5px" }}>Employee Onboard</h6>
          </Col>
          <Col>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6 style={{ marginTop: "5px" }}>Employee Offboard</h6>
          </Col>
          <Col>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
      </div>
    )
  }
}