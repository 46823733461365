//#region Imports
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { getUserID } from '../../../functions/authActions';
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';
import { pullEntities, pullEntityContractors, pullFinanceLeads, pullFleetEmployee } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { DatePicker } from '../../CoreComponents/DateComponents';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { CheckboxTableRow, EditSelectOptionsTableRow, FixedSelectOptionsTableRow, FixedTextboxTableRow } from '../../CoreComponents/SettingsTable';
import * as Structs from '../Structs';
import { pullEmployeeName } from '../../../functions/fetchObjectNameFunctions';
import { WarningModal } from '../../CoreComponents/Modals';
//#endregion

export class ActiveJobSettings extends React.Component<Structs.ActiveJobSettingsProps, Structs.ActiveJobSettingsState> {

  constructor(props: Structs.ActiveJobSettingsProps) {
    super(props);
    this.state = {
      uid: props.uid,
      name: '',

      entity: { label: "Select Entity", value: "" },
      entities: [],

      contractor: { label: "Select Contractor", value: "" },
      contractors: [],

      financeLead: [],
      financeLeads: [],

      contractDate: new Date(),

      createdBy: '',
      createdOn: new Date(),
      jobLeads: "",
      agencySignature: false,
      rdProject: false,
      useArchitect: false,
      completed: false,
      ownerName: "",
      ownerEmail: "",
      notarySigner: "",
      notarySignerName: "",
      waiverSigner: "",
      secondSigner: "",
      secondSignerName: "",
      firstApprover: { label: "Select First Approver", value: "" },
      firstApproverOptions: [],
      editing: true,
      adding: true,

      headers: this.generateHeaders()
    }
    this.generateHeaders = this.generateHeaders.bind(this);

    this.updateOwnerName = this.updateOwnerName.bind(this);
    this.saveOwnerName = this.saveOwnerName.bind(this);
    this.updateOwnerEmail = this.updateOwnerEmail.bind(this);
    this.saveOwnerEmail = this.saveOwnerEmail.bind(this);

    this.updateNotarySigner = this.updateNotarySigner.bind(this);
    this.saveNotarySigner = this.saveNotarySigner.bind(this);
    this.updateNotarySignerName = this.updateNotarySignerName.bind(this);
    this.saveNotarySignerName = this.saveNotarySignerName.bind(this);

    this.updateWaiverSigner = this.updateWaiverSigner.bind(this);
    this.saveWaiverSigner = this.saveWaiverSigner.bind(this);

    this.updateAgencySignature = this.updateAgencySignature.bind(this);
    this.saveAgencySignature = this.saveAgencySignature.bind(this);

    this.updateCompleted = this.updateCompleted.bind(this);
    this.saveCompleted = this.saveCompleted.bind(this);

    this.updateRDProject = this.updateRDProject.bind(this);
    this.saveRDProject = this.saveRDProject.bind(this);

    this.updateJobName = this.updateJobName.bind(this);
    this.updateFinanceLeads = this.updateFinanceLeads.bind(this);
    this.saveFinanceLeads = this.saveFinanceLeads.bind(this);

    this.updateEntity = this.updateEntity.bind(this);
    this.updateContractor = this.updateContractor.bind(this);

    this.updateSecondSigner = this.updateSecondSigner.bind(this);
    this.updateSecondSignerName = this.updateSecondSignerName.bind(this);
    this.saveSecondSigner = this.saveSecondSigner.bind(this);
    this.saveSecondSignerName = this.saveSecondSignerName.bind(this);
   
    this.updateUseArchitect = this.updateUseArchitect.bind(this);
    this.saveUseArchitect = this.saveUseArchitect.bind(this);

    this.createJob = this.createJob.bind(this);

    this.updateContractDate = this.updateContractDate.bind(this);
    this.saveContractDate = this.saveContractDate.bind(this);

    this.updateFirstApprover = this.updateFirstApprover.bind(this);
    this.saveFirstApprover = this.saveFirstApprover.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    const financeLeads = await pullFinanceLeads();
    const entities = await pullEntities();
    const contractors = await pullEntityContractors();
    const employees = await pullFleetEmployee()
    this.setState({
      financeLeads: financeLeads,
      entities: entities,
      contractors: contractors,
      firstApproverOptions: employees
    })
    if (this.state.uid !== '') {
      await axios.get("./api/managed-jobs/job-information/" + this.state.uid).then(function (response) {
        const data = response.data;
        let financeLeads: Array<any> = [];
        data.financeLeads.map((item: any) => (
          financeLeads.push({
            label: item.label,
            value: item.value
          })
        ))
        self.setState({
          name: data.name,
          entity: { label: data.entity, value: data.entityUID },
          contractor: { label: data.contractor, value: data.contractorUID },
          financeLead: financeLeads,
          createdBy: data.createdBy,
          createdOn: new Date(data.createdOn),
          contractDate: new Date(data.contractDate),
          jobLeads: data.jobLeads,
          agencySignature: data.agencySignature,
          useArchitect: data.useArchitect,
          completed: data.completed,
          ownerName: data.ownerName,
          ownerEmail: data.ownerEmail,
          notarySigner: data.notarySigner,
          notarySignerName: data.notarySignerName,
          waiverSigner: data.waiverSigner,
          rdProject: data.rdProject,
          secondSigner: data.secondSigner,
          secondSignerName: data.secondSignerName,
          firstApprover: { label: data.firstApproverName, value: data.firstApprover },
          editing: false,
          adding: false
        })
      });
    }
    else {
      let name = await pullEmployeeName(getUserID());
      this.setState({ createdBy: name });
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Setting",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
  }

  //#region Update Functions for Creating, but no patch methods

  updateJobName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      name: event.target.value
    })
  }

  updateEntity(event: SelectOptions | null): void {
    this.setState({
      entity: event
    })
  }

  updateContractor(event: SelectOptions | null): void {
    this.setState({
      contractor: event
    })
  }

  updateFinanceLeads(event: SelectOptions[] | null): void {
    if (event === null) { return; }
    this.setState({
      financeLead: event
    })
  }

  async saveFinanceLeads(): Promise<void> {
    var ids = this.state.financeLead;
    var _out = [];
    for (var i = 0; i < ids.length; ++i) {
      _out.push(ids[i].value);
    }
    const data = {
      JobUID: this.state.uid,
      FinanceLeadList: _out
    };
    await axios.patch('./api/managed-jobs/update-finance-leads', data).then(function (response) {
      CreateGlobalAlert("Finance Leads have been updated and Saved");
    });
  }

  //#endregion

  //#region Edit Owner Name
  updateOwnerName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      ownerName: event.target.value
    })
  }

  async saveOwnerName(): Promise<void> {
    await axios.patch('./api/managed-jobs/owner-name', { JobUID: this.state.uid, OwnerName: this.state.ownerName }).then(function (response) {
      CreateGlobalAlert("Owner Name Updated Successfully", 1500);
    })
  }
  //#endregion

  //#region Edit Owner Email
  updateOwnerEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      ownerEmail: event.target.value
    })
  }

  async saveOwnerEmail(): Promise<void> {
    await axios.patch('./api/managed-jobs/owner-email', { JobUID: this.state.uid, OwnerEmail: this.state.ownerEmail }).then(function (response) {
      CreateGlobalAlert("Owner Email Updated Successfully", 1500);
    })
  }
  //#endregion

  //#region Edit Notary Signer
  updateNotarySigner(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      notarySigner: event.target.value
    })
  }

  async saveNotarySigner(): Promise<void> {
    await axios.patch('./api/managed-jobs/notary-signer', { JobUID: this.state.uid, NotarySigner: this.state.notarySigner }).then(function (response) {
      CreateGlobalAlert("Notary Signer Updated Successfully", 1500);
    })
  }

  updateNotarySignerName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      notarySignerName: event.target.value
    })
  }

  async saveNotarySignerName(): Promise<void> {
    await axios.patch('./api/managed-jobs/notary-signer-name', { JobUID: this.state.uid, NotarySignerName: this.state.notarySignerName }).then(function (response) {
      CreateGlobalAlert("Notary Signer Name Updated Successfully", 1500);
    })
  }
  //#endregion

  //#region Agency Signature
  updateAgencySignature(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      agencySignature: event.target.checked
    })
  }

  async saveAgencySignature(): Promise<void> {
    await axios.patch('./api/managed-jobs/agency-signature', { JobUID: this.state.uid, AgencySignature: this.state.agencySignature }).then(function (response) {
      CreateGlobalAlert("Agency Signature Updated Successfully", 1500);
    })
  }
  //#endregion

  //#region update Use Architect
  updateUseArchitect(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      useArchitect: event.target.checked
    })
  }

  async saveUseArchitect(): Promise<void> {
    await axios.patch('./api/managed-jobs/use-architect', { JobUID: this.state.uid, UseArchitect: this.state.useArchitect }).then(function (response) {
      CreateGlobalAlert("Job's Use Architect Flag has been updated Changed Successfully", 1500);
    })
  }
  //#endregion

  //#region Completed
  updateCompleted(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      completed: event.target.checked
    })
  }

  async saveCompleted(): Promise<void> {
    await axios.patch('./api/managed-jobs/completed', { JobUID: this.state.uid, Completed: this.state.completed }).then(function (response) {
      CreateGlobalAlert("Project has been marked as Completed Successfully", 1500);
    })
  }

  //#endregion

  //#region RD Project
  updateRDProject(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      rdProject: event.target.checked
    })
  }

  async saveRDProject(): Promise<void> {
    await axios.patch('./api/managed-jobs/rd-project', { JobUID: this.state.uid, RDProject: this.state.rdProject }).then(function (response) {
      CreateGlobalAlert("Job's RD Project Changed Successfully", 1500);
    })
  }
  //#endregion

  //#region Waiver Signer
  updateWaiverSigner(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      waiverSigner: event.target.value
    })
  }

  async saveWaiverSigner(): Promise<void> {
    await axios.patch('./api/managed-jobs/waiver-signer', { JobUID: this.state.uid, WaiverSigner: this.state.waiverSigner }).then(function (response) {
      CreateGlobalAlert("Waiver Signer Updated Successfully", 1500);
    })
  }
  //#endregion

  //#region Contract Date
  updateContractDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      contractDate: new Date(event.target.valueAsDate)
    })
  }

  async saveContractDate(): Promise<void> {
    await axios.patch('./api/managed-jobs/contract-date', { JobUID: this.state.uid, ContractDate: this.state.contractDate.toISOString() }).then(function (response) {
      CreateGlobalAlert("Contract Date Updated Successfully", 1500);
    })
  }
  //#endregion

  //#region Second Signer & First Approver Information
  updateSecondSignerName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      secondSignerName: event.target.value
    })
  }

  async saveSecondSignerName(): Promise<void> {
    await axios.patch('./api/managed-jobs/second-signer-name', { JobUID: this.state.uid, SecondSignerName: this.state.secondSignerName }).then(function (response) {
      CreateGlobalAlert("Second Signer Name Updated Successfully", 1500);
    })
  }

  updateSecondSigner(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      secondSigner: event.target.value
    })
  }

  async saveSecondSigner(): Promise<void> {
    await axios.patch('./api/managed-jobs/second-signer', { JobUID: this.state.uid, SecondSigner: this.state.secondSigner }).then(function (response) {
      CreateGlobalAlert("Second Signer Updated Successfully", 1500);
    })
  }

  updateFirstApprover(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      firstApprover: event
    })
  }

  async saveFirstApprover(): Promise<void> {
    await axios.patch('./api/managed-jobs/first-approver', { JobUID: this.state.uid, ApproverUID: this.state.firstApprover.value }).then(function (response) {
      CreateGlobalAlert("First Approver has been updated", 1500);
    })
  }
  //#endregion

  async createJob(): Promise<void> {
    const data: any = {
      Job: {
        CreatedByUID: getUserID(),
        Created: new Date(),
        JobName: this.state.name,
        ContractDate: this.state.contractDate,
        IsCompleted: false,
        AgencySignature: this.state.agencySignature,
        RDProject: this.state.rdProject,
        UseArchitect: this.state.useArchitect,
        OwnerName: this.state.ownerName,
        OwnerEmail: this.state.ownerEmail,
        SecondSigner: this.state.secondSigner,
        SecondSignerName: this.state.secondSignerName,
        NotarySignerName: this.state.notarySignerName,
        NotarySigner: this.state.notarySigner,
        WaiverSigner: this.state.waiverSigner,
        FirstApproverUID: this.state.firstApprover.value,
      },
      FinanceLeads: [],
      Projects: [],
      EntityUID: this.state.entity.value,
      GeneralContractorUID: this.state.contractor.value
    }
    this.state.financeLeads.forEach(function (lead) {
      data.FinanceLeads.push(lead.value)
    });
    await axios.post('./api/managed-jobs', data).then(function (response) {
      CreateGlobalAlert("Job Successfully Created. Refreshing Page to Prevent Duplication", 2000, () => window.location.replace("/create-new-job?uid=" + response.data.uid));
    })
  }

  render(): JSX.Element {
    return (
      <div>
        <h3>Job Settings</h3>
        <div style={{ maxHeight: "78vh", overflowY: "scroll", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              <FixedTextboxTableRow title="Job Name" adding={this.state.adding}
                value={this.state.name} update={this.updateJobName}
              />
              <FixedSelectOptionsTableRow title="Entity Name" adding={this.state.adding}
                value={this.state.entity} values={this.state.entities} isMulti={false}
                update={this.updateEntity} save={null} menuPlacement="bottom"
              />
              <FixedSelectOptionsTableRow title="General Contractor" adding={this.state.adding}
                value={this.state.contractor} values={this.state.contractors} isMulti={false}
                update={this.updateContractor} save={null} menuPlacement="top"
              />
              <DatePickerTableRow title="Contract Date" adding={this.state.adding}
                editing={this.state.editing} value={this.state.contractDate}
                update={this.updateContractDate} save={this.saveContractDate}
              />
              <tr>
                <td className="fixedTableCellFirst">Created By</td>
                <td className="fixedTableCell">{this.state.createdBy}</td>
                <td className="fixedTableCell"></td>
              </tr>
              <tr>
                <td className="fixedTableCellFirst">Created On</td>
                <td className="fixedTableCell">{this.state.createdOn.toLocaleDateString()}</td>
                <td className="fixedTableCell"></td>
              </tr>
              <EditSelectOptionsTableRow title="Finance Leads" adding={this.state.adding}
                value={this.state.financeLead} values={this.state.financeLeads} editing={this.state.editing}
                isMulti={true} update={this.updateFinanceLeads} save={this.saveFinanceLeads} menuPlacement="top"
              />
              <CheckboxTableRow title="Agency Signature" adding={this.state.adding}
                editing={this.state.editing} value={this.state.agencySignature}
                update={this.updateAgencySignature} save={this.saveAgencySignature}
              />
              <CheckboxTableRow title="RD Project" adding={this.state.adding}
                editing={this.state.editing} value={this.state.rdProject}
                update={this.updateRDProject} save={this.saveRDProject}
              />
              <CheckboxTableRow title="Use Architect" adding={this.state.adding}
                editing={this.state.editing} value={this.state.useArchitect}
                update={this.updateUseArchitect} save={this.saveUseArchitect}
              />
              <CheckboxTableRow title="Completed" adding={this.state.adding}
                editing={this.state.editing} value={this.state.completed}
                update={this.updateCompleted} save={this.saveCompleted}
              />
              <EditTextboxTableRow title="Owner Name" adding={this.state.adding}
                editing={this.state.editing} value={this.state.ownerName}
                update={this.updateOwnerName} save={this.saveOwnerName}
              />
              <EditTextboxTableRow title="Owner Email" adding={this.state.adding}
                editing={this.state.editing} value={this.state.ownerEmail}
                update={this.updateOwnerEmail} save={this.saveOwnerEmail}
              />
              <EditTextboxTableRow title="Notary Signer Name" adding={this.state.adding}
                editing={this.state.editing} value={this.state.notarySignerName}
                update={this.updateNotarySignerName} save={this.saveNotarySignerName}
              />
              <EditTextboxTableRow title="Notary Signer" adding={this.state.adding}
                editing={this.state.editing} value={this.state.notarySigner}
                update={this.updateNotarySigner} save={this.saveNotarySigner}
              />
              <EditTextboxTableRow title="Waiver Signer" adding={this.state.adding}
                editing={this.state.editing} value={this.state.waiverSigner}
                update={this.updateWaiverSigner} save={this.saveWaiverSigner}
              />
              <EditTextboxTableRow title="Second Signer Name (Architect)" adding={this.state.adding}
                editing={this.state.editing} value={this.state.secondSignerName}
                update={this.updateSecondSignerName} save={this.saveSecondSignerName}
              />
              <EditTextboxTableRow title="Second Signer (Architect)" adding={this.state.adding}
                editing={this.state.editing} value={this.state.secondSigner}
                update={this.updateSecondSigner} save={this.saveSecondSigner}
              />
              <EditSelectOptionsTableRow title="First Approver" adding={this.state.adding} editing={this.state.editing}
                value={this.state.firstApprover} isMulti={false} values={this.state.firstApproverOptions}
                update={this.updateFirstApprover} save={this.saveFirstApprover} menuPlacement="top"
              />
            </tbody>
          </table>
        </div>
        <br />
        {this.state.adding && <input type="button" className="standard-input" value="Save Job" onClick={this.createJob} />}
        {this.state.editing && !this.state.adding && <input type="button" className="standard-input" value="Stop Editing" onClick={() => this.setState({ editing: false })} />}
        {!this.state.editing && !this.state.adding && <input type="button" className="standard-input" value="Edit" onClick={() => this.setState({ editing: true })} />}
      </div >
    )
  }
}

interface EditTextboxTableProps {
  adding: boolean;
  editing: boolean;
  value: string;
  update: (event: any) => void;
  save: () => void;
  title: string;
}

const EditTextboxTableRow = (props: EditTextboxTableProps): JSX.Element => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.title}</td>
      {!props.editing ?
        <>
          <td className="fixedTableCell">{props.value}</td>
          <td className="fixedTableCell"></td>
        </>
        :
        <>
          <td className="fixedTableCell">
            <input value={props.value} onChange={props.update} className="standard-input" />
          </td>
          <td className="fixedTableCell">
            {!props.adding && <FontAwesomeIcon icon={faSave} style={{ marginLeft: "30%" }} onClick={props.save} />}
          </td>
        </>
      }
    </tr>
  )
}

interface DatepickerTableProps {
  adding: boolean;
  editing: boolean;
  value: Date;
  update: (event: any) => void;
  save: () => void;
  title: string;
}

const DatePickerTableRow = (props: DatepickerTableProps): JSX.Element => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.title}</td>
      {!props.editing ?
        <>
          <td className="fixedTableCell">{props.value.toLocaleDateString()}</td>
          <td className="fixedTableCell"></td>
        </>
        :
        <>
          <td className="fixedTableCell">
            <DatePicker onChange={props.update} value={props.value} />
          </td>
          <td className="fixedTableCell">
            {!props.adding && <FontAwesomeIcon icon={faSave} style={{ marginLeft: "30%" }} onClick={props.save} />}
          </td>
        </>
      }
    </tr>
  )
}