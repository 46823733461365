import * as React from 'react';
import { ServerTicket } from '../TicketWork/TicketWorkManager.constants';
import axios from 'axios';
import { getUserID } from '../../../functions/authActions';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import { Col, Row } from 'reactstrap';
import TicketSummaryViewModal from './TicketSummaryOnlyWidget.viewModal';

interface TicketSummaryOnlyWidgetState {
  uid: string;
  summary: string;
  actual: string;
  closingNotes: string;
  controller: AbortController;
}

export default class TicketSummaryOnlyWidget extends React.Component<{ uid: string }, TicketSummaryOnlyWidgetState> {

  viewModal: React.RefObject<TicketSummaryViewModal> = React.createRef<TicketSummaryViewModal>();

  constructor(props: {uid: string}) {
    super(props);
    this.state = {
      uid: props.uid,
      summary: "",
      actual: "",
      closingNotes: "",
      controller: null
    }
    this.updateActualProblem = this.updateActualProblem.bind(this);
    this.changeActualProblem = this.changeActualProblem.bind(this);
  }
  
  async componentDidMount(): Promise<void> {
    const ticket: ServerTicket = (await axios.get("api/tickets/" + this.state.uid)).data;
    this.setState({
      summary: ticket.problemSummary,
      closingNotes: ticket.closingNotes,
      actual: ticket.actualProblemSummary
    })
  }


  changeActualProblem(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ actual: event.target.value });
  }


  async updateActualProblem(): Promise<void> {
    const data = { ProblemSummary: this.state.actual, TicketUID: this.state.uid, ModifierUID: getUserID() };
    var controller = new AbortController();
    if (this.state.controller !== null) {
      this.state.controller.abort();
    }
    this.setState({ controller: controller });
    await axios.patch('./api/tickets/actual-problem-summary', data, { signal: controller.signal});
  }

  render(): JSX.Element {
    return (
      <div >
        <TicketSummaryViewModal ref={this.viewModal} />
        {RenderHeader("Ticket Summaries")}
        <div>
          <Row>
            <Col>
              <h5>Submission Summary</h5>
            </Col>
            <Col xs='4'>
              <input type="button" value="View" onClick={() => this.viewModal.current.show(this.state.summary)} className="standard-input" />
            </Col>
          </Row>
          <div style={{ maxHeight: "32vh", minHeight: "32vh", overflowY: "scroll", color: "#d7c7a7"  }}  dangerouslySetInnerHTML={{ __html: this.state.summary }}>
          </div>
        </div>
      </div>
    )
  }
}