import * as React from 'react';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import axios from 'axios';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { OwnershipTypeMap } from '../EntityManagementTable/constants';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface EntityOwnedByTableWidgetProps {
  uid: string;
}

interface EntityOwnedByTableWidgetState {
  headers: Array<FilterAndSettingField>;
  rows: Array<EntityStructureOwnershipData>;
}

export interface EntityStructureOwnershipData {
  uid: string;
  name: string;
  id: string;
  percent: number;
  isManagingMember: boolean;
  type?: number;
}

export default class EntityOwnedByTableWidget extends React.Component<EntityOwnedByTableWidgetProps, EntityOwnedByTableWidgetState> {

  constructor(props: EntityOwnedByTableWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      rows: []
    }
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Entity Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Ownership Percentage",
        setting: true,
        filter: {}
      },
      {
        columnName: "Ownership Type",
        setting: true,
        filter: {}
      },
      {
        columnName: "Managing Member",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      }
    ]
    this.setState({ headers: headers }, async () => await this.pullEntities());
  }

  async pullEntities(): Promise<void> {
    let response = await axios.get("./api/entity-structure/" + this.props.uid);
    if (response.status === 200) {
      const rows: Array<EntityStructureOwnershipData> = [];
      response.data.forEach(function (item: any) {
        rows.push({
          uid: item.uid,
          name: item.ownerEntity.name,
          percent: item.percentageOwned,
          id: item.ownerEntityUid,
          isManagingMember: item.isManagingMember,
          type: item.ownershipType
        })
      })
      this.setState({
        rows: rows
      })
    }
    else {
      //TODO: Need to do Error Handling
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <div style={{ overflow: "auto", height: "30vh" }}>
          {RenderHeader("Owned By Entities")}
          <table id="entity-structure" className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{numberWithCommas(item.percent)}%</td>
                    <td className="fixedTableCell">{OwnershipTypeMap[item.type]}</td>
                    <td className="fixedTableCell">{item.isManagingMember ? "Yes" : "No"}</td>
                    <td className="fixedTableCell"></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Add Owner" className="standard-input" />
      </div>
    )
  }
}