import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import axios from 'axios';
import { pullProperties } from '../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import ScannerTrackingManagementWidget, { ScannerTrackingData } from './ScannerTrackingManagementWidget';

interface ScannerTrackingModalProps {
  callback(): void;
}

interface ScannerTrackingModalState {
  show: boolean;
  properties: Array<SelectOptions>;
  property: SelectOptions;
  bank: SelectOptions;
  serialNumber: string;
  uid: string;
  patch: boolean;
}

export default class ScannerTrackingModal extends React.Component<ScannerTrackingModalProps, ScannerTrackingModalState>{

  constructor(props: ScannerTrackingModalProps) {
    super(props);
    this.state = {
      show: false,
      properties: [],
      property: { label: "Select Property", value: "" },
      bank: { label: "Select Bank", value: "" },
      serialNumber: "",
      patch: false,
      uid: ""
    }
    this.updateProperty = this.updateProperty.bind(this);
    this.updateBank = this.updateBank.bind(this);
    this.updateSerialNumber = this.updateSerialNumber.bind(this);
    this.save = this.save.bind(this);
    this.hide = this.hide.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();

    this.setState({
      properties: properties
    })
  }

  show(edit: ScannerTrackingData = null): void {
    if (edit === null) {
      this.setState({
        show: true,
        uid: "",
        property: { label: "Select Options", value: "" },
        bank: { label: "Select Bank", value: "" },
        serialNumber: "",
        patch: false
      });
    }
    else {
      this.setState({
        show: true,
        uid: edit.uid,
        property: edit.property,
        bank: edit.bank,
        serialNumber: edit.serialNumber,
        patch: true
      });
    }
  }

  hide(): void {
    this.setState({ show: false })
  }

  async updateProperty(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ property: event });
    if (this.state.patch) {
      const data = { uid: this.state.uid, propertyUID: event.value };
      let response = await axios.patch('./api/scanner-tracking/property', data, { validateStatus: () => true });
      if (response.status !== 202) {
        // TODO: HANDLE ERROR
      }
    }
  }

  async updateBank(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ bank: event });
    if (this.state.patch) {
      const data = { uid: this.state.uid, bank: event.value };
      let response = await axios.patch('./api/scanner-tracking/bank', data, { validateStatus: () => true });
      if (response.status !== 202) {
        // TODO: HANDLE ERROR
      }
    }
  }

  async updateSerialNumber(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ serialNumber: event.target.value });
    if (this.state.patch) {
      const data = { uid: this.state.uid, serialNumber: event.target.value };
      let response = await axios.patch('./api/scanner-tracking/serial-number', data, { validateStatus: () => true });
      if (response.status !== 202) {
        // TODO: HANDLE ERROR
      }
    }
  }

  async save(): Promise<void> {
    const data = {
      propertyUID: this.state.property.value,
      serialNumber: this.state.serialNumber,
      bank: this.state.bank.value
    };
    let response = await axios.post('./api/scanner-tracking', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.props.callback();
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Modify Scanner Tracking
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Property</h5>
              </Col>
              <Col>
                <Select
                  options={this.state.properties}
                  value={this.state.property}
                  styles={reactSelectBasicStyle}
                  onChange={this.updateProperty}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Bank</h5>
              </Col>
              <Col>
                <Select
                  options={ScannerTrackingManagementWidget.Banks}
                  value={this.state.bank}
                  styles={reactSelectBasicStyle}
                  onChange={this.updateBank}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Serial Number</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.serialNumber} onChange={this.updateSerialNumber} className="standard-input" />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                {
                  !this.state.patch ?
                    <input type="button" value="Save" className="standard-input" onClick={this.save} />
                    :
                    <input type="button" value="Close" className="standard-input" onClick={this.hide} />
                  }
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}