import * as React from 'react';
import { Collapse, NavbarBrand, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../graphics/all-logos.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'reactstrap';
import { AUTHENTICATION_SETTINGS_STRING, AuthState, Jobs, Teams, ValidateAccess, getUserID } from '../functions/authActions';
import { GetSettings } from '../functions/StateManagement/StateStorageFunctions';

interface NavMenuProps {
}

interface NavMenuState {
  collapsed: boolean;
  accountOptionsOpen: boolean;
  isAuth: boolean;
}

export default class NavMenu extends React.Component<NavMenuProps, NavMenuState> {

  static displayName = NavMenu.name;

  constructor(props: NavMenuProps) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      accountOptionsOpen: false,
      isAuth: false
    };
  }

  async componentDidMount(): Promise<void> {
    let newRole = GetSettings<AuthState>(AUTHENTICATION_SETTINGS_STRING).userRole;
    if (newRole === 1 || await ValidateAccess([Jobs.Technology], [Teams.Services]) || await ValidateAccess([Jobs.HR], [Teams.Human_Resources])) {
      this.setState({ isAuth: true })
    }
  }

  toggleNavbar(): void {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render(): JSX.Element {
    var loginLink = '/logout';
    var loginText = 'Logout';
    return (
      <>
        <Row className="nav-bar-border box-shadow mb-3" style={{ height: "4em", marginBottom: "-15px" }}>
          <Col style={{ marginLeft: "2%", marginTop: "1em", marginBottom: "1em" }}>
            <NavbarBrand tag={Link} style={{ color: "#d7c7a7" }} to="/">Chrisman Command Center</NavbarBrand>
          </Col>
          <Col>
            <img src={logo} style={{ height: "90%", width: "90%", marginLeft: "5%", marginTop: "0%" }} />
          </Col>
          <Col style={{ marginTop: "1em", marginBottom: "1em", marginRight: "2%" }}>
            <Collapse style={{ float: "right" }} className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <Dropdown isOpen={this.state.accountOptionsOpen}
                toggle={() => this.setState({ accountOptionsOpen: !this.state.accountOptionsOpen })}>
                <DropdownToggle style={{
                  backgroundColor: "#15405c",
                  border: "2px solid #d7c7a7",
                  borderRadius: "100%",
                }}>
                  <FontAwesomeIcon icon={faUser}
                    style={{ color: "#d7c7a7", margin: "2px" }}
                    size="sm"
                  />
                </DropdownToggle>
                <DropdownMenu end className="dropdownItem">
                  {this.state.isAuth && <DropdownItem href={"/employees-list"} >Employee List</DropdownItem>}
                  {this.state.isAuth && <DropdownItem divider />}
                  <DropdownItem href={"/employee-account-settings?uid=" + getUserID()}>User Settings</DropdownItem>
                  <DropdownItem divider />
                  {this.state.isAuth && <DropdownItem href="/configurations">Configurations</DropdownItem>}
                  <DropdownItem divider />
                  <DropdownItem href="/logout">Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Collapse>
          </Col>
        </Row>

      </>
    );
  }
}
