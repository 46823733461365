import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import BankAccountWorkflowEntityRequestModal from './BankAccountWorkflowEntityRequestWidget.Modals';
import { pullEntityName } from '../../../functions/fetchObjectNameFunctions';
import { WarningModal } from '../../CoreComponents/Modals';
import axios from 'axios';
import BankAccountReviewRequestModal from './BankAccountWorkflowEntityRequestWidget.ViewModals';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface BankAccountEntityWorkflowRequestProps {
  uid?: string;
}
interface BankAccountEntityWorkflowRequestState {
  headers: Array<FilterAndSettingField>;
  rows: Array<any>;
  entityName: string;
}

export default class BankAccountEntityWorkflowRequests extends React.Component<BankAccountEntityWorkflowRequestProps, BankAccountEntityWorkflowRequestState> {

  requestModal: React.RefObject<BankAccountWorkflowEntityRequestModal> = React.createRef<BankAccountWorkflowEntityRequestModal>();
  reviewModal: React.RefObject<BankAccountReviewRequestModal> = React.createRef<BankAccountReviewRequestModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: BankAccountEntityWorkflowRequestProps) {
    super(props);
    this.state = {
      headers: [],
      rows: [],
      entityName: ""
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    if (this.props.uid) {
      let entityName = await pullEntityName(this.props.uid);
      this.setState({ entityName: entityName }, async () => await this.pullRequests());
    }
    else {
      await this.pullRequests();
    }
  }

  async pullRequests(): Promise<void> {
    let response;
    if (this.props.uid) {
      response = await axios.get('./api/bank-account-entity-request/entity/' + this.props.uid, { validateStatus: () => true });
    }
    else {
      response = await axios.get('./api/bank-account-entity-request/', { validateStatus: () => true });
    }
    this.setState({ rows: response.data });
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Requestor",
        setting: true,
        filter: {}
      }
    ]
    if (!this.props.uid) {
      headers.push({ columnName: "Entity", setting: true, filter: {} });
    }
    headers.push({ columnName: "Documents", setting: true, filter: {}, additionalClasses: "twenty" });
    headers.push({ columnName: "", setting: true, filter: {}, additionalClasses: "fifteen" })
    this.setState({ headers: headers });
  }

  callback(success: boolean) {
    this.requestModal.current.forceClose();
    if (success) {
      this.warningModal.current.show("Bank Account Request Submitted.", "Success.");
    }
    else {
      this.warningModal.current.show("Error submitting request. Please submit a ticket so the tech team can dig into it.", "Error");
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <WarningModal ref={this.warningModal} />
        <BankAccountWorkflowEntityRequestModal ref={this.requestModal} callback={this.callback} />
        <BankAccountReviewRequestModal ref={this.reviewModal} />
        {RenderHeader("Entity Bank Account Requests")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.createdBy}</td>
                    {!this.props.uid && <td className="fixedTableCell">{item.entity}</td>}
                    <td className="fixedTableCell">
                      <input type="button" value="Download" className="standard-input" onClick={() => this.warningModal.current.show("Feature not implemented yet.", "Feature Not Ready")} />
                    </td>
                    <td className="fixedTableCell">
                      <input type="button" value="View" className="standard-input" onClick={() => this.reviewModal.current.show(item.uid)} />
                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        {
          this.props.uid && <input type="button" value="Create Entity Request" className="standard-input" onClick={() => this.requestModal.current.show({ label: this.state.entityName, value: this.props.uid })} />
        }
      </div>
    )
  }
}