import * as React from 'react';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import { Col, Row } from 'reactstrap';
import AdobeSignManagementModal from './AdobeSignManagementWidget.SetupAccountModal';
import axios from 'axios';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { WarningModal } from '../../CoreComponents/Modals';

interface AdobeSignManagementWidgetProps {
  uid: string;
}

interface AdobeSignManagementWidgetState {
  year: number;
  uid: string;
  clientID: string;
  clientSecret: string;
  adobeServer: string;
  transactions: number;
  codeUrl: string;
}

export default class AdobeSignManagementWidget extends React.Component<AdobeSignManagementWidgetProps, AdobeSignManagementWidgetState> {

  managementModal: React.RefObject<AdobeSignManagementModal> = React.createRef<AdobeSignManagementModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: AdobeSignManagementWidgetProps) {
    super(props);
    this.state = {
      uid: props.uid,
      clientID: "",
      clientSecret: "",
      adobeServer: "",
      transactions: 0,
      codeUrl: "",
      year: new Date().getFullYear()
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let response = await axios.get('./api/adobe-sign-credentials/' + this.state.uid);
    if (response.status === 200) {
      this.setState({
        clientID: response.data.clientID.substring(0, 12) + "...",
        clientSecret: (response.data?.clientSecret.substring(0, 12) + "..."),
        adobeServer: (response.data?.adobeServer)
      })
    }
    else {
      this.setState({
        clientID: "Not Configured",
        clientSecret: "Not Configured",
        adobeServer: "Not Configured"
      })
    }
    
  }

  async callback(): Promise<void> {
    let response = await axios.get('./api/adobe-sign-credentials/' + this.state.uid);
    this.setState({
      codeUrl: response.data.getCodeUrl
    }, () => this.warningModal.current.show("", 'URL Received', () => window.location.reload(), "Finish", false,
      `<h6 style='text-align: center, width: 100%'>Please copy the link to follow setup instructions: <a style='background-color: #4C4A42' href=${response.data.getCodeUrl}>Link</a></h6>`));
  }

  render(): JSX.Element {
    return (
      <>
        <AdobeSignManagementModal ref={this.managementModal} callback={this.callback} />
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Adobe Sign Setup")}
        <div style={{ height: "25vh" }}>
          <Row>
            <Col>
              <h6>Client ID</h6>
            </Col>
            <Col>
              <h6>{this.state.clientID}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Client Secret</h6>
            </Col>
            <Col>
              <h6>{this.state.clientSecret}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Adobe Server</h6>
            </Col>
            <Col>
              <h6>{this.state.adobeServer}</h6>
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h6>Transactions ({this.state.year})</h6>
            </Col>
            <Col>
              <h6>{numberWithCommas(this.state.transactions, 0)}</h6>
            </Col>
          </Row>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Setup Account" className="standard-input" onClick={() => this.managementModal.current.show(this.state.uid)} />
      </>
    )
  }
}
