import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { Jobs, Teams, ValidateAccess, getUserID } from "../functions/authActions";
import { ActiveUserSettingsState } from "../reducers/activeUserSettingsReducer";
import { GetSettings } from "../functions/StateManagement/StateStorageFunctions";
import { defaultNavBarSettings } from "./ContactComponents/EmployeeNavBarSettings";

interface SideBarProps {

}

interface SideBarState {
  settings: ActiveUserSettingsState;
  employeeUID: any;
  payrollAccess: boolean;
  mciAccess: boolean;
  showProp: boolean;
  showReporting: boolean;
  showProject: boolean;
  showDocuments: boolean;
  showOther: boolean;
  showContacts: boolean;
  showJobs: boolean;
  showTaxes: boolean;
  showTechTeam: boolean;
}

export default class SideBar extends React.Component<SideBarProps, SideBarState> {

  settingsKey: string = "navigationBarSettings";
  static displayName = SideBar.name;
  pollVar: NodeJS.Timer;

  constructor(props: SideBarProps) {
    super(props);
    var settings: ActiveUserSettingsState = GetSettings(this.settingsKey);
    if (settings == null) {
      settings = defaultNavBarSettings;
    }
    this.state = {
      showProp: false,
      showReporting: false,
      showProject: false,
      showDocuments: false,
      showOther: false,
      showContacts: false,
      showJobs: false,
      showTaxes: false,
      showTechTeam: false,
      settings: settings,
      employeeUID: getUserID(),
      payrollAccess: false,
      mciAccess: false
    }
    this.poll = this.poll.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let payrollAccess = await ValidateAccess([Jobs.HR, Jobs.Technology_Lead], []);
    let mciAccess = await ValidateAccess([Jobs.CFO, Jobs.COO, Jobs.President, Jobs.Administrator, Jobs.Instructor, Jobs.Technology, Jobs.Marketing], [Teams.MCI, Teams.Services]);

    this.setState({
      payrollAccess: payrollAccess,
      mciAccess: mciAccess
    })
    if (window.location.pathname === "/employee-account-settings") {
      this.pollVar = setInterval(this.poll, 750)
    }
  }

  poll(): void {
    var items: ActiveUserSettingsState = GetSettings(this.settingsKey);
    if (JSON.stringify(items) !== JSON.stringify(this.state.settings)) {
      this.setState({
        settings: items
      });
    }
  }

  componentWillUnmount(): void {
    if (window.location.pathname === "/employee-account-settings") {
      clearInterval(this.pollVar);
    }
  }

  render(): JSX.Element {
    return (
      <ListGroup className="side-menu-main-container">
        <ListGroupItem className="side-menu-list" tag="a" href="/">Home</ListGroupItem>
        <ListGroupItem tag="a" href="/task-management" className="side-menu-list">
          Task Management
        </ListGroupItem>
        <ListGroupItem tag="a" href="/submit-supply-request" className="side-menu-list">
          Supply Request
        </ListGroupItem>
        <ListGroupItem className="side-menu-list" hidden={!this.state.settings.propertiesSetting}>Properties <FontAwesomeIcon icon={this.state.showProp ? faArrowUp : faArrowDown} style={{ marginTop: "3px", float: "right" }}
          onClick={(e) => this.setState({ showProp: !this.state.showProp })}
        />
          <ListGroup id="property-menu" className="side-menu-list-container" style={{ display: this.state.showProp ? 'block' : 'none' }}>
            <ListGroupItem tag="a" href="/property-summary-table" className="side-menu-list-item" hidden={!this.state.settings.propPropertiesSetting}>Properties</ListGroupItem>
            <ListGroupItem tag="a" href="/location-analysis" className="side-menu-list-item" hidden={!this.state.settings.propLocationAnalysisSetting}>Location Analysis</ListGroupItem>
          </ListGroup>
        </ListGroupItem>
        <ListGroupItem className="side-menu-list" hidden={!this.state.settings.entitiesSetting}>Entities<FontAwesomeIcon icon={this.state.showContacts ? faArrowUp : faArrowDown} style={{ marginTop: "3px", float: "right" }}
          onClick={(e) => this.setState({ showContacts: !this.state.showContacts })} />
          <ListGroup id="contacts-menu" className="side-menu-list-container" style={{ display: this.state.showContacts ? 'block' : 'none' }}>
            <ListGroupItem tag="a" href="/contact-list" className="side-menu-list-item" hidden={!this.state.settings.entiContactsSetting}>Contacts</ListGroupItem>
            <ListGroupItem tag="a" href="/vendor-dashboard" className="side-menu-list-item" hidden={!this.state.settings.entiExternalCompaniesSetting}>External Companies / Vendors</ListGroupItem>
            <ListGroupItem tag="a" href="/entity-manager" className="side-menu-list-item" hidden={!this.state.settings.entiMasterEntityListSetting}>Master Entity List</ListGroupItem>
            <ListGroup className="side-menu-list-container" hidden={!this.state.settings.entiEntityOwnershipSetting}>
              <ListGroupItem tag="a" href="/entity-structure-manager" className="side-menu-list-item" >Entity Ownership</ListGroupItem>
            </ListGroup>
          </ListGroup>
        </ListGroupItem>
        <ListGroupItem className="side-menu-list" hidden={!this.state.settings.jobManagementSetting}>Job Management
          <FontAwesomeIcon icon={this.state.showJobs ? faArrowUp : faArrowDown} style={{ marginTop: "3px", float: "right" }}
            onClick={(e) => this.setState({ showJobs: !this.state.showJobs })} />
          <ListGroup id="jobs-menu" className="side-menu-list-container" style={{ display: this.state.showJobs ? 'block' : 'none' }}>
            <ListGroupItem tag="a" href="active-job-management" className="side-menu-list-item" hidden={!this.state.settings.jobmActiveJobsSetting}>Active Jobs</ListGroupItem>
            <ListGroupItem tag="a" href="active-job-reporting" className="side-menu-list-item" hidden={!this.state.settings.jobmPayAppReportSetting}>Pay App Report</ListGroupItem>
          </ListGroup>
        </ListGroupItem>
        <ListGroupItem className="side-menu-list" hidden={!this.state.settings.otherToolsSetting}> Other Tools <FontAwesomeIcon icon={this.state.showOther ? faArrowUp : faArrowDown} style={{ marginTop: "3px", float: "right" }}
          onClick={(e) => this.setState({ showOther: !this.state.showOther })}
        />
          <ListGroup id="documents-menu" className=" side-menu-list-container" style={{ display: this.state.showOther ? 'block' : 'none' }} >
            <ListGroupItem tag="a" href="/nofa-mapping-tool" className="side-menu-list-item" hidden={!this.state.settings.otheNOFAMapperSetting}>NOFA Mapper</ListGroupItem>
            <ListGroupItem tag="a" href="/custom-invoice-generator" className="side-menu-list-item" hidden={!this.state.settings.otheInvoiceGeneratorsSetting}>Invoice Generators</ListGroupItem>
            {this.state.payrollAccess && <ListGroupItem tag="a" href="/payroll-processing" className="side-menu-list-item">Payroll Processing</ListGroupItem>}
          </ListGroup>
        </ListGroupItem>
        <ListGroupItem className="side-menu-list" hidden={!this.state.settings.techTeamSetting}> Tech Team <FontAwesomeIcon icon={this.state.showTechTeam ? faArrowUp : faArrowDown} style={{ marginTop: "3px", float: "right" }}
          onClick={(e) => this.setState({ showTechTeam: !this.state.showTechTeam })}
        />
          <ListGroup id="tech-team-menu" className=" side-menu-list-container" style={{ display: this.state.showTechTeam ? 'block' : 'none' }} >
            <ListGroupItem tag="a" href="/knowledge-base-home" className="side-menu-list-item" hidden={!this.state.settings.techKnowledgeBaseSetting}>Knowledge Base Home</ListGroupItem>
            <ListGroupItem tag="a" href="/equipment-manager" className="side-menu-list-item" hidden={!this.state.settings.techEquipmentSetting}>Equipment Management</ListGroupItem>
            <ListGroupItem tag="a" href="/ticket-submission" className="side-menu-list-item" hidden={!this.state.settings.techTicketSubmissionSetting}>Ticket Submission</ListGroupItem>
            {/*<ListGroupItem tag="a" href="/tech-team-projects" className="side-menu-list-item" hidden=*/}
            {/*  {!this.state.settings.techTeamProjectsSetting}>Tech Team Projects*/}
            {/*</ListGroupItem>*/}
            <ListGroupItem tag="a" href="/software-billing-management" className="side-menu-list-item" hidden=
              {!this.state.settings.techSoftwareBillingSetting}>Software Billing
            </ListGroupItem>
          </ListGroup>
        </ListGroupItem>
      </ListGroup>
    );
  }
}