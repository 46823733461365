import * as React from 'react';
import { PieGraphState, PieGraphWidget } from '../../CoreComponents/Widgets/PieGraphWidget';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { SupportDepartment } from '../TicketWork/TicketTableManager.constants';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketSubmissionEmployeeCategoriesState {
  categoryData: PieGraphState;
  categoryKey: string;
  departmentData: PieGraphState;
  departmentKey: string;
  stack: boolean;
}

interface TicketSubmissionEmployeeCategoriesProps {
  uid: string;
}

export default class TicketSubmissionEmployeeCategories extends React.Component<TicketSubmissionEmployeeCategoriesProps, TicketSubmissionEmployeeCategoriesState> {
  colors: Array<string> = ['#316650', '#641C34', '#1D334A', '#6D6552', '#1D1E33']
  container: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  constructor(props: TicketSubmissionEmployeeCategoriesProps) {
    super(props);

    this.state = {
      categoryKey: Math.random().toString(),
      categoryData: {
        width: 50,
        height: 50,
        values: [
          { label: "Expenses", value: 25, color: "#04ea40"},
          { label: "Expenses", value: 50, color: "#a3aea3"},
          { label: "Expenses", value: 25, color: "#333"},
        ]
      },
      departmentKey: Math.random().toString(),
      departmentData: {
        width: 50,
        height: 50,
        values: [
          { label: "Expenses", value: 25, color: "#04ea40" },
          { label: "Expenses", value: 50, color: "#a3aea3" },
          { label: "Expenses", value: 25, color: "#333" },
        ]
      },
      stack: false
    }
  }

  async componentDidMount(): Promise<void> {
    let width = this.container.current.parentElement.style.width;
    if (width === "24%") {
      this.setState({ stack: true });
    }
    let response = await axios.get('./api/tickets/employee-category-data/' + this.props.uid);
    if (response.status === 200) {
      let categories: Array<any> = response.data;
      let count = categories.reduce((a, {count}) => a + count, 0);
      let topThree = categories.slice(0, 5);
      let rest = categories.slice(5);
      let data: any = {
        width: 50,
        height: 50,
        values: []
      };
      topThree.forEach((item, idx) => (
        data.values.push({
          label: item.category,
          value: (item.count / count)*100,
          color: this.colors[idx]
        })
      ))
      if (categories.length > 3) {
        let restCount = rest.reduce((a, { count }) => a + count, 0);
        data.values.push({ label: "Other", value: (restCount / count) * 100, color: '#3E3B32' })
      }
      this.setState({
        categoryData: data,
        categoryKey: Math.random().toString()
      })
    }

    let _response = await axios.get('./api/tickets/employee-department-data/' + this.props.uid);

    if (_response.status === 200) {
      let departments: Array<any> = _response.data;
      let count = departments.reduce((a, { count }) => a + count, 0);
      let topThree = departments.slice(0, 5);
      let rest = departments.slice(5);
      let data: any = { width: 50, height: 50, values: [] };
      topThree.forEach((item, idx) => (
        data.values.push({
          label: SupportDepartment.filter(u => parseInt(u.value) === item.department)[0].label,
          value: (item.count / count) * 100,
          color: this.colors[idx]
        })
      ))
      if (departments.length > 3) {
        let restCount = rest.reduce((a, { count }) => a + count, 0);
        data.values.push({ label: "Other", value: (restCount / count) * 100, color: '#3E3B32' })
      }
      this.setState({
        departmentData: data,
        departmentKey: Math.random().toString()
      })
    }

  }

  render(): JSX.Element {
    return (
      <div ref={this.container}>
        {RenderHeader("Employee Ticket Data")}
        {this.state.stack ? 
           <>
            <Row>
              <Col>
                <PieGraphWidget key={this.state.categoryKey} values={this.state.categoryData.values} title="Categories" />
              </Col>
            </Row>
            <Row>
              <Col>
                <PieGraphWidget key={this.state.departmentKey} values={this.state.departmentData.values} title="Departments" />
              </Col>
            </Row>
          </>
          :
        <Row>
          <Col>
            <PieGraphWidget key={this.state.categoryKey} values={this.state.categoryData.values} title="Categories" />
          </Col>
          <Col>
            <PieGraphWidget key={this.state.departmentKey} values={this.state.departmentData.values} title="Departments" />
          </Col>
          </Row>
        }
      </div>
    )
  }
}