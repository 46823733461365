import { dateDiffInDays } from "../../../functions/dateFunctions";
import { AmortizationLineItem, LineDaysBalanceInterestData, RowProperties } from "../Structs";
//@ts-ignore
import { CreateGlobalAlert } from '../../../functions/CreateGlobalAlerts';

/**
 * This calculates the ACTUAL/360 method of interest
 * @param {number} principal -> the balance of the loan
 * @param {number} term -> the length of the loan
 * @param {number} rate -> the current interest rate
 * 
 * @return {number} The current amount of a monthly payment, interest & principal included.
 */
export function calculateMonthlyPayment(principal: number, term: number, rate: number): number {
  const monthly = term * 12;
  const _rate = rate * (365 / 360);
  const top = principal * (_rate / (1200)); // 12 * 100, 100 for converting 5 to .05
  const bottom = 1 - Math.pow(1 + (_rate / (1200)), -1 * monthly);
  return (top / bottom);
}

export function calculateMonthlyPayment_30_365(principal: number, term: number, rate: number): number {
  //var monthly = term * '

  return 0.0;
}


/**
 * This calculates the Daily Interest on an ACTUAL/360 Method, Need 30/360
 * @param {number} currentBalance
 * @param {number} currentRate
 */
export function calculateDailyInterest_Actual_360(currentBalance: number, currentRate: number): number {
  const scaledInterest = currentRate / 100;
  const effectiveRate = 1 + (scaledInterest / 360.0);
  return (currentBalance * Math.pow(effectiveRate, 1) - currentBalance);
}

/**
 * This needs testing, but should allow us to use the right method
 * @param currentBalance
 * @param currentRate
 */
export function calculateDailyInterest_30_360(currentBalance: number, currentRate: number): number {
  const scaledInterest = currentRate / 100;
  const effectiveRate = 1 + (scaledInterest / 360.0);
  return (currentBalance * Math.pow(effectiveRate, 1) - currentBalance);
}

/**
 * This needs testing, but should allow us to use the right method
 * @param currentBalance
 * @param currentRate
 */
export function calculateDailyInterest_Actual_365(currentBalance: number, currentRate: number): number {
  const scaledInterest = currentRate / 100;
  const effectiveRate = 1 + (scaledInterest / 365.0);
  return (currentBalance * Math.pow(effectiveRate, 1) - currentBalance);
}

function calculateCompoundingTypeNone(currentBalance: number, currentRate: number, numberOfDays: number, calculationMethod: number): number {
  let val: number = 0;
  switch (calculationMethod) {
    case 0:
      val = calculateDailyInterest_Actual_360(currentBalance, currentRate) * numberOfDays;
      break;
    case 1:
      val = calculateDailyInterest_Actual_365(currentBalance, currentRate) * numberOfDays;
      break;
    case 2:
      val = calculateDailyInterest_30_360(currentBalance, currentRate) * numberOfDays;
      break;
    default:
      throw Error("Invalid Calculation Method Requested");
  }
  return parseFloat(val.toFixed(2));
}

function calculateCompoundingTypeDaily(currentBalance: number, currentInterest: number, currentRate: number, numberOfDays: number, calculationMethod: number): number {
  let val: number = 0;
  for (let i = 0; i < numberOfDays; ++i) {
    switch (calculationMethod) {
      case 0:
        val += calculateDailyInterest_Actual_360(currentBalance + val + currentInterest, currentRate);
        break;
      case 1:
        val += calculateDailyInterest_Actual_365(currentBalance + val + currentInterest, currentRate);
        break;
      case 2:
        val += calculateDailyInterest_30_360(currentBalance + val + currentInterest, currentRate);
        break;
      default:
        throw Error("Invalid Calculation Method Requested");
    }
  }
  return parseFloat(val.toFixed(2));
}

function daysInMonth(date: Date): number {
  const month = date.getMonth();
  const year = date.getFullYear();
  return new Date(year, month, 0).getDate();
}

function calculateCompoundingTypeMonthly(currentBalance: number, currentInterest: number, currentRate: number, startDate: Date, numberOfDays: number, calculationMethod: number): number {
  let val: number = 0;
  let compoundedInterest: number = currentInterest;
  const date = new Date(startDate);
  for (let i = 0; i < numberOfDays; ++i) {
    date.setDate(date.getDate() + 1);
    if (date.getDate() === 1) {
      compoundedInterest = currentInterest + val;
    }
    switch (calculationMethod) {
      case 0:
        val += calculateDailyInterest_Actual_360(currentBalance + compoundedInterest + currentInterest, currentRate);
        break;
      case 1:
        val += calculateDailyInterest_Actual_365(currentBalance + compoundedInterest + currentInterest, currentRate);
        break;
      case 2:
        val += calculateDailyInterest_30_360(currentBalance + compoundedInterest + currentInterest, currentRate);
        break;
      default:
        throw Error("Invalid Calculation Method Requested");
    }
  }
  return parseFloat(val.toFixed(2));
}

function calculateCompoundingTypeAnnual(currentBalance: number, currentInterest: number, currentRate: number, startDate: Date, numberOfDays: number, calculationMethod: number): number {
  let val: number = 0;
  let compoundedInterest: number = currentInterest;
  const date = new Date(startDate);
  for (let i = 0; i < numberOfDays; ++i) {
    date.setDate(date.getDate() + 1);
    if (date.getDate() === 1 && date.getMonth() === 1) {
      compoundedInterest = currentInterest + val;
    }
    switch (calculationMethod) {
      case 0:
        val += calculateDailyInterest_Actual_360(currentBalance + compoundedInterest + currentInterest, currentRate);
        break;
      case 1:
        val += calculateDailyInterest_Actual_365(currentBalance + compoundedInterest + currentInterest, currentRate);
        break;
      case 2:
        val += calculateDailyInterest_30_360(currentBalance + compoundedInterest + currentInterest, currentRate);
        break;
      default:
        throw Error("Invalid Calculation Method Requested");
    }
  }
  return parseFloat(val.toFixed(2));
}

/**
 * 
 * @param {number} currentBalance
 * @param {number} currentInterest
 * @param {number} currentRate
 * @param {number} numberOfDays
 * @param {number} compoundType
 */
export function calculateTotalDailyInterest(currentBalance: number, currentInterest: number, currentRate: number, startDate: Date, numberOfDays: number, compoundType: number, calculationMethod: number): number {
  switch (compoundType) {
    case 0:
      return calculateCompoundingTypeNone(currentBalance, currentRate, numberOfDays, calculationMethod);
    case 1:
      return calculateCompoundingTypeDaily(currentBalance, currentInterest, currentRate, numberOfDays, calculationMethod);
    case 2:
      return calculateCompoundingTypeMonthly(currentBalance, currentInterest, currentRate, startDate, numberOfDays, calculationMethod);
    case 4:
      return calculateCompoundingTypeAnnual(currentBalance, currentInterest, currentRate, startDate, numberOfDays, calculationMethod);
    default:
      throw Error("Invalid Method at this time. Please choose a different compounding choice when selecting compound type");
  }

}

/**
 * 
 * @param previous
 * @param date
 * @param rate
 * @param advance
 * @param payment
 * @param interest_payment
 */
export function calculateWithPrevious(previous: AmortizationLineItem, date: Date, rate: number, advance: number, payment: number, interest_payment: number, compoundType: number, calculationMethod: number): LineDaysBalanceInterestData {
  if (compoundType > 1) {
    CreateGlobalAlert("Compounding Type not yet supported. Please choose None or Daily", 3000);
    return;
  }
  const currentDay = dateDiffInDays(previous.date, date);
  const totalInterest = calculateTotalDailyInterest(previous.p_balance, previous.i_balance, rate, previous.date, currentDay, compoundType, calculationMethod);
  return {
    p_balance: advance + previous.p_balance - payment,
    days: currentDay,
    interest: totalInterest,
    i_balance: (previous.i_balance + totalInterest - interest_payment),
  }
}

/**
 * 
 * @param advance
 * @param rate
 * @param i_payment
 */
export function calculateWithoutPrevious(advance: number, rate: number, i_payment: number, calculationMethod: number): LineDaysBalanceInterestData {
  let interest = 0;
  switch (calculationMethod) {
    case 0:
      interest = calculateDailyInterest_Actual_360(advance, rate);
      break;
    case 1:
      interest = calculateDailyInterest_Actual_365(advance, rate);
      break;
    case 2:
      interest = calculateDailyInterest_30_360(advance, rate);
      break;
  }
  return {
    p_balance: advance,
    days: 1,
    interest: interest,
    i_balance: interest,
  }
}
//=======
//﻿import { CreateGlobalAlert } from "../../../functions/CreateGlobalAlerts";
//import { dateDiffInDays } from "../../../functions/dateFunctions";
//import { CalculatedResults, RowProperties } from "../Structs";

///**
// * This calculates the ACTUAL/360 method of interest
// * @param {number} principal -> the balance of the loan
// * @param {number} term -> the length of the loan
// * @param {number} rate -> the current interest rate
// *
// * @return {number} The current amount of a monthly payment, interest & principal included.
// */
//export function calculateMonthlyPayment(principal: number, term: number, rate: number): number {
//    const monthly = term * 12;
//    const _rate = rate * (365 / 360);
//    const top = principal * (_rate / (1200)); // 12 * 100, 100 for converting 5 to .05
//    const bottom = 1 - Math.pow(1 + (_rate / (1200)), -1 * monthly);
//    return (top / bottom);
//}

//export function calculateMonthlyPayment_30_365(principal: number, term: number, rate: number): number {
//    //var monthly = term * '

//    return 0.0;
//}


///**
// * This calculates the Daily Interest on an ACTUAL/360 Method, Need 30/360
// * @param {number} currentBalance
// * @param {number} currentRate
// */
//export function calculateDailyInterest_Actual_360(currentBalance: number, currentRate: number): number {
//    const scaledInterest = currentRate / 100;
//    const effectiveRate = 1 + (scaledInterest / 360.0);
//    return (currentBalance * Math.pow(effectiveRate, 1) - currentBalance);
//}

///**
// * This needs testing, but should allow us to use the right method
// * @param currentBalance
// * @param currentRate
// */
//export function calculateDailyInterest_30_360(currentBalance: number, currentRate: number) : number {
//    const scaledInterest = currentRate / 100;
//    const effectiveRate = 1 + (scaledInterest / 360.0);
//    return (currentBalance * Math.pow(effectiveRate, 1) - currentBalance);
//}

///**
// * This needs testing, but should allow us to use the right method
// * @param currentBalance
// * @param currentRate
// */
//export function calculateDailyInterest_Actual_365(currentBalance: number, currentRate: number): number {
//    const scaledInterest = currentRate / 100;
//    const effectiveRate = 1 + (scaledInterest / 365.0);
//    return (currentBalance * Math.pow(effectiveRate, 1) - currentBalance);
//}

//function calculateCompoundingTypeNone(currentBalance: number, currentRate: number, numberOfDays: number, calculationMethod: number) : number {
//    let val: number = 0;
//    switch (calculationMethod) {
//        case 0:
//            val = calculateDailyInterest_Actual_360(currentBalance, currentRate) * numberOfDays;
//            break;
//        case 1:
//            val = calculateDailyInterest_Actual_365(currentBalance, currentRate)* numberOfDays;
//            break;
//        case 2:
//            val = calculateDailyInterest_30_360(currentBalance, currentRate) * numberOfDays;
//            break;
//        default:
//            throw Error("Invalid Calculation Method Requested");
//    }
//    return parseFloat(val.toFixed(2));
//}

//function calculateCompoundingTypeDaily(currentBalance: number, currentInterest: number, currentRate: number, numberOfDays: number, calculationMethod: number): number {
//    let val: number = 0;
//    for (var i = 0; i < numberOfDays; ++i) {
//        switch (calculationMethod) {
//            case 0:
//                val += calculateDailyInterest_Actual_360(currentBalance + val + currentInterest, currentRate);
//                break;
//            case 1:
//                val += calculateDailyInterest_Actual_365(currentBalance + val + currentInterest, currentRate);
//                break;
//            case 2:
//                val += calculateDailyInterest_30_360(currentBalance + val + currentInterest, currentRate);
//                break;
//            default:
//                throw Error("Invalid Calculation Method Requested");
//        }
//    }
//    return parseFloat(val.toFixed(2));
//}

//function daysInMonth(date: Date) : number {
//    const month = date.getMonth();
//    const year = date.getFullYear();
//    return new Date(year, month, 0).getDate();
//}

//function calculateCompoundingTypeMonthly(currentBalance: number, currentInterest: number, currentRate: number, startDate: Date, numberOfDays: number, calculationMethod: number): number {
//    let val: number = 0;
//    let compoundedInterest: number = currentInterest;
//    const date = new Date(startDate);
//    for (var i = 0; i < numberOfDays; ++i) {
//        date.setDate(date.getDate() + 1);
//        if (date.getDate() === 1) {
//            compoundedInterest = currentInterest + val;
//        }
//        switch (calculationMethod) {
//            case 0:
//                val += calculateDailyInterest_Actual_360(currentBalance + compoundedInterest + currentInterest, currentRate);
//                break;
//            case 1:
//                val += calculateDailyInterest_Actual_365(currentBalance + compoundedInterest + currentInterest, currentRate);
//                break;
//            case 2:
//                val += calculateDailyInterest_30_360(currentBalance + compoundedInterest + currentInterest, currentRate);
//                break;
//            default:
//                throw Error("Invalid Calculation Method Requested");
//        }
//    }
//    return parseFloat(val.toFixed(2));
//}

///**
// *
// * @param {number} currentBalance
// * @param {number} currentInterest
// * @param {number} currentRate
// * @param {number} numberOfDays
// * @param {number} compoundType
// */
//export function calculateTotalDailyInterest(currentBalance: number, currentInterest: number, currentRate: number, startDate: Date, numberOfDays: number, compoundType: number, calculationMethod: number): number {
//    switch (compoundType) {
//        case 0:
//            return calculateCompoundingTypeNone(currentBalance, currentRate, numberOfDays, calculationMethod);
//        case 1:
//            return calculateCompoundingTypeDaily(currentBalance, currentInterest, currentRate, numberOfDays, calculationMethod);
//        case 2:
//            return calculateCompoundingTypeMonthly(currentBalance, currentInterest, currentRate, startDate, numberOfDays, calculationMethod);
//        default:
//            throw Error("Invalid Method at this time. Please choose a different compounding choice when selecting compound type");
//    }

//}

///**
// *
// * @param previous
// * @param date
// * @param rate
// * @param advance
// * @param payment
// * @param interest_payment
// */
//export function calculateWithPrevious(previous: RowProperties, date: Date, rate: number, advance: number, payment: number, interest_payment: number, compoundType: number, calculationMethod: number): CalculatedResults {
//    if (compoundType > 1) {
//        CreateGlobalAlert("Compounding Type not yet supported. Please choose None or Daily", 3000);
//        return;
//    }
//    var currentDay = dateDiffInDays(previous.date, date);
//    var totalInterest = calculateTotalDailyInterest(previous.p_balance, previous.i_balance, rate, previous.date, currentDay, compoundType, calculationMethod);
//    return {
//        p_balance: advance + previous.p_balance - payment,
//        days: currentDay,
//        interest: totalInterest,
//        i_balance: (previous.i_balance + totalInterest - interest_payment),
//    }
//}

///**
// *
// * @param advance
// * @param rate
// * @param i_payment
// */
//export function calculateWithoutPrevious(advance: number, rate: number, i_payment: number, calculationMethod: number): CalculatedResults {
//    let interest = 0;
//    switch (calculationMethod) {
//        case 0:
//            interest = calculateDailyInterest_Actual_360(advance, rate);
//            break;
//        case 1:
//            interest = calculateDailyInterest_Actual_365(advance, rate);
//            break;
//        case 2:
//            interest = calculateDailyInterest_30_360(advance, rate);
//            break;
//    }
//    return {
//        p_balance: advance,
//        days: 1,
//        interest: interest,
//        i_balance: interest,
//    }
//>>>>>>> 354c7028e6262fd9f7c0d092b9ffaa7b2bb7fd8a:FleetPipelineManager/ClientApp/src/components/EntityComponents/EntityAmortizationSchedule/CalculateFunctions.tsx
//}