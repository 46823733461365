import * as React from 'react';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import ScannerTrackingModal from './ScannerTrackingManagementWidget.modals';
import { WarningModal } from '../CoreComponents/Modals';
import axios from 'axios';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

export interface ScannerTrackingData {
  bank: SelectOptions;
  property: SelectOptions,
  serialNumber: string;
  uid: string;
}

interface ScannerTrackingManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<ScannerTrackingData>;
}

export default class ScannerTrackingManagementWidget extends React.Component<{}, ScannerTrackingManagementWidgetState> {

  static Banks = [
    { label: "BEO", value: "0" },
    { label: "Umpqua", value: "1"}
  ]

  scannerModal: React.RefObject<ScannerTrackingModal> = React.createRef<ScannerTrackingModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  constructor(props: {}) {
    super(props);
    this.state = {
      headers: [],
      data: []
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
  }

  async updateData(): Promise<void> {
    let response = await axios.get('./api/scanner-tracking');

    let data: Array<any> = [];
    response.data.forEach((item: any) => (
      data.push({
        uid: item.uid,
        bank: ScannerTrackingManagementWidget.Banks[item.bank],
        property: { label: item.property.name, value: item.property.uid },
        serialNumber: item.serialNumber
      })
    ))
    this.setState({ data: data });
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Property",
        setting: true,
        filter: {}
      },
      {
        columnName: "Bank", 
        setting: true,
        filter: {}
      },
      {
        columnName: "Serial Number",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
    this.setState({ headers: headers }, async () => await this.updateData());
  }

  callback(): void {
    this.scannerModal.current.hide();
    this.warningModal.current.show("Scanner Tracking Component has been created", "Scanner Tracking Created");
  }

  render(): JSX.Element {
    return (
      <>
        <ScannerTrackingModal ref={this.scannerModal} callback={this.callback} />
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Scanner Tracking")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.property.label}</td>
                    <td className="fixedTableCell">{item.bank.label}</td>
                    <td className="fixedTableCell">{item.serialNumber}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="View" className="standard-input" onClick={() => this.scannerModal.current.show(item)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create" className="standard-input" onClick={() => this.scannerModal.current.show()} />
      </>
    )
  }
}