import * as React from 'react';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import DataStoragePropertyModal, { DoublePropertyData, ForeignKeyPropertyData, GeneratedPropertyData, IntPropertyData, StringPropertyData } from './DataStorageRequest.PropertyModal';
import { Col, Row } from 'reactstrap';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import { reactSelectBasicStyle } from '../../style/select-constants';
import Select from 'react-select';


interface DataStorageRequestState {
  className: string;
  createFactory: boolean;
  createBuilder: boolean;
  defaultCreation: SelectOptions;
  includePatching: boolean;
  properties: Array<GeneratedPropertyData>;

}

export class DataStorageRequest extends React.Component<{}, DataStorageRequestState> {

  propertyModal: React.RefObject<DataStoragePropertyModal> = React.createRef<DataStoragePropertyModal>();

  static TYPES: Array<SelectOptions> = [
    { label: "String", value: "0" },
    { label: "Foreign Key", value: "1" },
    { label: "Integer", value: "2" },
    { label: "Double", value: "3" },
    { label: "Decimal", value: "4"},
    { label: "Boolean", value: "5" },
    { label: "Enum", value: "6" }
  ]

  static CreationTypes: Array<SelectOptions> = [
    { label: "Prepare Function with Post", value: "0" },
    { label: "Factory", value: "1" },
    { label: "Building", value: "2"}
  ]

  constructor(props: {}) {
    super(props);
    this.state = {
      className: "",
      properties: [],
      createBuilder: false,
      createFactory: true,
      includePatching: true,
      defaultCreation: { label: "Prepare Function with Post", value: "0"}
    }
    this.addProperty = this.addProperty.bind(this);
    this.changeClassName = this.changeClassName.bind(this);
    this.changeCreateFactory = this.changeCreateFactory.bind(this);
    this.changeCreateBuilder = this.changeCreateBuilder.bind(this);

  }

  async componentDidMount(): Promise<void> {

  }

  async submitForGeneration() {
    let properties = this.state.properties;
    console.log(properties);
  }

  changeClassName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ className: event.target.value });
  }

  changeCreateFactory(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ createFactory: event.target.checked });
  }

  changeCreateBuilder(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ createBuilder: event.target.checked });
  }

  renderPropertySettings(field: GeneratedPropertyData): JSX.Element {
    if ('maxLength' in field) {
      let data: StringPropertyData = field as StringPropertyData;
      return (
        <div style={{ marginRight: "1vh", marginLeft: "1vh" }}>
          {RenderHeader(`${field.name} (String)`)}
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Factory Ignore</th>
                <th className="fixedTableHeader">Builder Ignore</th>
                <th className="fixedTableHeader">Max Length</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fixedTableCellFirst">{data.factoryIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.builderIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.maxLength}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
    else if ('referenceClass' in field) {
      let data: ForeignKeyPropertyData = field as ForeignKeyPropertyData;
      return (
        <div style={{ marginRight: "1vh", marginLeft: "1vh" }}>
          {RenderHeader(`${field.name} (Foreign Key)`)}
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Factory Ignore</th>
                <th className="fixedTableHeader">Builder Ignore</th>
                <th className="fixedTableHeader">Class</th>
                <th className="fixedTableHeader">Unique</th>
                <th className="fixedTableHeader">Ignore Load Function</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fixedTableCellFirst">{data.factoryIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.builderIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.referenceClass.label}</td>
                <td className="fixedTableCell">{data.unique ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.ignoreLoad ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
    else if ('defaultValue' in field && !('roundingPrecision' in field)) {
      let data: IntPropertyData = field as IntPropertyData;
      return (
        <div style={{ marginRight: "1vh", marginLeft: "1vh" }}>
          {RenderHeader(`${field.name} (Integer)`)}
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Factory Ignore</th>
                <th className="fixedTableHeader">Builder Ignore</th>
                <th className="fixedTableHeader">Default</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fixedTableCellFirst">{data.factoryIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.builderIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.defaultValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
    else if ('defaultValue' in field) {
      let data: DoublePropertyData = field as DoublePropertyData;
      return (
        <div style={{ marginRight: "1vh", marginLeft: "1vh" }}>
          {RenderHeader(`${field.name} (Double)`)}
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Factory Ignore</th>
                <th className="fixedTableHeader">Builder Ignore</th>
                <th className="fixedTableHeader">Default</th>
                <th className="fixedTableHeader">Precision</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fixedTableCellFirst">{data.factoryIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.builderIgnore ? "Yes" : "No"}</td>
                <td className="fixedTableCell">{data.defaultValue}</td>
                <td className="fixedTableCell">{data.roundingPrecision}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return <></>
    }
  }

  addProperty(field: GeneratedPropertyData) {
    let data = this.state.properties;
    data.push(field);
    this.setState({ properties: data });
  }

  render(): JSX.Element {
    return (
      <>
        <div style={{
          border: "2px solid #c2a877", background: "#4c4a42", display: "block", width: "49%",
          marginLeft: "1%", float: "left", height: "85vh", marginBottom: "1vh", overflow: "auto",
          paddingTop: "1vh"
        }} >
          <DataStoragePropertyModal ref={this.propertyModal} callback={this.addProperty} />
          {RenderHeader("Database Code Generation")}
          <Row>
            <Col>
              <h5 style={{ marginTop: "1vh" }}>Class Name</h5>
            </Col>
            <Col>
              <input type="text" value={this.state.className} className="standard-input" onChange={this.changeClassName} />
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h5 style={{ marginTop: "1vh" }}>Create Factory</h5>
            </Col>
            <Col>
              <input type="checkbox" checked={this.state.createFactory} style={{ marginLeft: "10vw" }} onChange={this.changeCreateFactory} />
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h5 style={{ marginTop: "1vh" }}>Create Building Functions</h5>
            </Col>
            <Col>
              <input type="checkbox" checked={this.state.createBuilder} onChange={this.changeCreateBuilder} />
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h5 style={{ marginTop: "1vh" }}>Default Creation</h5>
            </Col>
            <Col>
              <Select
                options={DataStorageRequest.CreationTypes}
                value={this.state.defaultCreation}
                styles={reactSelectBasicStyle}

              />
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col>
              <h5 style={{ marginTop: "1vh" }}>Include Patching</h5>
            </Col>
            <Col>
              <input type="checkbox" checked={this.state.includePatching} className="standard-input" onChange={this.changeClassName} />
            </Col>
          </Row>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <input type="button" value="Add Property" className="standard-input" onClick={() => this.propertyModal.current.show()} />
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <input type="button" value="Generate Code" className="standard-input" onClick={this.submitForGeneration} />
        </div>
        <div style={{
          border: "2px solid #c2a877", background: "#4c4a42", display: "block", width: "49%",
          marginLeft: "1%", float: "left", height: "85vh", marginBottom: "1vh", overflow: "auto",
          paddingTop: "1vh"
        }} >
          {RenderHeader("Field Definitions")}
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          {
            this.state.properties.map((item) => (
              <>
                {this.renderPropertySettings(item) }
                <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
              </>
            ))
          }
        </div>
      </>
    )
  }
}