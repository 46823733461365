//#region Imports
import * as React from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { CompanyBillingType, ExternalCompanyTypes, ExternalCompanyTypes_List } from '../../constants/EnumConstants';
import { pullContacts, pullStates } from '../../functions/fetchLinkedObjects';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import * as Structs from './interfaces';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { EMPTY_GUID } from '../../constants/DefaultConstants';
import { AddressComponent } from '../LocationComponents/AddressComponent';
import { TableAdditionRow } from '../CoreComponents/CoreTableRows';
import { FilterAndSettingField, StreetAddress } from '../CoreComponents/interfaces';
import { getBearerToken } from '../../functions/authActions';
//#endregion

const VendorRow = (props: Structs.VendorData) => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.name}</td>
      <td className="fixedTableCell">{props.address}</td>
      <td className="fixedTableCell">{props.type}</td>
      <td className="fixedTableCell">{props.contacts}</td>
      <td className="fixedTableCell">{props.email_list}</td>
      <td className="fixedTableCell"><a href={"/vendor-form?uid=" + props.uid}><FontAwesomeIcon icon={faCog} style={{ marginLeft: "35%" }} /></a></td>
    </tr>
  )
}

export class ExternalCompanyList extends React.Component<Structs.VendorListDataProps, Structs.VendorListDataState> {

  constructor(props: Structs.VendorListDataProps) {
    super(props);
    this.state = {
      vendors: [],
      headers: [],
      companyName: ''
    }
    this.changeName = this.changeName.bind(this);
    this.updateCompanies = this.updateCompanies.bind(this);
    this.addVendor = this.addVendor.bind(this);
    this.generateHeaders = this.generateHeaders.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    this.setState({
      headers: this.generateHeaders()
    })
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('./api/vendors/').then(function (response) {
      const vendors: Array<Structs.VendorData> = [];
      response.data.forEach(function (item: Structs.VendorServerData) {
        self.addVendor(vendors, item);
      });
      self.setState({
        vendors: vendors,
      })
    });
  }

  //#region Functions

  changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    //@ts-ignore
    this.setState({
      [event.target.name]: event.target.value
    }, this.updateCompanies)
  }

  async updateCompanies(): Promise<void> {
    const self = this;
    this.setState({
      headers: this.generateHeaders()
    })
    const name = this.state.companyName === "" ? "null" : this.state.companyName;
    axios.get('./api/vendors/filter/' + name).then(function (response) {
      const vendors: Array<Structs.VendorData> = [];
      response.data.forEach(function (item: Structs.VendorServerData) {
        self.addVendor(vendors, item);
      });
      self.setState({
        vendors: vendors
      })
    });
  }

  addVendor(list: Array<Structs.VendorData>, item: Structs.VendorServerData): void {
    list.push({
      uid: item.uid,
      name: item.name,
      address: item.streetAddress.fullAddress,
      type: ExternalCompanyTypes_List[item.type],
      contacts: item.mainContacts?.map(function (item) { return item.isMainContact ? item.contact?.fullName : null })
        .filter(x => x !== null)
        .join(", "),
      email_list: item.mainContacts?.map(function (item) { return item.isMainContact ? item.contact?.fullName + " <" + item.contact?.emailAddress + ">" : null })
        .filter(x => x !== null)
        .join(", ")
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        setting: true,
        columnName: "Name",
        filter: {
          type: "text",
          name: 'companyName',
          value: this.state.companyName,
          changeFilter: this.changeName,
          placeHolder: "Filter by Company Name"
        }
      },
      {
        setting: true,
        columnName: "Address",
        filter: {}
      },
      {
        setting: true,
        columnName: "Company",
        filter: {}
      },
      {
        setting: true,
        columnName: "Main Contacts",
        filter: {}
      },
      {
        setting: true,
        columnName: "Main Contact Email List",
        filter: {}
      }
    ]
  }

  //#endregion

  render(): JSX.Element {
    return (
      <div>
        <h3>External Company List</h3>
        <div style={{ minHeight: "82vh", maxHeight: "82vh", overflow: "scroll" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              {
                this.state.vendors.map((item) => (
                  <VendorRow key={item.uid} uid={item.uid} name={item.name}
                    address={item.address} type={item.type} contacts={item.contacts}
                    email_list={item.email_list}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
        <input type="button" value="Add New Vendor" className="standard-input" style={{ width: "25%" }} onClick={() => window.location.assign('/vendor-form/')} />
      </div>
    )
  }
}

const ContactRow = (props: any) => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.contact?.label}</td>
      <td className="fixedTableCell">{props.isMain ? "Yes" : "No"}</td>
      <td className="fixedTableCell">
        <FontAwesomeIcon style={{ marginLeft: "35%" }} icon={faTrash} onClick={() => props.remove(props.idx)} />
      </td>
    </tr>
  )
}

interface EditRowProps {
  save: (e: any) => void;
}

interface EditRowState {
  contact: SelectOptions;
  isMain: boolean;
  contacts: Array<SelectOptions>;
}

class EditRow extends React.Component<EditRowProps, EditRowState>{

  constructor(props: EditRowProps) {
    super(props);
    this.state = {
      contact: { label: "Select Contact", value: "" },
      isMain: false,
      contacts: []
    }
    this.changeContact = this.changeContact.bind(this);
    this.changeIsMain = this.changeIsMain.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const contacts = await pullContacts();
    this.setState({
      contacts
    })
  }

  changeContact(event: SelectOptions): void {
    this.setState({
      contact: event
    });
  }

  changeIsMain(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      isMain: event.target.checked
    })
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <Select
            options={this.state.contacts}
            styles={reactSelectBasicStyle}
            value={this.state.contact}
            onChange={this.changeContact}
          />
        </td>
        <td className="fixedTableCell">
          <input type="checkbox" checked={this.state.isMain} onChange={this.changeIsMain} />
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faSave} onClick={() => this.props.save(this.state)} />
        </td>
      </tr>
    )
  }
}

export class ExternalCompanyForm extends React.Component<Structs.VendorFormDataProps, Structs.VendorFormDataState> {

  address = React.createRef<AddressComponent>();

  constructor(props: Structs.VendorFormDataProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.state = {
      name: '',
      type: { label: 'Select Type', value: '' },
      relationshipType: { label: 'Select Type', value: '' },
      streetAddress: {
        address: '',
        city: '',
        state: { label: 'Select State', value: '-1' },
        zipcode: ''
      },
      contact: [],
      //@ts-ignore
      vendorId: params.has('uid') ? params.get('uid') : '',
      states: [],
      contacts: [],
      disabled: false,
      loaded: false,
      editing: false,
      headers: this.generateHeaders()
    };
    this.generateHeaders = this.generateHeaders.bind(this);
    this.changeString = this.changeString.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeRelation = this.changeRelation.bind(this);
    this.changeContact = this.changeContact.bind(this);
    this.addContact = this.addContact.bind(this);
    this.addContactButton = this.addContactButton.bind(this);
    this.remove = this.remove.bind(this);
    this.save = this.save.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const self = this;
    const states = await pullStates();
    this.setState({
      states: states,
    })
    if (self.state.vendorId) {
      await axios.get('./api/vendors/' + self.state.vendorId).then(function (response) {
        if (response.data.streetAddress === null) {
          response.data.streetAddress = {
            streetAddress: response.data.address,
            city: response.data.city,
            state: response.data.state,
            zipCode: response.data.zipCode
          }
        }
        const contacts = response.data.mainContacts.map(function (item: any) {
          return {
            contact: { label: item.contact.fullName, value: item.contact.uid },
            isMain: item.isMainContact
          }
        })
        self.setState({
          name: response.data.name,
          type: {
            label: ExternalCompanyTypes.filter(_ => _.value === response.data.type.toString())[0].label,
            value: response.data.type
          },
          relationshipType: {
            label: CompanyBillingType.filter(_ => _.value === response.data.businessType.toString())[0].label,
            value: response.data.businessType
          },
          streetAddress: {
            address: response.data.streetAddress.streetAddress,
            city: response.data.streetAddress.city,
            //@ts-ignore
            state: states.filter[response.data.state],
            zipcode: response.data.streetAddress.zipCode
          },
          contacts: contacts,
          loaded: true
        })
      })
    }
    else {
      this.setState({
        loaded: true
      })
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Contact",
        setting: true,
        filter: {}
      },
      {
        columnName: "Main",
        setting: true,
        filter: {}
      }
    ]
  }

  //#region Functions

  changeString(event: React.ChangeEvent<HTMLInputElement>): void {
    //@ts-ignore
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  updateAddress(streetAddress: StreetAddress): void {
    this.setState({
      streetAddress: streetAddress
    })
  }

  changeType(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      type: event
    })
  }

  changeRelation(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      relationshipType: event
    })
  }

  changeContact(event: SelectOptions[] | null): void {
    if (event === null) { return; }
    this.setState({
      contact: event
    })
  }

  addContact(contact: any): void {
    const contacts = this.state.contacts;
    contacts.push(contact);
    this.setState({
      contacts: contacts,
      editing: false
    })
  }

  addContactButton(): void {
    this.setState({
      editing: true
    })
  }

  remove(idx: number): void {
    const contacts = this.state.contacts;
    contacts.splice(idx, 1);
    this.setState({
      contacts: contacts
    })
  }

  save(): void {
    this.setState({
      disabled: true
    });
    const streetAddress = this.address.current?.state.streetAddress;
    const data = {
      vendor: {
        UID: EMPTY_GUID,
        StreetAddress: {
          StreetAddress: streetAddress.address,
          City: streetAddress.city,
          State: streetAddress.state.value,
          ZipCode: streetAddress.zipcode
        },
        Name: this.state.name,
        Type: this.state.type.value,
        BusinessType: this.state.relationshipType.value
      },
      contacts: this.state.contacts.map(function (item) { return { Key: item.contact.value, IsMain: item.isMain } })
    };
    if (this.state.vendorId) {
      data.vendor.UID = this.state.vendorId;
      axios.put('./api/vendors/', data)
        .then(function (response) {
          CreateGlobalAlert("Vendor Updated Successfully, refreshing page", 1200, () => window.location.assign("/vendor-form?uid=" + response.data.uid));
        })
    }
    else {
      axios.post('./api/vendors/', data)
        .then(function (response) {
          CreateGlobalAlert("Vendor Created Successfully, refreshing page", 1200, () => window.location.assign("/vendor-form?uid=" + response.data.uid));
        })
    }
  }

  //#endregion

  render(): JSX.Element {
    return (
      <div style={{ height: "90vh", overflowY: "auto", overflowX: "hidden", paddingBottom: "5vh" }}>
        <h3>Outside Company Form</h3>
        <Row>
          <Col>
            <label>Name</label>
            <br />
            <input className="standard-input" type="text" name="name" value={this.state.name} onChange={this.changeString} />
          </Col>
          <Col>
            <label>Type</label>
            <br />
            <Select
              value={this.state.type}
              onChange={this.changeType}
              styles={reactSelectBasicStyle}
              options={ExternalCompanyTypes}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Business Type</label>
            <br />
            <Select
              value={this.state.relationshipType}
              onChange={this.changeRelation}
              options={CompanyBillingType}
              styles={reactSelectBasicStyle}
            />
          </Col>
          <Col>
          </Col>
        </Row>
        <hr />
        {this.state.loaded && <AddressComponent updateAddress={this.updateAddress} streetAddress={this.state.streetAddress} states={this.state.states} ref={this.address}
        />}
        <hr />
        <div style={{ maxHeight: "38vh", minHeight: "38vh" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody>
              {
                this.state.contacts.map((item, idx) => (
                  <ContactRow contact={item.contact} isMain={item.isMain} remove={this.remove} idx={idx} />
                ))
              }
              {this.state.editing
                ? <EditRow save={this.addContact} />
                : <TableAdditionRow length={3} marginLeft={"35%"} onClick={this.addContactButton} />
              }
            </tbody>
          </table>
        </div>
        <hr />
        <Row>
          <Col>
            <input type="button" id="save" value="Save Company" className="standard-input" onClick={this.save} disabled={this.state.disabled} />
          </Col>
          <Col>
            <input type="button" id="save" value="Cancel" className="standard-input"
              onClick={() => window.location.assign('./vendor-list')}
              disabled={this.state.disabled} style={{ marginLeft: "15px" }}
            />
          </Col>
        </Row>


      </div>
    )
  }

}