import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { Col, Row } from 'reactstrap';
import { pullProperties } from '../../../functions/fetchLinkedObjects';
import axios from 'axios';
import * as Structs from './SiteSettingsManagementWidget.structs';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface SiteSettingsManagementWidgetState {
  headers: Array<FilterAndSettingField>;
  properties: Array<SelectOptions>;
  projects: Array<Structs.ProjectData>;
}


export class SiteSettingsManagementWidget extends React.Component<{ uid: string, height?: string }, SiteSettingsManagementWidgetState> {


  constructor(props: { uid: string, height?: string }) {
    super(props);
    this.state = {
      headers: [],
      properties: [],
      projects: []
    }
  }

  async componentDidMount(): Promise<void> {
    const properties = await pullProperties();
    let response = await axios.get('./api/managed-jobs/projects/' + this.props.uid);
    if (response.status === 200) {
      this.setState({ properties: properties, projects: response.data }, () => this.generateHeaders());
    }
  }

  generateHeaders(): void {
    const headers = [];
    headers.push({ columnName: "Property", setting: true, filter: {} });
    headers.push({ columnName: "Site Code", setting: true, filter: {} });
    headers.push({ columnName: "", setting: true, filter: {}, additionalClasses: "fifteen" });
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <div>
        {RenderHeader("Site Settings")}
        <div style={{ height: this.props.height ? this.props.height : "25vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.projects.map((item) => (
                  <tr key={item.propertyUID}>
                    <td className="fixedTableCellFirst">{item.property}</td>
                    <td className="fixedTableCell">{item.code}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Remove" className="standard-input" />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Add Site to Project" onClick={() => true} className="standard-input" />
          </Col>
        </Row>
      </div>
    )
  }
}