import axios from 'axios';
import * as React from 'react';
import { getUserID } from '../../../functions/authActions';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullEmployeesByCompany, pullProperties } from '../../../functions/fetchLinkedObjects';
import { EmployeeCompany } from '../../../constants/EnumConstants';
import { WarningModal } from '../../CoreComponents/Modals';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

export class RegionalManagerApprovalWorkflowWidget extends React.Component<{ isRegional: boolean }, { rows: Array<any> }> {

  approvalModal: React.RefObject<RegionalManagerApprovalWorkflowModal> = React.createRef<RegionalManagerApprovalWorkflowModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: { isRegional: boolean }) {
    super(props);
    this.state = {
      rows: []
    }
    this.callBack = this.callBack.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let list = this.props.isRegional ? await this.getActiveByRegional() : await this.getActive();
    this.setState({ rows: list });
  }

  async getActive(): Promise<Array<any>> {
    let response = await axios.get('./api/regional-manager-approval-workflow');
    return response.data;
  }

  async getActiveByRegional(): Promise<Array<any>> {
    let response = await axios.get('./api/regional-manager-approval-workflow/regional/' + getUserID());
    return response.data;
  }

  getHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Regional",
        setting: true,
        filter: {}
      },
      {
        columnName: "Date",
        setting: true,
        filter: {}
      }
    ]
  }

  callBack(): void {
    this.approvalModal.current.hide();
    this.warningModal.current.show("Distributions have been approved. Thank you for reviewing them.", "Distributions Approved", () => window.location.reload());
  }

  render(): JSX.Element {
    return (
      <>
        <RegionalManagerApprovalWorkflowModal ref={this.approvalModal} isRegional={this.props.isRegional} />
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Payroll Approval Workflows")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={1} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.regional}</td>
                    <td className="fixedTableCell">{new Date(item.payrollDate).toLocaleDateString("en-CA")}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faBinoculars} style={{ marginLeft: "20%" }} onClick={() => this.approvalModal.current.show(item.uid, item.archivedMaps, this.callBack)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

interface RegionalManagerApprovalWorkflowRow {
  employee: string;
  property: SelectOptions;
  allocation: number;
  uid: string;
  role: SelectOptions;
}

interface RegionalManagerApprovalWorkflowModalState {
  showing: boolean;
  rows: Array<RegionalManagerApprovalWorkflowRow>;
  employees: Array<SelectOptions>;
  properties: Array<SelectOptions>;
  uid: string;
  callback: () => void;
  error: string;
}

export class RegionalManagerApprovalWorkflowModal extends React.Component<{ isRegional: boolean }, RegionalManagerApprovalWorkflowModalState> {

  constructor(props: { isRegional: boolean }) {
    super(props);
    this.state = {
      employees: [],
      properties: [],
      showing: false,
      rows: [],
      uid: "",
      callback: null,
      error: ""
    }
    this.addRow = this.addRow.bind(this);
    this.changeSiteRole = this.changeSiteRole.bind(this);
    this.changeAllocation = this.changeAllocation.bind(this);
    this.updateAllocation = this.updateAllocation.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
    this.updateAllocation = this.updateAllocation.bind(this);
    this.approveDistributions = this.approveDistributions.bind(this);
    this.hide = this.hide.bind(this);
    this.checkValues = this.checkValues.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let employees = await pullEmployeesByCompany(EmployeeCompany.VMI);
    let properties = await pullProperties();
    this.setState({
      employees: employees,
      properties: properties
    })
  }

  show(uid: string, rows: Array<RegionalManagerApprovalWorkflowRow>, callback: () => void): void {
    this.setState({
      showing: true,
      rows: rows,
      uid: uid,
      callback: callback
    }, () => this.checkValues())
  }

  hide(): void {
    this.setState({ showing: false });
  }

  getHeaders(): Array<FilterAndSettingField> {
    const headers = [
      { columnName: "Employee", setting: true, filter: {} },
      { columnName: "Property", setting: true, filter: {} },
      { columnName: "Distribution", setting: true, filter: {} },
      { columnName: "Role", setting: true, filter: {} }
    ]
    return headers;
  }

  addRow(): void {
    let rows: Array<any> = this.state.rows;
    rows.push({
      uid: "",
      property: { label: "Select Property", value: "" },
      distribution: 0,
      siteRole: { label: "N/A", value: "-1" }
    });
    this.setState({ rows: rows });
  }

  removeRow(idx: number): void {
    let rows: Array<RegionalManagerApprovalWorkflowRow> = this.state.rows;
    rows.splice(idx, 1);
    this.setState({ rows: rows });
  }

  async changeSiteRole(event: SelectOptions, idx: number): Promise<void> {
    let row = this.state.rows[idx];
    row.role = event;
    this.state.rows[idx] = row;
    this.setState({});
    await axios.patch('api/regional-manager-approval-workflow/site-role', { archiveUID: row.uid, role: row.role.value });
  }

  changeAllocation(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let row = this.state.rows[idx];
    row.allocation = parseFloat(event.target.value);
    this.state.rows[idx] = row;
    this.setState({}, () => this.checkValues());
  }

  async updateAllocation(idx: number): Promise<void> {
    let row = this.state.rows[idx];
    await axios.patch('api/regional-manager-approval-workflow/allocation', { archiveUID: row.uid, allocation: row.allocation });
  }

  async changeProperty(event: SelectOptions | null, idx: number): Promise<void> {
    let row = this.state.rows[idx];
    row.property = event;
    this.state.rows[idx] = row;
    this.setState({});
    await axios.patch('api/regional-manager-approval-workflow/property', { archiveUID: row.uid, propertyUID: row.property.value });
  }

  async approveDistributions(): Promise<void> {
    let response = await axios.post('./api/regional-manager-approval-workflow/approve/' + this.state.uid, { validateStatus: () => true });
    if (response.status === 202) {
      this.state.callback();
    }
  }

  checkValues(): void {
    let rows = this.state.rows;
    let values = {};
    let error = "";
    let useError = false;
    rows.forEach((item) => {
      //@ts-ignore
      if (!values[item.employee]) { values[item.employee] = 0 }
      //@ts-ignore
      values[item.employee] += item.allocation;
    })
    for (let key in values) {
      //@ts-ignore
      if (values[key] !== 100 && !useError) {
        useError = true;
        error = "The following employees do not have allocations that sum to 100: " + key;
      }
      //@ts-ignore
      else if (values[key] !== 100) {
        error += ", " + key;
      }
    }
    this.setState({
      error: error
    })
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="lg" toggle={() => this.setState({ showing: false })}>
        <ModalHeader tag='h3' style={{ backgroundColor: "#4C4A42" }} toggle={() => this.setState({ showing: false })}>
          <b style={{ color: "#d7c7a7" }}>Edit Region Allocations</b>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42" }}>
          <div>
            <label style={{ color: "#ff6666", marginTop: "-1.2vh" }}>{this.state.error}</label>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={1} />
              </thead>
              <tbody>
                {
                  this.state.rows.map((item, idx) => (
                    <tr key={item.uid + idx}>
                      <td className="fixedTableCellFirst">
                        {item.employee}
                      </td>
                      <td className="fixedTableCell">
                        {item.property.label}
                      </td>
                      <td className="fixedTableCell">
                        {item.allocation}
                      </td>
                      <td className="fixedTableCell">
                        {item.role.label}
                      </td>
                      <td className="fixedTableCell">
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <hr />
            <Row>
              <Col>
              </Col>
              <Col>
                <input type="button" value="Submit Concern as Ticket" className="standard-input" onClick={() => window.open("https://chrismancommandcenter.in/ticket-submission", "_blank")} />
              </Col>
            </Row>
            <hr />
            <input type="button" className="standard-input" value="Approve Distributions & Close" onClick={this.approveDistributions} />
          </div>
        </ModalBody>
      </Modal>
    )
  }
}