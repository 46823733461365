import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { DatePicker } from '../CoreComponents/DateComponents';
import { pullEmpireEmployees, pullPhoneTracking } from '../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import axios from 'axios';

interface PhoneCheckoutModalProps {
  callback: (edit: boolean) => void;
}
interface PhoneCheckoutModalState {
  show: boolean;
  reason: string;
  checkedOut: Date;
  returnDate: Date;
  employee: SelectOptions;
  employees: Array<SelectOptions>;
  phoneTracking: SelectOptions;
  phoneTrackings: Array<SelectOptions>;
}

export default class PhoneCheckoutModal extends React.Component<PhoneCheckoutModalProps, PhoneCheckoutModalState> {

  constructor(props: PhoneCheckoutModalProps) {
    super(props);

    this.state = {
      show: false,
      reason: "",
      checkedOut: new Date(),
      returnDate: new Date(),
      employees: [],
      employee: { label: "Select Employee", value: "" },
      phoneTrackings: [],
      phoneTracking: { label: "Select Phone", value: "" }
    }
    this.show = this.show.bind(this);
    this.save = this.save.bind(this);
    this.hide = this.hide.bind(this);
    this.changeReason = this.changeReason.bind(this);
    this.changeCheckedOut = this.changeCheckedOut.bind(this);
    this.changeReturnDate = this.changeReturnDate.bind(this);
    this.changePhoneTracking = this.changePhoneTracking.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let employees = await pullEmpireEmployees();
    let trackings = await pullPhoneTracking();
    this.setState({ employees: employees, phoneTrackings: trackings });
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  hide(): void {
    this.setState({
      show: false
    })
  }

  async changePhoneTracking(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ phoneTracking: event })
  }

  async changeEmployee(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ employee: event });
  }

  async changeReason(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ reason: event.target.value });
  }

  async changeCheckedOut(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ checkedOut: event.target.valueAsDate });
  }

  async changeReturnDate(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ returnDate: event.target.valueAsDate });
  }

  async save(): Promise<void> {
    const data = {
      phoneTrackingUID: this.state.phoneTracking.value,
      employeeUID: this.state.employee.value,
      reason: this.state.reason,
      checkedOutDate: this.state.checkedOut.toISOString(),
      returnDate: this.state.returnDate.toISOString(),
      isReturned: false
    }
    let response = await axios.post('./api/phone-checkout', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.props.callback(false);
    }
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Checkout Phone / Hotspot
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Phone Tracking</h5>
              </Col>
              <Col>
                <Select
                  styles={reactSelectBasicStyle}
                  options={this.state.phoneTrackings}
                  value={this.state.phoneTracking}
                  onChange={this.changePhoneTracking}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Employee</h5>
              </Col>
              <Col>
                <Select
                  styles={reactSelectBasicStyle}
                  options={this.state.employees}
                  value={this.state.employee}
                  onChange={this.changeEmployee}
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Reason</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.reason} className="standard-input" onChange={this.changeReason} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Checked out</h5>
              </Col>
              <Col>
                <DatePicker value={this.state.checkedOut} onChange={this.changeCheckedOut} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Return</h5>
              </Col>
              <Col>
                <DatePicker value={this.state.returnDate} onChange={this.changeReturnDate} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <input type="button" value="Check Out" className="standard-input" onClick={this.save} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}