import axios from "axios";
import * as React from "react";
import { SelectOptions } from "../../../interfaces/CoreInterfaces";
import FilteredWithSettingsTableHeader from "../../CoreComponents/CoreTableHeaders";
import { FilterAndSettingField } from "../../CoreComponents/interfaces";
import { ServerTicket } from "./TicketWorkManager.constants";
import { CompanyNames, SupportDepartment, Ticket, TicketFilterSettings, TicketFilterStatuses, TicketTableState, TicketWorkStatuses } from "./TicketTableManager.constants";
import TicketTableRow from "./TicketTableManager.rows";
import { StatusModal } from "../../CoreComponents/Modals";
import { pullSecondaryCategories } from "../../../functions/fetchLinkedObjects";
import { ALL_ONE, ALL_THREE, ALL_TWO, EMPTY_GUID } from "../../../constants/DefaultConstants";
import { getUserJobFromServer } from "../../../functions/authActions";
import { GetSettings, SetSettings } from "../../../functions/StateManagement/StateStorageFunctions";

export default class TicketTableManager extends React.Component<{}, TicketTableState>{

  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();

  intervalCheck: NodeJS.Timer;
  filterSettingsString: string = "filterTaskTableSettings";

  constructor(props: {}) {
    super(props);
    let settings = GetSettings<TicketFilterSettings>(this.filterSettingsString);
    if (settings === null) {
      settings = {
        email: "",
        assignee: "",
        status: { label: "Not Closed", value: "0" },
        category: { label: "Tickets", value: EMPTY_GUID },
        department: { label: "All", value: "-1" },
        workNumber: "",
        problem: "",
      };
      SetSettings(this.filterSettingsString, settings);
    }
    this.state = {
      headers: [],
      loaded: true,
      tickets: [],
      categories: [],
      filterSettings: settings,
      refreshed: false,
      previousController: null,
    }
    this.techTeamTicket = this.techTeamTicket.bind(this);
    this.generateHeaders = this.generateHeaders.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeAssignee = this.changeAssignee.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changeDepartment = this.changeDepartment.bind(this);
    this.changeWorkNumber = this.changeWorkNumber.bind(this);
    this.changeProblemSearch = this.changeProblemSearch.bind(this);
    this.pullTickets = this.pullTickets.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let baseCategories = [
      { label: "Tickets", value: EMPTY_GUID },
      { label: "Projects", value: ALL_ONE },
      { label: "Equipment", value: ALL_TWO },
      { label: "Documentation", value: ALL_THREE }
    ]
    let categories = baseCategories.concat(await pullSecondaryCategories());
    let _department = await getUserJobFromServer();
    let department = this.state.filterSettings.department;
    if (_department === 16) {
      department = { label: "Compliance", value: '3' };
    }
    else if (_department === 2) {
      department = { label: "HR", value: "2" };
    }
    else if (_department === 5) {
      department = { label: "Accounting", value: "1"}
    }
    let filter = this.state.filterSettings;
    filter.department = department;
    let params = new URLSearchParams(window.location.search)
    if (params.has('category')) {
      let category = categories.filter(u => u.value === params.get('category'))[0];
      filter.category = category;
    }
    this.setState({ categories: categories, filterSettings: filter }, async () => await this.pullTickets());
    this.intervalCheck = setInterval(this.pullTickets, 10000);
  }

  async componentWillUnmount(): Promise<void> {
    clearInterval(this.intervalCheck);
  }

  techTeamTicket() {
    window.location.assign("./tech-team-ticket")
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Work Number',
        additionalClasses: "fifteen",
        setting: true,
        filter: {
          type: "text",
          name: "workNumber",
          value: this.state.filterSettings.workNumber,
          changeFilter: this.changeWorkNumber,
        }
      },
      {
        columnName: 'Email',
        setting: true,
        filter: {
          type: "text",
          name: 'email',
          value: this.state.filterSettings.email,
          changeFilter: this.changeEmail,
          placeHolder: "Filter by Email"
        }
      },
      {
        columnName: "Department",
        setting: true,
        additionalClasses: "fifteen",
        filter: {
          name: 'department',
          multi: false,
          value: this.state.filterSettings.department,
          changeFilter: this.changeDepartment,
          options: SupportDepartment,
        }
      },
      {
        columnName: "Assignee",
        setting: true,
        additionalClasses: "fifteen",
        filter: {
          type: "text",
          name: 'assignee',
          value: this.state.filterSettings.assignee,
          changeFilter: this.changeAssignee,
          placeHolder: "Filter by Assignee"
        }
      },
      {
        columnName: 'Created',
        setting: true,
        additionalClasses: "twenty",
        filter: {

        }
      },
      {
        columnName: 'Status',
        setting: true,
        additionalClasses: "ten",
        filter: {
          name: "status",
          multi: false,
          options: TicketFilterStatuses,
          changeFilter: this.changeStatus,
          value: this.state.filterSettings.status
        }
      },
      {
        columnName: 'Category',
        setting: true,
        additionalClasses: "fifteen",
        filter: {
          name: "category",
          multi: false,
          options: this.state.categories,
          changeFilter: this.changeCategory,
          value: this.state.filterSettings.category
        }
      },
      {
        columnName: 'Problem',
        setting: true,
        filter: {
          name: "problem",
          type: "text",
          value: this.state.filterSettings.problem,
          changeFilter: this.changeProblemSearch,
          placeHolder: "Search terms, separated by commas",
        }
      },
    ]
    this.setState({
      headers: headers
    })
  }

  async pullTickets(): Promise<void> {
    await this.generateHeaders();
    const assignee = this.state.filterSettings.assignee ? this.state.filterSettings.assignee : "null";
    const email = this.state.filterSettings.email ? this.state.filterSettings.email : "null";
    const data = {
      Assignee: assignee,
      Page: 0,
      PageCount: 0,
      Email: email,
      Status: this.state.filterSettings.status.value,
      Category: this.state.filterSettings.category.value,
      SupportDepartment: this.state.filterSettings.department?.value !== null ? this.state.filterSettings.department?.value : -1,
      WorkNumber: this.state.filterSettings.workNumber === "" ? "null" : this.state.filterSettings.workNumber,
      ProblemSearch: this.state.filterSettings.problem
    };
    let previousController = new AbortController();
    if (this.state.previousController !== null) { this.state.previousController.abort(); }
    this.setState({ previousController: previousController });
    let response = await axios.post('./api/tickets/filter/', data, { signal: previousController.signal });
    if (response.status === 200) {
      const tickets: Array<Ticket> = [];
      const server: Array<ServerTicket> = response.data;
      const categories = this.state.categories;
      this.setState({ tickets: []})
      server.forEach(function (item: ServerTicket) {
        const updated = new Date(item.created);
        let category = categories.filter(u => u.value === item.categoryUID)[0];
        if (category === undefined) { category = {label: "Invalid Category", value: ""}}
        tickets.push({
          fullName: item.fullName,
          email: item.employee.email,
          property: item.propertyUID,
          problemSummary: item.problemSummary,
          companyName: CompanyNames.filter(u => parseInt(u.value) === item.companyName)[0],
          title: item.title,
          category: category,
          created: updated,
          phoneNumber: item.phoneNumber,
          uid: item.uid,
          ticketWorkStatus: TicketWorkStatuses.filter(u => parseInt(u.value) === item.ticketWorkStatus)[0],
          ticketWorkNumber: item.ticketWorkNumber,
          assignee: item.currentAssignee?.fullName,
          ticketComments: item.ticketComments,
          department: SupportDepartment.filter(u => u.value === item.supportDepartment.toString())[0]
        })
      })
      this.setState({
        tickets: tickets,
        refreshed: false
      })
    }
    else {
      //TODO: Add Error Handling To Tickets
    }
  }

  async changeEmail(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const filter = this.state.filterSettings;
    filter.email = event.target.value;
    this.setState({ filterSettings: filter });
    SetSettings(this.filterSettingsString, filter);
    await this.pullTickets();
  }

  async changeAssignee(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const filter = this.state.filterSettings;
    filter.assignee = event.target.value;
    this.setState({ filterSettings: filter })
    SetSettings(this.filterSettingsString, filter);
    await this.pullTickets();
  }

  async changeDepartment(event: SelectOptions | null): Promise<void> {
    const filter = this.state.filterSettings;
    filter.department = event;
    this.setState({ filterSettings: filter });
    SetSettings(this.filterSettingsString, filter);
    await this.pullTickets();
  }

  async changeStatus(event: SelectOptions | null): Promise<void> {
    const filter = this.state.filterSettings;
    filter.status = event;
    this.setState({ filterSettings: filter });
    SetSettings(this.filterSettingsString, filter);
    await this.pullTickets();
  }

  async changeCategory(event: SelectOptions | null): Promise<void> {
    let params = new URLSearchParams(window.location.search)
    params.set('category', event.value);
    window.location.search = params.toString();
    const filter = this.state.filterSettings;
    filter.category = event;
    this.setState({ filterSettings: filter });
    SetSettings(this.filterSettingsString, filter);
    await this.pullTickets();
  }

  async changeWorkNumber(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const filter = this.state.filterSettings;
    filter.workNumber = event.target.value;
    this.setState({ filterSettings: filter });
    SetSettings(this.filterSettingsString, filter);
    await this.pullTickets();
  }

  async changeProblemSearch(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const filter = this.state.filterSettings;
    filter.problem = event.target.value;
    SetSettings(this.filterSettingsString, filter);
    this.setState({ filterSettings: filter }, async () => await this.pullTickets());
  }

  refresh(): void {
    this.setState({ refreshed: true }, this.pullTickets);
  }

  render(): JSX.Element {
    return (
      <div style={{ marginTop: "1vh", paddingBottom: "5vh" }}>
        <StatusModal ref={this.statusModal} />
        {!this.state.refreshed &&
          <div id="ticket-management-table" style={{ overflow: "auto", minHeight: "80vh", maxHeight: "80vh" }}>
            <table key={"ticketTable"} className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
              </thead>
              <tbody>
                {
                  this.state.tickets.map((item) => (
                    <TicketTableRow key={item.uid} ticket={item} />
                  ))
                }
              </tbody>
            </table>
          </div>}
        
      </div>
    )
  }
}