import axios from "axios";
import * as React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { pullAccountants, pullHumanResources, pullProperties, pullSecondaryCategories, pullTechSupport, pullVirdianCompliance, pullViridianRegionals } from "../../../functions/fetchLinkedObjects";
import { SelectOptions } from "../../../interfaces/CoreInterfaces";
import { SupportDepartment } from "./TicketTableManager.constants";
import { reactSelectBasicStyle } from "../../../style/select-constants";
import Select from "react-select";
import { getUserID } from "../../../functions/authActions";

interface TaskCreationModalState {
  show: boolean;
  problemSummary: string;
  department: SelectOptions;
  assignees: Array<SelectOptions>;
  assignee: SelectOptions;
  categories: Array<SelectOptions>;
  category: SelectOptions;
  properties: Array<SelectOptions>;
  property: SelectOptions;
}

export default class TaskCreationModal extends React.Component<{}, TaskCreationModalState>{

  constructor(props: {}) {
    super(props);

    this.state = {
      show: false,
      problemSummary: "",
      department: { label: "Select Department", value: "" },
      assignees: [],
      assignee: { label: "Select Assignee", value: "" },
      categories: [],
      category: { label: "Select Categories", value: "" },
      properties: [],
      property: { label: "Select Property", value: ""}
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.save = this.save.bind(this);
    this.changeProblemSummary = this.changeProblemSummary.bind(this);
    this.changeDepartment = this.changeDepartment.bind(this);
    this.changeAssignee = this.changeAssignee.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let categories = await pullSecondaryCategories();
    let properties = await pullProperties();
    this.setState({
      categories: categories,
      properties: properties
    })
  }

  show(): void {
    this.setState({
      show: true
    })
  }

  hide(): void {
    this.setState({
      show: false
    }, () => window.location.reload());
  }

  async changeProblemSummary(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ problemSummary: event.target.value });
  }

  async changeDepartment(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ department: event }, async () => await this.pullDepartmentEmployees());
  }

  async changeAssignee(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ assignee: event });
  }

  async changeProperty(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ property: event });
  }

  async changeCategory(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    this.setState({ category: event });
  }

  async save(): Promise<void> {
    const data = {
      employeeUID: getUserID(),
      problemSummary: this.state.problemSummary,
      propertyUID: this.state.property.value,
      categoryUID: this.state.category.value,
      assigneeUID: this.state.assignee.value,
      department: this.state.department.value
    }
    let response = await axios.post('./api/tickets/task-creation', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.hide();
    }
  }

  async pullDepartmentEmployees(): Promise<void> {
    let assignees: Array<SelectOptions> = [];
    switch (this.state.department.value) {
      case "0":
        assignees = await pullTechSupport();
        break;
      case "1":
      case "5":
        assignees = await pullAccountants();
        break;
      case "2":
        assignees = await pullHumanResources();
        break;
      case "3":
        assignees = await pullVirdianCompliance();
        break;
      case "4":
        assignees = await pullViridianRegionals();
    }
    this.setState({ assignees: assignees });
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={this.hide} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            New Task
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "7px" }}>Summary</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.problemSummary} className="standard-input" onChange={this.changeProblemSummary} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "5px" }}>Department</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.department}
                  styles={reactSelectBasicStyle}
                  options={SupportDepartment}
                  onChange={this.changeDepartment}
                  menuPosition="fixed"
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "5px" }}>Assignee</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.assignee}
                  styles={reactSelectBasicStyle}
                  options={this.state.assignees}
                  onChange={this.changeAssignee}
                  menuPosition="fixed"
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "5px" }}>Property</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.property}
                  styles={reactSelectBasicStyle}
                  options={this.state.properties}
                  onChange={this.changeProperty}
                  menuPosition="fixed"
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col xs='4'>
                <h5 style={{ marginTop: "5px" }}>Category</h5>
              </Col>
              <Col>
                <Select
                  value={this.state.category}
                  styles={reactSelectBasicStyle}
                  options={this.state.categories}
                  onChange={this.changeCategory}
                  menuPosition="fixed"
                />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <input type="button" value="Create Task" className="standard-input" onClick={this.save} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}