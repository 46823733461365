import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { GraphData } from './GraphConstants';

//#region Radius Graph
interface RadiusGraphProps {
  radiusGraphProps: RadiusGraphState
}

export interface RadiusGraphState {
  width: string;
  height: string;
  values: Array<GraphData>;
  baseStroke: string;
  colors: Array<string>
}

export class RadiusGraph extends React.Component<RadiusGraphProps, RadiusGraphState>{

  constructor(props: RadiusGraphProps) {
    super(props);
    this.state = {
      width: props.radiusGraphProps.width,
      height: props.radiusGraphProps.height,
      values: props.radiusGraphProps.values,
      baseStroke: props.radiusGraphProps.baseStroke,
      colors: props.radiusGraphProps.colors
    }
  }

  componentDidMount(): void {

  }

  componentDidUpdate(): void {

  }

  render(): JSX.Element {
    return (
      <svg viewBox="0 0 36 36" width={this.state.width} height={this.state.height} style={{ display: "block", margin: "auto" }}>
        {
          this.state.values.length > 0 &&
          <g key={Math.random()} transform="rotate(-90, 18, 18)" fill="none" strokeWidth="3">
            <circle r={100 / (2 * Math.PI)} cx="50%" cy="50%"
              stroke={this.state.baseStroke}
              strokeDasharray="100 100"
            />
            {
              this.state.values.map((item, idx) =>
                <div key={Math.random()}>
                  <circle r={100 / (2 * Math.PI)} cx="50%" cy="50%"
                    stroke={this.state.colors[idx]}
                    strokeDasharray={item.value + " 100"}
                  />
                </div>
              )
            }
          </g>
        }
        {
          this.state.values.length !== 0 ?
            <text x="50%" y="50%" dy=".3em" style={{
              fill: "#ffffff",
              textAnchor: "middle",
              font: "bold 11px sans-serif"
            }}>
              {this.state.values[this.state.values.length - 1].value === 0 ? (this.state.values[this.state.values.length - 1].value)?.toFixed(0) : (this.state.values[this.state.values.length - 1].value)?.toFixed(0)}%
            </text> :
            <>
            </>
        }
      </svg>
    )
  }
}
//#endregion

//#region Pie Graph
interface PieGraphProps {
  pieGraphProps: PieGraphState;
}

export interface PieGraphState {
  width: string;
  height: string;
  values: Array<GraphData>;
  colors: Array<string>;
}

export class PieGraph extends React.Component<PieGraphProps, PieGraphState>{

  constructor(props: PieGraphProps) {
    super(props);
    this.state = {
      width: props.pieGraphProps.width,
      height: props.pieGraphProps.height,
      values: props.pieGraphProps.values,
      colors: props.pieGraphProps.colors
    }
  }

  componentDidMount(): void {

  }

  componentDidUpdate(): void {

  }

  createGraph(): JSX.Element {
    let total = 0;
    if (this.state.values.length === 0) {
      const chart = (
        <g transform="rotate(-90, 18, 18)" fill={this.state.colors[0]} strokeWidth="3">
          < circle r={
            100 / (2 * Math.PI)} cx="50%" cy="50%"
            stroke={this.state.colors[0]}
            strokeDasharray="100 100"
          />
        </g>
      )
      return chart;
    } else {
      const values = this.state.values;
      values.map((item) => {
        total = parseInt(total.toString()) + parseInt(item.value.toString());
      })
      let runningTotal = 0;
      const chart = (
        <g transform="rotate(-90, 18, 18)" fill="none" strokeWidth="32">
          {
            values.map((item, idx) => <>{
              <circle r={100 / (2 * Math.PI)} cx="50%" cy="50%"
                stroke={this.state.colors[idx]}
                strokeDasharray={((item.value / total) * 100) + " 100"}
                transform={"rotate(" + (((runningTotal) / total) * 360) + ", 18, 18)"}
              />
            }{runningTotal = parseInt(runningTotal.toString()) + parseInt(item.value.toString())}</>
            )
          }
        </g>
      )
      return chart;
    }
  }

  render(): JSX.Element {
    return (
      <>
        <svg width={this.state.width} height={this.state.height} viewBox="0 0 36 36" style={{ display: "block", margin: "auto", borderRadius: "50%" }}>
          {
            this.createGraph()
          }
        </svg>
        <Row style={{ marginLeft: "1em", marginTop: "1vh" }}>
          <>
            {this.state.values.map((item, idx) =>
              <Col>
                <h6 style={{
                  color: this.state.colors[idx],
                  marginLeft: "-5%",
                  textAlign: "center",
                  font: "bold 25px sans-serif"
                }}>{item.label}</h6>
                <h6 style={{
                  color: this.state.colors[idx],
                  marginLeft: "-5%",
                  textAlign: "center",
                  font: "bold 25px sans-serif"
                }}>{item.value.toString()}</h6>
              </Col>
            )}
          </>
        </Row>
      </>
    )
  }
}
//#endregion

//#region BarGraph
interface BarGraphProps {
  barGraphProps: BarGraphState;
}

export interface BarGraphState {
  width: string;
  height: string;
  values: Array<GraphData>;
  colors: Array<string>;
  textColor: string;
  xAxisLabel: string;
  yAxisLabel: string;
  viewBox?: string;
  viewHeight?: string;
  viewWidth?: string;
  yLabelX?: number;
  yLabelY?: number;
}

export class BarGraph extends React.Component<BarGraphProps, BarGraphState>{

  constructor(props: BarGraphProps) {
    super(props);
    this.state = {
      width: props.barGraphProps.width,
      height: props.barGraphProps.height,
      values: props.barGraphProps.values,
      colors: props.barGraphProps.colors,
      xAxisLabel: props.barGraphProps.xAxisLabel,
      yAxisLabel: props.barGraphProps.yAxisLabel,
      textColor: props.barGraphProps.textColor,
      viewBox: props.barGraphProps.viewBox,
      viewHeight: props.barGraphProps.viewHeight,
      viewWidth: props.barGraphProps.viewWidth,
      yLabelX: props.barGraphProps.yLabelX,
      yLabelY: props.barGraphProps.yLabelY
    }
  }

  componentDidMount(): void {

  }

  componentDidUpdate(): void {

  }

  createGraph(): JSX.Element {
    const arrayLength = this.state.values.length
    const values = this.state.values;
    let high = 1;
    values.map((item) => {
      if (parseInt(high.toString()) < parseInt(item.value.toString())) {
        high = parseInt(item.value.toString())
      }
    })

    const barXMinRange = parseInt(this.state.width) *.1
    const barXMaxRange = parseInt(this.state.width) * .9
    const barYMaxRange = parseInt(this.state.height) * .795
    const barWidth = (barXMaxRange / arrayLength)
    const barPadding = barWidth * .05
    const barWithPadding = barWidth + barPadding
    const barHeight = parseInt(this.state.height) * .75

    const xLabelX = (parseInt(this.state.width) * .15)
    const barLabelY = (parseInt(this.state.height) * .78)

    const yAxisLabelX = parseInt(this.state.width) * this.state.yLabelX
    const yAxisLabelY = parseInt(this.state.height) * this.state.yLabelY
    
    
    
    
    

    
    





    

    const yLabelX = parseInt(this.state.width) * .09
    const xLineXMinRange = parseInt(this.state.width) * .1
    const xLineXMaxRange = parseInt(this.state.width) * .9
    const xLineYMinRange = parseInt(this.state.height) * .01
    const xLineYMaxRange = parseInt(this.state.height) * .8

    const yLabelTop = parseInt(this.state.height) * .06
    const yLabelSecond = parseInt(this.state.height) * .245
    const yLabelThird = parseInt(this.state.height) * .43
    const yLabelFourth = parseInt(this.state.height) * .615
    const yLabelBottom = parseInt(this.state.height) * .8

    const xLabelY = parseInt(this.state.height) - 125

    const xLabelMaxRange = parseInt(this.state.width) * .925
    const xLabelRange = xLabelMaxRange - xLabelX
    const xLabelOffset = xLabelRange / arrayLength + 8

    const chart = (
      <>
        <g stroke={this.state.textColor} style={{ strokeDasharray: "0", strokeWidth: "1" }}>
          <line x1={xLineXMinRange} x2={xLineXMinRange}
            y1={xLineYMinRange} y2={xLineYMaxRange}></line>
        </g>
        <g stroke={this.state.textColor} style={{ strokeDasharray: "0", strokeWidth: "1" }}>
          <line x1={xLineXMinRange} x2={xLineXMaxRange}
            y1={xLineYMaxRange} y2={xLineYMaxRange}></line>
        </g>
        {
          this.state.values.map((item, idx) =>
            <rect
              key={Math.random()}
              width={barWidth}
              height={barHeight * (item.value / high)}
              x={(barXMinRange + barPadding) + (idx * barWithPadding)}
              y={barYMaxRange - (barHeight * (item.value / high))}
              fill={this.state.colors[idx]}
            />
          )
        }
        <g style={{ textAnchor: "end" }}>
          <text fill={this.state.textColor} x={yLabelX} y={yLabelTop}>{(high).toFixed(2)}</text>
          <text fill={this.state.textColor} x={yLabelX} y={yLabelSecond}>{((high) * .75).toFixed(2)}</text>
          <text fill={this.state.textColor} x={yLabelX} y={yLabelThird}>{((high) * .5).toFixed(2)}</text>
          <text fill={this.state.textColor} x={yLabelX} y={yLabelFourth}>{((high) * .25).toFixed(2)}</text>
          <text fill={this.state.textColor} x={yLabelX} y={yLabelBottom}>0</text>
          <text fill={this.state.textColor} x={yAxisLabelX} y={yAxisLabelY}
            style={{
              fontWeight: "bold", textTransform: "uppercase", fontSize: "12px"
            }}
            transform={"rotate(-90, " + yAxisLabelX + ", " + yAxisLabelY + ")"}
          >{this.state.yAxisLabel}</text>
        </g>
        <g style={{ textAnchor: "middle" }}>
          {
            this.state.values.map((item, idx) =>
              <text
                key={Math.random()}
                x={xLabelX + (idx * xLabelOffset)}
                y={xLabelY}
                fill={this.state.textColor}
                style={{ fontSize: "9px" }}
                transform={"rotate(-90, " + (xLabelX + (idx * xLabelOffset)) + ", " + xLabelY + ")"}
              >
                {item.label}
              </text>
            )}
          <text x={parseInt(this.state.width) / 2}
            y={parseInt(this.state.height) * .99}
            fill={this.state.textColor}
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "9px"
            }}
          >{this.state.xAxisLabel}</text>
        </g>
        {
          this.state.values.map((item, idx) =>
            <text fill={this.state.textColor}
              key={Math.random()}
              x={xLabelX + (idx * xLabelOffset)}
              y={barLabelY}
            >{item.value}</text>
          )
        }
      </>
    )
    return chart;
  }

  render(): JSX.Element {
    return (
      <svg viewBox={this.state.viewBox} width={this.state.width} height={this.state.height} style={{ marginLeft: "1vw", width: this.state.viewWidth, height: this.state.viewHeight, padding: "1vh" }} >
        {
          this.createGraph()
        }
      </svg>
    )
  }
}
//#endregion

//#region ScatterGraph
interface ScatterGraphProps {
  scatterGraphProps: ScatterGraphState;
}

export interface ScatterGraphState {
  width: string;
  height: string;
  values: Array<GraphData>;
  xAxisLabel: string;
  yAxisLabel: string;
  checkedValues: Array<GraphData>;
}

export class ScatterGraph extends React.Component<ScatterGraphProps, ScatterGraphState>{

  constructor(props: ScatterGraphProps) {
    super(props);
    this.state = {
      width: props.scatterGraphProps.width,
      height: props.scatterGraphProps.height,
      values: props.scatterGraphProps.values,
      xAxisLabel: props.scatterGraphProps.xAxisLabel,
      yAxisLabel: props.scatterGraphProps.xAxisLabel,
      checkedValues: props.scatterGraphProps.checkedValues
    }
  }

  componentDidMount(): void {

  }

  componentDidUpdate(): void {

  }

  createGraph() {
    let high = 1;
    const values = this.state.values;
    values.map((item) => {
      if (parseInt(high.toString()) < parseInt(item.value.toString())) {
        high = parseInt(item.value.toString())
      }
    })

    const chart = (
      <>
        <g style={{ textAnchor: "end" }}>
          <text x={parseInt(this.state.width) * .1}
            y={parseInt(this.state.height) * .03}>{(high * 1.1).toFixed(2)}</text>
          <text x={parseInt(this.state.width) * .1}
            y={parseInt(this.state.height) * .1875}>{((high * 1.1) * .75).toFixed(2)}</text>
          <text x={parseInt(this.state.width) * .1}
            y={parseInt(this.state.height) * .375}>{((high * 1.1) * .5).toFixed(2)}</text>
          <text x={parseInt(this.state.width) * .1}
            y={parseInt(this.state.height) * .5625}>{((high * 1.1) * .25).toFixed(2)}</text>
          <text x={parseInt(this.state.width) * .1}
            y={parseInt(this.state.height) * .75}>0</text>
          <text x={parseInt(this.state.width) * .03}
            y={parseInt(this.state.height) * .35}
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "12px",
              fill: "#000000"
            }}
            transform={"rotate(-75, " + (parseInt(this.state.width) * .03) + ", " + (parseInt(this.state.height) * .35) + ")"}
          >{this.state.yAxisLabel}</text>
        </g>
        <g style={{
          fill: "#ffffff",
          strokeWidth: "1"
        }} >
          {this.state.values.map((item, idx) =>
            <>
              <circle key={idx}
                cx={(parseInt(this.state.width) * .1) + (((parseInt(this.state.width) * .775) / this.state.values.length) * (idx + 1))}
                cy={((parseInt(this.state.height) * .74) - (item.value / ((high * 1.1) / parseInt(this.state.height) * 1.34)))}
                r="4"
              ></circle>
            </>
          )}
          {this.state.checkedValues.length > 1 ?
            <>
              {this.state.checkedValues.map((item, idx) =>
                <>
                  {idx < this.state.checkedValues.length - 1 ?
                    <>
                      <g style={{
                        stroke: "#000000",
                        strokeDasharray: "0",
                        strokeWidth: "1"
                      }}>
                        <line
                          x1={(parseInt(this.state.width) * .1) + (((parseInt(this.state.width) * .775) / this.state.values.length) * (this.state.checkedValues[idx].key + 1))}
                          x2={(parseInt(this.state.width) * .1) + (((parseInt(this.state.width) * .775) / this.state.values.length) * (this.state.checkedValues[idx + 1].key + 1))}
                          y1={((parseInt(this.state.height) * .74) - (item.value / ((high * 1.1) / parseInt(this.state.height) * 1.34)))}
                          y2={((parseInt(this.state.height) * .74) - (this.state.checkedValues[idx + 1].value / ((high * 1.1) / parseInt(this.state.height) * 1.34)))}
                        ></line>
                      </g>
                    </> :
                    <>
                    </>
                  }
                </>
              )}
            </> :
            <>
            </>
          }
        </g>
      </>
    )
    return chart;
  }

  render(): JSX.Element {
    return (
      <svg width={this.state.width} height={this.state.height}>
        <g style={{
          stroke: "#000000",
          strokeDasharray: "0",
          strokeWidth: "1"
        }}>
          <line x1={parseInt(this.state.width) * .112}
            x2={parseInt(this.state.width) * .112}
            y1={parseInt(this.state.height) * .01}
            y2={parseInt(this.state.height) * .74}></line>
        </g>
        <g style={{
          stroke: "#000000",
          strokeDasharray: "0",
          strokeWidth: "1"
        }}>
          <line x1={parseInt(this.state.width) * .112}
            x2={parseInt(this.state.width) * .9}
            y1={parseInt(this.state.height) * .74}
            y2={parseInt(this.state.height) * .74}></line>
        </g>
        <g style={{ textAnchor: "middle" }}>
          {
            this.state.values.map((item, idx) =>
              <>
                <text key={Math.random()} x={(parseInt(this.state.width) * .1) + (((parseInt(this.state.width) * .775) / this.state.values.length) * (idx + 1))}
                  y={parseInt(this.state.height) * .8}>{item.label}</text>
              </>
            )}
          <text x={parseInt(this.state.width) * .5}
            y={parseInt(this.state.height) * .88}
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "12px",
              fill: "#000000"
            }} >{this.state.xAxisLabel}</text>
        </g>
        {
          this.createGraph()
        }
      </svg>
    )
  }
}
//#endregion