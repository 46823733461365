import * as React from 'react';
import { Row } from 'reactstrap';
import EmployeeNavBarSettings from './EmployeeNavBarSettings';

interface EmployeeSettingsProps {

}
interface EmployeeSettingsState {
  currentTab: number;
}

export default class EmployeeSettings extends React.Component<EmployeeSettingsProps, EmployeeSettingsState> {

  constructor(props: EmployeeSettingsProps) {
    super(props);
    this.state = {
      currentTab: 0
    };
    this.getNavRow = this.getNavRow.bind(this);
  }

  getNavRow(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <EmployeeNavBarSettings />
      default:
        console.error("This isn't supposed to happen");
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row style={{ marginBottom: "1em" }}>
          <input type="button" className={this.state.currentTab === 0 ? "tab-active" : "tab"} style={{ width: "33%" }} value="Nav Bar Settings" onClick={() => this.setState({ currentTab: 0 })} />
        </Row>
        <Row>
          {this.getNavRow()}
        </Row>
      </>
    )
  }
}