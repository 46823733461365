import { SelectOptions, NameAndUIDObject } from "../../interfaces/CoreInterfaces";
import { EntityManagementTableSetting } from "../../reducers/TableSettingStructures";
import { FilterAndSettingField, StreetAddress } from "../CoreComponents/interfaces";

export const EntityTypes = [
  'All',
  'Limited Liability Company',
  'S Corp',
  'Limited Partnership',
  'Individual',
  'Foreign Limited Partnership',
  'Limited Liability Limited Partnership',
  'For Profit Corporation'
]

export const EntityTypes_Select: Array<SelectOptions> = [
  { label: "All", value: "0" },
  { label: "Limited Liability Company", value: "1" },
  { label: "S Corp", value: "2" },
  { label: "Limited Partnership", value: "3" },
  { label: "Individual", value: "4" },
  { label: "Foreign Limited Partnership", value: "5" },
  { label: "Limited Liability Limited Partnership", value: "6" },
  { label: "For Profit Corporation", value: "7" },
  { label: "C Corp / Inc. ", value: "8" },
]

export const EntityTypes_NewRequest: Array<SelectOptions> = [
  { label: 'Corporation', value: "7" },
  { label: "S-Corp", value: "2" },
  { label: "Limited Liability Corporation", value: "1" },
  { label: "Other", value: "0" }
]

export const EntityPurpose_NewRequest: Array<SelectOptions> = [
  { label: "Membership", value: "0" },
  { label: "Ownership", value: "1" },
  { label: "Other", value: "2" }
]

export const EntityAccountingType_NewRequest: Array<SelectOptions> = [
  { label: "Accrual", value: "0" },
  { label: "Cash", value: "1" }
]

export const EntitySCorp_NewRequest: Array<SelectOptions> = [
  { label: "No", value: "0" },
  { label: "Yes", value: "1" },
]

export const EntityManagementType_NewRequest: Array<SelectOptions> = [
  { label: "Member Managed", value: "0" },
  { label: "Manager Managed", value: "1" },
]

export const EntityBankAccountType_NewRequest: Array<SelectOptions> = [
  { label: "Controlled Account", value: "0" },
  { label: "Operating Checking", value: "1" },
  { label: "Reserve Checking", value: "2" },
  { label: "Reserve Saving", value: "3" },
  { label: "Savings General", value: "4" },
]

export const EntityBankName_NewRequest: Array<SelectOptions> = [
  { label: "Umpqua", value: "0" },
  { label: "Bank of Eastern Oregon", value: "1" },
]

export const CompoundingTypes = [
  { label: "None", value: '0' },
  { label: "Daily", value: '1' },
  //{ label: "Monthly", value: '2' },
  //{ label: "Quarterly", value: '3' },
  //{ label: "Annually", value: '4' },
]

export const CalculationMethods = [
  { label: "ACTUAL/360", value: '0' },
  { label: "ACTUAL/365", value: '1' },
/*  { label: "30/360", value: '2' },*/
]

export interface RowProperties {
  uid: string;
  date: Date;
  days: number;
  interest: number;
  advance: number;
  i_payment: number;
  i_balance: number;
  p_payment: number;
  p_balance: number;
  termLimit: number;
  monthlyPayment: number;
  isInterest: boolean;
  newInterest: number;
  previous?: RowProperties;
  index: number;
}

export interface RateChangeProperties {
  previous: AmortizationLineItem;
  currentInterestRate: number;
  termLimit: number;
  index: number;
  funcs: StandardFunctions;
  compoundType: number;
  calculationMethod: number;
  loanStartDate: Date;
}

export interface RateChangeState {
  date: Date;
  days: number;
  interest: number;
  i_balance: number;
  index: number;
  newInterest: number;
  termLimit: number;
  rate: number;
  save: (newRow: AmortizationLineItem, isInterest: boolean) => void;
  cancel: () => void;
  previous: AmortizationLineItem;
  compoundType: number;
  calculationMethod: number;
  loanStartDate: Date;
}

export interface EditableLineState {
  row: AmortizationLineItem;
  funcs: StandardFunctions;
  previous: AmortizationLineItem;
  rate: number;
  termLimit: number;
  compoundType: number;
  calculationMethod: number;
  loanStartDate: Date;
}

export interface LineDaysBalanceInterestData {
  days: number;
  p_balance: number;
  i_balance: number;
  interest: number;
}

export interface ScheduleTableDataProps {

}

export interface ScheduleTableDataState {
  isAdding: boolean;
  newRate: boolean;
  balloon: number;
  message: string;
  editableIndex: number;
  showFields: boolean;
  monthlyHeaders: Array<FilterAndSettingField>;
  principleHeaders: Array<FilterAndSettingField>;
  ignorePrincipal: boolean;
}

export interface StandardFunctions {
  save: (newRow: AmortizationLineItem, isInterest: boolean) => void;
  cancel: () => void;
}

export interface AmortizationLineItem {
  uid: string;
  date: Date;
  days: number;
  interestAccumulated: number;
  advance: number;
  i_payment: number;
  i_balance: number;
  p_payment: number;
  p_balance: number;
  monthlyPayment: number;
  isRateChange: boolean;
  newInterest: number;
  previous: AmortizationLineItem;
  index: number;
}

export interface AmortizationData {
  schedule: AmortizationSchedule;
  map: AmortizationMap;
}

export interface AmortizationSchedule {
  uid: string;
  startingInterestRate: number;
  startDate: Date;
  term: number;
  balloonTerm: number;
  principalLimit: number;
  amortizationLineItems: Array<AmortizationLineItem>;
  loanTitle: string;
  compoundingType: number;
  interestMethod: number;
  ignorePrincipal: boolean;
}

export interface AmortizationMap {
  lenderUID: string;
  borrowerUID: string;
}

//#region Entity Table Interfaces

export interface EntityData {
  uid: string;
  name: string;
  uniqueCode: string;
  isIndividual: boolean;
  jurisdiction: SelectOptions;
  firm: SelectOptions;
  type: SelectOptions;
  fein: string;
  duns: string;
  uei: string;
  cage: string;
  cageExpiration: Date;
  sosRenew: Date;
  sCorpElection: SelectOptions;
  status: SelectOptions;
  formationDate: Date;
  accountant: SelectOptions;
  portfolio: SelectOptions;
  yardiAccount: SelectOptions;
  isFirstYearReturn: SelectOptions;
  isFinalYearReturn: SelectOptions;
  notes: string;
  agent: SelectOptions;
  authorizedSigners: Array<SelectOptions>;
  ownership: string;
  managingMember: string;
  streetAddress: string;
  address: StreetAddress;
}

export interface EntityEmptyRow {
  entityTableSettings: EntityManagementTableSetting;
  create: () => void;
}

export interface EntityTableRowData {
  entity: EntityData;
  index: number;
  entityTableSettings: EntityManagementTableSetting;
  backgroundColor?: string;
  creating?: boolean;
  editRow: (uid: string, entity: EntityData) => void;
  color?: string;
  //editingAddress: boolean;
  editAddress: (index: number, updateItem?: StreetAddress) => void;
}

export interface EntityEditableTableRowState {
  entity: EntityData;
  index: number;
  create: boolean;
  entityTableSettings: EntityManagementTableSetting;
  accountingFirms: Array<SelectOptions>;
  accountants: Array<SelectOptions>;
  states: Array<SelectOptions>;
  registered_agents: Array<SelectOptions>;
  contacts: Array<SelectOptions>;
  managers: Array<SelectOptions>;
  saveRow: (index: number) => void;

}

export interface EntityEditableTableRowProps {
  entity: EntityData;
  index: number;
  create: boolean;
  entityTableSettings: EntityManagementTableSetting;
  saveRow: (index: number, create: boolean) => void;
}

export interface EntityTableSettingsModalData {
  showing?: boolean;
  entityTableSettings: EntityManagementTableSetting;
  updateSettings: (settings: EntityManagementTableSetting) => void;
  showAll: boolean;
}

//#endregion

//#region Entity Structure Interfaces
export interface EntityStructureTableData {
  owners: Array<EntityStructureOwnershipData>;
  ownedBy: Array<EntityStructureOwnershipData>;
  creating: boolean;
  assets: Array<SelectOptions>;
  assetId: string;
  headers: Array<FilterAndSettingField>;
}

export interface EntityStructureOwnershipData {
  key: string;
  name: string;
  id: string;
  percent: number;
  isManagingMember: boolean;
  type?: number;
}

//#region TODO: REFACTOR FOR A SINGLE TYPE OF RESPONSE
export interface EntityStructureOwnershipFromDB {
  uid: string;
  ownerEntity: NameAndUIDObject;
  ownerEntityId: string;
  percentageOwned: number;
  isManagingMember: boolean;
  ownershipType: number;
}

export interface EntityOwnedByStructureOwnershipFromDB {
  uid: string;
  entity: NameAndUIDObject;
  entityId: string;
  percentageOwned: number;
  isManagingMember: boolean;
  ownershipType: number;
}

export interface ManagedAssetByStructureOwnershipFromDB {
  uid: string;
  managedAsset: NameAndUIDObject;
  entityId: string;
  percentageOwned: number;
  isManagingMember: boolean;
  ownershipType: number;
}
//#endregion

export interface EntityStructureRowData {
  name: string;
  percent: number;
  isManagingMember: boolean;
  type: number;
  canArchive: boolean;
  uid: string;
  deleteRow: (uid: string) => void;
}

export interface EntityStructureRowProps {
  name: string;
  percent: number;
  isManagingMember: boolean;
  type: number;
  canArchive: boolean;
  uid: string;
  deleteRow?: (uid: string) => void;
}

export interface EntityStructureNewRowState {
  entities: Array<SelectOptions>;
  entityName: string;
  entityId: string;
  percent: number;
  isManagingMember: boolean;
  type: SelectOptions;
}

export interface EntityStructureNewRowProps {
  onClick: (name: string, percent: number, entityId: string, type: SelectOptions, isManagingMember: boolean) => void;
}
//#endregion

//#region Entity Information Page

export interface EntityInformationPageDataProps {

}

export interface EntityInformationPageDataState {
  key: string | null;
  entityName: string;
  entites: Array<SelectOptions>;
  amortizations: Array<any>;
  headers: Array<FilterAndSettingField>;
}
//#endregion

//#region EntityOwnershipMap
export interface FlowMapProps {

}

export interface FlowMapState {
  elements: Array<any>;
  loaded: boolean;
  entities: Array<SelectOptions>;
}

export interface EntityNode {
  id: string;
  type: string;
  data: EntityNodeData;
  position: Position;
  isHidden: boolean;

}

export interface EntityEdge {
  id: string;
  type: string;
  source: string;
  sourceHandle: string;
  target: string;
  targetHandle: string;
  isHidden: boolean;
  data: EntityOwnerNodeData;
}

export interface EntitySidebar {
  element: EntityNode;
  entities: Array<SelectOptions>;
}

export interface OwnerSidebar {
  element: EntityEdge;
  isEditing: boolean;
  Edit: (input: any) => void;
}

export interface EntityNodeData {
  label: any;
  id: string;
  entityId: string;
  portfolio: string;
  accountant: string;
  notes: string;
  address: string;
  agent: string;
  firm: string;
  type: string;
  fein: string;
  sosRenewalDate: string;
  duns: string;
  samCageCode: string;
}

interface EntityOwnerNodeData {
  label: any;
  uid: string;
  ownerId: string;
  entityId: string;
  ownershipType: number;
  ownershipPercentage: number;
  isManagingMember: boolean;
  purchaseDate: Date;
  dateOfSale: Date;
  parentName: string;
  childName: string;
  creating?: boolean;
  portfolio?: string;
}

interface Position {
  x: number;
  y: number;
}

//#endregion