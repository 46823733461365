import * as React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { getBearerToken } from '../functions/authActions';
import { FilterAndSettingField } from './CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from './CoreComponents/CoreTableHeaders';

function FormatCell(cell: any) {
  if (cell !== undefined && cell !== null && (cell.length > 0 || !isNaN(cell))) {
    var cellIsMoney = cell[0] === '$';
    if (cellIsMoney) {
      cell = parseFloat(cell.slice(1));
    }
    var _ = parseFloat(cell);
    var fixed = parseInt(cell) === parseFloat(cell);
    try {
      cell = isNaN(_) ? cell : cell.toFixed(fixed ? 0 : 2);
    }
    catch {

    }
    if (cellIsMoney) {
      cell = '$' + cell;
    }
  }
  return cell;
}

const ProjectRow = (props: any) => {
  var cellOne = FormatCell(props.cellOne);
  var cellTwo = FormatCell(props.cellTwo);
  var cellThree = FormatCell(props.cellThree);
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.name}</td>
      <td className="fixedTableCell">{cellOne}</td>
      <td className="fixedTableCell">{cellTwo}</td>
      {props.cellThree !== null &&
        <td className="fixedTableCell">{cellThree}</td>
      }
      <td className="fixedTableCell"><a href={props.url}><FontAwesomeIcon icon={faCog} style={{ marginLeft: "33%" }} /></a></td>
    </tr>
  )
}

//export class SolarProjectList extends React.Component<any, any> {
//    render() {
//        return (
//            <ProjectList type="Solar" url="/solar-project-form"
//                get="/api/solar-projects" name="ProjectMapper.Property.Name"
//                cellOne="SystemWattSize" cellTwo="ProductionSystemName" cellThree="SystemStatusName"
//                headerOne="System Size (Watts)" headerTwo="Production System" headerThree="System Status" headerFour="Screener"
//            />
//        )
//    }
//}

interface SolarProjectListProps {
  type: any;
  url: any;
  get: any;
  name: any;
  cellOne: any;
  cellTwo: any;
  cellThree: any;
  headerOne: any;
  headerTwo: any;
  headerThree: any;
}

interface SolarProjectListState {
  type: any;
  url: any;
  get: any;
  name: any;
  cellOne: any;
  cellTwo: any;
  cellThree: any;
  headerOne: any;
  headerTwo: any;
  headerThree: any;
  projectRows: Array<any>;
  headers: Array<any>;
}

export class SolarProjectList extends React.Component<SolarProjectListProps, SolarProjectListState> {

  constructor(props: SolarProjectListProps) {
    super(props);
    this.state = {
      type: props.type,
      url: props.url,
      get: props.get,
      name: props.name,
      cellOne: props.cellOne,
      cellTwo: props.cellTwo,
      cellThree: props.cellThree,
      headerOne: props.headerOne,
      headerTwo: props.headerTwo,
      headerThree: props.headerThree,
      projectRows: [],
      headers: this.generateHeaders()
    }
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get("/api/solar-projects").then(function (response) {
      var projects: any = [];
      response.data.forEach(function (item: any) {
        self.renderItem(item, projects);
      });
      self.setState({
        projectRows: projects
      })
    })
  }

  renderItem(item: any, projects: any): void {
    const url = "/solar-project-form?uid=" + item.uid;
    var name = item.property.name;
    var cellOne = item.systemWattSize;
    var cellTwo = item.productionSystemName
    var cellThree = item.systemStatusName
    projects.push({
      name: name,
      url: url,
      watts: cellOne,
      system: cellTwo,
      status: cellThree
    });
  }

  Add(): void {
    window.location.assign("/solar-project-form");
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Property Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "System Watt Size",
        setting: true,
        filter: {}
      },
      {
        columnName: "Production System Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "System Status Name",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div>
        <h3 style={{ marginLeft: "-25px" }}>Solar Projects</h3>
        <div id="management-table-container" style={{ marginLeft: "-25px", maxHeight: "78vh", minHeight: "78vh", overflow: "scroll" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={1} />
            </thead>
            <tbody id="list-of-projects">
              {
                this.state.projectRows.map((item: any) => (
                  <ProjectRow name={item.name} url={item.url} cellOne={item.watts} cellTwo={item.system} cellThree={item.status} />
                ))
              }
            </tbody>
          </table>
        </div>
        <hr />
        <input value={"Add New Solar Project"} className="standard-input" style={{ width: "25%" }} type="button" onClick={this.Add} />
      </div>
    )
  }
}