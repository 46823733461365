import * as React from "react";
import { Col, Row } from "reactstrap";
import NewEntityRequestDraftsTable from "./NewEntityRequestDraftsTable";
import NewEntityInProgressTable from "./NewEntityRequestInProgressTable";
import NewEntityRequestSubmittedDraftsTable from "./NewEntityRequestSubmittedDraftsTable";

export interface NewEntityRequestDraftManagerProps {

}

export interface NewEntityRequestDraftManagerState {
  currentTab: number;
}


export default class NewEntityRequestDraftManager extends React.Component<NewEntityRequestDraftManagerProps, NewEntityRequestDraftManagerState>{

  constructor(props: NewEntityRequestDraftManagerProps) {
    super(props);
    this.state = {
      currentTab: 0
    }
    this.getTab = this.getTab.bind(this);
  }

  async componentDidMount(): Promise<void> {

  }

  getTab(): JSX.Element {
    switch (this.state.currentTab) {
      case 0:
        return <NewEntityRequestDraftsTable />
      case 1:
        return <NewEntityRequestSubmittedDraftsTable />
      case 2:
        return <NewEntityInProgressTable />
      case 3:
        return <h1>Not Yet Implemented</h1>
      default:
        console.error("Someone has figured out how to choose an incorrect tab");
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Row style={{ marginBottom: "2vh" }}>
          <Col xs="10">
            <h4>New Entity Request Drafts</h4>
          </Col>
          <Col>
            <input type="button" className="standard-input" style={{ width: "100%" }} value="Add New" onClick={() => window.location.assign("./entity-request-form")} />
          </Col>
        </Row>
        <Row >
          <Col style={{ marginBottom: "10px" }}>
            <input type="button" className={this.state.currentTab === 0 ? "tabActive" : "tab"} style={{ width: "25%" }} value="New Entity Drafts" onClick={() => this.setState({ currentTab: 0 })} />
            <input type="button" className={this.state.currentTab === 1 ? "tabActive" : "tab"} style={{ width: "25%" }} value="Requested Entities" onClick={() => this.setState({ currentTab: 1 })} />
            <input type="button" className={this.state.currentTab === 2 ? "tabActive" : "tab"} style={{ width: "25%" }} value="In-Progress" onClick={() => this.setState({ currentTab: 2 })} />
            <input type="button" className={this.state.currentTab === 3 ? "tabActive" : "tab"} style={{ width: "25%" }} value="Completed" onClick={() => this.setState({ currentTab: 3 })} />
          </Col>
        </Row>
        <Row style={{ width: "98%", marginLeft: "1%" }}>
          <Col>
            {
              this.getTab()
            }
          </Col>
        </Row>
      </>
    )
  }
}