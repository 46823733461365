import * as React from 'react';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../style/select-constants';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { numberWithCommas } from '../../functions/numberFunctions';

export interface ElectricRow {
  description: string;
  rate: number;
  start: number;
  end: number;
  calculationType: SelectOptions;
  estimatedCost: number;
}

interface ElectricDataRowProps {
  id: string;
  updateRow: (row: ElectricRow, idx: number) => void;
  removeRow: (id: string) => void;
  row: ElectricRow;
  index: number;
}

interface ElectricDataRowState {
  id: string;
  row: ElectricRow;
  index: number;
}

export default class ElectricDataRow extends React.Component<ElectricDataRowProps, ElectricDataRowState> {

  static CalculationTypes = [
    { label: "Consumption", value: "0" },
    { label: "Demand", value: "1" },
    { label: "Flat", value: "2" },
    { label: "Tax", value: "3" }
  ]

  constructor(props: ElectricDataRowProps) {
    super(props);
    this.state = {
      id: props.id,
      row: props.row,
      index: props.index
    }
    this.changeDescription = this.changeDescription.bind(this);
    this.changeRate = this.changeRate.bind(this);
    this.changeStartRate = this.changeStartRate.bind(this);
    this.changeEndRate = this.changeEndRate.bind(this);
    this.changeCalculationType = this.changeCalculationType.bind(this);
  }

  changeDescription(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.description = event.target.value;
    this.setState({ row: row }, () => this.props.updateRow(row, this.state.index))
  }

  changeRate(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.rate = parseFloat(event.target.value);
    this.setState({ row: row }, () => this.props.updateRow(row, this.state.index))
  }

  changeStartRate(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.start = parseFloat(event.target.value);
    this.setState({ row: row }, () => this.props.updateRow(row, this.state.index))
  }

  changeEndRate(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.end = parseFloat(event.target.value);
    this.setState({ row: row }, () => this.props.updateRow(row, this.state.index))
  }

  changeCalculationType(event: SelectOptions | null): void {
    if (event === null) { return; }
    let row = this.state.row;
    row.calculationType = event;
    this.setState({ row: row }, () => this.props.updateRow(row, this.state.index))
  }

  render(): JSX.Element {
    return (
      <tr key={this.props.index} className="rate-data">
          <td className="fixedTableCellFirst">
            <input className="standard-input" type='text' value={this.state.row.description} placeholder='Delivery Charge' onChange={this.changeDescription} />
          </td>
          <td className="fixedTableCell">
            <input id="rate" type='number' value={this.state.row.rate} className="electric-rate standard-input" placeholder='0.000' onChange={this.changeRate} />
          </td >
          <td className="fixedTableCell">
            <input id="startRate" type='number' value={this.state.row.start} className="start-rate standard-input" placeholder="0" onChange={this.changeStartRate} />
          </td>
          <td className="fixedTableCell">
            <input id="endRate" type='number' value={this.state.row.end} className="end-rate standard-input" placeholder="-1" onChange={this.changeEndRate} />
          </td>
          <td className="fixedTableCell">
            <Select
              options={ElectricDataRow.CalculationTypes}
              styles={reactSelectBasicStyle}
              onChange={this.changeCalculationType}
              value={this.state.row.calculationType}
            />
          </td>
          <td className="fixedTableCell">
            ${numberWithCommas(this.state.row.estimatedCost)}
          </td>
          <td className="fixedTableCell">
            <input type="button" value="Remove Row" onClick={(e) => this.props.removeRow(this.state.id)} className="standard-input" />
          </td>
        </tr>
    )
  }
}