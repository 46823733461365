import * as React from 'react'
import { CNAExecutiveSummaryWidgetProps, CNAExecutiveSummaryWidgetState, CNAYearAmount } from './CNAExecutiveSummaryWidget.constants';
import { StatusModal, WarningModal } from '../../../CoreComponents/Modals';
import axios from 'axios';
import { FilterAndSettingField } from '../../../CoreComponents/interfaces';
import { Col, Row } from 'reactstrap';
import FilteredWithSettingsTableHeader from '../../../CoreComponents/CoreTableHeaders';

export default class CNAExecutiveSummaryWidget extends React.Component<CNAExecutiveSummaryWidgetProps, CNAExecutiveSummaryWidgetState> {

  statusRef: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningRef: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: CNAExecutiveSummaryWidgetProps) {
    super(props);
    this.state = {
      site: [],
      architectual: [],
      mechAndElec: [],
      dwellUnit: [],
      headers: [],
      totalData: [],
      inflationData: [],
      inflatedTotal: [],
      inflatePercent: 7
    }
    this.updateInflationPercent = this.updateInflationPercent.bind(this)
  }

  async componentDidMount(): Promise<void> {
    const inflatedTotal: Array<any> = []
    const inflationData: Array<any> = []
    const totalList: Array<CNAYearAmount> = []
    const site: Array<CNAYearAmount> = []
    const architectual: Array<CNAYearAmount> = []
    const mechAndElec: Array<CNAYearAmount> = []
    const dwellUnit: Array<CNAYearAmount> = []

    inflationData.push(0)
    totalList.push({ year: i + (new Date(Date.now()).getFullYear() - 1), amount: 0 })
    inflatedTotal.push({ year: i + (new Date(Date.now()).getFullYear() - 1), amount: 0 })
    site.push({ year: i + (new Date(Date.now()).getFullYear() - 1), amount: 0 })
    architectual.push({ year: i + (new Date(Date.now()).getFullYear() - 1), amount: 0 })
    mechAndElec.push({ year: i + (new Date(Date.now()).getFullYear() - 1), amount: 0 })
    dwellUnit.push({ year: i + (new Date(Date.now()).getFullYear() - 1), amount: 0 })


    for (var i = 0; i < 21; i++) {
      let data = Math.pow((1 + (this.state.inflatePercent / 100.0)), i)
      data = (data - 1) * 100
      inflationData.push(data)

      totalList.push({ year: i + (new Date(Date.now()).getFullYear()), amount: 0 })
      inflatedTotal.push({ year: i + (new Date(Date.now()).getFullYear()), amount: 0 })
      site.push({ year: i + (new Date(Date.now()).getFullYear()), amount: 0 })
      architectual.push({ year: i + (new Date(Date.now()).getFullYear()), amount: 0 })
      mechAndElec.push({ year: i + (new Date(Date.now()).getFullYear()), amount: 0 })
      dwellUnit.push({ year: i + (new Date(Date.now()).getFullYear()), amount: 0 })
    }

    const siteResponse = await axios.get('./api/cna-line-item-year-data/cna-summary/site/' + this.props.cnaUID)
    const architectualResponse = await axios.get('./api/cna-line-item-year-data/cna-summary/architectual/' + this.props.cnaUID)
    const mechAndElecResponse = await axios.get('./api/cna-line-item-year-data/cna-summary/mech-and-electric/' + this.props.cnaUID)
    const dwellUnitResponse = await axios.get('./api/cna-line-item-year-data/cna-summary/dwelling-unit/' + this.props.cnaUID)

    if (siteResponse.status === 200) {
      siteResponse.data.forEach((item: any) => {
        item.forEach((year: any, idx: number) => {
          if (year.has) {
            site[0].amount += year.amount
            totalList[0].amount += year.amount
            inflatedTotal[0].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
          architectual[idx + 1].amount += year.amount
          totalList[idx + 1].amount += year.amount
          if (idx < 1) {
            inflatedTotal[idx + 1].amount += year.amount
          }
          else {
            inflatedTotal[idx + 1].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
        })
      })
    }
    else {
      //TODO ERROR HANDLE
    }
    if (architectualResponse.status === 200) {
      architectualResponse.data.forEach((item: any) => {
        item.forEach((year: any, idx: number) => {
          if (year.has) {
            architectual[0].amount += year.amount
            totalList[0].amount += year.amount
            inflatedTotal[0].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
          architectual[idx + 1].amount += year.amount
          totalList[idx + 1].amount += year.amount
          if (idx < 1) {
            inflatedTotal[idx + 1].amount += year.amount
          }
          else {
            inflatedTotal[idx + 1].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
        })
      })
    }
    else {
      //TODO ERROR HANDLE
    }
    if (mechAndElecResponse.status === 200) {
      mechAndElecResponse.data.forEach((item: any) => {
        item.forEach((year: any, idx: number) => {
          if (year.has) {
            mechAndElec[0].amount += year.amount
            totalList[0].amount += year.amount
            inflatedTotal[0].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
          architectual[idx + 1].amount += year.amount
          totalList[idx + 1].amount += year.amount
          if (idx < 1) {
            inflatedTotal[idx + 1].amount += year.amount
          }
          else {
            inflatedTotal[idx + 1].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
        })
      })
    }
    else {
      //TODO ERROR HANDLE
    }
    if (dwellUnitResponse.status === 200) {
      dwellUnitResponse.data.forEach((item: any) => {
        item.forEach((year: any, idx: number) => {
          if (year.has) {
            dwellUnit[0].amount += year.amount
            totalList[0].amount += year.amount
            inflatedTotal[0].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
          architectual[idx + 1].amount += year.amount
          totalList[idx + 1].amount += year.amount
          if (idx < 1) {
            inflatedTotal[idx + 1].amount += year.amount
          }
          else {
            inflatedTotal[idx + 1].amount += year.amount * Math.pow((1 + (this.state.inflatePercent / 100.0)), idx + 1)
          }
        })
      })
    }
    else {
      //TODO ERROR HANDLE
    }

    this.setState({
      site: site,
      architectual: architectual,
      mechAndElec: mechAndElec,
      dwellUnit: dwellUnit,
      totalData: totalList,
      inflationData: inflationData,
      inflatedTotal: inflatedTotal
    })
    this.getHeaders();
  }

  getHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: 'Item',
        additionalClasses: "thirty",
        setting: true,
        filter: {

        }
      },
      {
        columnName: 'H & S',
        additionalClasses: "fifteen",
        setting: true,
        filter: {

        }
      }
    ]
    let year = new Date(Date.now()).getFullYear();
    for (var count = 0; count < 21; count++) {
      headers.push({
        columnName: (year + count).toString(),
        additionalClasses: "fifteen",
        setting: true,
        filter: {

        }
      })
    }
    this.setState({ headers: headers })
  }
  updateInflationPercent(event: React.ChangeEvent<HTMLInputElement>): void {
    if (Number.isNaN(parseInt(event.target.value))) {
      event.target.value = "0"
    }

    if (parseInt(event.target.value) > 0) {
      this.state.totalData.forEach((item, idx) => {
        this.state.inflatedTotal[idx].amount = item.amount * Math.pow((1 + (parseInt(event.target.value) / 100.0)), idx)
        this.state.inflationData[idx] = Math.pow((1 + (parseInt(event.target.value) / 100.0)), idx)
      })
    }
    else if (parseInt(event.target.value) <= 0) {
      this.state.totalData.forEach((item, idx) => {
        this.state.inflatedTotal[idx].amount = item.amount
        this.state.inflationData[idx] = 0
      })
    }
    this.setState({ inflatePercent: parseInt(event.target.value) })
  }

  render(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <table className="fixedTable" style={{ margin: "1vh" }}>
              <thead>
                <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
              </thead>
              <tbody>
                {this.state.site.length > 0 && <tr key={Math.random()}>
                  <td className="fixedTableCellFirst">Sites</td>
                  {
                    this.state.site.map((item) => (
                      <td key={Math.random()} className="fixedTableCell">$ {item.amount}</td>
                    ))
                  }
                </tr>}
                {this.state.architectual.length > 0 && <tr key={Math.random()}>
                  <td className="fixedTableCellFirst">Architectual</td>
                  {
                    this.state.architectual.map((item) => (
                      <td key={Math.random()} className="fixedTableCell">$ {item.amount}</td>
                    ))
                  }
                </tr>}
                {this.state.mechAndElec.length > 0 && <tr key={Math.random()}>
                  <td className="fixedTableCellFirst">Mechanical And Electric</td>
                  {
                    this.state.mechAndElec.map((item) => (
                      <td key={Math.random()} className="fixedTableCell">$ {item.amount}</td>
                    ))
                  }
                </tr>}
                {this.state.dwellUnit.length > 0 && <tr key={Math.random()}>
                  <td key={Math.random()} className="fixedTableCellFirst">Dwelling Units</td>
                  {
                    this.state.dwellUnit.map((item, idx) => (
                      <td key={idx * 1000} className="fixedTableCell">$ {item.amount}</td>
                    ))
                  }
                </tr>}
                <tr key={Math.random()}>
                  <td key={Math.random()} className="fixedTableCellFirst">Uninflated Totals</td>
                  {
                    this.state.totalData.map((item) => (
                      <td key={Math.random()} className="fixedTableCell">$ {item.amount}</td>
                    ))
                  }
                </tr>
                <tr key={Math.random()}>
                  <td key={"STATIC_VALUE"} className="fixedTableCellFirst">Inflation Factor:  <input type="text" className="standard-input" style={{ width: "2vw", height: "3vh", float: "right", textAlign: "right" }} value={this.state.inflatePercent} onChange={this.updateInflationPercent} /></td>
                  {
                    this.state.inflationData.map((item, idx) => (
                      <td key={Math.random()} className="fixedTableCell">{item.toFixed(2)} %</td>
                    ))
                  }
                </tr>
                <tr key={Math.random()}>
                  <td key={Math.random()} className="fixedTableCellFirst">Inflated Totals</td>
                  {
                    this.state.inflatedTotal.map((item) => (
                      <td key={Math.random()} className="fixedTableCell">$ {item.amount.toFixed(2)}</td>
                    ))
                  }
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </>
    )
  }
}