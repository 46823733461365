import axios from 'axios';
import { NameAndUID, NameAndUIDObject, NameAndUIDObject_Property, SelectCourseOptions, SelectOptions, SelectOptionsProperty } from '../interfaces/CoreInterfaces';
import { getBearerToken, getUserID, getUserJobFromServer } from './authActions';


/**
 * This file is designed to pull Select Options for Dropdowns. Each of the functions below 
 * are used to pull SelectOptions (or interfaces inheriting from SelectOptions such as Properties)
 * If you are adding to this file please ensure that you return an Array of SelectOptions (or extended interfaces)
 * If the functions do not return SelectOptions they will be removed during refactor
 */
function UnusableFunctionOnlyCreatedForJavascriptComments(): void {
  // DOESNT DO ANYTHING
}

/**
 * Pull the State enum, this function should replace all calls similar to it across the frontend 
 * There are parts of the front-end UI that need updating (Select implemented) using this function
 * 
 * This is the first totally compliant function to the requirements outlined in the above TODO
 */
export async function pullStates(): Promise<Array<SelectOptions>> {
  const states: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('/api/state-enum').then(function (response) {
    for (const key in response.data) {
      states.push({
        value: key,
        label: response.data[key]
      });
    }
  });
  return states;
}

/**
 * Pull Accounting Firms from the server. 
 */
export async function pullAccountingFirms(): Promise<Array<SelectOptions>> {
  const firms: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/accounting-firms").then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      firms.push({
        value: item.uid,
        label: item.name
      });
    });
  });
  return firms;
}

/**
 * Pull Empire Employees who are assigned as an Accountant in the development or Property Management Teams. 
 * 
 * Returns them as an Array<SelectOptions>
 */
export async function pullAccountants(): Promise<Array<SelectOptions>> {
  const accountants: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/accountants").then(function (response) {
    response.data.forEach(function (item: any) {
      accountants.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return accountants;
}

/**
 * 
 * @returns
 */
export async function pullFinanceLeads(): Promise<Array<SelectOptions>> {
  const accountants: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/finance").then(function (response) {
    response.data.forEach(function (item: any) {
      accountants.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return accountants;
}

export async function pullProjectCoordinators(): Promise<Array<SelectOptions>> {
  const accountants: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/project-coordinator").then(function (response) {
    response.data.forEach(function (item: any) {
      accountants.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return accountants;
}


export async function pullTechSupport(): Promise<Array<SelectOptions>> {
  const tech: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("/api/empire/auth/tech");
  return response.data;
}

export async function pullAssetManagement(): Promise<Array<SelectOptions>> {
  const tech: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("/api/empire/auth/asset-management");
  return response.data;
}

export async function pullHumanResources(): Promise<Array<SelectOptions>> {
  const list: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/human-resources").then(function (response) {
    response.data.forEach(function (item: any) {
      list.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return list;
}

export async function pullViridianAccountants(): Promise<Array<SelectOptions>> {
  const list: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/viridian-accountant").then(function (response) {
    response.data.forEach(function (item: any) {
      list.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return list;
}


export async function pullVirdianCompliance(): Promise<Array<SelectOptions>> {
  const list: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/viridian-compliance").then(function (response) {
    response.data.forEach(function (item: any) {
      list.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return list;
}

export async function pullViridianRegionals(): Promise<Array<SelectOptions>> {
  const list: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/viridian-regional").then(function (response) {
    response.data.forEach(function (item: any) {
      list.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return list;
}

export async function pullAdmin(): Promise<Array<SelectOptions>> {
  const list: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/admin").then(function (response) {
    response.data.forEach(function (item: any) {
      list.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return list;
}

export async function pullViridianSiteManagers(): Promise<Array<SelectOptions>> {
  const list: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/viridian-site-managers").then(function (response) {
    response.data.forEach(function (item: any) {
      list.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return list;
}

export async function pullViridianMaintenanceManagers(): Promise<Array<SelectOptions>> {
  const list: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/viridian-maintenance-managers").then(function (response) {
    response.data.forEach(function (item: any) {
      list.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return list;
}

export async function pullFleetEmployee(): Promise<Array<SelectOptions>> {
  const employee: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth").then(function (response) {
    response.data.forEach(function (item: any) {
      employee.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return employee;
}

export async function pullEmpireEmployees(): Promise<Array<SelectOptions>> {
  const employee: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth").then(function (response) {
    response.data.forEach(function (item: any) {
      employee.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return employee;
}

export async function pullEmployeesByCompany(company: number): Promise<Array<SelectOptions>> {
  const employee: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/company/" + company).then(function (response) {
    response.data.forEach(function (item: any) {
      employee.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return employee;
}

/**
 * 
 * 
 */
export async function pullMCICourses(): Promise<Array<SelectOptions>> {
  const courses: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/course-config').then(function (response) {
    response.data.forEach(function (item: any) {
      courses.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return courses;
}

/**
 * 
 * 
 */
export interface UIDNameParse {
  uid: string;
  name: string;
}

export async function pullActiveMCICourses(): Promise<Array<SelectCourseOptions>> {
  const courses: Array<SelectCourseOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/course-config').then(function (response) {
    response.data.forEach(function (item: UIDNameParse, idx: number) {
      courses.push({
        value: item.uid,
        label: item.name,
        order: idx + 1
      })
    })
  })
  return courses;
}

/**
 * 
 * 
 */
export async function pullMCIInstructors(): Promise<Array<SelectOptions>> {
  const instructors: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/mciinstructors").then(function (response) {
    response.data.forEach(function (item: any) {
      instructors.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return instructors;
}

/**
 * 
 */
export async function pullMCIStudents(includeEmails: boolean = false): Promise<Array<SelectOptions>> {
  const students: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/student-profile').then(function (response) {
    response.data.forEach(function (item: any) {
      students.push({
        value: item.uid,
        label: item.fullName + (includeEmails ? " (" + item.email + ")" : "")
      })
    });
  });
  return students;
}

export async function pullMCIStudentsMinimal(): Promise<Array<SelectOptions>> {
  let students: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/student-profile/minimal').then(function (response) {
    students = response.data;
  });
  return students;
}

/**
 * Pull Registered Agents into an array of select options
 */
export async function pullRegisteredAgents(): Promise<Array<SelectOptions>> {
  let output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("/api/registered-agents/minimal");
  if (response.status === 200) {
    output = response.data;
  }
  return output;
}

/**
 * Pull Contacts into a Select Options Array
 */
export async function pullContacts(): Promise<Array<SelectOptions>> {
  let output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("/api/contacts/minimal"); 
  if (response.status === 200) {
    output = response.data;
  }
  return output;
}

/**
 * 
 * @returns
 */
export async function pullContactOwners(): Promise<Array<SelectOptions>> {
  let output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("/api/contacts/owners");
  if (response.status === 200) {
    output = response.data;
  }
  return output;
}

/**
 * Pull Entities into a Select Options array for use with Select Options
 * 
 */
export async function pullEntities(useIndividuals: boolean = false): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/entities/' + useIndividuals + "/minimal/").then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    });
  });
  return output;
}

export async function pullEntityContractors(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/entities/contractors').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    });
  });
  return output;
}

/**
 * Pull the Management Companies that can be used as Entity Managers
 * @returns
 */
export async function pullEntityManagers(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/entities/get-management-companies').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    });
  });
  return output;
}

export async function pullEntitiesWithIndividuals(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/entities/individuals').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    });
  });
  return output;
}


export async function pullProperties(): Promise<Array<SelectOptionsProperty>> {
  const output: Array<SelectOptionsProperty> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("./api/property/minimal"); 
  if (response.status === 200) {
    response.data.forEach((item: NameAndUIDObject_Property) => {
      output.push({
        value: item.uid,
        label: item.name,
        code: item.code
      })
    });
  }
  return output;
}

export async function pullNonPropertyPaidProperties(): Promise<Array<SelectOptionsProperty>> {
  const output: Array<SelectOptionsProperty> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("./api/property/is-not-property-paid");
  if (response.status === 200) {
    response.data.forEach((item: NameAndUIDObject_Property) => {
      output.push({
        value: item.uid,
        label: item.name,
        code: item.code
      })
    });
  }
  return output;
}

export async function pullPropertiesForRegionals(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("./api/property/regionals/" + getUserID()).then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    });
  });
  return output;
}

/**
 * Pull Properties with Solar Projects 
 */
export async function pullSolarPropertiesForSelect(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("./api/property/solar").then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    });
  });
  return output
}


export async function pullVendorsForSelect(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("./api/vendors/")
    .then(function (response) {
      response.data.forEach(function (item: NameAndUID) {
        output.push({
          value: item.uid,
          label: item.name
        })
      })
    });
  return output;
}

export async function pullSolarVendors(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("./api/vendors/solar-contractors")
    .then(function (response) {
      response.data.forEach(function (item: NameAndUID) {
        output.push({
          value: item.uid,
          label: item.name
        })
      })
    });
  return output;
}

export async function pullExternalCompaniesVendorType(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/vendors/type/2').then(function (response) {
    response.data.forEach(function (item: NameAndUID) {
      output.push({
        label: item.name,
        value: item.uid
      })
    })
  })
  return output;
}

export async function pullExternalCompaniesCustomerType(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/vendors/type/1').then(function (response) {
    response.data.forEach(function (item: NameAndUID) {
      output.push({
        label: item.name,
        value: item.uid
      })
    })
  })
  return output;
}

export async function pullExternalCompaniesABCHASE(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/vendors/abchase-contractors').then(function (response) {
    response.data.forEach(function (item: NameAndUID) {
      output.push({
        label: item.name,
        value: item.uid
      })
    })
  })
  return output;
}

export async function pullEntitiesVendorType(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/entities/type/2').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}

export async function pullEntitiesCustomerType(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/entities/type/1').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}


//#region Utility (housing) Methods
export async function pullElectricUtilityProviders(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/electric-utility-providers').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}


export async function pullWaterUtilityProviders(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/water-utility-providers').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}

export async function pullSewerUtilityProviders(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/sewer-utility-providers').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}

export async function pullSolidWasteUtilityProviders(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/solid-waste-utility-providers').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}

export async function pullCableUtilityProviders(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/cable-utility-providers').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}

export async function pullTelephoneUtilityProviders(): Promise<Array<SelectOptions>> {
  const output: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/telephone-utility-providers').then(function (response) {
    response.data.forEach(function (item: NameAndUIDObject) {
      output.push({
        value: item.uid,
        label: item.name
      })
    })
  })
  return output;
}
//#endregion

//#region Job Management

export async function pullDrawsBySite(siteUID: string): Promise<Array<SelectOptions>> {
  const draws: Array<SelectOptions> = [];
  await axios.get('./api/draw-requests/' + siteUID + "/false").then((response) => {
    response.data.forEach(function (draw: any) {
      draws.push({
        label: draw.number + 1,
        value: draw.uid
      })
    })
  })
  return draws;
}

//#endregion

//#region Subcontracting Management


export async function pullConstructionSupervisors(): Promise<Array<SelectOptions>> {
  const accountants: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/construction-supervisors").then(function (response) {
    response.data.forEach(function (item: any) {
      accountants.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return accountants;
}

export async function pullConstructionCoordinators(): Promise<Array<SelectOptions>> {
  const accountants: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/project-coordinators").then(function (response) {
    response.data.forEach(function (item: any) {
      accountants.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return accountants;
}

export async function pullConstructionForeman(): Promise<Array<SelectOptions>> {
  const accountants: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get("/api/empire/auth/construction-foreman").then(function (response) {
    response.data.forEach(function (item: any) {
      accountants.push({
        value: item.uid,
        label: item.fullName
      })
    });
  });
  return accountants;
}


export async function pullSubcontractingProjectsType(): Promise<Array<SelectOptions>> {
  const draws: Array<SelectOptions> = [];
  let job = await getUserJobFromServer();
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/service-projects/' + job + "/" + getUserID()).then((response) => {
    response.data.forEach(function (draw: any) {
      draws.push({
        label: draw.name,
        value: draw.uid
      })
    })
  })
  return draws;
}

export async function pullAllSubcontractingProjectsForSelect(): Promise<Array<SelectOptions>> {
  const draws: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/service-projects/minimal').then((response) => {
    response.data.forEach(function (draw: any) {
      draws.push({
        label: draw.label,
        value: draw.value
      })
    })
  })
  return draws;
}

export async function pullSubcontractingJobs(): Promise<Array<SelectOptions>> {
  const draws: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/service-jobs/minimal').then((response) => {
    response.data.forEach(function (draw: any) {
      draws.push({
        label: draw.label,
        value: draw.value
      })
    })
  })
  return draws;
}
//#endregion


export async function pullPropertyPayrollDates(propertyUID: string): Promise<Array<SelectOptions>> {
  let dates: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/property-payroll-tracking/dates/' + propertyUID).then((response) => {
    dates = response.data;
  })
  return dates;
}

export async function pullEmployeeAllocationArchiveDates(): Promise<Array<SelectOptions>> {
  let dates: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/property-employee-map-archive/dates/').then((response) => {
    console.log(response.data);
    dates = response.data;
  })
  return dates;
}

export async function pullComputerConfigurations(): Promise<Array<SelectOptions>> {
  let configs: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/computer-configuration-tracking/minimal').then((response) => {
    configs = response.data;
  })
  return configs;
}

export async function pullComputerTracking(): Promise<Array<SelectOptions>> {
  let configs: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/computer-tracking/minimal').then((response) => {
    configs = response.data;
  })
  return configs;
}

/**
 * This function pulls the Phone Tracking table rows into a select options array for usage as 
 * options in a react-select field. 
 * @returns Phone Tracking as an Array of SelectOptions
 */
export async function pullPhoneTracking(): Promise<Array<SelectOptions>> {
  let configs: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/phone-tracking/minimal').then((response) => {
    configs = response.data;
  })
  return configs;
}

/**
 * This function pulls the Primary Categories (Tasks/Tickets) table rows into a select options array for usage as 
 * options in a react-select field. 
 * @returns Primary Categories (Tasks/Tickets) as an Array of SelectOptions
 */
export async function pullPrimaryCategories(): Promise<Array<SelectOptions>> {
  let configs: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/primary-ticket-category/minimal').then((response) => {
    configs = response.data;
  })
  return configs;
}

/**
 * This function pulls the Secondary Categories (Tasks/Tickets) table rows into a select options array for usage as 
 * options in a react-select field. 
 * @returns Secondary Categories (Tasks/Tickets) as an Array of SelectOptions
 */
export async function pullSecondaryCategories(): Promise<Array<SelectOptions>> {
  let configs: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/secondary-ticket-category/minimal').then((response) => {
    configs = response.data;
  })
  return configs;
}

/**
 * This function pulls the Bank table rows into a select options array for usage as 
 * options in a react-select field. 
 * @returns Banks as an Array of SelectOptions
 */
export async function pullBanks(): Promise<Array<SelectOptions>> {
  let banks: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/banks/minimal').then((response) => {
    banks = response.data;
  })
  return banks;
}


/**
 * 
 * @returns
 */
export async function pullManagedJobs(): Promise<Array<SelectOptions>> {
  let jobs: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/managed-jobs/minimal').then((response) => {
    jobs = response.data;
  })
  return jobs;
}

export async function pullManagedProjectsByJob(uid: string): Promise<Array<SelectOptions>> {
  let jobs: Array<SelectOptions> = [];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  await axios.get('./api/managed-job-projects/job/' + uid).then((response) => {
    jobs = response.data;
  })
  return jobs;
}

export async function pullPaymentApplications(uid: string): Promise<Array<SelectOptions>> {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("./api/draw-requests/" + uid + "/job");
  const numbers: any = [];
  response.data.forEach(function (num: any) {
    numbers.push({
      label: num + 1,
      value: num
    })
  })
  return numbers;
}

export async function pullCompletedTicketMinimal(): Promise<Array<SelectOptions>> {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("./api/tickets/minimal/completed");
  return response.data;
}

export async function pullBuildingByPropertyMinimal(property: string): Promise<Array<SelectOptions>> {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("./api/buildings/property/" + property + "/minimal");
  return response.data;
}

export async function pullDatabaseClasses(): Promise<Array<SelectOptions>> {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
  let response = await axios.get("./api/database-code-generator");
  return response.data;
}