import * as React from 'react'
import { DashboardOrganizer } from '../../functions/WidgetDashboardOrganizer';
import ProrateTool from './ProrateTool';
import { RenderSettings } from '../../interfaces/WidgetSettingsInterfaces';

export default class OtherToolsDashboard extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {

    }
  }

  render(): JSX.Element {

    return (<>
      {DashboardOrganizer({ 0: <ProrateTool /> }, { rows: [{ col: 1, widgets: [0] }] })}
    </>)
  }
}