import axios from 'axios';
import * as React from 'react';
import { EntityData } from '../Structs';

export async function pullEntityByUID(uid: string): Promise<EntityData> {
  if (uid === "") { return; }
  let response = await axios.get('./api/entities/' + uid);
  if (response.status === 200) {
    return response.data;
  }
  else {
    throw Error("Invalid Entity request, unable to return Pull Entity by UID");
  }
}

