import * as React from 'react';
import axios from 'axios';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import * as Structs from './UtilityManagerStructs';

const RateRow = (props: Structs.RateRowProps) => {
  return (
    <tr>
      <td className="fixedTableCellFirst">{props.data.name}</td>
      <td className="fixedTableCell">{props.data.rate}</td>
      <td className="fixedTableCell">{props.data.weightedConsumptionCharge}</td>
      <td className="fixedTableCell" >{props.data.weightedDemandCharge}</td>
      <td className="fixedTableCell" >{props.data.isActive}</td>
      <td className="fixedTableCell">{props.data.stateName}</td>
      <td className="fixedTableCell">
        <input type="button" value="Edit" style={{ width: "100%" }} onClick={(e) => props.edit(props.uid, props.rate)}
          className="standard-input"
        />
      </td>
      <td className="fixedTableCell">
        <input type="button" onClick={(e) => props.deactivate(props.rate)} value="Deactivate" className="standard-input" />
      </td>
    </tr>
  )
}

export default class ElectricUtilityTable extends React.Component<Structs.ElectricUtilityTableProps, Structs.ElectricUtilityTableState> {

  static displayName = ElectricUtilityTable.name;

  constructor(props: Structs.ElectricUtilityTableProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const utility = params.has('utility') ? params.get('utility') : ''
    this.state = {
      utility: utility,
      rates: [],
      name: '',
      headers: this.generateHeaders()
    }
    this.deactivateRate = this.deactivateRate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    var self = this;
    await axios.get("./api/electric-utility-providers/" + this.state.utility).then(function (response) {
      var data = response.data;
      var tmp: Array<Structs.RateRowData> = [];
      data.rates.forEach(function (item: any) {
        var rate = item.type === 0 ? "Residential" : "Commercial"
        var isActive = item.isActive ? "Yes" : "No";
        tmp.push({
          weightedConsumptionCharge: item.weightedConsumptionCharge,
          weightedDemandCharge: item.weightedDemandCharge,
          rate: rate,
          isActive: isActive,
          stateName: item.stateName,
          uid: item.uid,
          name: item.name
        });
      });
      self.setState({
        rates: tmp,
        name: data.name
      });
    })
  }

  async deactivateRate(rate: any): Promise<void> {
    await axios.put('./api/electric-rate/' + rate + "/inactivate").then(function (response) {
      CreateGlobalAlert(`Rate has been set as Inactive`, 2000);
    });
  }

  editRate(uid: string, rate: string): void {
    window.location.assign("./create-electric-rates?utility=" + uid + "&rate=" + rate);
  }

  createNew(uid: string): void {
    window.location.assign('./create-electric-rates?utility=' + uid);
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Rate Type",
        setting: true,
        filter: {}
      },
      {
        columnName: "Consumption Rate",
        setting: true,
        filter: {}
      },
      {
        columnName: "Demand Rate",
        setting: true,
        filter: {}
      },
      {
        columnName: "Is Active",
        setting: true,
        filter: {}
      },
      {
        columnName: "State",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {}
      }
    ]
  }

  render(): JSX.Element {
    return (
      <div id="electric-utility-rate-table">
        <h4 id="utility-name">{this.state.name}</h4>
        <h5>Rate Table</h5>
        <div id="table-container" style={{ maxHeight: "80vh", minHeight: "80vh", maxWidth: "100%", overflowY: "scroll" }}>
          <table style={{ tableLayout: "fixed" }}>
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody id="list-of-rates">
              {
                this.state.rates.map((item: Structs.RateRowData) => (
                  <RateRow key={item.uid} uid={this.state.utility}
                    rate={item.uid} data={item} edit={this.editRate} deactivate={this.deactivateRate} />
                ))
              }
            </tbody>
          </table>
        </div>
        <input type="button" style={{ width: "100%" }} value="Add New Rate" onClick={(e) => this.createNew(this.state.utility)} />
      </div>
    )
  }
}