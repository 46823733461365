import axios from 'axios';
import * as React from 'react';
import { numberWithCommas } from '../../../functions/numberFunctions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { PropertyPayrollWidgetState } from './PropertyPayrollWidget.constants';

interface PropertyPayrollWidgetProps {
  uid: string;
  date?: string;
}

export class PropertyPayrollWidget extends React.Component<PropertyPayrollWidgetProps, PropertyPayrollWidgetState> {

  headers: Array<FilterAndSettingField> = [
    { columnName: "Description", setting: true, filter: {} },
    { columnName: "Cost", setting: true, filter: {}}
  ]

  constructor(props: PropertyPayrollWidgetProps) {
    super(props);
    this.state = {
      propertyUID: props.uid,
      data: null
    }
  }

  async componentDidMount(): Promise<void> {
    let response = (this.props.date === "" || this.props.date === undefined)
      ? await axios.get('./api/property-payroll-tracking/latest/' + this.state.propertyUID, { validateStatus: () => true })
      : await axios.get('./api/property-payroll-tracking/' + this.props.date + "/" + this.state.propertyUID, { validateStatus: () => true });
    if (response.status === 200) {
      this.setState({
        data: response.data
      })
    }
  }

  render(): JSX.Element {
    return (
      <>
        <h4 style={{ width: "100%", textAlign: "center" }}>{this.state.data !== null && new Date(this.state.data.payrollDate).toLocaleDateString("en-CA")} Payroll</h4>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.headers} icons={0} />
          </thead>
          <tbody>
            {
              this.state.data !== null && this.state.data.length === 11 && 
              <>
                <tr key={"siteLabor"}>
                  <td className="fixedTableCellFirst">{this.state.data[0].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[0].amount, 2)}</td>
                </tr>
                <tr key={"siteTaxes"}>
                  <td className="fixedTableCellFirst">{this.state.data[1].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[1].amount, 2)}</td>
                </tr>
                <tr key={"siteBenefits"}>
                  <td className="fixedTableCellFirst">{this.state.data[2].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[2].amount, 2)}</td>
                </tr>
                <tr key={"siteWorkersComp"}>
                  <td className="fixedTableCellFirst">{this.state.data[3].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[3].amount, 2)}</td>
                </tr>
                <tr key={"maintenanceLabor"}>
                  <td className="fixedTableCellFirst">{this.state.data[4].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[4].amount, 2)}</td>
                </tr>
                <tr key={"maintenanceTaxes"}>
                  <td className="fixedTableCellFirst">{this.state.data[5].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[5].amount, 2)}</td>
                </tr>
                <tr key={"maintenanceBenefits"}>
                  <td className="fixedTableCellFirst">{this.state.data[6].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[6].amount, 2)}</td>
                </tr>
                <tr key={"maintenanceWorkersComp"}>
                  <td className="fixedTableCellFirst">{this.state.data[7].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[7].amount, 2)}</td>
                </tr>
                <tr key={"phoneAllowance"}>
                  <td className="fixedTableCellFirst">{this.state.data[8].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[8].amount, 2)}</td>
                </tr>
                <tr key={"autoAllowance"}>
                  <td className="fixedTableCellFirst">{this.state.data[9].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[9].amount, 2)}</td>
                </tr>
                <tr key={"expenseReimbursements"}>
                  <td className="fixedTableCellFirst">{this.state.data[10].payrollType}</td>
                  <td className="fixedTableCell" style={{ textAlign: "right" }} >${numberWithCommas(this.state.data[10].amount, 2)}</td>
                </tr>
              </>
            }
          </tbody>
        </table>
      </>
    )
  }
}