import * as React from 'react';
import FilteredWithSettingsTableHeader from '../CoreTableHeaders';
import { FilterAndSettingField } from '../interfaces';
import { DownloadSavedFile } from '../../../functions/documentTools';
import DocumentUploadWidget from '../Modals/DocumentUploadWidget';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface DocumentListWidgetProps {
  title: string, 
  uploadURL: string
}

interface DocumentListWidgetState {
  headers: Array<FilterAndSettingField>;
  data: Array<any>;
}

export interface DocumentListDataBase {
  uid: string;
  documentName: string;
  size: string;
}

export default class DocumentListWidget<T> extends React.Component<DocumentListWidgetProps, DocumentListWidgetState> {

  uploadWidget: React.RefObject<DocumentUploadWidget> = React.createRef<DocumentUploadWidget>();

  constructor(props: DocumentListWidgetProps) {
    super(props);
    this.state = {
      headers: [],
      data: [],
    }
    this.registerHeaders = this.registerHeaders.bind(this);
    this.build = this.build.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.registerHeaders();
  }

  registerHeaders(): DocumentListWidget<T> {
    const headers: Array<FilterAndSettingField> = [
      { columnName: "Document", setting: true, filter: {} },
      { columnName: "Size", setting: true, filter: {} },
      { columnName: "", setting: true, filter: {} }
    ]
    this.setState({ headers: headers });
    return this;
  }

  renderRow(item: any): JSX.Element {
    return (
      <tr key={item.uid}>
        <td className="fixedTableCellFirst">{item.name}</td>
        <td className="fixedTableCell" style={{ textAlign: "right"}}>{item.size}</td>
        <td className="fixedTableCell">
          <input type="button" value="Download" className="standard-input" onClick={() => DownloadSavedFile(item.uid)} />
        </td>
      </tr>
    )
  }

  async build(pull: () => Promise<Array<T>>): Promise<void> {
    let data = await pull();
    this.setState({ data: data });
  }

  render(): JSX.Element {
    return (
      <div>
        <DocumentUploadWidget ref={this.uploadWidget} uploadURL={this.props.uploadURL} />
        {RenderHeader(this.props.title) }
        <div style={{ height: "26vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) => (
                  this.renderRow(item)
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Upload Document" className="standard-input" onClick={() => this.uploadWidget.current.show()} />
      </div>
    )
  }
}