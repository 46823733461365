import * as React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { UploadSavedFile } from '../../../functions/documentTools';

interface DocumentUploadWidgetProps {
  uploadURL: string;
}

interface DocumentUploadWidgetState {
  showing: boolean;
  name: string;
  file: File;
}

export default class DocumentUploadWidget extends React.Component<DocumentUploadWidgetProps, DocumentUploadWidgetState> {

  constructor(props: DocumentUploadWidgetProps) {
    super(props);
    this.state = {
      showing: false,
      name: "",
      file: null
    }
    this.show = this.show.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.saveFile = this.saveFile.bind(this);
  }

  show(): void {
    this.setState({ showing: true });
  }

  changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ name: event.target.value });
  }

  changeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ file: event.target.files[0] });
  }

  async saveFile(): Promise<void> {
    await UploadSavedFile(this.props.uploadURL, this.state.name, this.state.file);
    window.location.reload();
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} toggle={() => this.setState({ showing: false })}>
        <div style={{ background: "#c2a877", border: "0px solid #15405c" }}>
          <ModalHeader tag="h3" toggle={() => this.setState({ showing: false })} style={{ border: "0px solid #15405c", color: "#15405c" }}>
            Upload Document
          </ModalHeader>
        </div>
        <div style={{ background: "#4c4a42", color: "#c2a877", border: "0px solid #15405c" }}>
          <ModalBody style={{ border: "0px solid #15405c" }}>
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Document Name</h5>
              </Col>
              <Col>
                <input type="text" value={this.state.name} className="standard-input" onChange={this.changeName} />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <Row>
              <Col>
                <h5 style={{ marginTop: "7px" }}>Document</h5>
              </Col>
              <Col>
                <input type="file" className="standard-input" style={{ paddingTop: "2%" }} onChange={this.changeFile}  />
              </Col>
            </Row>
            <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
            <input type="button" value="Save" className="standard-input" onClick={this.saveFile} />
          </ModalBody>
        </div>
      </Modal>
    )
  }
}